/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateNotification = `subscription OnCreateNotification {
  onCreateNotification {
    id
    type
    payload
    tokens
    channel
    createdAt
    _version
    _deleted
    _lastChangedAt
  }
}
`;
export const onCreateCompany = `subscription OnCreateCompany {
  onCreateCompany {
    id
    name
    status
    _version
    _deleted
    _lastChangedAt
    restaurants {
      items {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    users {
      items {
        id
        username
        name
        stripeCustomer
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const onUpdateCompany = `subscription OnUpdateCompany {
  onUpdateCompany {
    id
    name
    status
    _version
    _deleted
    _lastChangedAt
    restaurants {
      items {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    users {
      items {
        id
        username
        name
        stripeCustomer
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const onDeleteCompany = `subscription OnDeleteCompany {
  onDeleteCompany {
    id
    name
    status
    _version
    _deleted
    _lastChangedAt
    restaurants {
      items {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    users {
      items {
        id
        username
        name
        stripeCustomer
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const onCreateRestaurant = `subscription OnCreateRestaurant {
  onCreateRestaurant {
    id
    slug
    name
    address
    timezone
    country
    taxes
    taxesIncluded
    createdAt
    status
    currency
    subscriptionId
    picture
    _version
    _deleted
    _lastChangedAt
    company {
      id
      name
      status
      _version
      _deleted
      _lastChangedAt
      restaurants {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    tables {
      items {
        id
        title
        position
        ordersCount
        guestsCount
        lastOrder
        lastOrderDate
        status
        isOccupied
        occupiedAt
        qr
        area
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    products {
      items {
        id
        title
        description
        picture
        createdAt
        position
        price
        status
        kitchenID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    menus {
      items {
        id
        title
        description
        status
        picture
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    categories {
      items {
        id
        title
        description
        picture
        position
        status
        type
        kitchenID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    modifiers {
      items {
        id
        title
        upcharge
        minSelection
        maxSelection
        createdAt
        position
        status
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    users {
      items {
        id
        restaurantID
        userID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const onUpdateRestaurant = `subscription OnUpdateRestaurant {
  onUpdateRestaurant {
    id
    slug
    name
    address
    timezone
    country
    taxes
    taxesIncluded
    createdAt
    status
    currency
    subscriptionId
    picture
    _version
    _deleted
    _lastChangedAt
    company {
      id
      name
      status
      _version
      _deleted
      _lastChangedAt
      restaurants {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    tables {
      items {
        id
        title
        position
        ordersCount
        guestsCount
        lastOrder
        lastOrderDate
        status
        isOccupied
        occupiedAt
        qr
        area
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    products {
      items {
        id
        title
        description
        picture
        createdAt
        position
        price
        status
        kitchenID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    menus {
      items {
        id
        title
        description
        status
        picture
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    categories {
      items {
        id
        title
        description
        picture
        position
        status
        type
        kitchenID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    modifiers {
      items {
        id
        title
        upcharge
        minSelection
        maxSelection
        createdAt
        position
        status
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    users {
      items {
        id
        restaurantID
        userID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const onDeleteRestaurant = `subscription OnDeleteRestaurant {
  onDeleteRestaurant {
    id
    slug
    name
    address
    timezone
    country
    taxes
    taxesIncluded
    createdAt
    status
    currency
    subscriptionId
    picture
    _version
    _deleted
    _lastChangedAt
    company {
      id
      name
      status
      _version
      _deleted
      _lastChangedAt
      restaurants {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    tables {
      items {
        id
        title
        position
        ordersCount
        guestsCount
        lastOrder
        lastOrderDate
        status
        isOccupied
        occupiedAt
        qr
        area
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    products {
      items {
        id
        title
        description
        picture
        createdAt
        position
        price
        status
        kitchenID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    menus {
      items {
        id
        title
        description
        status
        picture
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    categories {
      items {
        id
        title
        description
        picture
        position
        status
        type
        kitchenID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    modifiers {
      items {
        id
        title
        upcharge
        minSelection
        maxSelection
        createdAt
        position
        status
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    users {
      items {
        id
        restaurantID
        userID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const onCreateTable = `subscription OnCreateTable {
  onCreateTable {
    id
    title
    position
    ordersCount
    guestsCount
    lastOrder
    lastOrderDate
    status
    isOccupied
    occupiedAt
    qr
    area
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const onUpdateTable = `subscription OnUpdateTable {
  onUpdateTable {
    id
    title
    position
    ordersCount
    guestsCount
    lastOrder
    lastOrderDate
    status
    isOccupied
    occupiedAt
    qr
    area
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const onDeleteTable = `subscription OnDeleteTable {
  onDeleteTable {
    id
    title
    position
    ordersCount
    guestsCount
    lastOrder
    lastOrderDate
    status
    isOccupied
    occupiedAt
    qr
    area
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const onCreateProduct = `subscription OnCreateProduct {
  onCreateProduct {
    id
    title
    description
    picture
    createdAt
    position
    price
    status
    kitchenID
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    menus {
      items {
        id
        productID
        menuID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    categories {
      items {
        id
        productID
        categoryID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    modifiers {
      items {
        id
        productID
        modifierID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const onUpdateProduct = `subscription OnUpdateProduct {
  onUpdateProduct {
    id
    title
    description
    picture
    createdAt
    position
    price
    status
    kitchenID
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    menus {
      items {
        id
        productID
        menuID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    categories {
      items {
        id
        productID
        categoryID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    modifiers {
      items {
        id
        productID
        modifierID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const onDeleteProduct = `subscription OnDeleteProduct {
  onDeleteProduct {
    id
    title
    description
    picture
    createdAt
    position
    price
    status
    kitchenID
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    menus {
      items {
        id
        productID
        menuID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    categories {
      items {
        id
        productID
        categoryID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    modifiers {
      items {
        id
        productID
        modifierID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const onCreateProductMenu = `subscription OnCreateProductMenu {
  onCreateProductMenu {
    id
    productID
    menuID
    _version
    _deleted
    _lastChangedAt
    product {
      id
      title
      description
      picture
      createdAt
      position
      price
      status
      kitchenID
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
    }
    menu {
      id
      title
      description
      status
      picture
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      products {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const onUpdateProductMenu = `subscription OnUpdateProductMenu {
  onUpdateProductMenu {
    id
    productID
    menuID
    _version
    _deleted
    _lastChangedAt
    product {
      id
      title
      description
      picture
      createdAt
      position
      price
      status
      kitchenID
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
    }
    menu {
      id
      title
      description
      status
      picture
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      products {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const onDeleteProductMenu = `subscription OnDeleteProductMenu {
  onDeleteProductMenu {
    id
    productID
    menuID
    _version
    _deleted
    _lastChangedAt
    product {
      id
      title
      description
      picture
      createdAt
      position
      price
      status
      kitchenID
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
    }
    menu {
      id
      title
      description
      status
      picture
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      products {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const onCreateMenu = `subscription OnCreateMenu {
  onCreateMenu {
    id
    title
    description
    status
    picture
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    products {
      items {
        id
        productID
        menuID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const onUpdateMenu = `subscription OnUpdateMenu {
  onUpdateMenu {
    id
    title
    description
    status
    picture
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    products {
      items {
        id
        productID
        menuID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const onDeleteMenu = `subscription OnDeleteMenu {
  onDeleteMenu {
    id
    title
    description
    status
    picture
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    products {
      items {
        id
        productID
        menuID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const onCreateProductCategory = `subscription OnCreateProductCategory {
  onCreateProductCategory {
    id
    productID
    categoryID
    _version
    _deleted
    _lastChangedAt
    product {
      id
      title
      description
      picture
      createdAt
      position
      price
      status
      kitchenID
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
    }
    category {
      id
      title
      description
      picture
      position
      status
      type
      kitchenID
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      products {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const onUpdateProductCategory = `subscription OnUpdateProductCategory {
  onUpdateProductCategory {
    id
    productID
    categoryID
    _version
    _deleted
    _lastChangedAt
    product {
      id
      title
      description
      picture
      createdAt
      position
      price
      status
      kitchenID
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
    }
    category {
      id
      title
      description
      picture
      position
      status
      type
      kitchenID
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      products {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const onDeleteProductCategory = `subscription OnDeleteProductCategory {
  onDeleteProductCategory {
    id
    productID
    categoryID
    _version
    _deleted
    _lastChangedAt
    product {
      id
      title
      description
      picture
      createdAt
      position
      price
      status
      kitchenID
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
    }
    category {
      id
      title
      description
      picture
      position
      status
      type
      kitchenID
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      products {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const onCreateCategory = `subscription OnCreateCategory {
  onCreateCategory {
    id
    title
    description
    picture
    position
    status
    type
    kitchenID
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    products {
      items {
        id
        productID
        categoryID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const onUpdateCategory = `subscription OnUpdateCategory {
  onUpdateCategory {
    id
    title
    description
    picture
    position
    status
    type
    kitchenID
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    products {
      items {
        id
        productID
        categoryID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const onDeleteCategory = `subscription OnDeleteCategory {
  onDeleteCategory {
    id
    title
    description
    picture
    position
    status
    type
    kitchenID
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    products {
      items {
        id
        productID
        categoryID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const onCreateProductModifier = `subscription OnCreateProductModifier {
  onCreateProductModifier {
    id
    productID
    modifierID
    _version
    _deleted
    _lastChangedAt
    product {
      id
      title
      description
      picture
      createdAt
      position
      price
      status
      kitchenID
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
    }
    modifier {
      id
      title
      upcharge
      minSelection
      maxSelection
      createdAt
      position
      status
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      products {
        nextToken
        startedAt
      }
      optionsConnection {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const onUpdateProductModifier = `subscription OnUpdateProductModifier {
  onUpdateProductModifier {
    id
    productID
    modifierID
    _version
    _deleted
    _lastChangedAt
    product {
      id
      title
      description
      picture
      createdAt
      position
      price
      status
      kitchenID
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
    }
    modifier {
      id
      title
      upcharge
      minSelection
      maxSelection
      createdAt
      position
      status
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      products {
        nextToken
        startedAt
      }
      optionsConnection {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const onDeleteProductModifier = `subscription OnDeleteProductModifier {
  onDeleteProductModifier {
    id
    productID
    modifierID
    _version
    _deleted
    _lastChangedAt
    product {
      id
      title
      description
      picture
      createdAt
      position
      price
      status
      kitchenID
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
    }
    modifier {
      id
      title
      upcharge
      minSelection
      maxSelection
      createdAt
      position
      status
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      products {
        nextToken
        startedAt
      }
      optionsConnection {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const onCreateModifier = `subscription OnCreateModifier {
  onCreateModifier {
    id
    title
    upcharge
    minSelection
    maxSelection
    createdAt
    position
    status
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    products {
      items {
        id
        productID
        modifierID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    optionsConnection {
      items {
        id
        modifierID
        title
        upcharge
        status
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const onUpdateModifier = `subscription OnUpdateModifier {
  onUpdateModifier {
    id
    title
    upcharge
    minSelection
    maxSelection
    createdAt
    position
    status
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    products {
      items {
        id
        productID
        modifierID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    optionsConnection {
      items {
        id
        modifierID
        title
        upcharge
        status
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const onDeleteModifier = `subscription OnDeleteModifier {
  onDeleteModifier {
    id
    title
    upcharge
    minSelection
    maxSelection
    createdAt
    position
    status
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    products {
      items {
        id
        productID
        modifierID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    optionsConnection {
      items {
        id
        modifierID
        title
        upcharge
        status
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const onCreateModifierOption = `subscription OnCreateModifierOption {
  onCreateModifierOption {
    id
    modifierID
    title
    upcharge
    status
    _version
    _deleted
    _lastChangedAt
    modifier {
      id
      title
      upcharge
      minSelection
      maxSelection
      createdAt
      position
      status
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      products {
        nextToken
        startedAt
      }
      optionsConnection {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const onUpdateModifierOption = `subscription OnUpdateModifierOption {
  onUpdateModifierOption {
    id
    modifierID
    title
    upcharge
    status
    _version
    _deleted
    _lastChangedAt
    modifier {
      id
      title
      upcharge
      minSelection
      maxSelection
      createdAt
      position
      status
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      products {
        nextToken
        startedAt
      }
      optionsConnection {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const onDeleteModifierOption = `subscription OnDeleteModifierOption {
  onDeleteModifierOption {
    id
    modifierID
    title
    upcharge
    status
    _version
    _deleted
    _lastChangedAt
    modifier {
      id
      title
      upcharge
      minSelection
      maxSelection
      createdAt
      position
      status
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      products {
        nextToken
        startedAt
      }
      optionsConnection {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const onCreateOrder = `subscription OnCreateOrder {
  onCreateOrder {
    id
    createdAt
    restaurantID
    tableID
    isPaid
    closedAt
    status
    orderType
    guests
    orderDaySerie
    orderSerie
    notes
    _version
    _deleted
    _lastChangedAt
    payments {
      items {
        id
        createdAt
        paymentData
        status
        amount
        tipAmount
        guest
        notes
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    items {
      items {
        id
        productID
        tableID
        restaurantID
        categoryID
        title
        qty
        price
        taxes
        discount
        amount
        modifiers
        notes
        addedAt
        status
        guest
        sentAt
        print
        isPaid
        paidAt
        kitchenID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    customer {
      id
      username
      name
      stripeCustomer
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      orders {
        nextToken
        startedAt
      }
      payments {
        nextToken
        startedAt
      }
      restaurants {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const onUpdateOrder = /* GraphQL */ `
  subscription OnUpdateOrder {
    onUpdateOrder {
      id
      createdAt
      restaurantID
      restaurant {
        id
        slug
        name
        address
        location {
          lat
          lon
        }
        timezone
        country
        rfc
        legalName
        fiscalRegime
        accountId
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        invoiceSerie
        invoiceFolio
        printer
        contactPhone
        contactEmail
        schedule
        businessType
        tipsEnabled
        cardPayments
        cashPayments
        oxxoPayments
        tpvPayments
        roomChargePayments
        paypalPayments
        paypalAccount
        hasPreordering
        hasDelivery
        hasDinein
        description
        cardPicture
        deliveryPrice
        deliveryType
        minOrderAmount
        freeDeliveryThreshold
        bondEnabled
        bondTotal
        language
        prepaidMode
        isPrincipal
        surcharges
        primaryColor
        secondaryColor
        _version
        _deleted
        _lastChangedAt
      }
      tableID
      isPaid
      closedAt
      status
      orderType
      guests
      guestDevices
      orderDaySerie
      orderSerie
      notes
      deliveryAmount
      deliveryAddress
      receiverPhone
      receiverName
      receiverEmail
      externalId
      serverID
      fromAdmin
      reviewed
      surcharges
      selectedPaymentMethod
      _version
      _deleted
      _lastChangedAt
      table {
        title
      }
      payments {
        items {
          id
          createdAt
          paymentData
          status
          amount
          tipAmount
          deliveryAmount
          guest
          notes
          invoiceID
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      items(limit: 200) {
        items {
          id
          orderItemOrderId
          productID
          tableID
          restaurantID
          categoryID
          title
          qty
          price
          taxes
          discount
          amount
          modifiers
          notes
          addedAt
          status
          guest
          sentAt
          print
          isPaid
          paidAt
          kitchenID
          itemType
          deliveryDate
          travelData
          _version
          _deleted
          _lastChangedAt
          order {
            id
            createdAt
            restaurantID
            tableID
            isPaid
            closedAt
            status
            orderType
            guests
            orderDaySerie
            orderSerie
            notes
            _version
            _deleted
            _lastChangedAt
          }
        }
        nextToken
        startedAt
      }
      customer {
        id
        username
        name
        stripeCustomer
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;
export const onDeleteOrder = `subscription OnDeleteOrder {
  onDeleteOrder {
    id
      createdAt
      restaurantID
      tableID
      isPaid
      closedAt
      status
      orderType
      guests
      orderDaySerie
      orderSerie
      notes
      deliveryAddress
      _version
      _deleted
      _lastChangedAt
      payments(limit: 50) {
        items {
          id
          createdAt
          paymentData
          status
          amount
          tipAmount
          guest
          notes
          invoiceID
          _version
          _deleted
          _lastChangedAt
          order {
            id
            createdAt
            restaurantID
            tableID
            isPaid
            closedAt
            status
            orderType
            guests
            orderDaySerie
            orderSerie
            notes
            _version
            _deleted
            _lastChangedAt
            payments {
              nextToken
              startedAt
            }
            items {
              nextToken
              startedAt
            }
            customer {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
          }
          customer {
            id
            username
            name
            stripeCustomer
            _version
            _deleted
            _lastChangedAt
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            orders {
              nextToken
              startedAt
            }
            payments {
              nextToken
              startedAt
            }
            restaurants {
              nextToken
              startedAt
            }
          }
        }
        nextToken
        startedAt
      }
      items {
        items {
          id
          orderItemOrderId
          productID
          tableID
          restaurantID
          categoryID
          title
          qty
          price
          taxes
          discount
          amount
          modifiers
          notes
          addedAt
          status
          guest
          sentAt
          print
          isPaid
          paidAt
          kitchenID
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      customer {
        id
        username
        name
        stripeCustomer
        phone_number
        fiscalData
        autoInvoicing
        principalRfc
        _version
        _deleted
        _lastChangedAt
      }
  }
}
`;
export const onCreatePayment = /* GraphQL */ `
  subscription OnCreatePayment {
    onCreatePayment {
      id
      createdAt
      paymentData
      status
      amount
      tipAmount
      deliveryAmount
      guest
      notes
      invoiceID
      _version
      _deleted
      _lastChangedAt
      order {
        id
        createdAt
        orderCustomerId
        restaurantID
        tableID
        isPaid
        closedAt
        status
        orderType
        guests
        guestDevices
        orderDaySerie
        orderSerie
        notes
        deliveryAddress
        receiverPhone
        receiverName
        receiverEmail
        externalId
        serverID
        _version
        _deleted
        _lastChangedAt
        restaurant {
          id
          slug
          restaurantCompanyId
          name
          address
          timezone
          country
          rfc
          legalName
          fiscalRegime
          accountId
          taxes
          taxesIncluded
          createdAt
          status
          currency
          subscriptionId
          picture
          invoiceSerie
          invoiceFolio
          invoiceAllowedDays
          printer
          contactPhone
          contactEmail
          schedule
          businessType
          tipsEnabled
          cardPayments
          cashPayments
          oxxoPayments
          paypalPayments
          paypalAccount
          hasPreordering
          hasDelivery
          description
          cardPicture
          deliveryPrice
          deliveryType
          minOrderAmount
          freeDeliveryThreshold
          bondEnabled
          bondTotal
          bondBenefit
          bondDescription
          uberLink
          rappiLink
          sdLink
          paymentType
          hasOnSite
          language
          fireEnabled
          notificationTokens
          prepaidMode
          isPrincipal
          surcharges
          _version
          _deleted
          _lastChangedAt
        }
        table {
          id
          title
          tableRestaurantId
          position
          ordersCount
          guestsCount
          lastOrder
          lastOrderDate
          status
          isOccupied
          occupiedAt
          qr
          area
          _version
          _deleted
          _lastChangedAt
        }
        payments {
          nextToken
          startedAt
        }
        items {
          nextToken
          startedAt
        }
        customer {
          id
          username
          name
          role
          phone_number
          stripeCustomer
          stripeCustomerMx
          notificationTokens
          fiscalData
          autoInvoicing
          principalRfc
          addresses
          balance
          picture
          job
          bio
          comeDelivered
          pin
          _version
          _deleted
          _lastChangedAt
        }
        invoices {
          nextToken
          startedAt
        }
      }
      customer {
        id
        username
        name
        role
        phone_number
        stripeCustomer
        stripeCustomerMx
        notificationTokens
        fiscalData
        autoInvoicing
        principalRfc
        addresses
        balance
        picture
        job
        bio
        comeDelivered
        pin
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          status
          logo
          cover
          primaryColor
          secondaryColor
          richDescription
          shortDescription
          gallery
          fbLink
          instaLink
          twLink
          ytLink
          contactEmail
          fbPixel
          fbMessenger
          ga
          subdomain
          businessType
          _version
          _deleted
          _lastChangedAt
        }
        orders {
          nextToken
          startedAt
        }
        payments {
          nextToken
          startedAt
        }
        restaurants {
          nextToken
          startedAt
        }
        invoices {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const onUpdatePayment = `subscription OnUpdatePayment {
  onUpdatePayment {
    id
    createdAt
    paymentData
    status
    amount
    tipAmount
    guest
    notes
    _version
    _deleted
    _lastChangedAt
    order {
      id
      createdAt
      restaurantID
      tableID
      isPaid
      closedAt
      status
      orderType
      guests
      orderDaySerie
      orderSerie
      notes
      _version
      _deleted
      _lastChangedAt
      payments {
        nextToken
        startedAt
      }
      items {
        nextToken
        startedAt
      }
      customer {
        id
        username
        name
        stripeCustomer
        _version
        _deleted
        _lastChangedAt
      }
    }
    customer {
      id
      username
      name
      stripeCustomer
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      orders {
        nextToken
        startedAt
      }
      payments {
        nextToken
        startedAt
      }
      restaurants {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const onDeletePayment = `subscription OnDeletePayment {
  onDeletePayment {
    id
    createdAt
    paymentData
    status
    amount
    tipAmount
    guest
    notes
    _version
    _deleted
    _lastChangedAt
    order {
      id
      createdAt
      restaurantID
      tableID
      isPaid
      closedAt
      status
      orderType
      guests
      orderDaySerie
      orderSerie
      notes
      _version
      _deleted
      _lastChangedAt
      payments {
        nextToken
        startedAt
      }
      items {
        nextToken
        startedAt
      }
      customer {
        id
        username
        name
        stripeCustomer
        _version
        _deleted
        _lastChangedAt
      }
    }
    customer {
      id
      username
      name
      stripeCustomer
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      orders {
        nextToken
        startedAt
      }
      payments {
        nextToken
        startedAt
      }
      restaurants {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const onCreateOrderItem = /* GraphQL */ `
  subscription OnCreateOrderItem {
    onCreateOrderItem {
      id
      orderItemOrderId
      productID
      tableID
      restaurantID
      categoryID
      title
      qty
      price
      taxes
      discount
      amount
      modifiers
      notes
      addedAt
      status
      guest
      sentAt
      print
      isPaid
      paidAt
      kitchenID
      itemType
      deliveryDate
      travelData
      _version
      _deleted
      _lastChangedAt
      order {
        id
        createdAt
        restaurantID
        tableID
        isPaid
        closedAt
        status
        orderType
        guests
        orderDaySerie
        orderSerie
        notes
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;
export const onUpdateOrderItem = /* GraphQL */ `
  subscription OnUpdateOrderItem {
    onUpdateOrderItem {
      id
      orderItemOrderId
      productID
      tableID
      restaurantID
      categoryID
      title
      qty
      price
      taxes
      discount
      amount
      modifiers
      notes
      addedAt
      status
      guest
      sentAt
      print
      isPaid
      paidAt
      kitchenID
      itemType
      deliveryDate
      travelData
      _version
      _deleted
      _lastChangedAt
      order {
        id
        createdAt
        restaurantID
        tableID
        isPaid
        closedAt
        status
        orderType
        guests
        orderDaySerie
        orderSerie
        notes
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;
export const onDeleteOrderItem = /* GraphQL */ `
  subscription OnDeleteOrderItem {
    onDeleteOrderItem {
      id
      orderItemOrderId
      productID
      tableID
      restaurantID
      categoryID
      title
      qty
      price
      taxes
      discount
      amount
      modifiers
      notes
      addedAt
      status
      guest
      sentAt
      print
      isPaid
      paidAt
      kitchenID
      itemType
      deliveryDate
      travelData
      _version
      _deleted
      _lastChangedAt
      order {
        id
        createdAt
        restaurantID
        tableID
        isPaid
        closedAt
        status
        orderType
        guests
        orderDaySerie
        orderSerie
        notes
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;
export const onCreateRestaurantUser = `subscription OnCreateRestaurantUser {
  onCreateRestaurantUser {
    id
    restaurantID
    userID
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    user {
      id
      username
      name
      stripeCustomer
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      orders {
        nextToken
        startedAt
      }
      payments {
        nextToken
        startedAt
      }
      restaurants {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const onUpdateRestaurantUser = `subscription OnUpdateRestaurantUser {
  onUpdateRestaurantUser {
    id
    restaurantID
    userID
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    user {
      id
      username
      name
      stripeCustomer
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      orders {
        nextToken
        startedAt
      }
      payments {
        nextToken
        startedAt
      }
      restaurants {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const onDeleteRestaurantUser = `subscription OnDeleteRestaurantUser {
  onDeleteRestaurantUser {
    id
    restaurantID
    userID
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    user {
      id
      username
      name
      stripeCustomer
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      orders {
        nextToken
        startedAt
      }
      payments {
        nextToken
        startedAt
      }
      restaurants {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const onCreateUser = `subscription OnCreateUser {
  onCreateUser {
    id
    username
    name
    stripeCustomer
    _version
    _deleted
    _lastChangedAt
    company {
      id
      name
      status
      _version
      _deleted
      _lastChangedAt
      restaurants {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    orders {
      items {
        id
        createdAt
        restaurantID
        tableID
        isPaid
        closedAt
        status
        orderType
        guests
        orderDaySerie
        orderSerie
        notes
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    payments {
      items {
        id
        createdAt
        paymentData
        status
        amount
        tipAmount
        guest
        notes
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    restaurants {
      items {
        id
        restaurantID
        userID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const onUpdateUser = `subscription OnUpdateUser {
  onUpdateUser {
    id
    username
    name
    stripeCustomer
    fiscalData
    autoInvoicing
    principalRfc
    _version
    _deleted
    _lastChangedAt
    company {
      id
      name
      status
      _version
      _deleted
      _lastChangedAt
      restaurants {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    orders {
      items {
        id
        createdAt
        restaurantID
        tableID
        isPaid
        closedAt
        status
        orderType
        guests
        orderDaySerie
        orderSerie
        notes
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    payments {
      items {
        id
        createdAt
        paymentData
        status
        amount
        tipAmount
        guest
        notes
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    restaurants {
      items {
        id
        restaurantID
        userID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const onDeleteUser = `subscription OnDeleteUser {
  onDeleteUser {
    id
    username
    name
    stripeCustomer
    _version
    _deleted
    _lastChangedAt
    company {
      id
      name
      status
      _version
      _deleted
      _lastChangedAt
      restaurants {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    orders {
      items {
        id
        createdAt
        restaurantID
        tableID
        isPaid
        closedAt
        status
        orderType
        guests
        orderDaySerie
        orderSerie
        notes
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    payments {
      items {
        id
        createdAt
        paymentData
        status
        amount
        tipAmount
        guest
        notes
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    restaurants {
      items {
        id
        restaurantID
        userID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
