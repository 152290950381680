import React from "react";
import { Route, useLocation } from "react-router-dom";

import ProtectedRoute from "./components/ProtectedRoute";
import OrderItemDialog from "./components/OrderItemDialog";
import SearchModal from "./components/Search/SearchModal";

import Checkout from "./pages/checkout";
import How from "./pages/how";
import Bond from "./pages/bond";
import useReducer from "./reducers";
import MasterApp from "./MasterApp";
import SiteApp from "./SiteApp";
import { Helmet } from "react-helmet";
import { language } from "./util";

function App() {
  const location = useLocation();
  const [
    {
      siteReducer: { siteDomain, siteData },
    },
  ] = useReducer();
  let background = location.state && location.state.background;

  return (
    <>
      <Helmet
        defaultTitle={
          siteDomain ? `${siteData.name}` : "2dine | Self Ordering System"
        }
        titleTemplate={siteDomain ? `${siteData.name} - %s` : "2dine - %s"}
      >
        <html
          lang={
            siteData && siteData.language
              ? siteData.language.toLowerCase()
              : language
          }
        />
        <meta
          name="description"
          content={
            siteDomain
              ? `${siteData.description}`
              : "The smart self ordering system | 2dine, Inc."
          }
        />
      </Helmet>
      {siteDomain ? <SiteApp /> : <MasterApp />}

      {background && <Route path={`/bono/:restaurantId`} children={<Bond />} />}
      {background && (
        <ProtectedRoute path="/checkout" children={<Checkout />} />
      )}
      {background && <Route path="/how" children={<How />} />}
      {background && (
        <ProtectedRoute
          path="/order-item/:oiid"
          children={<OrderItemDialog />}
        />
      )}
      {background && <Route path="/search" children={<SearchModal />} />}
    </>
  );
}

export default App;
