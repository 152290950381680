import React from "react";
import { Redirect } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";

import MenuCategories from "./MenuCategories";
import MenuProducts from "./MenuProducts";
import SimplifiedView from "../SimplifiedView/SimplifiedView";
import * as queries from "../../graphql/queries";

const MenuGrid = ({
  fromSite,
  delivery = false,
  restaurantId,
  selectedMenu,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [menu, setMenu] = React.useState();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const menuResponse = API.graphql({
          ...graphqlOperation(queries.getMenu, {
            id: selectedMenu,
            limit: 100,
          }),
        });
        const { data: menuData } = (await menuResponse) || {};
        const { getMenu } = menuData || {};
        setMenu(getMenu);
        setLoading(false);
      } catch (error) {
        console.log("error", error);
        setLoading(false);
      }
    };

    if (selectedMenu) {
      fetchData();
    }
  }, [selectedMenu]);

  if (!selectedMenu) {
    if (!delivery) {
      return <Redirect to={"/t/menu"} />;
    } else {
      return (
        <Redirect to={fromSite ? `/d/menu` : `/d/r/${restaurantId}/menu`} />
      );
    }
  }

  if (loading) {
    return null;
  }

  return menu && menu.view !== "LIST" ? (
    <>
      <MenuCategories
        selectedMenu={selectedMenu}
        restaurantId={restaurantId}
        delivery={delivery}
        fromSite={fromSite}
      />

      <MenuProducts
        selectedMenu={selectedMenu}
        delivery={delivery}
        fromSite={fromSite}
      />
    </>
  ) : (
    <div style={{ flex: 1, height: 0, marginLeft: -16, marginRight: -16 }}>
      <SimplifiedView
        restaurantId={restaurantId}
        menu={menu}
        delivery={delivery}
        fromSite={fromSite}
      />
    </div>
  );
};

export default MenuGrid;
