import React from "react";
// import { uuid } from "uuidv4";
import { v4 as uuid } from "uuid";
import moment from "moment";
import { Cache } from "aws-amplify";
import { I18n } from "aws-amplify";
import _omit from "lodash/omit";
import { makeStyles } from "@material-ui/core/styles";
// import TextField from "@material-ui/core/TextField";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import Box from "@material-ui/core/Box";
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { Button, Divider, Typography } from "@material-ui/core";
import { isValidPhoneNumber } from "react-phone-number-input";

import OrderSummary from "../Order/OrderSummary";
import NoDataItem from "../Order/NoDataItem";
import OrderItem from "../Order/OrderItem";
import { handleGetGroupedItems, getTotal, emailRegex } from "../../util";
import useReducer from "../../reducers";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import travelEmailTemplate from "../../emailTemplates/travelEmailTemplate";
import DeliveryCustomerData from "../Delivery/DeliveryCustomerData";
import AcceptTerms from "./AcceptTerms";
import { green } from "@material-ui/core/colors";
import ClearCartButton from "../Delivery/ClearCartButton";
import PaypalButton from "../Checkout/PaypalButton";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  button: {
    // color: theme.palette.background.paper,
    // backgroundColor: theme.palette.success.main,
    borderRadius: 0,
    paddingTop: 15,
    paddingBottom: 15,
    height: 56,
    minHeight: 56,

    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}));

const TravelCheckout = ({ fromSite = false }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [accepted, setAccepted] = React.useState(false);
  const [
    {
      loginReducer: { currentUser },
      deliveryReducer: {
        deliveryCart,
        deliveryNotes,
        paymentMethod,
        deliveryOrderAmount,
        deliveryOrderTaxes,
        receiverPhone,
        receiverPhoneCode,
        receiverName,
        receiverEmail,
        selectedRestaurant,
      },
    },
    dispatch,
  ] = useReducer();
  const { currency, paypalPayments } = selectedRestaurant;

  const dataCompleted = React.useMemo(
    () => Boolean(receiverPhone && receiverEmail && receiverName),
    [receiverPhone, receiverEmail, receiverName]
  );

  const total = React.useMemo(
    () => getTotal(deliveryOrderAmount, deliveryOrderTaxes),
    [deliveryOrderAmount, deliveryOrderTaxes]
  );

  const handleSelectPaymentMethod = (value) => () => {
    dispatch({
      type: "DELIVERY_SET_PAYMENT_METHOD",
      payload: { paymentMethod: value },
    });
  };

  const handleSendEmail = async (email, orderId) => {
    let apiName = "api616cdc2a";
    let path = "/notifications/email";

    if (!emailRegex.test(email)) {
      enqueueSnackbar("Ingresa un correo electrónico válido", {
        variant: "error",
        autoHideDuration: 3000,
      });
      return;
    }

    const result = await API.post(apiName, path, {
      body: {
        attributes: {
          email,
          subject: `Abona y viaja | Orden creada`,
          template: travelEmailTemplate({
            orderId,
            link: `${window.location.origin}/d/order/${orderId}`,
          }),
        },
      },
    });
    console.log("result", result);
  };

  const handleSendSms = async (phone_number, orderId) => {
    let apiName = "api616cdc2a";
    let path = "/notifications/sms";
    if (!isValidPhoneNumber(phone_number)) {
      enqueueSnackbar(
        "Ingresa un número de celular válido, verifica tu código de país.",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
      return;
    }

    const result = await API.post(apiName, path, {
      body: {
        attributes: {
          phone_number,
          message: `Abona y viaja. Orden creada. Puedes consultar tu orden, pagos y voucher en: ${window.location.origin}/d/order/${orderId}`,
        },
      },
    });
    console.log("result", result);
  };

  const handleAcceptTerms = () => {
    setAccepted((state) => !state);
  };

  const handleCreateOrder = async (isPaypal, paypalData) => {
    dispatch({ type: "LOADING_START" });

    if (!(deliveryCart && deliveryCart.length)) {
      enqueueSnackbar(I18n.get("¡Es necesario agregar productos al carrito!"), {
        variant: "error",
        autoHideDuration: 3000,
      });

      dispatch({ type: "LOADING_STOP" });
      return;
    }

    if (!paymentMethod || !["oxxo", "card"].includes(paymentMethod)) {
      enqueueSnackbar(I18n.get("¡Selecciona un método de pago!"), {
        variant: "error",
        autoHideDuration: 3000,
      });
      dispatch({ type: "LOADING_STOP" });
      return;
    }

    if (!receiverName) {
      enqueueSnackbar(
        "Ingresa tu nombre o de la persona que recibe tu pedido",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
      dispatch({ type: "LOADING_STOP" });
      return;
    }

    if (!receiverEmail || !emailRegex.test(receiverEmail)) {
      enqueueSnackbar("Ingresa un correo electrónico válido", {
        variant: "error",
        autoHideDuration: 3000,
      });
      dispatch({ type: "LOADING_STOP" });
      return;
    }

    if (
      !receiverPhone ||
      !isValidPhoneNumber(receiverPhoneCode + receiverPhone)
    ) {
      enqueueSnackbar(
        "Ingresa un número de celular válido o verifica tu código de país",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
      dispatch({ type: "LOADING_STOP" });
      return;
    }

    if (!accepted) {
      enqueueSnackbar(
        "Debes aceptar los términos y condiciones de uso para continuar",
        {
          variant: "warning",
          autoHideDuration: 3000,
        }
      );
      dispatch({ type: "LOADING_STOP" });
      return;
    }

    const now = new Date();
    const orderId = uuid();

    await Promise.all(
      deliveryCart.map(async (item) => {
        const original = deliveryCart.find((x) => x.id === item.id);
        const input = {
          ..._omit(original, ["companyID", "restaurantName"]),
          tableID: "travel",
          addedAt: now.toISOString(),
          sentAt: now.toISOString(),
          print: 0,
          isPaid: false,
          orderItemOrderId: orderId,
        };

        console.log("input", input);

        await API.graphql({
          ...graphqlOperation(mutations.createOrderItem, {
            input,
          }),
          authMode: "AWS_IAM",
        });
      })
    );

    // crear orden
    const prevOrderRes = await API.graphql({
      ...graphqlOperation(queries.ordersByRestaurantByDate, {
        restaurantID: deliveryCart[0].restaurantID,
        sortDirection: "DESC",
      }),
      authMode: "AWS_IAM",
    });
    const {
      data: {
        ordersByRestaurantByDate: { items: prevOrders } = { items: [] },
      } = {},
    } = prevOrderRes || {};
    const prevOrder = prevOrders && prevOrders.length ? prevOrders[0] : {};
    console.log("prevOrder result", prevOrder);
    const isSameDay = moment().isSame(prevOrder.createdAt, "day");
    console.log("isSameDay", isSameDay);

    const vars = {
      id: orderId,
      restaurantID: deliveryCart[0].restaurantID,
      tableID: "travel",
      status: "NEW",
      orderType: "travel",
      orderDaySerie: isSameDay ? (prevOrder.orderDaySerie || 0) + 1 : 1,
      orderSerie: (prevOrder.orderSerie || 0) + 1,
      receiverPhone: `${receiverPhoneCode}${receiverPhone}`,
      receiverName,
      receiverEmail,
    };

    if (deliveryNotes) {
      vars.notes = deliveryNotes;
    }

    if (currentUser) {
      vars.orderCustomerId = currentUser.id;
    }

    console.log("new order", vars);

    const { data } = await API.graphql({
      ...graphqlOperation(mutations.createOrder, { input: vars }),
      authMode: "AWS_IAM",
    });
    const { createOrder: newOrder } = data || {};

    console.log("newOrder", newOrder);

    Cache.removeItem("deliveryCart");
    Cache.removeItem("cartRestaurant");
    Cache.removeItem("cartCompany");
    dispatch({ type: "DELIVERY_CLEAR_CART" });
    try {
      await handleSendSms(vars.receiverPhone, orderId);
    } catch (error) {
      console.log("sms error", error);
    }

    try {
      await handleSendEmail(vars.receiverEmail, orderId);
    } catch (error) {
      console.log("email error", error);
    }

    if (isPaypal && paypalData) {
      const paymentInput = {
        paymentData: JSON.stringify({
          payment_method: "paypal",
          ...paypalData,
        }),
        status: "succeeded",
        amount: total,
        tipAmount: 0,
        deliveryAmount: 0,
        paymentOrderId: orderId,
        internal: true,
      };

      const { data } = await API.graphql({
        ...graphqlOperation(mutations.createPayment, { input: paymentInput }),
        authMode: "AWS_IAM",
      });
      const { createPayment } = data || {};
      console.log("createPayment", createPayment);

      dispatch({
        type: "DELIVERY_TOGGLE_CHECKOUT",
        payload: { openCheckout: false },
      });
      dispatch({ type: "LOADING_STOP" });
      history.push({
        pathname: `/payment/paypal`,
        search: `?o=${orderId}&ot=travel`,
      });
      return;
    }

    if (paymentMethod && paymentMethod === "card") {
      console.log("payment method", paymentMethod);

      dispatch({
        type: "DELIVERY_TOGGLE_CHECKOUT",
        payload: { openCheckout: false },
      });
      dispatch({ type: "LOADING_STOP" });

      history.push({
        pathname: `/payment/card`,
        search: `?o=${orderId}`,
      });

      // await handlePay(orderId);
    } else {
      console.log("payment method", paymentMethod);
      enqueueSnackbar(I18n.get("Order created successfully!"), {
        variant: "success",
        autoHideDuration: 3000,
      });

      dispatch({
        type: "DELIVERY_TOGGLE_CHECKOUT",
        payload: { openCheckout: false },
      });
      dispatch({ type: "LOADING_STOP" });

      history.push({
        pathname: `/payment/oxxo`,
        search: `?o=${orderId}&rn=${receiverName}`,
        state: { amount: total, receiverName },
      });
    }
  };

  return (
    <>
      <Box mt={1} flex={1} display="flex" flexDirection="column">
        <ListSubheader disableSticky>Customer info</ListSubheader>
        <DeliveryCustomerData withEmail />

        <List
          style={{ marginTop: 8 }}
          subheader={
            <ListSubheader disableSticky>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography>{I18n.get("Your order")}</Typography>
                <ClearCartButton />
              </Box>
            </ListSubheader>
          }
        >
          {deliveryCart && deliveryCart.length ? (
            handleGetGroupedItems(deliveryCart).map((item, index) => (
              <OrderItem key={index} item={item} travel fromSite={fromSite} />
            ))
          ) : (
            <NoDataItem
              title={I18n.get("No ordered items in your cart")}
              secondary={I18n.get("Add items to your order")}
            />
          )}
        </List>
        {/* <Box p={1}>
          <TextField
            id="outlined-multiline-static"
            label={I18n.get("Add special indications")}
            value={deliveryNotes}
            multiline
            rows="4"
            variant="outlined"
            fullWidth
            onChange={(event) =>
              dispatch({
                type: "DELIVERY_SET_NOTES",
                payload: { deliveryNotes: event.target.value },
              })
            }
          />
        </Box> */}
        <OrderSummary travel />

        <AcceptTerms accepted={accepted} handleAccept={handleAcceptTerms} />

        <Box>
          <ListSubheader disableSticky>
            {I18n.get("Payment Method")}
          </ListSubheader>
        </Box>
        <Box
          mt={1}
          mb={2}
          mx={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          minHeight={42}
        >
          <Button
            variant={paymentMethod === "card" ? "contained" : "outlined"}
            color="primary"
            size="small"
            fullWidth
            style={{
              borderRadius: 40,
              paddingTop: 8,
              paddingBottom: 8,
            }}
            onClick={handleSelectPaymentMethod("card")}
          >
            Pay with card
          </Button>
          <Button
            variant={paymentMethod === "oxxo" ? "contained" : "outlined"}
            color="primary"
            size="small"
            fullWidth
            style={{
              marginLeft: 16,
              borderRadius: 40,
              paddingTop: 8,
              paddingBottom: 8,
            }}
            onClick={handleSelectPaymentMethod("oxxo")}
          >
            <img alt="oxxo" src="/assets/oxxo.png" width={48} height={24} />
          </Button>
        </Box>

        {paypalPayments && dataCompleted && accepted ? (
          <>
            <Box
              mb={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Divider style={{ flex: 1 }} variant="middle" />
              <Typography variant="caption">{I18n.get("or")}</Typography>
              <Divider style={{ flex: 1 }} variant="middle" />
            </Box>
            <Box px={2}>
              <PaypalButton
                amount={total}
                currency={currency}
                afterApprove={handleCreateOrder}
              />
            </Box>
          </>
        ) : (
          <Box mb={2} px={2} bgcolor="#fff" borderRadius={4}>
            <Typography variant="caption">
              {I18n.get(
                "Add your name, email, phone & accept terms & conditions to enable paypal payment."
              )}
            </Typography>
          </Box>
        )}

        <Button className={classes.button} onClick={handleCreateOrder}>
          {I18n.get("Confirm order")}
        </Button>
      </Box>
    </>
  );
};

export default TravelCheckout;
