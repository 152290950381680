const initialState = {
  bondAmount: "",
  bondOrderId: null,
  bondOrder: null,
  myBonds: []
};

const reducer = (state, action) => {
  switch (action.type) {
    case "BOND_SET_AMOUNT":
      return {
        ...state,
        bondAmount: action.payload.bondAmount
      };
    case "BOND_SET_BOND_ORDER_ID":
      return {
        ...state,
        bondOrderId: action.payload.bondOrderId
      };
    case "BOND_SET_BOND_ORDER":
      return {
        ...state,
        bondOrder: action.payload.bondOrder
      };
    case "BOND_SET_BONDS_LIST":
      return {
        ...state,
        myBonds: action.payload.myBonds
      };
    case "BOND_RESET":
      return initialState;
    default:
      return state;
  }
};

export default { initialState, reducer };
