import React from "react";

import Signin from "./SignIn";
import Signup from "./SignUp";
import SecretCode from "./SecretCode";
import useReducer from "../../reducers";

const LoginWrapper = () => {
  const [
    {
      loginReducer: { loginView },
    },
  ] = useReducer();

  const renderView = () => {
    switch (loginView) {
      case "signup":
        return <Signup />;
      case "secret_code":
        return <SecretCode />;
      case "confirm":
        return <div>Confirm signup</div>;
      default:
        return <Signin />;
    }
  };

  return renderView();
};

export default LoginWrapper;
