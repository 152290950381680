import React from "react";
import clsx from "clsx";
import getDistance from "geolib/es/getDistance";
import { Box, Typography, ButtonBase, makeStyles } from "@material-ui/core";
import { I18n } from "aws-amplify";
import ArrowDropDownCircleIcon from "@material-ui/icons/ArrowDropDownCircle";
import useReducer from "../../reducers";
import { isEmptyObject } from "../../util";

const useStyles = makeStyles(() => ({
  shake: {
    animation: `$shake 1s cubic-bezier(.36,.07,.19,.97) 4s both infinite`,
    transform: "translate3d(0, 0, 0)",
    backfaceVisibility: "hidden",
    perspective: 1000,
  },
  "@keyframes shake": {
    "10%, 90%": {
      transform: "translate3d(-1px, 0, 0)",
    },

    "20%, 80%": {
      transform: "translate3d(2px, 0, 0)",
    },

    "30%, 50%, 70%": {
      transform: "translate3d(-4px, 0, 0)",
    },

    "40%, 60%": {
      transform: "translate3d(4px, 0, 0)",
    },
  },
}));

const DeliveryGeolocation = ({ onClick = () => null }) => {
  const classes = useStyles();
  const [isAway, setIsAway] = React.useState(false);
  const [
    {
      deliveryReducer: { deliveryAddress, selectedRestaurant },
    },
  ] = useReducer();
  const { businessType } = selectedRestaurant || {};
  const { formatted_address } = deliveryAddress || {};
  const isTravel = businessType === "TRAVEL";

  React.useEffect(() => {
    let mounted = true;

    if (
      navigator.geolocation &&
      deliveryAddress &&
      !isEmptyObject(deliveryAddress)
    ) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          const latitude =
            deliveryAddress &&
            deliveryAddress.geometry &&
            deliveryAddress.geometry.location &&
            deliveryAddress.geometry.location.lat;
          const longitude =
            deliveryAddress &&
            deliveryAddress.geometry &&
            deliveryAddress.geometry.location &&
            deliveryAddress.geometry.location.lng;

          const distance = getDistance(
            {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            },
            {
              latitude,
              longitude,
            }
          );
          if (mounted) {
            setIsAway(distance > 4000);
          }
        },
        (error) => {
          console.log("Position could not be determined.");
          console.log("Error occurred. Error code: " + error.code);
          // error.code can be:
          //   0: unknown error
          //   1: permission denied
          //   2: position unavailable (error response from location provider)
          //   3: timed out
        }
      );
    }

    return () => {
      mounted = false;
    };
  }, [deliveryAddress]);

  return isTravel ? null : (
    <ButtonBase onClick={onClick}>
      <Box
        mb={1}
        width="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        overflow="hidden"
        className={clsx({ [classes.shake]: isAway })}
      >
        <Box flex={1} width={0} textAlign="left">
          <Typography variant="caption">{I18n.get("Localization")}</Typography>

          <Typography color="textSecondary" noWrap>
            {formatted_address ? formatted_address : "Agrega tu dirección"}
          </Typography>
        </Box>

        <ArrowDropDownCircleIcon fontSize="small" style={{ marginLeft: 8 }} />
      </Box>
    </ButtonBase>
  );
};

export default DeliveryGeolocation;
