import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation, useHistory } from "react-router-dom";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { I18n } from "aws-amplify";
import useReducer from "../../reducers";

const useStyles = makeStyles((theme) => ({
  banner: {
    minHeight: 56,
    height: 56,
    width: "100%",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.getContrastText(theme.palette.primary.dark),
    padding: theme.spacing(1, 2),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));

const ActiveOrderBanner = () => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const isDeliveryRoute = location.pathname.startsWith("/d");
  const [
    {
      deliveryReducer: {
        currentDeliveryOrderId,
        currentDeliveryOrder,
        selectedRestaurant,
      },
    },
  ] = useReducer();
  // console.log("selectedRestaurant", selectedRestaurant);
  const isTravel = Boolean(
    (selectedRestaurant && selectedRestaurant.businessType) === "TRAVEL"
  );

  return isDeliveryRoute && currentDeliveryOrderId && !isTravel ? (
    <Box
      className={classes.banner}
      onClick={() => {
        history.push(`/d/order/${currentDeliveryOrderId}`);
      }}
    >
      <Box display="flex" flexDirection="column">
        <Typography variant="button">
          {I18n.get("You have an active order")}
        </Typography>
        <Typography variant="caption">
          {I18n.get(currentDeliveryOrder && currentDeliveryOrder.status)}
        </Typography>
      </Box>
      <ArrowForwardIosIcon fontSize="inherit" color="inherit" />
    </Box>
  ) : null;
};

export default ActiveOrderBanner;
