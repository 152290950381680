import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import Img from "react-image";
import VisibilitySensor from "react-visibility-sensor";
import Skeleton from "@material-ui/lab/Skeleton";

import {
  JSONParse,
  toCurrency,
  getPictureUrl,
  isEmptyObject,
} from "../../util";
import Photo from "../Photo";
import useReducer from "../../reducers";

const useStyles = makeStyles(() => ({
  inline: {
    display: "inline",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "center",
  },
}));

const SearchListItem = ({ item, delivery }) => {
  const classes = useStyles();
  const [
    {
      siteReducer: { siteData },
      deliveryReducer: { selectedRestaurant },
    },
    dispatch,
  ] = useReducer();
  const fromSite = siteData && !isEmptyObject(siteData);

  const history = useHistory();
  const picture =
    item && item.cardPicture
      ? JSONParse(item.cardPicture)
      : item && item.picture
      ? JSONParse(item.picture)
      : {};
  const isPicArray = picture ? Array.isArray(picture) : false;
  const imageUrl = picture
    ? isPicArray
      ? getPictureUrl({ ...picture[0], size: "0x91" })
      : getPictureUrl({ ...picture, size: "0x91" })
    : null;
  const to = delivery
    ? item.businessType
      ? `/d/r/${item.id}`
      : fromSite
      ? `/d/product/${item.id}`
      : `/d/r/${item.restaurantID || selectedRestaurant.id}/product/${item.id}`
    : `/product/${item.id}`;

  if (!item || item._deleted || item.status !== "ACTIVE") {
    return null;
  }

  return (
    <>
      <ListItem
        button
        onClick={() => {
          dispatch({ type: "TABS_SET_TAB", payload: { tab: 0 } });
          history.push(to);
        }}
        alignItems="flex-start"
      >
        <ListItemAvatar>
          <Avatar>
            <VisibilitySensor>
              <Img
                src={[imageUrl]}
                alt={item.title}
                loader={
                  <Skeleton
                    variant="rect"
                    animation="wave"
                    width={"100%"}
                    height={"100%"}
                  />
                }
                unloader={<Photo />}
                className={classes.image}
              />
            </VisibilitySensor>
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            !!item.businessType
              ? item.name
              : delivery
              ? `${item.title} ${
                  item && item.restaurantName ? item.restaurantName : ""
                }`
              : item.title
          }
          secondary={
            <React.Fragment>
              {!!item.businessType ? null : (
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="textPrimary"
                >
                  {item && item.price
                    ? toCurrency(((item && item.price) || 0).toString())
                    : null}
                </Typography>
              )}
              {item.description ? ` — ${item.description}` : null}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  );
};

export default SearchListItem;
