const pushServerPublicKey =
  "BN5UVlNj1Y3w4UzGlcZ8nUAAPQ5rPNiqr-WG2EaPnoDApnDUar4g4gYczc8wkwf-fdAR3_3WM0iJGt-JxciPrJ8";

function urlB64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

/**
 * checks if Push notification and service workers are supported by your browser
 */
function isPushNotificationSupported() {
  return (
    "Notification" in window &&
    "serviceWorker" in navigator &&
    "PushManager" in window
  );
}

/**
 * asks user consent to receive push notifications and returns the response of the user, one of granted, default, denied
 */
async function askUserPermission() {
  return await Notification.requestPermission();
}

/**
 *
 * using the registered service worker creates a push notification subscription and returns it
 *
 */
async function createNotificationSubscription() {
  //wait for service worker installation to be ready
  const serviceWorker = await navigator.serviceWorker.ready;

  const applicationServerKey = urlB64ToUint8Array(pushServerPublicKey);

  // subscribe and return the subscription
  return await serviceWorker.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey,
  });
}

async function removeNotificationSubscription() {
  try {
    const subscription = await getUserSubscription();

    if (!subscription) {
      return;
    }

    await subscription.unsubscribe();
    return null;
  } catch (error) {
    console.log("Error unsubscribing", error);
    return null;
  }
}

/**
 * returns the subscription if present or nothing
 */
function getUserSubscription() {
  //wait for service worker installation to be ready, and then
  return navigator.serviceWorker.ready
    .then(function (serviceWorker) {
      return serviceWorker.pushManager.getSubscription();
    })
    .then(function (pushSubscription) {
      return pushSubscription;
    });
}

export {
  isPushNotificationSupported,
  askUserPermission,
  createNotificationSubscription,
  removeNotificationSubscription,
  getUserSubscription,
};
