import React from "react";
import { I18n } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: theme.spacing(3, 0),
  },
}));

const PaymentIdStep = ({ formik }) => {
  const classes = useStyles();

  return (
    <TextField
      id="paymentId"
      name="paymentId"
      label={I18n.get("Payment ID")}
      className={classes.textField}
      fullWidth
      size="medium"
      variant="outlined"
      inputProps={{
        spellCheck: "false",
        autoCorrect: "off",
        autoCapitalize: "off",
        autoComplete: "off",
      }}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={formik.values.paymentId}
      error={formik.errors.paymentId && formik.touched.paymentId}
      helperText={
        formik.errors.paymentId && formik.touched.paymentId
          ? formik.errors.paymentId
          : I18n.get("Add the payment ID")
      }
    />
  );
};

export default PaymentIdStep;
