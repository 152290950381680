import React from "react";
import ReactMarkdown from "react-markdown";

let markdown =
  '# TERMS OF USE\nEffective: 01/01/2020\n## Resumen\n¡Bienvenido a 2Dine! Este servicio es propiedad u operado por 2Dine, Inc ("2DINE", "nosotros", "nuestro" o "nosotros"). Estos Términos de uso ("Términos") establecen los términos y condiciones bajo los cuales usted está autorizado a usar nuestro "Sitio", que incluye nuestro sitio web en 2dine.io y otros sitios y dispositivos donde vinculamos/publicamos estos Términos de uso ( incluidos los subdominios o versiones móviles de los mismos).\nA través del uso de nuestro Sitio, usted acepta estos Términos.';
markdown += "\n### Acuerdos adicionales";
markdown +=
  '\nEn la medida en que las reglas o pautas adicionales de 2Dine afecten su uso de nuestro Sitio, dichas reglas y pautas (incluida nuestra Política de privacidad adjunta) se incorporan por referencia a estos Términos. Brindamos productos y servicios, y procesamos información relacionada con terceros que han celebrado un contrato de suscripción o servicios con nosotros (nuestros "Clientes"), y en relación con los usuarios autorizados de la Plataforma del Cliente ("Usuarios autorizados"). Podemos proporcionar estos productos/servicios a los Clientes de conformidad con un acuerdo de servicios u otro contrato ("Acuerdo de servicios"). En caso de conflicto entre estos Términos y nuestra Política de privacidad o cualquier Acuerdo de servicio, la Política de privacidad o el Acuerdo de servicio prevalecerán en la medida de dicho conflicto. Los términos en mayúscula no definidos en estos Términos tendrán la definición establecida en el Acuerdo de servicio o la Política de privacidad.';
markdown += "\n### Su acceso al sitio";
markdown +=
  "\nComo condición de su derecho a acceder y utilizar nuestro Sitio, usted declara que es mayor de edad para celebrar un contrato vinculante y que no tiene prohibido acceder al Sitio según las leyes de los Estados Unidos o cualquier otro país. 2Dine se reserva el derecho de cancelar su acceso en caso de que viole estos Términos o cualquier Acuerdo de servicio. USTED ES TOTALMENTE RESPONSABLE DE TODAS LAS ACTIVIDADES REALIZADAS EN RELACIÓN CON EL USO DE LOS SERVICIOS EN SU(S) DISPOSITIVO(S) O BAJO SU CUENTA.";
markdown += "\n### Plataforma y contenido de terceros";
markdown +=
  "\nPodemos utilizar proveedores de servicios de terceros para proporcionarle partes del Sitio, o puede acceder a sitios de terceros desde nuestro Sitio. Los terceros no se rigen por estos Términos. Usted reconoce que cualquier confianza en las representaciones y garantías proporcionadas por cualquier parte que no sea 2Dine será bajo su propio riesgo. Su uso de sitios web/servicios operados por terceros puede estar sujeto a términos de uso y políticas de privacidad adicionales.";
markdown += "\n### Consentimiento para comunicaciones electrónicas";
markdown +=
  "\nAl usar el Sitio, usted acepta que 2Dine puede comunicarse con usted electrónicamente con respecto a su uso del Sitio y asuntos relacionados, y que cualquier aviso, acuerdo, divulgación u otra comunicación que 2Dine le envíe electrónicamente cumplirá con los requisitos legales de comunicación, incluyendo que las comunicaciones sean por escrito. Para retirar su consentimiento para recibir un aviso electrónico, notifique a 2Dine a: [help@2dine.io](mailto:help@2dine.io)";
markdown += "\n### Modificaciones e Interrupción del Sitio";
markdown +=
  "\nNos reservamos el derecho de modificar o descontinuar todo o parte de nuestro Sitio con o sin previo aviso. No seremos responsables si elegimos ejercer este derecho. Usted reconoce y acepta que no garantizamos un acceso continuo, ininterrumpido o seguro a nuestro Sitio, o que nuestro Sitio estará libre de errores. Usted comprende que el uso de nuestro Sitio puede verse interferido o afectado negativamente por numerosos factores o circunstancias fuera de nuestro control.";
markdown += "\n### Actividades restringidas";
markdown +=
  "\nNo puede participar en nada de lo siguiente con respecto al Sitio (incluyendo, entre otros, publicar o transmitir contenido a través del Sitio): ";
markdown +=
  "\n- violar o alentar la violación de cualquier ley o regulación local, estatal, nacional o internacional;";
markdown +=
  "\n- recopilar o almacenar datos personales sobre otros usuarios de nuestro Sitio o solicitar información personal de cualquier persona sin los derechos adecuados o el consentimiento de la persona;";
markdown +=
  "\n- enviar o promover cualquier mensaje que sea ilegal, calumnioso, difamatorio, abusivo, sexualmente explícito, amenazante, vulgar, obsceno, profano, despectivo con respecto a la raza, el género o el origen étnico, cualquier declaración que tenga motivos para saber que es falsa o mensajes engañosos u objetables, según lo determine 2Dine a su exclusivo criterio;";
markdown +=
  "\n- infringir cualquier patente, marca comercial, secreto comercial, derechos de autor, derecho de publicidad o privacidad u otro derecho de cualquier parte, o distribuir cualquier contenido que no tenga derecho a poner a disposición bajo ninguna ley o bajo relaciones contractuales o fiduciarias ; ";
markdown +=
  '\n- promover o distribuir cualquier publicidad no autorizada, material promocional o material que pueda caracterizarse como "correo basura", "correo no deseado", "cartas en cadena", "esquemas piramidales" o material similar, cualquier solicitud o solicitud de dinero, bienes o servicios para beneficio privado, o cualquier información publicada principalmente con fines publicitarios, promocionales u otros fines comerciales;';
markdown +=
  "\n- interrumpir o interferir con la seguridad o el uso del Sitio o cualquier sitio web o contenido vinculado a ellos; ";
markdown +=
  "\n- interferir o dañar la integridad del Sitio, lo que incluye, entre otros, mediante el uso de virus, caballos de Troya, código dañino, ataques de denegación de servicio, suplantación de IP o paquetes, enrutamiento falsificado o información de dirección de correo electrónico o métodos similares o tecnología o desobedecer cualquier requisito, procedimiento, política o regulación de las redes conectadas a nuestro Sitio; ";
markdown +=
  "\n- usar el Sitio para almacenar o transmitir código, archivos, scripts, agentes o programas destinados a causar daño, incluidos, por ejemplo, virus, gusanos, bombas de tiempo o caballos de Troya;";
markdown +=
  "\n- intentar usar a otro usuario, persona o entidad, tergiversar su afiliación con una persona o entidad, incluyendo (sin limitación) 2Dine o crear o usar una identidad falsa; ";
markdown +=
  "\n- intentar obtener acceso no autorizado al Sitio o partes del mismo cuyo acceso general está restringido; ";
markdown +=
  '\n- usar metaetiquetas o cualquier otro "texto oculto" utilizando el nombre, las marcas registradas o los nombres de productos de 2Dine; ';
markdown +=
  "\n- intentar aplicar ingeniería inversa o derivar u obtener el código de cualquier forma para cualquier software utilizado en el Sitio; ";
markdown +=
  "\n- participar en cualquier actividad que interfiera con la capacidad de un tercero para usar o disfrutar el Sitio; o ";
markdown +=
  "\n- ayudar a cualquier tercero a participar en cualquier actividad prohibida por estos Términos.";
markdown +=
  "\n### Además, sin nuestro consentimiento por escrito, usted no puede:";
markdown +=
  "\n- reproducir, duplicar, copiar, vender, revender, crear trabajos derivados o explotar para fines comerciales cualquier contenido de 2Dine o cualquier uso o acceso al Sitio; ";
markdown +=
  "\n- usar cualquier medio de gran volumen, automatizado o electrónico (incluidos, entre otros, robots, arañas, secuencias de comandos u otros dispositivos automatizados) para acceder al Sitio o monitorear o copiar nuestras páginas web o el contenido de las mismas; ";
markdown +=
  "\n- enlace profundo al Sitio para cualquier propósito; o enmarcar el Sitio, colocar ventanas emergentes sobre cualquier contenido, o afectar de otro modo la visualización del Sitio;";
markdown +=
  "\n- acceder al Sitio para construir un servicio competitivo o compararlo con un servicio que no sea 2DINE; o ";
markdown +=
  "\n- aplicar ingeniería inversa al Sitio (en la medida en que la ley permita dicha restricción).";

markdown += "\n## RENUNCIA DE GARANTÍAS Y LIMITACIÓN DE RESPONSABILIDAD";
markdown +=
  '\nEN LA MEDIDA MÁXIMA PERMITIDA POR LA LEY, 2DINE, SUS ENTIDADES RELACIONADAS, SUS PROVEEDORES DE SERVICIOS, SUS LICENCIANTES Y SUS RESPECTIVOS FUNCIONARIOS, DIRECTORES, EMPLEADOS O AGENTES (COLECTIVAMENTE LAS "PARTES DE LA COMPAÑÍA") RENUNCIA EXPRESAMENTE A TODAS LAS GARANTÍAS DE CUALQUIER TIPO , YA SEA EXPRESA, IMPLÍCITA O ESTATUTARIA, INCLUYENDO, ENTRE OTRAS, CUALQUIER GARANTÍA IMPLÍCITA DE COMERCIABILIDAD, IDONEIDAD PARA UN FIN DETERMINADO, TÍTULO Y NO VIOLACIÓN, RELACIONADA CON NUESTROS SERVICIOS. ';
markdown +=
  'USTED ENTIENDE Y ACEPTA QUE SU USO DE NUESTROS SERVICIOS ES BAJO SU PROPIO RIESGO. NUESTROS SERVICIOS Y TODO EL CONTENIDO, PRODUCTOS Y SERVICIOS OFRECIDOS A TRAVÉS DE LOS SERVICIOS SE PROPORCIONAN "TAL CUAL" Y "SEGÚN DISPONIBILIDAD". LAS PARTES DE LA EMPRESA NO SON RESPONSABLES DE LA PUNTUALIDAD EN LA ENTREGA DEL CONTENIDO, DE LAS FALLAS EN LA ENTREGA, DEL ELIMINACIÓN ERRÓNEA O DE CUALQUIER PÉRDIDA O DAÑO DE CUALQUIER TIPO QUE USTED AFIRME COMO RESULTADO DEL USO DE CUALQUIER SERVICIO. BAJO NINGUNA CIRCUNSTANCIA,';
markdown +=
  "¿ALGUNA DE LAS PARTES DE LA EMPRESA SERÁ RESPONSABLE ANTE USTED O CUALQUIER PERSONA O ENTIDAD QUE RECLAME A TRAVÉS DE USTED POR CUALQUIER PÉRDIDA, LESIÓN, RESPONSABILIDAD O DAÑOS QUE SURJAN DE O EN RELACIÓN CON SU ACCESO A, USO DE, INCAPACIDAD DE USO O CONFIANZA EN CUALQUIERA DE NUESTROS SERVICIOS O CUALQUIER CONTENIDO, PRODUCTO O SERVICIO PROPORCIONADO A TRAVÉS DE CUALQUIERA DE NUESTROS SERVICIOS O EN RELACIÓN CON CUALQUIERA DE NUESTROS SERVICIOS. ESTA ES UNA LIMITACIÓN COMPLETA DE RESPONSABILIDAD QUE SE APLICA A TODAS LAS PÉRDIDAS Y DAÑOS DE CUALQUIER TIPO, ";
markdown +=
  "YA SEA DIRECTO O INDIRECTO, GENERAL, ESPECIAL, INCIDENTAL, CONSECUENTE, EJEMPLAR O DE OTRO MODO, INCLUYENDO SIN LIMITACIÓN, PÉRDIDA DE DATOS, FONDO DE COMERCIO, INGRESOS O BENEFICIOS. ESTA LIMITACIÓN DE RESPONSABILIDAD SE APLICA SI LA RESPONSABILIDAD ALEGADA SE BASA EN CONTRATO, NEGLIGENCIA, AGRAVIO, RESPONSABILIDAD ESTRICTA O CUALQUIER OTRA BASE; INCLUSO SI CUALQUIER PARTE DE LA COMPAÑÍA HA SIDO ADVERTIDA O DEBERÍA HABER CONOCIDO LA POSIBILIDAD DE DICHOS DAÑOS; Y SIN CONSIDERAR EL ÉXITO O LA EFICACIA DE OTROS RECURSOS. ";
markdown +=
  "SI SE DETERMINA QUE ALGUNA PARTE DE ESTA LIMITACIÓN DE RESPONSABILIDAD NO ES VÁLIDA, ILEGAL O INAPLICABLE POR CUALQUIER MOTIVO, ENTONCES LA RESPONSABILIDAD TOTAL DE LAS PARTES DE LA COMPAÑÍA BAJO TALES CIRCUNSTANCIAS HACIA USTED O CUALQUIER PERSONA O ENTIDAD QUE RECLAME A TRAVÉS DE USTED POR RESPONSABILIDADES QUE DE OTRO MODO SE HABRÍAN SIDO LIMITED NO EXCEDERÁ LOS CIEN DÓLARES ESTADOUNIDENSES.";
markdown +=
  "\nALGUNAS JURISDICCIONES NO PERMITEN LA EXCLUSIÓN DE CIERTAS GARANTÍAS O LA LIMITACIÓN O EXCLUSIÓN DE RESPONSABILIDAD POR CIERTOS TIPOS DE DAÑOS. EN CONSECUENCIA, ALGUNAS DE LAS EXENCIONES DE GARANTÍAS Y LIMITACIONES DE RESPONSABILIDAD ANTERIORES PUEDEN NO SER APLICABLES A USTED.";
markdown +=
  "\nUsted reconoce que puede tener o puede tener en el futuro reclamos contra nosotros que no sabe o sospecha que existen a su favor cuando aceptó estos Términos y que, si se conocen, podrían afectar materialmente su consentimiento a estos Términos. Usted renuncia expresamente a todos los derechos que pueda tener bajo la Sección 1542 del Código Civil de California, que establece:";
markdown +=
  "\nUNA LIBERACIÓN GENERAL NO SE EXTIENDE A LAS RECLAMACIONES QUE EL ACREEDOR NO CONOCE O SOSPECHA QUE EXISTEN A SU FAVOR EN EL MOMENTO DE EJECUTAR LA LIBERACIÓN, QUE DE SER CONOCIDAS POR ÉL O ELLA DEBEN HABER AFECTADO CONSIDERABLEMENTE SU LIQUIDACIÓN CON EL DEUDOR.";

markdown += "\n### Indemnización";
markdown +=
  "\nUsted acepta indemnizar y eximir de responsabilidad a las Partes de 2Dine por cualquier reclamo o demanda, incluidos los honorarios y costos razonables de abogados, realizado por cualquier tercero debido a su publicación de cualquier contenido en nuestro Sitio o como resultado de su publicación de cualquier contenido en nuestro Sitio, u otro uso de nuestro Sitio de una manera no permitida por estos Términos, incluida, entre otras, su violación real o supuesta de estos Términos, o la infracción de la propiedad intelectual u otros derechos de un tercero por parte de usted u otro usuario de nuestro Sitio que usa su computadora, dispositivo o cuenta. ";
markdown += "\n### Restricciones, modificaciones y cancelaciones del servicio";
markdown +=
  "\n2Dine shall not be responsible for any delays or interruptions of, or errors or omissions contained in, the Site. 2dine reserves the right, but shall not be required, to correct and delays, interruptions, errors or omissions. 2Dine may discontinue or alter any aspect of this Site, including, but not limited to: (i) restricting the time of availability, (ii) restricting the availability and/or scope of the Site for certain users, (iii) restricting the amount of use permitted, and (iv) restricting or terminating any user’s right to use this service, at 2dine’s sole discretion and without prior notice or liability.";
markdown += "\n### Nombres de usuario, contraseñas y seguridad";
markdown +=
  "\nSu nombre de usuario será su identidad para interactuar con 2Dine y otros usuarios a través del Sitio. Deberá mantener la confidencialidad, no difundirá y utilizará únicamente de conformidad con este Acuerdo, su nombre de usuario para el Sitio. Deberá notificar inmediatamente a 2Dine si se entera o sospecha: (i) cualquier pérdida o robo de su nombre de usuario, o (ii) cualquier uso no autorizado de su nombre de usuario o del Sitio. En caso de pérdida, robo o uso no autorizado, 2Dine puede imponerle, a su sola discreción, obligaciones de seguridad adicionales. Si alguna persona no autorizada obtiene acceso al Sitio como resultado de cualquier acto u omisión de su parte, deberá hacer todo lo posible para determinar la fuente y la forma de adquisición y deberá informar completa y rápidamente a 2Dine. De lo contrario, deberá cooperar y ayudar en cualquier investigación relacionada con dicho acceso no autorizado.";
markdown += "\n### Información sobre derechos de autor y marcas registradas";
markdown +=
  '\nTodo el contenido, el material protegido por derechos de autor y otros derechos de propiedad intelectual en el contenido disponible en nuestro Sitio, incluidos, entre otros, el diseño, el texto, los gráficos, las interfaces y la selección y arreglos de los mismos (colectivamente, "Contenido"), son propiedad de 2Dine, sus Clientes, o usuarios con todos los derechos reservados, o en algunos casos pueden ser autorizados a 2Dine por el Cliente, usuario u otros terceros. Este Contenido está protegido por los derechos de propiedad intelectual de 2Dine o de sus propietarios. Todo el Contenido que califica para la protección bajo la Ley Federal de Derechos de Autor de los EE. UU. está sujeto a la jurisdicción exclusiva del Sistema de Tribunales Federales, ya sea registrado o no registrado. Todas las marcas comerciales que se muestran en nuestro Sitio son marcas comerciales de sus respectivos propietarios y no constituyen un respaldo ni una recomendación de dichas partes. Además, dicho uso de marcas comerciales o enlaces a sitios web de terceros no implica, directa o indirectamente, que dichos terceros respalden o tengan alguna afiliación con 2Dine.';
markdown += "\n### Uso Permitido del Contenido";
markdown +=
  "\nCualquier uso del Contenido en el Sitio, incluida, entre otras, la reproducción para fines distintos a los aquí señalados, la modificación, distribución, replicación, cualquier forma de extracción de datos o minería de datos, u otra explotación comercial de cualquier tipo, sin el permiso previo por escrito de un funcionario autorizado de 2Dine o como parte de un acuerdo de Cliente con 2Dine, está estrictamente prohibido. Con la excepción de los motores de búsqueda, usted acepta que no utilizará ningún robot, araña u otro dispositivo automático o proceso manual para monitorear o copiar nuestras páginas web o el Contenido que contienen sin el permiso previo por escrito de 2Dine. No puede hacer ningún uso del Contenido propiedad de terceros que está disponible en el Sitio, sin el consentimiento expreso de dichos terceros. ";
markdown += "\n### Quejas de contenido";
markdown +=
  "\nSi cree que algún Contenido de nuestro Sitio viola estos Términos o es inapropiado, informe el Contenido comunicándose con nosotros a: [help@2dine.io](mailto:help@2dine.io)";
markdown += "\n### Notificación de infracción de derechos de autor reclamada";
markdown +=
  '\nEn caso de que encuentre contenido publicado en nuestro Sitio que considere que infringe los derechos de autor de un tercero, comuníquese de inmediato con el Agente de derechos de autor de 2Dine como se describe a continuación. Para informar cualquier supuesta infracción, comuníquese con nosotros por escrito proporcionando una declaración firmada que contenga la siguiente información de conformidad con la Ley de derechos de autor del milenio digital ("DMCA"):';
markdown +=
  "\n- Su nombre, dirección, número de teléfono y dirección de correo electrónico, y si actúa en nombre del propietario de la propiedad intelectual, el nombre del propietario;";
markdown +=
  "\n- una declaración, realizada bajo pena de perjurio, de que usted es el propietario de los derechos de autor o está autorizado para actuar en nombre del propietario;";
markdown +=
  "\n- una descripción detallada del trabajo protegido por derechos de autor u otra propiedad intelectual que afirma que se ha infringido;";
markdown +=
  "\n- si su reclamo se basa en una obra registrada, el número de registro y la fecha de emisión del registro;";
markdown +=
  "\n- una descripción del material infractor y la URL donde se encuentra dicho material en el Sitio, o una descripción de dónde encontró dicho material en nuestro Sitio;";
markdown +=
  "\n- su declaración escrita de que cree, de buena fe, que el uso del trabajo en nuestro Sitio no ha sido autorizado por el verdadero propietario del trabajo, su agente, o por una cuestión de ley; y";
markdown +=
  "\n- una declaración de que toda la información que ha proporcionado es verdadera.";
markdown +=
  "\n- Envíenos su aviso de supuesta infracción: **Aviso de DMCA** Almanalco 47, CDMX, MÉXICO, 14340 Correo electrónico: [help@2dine.io](mailto:help@2dine.io), asunto línea **Aviso de DMCA** De acuerdo con la DMCA, es política de 2dine cancelar el uso de nuestro Sitio por parte de los infractores reincidentes en las circunstancias apropiadas.";
markdown += "\n### Contenido enviado por el usuario";
markdown +=
  '\nCualquier contenido cargado, publicado, enviado o puesto a disposición de otro modo por usuarios individuales del Sitio, incluidos, entre otros, todos los archivos, documentos y cualquier otro Contenido que no se origine con 2dine ("Contenido de usuario"), es responsabilidad exclusiva de la persona que puso a disposición dicho Contenido de Usuario en el Sitio. Bajo ninguna circunstancia, 2dine será responsable de ningún Contenido de usuario puesto a disposición a través del Sitio por usted o por un tercero.';
markdown +=
  "\nDado que 2dine no controla el Contenido del usuario publicado en el Sitio, no garantiza la veracidad, integridad, precisión, idoneidad o calidad de ese Contenido del usuario, y no respalda dicho Contenido del usuario. También acepta y comprende que al acceder al Sitio, puede encontrar Contenido que puede considerar objetable. El Contenido del usuario es propiedad del autor del mismo, y 2dine no reclama la propiedad de los trabajos originales creados y publicados por visitantes individuales del Sitio. 2dine se reserva el derecho (pero no tiene la obligación) a su exclusivo criterio de preseleccionar, editar, rechazar, mover o eliminar cualquier Contenido de usuario que se publique en el Sitio. Usted acepta que el ejercicio por parte de 2dine de tal discreción no convertirá ni transformará el Contenido de usuario en contenido propiedad de 2dine o proporcionado por 2dine, y el usuario que hizo que dicho Contenido de usuario esté disponible en el Sitio conservará la propiedad del mismo como se describe a continuación.";
markdown += "\n### Proporcionar comentarios a 2DINE";
markdown +=
  '\nAgradecemos sus comentarios y opiniones sobre nuestro sitio. Toda la información y los materiales enviados a 2Dine a través del Sitio o de otro modo, como comentarios, comentarios, ideas, preguntas, diseños, datos o similares relacionados con el Sitio o el negocio de 2Dine (colectivamente, "Comentarios"), serán ser considerado NO CONFIDENCIAL y NO PROPIETARIO con respecto a usted, pero 2Dine se reserva el derecho de tratar dichos Comentarios como información confidencial de 2Dine.';
markdown +=
  "\nAl enviar Comentarios a 2Dine, asigna a las Partes de la empresa, sin cargo alguno, todos los derechos, títulos e intereses en todo el mundo sobre todos los derechos de autor y otros derechos de propiedad intelectual en dichos Comentarios. Las Partes de la empresa tendrán derecho a utilizar cualquier Comentario que envíe, y cualquier idea, concepto, conocimiento o técnica contenida en dichos Comentarios, para cualquier propósito, incluidos, entre otros, el desarrollo, la fabricación y la comercialización de productos y servicios utilizando dichos Comentarios. Comentarios sin restricciones y sin compensarte de ninguna manera. Usted es responsable de la información y otro contenido incluido en cualquier Comentario que nos envíe, lo que incluye, entre otros, su veracidad y precisión.";
markdown += "\n### Leyes que rigen";
markdown +=
  "\nLa interpretación de los derechos y obligaciones de las partes en virtud de este Acuerdo, incluidos, en la medida aplicable, cualquier negociación, arbitraje u otro procedimiento en virtud del presente, se regirá en todos los aspectos exclusivamente por las leyes del Estado de Colorado, EE. UU. Cada parte acuerda que solo entablará cualquier acción o procedimiento que surja de o esté relacionado con este Acuerdo en un tribunal federal en los tribunales estatales y federales de Denver, Colorado, y usted se somete irrevocablemente a la jurisdicción personal y el lugar de dicho tribunal en cualquier tal acción o procedimiento o en cualquier acción o procedimiento iniciado en dichos tribunales por 2Dine.";
markdown += "\n### Resolución de disputas";
markdown +=
  "\nAl aceptar los Términos de uso, usted renuncia irrevocablemente a cualquier derecho que pueda tener para unir reclamos con los de otros en forma de demanda colectiva o dispositivo procesal similar. Cualquier reclamo que surja de, se relacione o esté conectado con estos Términos de uso debe hacerse valer individualmente.";
markdown +=
  "\nUsted reconoce y acepta que, independientemente de cualquier estatuto o ley en contrario, cualquier reclamo o causa de acción que pueda tener que surja de, se relacione o esté conectado con su uso del Sitio debe completarse dentro de un año calendario después de surja dicha reclamación o causa de acción, o prescribirá para siempre. Si un reclamo procede en la corte, ambos renunciamos a cualquier derecho a un juicio con jurado.";
markdown += "\n### Cumplimiento de las leyes";
markdown +=
  "\nUsted asume todo el conocimiento de la ley aplicable y es responsable del cumplimiento de dichas leyes. No puede usar el Sitio de ninguna manera que viole las leyes, regulaciones estatales, federales o internacionales aplicables u otros requisitos gubernamentales.";
markdown += "\n### Aviso para residentes de California";
markdown +=
  "\nSi es residente de California, de acuerdo con Cal. civ. Code §1789.3, puede presentar quejas a la Unidad de Asistencia de Quejas de la División de Sitios del Consumidor del Departamento de Asuntos del Consumidor de California comunicándose con ellos por escrito a 400 R Street, Sacramento, CA 95814, o por teléfono al (800) 952- 5210.";
markdown += "\n### Cambios a estos términos";
markdown +=
  "\nNos reservamos el derecho, en cualquier momento, de modificar, alterar o actualizar estos Términos sin previo aviso. Le recomendamos que consulte esta página con regularidad para conocer los cambios en los Términos. Las modificaciones entrarán en vigencia inmediatamente después de su publicación en nuestro Sitio, sin previo aviso. Su uso continuado de cualquiera de nuestros Sitios después de que se publiquen dichas modificaciones constituye su reconocimiento y aceptación de dichas modificaciones, y no puede modificar estos Términos.";
markdown += "\n### Otros términos";
markdown +=
  "\n- Cesión: estos Términos de uso serán vinculantes para cada una de las partes y sus sucesores y cesionarios autorizados. Usted no puede asignar ni transferir estos Términos de uso sin el consentimiento previo por escrito de 2Dine. Usted acepta que estos Términos de uso y cualquier otro acuerdo al que se haga referencia en el presente pueden ser asignados por nosotros, a nuestro exclusivo criterio, a un tercero en caso de fusión o adquisición, o de otra manera. ";
markdown +=
  "\n- Integración: estos Términos de uso (incluidas todas las políticas descritas en estos Términos de uso, que se incorporan aquí por esta referencia) contienen el entendimiento completo de las partes con respecto a su objeto, y reemplaza todos los acuerdos anteriores y contemporáneos. y entendimientos entre las partes en cuanto a su objeto. ";
markdown +=
  "\n- Renuncia: ninguna falla o retraso por parte de una parte en el ejercicio de cualquier derecho, poder o privilegio en virtud de estos Términos de uso operará como una renuncia a los mismos, ni el ejercicio único o parcial de cualquier derecho, poder o privilegio excluirá cualquier otro o el ejercicio posterior de los mismos o el ejercicio de cualquier otro derecho, poder o privilegio. ";
markdown +=
  "\n- Divisibilidad: si un tribunal de justicia determina que alguna disposición de estos Términos de uso es ilegal, nula o inaplicable por cualquier motivo, las demás disposiciones (y cualquier disposición parcialmente aplicable) no se verán afectadas por ello y seguirán siendo válidos y exigibles en la mayor medida posible, y la disposición inválida se modificará según sea necesario para que sea válida y exigible mientras refleje lo más fielmente posible las intenciones originales de 2Dine. ";
markdown +=
  "\n- Limitación: usted acepta que, independientemente de cualquier estatuto o ley que establezca lo contrario, cualquier reclamo o causa de acción que surja o esté relacionado con el uso de nuestro Sitio o con estos Términos de uso debe presentarse dentro de un (1) un año después de que surja tal reclamo o causa de acción o prescribirá para siempre.";
markdown +=
  "\n- Sin relación: estos Términos de uso no pretenden ni crean ninguna relación de agencia, asociación, empresa conjunta o empleado-empleador. ";
markdown +=
  "\n- Fuerza mayor: 2dine no será responsable de ninguna falla o deficiencia en el rendimiento o la disponibilidad del Sitio debido a la ocurrencia de cualquier evento fuera de nuestro control razonable, que incluye, entre otros, un disturbio laboral, una interrupción de Internet, interrupción del servicio, interrupción de la comunicación, incumplimiento de 2Dine por parte de un proveedor de servicios, incendio, terrorismo, desastre natural o guerra";

const Privacy = () => {
  return <ReactMarkdown source={markdown} escapeHtml={false} />;
};

export default Privacy;
