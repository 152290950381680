import React from "react";
import qs from "query-string";
import { useLocation } from "react-router-dom";

import useReducer from "../reducers";
import SearchContainer from "../components/Search/SearchContainer";
import useQuery from "../hooks/useQuery";

const Search = () => {
  const location = useLocation();
  const [, dispatch] = useReducer();
  const query = useQuery();
  const origin = query.get("origin"); // origin [delivery]

  React.useEffect(() => {
    let mounted = true;
    const { q } = qs.parse(location.search) || {};
    if (mounted) {
      dispatch({ type: "SEARCH_SET_QUERY", payload: { searchQuery: q } });
    }

    return () => {
      mounted = false;
    };
  }, [location.search]);

  return (
    <SearchContainer
      delivery={origin === "delivery"}
      bond={origin === "bond"}
    />
  );
};

export default Search;
