import React from "react";
import GridListTile from "@material-ui/core/GridListTile";
import Skeleton from "@material-ui/lab/Skeleton";

const CategoryCardSkeleton = ({ ...other }) => {
  return (
    <GridListTile {...other}>
      <Skeleton
        variant="rect"
        animation="wave"
        height={180}
        width={185}
        style={{ borderRadius: 8 }}
      />
    </GridListTile>
  );
};

export default CategoryCardSkeleton;
