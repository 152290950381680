import React from "react";
import { I18n } from "aws-amplify";
import moment from "moment";
import { API, graphqlOperation } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useSnackbar } from "notistack";
import { Typography, Button, Box } from "@material-ui/core";
import SvgIcon from "@material-ui/core/SvgIcon";

import * as queries from "../../graphql/queries";
import useReducer from "../../reducers";
import {
  JSONParse,
  subtractTaxes,
  toCurrency,
  isEmptyObject,
} from "../../util";
import { ReactComponent as NoDataIcon } from "../../svg/undraw_no_data.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  button: {
    margin: theme.spacing(2, 0),
  },
}));

const InvoiceDataStep = ({ formik, handleReset }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [
    {
      invoiceReducer: { invoicePayment, loadingPayment },
    },
    dispatch,
  ] = useReducer();
  const [paymentRestaurant, setPaymentRestaurant] = React.useState({});
  const paymentId = formik.values.paymentId;

  const { taxes: paymentRestaurantTaxes } = paymentRestaurant || {};

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await API.graphql({
          ...graphqlOperation(queries.getPayment, {
            id: paymentId,
          }),
          authMode: "AWS_IAM",
        });
        const { getPayment: paymentData } = data || {};

        dispatch({
          type: "INVOICE_SET_PAYMENT",
          payload: { payment: paymentData || {} },
        });

        if (!paymentData) {
          enqueueSnackbar("Pago no encontrado", {
            variant: "error",
            autoHideDuration: 5000,
          });
          return;
        }

        const { data: restaurantData } = await API.graphql({
          ...graphqlOperation(queries.getRestaurantData, {
            id: paymentData.order.restaurantID,
          }),
          authMode: "AWS_IAM",
        });
        const { getRestaurant: restaurant } = restaurantData || {};
        setPaymentRestaurant(restaurant);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch({ type: "INVOICE_SET_LOADING", payload: { loading: false } });
      }
    };

    if (paymentId) {
      fetchData();
    } else {
      dispatch({ type: "INVOICE_SET_LOADING", payload: { loading: false } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentId]);

  const getRealPayment = (i) => {
    return i.amount - i.tipAmount;
  };
  const getAmountNoTaxes = (paid, taxes) => {
    return subtractTaxes(paid, taxes);
  };

  const realPaid = React.useMemo(() => getRealPayment(invoicePayment), [
    invoicePayment,
  ]);
  const amountNoTaxes = React.useMemo(() => {
    const parsedTaxes = paymentRestaurantTaxes
      ? JSONParse(paymentRestaurantTaxes)
      : [];
    return getAmountNoTaxes(realPaid, parsedTaxes);
  }, [paymentRestaurantTaxes, realPaid]);

  if (loadingPayment) {
    return <Typography>Loading...</Typography>;
  }

  if (!invoicePayment || isEmptyObject(invoicePayment)) {
    return (
      <Box textAlign="center">
        <Typography>Pago no encontrado</Typography>
        <SvgIcon
          component={NoDataIcon}
          viewBox="0 0 820.16 780.81"
          style={{ marginTop: 16, fontSize: 80 }}
        />
        <div>
          <Button
            className={classes.button}
            variant="outlined"
            onClick={handleReset}
          >
            {I18n.get("Reset")}
          </Button>
        </div>
      </Box>
    );
  }

  return (
    <List className={classes.root}>
      <ListItem dense>
        <ListItemText primary="Payment ID" secondary={paymentId} />
      </ListItem>
      <ListItem dense>
        <ListItemText primary="Restaurant" secondary={paymentRestaurant.name} />
      </ListItem>
      <ListItem dense>
        <ListItemText primary="RFC" secondary={formik.values.selectedRfc} />
      </ListItem>
      <ListItem dense>
        <ListItemText
          primary="Date"
          secondary={moment(invoicePayment.createdAt).format("LLL")}
        />
      </ListItem>
      <ListItem dense>
        <ListItemText
          primary="Amount"
          secondary={`$ ${toCurrency((amountNoTaxes || 0).toString())}`}
        />
      </ListItem>
      <ListItem dense>
        <ListItemText
          primary="Taxes"
          secondary={`$ ${toCurrency(
            (realPaid - amountNoTaxes || 0).toString()
          )}`}
        />
      </ListItem>
      <ListItem dense>
        <ListItemText
          primary="Total"
          secondary={`$ ${toCurrency((realPaid || 0).toString())}`}
        />
      </ListItem>
    </List>
  );
};

export default InvoiceDataStep;
