import React from "react";
import ListItem from "@material-ui/core/ListItem";
import { API } from "aws-amplify";
import _orderBy from "lodash/orderBy";
import { Typography, makeStyles } from "@material-ui/core";

import * as queries from "../../graphql/queries";
import useReducer from "../../reducers";
import { isEmptyObject, handleAvailability } from "../../util";
import ItemModifier from "./ItemModifier";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  itemText: {
    WebkitLineClamp: 2,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
}));

const SimplifiedItem = ({ restaurantId, item, delivery, fromSite }) => {
  const classes = useStyles();
  const history = useHistory();
  const { product } = item || {};

  // Check if product is available (hours)
  const isProductAvailable = React.useMemo(() => handleAvailability(product), [
    product,
  ]);

  const handleClickCard = () => {
    if (product.status === "ACTIVE" && isProductAvailable) {
      if (!delivery) {
        history.push(`/t/product/${product.id}`);
      } else {
        history.push(
          fromSite
            ? `/d/product/${product.id}`
            : `/d/r/${restaurantId}/product/${product.id}`
        );
      }
    }
  };

  return (
    <ListItem button onClick={handleClickCard}>
      <div style={{ flex: 6 }}>
        <Typography className={classes.itemText}>{product?.title}</Typography>
      </div>
      <div style={{ flex: 4, textAlign: "right" }}>
        {/* Modifier & Price */}
        <ItemModifier productId={product?.id} />
      </div>
    </ListItem>
  );
};

const SimplifiedSection = ({
  restaurantId,
  categoryId,
  delivery,
  fromSite,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [items, setItems] = React.useState([]);
  const [
    {
      deliveryReducer: { selectedRestaurant },
      restaurantReducer: { currentRestaurant },
    },
  ] = useReducer();
  const { businessType } =
    currentRestaurant && !isEmptyObject(currentRestaurant)
      ? currentRestaurant
      : selectedRestaurant || {};

  console.log("simplifiedSectionItems", items);

  React.useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      const { data } = await API.graphql({
        query: queries.productsbyCategory,
        variables: {
          categoryID: categoryId,
          limit: 500,
        },
        authMode: "AWS_IAM",
      });
      const { productsbyCategory: { items: products } = { items: [] } } =
        data || {};

      const sortedProducts = _orderBy(
        products,
        ["product.status", "product.position"],
        ["asc", "asc"]
      );

      if (mounted) {
        setLoading(false);

        if (delivery && businessType === "RESTAURANT") {
          setItems(
            sortedProducts.filter(
              (x) =>
                x &&
                !x._deleted &&
                x.product &&
                !x.product._deleted &&
                x?.product?.status === "ACTIVE" &&
                (x.product.preorderingEnabled || x.product.deliveryEnabled)
            )
          );
        } else {
          setItems(
            sortedProducts.filter(
              (x) =>
                x &&
                !x._deleted &&
                x.product &&
                !x.product._deleted &&
                x?.product?.status === "ACTIVE"
            )
          );
        }
      }
    };

    if (categoryId) {
      fetchData();
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId, delivery]);

  if (loading) {
    return null;
  }

  return (items || []).map((item) => {
    return (
      <SimplifiedItem
        key={`item-${categoryId}-${item.id}`}
        restaurantId={restaurantId}
        categroyId={categoryId}
        item={item}
        delivery={delivery}
        fromSite={fromSite}
      />
    );
  });
};

export default SimplifiedSection;
