import React from "react";
import { Auth } from "aws-amplify";
import { Hub } from "aws-amplify";
import { Cache } from "aws-amplify";
import { API, graphqlOperation } from "aws-amplify";

import useReducer from "../reducers";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { useHistory } from "react-router-dom";

const useAuth = () => {
  const [, dispatch] = useReducer();
  const history = useHistory();

  const ga =
    window.gapi && window.gapi.auth2
      ? window.gapi.auth2.getAuthInstance()
      : null;
  const hasAuthProviders =
    typeof ga !== undefined &&
    typeof window.FB !== undefined &&
    !!ga &&
    !!window.FB;

  React.useEffect(() => {
    const createScript = () => {
      // load the sdk
      window.fbAsyncInit = fbAsyncInit;
      const script = document.createElement("script");
      script.src = "https://connect.facebook.net/en_US/sdk.js";
      script.async = true;
      script.onload = initFB;
      document.body.appendChild(script);
    };

    const createGoogleScript = () => {
      // load the Google SDK
      const script = document.createElement("script");
      script.src = "https://apis.google.com/js/platform.js";
      script.async = true;
      script.onload = initGapi;
      document.body.appendChild(script);
    };

    if (!window.FB) createScript();
    if (!ga) createGoogleScript();

    // check the current user when the App component is loaded
    const onAuthEvent = (payload) => {
      let myuser = null;
      switch (payload.event) {
        case "signIn":
          console.log("user signed in");
          Auth.currentAuthenticatedUser()
            .then((user) => {
              console.log("user", user);

              if (user.id) {
                dispatch({
                  type: "SET_IDENTITY_ID",
                  payload: { identityId: user.id },
                });
              } else {
                dispatch({
                  type: "SET_IDENTITY_ID",
                  payload: { identityId: null },
                });
              }
              myuser = user;

              const userId = user.email || user.username;

              return API.graphql({
                ...graphqlOperation(queries.getUser, { id: userId }),
                authMode: "AWS_IAM",
              });
            })
            .then((userQuery) => {
              const { data } = userQuery;
              const { getUser: userData } = data || {};
              console.log("userData", userData);
              if (userData) {
                return { data: { createUser: userData } };
              } else {
                console.log("myuser", myuser);

                const userId = myuser.email || myuser.username;

                const input = myuser.email
                  ? {
                      id: userId,
                      username: myuser.id,
                      name: myuser.name,
                    }
                  : {
                      id: userId,
                      username: myuser.attributes.sub,
                      name: myuser.attributes.name,
                    };

                return API.graphql({
                  query: mutations.createUser,
                  variables: {
                    input,
                  },
                });
              }
            })
            .then(({ data: { createUser: userData } }) => {
              dispatch({
                type: "LOGIN_SET_USER",
                payload: { user: userData },
              });
              dispatch({
                type: "LOGIN_SET_AUTHSTATE",
                payload: { authState: "signedIn" },
              });

              const fromUrl = Cache.getItem("fromUrl");
              if (fromUrl) {
                Cache.removeItem("fromUrl");
                history.replace(fromUrl);
              }
            })
            .catch((err) => console.log(err));
          break;
        case "signIn_failure":
          console.log("user sign in failed");
          dispatch({
            type: "LOGIN_SET_AUTHSTATE",
            payload: { authState: "signIn" },
          });
          dispatch({
            type: "LOGIN_SET_USER",
            payload: { user: null },
          });
          break;
        case "signOut":
          console.log("user signed out");
          dispatch({
            type: "LOGIN_SET_AUTHSTATE",
            payload: { authState: "signIn" },
          });
          dispatch({
            type: "LOGIN_SET_USER",
            payload: { user: null },
          });
          dispatch({ type: "LOGIN_SET_VIEW", payload: { view: "signin" } });
          dispatch({ type: "LOGIN_CLOSE" });
          break;
        case "configured":
          console.log("the Auth module is configured");
          break;
        default:
          break;
      }
    };

    const HubListener = () => {
      Hub.listen("auth", (data) => {
        const { channel, payload } = data;
        if (channel === "auth") {
          onAuthEvent(payload);
        }
      });
    };

    HubListener();

    return () => {
      Hub.remove("auth");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (hasAuthProviders) {
      try {
        Auth.currentCredentials().then((credentials) => {
          console.log("credentials", credentials);

          if (!credentials.authenticated) {
            console.log("identityId", credentials.identityId);
            dispatch({
              type: "SET_IDENTITY_ID",
              payload: { identityId: credentials.identityId },
            });
          } else {
            dispatch({
              type: "SET_IDENTITY_ID",
              payload: { identityId: null },
            });
          }
        });
      } catch (error) {
        console.log("error", error);
        dispatch({ type: "SET_IDENTITY_ID", payload: { identityId: null } });
      }

      let myuser = null;
      Auth.currentAuthenticatedUser()
        .then((user) => {
          console.log("user", user);

          if (user.id) {
            dispatch({
              type: "SET_IDENTITY_ID",
              payload: { identityId: user.id },
            });
          } else {
            dispatch({
              type: "SET_IDENTITY_ID",
              payload: { identityId: null },
            });
          }
          myuser = user;

          const userId = user.email || user.username;

          return API.graphql({
            ...graphqlOperation(queries.getUser, { id: userId }),
            authMode: "AWS_IAM",
          });
        })
        .then((userQuery) => {
          const { data } = userQuery;
          const { getUser: userData } = data || {};
          console.log("userData", userData);
          if (userData) {
            return { data: { createUser: userData } };
          } else {
            console.log("myuser", myuser);

            const userId = myuser.email || myuser.username;

            const input = myuser.email
              ? {
                  id: userId,
                  username: myuser.id,
                  name: myuser.name,
                }
              : {
                  id: userId,
                  username: myuser.attributes.sub,
                  name: myuser.attributes.name,
                };

            return API.graphql({
              query: mutations.createUser,
              variables: {
                input,
              },
            });
          }
        })
        .then(({ data: { createUser: userData } }) => {
          dispatch({
            type: "LOGIN_SET_USER",
            payload: { user: userData },
          });
          dispatch({
            type: "LOGIN_SET_AUTHSTATE",
            payload: { authState: "signedIn" },
          });
        })
        .catch((e) => {
          console.log(e);
          dispatch({
            type: "LOGIN_SET_AUTHSTATE",
            payload: { authState: "signIn" },
          });
          dispatch({
            type: "LOGIN_SET_USER",
            payload: { user: null },
          });
        });
    }
  }, [hasAuthProviders]);

  const initFB = () => {
    const fb = window.FB;
    console.log("FB SDK initialized", fb);
  };

  const fbAsyncInit = () => {
    // init the fb sdk client
    const fb = window.FB;
    fb.init({
      appId: process.env.REACT_APP_FB_APP_ID,
      cookie: true,
      xfbml: true,
      version: "v2.11",
    });
  };

  const initGapi = () => {
    // init the Google SDK client
    const g = window.gapi;
    g.load("auth2", function () {
      g.auth2.init({
        client_id: process.env.REACT_APP_GA_CLIENT_ID,
        // authorized scopes
        scope: "profile email openid",
      });
    });
  };
};

export default useAuth;
