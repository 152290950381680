import React from "react";
import { I18n } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import MoneyTwoToneIcon from "@material-ui/icons/MoneyTwoTone";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import SvgIcon from "@material-ui/core/SvgIcon";
import { API, graphqlOperation } from "aws-amplify";
import { useSnackbar } from "notistack";

import useReducer from "../../reducers";
import { toCurrency } from "../../util";
import * as mutations from "../../graphql/mutations";
import { ReactComponent as GPay } from "../../svg/google-pay.svg";
import { ReactComponent as APay } from "../../svg/Apple-Pay.svg";
import { ReactComponent as Amex } from "../../svg/american-express.svg";
import { ReactComponent as MCard } from "../../svg/mastercard.svg";
import { ReactComponent as Visa } from "../../svg/visa.svg";
import PaypalButton from "./PaypalButton";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
  },
  title: {
    textAlign: "center",
    margin: theme.spacing(1, 0),
  },
}));

const PaymentMethod = ({
  tipsEnabled,
  currency,
  cardPayments = false,
  cashPayments = false,
  paypalPayments = false,
  handlePay,
  handlePaypalPay,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [
    {
      orderReducer: { currentOrder },
      checkoutReducer: { amountToPay },
      restaurantReducer: { currentRestaurant },
    },
    dispatch,
  ] = useReducer();
  const tokens =
    currentRestaurant && currentRestaurant.notificationTokens
      ? JSON.parse(currentRestaurant.notificationTokens)
      : [];
  const nativeTokens =
    currentRestaurant && currentRestaurant.nativeNotificationTokens
      ? JSON.parse(currentRestaurant.nativeNotificationTokens)
      : [];

  const handleSendExpPush = async (data) => {
    console.log("send notification");

    let apiName = "api616cdc2a";
    let path = "/notifications/expush";

    try {
      const result = await API.post(apiName, path, {
        body: { ...data },
      });
      console.log("result", result);
    } catch (error) {
      console.log("handleSendExpPush", error);
    }
  };

  const setPaymentMethod = (value) => async () => {
    dispatch({
      type: "CHECKOUT_SET_PAYMENT_METHOD",
      payload: { paymentMethod: value },
    });

    if (value === "card") {
      if (tipsEnabled) {
        dispatch({ type: "CHECKOUT_SET_STEP", payload: { checkoutStep: 4 } });
      } else {
        await handlePay();
      }
    } else if (value === "paypal") {
      if (tipsEnabled) {
        dispatch({ type: "CHECKOUT_SET_STEP", payload: { checkoutStep: 4 } });
      }
    } else {
      const title = `Mesa ${
        currentOrder && currentOrder.table && currentOrder.table.title
          ? currentOrder.table.title
          : currentOrder.tableID
      }`;
      const message = `Quiere pagar en efectivo`;

      const input = {
        type: "CASH_PAYMENT",
        channel: currentOrder.restaurantID,
        title,
        body: message,
        data: JSON.stringify({
          url: `/orders/sales/${currentOrder.id}`,
          orderID: currentOrder.id,
        }),
        payload: JSON.stringify({
          orderID: currentOrder.id,
          restaurantID: currentOrder.restaurantID,
          title,
          message,
        }),
        tokens: tokens && tokens.length ? JSON.stringify([...tokens]) : null,
      };

      const { data } = await API.graphql({
        ...graphqlOperation(mutations.createNotification, {
          input,
        }),
        authMode: "AWS_IAM",
      });
      const { createNotification } = data || {};
      console.log("createNotification", createNotification);
      await handleSendExpPush({
        ...input,
        tokens:
          nativeTokens && nativeTokens.length
            ? JSON.stringify([...nativeTokens])
            : null,
      });
      enqueueSnackbar(
        I18n.get("Ok, your waiter is comming! Wait a minute please."),
        {
          variant: "info",
          autoHideDuration: 5000,
        }
      );

      history.push("/payment/pending");
    }
  };

  return (
    <>
      <Box flex={1} display="flex" flexDirection="column">
        <Typography variant="h5" className={classes.title}>
          {I18n.get("Select payment method")}
        </Typography>
        <Box mx={1}>
          {cardPayments ? (
            <Button
              fullWidth
              size="large"
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={setPaymentMethod("card")}
            >
              <Box
                width="100%"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="button">{I18n.get("Pay with")}</Typography>
                <Box display="flex" alignItems="center" justifyContent="center">
                  <SvgIcon
                    style={{ width: 32, fill: "#000" }}
                    viewBox="0 0 165.52107 105.9651"
                    component={APay}
                  />
                  <SvgIcon
                    style={{ width: 32, marginLeft: 4 }}
                    viewBox="0 0 425 272"
                    component={GPay}
                  />

                  <SvgIcon
                    style={{ width: 32 }}
                    viewBox="0 0 504 504"
                    component={Amex}
                  />
                  <SvgIcon
                    style={{ width: 32 }}
                    viewBox="0 0 504 504"
                    component={MCard}
                  />
                  <SvgIcon
                    style={{ width: 32 }}
                    viewBox="0 0 504 504"
                    component={Visa}
                  />
                </Box>
              </Box>
            </Button>
          ) : null}
          {cashPayments ? (
            <Button
              fullWidth
              size="large"
              variant="outlined"
              color="primary"
              className={classes.button}
              startIcon={<MoneyTwoToneIcon />}
              onClick={setPaymentMethod("cash")}
            >
              {I18n.get("Cash")}
            </Button>
          ) : null}
          {paypalPayments ? (
            tipsEnabled ? (
              <Button
                fullWidth
                size="large"
                variant="outlined"
                color="primary"
                className={classes.button}
                onClick={setPaymentMethod("paypal")}
              >
                <img
                  src="assets/paypal.png"
                  border="0"
                  alt="PayPal Logo"
                  style={{ height: 24 }}
                />
              </Button>
            ) : (
              <Box px={2}>
                <PaypalButton
                  amount={amountToPay}
                  currency={currency}
                  afterApprove={handlePaypalPay}
                />
              </Box>
            )
          ) : null}
        </Box>
      </Box>
      <Box
        mx={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h6">{I18n.get("Amount to pay")}</Typography>
        <Typography variant="h6">
          ${toCurrency((amountToPay || 0).toString())}
        </Typography>
      </Box>
      <Box p={2} textAlign="center">
        <Button
          onClick={() =>
            dispatch({
              type: "CHECKOUT_SET_STEP",
              payload: { checkoutStep: 0 },
            })
          }
          size="large"
          className={classes.backButton}
        >
          {I18n.get("Back")}
        </Button>
      </Box>
    </>
  );
};

export default PaymentMethod;
