import React from "react";
import { Route, Redirect } from "react-router-dom";

import useReducer from "../reducers";
import Loading from "./Loading";

function ProtectedRoute({ children, ...rest }) {
  const [{ orderReducer, loadingReducer }] = useReducer();
  const { currentOrder = {} } = orderReducer || {};

  if (loadingReducer.loadingApp) {
    return <Loading force />;
  }

  return (
    <Route
      {...rest}
      render={({ location }) =>
        currentOrder &&
        !(currentOrder._deleted || currentOrder.status === "CLOSED") ? (
          children
        ) : (
          <Redirect to={{ pathname: "/main", state: { from: location } }} />
        )
      }
    />
  );
}

export default ProtectedRoute;
