import React from "react";
import { I18n } from "aws-amplify";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import InputBase from "@material-ui/core/InputBase";

import useReducer from "../../reducers";
import { IconButton } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import useStyles from "./Search.styles";

const SearchComponent = ({ forwardedRef }) => {
  const classes = useStyles();
  const history = useHistory();
  const [
    {
      searchReducer: { searchQuery },
    },
    dispatch,
  ] = useReducer();

  const onChange = (event) => {
    const { value: searchQuery } = event.target;
    dispatch({ type: "SEARCH_SET_QUERY", payload: { searchQuery } });
  };

  const back = (e) => {
    e.stopPropagation();
    dispatch({ type: "SEARCH_SET_QUERY", payload: { searchQuery: "" } });

    history.goBack();
  };

  return (
    <Paper ref={forwardedRef} square className={classes.paper}>
      <div className={classes.search}>
        <div className={classes.searchIcon}>
          <SearchIcon />
        </div>
        <InputBase
          autoFocus
          fullWidth
          placeholder={I18n.get("Search…")}
          onChange={onChange}
          value={searchQuery}
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          inputProps={{ "aria-label": "search" }}
        />
      </div>

      <IconButton size="small" onClick={back}>
        <CloseIcon fontSize="inherit" />
      </IconButton>
    </Paper>
  );
};

export default React.forwardRef((props, ref) => (
  <SearchComponent {...props} forwardedRef={ref} />
));
