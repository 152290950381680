import React from "react";
import _orderBy from "lodash/orderBy";
import { I18n } from "aws-amplify";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import { API, graphqlOperation } from "aws-amplify";

import * as queries from "../../graphql/queries";
import OptionControl from "./OptionControl";

function handleRequiredMessage(minSelection) {
  switch (minSelection) {
    case 2:
      return "Two selections are required";
    case 3:
      return "Three selections are required";
    case 4:
      return "Four selections are required";
    case 5:
      return "Five selections are required";
    default:
      return "Select an option";
  }
}

const ModifierSelect = ({
  modifier = {},
  handleChange,
  selectedOptions = [],
  hasError,
}) => {
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      const { data } = await API.graphql({
        ...graphqlOperation(queries.optionsByModifier, {
          modifierID: modifier.id,
          limit: 500,
          sortDirection: "ASC",
          filter: { status: { eq: "ACTIVE" } },
        }),
        authMode: "AWS_IAM",
      });
      const { optionsByModifier: { items: options } = {} } = data || {};

      if (mounted) {
        setOptions(options);
      }
    };

    fetchData();

    return () => {
      mounted = false;
    };
  }, [modifier.id]);

  const sortedOptions = _orderBy(
    options.filter((x) => !x._deleted && x.status === "ACTIVE"),
    ["position", "title"],
    ["asc", "asc"]
  );

  const sum = selectedOptions.reduce((sum, val) => sum + (val.qty || 1), 0);

  return (
    <FormControl
      fullWidth
      required={!!modifier.minSelection}
      component="fieldset"
      error={hasError}
    >
      <FormLabel component="legend">
        {modifier.minSelection === 0
          ? I18n.get("Optional")
          : I18n.get(handleRequiredMessage(modifier.minSelection))}
      </FormLabel>
      <FormGroup>
        {sortedOptions && sortedOptions.length
          ? sortedOptions.map((option) => (
              <OptionControl
                key={option.id}
                option={option}
                handleChange={handleChange}
                selectedOptions={selectedOptions}
                modifier={modifier}
                sum={sum}
              />
            ))
          : null}
      </FormGroup>
    </FormControl>
  );
};

export default ModifierSelect;
