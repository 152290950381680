import React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Slide from "@material-ui/core/Slide";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import SearchIcon from "@material-ui/icons/Search";
import { useSnackbar } from "notistack";
import { useNetwork } from "react-use";
import SvgIcon from "@material-ui/core/SvgIcon";
import IconButton from "@material-ui/core/IconButton";
import { I18n } from "aws-amplify";
import Skeleton from "@material-ui/lab/Skeleton";
import { makeStyles } from "@material-ui/core/styles";
import Img from "react-image";

import MobileNavigation from "./MobileNavigation/MobileNavigation";
import NavCodeScanButton from "./NavCodeScanButton";
import { useHistory, useLocation } from "react-router-dom";
import useReducer from "../reducers";
import NavProfileButton from "./NavProfileButton";
import { ReactComponent as LogoSVG } from "../svg/2dine.svg";
import { getPictureUrl, JSONParse, isEmptyObject } from "../util";
import { ButtonBase } from "@material-ui/core";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  image: {
    // width: '100%',
    height: 40,
    maxWidth: 120,
    objectFit: "contain",
    objectPosition: "center",
  },
  skeleton: {
    marginRight: theme.spacing(2),
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      maxWidth: 414,
      left: 0,
      right: "auto",
    },
  },
}));

function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();
  const elevationTrigger = useScrollTrigger({
    disableHysteresis: true,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {React.cloneElement(children, {
        elevation: elevationTrigger ? 4 : 0,
      })}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
};

const Navigator = ({ fromSite = false }) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { online } = useNetwork();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [imageUrl, setImageUrl] = React.useState("");
  const [link, setLink] = React.useState({ pathname: "/" });
  const [
    {
      siteReducer: { onlyDelivery },
      orderReducer: { currentOrder },
      restaurantReducer: { currentRestaurant },
      deliveryReducer: { selectedRestaurant },
    },
    dispatch,
  ] = useReducer();
  const { businessType } = selectedRestaurant || {};

  const fromDelivery = location.pathname.startsWith("/d");
  const fromBond = location.pathname.startsWith("/bono");

  React.useEffect(() => {
    let mounted = true;
    if (
      onlyDelivery ||
      location.pathname.startsWith("/d") ||
      location.pathname.startsWith("/payment")
    ) {
      const picture =
        selectedRestaurant && selectedRestaurant.picture
          ? JSONParse(selectedRestaurant.picture)
          : {};

      if (mounted) {
        setImageUrl(
          selectedRestaurant && selectedRestaurant.picture
            ? getPictureUrl({ ...picture, size: "0x71" })
            : ""
        );

        // if (["MARKET", "TRAVEL"].includes(businessType)) {
        setLink({
          pathname: fromSite ? `/d` : `/d/r/${selectedRestaurant.id}`,
        });
        // } else {
        //   setLink({ pathname: `/d` });
        // }
      }
    } else if (fromBond || location.pathname.startsWith("/main")) {
      if (mounted) {
        setImageUrl("");
        setLink({ pathname: `/main` });
      }
    } else if (currentRestaurant && !isEmptyObject(currentRestaurant)) {
      const picture =
        currentRestaurant && currentRestaurant.picture
          ? JSONParse(currentRestaurant.picture)
          : {};
      if (mounted) {
        setImageUrl(
          currentRestaurant && currentRestaurant.picture
            ? getPictureUrl({ ...picture, size: "0x71" })
            : ""
        );
        setLink({ pathname: `/` });
      }
    } else {
      if (mounted) {
        setImageUrl("");
        setLink({ pathname: `/main` });
      }
    }

    return () => {
      mounted = false;
    };
  }, [
    businessType,
    currentRestaurant,
    fromBond,
    location.pathname,
    selectedRestaurant,
    fromSite,
    onlyDelivery,
  ]);

  React.useEffect(() => {
    let notikey;
    if (online !== undefined && !online) {
      notikey = enqueueSnackbar(
        I18n.get("No connection! Trying to reconnect."),
        {
          variant: "error",
          persist: true,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        }
      );
    } else {
      closeSnackbar(notikey);
    }
  }, [closeSnackbar, enqueueSnackbar, online]);

  return (
    <>
      <Helmet>
        <link
          id="favicon"
          rel="icon"
          href={imageUrl ? imageUrl : "/android/mipmap-mdpi/ic_launcher.png"}
        />
        <link
          rel="apple-touch-icon"
          href={imageUrl ? imageUrl : "/android/mipmap-mdpi/ic_launcher.png"}
        />
      </Helmet>

      <HideOnScroll>
        <AppBar className={classes.appBar} color="inherit">
          <Toolbar>
            <MobileNavigation fromSite={fromSite} />

            <ButtonBase
              disableRipple
              onClick={() => {
                dispatch({ type: "TABS_RESET" });
                history.push(link);
              }}
            >
              <Img
                src={[imageUrl]}
                alt={currentRestaurant.name}
                loader={
                  <Skeleton
                    variant="rect"
                    animation="wave"
                    width={80}
                    height={40}
                    className={classes.skeleton}
                  />
                }
                unloader={
                  <SvgIcon
                    color="primary"
                    component={LogoSVG}
                    viewBox="0 0 409.32 172.91"
                    style={{ width: 48, height: 20 }}
                  />
                }
                className={classes.image}
              />
            </ButtonBase>

            <Box flex={1} />

            <IconButton
              aria-label="theme"
              onClick={() => {
                history.push({
                  pathname: "/search",
                  search: fromDelivery
                    ? `?origin=delivery`
                    : fromBond
                    ? "?origin=bond"
                    : "",
                  state: { background: location, delivery: fromDelivery },
                });
              }}
            >
              <SearchIcon fontSize="default" />
            </IconButton>
            {currentOrder || (fromSite && onlyDelivery) ? (
              <NavProfileButton />
            ) : (
              <NavCodeScanButton />
            )}
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </>
  );
};

export default Navigator;
