import { Auth } from "aws-amplify";

const awsConfig = {
  url: process.env.REACT_APP_EXTENSIONS_API,
  region: "us-east-1",
  auth: {
    type: "AWS_IAM",
    credentials: () => Auth.currentCredentials(),
  },
  disableOffline: true,
};

export default awsConfig;
