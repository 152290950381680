import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import { useParams, Redirect, useHistory } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import Img from "react-image";
import VisibilitySensor from "react-visibility-sensor";
import Skeleton from "@material-ui/lab/Skeleton";
import CloseIcon from "@material-ui/icons/Close";
import {
  Typography,
  DialogTitle,
  DialogContent,
  Box,
  Card,
  CardMedia,
  IconButton,
} from "@material-ui/core";

import * as queries from "../graphql/queries";
import { getPictureUrl, JSONParse } from "../util";
import Photo from "../components/Photo";
import BondSelection from "../components/Bond/BondSelection";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  paper: {
    width: "100%",
  },
  media: {
    height: 160,
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "center",
  },
  button: {
    backgroundImage: "linear-gradient(147deg, #fe8a39 0%, #fd3838 74%)",
    boxShadow: "0px 4px 32px rgba(252, 56, 56, 0.4)",
    borderRadius: 100,
    paddingLeft: 24,
    paddingRight: 24,
    color: "#fff",
  },
  ribbon: {
    position: "absolute",
    top: "15px",
    right: 0,
    padding: "8px 10px",
    background: "#ee4035", //#ee4035
    color: "#fff",
    boxShadow: "-1px 2px 3px rgba(0,0,0,.3)",
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Bond = () => {
  const classes = useStyles();
  const history = useHistory();
  const { restaurantId } = useParams();
  const [item, setItem] = React.useState({});
  const [loading, setLoading] = React.useState(true);

  const picture = item && item.cardPicture ? JSONParse(item.cardPicture) : {};
  const imageUrl =
    item && item.cardPicture
      ? getPictureUrl({ ...picture, size: "571x0" })
      : null;

  React.useEffect(() => {
    let mounted = true;

    const fetchData = async () => {
      const { data } = await API.graphql({
        ...graphqlOperation(queries.getRestaurant, {
          id: restaurantId,
          filter: {
            status: {
              eq: "ACTIVE",
            },
          },
        }),
        authMode: "AWS_IAM",
      });
      const { getRestaurant: response } = data || {};

      if (mounted) {
        setLoading(false);

        if (!response || !response.bondEnabled) {
          history.replace("/bonos");
        } else {
          setItem(response);
        }
      }
    };

    if (restaurantId) {
      fetchData();
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurantId]);

  const handleClose = () => {
    history.goBack();
  };

  if (!restaurantId) {
    return <Redirect to="/main" />;
  }

  return (
    <>
      <Dialog
        container={() => document.getElementById("mainBox")}
        open={true}
        onClose={handleClose}
        TransitionComponent={Transition}
        classes={{ paper: classes.paper }}
        scroll={"body"}
        style={{
          position: "absolute",
        }}
        BackdropProps={{
          style: {
            position: "absolute",
          },
        }}
      >
        <IconButton
          color="primary"
          style={{ position: "absolute", top: 2, left: 2, zIndex: 1 }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>

        <Box className={classes.ribbon}>
          <Img
            src={[`/assets/comecdmx-white.png`]}
            alt={"#ComeCDMX"}
            loader={
              <Skeleton
                variant="rect"
                animation="wave"
                width={96}
                height={24}
              />
            }
            unloader={<Photo />}
            style={{ width: 96 }}
          />
        </Box>

        <Card>
          <CardMedia className={classes.media} title={item && item.name}>
            <VisibilitySensor>
              <Img
                src={[imageUrl]}
                alt={item.name}
                loader={
                  <Skeleton
                    variant="rect"
                    animation="wave"
                    width={"100%"}
                    height={160}
                  />
                }
                unloader={<Photo />}
                className={classes.image}
              />
            </VisibilitySensor>
          </CardMedia>
        </Card>
        {!loading ? (
          <DialogTitle id="scroll-dialog-title">
            {(item && item.name) || "Bono Gastronómico"}
          </DialogTitle>
        ) : (
          <div style={{ padding: "16px 24px" }}>
            <Skeleton
              variant="rect"
              animation="wave"
              height={32}
              width={"40%"}
            />
          </div>
        )}
        <DialogContent>
          <Typography variant="body2" style={{ textAlign: "justify" }}>
            {`Compra tu bono gastronómico para tu consumo futuro en sucursal. `}
          </Typography>
          <Typography
            variant="body2"
            style={{ marginTop: 8, textAlign: "justify" }}
          >
            {`Apoya
          a ${
            (item && item.name) || "tu restaurante favorito"
          } a seguir dando empleo durante la pandemia a las familias que dependen de la industria restaurantera.`}
          </Typography>

          <BondSelection item={item} restaurantId={restaurantId} />

          <Typography
            variant="body2"
            color="textSecondary"
            style={{ marginBottom: 16 }}
          >
            * Haz válido tu bono a partir de mayo 2020 en sucursal
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Bond;
