import React from "react";
import { I18n } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Box, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import CurrencyInput from "../CurrencyInput";
import {
  toCurrency,
  getDigitsFromValue,
  getTotal,
  getRemainingAmount,
} from "../../util";
import NumericKeyboard from "../NumericKeyboard";
import useReducer from "../../reducers";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .simple-keyboard.hg-theme-default.myTheme": {
      borderRadius: theme.shape.borderRadius,
    },
    "& .simple-keyboard.hg-layout-default .hg-button": {
      background: theme.palette.background.default,
      color: theme.palette.text.primary,
      boxShadow: theme.shadows[1],
      border: "none",
      ...theme.typography.button,
      fontSize: "1rem",
    },
  },
  title: {
    textAlign: "center",
    margin: theme.spacing(1, 0),
  },
  button: {
    width: 120,
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  inputInput: {
    textAlign: "right",
    fontSize: 24,
    fontWeight: 700,
  },
}));

const CustomAmount = () => {
  const classes = useStyles();
  const keyboardRef = React.useRef();
  const [
    {
      orderReducer: {
        orderAmount,
        orderTaxes,
        amountPaid,
        orderDiscountAmount,
      },
      checkoutReducer: { amountToPay },
    },
    dispatch,
  ] = useReducer();

  React.useEffect(() => {
    if (keyboardRef && keyboardRef.current && amountToPay) {
      keyboardRef.current.setInput((amountToPay || 0).toString());
    }
  }, [amountToPay]);

  const total = React.useMemo(() => getTotal(orderAmount, orderTaxes), [
    orderAmount,
    orderTaxes,
  ]);

  const remainingAmount = React.useMemo(
    () => getRemainingAmount(total, amountPaid, orderDiscountAmount),
    [total, amountPaid, orderDiscountAmount]
  );

  const onChange = (value) => {
    const numericValue = +getDigitsFromValue(value);
    let topay = value;
    if (+numericValue > remainingAmount) {
      topay = (remainingAmount || 0).toString();

      keyboardRef.current.setInput(topay);
    }
    dispatch({
      type: "CHECKOUT_SET_A2P",
      payload: {
        amountToPay: +topay,
      },
    });
  };

  const onKeyPress = (button) => {};

  return (
    <>
      <Box flex={1} display="flex" flexDirection="column">
        <Typography variant="h5" className={classes.title}>
          {I18n.get("Custom Amount")}
        </Typography>
        <Box my={2} mx={1} flex={1}></Box>
        <Box px={1} className={classes.root}>
          <CurrencyInput
            Component={TextField}
            variant="filled"
            fullWidth
            placeholder={I18n.get("Amount to pay")}
            InputProps={{
              classes: { input: classes.inputInput },
              readOnly: true,
            }}
            value={amountToPay ? toCurrency((amountToPay || 0).toString()) : ""}
          />
          <NumericKeyboard
            keyboardRef={keyboardRef}
            onChange={onChange}
            onKeyPress={onKeyPress}
          />
        </Box>
      </Box>
      <Box p={2} textAlign="center">
        <Button
          onClick={() =>
            dispatch({
              type: "CHECKOUT_SET_STEP",
              payload: { checkoutStep: 0 },
            })
          }
          size="large"
          className={classes.backButton}
        >
          {I18n.get("Back")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() =>
            dispatch({
              type: "CHECKOUT_SET_STEP",
              payload: { checkoutStep: 3 },
            })
          }
        >
          {I18n.get("Next")}
        </Button>
      </Box>
    </>
  );
};

export default CustomAmount;
