import React from "react";
import { Box } from "@material-ui/core";
import { Slider } from "@2dine/promos";
import { useHistory, useRouteMatch } from "react-router-dom";
// import Skeleton from "@material-ui/lab/Skeleton";

import MainCategories from "../components/MainCategories";
import MainMenus from "../components/MainMenus";
import { isSafariBrowser } from "../util";
import useReducer from "../reducers";

const Home = () => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [
    {
      restaurantReducer: { currentRestaurant },
    },
  ] = useReducer();

  return (
    <>
      <Box flex={1} mb={7}>
        <div style={{ marginLeft: -16, marginRight: -16 }}>
          <Slider
            restaurantID={currentRestaurant?.id}
            history={history}
            path={path}
          />
        </div>

        <MainMenus />

        <MainCategories />

        {/* <Box mt={2}>
          <Typography variant="body2">
            Prohibida la venta de bebidas alcohólicas a menores de Edad.
          </Typography>
        </Box> */}

        {isSafariBrowser ? (
          <div id="space" style={{ minHeight: 64, height: 64 }} />
        ) : null}
      </Box>
    </>
  );
};

export default Home;
