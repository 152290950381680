import React from "react";
import { I18n } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import TextField from "@material-ui/core/TextField";
import Collapse from "@material-ui/core/Collapse";

import CurrencyInput from "../CurrencyInput";
import useReducer from "../../reducers";
import {
  getTipCurrency,
  toCurrency,
  getDigitsFromValue,
  JSONParse,
  isEmptyObject,
} from "../../util";
import NumericKeyboard from "../NumericKeyboard";
import PaypalButton from "./PaypalButton";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
  },
  title: {
    textAlign: "center",
    margin: theme.spacing(1, 0),
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  inputInput: {
    textAlign: "right",
    fontSize: 24,
    fontWeight: 700,
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
}));

const Tip = ({ handlePay, handlePaypalPay }) => {
  const classes = useStyles();
  const keyboardRef = React.useRef();
  const [
    {
      restaurantReducer: { currentRestaurant },
      checkoutReducer: { amountToPay, tipAmount, tipType, paymentMethod },
    },
    dispatch,
  ] = useReducer();
  const { tips, currency } = currentRestaurant || {};
  let parsedTips = tips ? JSONParse(tips) : null;
  parsedTips = parsedTips && !isEmptyObject(parsedTips) ? parsedTips : null;
  console.log("tips", parsedTips);

  const handleTipType = React.useCallback(
    (value) => () => {
      dispatch({ type: "CHECKOUT_SET_TIP_TYPE", payload: { tipType: value } });
      const amount = getTipCurrency(value, amountToPay, parsedTips);
      setTipAmount(amount);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [amountToPay]
  );

  React.useEffect(() => {
    if (amountToPay && !tipAmount) {
      handleTipType(tipType)();
    }
  }, [handleTipType, amountToPay, tipAmount, tipType]);

  const setTipAmount = (value) => {
    const numericValue = +getDigitsFromValue(value);
    dispatch({
      type: "CHECKOUT_SET_TIP_AMOUNT",
      payload: { tipAmount: numericValue },
    });
    keyboardRef.current.setInput(value);
  };

  const variant = (value) => (tipType === value ? "contained" : "outlined");

  const onChange = (input) => {
    const currencyValue = toCurrency(input);
    setTipAmount(currencyValue);
  };

  const onKeyPress = (button) => {};

  return (
    <>
      <Box flex={1} display="flex" flexDirection="column">
        <Typography variant="h5" className={classes.title}>
          {I18n.get("Tip")}
        </Typography>
        <Box className={classes.root}>
          {parsedTips ? (
            <ButtonGroup
              size="large"
              color="primary"
              aria-label="large outlined primary button group"
            >
              {Object.keys(parsedTips).map((t, index) => {
                return (
                  <Button
                    key={index}
                    variant={variant(index)}
                    onClick={handleTipType(index)}
                  >
                    {t}
                  </Button>
                );
              })}
              <Button variant={variant(3)} onClick={handleTipType(3)}>
                {I18n.get("Custom")}
              </Button>
            </ButtonGroup>
          ) : (
            <ButtonGroup
              size="large"
              color="primary"
              aria-label="large outlined primary button group"
            >
              <Button variant={variant(0)} onClick={handleTipType(0)}>
                10%
              </Button>
              <Button variant={variant(1)} onClick={handleTipType(1)}>
                15%
              </Button>
              <Button variant={variant(2)} onClick={handleTipType(2)}>
                20%
              </Button>

              <Button variant={variant(3)} onClick={handleTipType(3)}>
                {I18n.get("Custom")}
              </Button>
            </ButtonGroup>
          )}
          <Box mt={1}>
            <Button color="secondary" size="small" onClick={handleTipType(4)}>
              {I18n.get("No tip")}
            </Button>
          </Box>
        </Box>
        <Box m={1}>
          <CurrencyInput
            Component={TextField}
            variant="filled"
            fullWidth
            placeholder={I18n.get("Tip amount")}
            InputProps={{
              classes: { input: classes.inputInput },
              readOnly: true,
            }}
            value={tipAmount ? toCurrency((tipAmount || 0).toString()) : ""}
          />
          <Collapse in={tipType === 3}>
            <NumericKeyboard
              keyboardRef={keyboardRef}
              onChange={onChange}
              onKeyPress={onKeyPress}
            />
          </Collapse>
        </Box>
      </Box>
      <Box p={2} textAlign="center">
        <Button
          onClick={() =>
            dispatch({
              type: "CHECKOUT_SET_STEP",
              payload: { checkoutStep: 3 },
            })
          }
          size="large"
          className={classes.backButton}
        >
          {I18n.get("Back")}
        </Button>
        {paymentMethod === "paypal" ? (
          <>
            <Box
              mb={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography>
                {I18n.get("Complete your payment with PayPal")} ${" "}
                {toCurrency((amountToPay + tipAmount || 0).toString())}
              </Typography>
            </Box>
            <Box px={2}>
              <PaypalButton
                amount={amountToPay + (tipAmount || 0)}
                currency={currency || "MXN"}
                afterApprove={handlePaypalPay}
              />
            </Box>
          </>
        ) : (
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handlePay}
          >
            {I18n.get("Pay")} $
            {toCurrency((amountToPay + tipAmount || 0).toString())}
          </Button>
        )}
      </Box>
    </>
  );
};

export default Tip;
