import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { I18n } from "aws-amplify";
import Typography from "@material-ui/core/Typography";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import { API, graphqlOperation } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import * as mutations from "../graphql/mutations";
import useReducer from "../reducers";
import businessLanguage from "../lib/businessLanguage";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    // overflow: 'hidden'
    marginTop: theme.spacing(2),
  },
  gridList: {
    width: "100%",
    // height: 450,
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  listRoot: {
    overflow: "visible",
  },
  gridListTile: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[4],
  },
  titleBar: {
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
      "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
}));

const Help = () => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [
    {
      orderReducer: { currentOrder },
      restaurantReducer: { currentRestaurant },
    },
  ] = useReducer();
  const btype =
    currentRestaurant && currentRestaurant.businessType
      ? currentRestaurant.businessType
      : "RESTAURANT";
  const tokens =
    currentRestaurant && currentRestaurant.notificationTokens
      ? JSON.parse(currentRestaurant.notificationTokens)
      : [];
  const nativeTokens =
    currentRestaurant && currentRestaurant.nativeNotificationTokens
      ? JSON.parse(currentRestaurant.nativeNotificationTokens)
      : [];

  const tileData = [
    {
      img: "/assets/waitress.jpg",
      title: "Call your server",
      subtitle: "Ask for your server",
      type: "SERVER",
      businessType: "RESTAURANT",
    },
    {
      img: "/assets/cash.jpg",
      title: "Cash payment",
      subtitle: "Ask for cash payment of your order",
      type: "CASH_PAYMENT",
      businessType: "RESTAURANT",
    },
    {
      img: "/assets/concierge.jpg",
      title: "Concierge",
      subtitle: "Call to concierge",
      type: "CONCIERGE",
      businessType: "HOTEL",
      featured: true,
    },
    {
      img: "/assets/faqs.jpg",
      title: "FAQs",
      subtitle: "Find an answer to your questions",
      route: "/faqs",
      featured: true,
    },
  ];

  const handleSendExpPush = async (data) => {
    console.log("send notification");

    let apiName = "api616cdc2a";
    let path = "/notifications/expush";

    try {
      const result = await API.post(apiName, path, {
        body: { ...data },
      });
      console.log("result", result);
    } catch (error) {
      console.log("handleSendExpPush", error);
    }
  };

  const handleClickTile = (value) => async () => {
    if (!value.route) {
      const title = `Ubicación ${
        currentOrder && currentOrder.table && currentOrder.table.title
          ? currentOrder.table.title
          : currentOrder.tableID
      }`;
      const message =
        value.type === "SERVER"
          ? `Calling waiter`
          : value.type === "CONCIERGE"
          ? "Calling concierge"
          : "Wants to pay with cash";

      const input = {
        type: value.type,
        channel: currentOrder.restaurantID,
        title,
        body: I18n.get(message),
        data: JSON.stringify({
          url: `/orders/sales/${currentOrder.id}`,
          orderID: currentOrder.id,
        }),
        payload: JSON.stringify({
          orderID: currentOrder.id,
          restaurantID: currentOrder.restaurantID,
          title,
          message: I18n.get(message),
        }),
        tokens: tokens && tokens.length ? JSON.stringify([...tokens]) : null,
      };

      const { data } = await API.graphql({
        ...graphqlOperation(mutations.createNotification, {
          input,
        }),
        authMode: "AWS_IAM",
      });
      const { createNotification } = data || {};
      console.log("createNotification", createNotification);
      await handleSendExpPush({
        ...input,
        tokens:
          nativeTokens && nativeTokens.length
            ? JSON.stringify([...nativeTokens])
            : null,
      });
      enqueueSnackbar(
        I18n.get(
          businessLanguage[btype][
            "Ok, your waiter is comming! Wait a minute please."
          ]
        ),
        {
          variant: "info",
          autoHideDuration: 5000,
        }
      );
    } else {
      history.push(value.route);
    }
  };

  return (
    <>
      <Typography variant="h4">{I18n.get("Help")}</Typography>

      <div className={classes.root}>
        <GridList
          cellHeight={180}
          spacing={12}
          className={classes.gridList}
          classes={{ root: classes.listRoot }}
        >
          {tileData
            .filter(
              (x) =>
                !x.businessType ||
                x.businessType === currentRestaurant.businessType
            )
            .map((tile) => (
              <GridListTile
                key={tile.title}
                cols={tile.featured ? 2 : 1}
                // rows={tile.featured ? 2 : 1}
                onClick={handleClickTile(tile)}
                classes={{ tile: classes.gridListTile }}
              >
                <img src={tile.img} alt={tile.title} />
                <GridListTileBar
                  title={I18n.get(tile.title)}
                  subtitle={I18n.get(tile.subtitle)}
                  titlePosition="top"
                  className={classes.titleBar}
                />
              </GridListTile>
            ))}
        </GridList>
      </div>
    </>
  );
};

export default Help;
