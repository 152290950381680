import React from "react";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";

import SecretCodeForm from "./SecretCodeForm";
import useReducer from "../../reducers";

const useStyles = makeStyles((theme) => ({
  link: {
    margin: theme.spacing(1),
  },
  sectionTitle: {
    margin: theme.spacing(2, 2, 0, 2),
  },
  section: {
    margin: theme.spacing(0, 2, 2),
  },
}));

const SecretCode = () => {
  const classes = useStyles();
  const [, dispatch] = useReducer();

  return (
    <>
      <div className={classes.sectionTitle}>
        <Typography variant="h6">Ingresa tu código secreto</Typography>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Te enviamos un código de 6 dígitos a tu correo o número de celular
        </Typography>
      </div>
      <Divider variant="middle" light />
      <div className={classes.section}>
        <SecretCodeForm />
      </div>
      <Typography
        component="p"
        style={{
          marginTop: 8,
          marginLeft: 16,
          marginRight: 16,
          marginBottom: 16,
        }}
      >
        <Link
          onClick={() => {
            dispatch({ type: "LOGIN_SET_VIEW", payload: { view: "signin" } });
          }}
          className={classes.link}
        >
          Volver a ingresar email o número de celular
        </Link>
      </Typography>
    </>
  );
};

export default SecretCode;
