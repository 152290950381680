import React from "react";
import { Cache } from "aws-amplify";
import { I18n } from "aws-amplify";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Box, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useLocation } from "react-router-dom";

import { ReactComponent as CancelIcon } from "../../svg/undraw_access_denied.svg";
import useQuery from "../../hooks/useQuery";
import useReducer from "../../reducers";
// import useReducer from "../../reducers";

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

const PaymentCancel = () => {
  const classes = useStyles();
  const query = useQuery();
  const location = useLocation();
  const [, dispatch] = useReducer();
  const ot = query.get("ot");
  const isDelivery = ot === "todeliver" || ot === "togo" || ot === "dinein";
  const isBond = ot === "bond";
  // const [, dispatch] = useReducer();
  const cachedCurrentDeliveryOrderId = Cache.getItem(`currentDeliveryOrderId`);

  return (
    <Box
      flex={1}
      flexDirection="column"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <SvgIcon
        component={CancelIcon}
        viewBox="0 0 638.9752 778.64494"
        style={{ fontSize: 120 }}
      />
      <Typography variant="h4" className={classes.title}>
        {I18n.get("Payment Cancelled")}
      </Typography>
      <Typography>
        {I18n.get("Error processing your payment, try again!")}
      </Typography>

      <Button
        variant="contained"
        color="secondary"
        component={Link}
        to={{
          pathname: isDelivery
            ? cachedCurrentDeliveryOrderId
              ? `/d/order/${cachedCurrentDeliveryOrderId}`
              : "/d"
            : isBond
            ? "/bonos"
            : "/checkout",
          state: isDelivery || isBond ? null : { background: location },
        }}
        onClick={() => {
          if (isDelivery) {
            Cache.removeItem("deliveryCart");
            Cache.removeItem("cartRestaurant");
            Cache.removeItem("cartCompany");
            dispatch({ type: "DELIVERY_CLEAR_CART" });
          }
        }}
        className={classes.button}
      >
        {I18n.get("Continue")}
      </Button>
    </Box>
  );
};

export default PaymentCancel;
