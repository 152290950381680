import React from "react";
import { I18n } from "aws-amplify";
import * as Sentry from "@sentry/browser";
import { Box } from "@material-ui/core";
import SvgIcon from "@material-ui/core/SvgIcon";
import { ReactComponent as BugIcon } from "../svg/undraw_bug_fixing.svg";

class ExampleBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { eventId: null };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.hasError) {
      //render fallback UI
      return (
        <Box
          flex={1}
          flexDirection="column"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <SvgIcon
            component={BugIcon}
            viewBox="0 0 1022.7 785.81"
            style={{ fontSize: 120 }}
          />
          <button
            onClick={() =>
              Sentry.showReportDialog({ eventId: this.state.eventId })
            }
          >
            {I18n.get("Report feedback")}
          </button>
        </Box>
      );
    }

    //when there's not an error, render children untouched
    return this.props.children;
  }
}

export default ExampleBoundary;
