const initialState = {
  searchQuery: ''
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SEARCH_SET_QUERY':
      return { ...state, searchQuery: action.payload.searchQuery };
    default:
      return state;
  }
};

export default { initialState, reducer };
