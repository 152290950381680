import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: 14,
    letterSpacing: '0.025em',
    textTransform: 'uppercase',
    margin: theme.spacing(-1, 0, 2, 2),
    padding: 0,
  },
}));

const NavTitle = ({ children }) => {
  const classes = useStyles();
  return (
    <Typography variant="h4" className={classes.title}>
      {children}
    </Typography>
  );
};

NavTitle.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default NavTitle;
