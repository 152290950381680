import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Img from "react-image";
import Skeleton from "@material-ui/lab/Skeleton";
import Box from "@material-ui/core/Box";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

import {
  toCurrency,
  JSONParse,
  getPictureUrl,
  handleProductPrice,
} from "../../util";
import useReducer from "../../reducers";
import Photo from "../Photo";
import PromoLabel from "../PromoLabel";
// import TranslateText from "../TranslateText";
import Markdown from "../Markdown";
import useTranslate from "../../hooks/useTranslate";
// import useTranslate from "../../hooks/useTranslate";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  card: {
    marginTop: theme.spacing(1),
  },
  media: {
    height: 320,
    position: "relative",
  },
  lineClamp: {
    display: "-webkit-box",
    // WebkitLineClamp: 7,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
  image: {
    height: 320,
    width: "100%",
    objectFit: "cover",
    objectPosition: "center",
    display: "block",
    overflow: "hidden",
  },
  price: {
    color: theme.palette.common.white,
  },
}));

const Translation = ({ item, field }) => {
  const text = useTranslate(item[field], item.i18n, field);
  return text || null;
};

const PHDescription = ({ text, i18n, field = "titleMarkdown" }) => {
  const value = useTranslate(text, i18n, field);

  return <Markdown>{value}</Markdown>;
};

const ProductImage = ({ product, ...other }) => {
  const classes = useStyles();
  const pic = product && product.picture ? JSONParse(product.picture) : {};
  const isPicArray = pic ? Array.isArray(pic) : false;
  const [activeStep, setActiveStep] = React.useState(0);
  const imageUrl =
    pic && !isPicArray ? getPictureUrl({ ...pic, size: "571x0" }) : null;
  const imageUrlArr = isPicArray
    ? pic.map((p) => getPictureUrl({ ...p, size: "571x0" }))
    : [];

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return !isPicArray ? (
    <Img
      src={[imageUrl]}
      alt={product.title}
      loader={
        <Skeleton
          variant="rect"
          animation="wave"
          width={"100%"}
          height={"100%"}
        />
      }
      unloader={<Photo />}
      className={classes.image}
    />
  ) : (
    <AutoPlaySwipeableViews
      index={activeStep}
      onChangeIndex={handleStepChange}
      enableMouseEvents
      interval={7500}
      style={{ height: 320 }}
      containerStyle={{ height: 320 }}
    >
      {imageUrlArr.map((step, index) => (
        <Img
          key={index}
          src={[step]}
          alt={product.title + "_" + index}
          loader={
            <Skeleton
              variant="rect"
              animation="wave"
              width={"100%"}
              height={"100%"}
            />
          }
          unloader={<Photo />}
          className={classes.image}
        />
      ))}
    </AutoPlaySwipeableViews>
  );
};

const ProductHeader = ({
  loading,
  product,
  delivery = false,
  dialog = false,
}) => {
  // console.log("product header", product);
  const classes = useStyles();
  const [
    {
      deliveryReducer: {
        selectedRestaurant: { currency: restCurrency },
      },
      restaurantReducer: {
        currentRestaurant: { currency },
      },
    },
  ] = useReducer();

  const selectedCurrency = currency || restCurrency;

  const productPrice = React.useMemo(
    () => handleProductPrice(product, delivery),
    [product, delivery]
  );

  return (
    <div
      style={
        dialog
          ? { marginTop: 0, position: "relative" }
          : { position: "relative" }
      }
    >
      {product && product.compare && productPrice !== product.compare ? (
        <PromoLabel
          label={`- ${Math.abs(
            (productPrice / product.compare - 1) * 100
          ).toFixed(0)}%`}
        />
      ) : null}
      <Card
        className={classes.card}
        style={{ marginTop: 0 }}
        square={dialog}
        elevation={dialog ? 0 : 1}
        onContextMenu={(e) => {
          e.preventDefault(); //===added this===
          return false;
        }}
      >
        <CardMedia className={classes.media} title={product.title}>
          <ProductImage product={product} />

          <Box
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              left: 0,
              paddingRight: 16,
              paddingTop: 8,
              textAlign: "right",
              background:
                "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
            }}
          >
            {product && product.compare && productPrice !== product.compare ? (
              <Typography
                variant="subtitle2"
                color="secondary"
                style={{ textDecoration: "line-through", marginBottom: -8 }}
              >
                {toCurrency(((product && product.compare) || 0).toString())}
              </Typography>
            ) : null}
            {loading ? (
              <Skeleton
                variant="rect"
                animation="wave"
                height={24}
                width={"15%"}
                style={{
                  borderRadius: 4,
                  marginLeft: "auto",
                  marginTop: 8,
                  marginBottom: 8,
                }}
              />
            ) : (
              <Typography gutterBottom variant="h6" className={classes.price}>
                {productPrice
                  ? `${selectedCurrency} ${toCurrency(
                      (productPrice || 0).toString()
                    )}`
                  : null}
              </Typography>
            )}
          </Box>
        </CardMedia>

        <CardContent>
          {loading ? (
            <>
              <Skeleton
                variant="rect"
                animation="wave"
                height={32}
                style={{ marginBottom: 4 }}
              />
              <Skeleton variant="rect" animation="wave" height={32} />
            </>
          ) : (
            <Typography gutterBottom variant="h5" component="h2">
              {product && product.titleMarkdown ? (
                <PHDescription
                  i18n={product.i18n}
                  field="titleMarkdown"
                  text={product.titleMarkdown}
                />
              ) : (
                <Translation item={product} field="title" /> || null
              )}
            </Typography>
          )}

          {loading ? (
            <>
              <Skeleton variant="text" animation="wave" width={"80%"} />
              <Skeleton variant="text" animation="wave" width={"70%"} />
            </>
          ) : (
            <Typography
              variant="body2"
              color="textSecondary"
              component="div"
              className={classes.lineClamp}
            >
              {product && product.descriptionMarkdown ? (
                <PHDescription
                  i18n={product.i18n}
                  field="descriptionMarkdown"
                  text={product.descriptionMarkdown}
                />
              ) : (
                <Translation item={product} field="description" /> || null
              )}
            </Typography>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default ProductHeader;
