import React from 'react';
import PropTypes from 'prop-types';
import { toCurrency } from '../util';

const CurrencyInput = ({
  onChange,
  defaultValue,
  separator = '.',
  thousandSeparator = ',',
  value: givenValue,
  Component,
  inputProps,
  ...other
}) => {
  const [value, setValue] = React.useState(defaultValue || '');
  const InputComponent = Component || 'input';

  const handleChange = event => {
    const valueAsCurrency = toCurrency(
      event.target.value,
      separator,
      thousandSeparator
    );
    setValue(valueAsCurrency);

    if (onChange) {
      event.persist();
      onChange(event, valueAsCurrency);
    }
  };

  const ip =
    InputComponent === 'input'
      ? { ...inputProps }
      : { inputProps: { ...inputProps } };

  return (
    <InputComponent
      pattern="\d*"
      {...ip}
      {...other}
      value={givenValue || value}
      onChange={handleChange}
    />
  );
};

CurrencyInput.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  separator: PropTypes.oneOf(['.', ',']),
  thousandSeparator: PropTypes.oneOf(['.', ',']),
  Component: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.element,
    PropTypes.node
  ]),
  inputProps: PropTypes.object
};

export default CurrencyInput;
