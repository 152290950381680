import React from "react";
import _orderBy from "lodash/orderBy";
import { I18n } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import CategoryCard from "./CategoryCard";
import { Button } from "@material-ui/core";
import CategoryCardSkeleton from "./CategoryCardSkeleton";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    marginTop: theme.spacing(2),
  },
  gridList: {
    width: "100%",
  },
}));

const CategoriesWidget = ({
  title = "Categories",
  loading = true,
  categories = [],
  restaurantId,
  delivery = false,
  isMain = false,
  loadingMore = false,
  handleLoadMore,
  hasMore = false,
  fromSite = false,
}) => {
  const classes = useStyles();

  const sortedCategories = _orderBy(
    categories,
    ["position", "title"],
    ["asc", "asc"]
  );

  return (
    <Box mt={2}>
      <Typography variant="h5">{I18n.get(title)}</Typography>

      <div className={classes.root}>
        <GridList cols={2} cellHeight={180} className={classes.gridList}>
          {(loading
            ? Array.from(new Array(4))
            : isMain
            ? (sortedCategories || []).filter((x) => !x.isSubcategory)
            : sortedCategories || []
          ).map((item, index) =>
            item ? (
              item._deleted ? null : (
                <CategoryCard
                  index={index}
                  key={item.id}
                  category={item}
                  restaurantId={restaurantId}
                  delivery={delivery}
                  fromSite={fromSite}
                />
              )
            ) : (
              <CategoryCardSkeleton key={index} />
            )
          )}
          {loadingMore &&
            Array.from(new Array(2)).map((item, index) => (
              <CategoryCardSkeleton key={index} />
            ))}
        </GridList>
        {hasMore ? (
          <Button
            style={{ marginTop: 8 }}
            onClick={handleLoadMore}
            disabled={loading || !hasMore}
          >
            {I18n.get("Load more...")}
          </Button>
        ) : null}
      </div>
    </Box>
  );
};

export default CategoriesWidget;
