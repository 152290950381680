import React from "react";
import { I18n } from "aws-amplify";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import useReducer from "../../reducers";
import DivideButtonGroup from "./DivideButtonGroup";
import { getTotal, toCurrency, getRemainingAmount } from "../../util";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  title: {
    textAlign: "center",
    margin: theme.spacing(1, 0),
  },
  button: {
    width: 120,
  },
}));

const Divide = () => {
  const classes = useStyles();
  const [
    {
      orderReducer: {
        orderAmount,
        orderTaxes,
        amountPaid,
        orderDiscountAmount,
      },
      checkoutReducer: { divide, dividePayment },
    },
    dispatch,
  ] = useReducer();

  const total = React.useMemo(() => getTotal(orderAmount, orderTaxes), [
    orderAmount,
    orderTaxes,
  ]);

  const remainingAmount = React.useMemo(
    () => getRemainingAmount(total, amountPaid, orderDiscountAmount),
    [total, amountPaid, orderDiscountAmount]
  );

  const handleDivide = (value) => () => {
    dispatch({ type: "CHECKOUT_SET_DIVIDE", payload: { divide: value } });

    if (!value) {
      dispatch({
        type: "CHECKOUT_SET_STEP",
        payload: { checkoutStep: 3 },
      });
      dispatch({
        type: "CHECKOUT_SET_A2P",
        payload: { amountToPay: remainingAmount },
      });
    }
  };

  const handleNext = () => {
    if (!divide) {
      dispatch({
        type: "CHECKOUT_SET_STEP",
        payload: { checkoutStep: 3 },
      });
      dispatch({
        type: "CHECKOUT_SET_A2P",
        payload: { amountToPay: remainingAmount },
      });
    } else {
      if (dividePayment < 3) {
        dispatch({
          type: "CHECKOUT_SET_STEP",
          payload: { checkoutStep: 3 },
        });
      } else if (dividePayment === 3) {
        dispatch({
          type: "CHECKOUT_SET_STEP",
          payload: { checkoutStep: 1 },
        });
      } else {
        dispatch({
          type: "CHECKOUT_SET_STEP",
          payload: { checkoutStep: 2 },
        });
      }
    }
  };

  return (
    <>
      <Box flex={1} display="flex" flexDirection="column">
        <Typography variant="h5" className={classes.title}>
          {I18n.get("Divide check")}
        </Typography>
        <Box
          marginX={2}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle1" className={classes.title}>
            {I18n.get("Order Total")}
          </Typography>
          <Typography variant="subtitle1" className={classes.title}>
            ${toCurrency((total - orderDiscountAmount || 0).toString())}
          </Typography>
        </Box>
        <Box
          marginX={2}
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle1" className={classes.title}>
            {I18n.get("Remaining Amount")}
          </Typography>
          <Typography variant="subtitle1" className={classes.title}>
            ${toCurrency((remainingAmount || 0).toString())}
          </Typography>
        </Box>

        <Box className={classes.root}>
          <ButtonGroup
            size="large"
            color="primary"
            aria-label="divide check buttons group"
          >
            <Button
              variant={divide ? "outlined" : "contained"}
              className={classes.button}
              onClick={handleDivide(false)}
            >
              {I18n.get("No divide")}
            </Button>
            <Button
              variant={!divide ? "outlined" : "contained"}
              className={classes.button}
              onClick={handleDivide(true)}
            >
              {I18n.get("Divide")}
            </Button>
          </ButtonGroup>
        </Box>

        <DivideButtonGroup />
      </Box>

      <Box p={2} textAlign="center">
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleNext}
        >
          {I18n.get("Next")}
        </Button>
      </Box>
    </>
  );
};

export default Divide;
