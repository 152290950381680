import React from "react";
import { Redirect, useParams, useHistory } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";

import * as queries from "../../graphql/queries";
import ScrollToTopOnMount from "../ScrollToTopOnMount";
// import RouterBreadcrumbs from "../RouterBreadcrumbs";
import ProductHeader from "../Product/ProductHeader";
import ProductForm from "../Product/ProductForm";

const OrderItemUpdate = () => {
  const { productId, oiid } = useParams();
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [product, setProduct] = React.useState({});

  const [orderItem, setOrderItem] = React.useState({});

  React.useEffect(() => {
    let mounted = true;

    const fetchProduct = async () => {
      setLoading(true);
      try {
        const { data } = await API.graphql({
          ...graphqlOperation(queries.getProduct, {
            id: productId,
          }),
          authMode: "AWS_IAM",
        });
        const { getProduct } = data || {};
        console.log("getProduct", getProduct);
        if (!getProduct || getProduct.status !== "ACTIVE") {
          history.goBack();
          return;
        } else {
          setProduct(getProduct);
        }
        setLoading(false);
      } catch (error) {
        console.log("error", error);
        setLoading(false);
      }
    };

    const fetchData = async () => {
      const { data } = await API.graphql({
        ...graphqlOperation(queries.getOrderItem, {
          id: oiid,
          filter: {
            status: {
              eq: "PENDING",
            },
          },
        }),
        authMode: "AWS_IAM",
      });
      const { getOrderItem: item } = data || {};
      console.log("getOrderItem response", item);

      if (mounted) {
        setOrderItem(item);
      }
    };

    if (productId) {
      fetchProduct();
    }
    fetchData();

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oiid, productId]);

  if (!loading && (!product || product.status !== "ACTIVE")) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <ScrollToTopOnMount />
      {/* <RouterBreadcrumbs title={product.title} /> */}

      <ProductHeader loading={loading} product={product} />

      <ProductForm
        productId={productId}
        orderItemId={oiid}
        product={product}
        orderItem={orderItem}
      />
    </>
  );
};

export default OrderItemUpdate;
