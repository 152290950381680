import React from "react";
import { I18n } from "aws-amplify";
import _groupBy from "lodash/groupBy";
import { makeStyles } from "@material-ui/core/styles";
import { List, ListSubheader, Box, Typography } from "@material-ui/core";

import useReducer from "../../reducers";
import OrderItem from "../Order/OrderItem";
import NoDataItem from "../Order/NoDataItem";
import { handleGetGroupedItems, isEmptyObject } from "../../util";
import ClearCartButton from "./ClearCartButton";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    // maxHeight: 300,
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
}));

const DeliveryCheckoutItems = ({ fromSite = false }) => {
  const classes = useStyles();

  const [
    {
      deliveryReducer: { deliveryCart },
    },
  ] = useReducer();

  const groupedDeliveryCart = _groupBy(deliveryCart, "restaurantID");
  // console.log("groupedDeliveryCart", groupedDeliveryCart);

  return (
    <List
      className={classes.root}
      subheader={
        <ListSubheader disableSticky>
          <Box
            py={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography>{I18n.get("Your order")}</Typography>
            <ClearCartButton />
          </Box>
        </ListSubheader>
      }
    >
      {groupedDeliveryCart && !isEmptyObject(groupedDeliveryCart) ? (
        Object.keys(groupedDeliveryCart).map((restId) => {
          const oitems = groupedDeliveryCart[restId];
          const { restaurantName } = oitems?.[0] || {};

          return (
            <li key={`section-${restId}`} className={classes.listSection}>
              <ul className={classes.ul}>
                <ListSubheader disableSticky>{restaurantName}</ListSubheader>
                {handleGetGroupedItems(oitems).map((item, index) => (
                  <OrderItem
                    key={index}
                    item={item}
                    delivery
                    fromSite={fromSite}
                  />
                ))}
              </ul>
            </li>
          );
        })
      ) : (
        <NoDataItem
          title={I18n.get("No ordered items in your cart")}
          secondary={I18n.get("Add items to your order")}
        />
      )}
    </List>
  );
};

export default DeliveryCheckoutItems;
