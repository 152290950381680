import React from "react";
import { I18n } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import SearchListItem from "./SearchListItem";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: "inline",
  },
}));

const SearchList = ({ items, delivery = false }) => {
  const classes = useStyles();

  return (
    <List className={classes.root}>
      {items && items.length ? (
        items.map((item) => {
          return (
            <SearchListItem key={item.id} item={item} delivery={delivery} />
          );
        })
      ) : (
        <ListItem alignItems="flex-start">
          <ListItemText primary={I18n.get("Search for something...")} />
        </ListItem>
      )}
    </List>
  );
};

export default SearchList;
