import React from "react";
import { I18n } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import { Droppable } from "react-beautiful-dnd";

import { toCurrency, JSONParse, filterOrderItems } from "../../util";
import useReducer from "../../reducers";
import GuestOrderItem from "./GuestOrderItem";

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(9),
  },
  listItemText: {
    fontWeight: "700",
    color: theme.palette.primary.main,
  },
}));

const GuestItem = ({ guest, orderItems, isPaid }) => {
  const classes = useStyles();
  const [
    {
      checkoutReducer: { guestsChecked },
    },
    dispatch,
  ] = useReducer();
  console.log("orderItems", orderItems);
  const labelId = `checkbox-list-label-${guest.id}`;
  const guestAmount = filterOrderItems(orderItems).reduce(
    (sum, val) =>
      sum +
      (!val
        ? 0
        : !(val && val.isPaid)
        ? ((val && val.amount) || 0) +
          (val.taxes
            ? JSONParse(val.taxes).reduce((sum, val) => sum + val.amount, 0)
            : 0)
        : 0),
    0
  );

  const handleToggle = (value) => () => {
    if (!guestAmount || isPaid) {
      return;
    }

    const currentIndex = guestsChecked.findIndex((x) => x.id === value.id);
    const newChecked = [...guestsChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    dispatch({
      type: "CHECKOUT_SET_GUESTSCHECKED",
      payload: { guestsChecked: newChecked },
    });
    const inputValue = newChecked.reduce((sum, val) => sum + val.amount, 0);

    dispatch({
      type: "CHECKOUT_SET_A2P",
      payload: { amountToPay: inputValue },
    });
  };

  return (
    <>
      <ListItem
        role={undefined}
        dense
        button
        disableRipple={!guestAmount}
        onClick={handleToggle({ ...guest, amount: guestAmount })}
      >
        <ListItemIcon>
          <Checkbox
            edge="start"
            indeterminate={!guestAmount || isPaid}
            // indeterminate={!guestAmount}
            checked={!!guestsChecked.find((x) => x.id === guest.id)}
            tabIndex={-1}
            disableRipple
            inputProps={{ "aria-labelledby": labelId }}
            disabled={!guestAmount || isPaid}
            // disabled={!guestAmount}
            color="primary"
          />
        </ListItemIcon>
        <ListItemText
          id={labelId}
          primary={guest.label}
          classes={{ primary: classes.listItemText }}
        />
        <ListItemSecondaryAction>
          <Typography variant="subtitle2" className={classes.listItemText}>
            $ {toCurrency((guestAmount || 0).toString())}
          </Typography>
        </ListItemSecondaryAction>
      </ListItem>
      <Droppable droppableId={guest.id.toString()}>
        {(provided) => (
          <List
            ref={provided.innerRef}
            {...provided.droppableProps}
            component="div"
            disablePadding
          >
            {orderItems && orderItems.length ? (
              filterOrderItems(orderItems).map((item, index) => {
                return (
                  <GuestOrderItem key={item.id} index={index} item={item} />
                );
              })
            ) : (
              <ListItem dense className={classes.nested}>
                <ListItemText
                  primary={I18n.get("No items")}
                  secondary={I18n.get("Add or drag products to this guest")}
                />
              </ListItem>
            )}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </>
  );
};

export default GuestItem;
