import React from "react";
import { I18n } from "aws-amplify";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import useReducer from "../reducers";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    flexDirection: "column",
    zIndex: theme.zIndex.drawer + 1001,
    color: theme.palette.primary.main,
  },
  message: {
    width: "fit-content",
    marginTop: theme.spacing(2),
  },
}));

const Loading = ({ force = false }) => {
  const classes = useStyles();

  const [{ loadingReducer }] = useReducer();
  return (
    <Backdrop
      className={classes.backdrop}
      open={force || loadingReducer.loading}
      // onClick={() => {
      //   if (!force) dispatch({ type: 'LOADING_STOP' });
      // }}
    >
      <CircularProgress color="inherit" />
      <Typography
        component="marquee"
        className={classes.message}
        variant="subtitle2"
      >
        {I18n.get(loadingReducer.message)}
      </Typography>
    </Backdrop>
  );
};

export default Loading;
