import React from "react";
import Markdown from "../Markdown";

let markdown = `
  AVISO DE PRIVACIDAD
  
  En cumplimiento con lo establecido por la Ley Federal de Protección de Datos Personales en Posesión de Particulares de los Particulares (LFPDPPP). Plukke SAPI de C.V. y/o Operador Lider en Reservaciones, S.A. de C.V., pone a su disposición el siguiente Aviso de Privacidad:
  
  Responsable de la protección y tratamiento de sus Datos Personales: Plukke SAPI de C.V. y/o Operador Líder en Reservaciones, S.A. de C.V., con domicilio en Calle Playa Chica, Manzana 4, Lote6, Edificio P, Interior 32, Supermanzan 29, Código 77508.
  
  Medios de obtención de Datos Personales: Los datos personales que Plukke SAPI de C.V. y/o Operador Líder en Reservaciones, S.A. de C.V., recabe de usted, serán destinados únicamente para los fines descritos en el presente aviso de privacidad y podrán ser recabados:
  
  - De manera directa, ingresando sus datos o utilizando los servicios en línea a través de la página [www.2dine.io](http://www.2dine.io/) y [www.abonayviaja.com](http://www.abonayviaja.com/) y nuestra aplicación móvil &quot;2dine&quot;.
  - De manera directa, a través de nuestro centro de reservación telefónica &quot;call center&quot;.
  - De manera directa, a través de nuestras cuentas oficiales de redes sociales.
  
  Datos recabados
  
  Los datos que Plukke SAPI de C.V. y/o Operador Líder en Reservaciones, S.A. de C.V. recabe de usted, serán los siguientes: Nombre completo con apellidos, número de teléfono, correo electrónico, domicilio y datos financieros como lo son: Número de tarjeta de crédito, nombre del titular de la misma, fecha de vencimiento, código de seguridad y aquellos datos que resulten necesarios para completar y administrar las reservaciones solicitadas por usted.
  
  Asimismo, Plukke SAPI de C.V. y/o Operador Líder en Reservaciones, S.A. de C.V. en casos especiales solicitará recabar su imagen física (fotografía y/o video), para lo cual habilitará los medios idóneos para obtener el consentimiento de los titulares de acuerdo a lo establecido en la LFPDPPP y en su reglamento.
  
  Finalidad de los Datos Personales recabados: Sus datos personales serán recabados para las siguientes finalidades principales que son necesarias para el servicio que solicita:
  
  - Procesamiento, seguimiento, actualización, modificación, cancelación y confirmación de los servicios por usted reservados con Plukke SAPI de C.V. y/o Operador Líder en Reservaciones, S.A. de C.V. a través de sus medios, con fines financieros y crediticios, para dar cumplimiento a las obligaciones contraídas con usted y con nuestros proveedores.
  
  Asimismo, los datos que le sean recabados se utilizará para ponerse en contacto con usted para ofrecerles mayor información para la generación de posibles reservas.
  
  Y de manera accesoria, utilizaremos su información personal para las siguientes finalidades que no son necesarias para el servicio solicitado, pero que nos permiten y facilitan una mejor atención:
  
  - Evaluar la calidad del servicio, realizar sondeos sobre hábitos y preferencias de viaje, para participar en concursos y sorteos, programas de lealtad, implementación y desarrollo de campañas publicitarias, así como para el envío de promociones, ofertas, servicios y fines publicitarios (Única y exclusivamente para Plukke SAPI de C.V. y/o Operador Líder en Reservaciones, S.A. de C.V.
  
  Los titulares de manera inmediata y gratuita podrán en cualquier momento solicitar la cancelación de envío de correos electrónicos con fines publicitarios por parte de Plukke SAPI de C.V. y/o Operador Líder en Reservaciones, S.A. de C.V., enviando un correo electrónico a [hola@abonayviaja.com](mailto:hola@abonayviaja.com) o haciendo click en el link de su perfil en &quot;Notificaciones&quot; y desactivando las notificaciones.
  
  En caso de que no desee que sus datos personales sean tratados para estos fines adicionales, usted puede presentar desde este momento un escrito vía correo electrónico a la dirección hola@abonayviaja.com.
  
  Transferencia de datos, nacional e internacional: En virtud de lo expuesto en los artículos 36 y 37 de la LFPDPPP sus datos personales podrán ser transferidos y/o compartidos a:
  
  1. Sociedades controladoras, subsidiarias o afiliadas bajo el control común de Plukke SAPI de C.V. y/o Operador Líder en Reservaciones, S.A. de C.V. que opere bajo los mismos procesos y políticas internas;
  2. Cuando la transferencia sea necesaria por virtud de un contrato celebrado o por celebrar en interés del titular, por el responsable y un tercero;
  3. Cuando la transferencia sea precisa para el mantenimiento o cumplimiento de una relación jurídica entre el responsable y el titular tales como los Proveedores, Proveedores de Servicios Turísticos, Instituciones Bancarias y Crediticias, y entre otras.
  
  Es importante señalar que los terceros a los que se transfieran sus datos personales se obligarán en los mismos términos del presente Aviso de Privacidad y cumplirán con las medidas de seguridad y confidencialidad correspondientes.
  
  De cualquier manera, nos comprometemos a no transferir su información personal a terceros sin su consentimiento, salvo en el caso de lo previsto en el artículo 37 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, así como a realizar esta transferencia en los términos que fija dicha Ley.
  
  Medidas para proteger su información y para limitar el uso o divulgación de su información personal: Esta será resguardada bajo estricta confidencialidad y para prevenir razonablemente el uso o divulgación indebida de la misma, hemos implementado medidas de seguridad física, técnicas y administrativas de conformidad con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares y demás normatividad aplicable.
  
  En particular, contamos con una política de privacidad, cursos de capacitación al personal, acceso restringido a información personal sólo a usuarios autorizados, oficiales de privacidad, un inventario de datos personales (debidamente clasificados por categoría de datos), de los sistemas de tratamiento, análisis de riesgos y cláusulas contractuales.
  
  Con objeto de que usted pueda limitar el uso y divulgación de su información personal, le ofrecemos la opción de su inscripción en el Registro Público para evitar Publicidad (REPEP), que está a cargo de la Procuraduría Federal del Consumidor (PROFECO), con la finalidad de que sus datos personales no sean utilizados para recibir publicidad o promociones de empresas de bienes o servicios. Para mayor información sobre este registro, usted puede consultar el portal de Internet de la PROFECO, o bien ponerse en contacto directo con ésta.
  
  COOKIES
  
  Plukke SAPI de C.V. y/o Operador Líder en Reservaciones, S.A. de C.V. utiliza cookies propias y de terceros para analizar su navegación y ofrecerle un servicio más personalizado acorde a sus intereses. Si desea conocer nuestra política de Cookies de [click aqui](http://www.2dine.io/about).
  
  Modificaciones al viso de privacidad: Plukke SAPI de C.V. y/o Operador Líder en Reservaciones, S.A. de C.V., podrá efectuar en cualquier momento modificaciones o actualizaciones al presente Aviso de Privacidad. Las modificaciones o actualizaciones que se efectúen, entrarán en vigor en el momento en que son publicados en el sitio de internet www.2dine.io, en sus sitios afiliados o en cualquier medio de comunicación que utilice para publicarlo, por lo que se recomienda su revisión continua.
  
  Aceptación expresa del Aviso de Privacidad: Si usted no se opone de manera expresa al presente aviso y sus actualizaciones se da por entendido que ha otorgado su consentimiento tácito en términos del artículo 8 de la LFPDPPP. Plukke SAPI de C.V. y/o Operador Líder en Reservaciones, S.A. de C.V. le sugiere la lectura del presente Aviso de Privacidad, ya que al aportar sus datos por cualquier medio, constituye la aceptación del presente Aviso de Privacidad.
  
  USOS Y RESTRICCIONES PROPIEDAD INTELECTUAL RESTRICCIONES PROPIEDAD INTELECTUAL DE TERCEROS USOS PERMITIDOS
  
  El acceso o utilización de la APP o de la página [www.abonayviaja.com](http://www.abonayviaja.com/) y [www.2dine.io](http://www.2dine.io/) expresan la adhesión plena y sin reservas del Usuario a los Términos y Condiciones de Uso y Privacidad. A través del uso de la APP o la página [www.abonayviaja.com](http://www.abonayviaja.com/) y [www.2dine.io](http://www.2dine.io/) el Usuario (entendiéndose como cualquier persona que accese al Portal) utilizará diversos servicios y contenidos, puestos a disposición de los Usuarios por Plukke SAPI de C.V. y/o Operador Líder en Reservaciones, S.A. de C.V. y/o por terceros proveedores de Servicios y Contenidos. Plukke SAPI de C.V. y/o Operador Líder en Reservaciones, S.A. de C.V. tendrá el derecho a negar, restringir o condicionar al Usuario el acceso a las Páginas, total o parcialmente, a su entera discreción en cualquier momento y sin necesidad de previo aviso.
  
  DERECHOS DE PROPIEDAD
  
  Los derechos de propiedad intelectual respecto de los Servicios y Contenidos y los signos distintivos y dominios de las Páginas o el Portal, así como los derechos de uso y explotación de los mismos, incluyendo su divulgación, publicación, reproducción, distribución y transformación, son propiedad exclusiva de Plukke SAPI de C.V. y/o Operador Líder en Reservaciones, S.A. de C.V.
  
  El Portal es para el uso individual del Usuario por lo que no podrá comercializar de manera alguna los Servicios y Contenidos.
  
  BIENES Y SERVICIOS DE TERCEROS. La disponibilidad de bienes y/o servicios ofertados por terceros o por sitios ligados o vinculados, no es responsabilidad de Plukke SAPI de C.V. y/o Operador Líder en Reservaciones, S.A. de C.V. En vista de lo anterior, no será responsable ante cualquier autoridad de cualquier naturaleza, por cualquier asunto relacionado con la venta, consumo, distribución, entrega, disponibilidad o prestación con respecto de cualquiera de los bienes y/o servicios ofertados por terceros o por sitios ligados o vinculados a través del Portal o la APP.
  
  LEYES APLICABLES Y JURISDICCIÓN: Para la interpretación, cumplimiento y ejecución de los presentes Términos y Condiciones de Uso y Privacidad, el Usuario está de acuerdo en que serán aplicables las leyes de los Estados Unidos Mexicanos y competentes los tribunales de la Ciudad de Cancún, Quintana Roo, renunciando expresamente a cualquier otro fuero o jurisdicción que pudiera corresponderles en razón de sus domicilios presentes o futuros o por cualquier otra causa.
  
  POLÍTICA DE CANCELACIÓN.
  
  De manera general, la política de cancelación de hoteles aplica de la siguiente manera:
  
  - Cancelaciones hechas 15 días o más antes de la fecha de llegada aplica un cargo de 10%.
  - Cancelaciones hechas de 3 a 14 días antes de la fecha de llegada aplica un cargo del 25%.
  - Cancelaciones hechas de 0 a 2 días antes de la fecha de llegada aplica un cargo de 100%.
  - Si la habitación está marcada como _no cancelable_, _no reembolsable_ o similar, aplica una penalidad del 100% sin importar la fecha en que solicita la cancelación.
  
  Cancelaciones en temporada alta y días festivos: entiéndanse estos como Navidad, Año Nuevo, Semana Santa, verano, y días festivos de acuerdo al calendario oficial y fechas designadas por los hoteles.
  
  - Las reservaciones canceladas 30 días o más antes de la fecha de llegada tienen un cargo del 10%
  - Las reservaciones canceladas de 15 a 29 días antes de la fecha de llegada, tienen un cargo del 25%
  - Las reservaciones canceladas con menos de 15 días de anticipación tienen un cargo del 100%
  
  En caso de que tengas que irte del hotel antes de que termine tu estancia (salida anticipada), o no te presentes al hotel (conocido como _no show_) se considera como cancelación y no aplican reembolsos. Si reduces el número de huéspedes una vez pagada la reservación, es decisión del hotel aplicar penalidades o reembolsos.
  
  Para cancelar puedes llamar al :
  
  No olvides tener a la mano, numero de reservación, nombre completo del titular de la reservación, fecha y modo de pago.
  
  Si lo prefieres, manda tu solicitud de cancelación vía email a  **reservas@abonayviaja.com**  con los datos mencionados arriba.`;

const TermsAyV = () => {
  return <Markdown>{markdown}</Markdown>;
};

export default TermsAyV;
