import React from "react";
import { Box, Typography, SvgIcon } from "@material-ui/core";
import { API, graphqlOperation, I18n } from "aws-amplify";
import { useHistory } from "react-router-dom";

import Loading from "../Loading";
import * as queries from "../../graphql/queries";
import PaymentButtons from "./PaymentButtons";
import useQuery from "../../hooks/useQuery";
import {
  getOrderTotal,
  getAmountPaid,
  getRemainingAmount,
  toCurrency,
  filterOrderItems,
  getDiscountItems,
  getDiscountAmount,
} from "../../util";
import useCheckout from "../../hooks/useCheckout";
import useReducer from "../../reducers";
import ConfirmDialog from "../ConfirmDialog";

import { ReactComponent as GPay } from "../../svg/google-pay.svg";
import { ReactComponent as APay } from "../../svg/Apple-Pay.svg";
import { ReactComponent as Amex } from "../../svg/american-express.svg";
import { ReactComponent as MCard } from "../../svg/mastercard.svg";
import { ReactComponent as Visa } from "../../svg/visa.svg";

const PaymentCard = () => {
  const [order, setOrder] = React.useState();
  const [maxAmount, setMaxAmount] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [amount, setAmount] = React.useState(0);
  const history = useHistory();
  const [
    {
      loginReducer: { currentUser },
    },
    dispatch,
  ] = useReducer();

  const query = useQuery();
  const orderId = query.get("o");
  const checkout = useCheckout();

  React.useEffect(() => {
    let mounted = true;

    if (orderId) {
      API.graphql({
        ...graphqlOperation(queries.getOrder, { id: orderId }),
        authMode: "AWS_IAM",
      }).then((orderResponse) => {
        const { data = {} } = orderResponse || {};
        const currOrder = data.getOrder || null;
        console.log("currOrder", currOrder);
        if (currOrder) {
          if (currOrder.isPaid) {
            history.replace(`/d/order/${orderId}`);
          }

          const orderItems =
            currOrder &&
            currOrder.items &&
            currOrder.items.items &&
            currOrder.items.items.length
              ? filterOrderItems(currOrder.items.items)
              : [];
          const payments =
            currOrder &&
            currOrder.payments &&
            currOrder.payments.items &&
            currOrder.payments.items.length
              ? currOrder &&
                currOrder.payments &&
                currOrder.payments.items &&
                currOrder.payments.items.filter((x) => !x._deleted)
              : [];

          const discountItems =
            currOrder &&
            currOrder.items &&
            currOrder.items.items &&
            currOrder.items.items.length
              ? getDiscountItems(currOrder.items.items)
              : [];
          const discountAmount = getDiscountAmount(discountItems);

          console.log("fetch Result", orderItems);
          console.log("fetch PaymentResult", payments);

          if (mounted) {
            setOrder(currOrder);
            if (currOrder?.restaurant) {
              dispatch({
                type: "DELIVERY_RESTAURANT_SET",
                payload: { selectedRestaurant: currOrder?.restaurant },
              });

              if (currOrder?.restaurant?.accountId) {
                let apiName = "api616cdc2a";
                let path = "/stripe/account";

                API.get(apiName, path, {
                  queryStringParameters: {
                    account: currOrder?.restaurant?.accountId,
                  },
                })
                  .then((result) => {
                    console.log("/stripe/account response", result);
                    dispatch({
                      type: "CHECKOUT_SET_ACCOUNT_CHARGES_ENABLED",
                      payload: {
                        accountChargesEnabled: Boolean(result.charges_enabled),
                      },
                    });
                  })
                  .catch((err) => {
                    console.log("error", err);
                  });
              }
            }
            const total = getOrderTotal(orderItems, true);
            const paid = getAmountPaid(payments);

            const remainingAmount = getRemainingAmount(
              total,
              paid,
              discountAmount
            );
            console.log("remainingAmount", remainingAmount);

            if (remainingAmount) {
              setMaxAmount(remainingAmount);
            }
            setLoading(false);
          }
        } else {
          history.goBack();
        }
      });
    } else {
      history.replace(`/main`);
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const handlePay = async (amount) => {
    const lineItems = [
      {
        name: order.restaurant.name,
        description: "Tu pago en Abona y Viaja",
        amount,
        currency: (order.restaurant.currency || "usd").toLowerCase(),
        quantity: 1,
      },
    ];

    console.log("lineItems", lineItems);

    const metadata = {
      orderId,
      userId: currentUser && currentUser.id ? currentUser.id : null,
      orderType: "travel",
      restaurantName: order.restaurant ? order.restaurant.name : null,
      restaurant: order.restaurant ? order.restaurant.id : null,
    };

    await checkout({ lineItems, orderId, metadata });
  };

  const setPaymentAmount = (value) => () => {
    console.log("paymentAmount", value);
    setAmount(value);
    setOpen(true);
  };

  if (loading) {
    return <Loading force />;
  }

  return (
    <Box>
      <Typography variant="h4" component="h2">
        Pay with card
      </Typography>
      <Typography variant="body2" color="textSecondary">
        You can reserve the tentative date for your trip and pay. Once the
        payment is complete, you can enjoy your vacation.
      </Typography>
      <Box mt={2}>
        <PaymentButtons
          isCard
          maxAmount={maxAmount}
          setPaymentAmount={setPaymentAmount}
          order={order}
        />
      </Box>

      <Box mt={3}>
        <Box display="flex" alignItems="center" justifyContent="center">
          <SvgIcon
            style={{ width: 56 }}
            viewBox="0 0 165.52107 105.9651"
            component={APay}
          />
          <SvgIcon
            style={{ width: 56, marginLeft: 4 }}
            viewBox="0 0 425 272"
            component={GPay}
          />

          <SvgIcon
            style={{ width: 56 }}
            viewBox="0 0 504 504"
            component={Amex}
          />
          <SvgIcon
            style={{ width: 56 }}
            viewBox="0 0 504 504"
            component={MCard}
          />
          <SvgIcon
            style={{ width: 56 }}
            viewBox="0 0 504 504"
            component={Visa}
          />
        </Box>
      </Box>

      <ConfirmDialog
        keepMounted
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onConfirm={async () => await handlePay(amount)}
        message={I18n.get(
          `Do you want to generate your payment for ${
            order.restaurant.currency || "USD"
          } $${toCurrency((amount || 0).toString())}?`
        )}
      />
    </Box>
  );
};

export default PaymentCard;
