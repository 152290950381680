import React from "react";
// import Avatar from "@material-ui/core/Avatar";
// import Chip from "@material-ui/core/Chip";
import Img from "react-image";
import { makeStyles } from "@material-ui/core/styles";
import VisibilitySensor from "react-visibility-sensor";
import GridListTile from "@material-ui/core/GridListTile";
import { Link as RouterLink } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";

import { JSONParse, getPictureUrl } from "../../util";
import Photo from "../Photo";
import { GridListTileBar } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  chip: {
    width: "100%",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "center",
  },
  gridListTile: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[1],
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  lineClamp: {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    whiteSpace: "initial",
  },
}));

const MenuTile = ({
  index,
  tile,
  delivery,
  restaurantId,
  fromSite,
  ...props
}) => {
  const classes = useStyles();
  const picture = tile && tile.picture ? JSONParse(tile.picture) : {};
  const imageUrl =
    tile && tile.picture ? getPictureUrl({ ...picture, size: "0x180" }) : null;

  if (!tile || tile._deleted || tile.status === "INACTIVE") {
    return null;
  }
  return (
    // <Grow
    //   in
    //   style={{ transformOrigin: "0 0 0" }}
    //   {...{ timeout: (index + 1) * 300 }}
    // >
    <GridListTile
      {...props}
      // cols={tile.featured ? 2 : 1}
      // // rows={tile.featured ? 2 : 1}
      // onClick={handleClickTile(tile.route)}
      classes={{ tile: classes.gridListTile }}
      // ref={tileRef}
      // style={parsedStyle}
      component={RouterLink}
      to={{
        pathname: !delivery
          ? "/t/menu"
          : fromSite
          ? `/d/menu`
          : `/d/r/${restaurantId}/menu`,
        search: `?mtitle=${tile.title}&smenu=${tile.id}`,
      }}
    >
      <VisibilitySensor>
        <Img
          src={[imageUrl]}
          alt={tile.title}
          loader={
            <Skeleton
              variant="rect"
              animation="wave"
              width={"100%"}
              height={"100%"}
            />
          }
          unloader={<Photo />}
          className={classes.image}
        />
      </VisibilitySensor>

      <GridListTileBar
        title={tile.title}
        titlePosition="bottom"
        className={classes.titleBar}
        classes={{ title: classes.lineClamp }}
      />
    </GridListTile>
    // </Grow>
  );
};

export default MenuTile;
