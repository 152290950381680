import React from "react";
import _orderBy from "lodash/orderBy";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, Grid } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import useInfiniteScroll from "react-infinite-scroll-hook";
import IconButton from "@material-ui/core/IconButton";
import ViewAgendaIcon from "@material-ui/icons/ViewAgenda";
import ViewListIcon from "@material-ui/icons/ViewList";
import List from "@material-ui/core/List";

import ScrollToTopOnMount from "../ScrollToTopOnMount";
// import RouterBreadcrumbs from "../RouterBreadcrumbs";
import ProductCard from "../ProductCard";
import * as queries from "../../graphql/queries";
import useReducer from "../../reducers";
import ProductCardSkeleton from "../ProductCardSkeleton";
import Subcategories from "./Subcategories";
import Skeleton from "@material-ui/lab/Skeleton";
import useTranslate from "../../hooks/useTranslate";
import ProductItem from "../Product/ProductItem";
import ProductItemSkeleton from "../Product/ProductItemSkeleton";
import SimplifiedView from "../SimplifiedView/SimplifiedView";
// import TranslateText from "../TranslateText";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const CategoryTitle = ({ categoryId, category }) => {
  const value = useTranslate(category.title, category.i18n, "title");
  const [
    {
      navReducer: { categoryView },
    },
    dispatch,
  ] = useReducer();

  const toggleView = () => {
    dispatch({ type: "NAV_TOGGLE_CATEGORY_VIEW" });
  };

  return (
    <Box display="flex" alignItems="center">
      <Typography variant="h6" style={{ flex: 1 }}>
        {value || categoryId}
      </Typography>
      <IconButton edge="end" aria-label="view" onClick={toggleView}>
        {categoryView === "panel" ? (
          <ViewAgendaIcon fontSize="small" />
        ) : (
          <ViewListIcon fontSize="small" />
        )}
      </IconButton>
    </Box>
  );
};

const CategoryDescription = ({ category }) => {
  const value = useTranslate(
    category.description,
    category.i18n,
    "description"
  );

  return (
    <Typography variant="body2" color="textSecondary">
      {value}
    </Typography>
  );
};

const CategoryWidget = ({ fromSite = false, delivery = false }) => {
  const { categoryId } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [loadingMore, setLoadingMore] = React.useState(false);
  const [token, setToken] = React.useState();
  const [category, setCategory] = React.useState({});
  const [items, setItems] = React.useState([]);
  const [
    {
      navReducer: { categoryView },
      deliveryReducer: { selectedRestaurant },
    },
    dispatch,
  ] = useReducer();
  const { businessType } = selectedRestaurant || {};

  React.useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        setLoading(true);
      }

      const categoryResponse = API.graphql({
        query: queries.getCategory,
        variables: { id: categoryId },
        authMode: "AWS_IAM",
      });

      const categoryProductsResponse = API.graphql({
        ...graphqlOperation(queries.productsbyCategory, {
          categoryID: categoryId,
          limit: 40,
        }),
        authMode: "AWS_IAM",
      });

      // getCategory response
      const { data: { getCategory } = {} } = (await categoryResponse) || {};
      // productsByCategoryResponse
      const { data } = await categoryProductsResponse;
      const { productsbyCategory: { items: products, nextToken } = {} } =
        data || {};

      console.log("products", products);

      if (!getCategory?.id || getCategory?.status !== "ACTIVE") {
        if (!delivery) {
          history.replace("/");
        } else {
          history.replace("/d");
        }
      }

      if (mounted) {
        setCategory(getCategory);
        dispatch({
          type: "NAV_TOGGLE_CATEGORY_VIEW",
          payload: { view: getCategory?.view },
        });
        setLoading(false);

        if (delivery && businessType === "RESTAURANT") {
          setItems(
            products.filter(
              (x) =>
                x &&
                !x._deleted &&
                x.product &&
                !x.product._deleted &&
                (x.product.preorderingEnabled || x.product.deliveryEnabled)
            )
          );
        } else {
          setItems(
            products.filter(
              (x) => x && !x._deleted && x.product && !x.product._deleted
            )
          );
        }
        setToken(nextToken);
      }
    };

    fetchData();
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  const handleLoadMore = async () => {
    setLoadingMore(true);

    if (!token) {
      setLoadingMore(false);
      return;
    }

    try {
      const { data } = await API.graphql({
        ...graphqlOperation(queries.productsbyCategory, {
          categoryID: categoryId,
          limit: 10,
          nextToken: token,
        }),
        authMode: "AWS_IAM",
      });
      const {
        productsbyCategory: { items: products, nextToken } = { items: [] },
      } = data || {};
      setLoadingMore(false);
      setItems([
        ...items,
        ...products.filter(
          (x) => x && !x._deleted && x.product && !x.product._deleted
        ),
      ]);
      setToken(nextToken);
    } catch (err) {
      console.log("err", err);
      setLoadingMore(false);
    }
  };

  const infiniteRef = useInfiniteScroll({
    loading: loadingMore,
    hasNextPage: Boolean(token),
    onLoadMore: handleLoadMore,
    scrollContainer: "window",
  });

  const sortedProducts = _orderBy(
    items,
    ["product.position", "product.status"],
    ["asc", "asc"]
  ).filter((x) => !x?.product?._deleted && x?.product?.status === "ACTIVE");

  return (
    <>
      <ScrollToTopOnMount />
      {/* <RouterBreadcrumbs title={category.title} delivery={delivery} /> */}
      <Box my={1}>
        {!loading ? (
          <CategoryTitle categoryId={categoryId} category={category} />
        ) : (
          <Skeleton variant="rect" animation="wave" height={32} />
        )}
        {!loading ? (
          category && category.description ? (
            <CategoryDescription category={category} />
          ) : null
        ) : (
          <>
            <Skeleton variant="text" animation="wave" width={"80%"} />
            <Skeleton variant="text" animation="wave" width={"70%"} />
          </>
        )}
      </Box>

      {category && category.id ? (
        <div className={classes.root}>
          <Subcategories
            categoryId={categoryId}
            delivery={delivery}
            fromSite={fromSite}
          />

          {categoryView === "panel" ? (
            <Grid container spacing={2} ref={infiniteRef}>
              {(loading ? Array.from(new Array(6)) : sortedProducts).map(
                (item, index) =>
                  item ? (
                    <ProductCard
                      key={item.id}
                      index={index}
                      product={item.product}
                      delivery={delivery}
                      fromSite={fromSite}
                    />
                  ) : (
                    <ProductCardSkeleton key={index} />
                  )
              )}
              {loadingMore && <ProductCardSkeleton />}
            </Grid>
          ) : (
            <List>
              {(loading ? Array.from(new Array(6)) : sortedProducts).map(
                (item, index) =>
                  item ? (
                    <ProductItem
                      key={item.id}
                      index={index}
                      product={item.product}
                      delivery={delivery}
                      fromSite={fromSite}
                    />
                  ) : (
                    <ProductItemSkeleton key={index} />
                  )
              )}
              {loadingMore && <ProductItemSkeleton />}
            </List>
          )}
        </div>
      ) : (
        <div style={{ flex: 1, height: 0, marginLeft: -16, marginRight: -16 }}>
          {console.log("category", category)}
          <SimplifiedView
            restaurantId={selectedRestaurant.id}
            category={category}
            delivery={delivery}
            fromSite={fromSite}
          />
        </div>
      )}
    </>
  );
};

export default CategoryWidget;
