import React from "react";
import { I18n } from "aws-amplify";
import { DragDropContext } from "react-beautiful-dnd";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { API, graphqlOperation } from "aws-amplify";

import { toCurrency, getGuestPaid, filterOrderItems } from "../../util";
import CurrencyInput from "../CurrencyInput";
import useReducer from "../../reducers";
import GuestItem from "./GuestItem";
import OrderGuestsSelect from "./OrderGuestsSelect";
import * as mutations from "../../graphql/mutations";
import businessLanguage from "../../lib/businessLanguage";

const useStyles = makeStyles((theme) => ({
  list: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
  },
  title: {
    textAlign: "center",
    margin: theme.spacing(1, 0),
  },
  inputInput: {
    textAlign: "right",
    fontSize: 24,
    fontWeight: 700,
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
    height: 0,
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const ByGuest = () => {
  const classes = useStyles();
  const [
    {
      checkoutReducer: { amountToPay },
      restaurantReducer: { currentRestaurant },
      orderReducer: { currentOrder, orderItems, orderPayments },
    },
    dispatch,
  ] = useReducer();
  const filteredOrderItems = React.useMemo(() => filterOrderItems(orderItems), [
    orderItems,
  ]);
  const btype =
    (currentRestaurant && currentRestaurant.businessType) || "RESTAURANT";
  let items = {};
  filteredOrderItems.map((oi) => (items = { ...items, [oi.id]: oi }));
  let guests = {};

  Array.from(
    { length: currentOrder.guests + 1 },
    (i, index) =>
      (guests = {
        ...guests,
        [index]: {
          id: index,
          label:
            index === 0
              ? I18n.get(businessLanguage[btype]["Table"])
              : `${I18n.get("Guest")} ${index}`,
          items: filteredOrderItems
            .filter(
              (x) =>
                x.guest === index &&
                ["ORDERED", "FIRED", "READY", "DELIVERED"].includes(x.status)
            )
            .map((x) => x.id),
        },
      })
  );
  const guestsOrder = Object.keys(guests);
  const initialData = {
    items,
    guests,
    guestsOrder,
  };
  const [state, setState] = React.useState(initialData);

  React.useEffect(() => {
    setState((prev) => ({
      ...prev,
      guests,
      guestsOrder,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentOrder.guests, filteredOrderItems]);

  console.log("ORDERITEMS", filteredOrderItems);
  console.log("guests", guests);

  const onDragEnd = async (result) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const start = state.guests[source.droppableId];
    const finish = state.guests[destination.droppableId];

    if (start === finish) {
      return;
    }

    // Moving from one list to another
    const startItemIds = Array.from(start.items);
    startItemIds.splice(source.index, 1);
    const newStart = {
      ...start,
      items: startItemIds,
    };

    const finishItemIds = Array.from(finish.items);
    finishItemIds.splice(destination.index, 0, draggableId);
    const newFinish = {
      ...finish,
      items: finishItemIds,
    };

    setState((prev) => ({
      ...prev,
      guests: {
        ...prev.guests,
        [newStart.id]: newStart,
        [newFinish.id]: newFinish,
      },
    }));

    const original = filteredOrderItems.find((x) => x.id === draggableId);

    await API.graphql({
      ...graphqlOperation(mutations.updateOrderItem, {
        input: {
          id: original.id,
          guest: +destination.droppableId,
          _version: original._version,
        },
      }),
      authMode: "AWS_IAM",
    });
  };

  console.log("guestsOrder", guestsOrder);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <>
        <Box flex={1} display="flex" flexDirection="column">
          <Typography variant="h5" className={classes.title}>
            {I18n.get("Divide by guest")}
          </Typography>
          <OrderGuestsSelect />
          <Divider light />
          <Box className={classes.root}>
            <List className={classes.list}>
              {state.guestsOrder.map((guestId) => {
                const guest = state.guests[guestId];
                const oitems = (
                  guest.items.map((itemId) => state.items[itemId]) || []
                ).filter(Boolean);
                const isPaid = getGuestPaid(orderPayments, guest.id);

                console.log("oitems", oitems);

                return (
                  <GuestItem
                    key={guest.id}
                    guest={guest}
                    orderItems={oitems}
                    isPaid={isPaid}
                  />
                );
              })}
            </List>
          </Box>

          <Box m={1}>
            <CurrencyInput
              Component={TextField}
              variant="filled"
              fullWidth
              placeholder={I18n.get("Amount to pay")}
              InputProps={{
                classes: { input: classes.inputInput },
                readOnly: true,
              }}
              value={
                amountToPay ? toCurrency((amountToPay || 0).toString()) : ""
              }
            />
          </Box>
        </Box>

        <Box p={2} textAlign="center">
          <Button
            onClick={() =>
              dispatch({
                type: "CHECKOUT_SET_STEP",
                payload: { checkoutStep: 0 },
              })
            }
            size="large"
            className={classes.backButton}
          >
            {I18n.get("Back")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            disabled={!amountToPay}
            onClick={() =>
              dispatch({
                type: "CHECKOUT_SET_STEP",
                payload: { checkoutStep: 3 },
              })
            }
          >
            {I18n.get("Next")}
          </Button>
        </Box>
      </>
    </DragDropContext>
  );
};

export default ByGuest;
