import React from "react";
import moment from "moment";
import { I18n } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import { useParams, useHistory, NavLink } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import { API, graphqlOperation } from "aws-amplify";
import Skeleton from "@material-ui/lab/Skeleton";

import * as queries from "../graphql/queries";
import { toCurrency, getCardData, isEmptyObject } from "../util";
import { Button } from "@material-ui/core";
import DownloadInvoiceButtons from "../components/Invoicing/DownloadInvoiceButtons";
import SignedInRoute from "../components/SignedInRoute";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  boxItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  button: {
    marginTop: theme.spacing(1),
  },
}));

const PaymentDetailPage = () => {
  const [loading, setLoading] = React.useState(true);
  const classes = useStyles();
  let { paymentId } = useParams();
  const history = useHistory();
  const [payment, setPayment] = React.useState({});

  React.useEffect(() => {
    let mounted = true;

    if (!paymentId) {
      history.replace("/payments");
    }

    const fetchData = async () => {
      try {
        const { data } = await API.graphql({
          ...graphqlOperation(queries.getPayment, {
            id: paymentId,
          }),
          authMode: "AWS_IAM",
        });
        const { getPayment: response = {} } = data || {};
        // console.log("getPayment response", response);

        if (!response || isEmptyObject(response)) {
          history.replace("/orders");
        }

        if (mounted) {
          setPayment(response);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    fetchData();
    return () => {
      mounted = false;
    };
  }, [history, paymentId]);

  const isThisMonth = moment().isSame(payment.createdAt, "month");
  // console.log("isThisMonth", isThisMonth);

  return (
    <SignedInRoute>
      <>
        <Box my={1}>
          <Typography variant="h4">{I18n.get("Payment Detail")}</Typography>
          <Typography variant="body1">{paymentId}</Typography>
        </Box>
        <Paper className={classes.paper}>
          <Box>
            <Box className={classes.boxItem}>
              <Typography variant="subtitle1">{I18n.get("Order")}</Typography>

              {loading ? (
                <Skeleton variant="text" width="50%" animation="wave" />
              ) : (
                <Typography style={{ textAlign: "right" }}>
                  {payment &&
                    payment.order &&
                    payment.order.id &&
                    payment.order.id.substring(0, 8)}
                </Typography>
              )}
            </Box>
            <Box className={classes.boxItem}>
              <Typography variant="subtitle1">{I18n.get("Place")}</Typography>

              {loading ? (
                <Skeleton variant="text" width="50%" animation="wave" />
              ) : (
                <Typography style={{ textAlign: "right" }}>
                  {payment &&
                    payment.order &&
                    payment.order.restaurant &&
                    payment.order.restaurant.name}
                </Typography>
              )}
            </Box>
            <Box className={classes.boxItem}>
              <Typography variant="subtitle1">{I18n.get("Created")}</Typography>

              {loading ? (
                <Skeleton variant="text" width="50%" animation="wave" />
              ) : (
                <Typography>
                  {moment(payment.createdAt).format("LLL")}
                </Typography>
              )}
            </Box>
            <Box className={classes.boxItem}>
              <Typography variant="subtitle1">{I18n.get("Card")}</Typography>

              {loading ? (
                <Skeleton variant="text" width="50%" animation="wave" />
              ) : (
                <Typography>{getCardData(payment.paymentData)}</Typography>
              )}
            </Box>

            <Box className={classes.boxItem}>
              <Typography variant="subtitle1">{I18n.get("Amount")}</Typography>
              {loading ? (
                <Skeleton variant="text" width="50%" animation="wave" />
              ) : (
                <Typography>
                  $ {toCurrency(((payment && payment.amount) || 0).toString())}
                </Typography>
              )}
            </Box>
          </Box>
        </Paper>
        <Paper className={classes.paper}>
          <Typography variant="h6">Factura</Typography>
          {!loading ? (
            <>
              {isThisMonth && !payment.invoiceID ? (
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  className={classes.button}
                  component={NavLink}
                  to={`/invoicing?pid=${paymentId}`}
                >
                  Generar factura
                </Button>
              ) : null}
              {!isThisMonth && !payment.invoiceID ? (
                <Typography>No hay factura disponible</Typography>
              ) : null}
              {payment.invoiceID ? (
                <DownloadInvoiceButtons invoiceID={payment.invoiceID} />
              ) : null}
            </>
          ) : (
            <Skeleton />
          )}
        </Paper>
      </>
    </SignedInRoute>
  );
};

export default PaymentDetailPage;
