import React from "react";
import cx from "clsx";
import moment from "moment";
import makeStyles from "@material-ui/styles/makeStyles";
import Card from "@material-ui/core/Card";
import CardMedia from "@material-ui/core/CardMedia";
import { useParams, Redirect, NavLink, useHistory } from "react-router-dom";
import CardContent from "@material-ui/core/CardContent";
import { API, graphqlOperation } from "aws-amplify";
import Img from "react-image";
import Skeleton from "@material-ui/lab/Skeleton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import RoomIcon from "@material-ui/icons/Room";

import TextInfoContent from "@mui-treasury/components/content/textInfo";
import { useWideCardMediaStyles } from "@mui-treasury/styles/cardMedia/wide";
import { useFadedShadowStyles } from "@mui-treasury/styles/shadow/faded";
import { useN04TextInfoContentStyles } from "@mui-treasury/styles/textInfoContent/n04";

import * as queries from "../graphql/queries";
import Photo from "../components/Photo";
import { isEmptyObject, JSONParse, getPictureUrl } from "../util";
import { Typography, Box, Button, Divider } from "@material-ui/core";
import { I18n } from "aws-amplify";
import Loading from "../components/Loading";
import Markdown from "../components/Markdown";
import useReducer from "../reducers";
import { Helmet } from "react-helmet";

const initialShadow = `0 4px 6px 2px rgba(0,0,0,0.08), 0px 2px 4px 0px rgba(0,0,0,0.24), inset 0 -3px 0 0 rgba(0,0,0,0.12)`;
const useBtnStyles = makeStyles(({ palette }) => ({
  root: {
    borderRadius: 50,
    "&:hover": {
      borderBottom: "none",
    },
  },
  label: {
    textTransform: "initial",
    marginTop: -2,
  },
  contained: {
    boxShadow: "none",
    backgroundColor: palette.grey[200],
    "&:focus": {
      transform: "translateY(-1px)",
      boxShadow: initialShadow,
    },
    "&:active": {
      boxShadow: `inset 0 4px 4px 0 rgba(0,0,0,0.14)`,
    },
  },
  containedPrimary: {
    textShadow: "0 1px 0 rgba(0,0,0,0.2)",
    transition: "0.2s",
    background: `linear-gradient(to top, ${palette.primary.main}, ${palette.primary.light})`,
    boxShadow: initialShadow,
    transform: "translateY(-1px)",
    "& $label": {
      textTransform: "uppercase",
      letterSpacing: "0.5px",
    },
  },
  text: {
    padding: "6px 16px",
  },
}));

const GraphicButton = ({ children, ...other }) => {
  const styles = useBtnStyles();
  return (
    <Button
      variant="contained"
      color="primary"
      classes={{
        root: styles.root,
        label: styles.label,
        text: styles.text,
        contained: styles.contained,
        containedPrimary: styles.containedPrimary,
      }}
      {...other}
    >
      {children}
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "initial",
    maxWidth: 414,
    width: "100%",
    margin: "auto",
    backgroundColor: "transparent",
  },
  title: {
    marginBottom: 0,
  },
  rateValue: {
    fontWeight: "bold",
    marginTop: 2,
  },
  content: {
    position: "relative",
    padding: 24,
    margin: "16px 16px 0",
    backgroundColor: theme.palette.background.paper,
    borderRadius: 4,
  },
  favorite: {
    position: "absolute",
    top: 12,
    right: 12,
  },
  locationIcon: {
    marginRight: 4,
    fontSize: 18,
  },
  heading: {
    color: `${theme.palette.text.secondary} !important`,
  },
  img: {
    height: 64,
  },
}));

const Commerce = () => {
  const styles = useStyles();
  const [, dispatch] = useReducer();
  const mediaStyles = useWideCardMediaStyles();
  const shadowStyles = useFadedShadowStyles();
  const textCardContentStyles = useN04TextInfoContentStyles();
  const [loading, setLoading] = React.useState(true);
  const [commerce, setCommerce] = React.useState({});
  const { slug } = useParams();
  const history = useHistory();
  const dayofweek = moment().weekday();

  React.useEffect(() => {
    let mounted = true;
    API.graphql({
      ...graphqlOperation(queries.bySlug, { slug }),
      authMode: "AWS_IAM",
    }).then((bySlugResponse) => {
      const { items: [restaurant] = { items: [] } } =
        (bySlugResponse && bySlugResponse.data && bySlugResponse.data.bySlug) ||
        null;
      if (
        restaurant &&
        !restaurant._deleted &&
        restaurant.status !== "INACTIVE"
      ) {
        // console.log("commerce", restaurant);
        if (mounted) {
          setCommerce(restaurant);
          if (restaurant?.primaryColor || restaurant?.company?.primaryColor) {
            dispatch({
              type: "THEME_SET_CUSTOM",
              payload: {
                primaryColor:
                  restaurant?.primaryColor || restaurant?.company?.primaryColor,
              },
            });
          }
        }
      }
      if (mounted) {
        setLoading(false);
      }
    });

    return () => {
      mounted = false;
    };
  }, [slug]);

  const picture =
    commerce && commerce.picture ? JSONParse(commerce.picture) : {};
  const cardPicture =
    commerce && commerce.cardPicture ? JSONParse(commerce.cardPicture) : {};
  const imageUrl =
    commerce && commerce.picture
      ? getPictureUrl({ ...picture, size: "0x144" })
      : null;
  const cardPictureUrl =
    commerce && commerce.cardPicture
      ? getPictureUrl({ ...cardPicture, size: "521x0" })
      : null;
  const { formatted_address } =
    commerce && commerce.address ? JSONParse(commerce.address) : {};
  const parsedSchedule =
    commerce && commerce.schedule ? JSONParse(commerce.schedule) : {};
  const btype =
    commerce && commerce.businessType ? commerce.businessType : "RESTAURANT";

  if (!loading && (!commerce || isEmptyObject(commerce))) {
    return <Redirect to={`/main`} />;
  }

  if (loading) {
    return <Loading force />;
  }

  return (
    <div style={{ flex: 1 }}>
      <Helmet>
        <title>{commerce.name}</title>
        <meta name="description" content={commerce.description} />
      </Helmet>
      <Card elevation={0} className={styles.root}>
        {loading ? (
          <Skeleton
            variant="rect"
            animation="wave"
            height={232}
            width="100%"
          ></Skeleton>
        ) : (
          <CardMedia classes={mediaStyles} image={cardPictureUrl || imageUrl} />
        )}

        <CardContent className={cx(shadowStyles.root, styles.content)}>
          <TextInfoContent
            classes={textCardContentStyles}
            overline={
              <Img
                src={[imageUrl]}
                alt={commerce && commerce.name}
                onClick={() => history.push(`/d/r/${commerce.id}`)}
                loader={
                  <Skeleton
                    variant="rect"
                    animation="wave"
                    width={48}
                    height={48}
                  />
                }
                unloader={<Photo />}
                style={{
                  height: 96,
                  maxWidth: "100%",
                  objectFit: "contain",
                  margin: "auto",
                }}
              />
            }
            heading={!imageUrl ? commerce && commerce.name : null}
            bodyProps={{ className: styles.heading, component: "div" }}
            body={
              commerce && commerce.descriptionMarkdown ? (
                <Markdown>{commerce.descriptionMarkdown}</Markdown>
              ) : (
                commerce.description || null
              )
            }
          />

          <Box mt={2} textAlign="center">
            <GraphicButton component={NavLink} to={`/d/r/${commerce.id}`}>
              {I18n.get(btype === "RESTAURANT" ? "See menu" : "Enter")}
            </GraphicButton>
          </Box>

          {commerce && commerce.schedule ? (
            <>
              <Divider variant="middle" style={{ marginTop: 16 }} />
              <Box mt={2} textAlign="center">
                <Typography variant="body1">{I18n.get("Hours")}</Typography>
                {[
                  "Monday",
                  "Tuesday",
                  "Wednesday",
                  "Thursday",
                  "Friday",
                  "Saturday",
                  "Sunday",
                ].map((s, index) => {
                  return (
                    <Box
                      key={s}
                      width={280}
                      margin="auto"
                      display="flex"
                      justifyContent="space-between"
                    >
                      <Typography
                        style={index === dayofweek ? { fontWeight: "700" } : {}}
                      >
                        {I18n.get(s)}
                      </Typography>
                      <Typography
                        style={index === dayofweek ? { fontWeight: "700" } : {}}
                      >
                        {(
                          (parsedSchedule[s] && (parsedSchedule[s] || {})) ||
                          {}
                        )?.closed
                          ? "Cerrado"
                          : parsedSchedule[s] && parsedSchedule[s].range
                          ? `${parsedSchedule[s].range[0]} - ${parsedSchedule[s].range[1]}`
                          : `Abierto`}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </>
          ) : null}
        </CardContent>
      </Card>

      <List
        subheader={
          commerce.contactPhone ||
          commerce.contactEmail ||
          formatted_address ? (
            <ListSubheader>{I18n.get("Contact")}</ListSubheader>
          ) : null
        }
        className={styles.root}
      >
        {commerce.contactPhone ? (
          <ListItem
            button
            component={"a"}
            href={`https://wa.me/${
              commerce &&
              commerce.contactPhone &&
              commerce.contactPhone.replace("+", "")
            }?text=${encodeURIComponent("Hola, quiero hacer un pedido")}`}
          >
            <ListItemIcon>
              <WhatsAppIcon />
            </ListItemIcon>
            <ListItemText
              id="switch-list-label-wifi"
              primary="Phone number"
              secondary={commerce.contactPhone || "No especificado"}
            />
          </ListItem>
        ) : null}
        {commerce.contactEmail ? (
          <ListItem
            button
            component="a"
            href={`mailto:${commerce.contactEmail}`}
          >
            <ListItemIcon>
              <AlternateEmailIcon />
            </ListItemIcon>
            <ListItemText
              id="switch-list-label-bluetooth"
              primary="Email address"
              secondary={commerce.contactEmail || "No especificado"}
            />
          </ListItem>
        ) : null}
        {formatted_address ? (
          <ListItem
            button
            component="a"
            href={`https://www.google.com/maps/place/${encodeURIComponent(
              formatted_address
            )}`}
          >
            <ListItemIcon>
              <RoomIcon />
            </ListItemIcon>
            <ListItemText
              id="switch-list-label-bluetooth"
              primary={I18n.get("Address")}
              secondary={formatted_address || "No especificado"}
            />
          </ListItem>
        ) : null}
      </List>

      {formatted_address ? (
        !loading ? (
          <div
            style={{
              minHeight: 180,
              marginTop: 8,
              width: "100%",
              maxWidth: 414,
              position: "relative",
              margin: "auto",
              textAlign: "center",
            }}
          >
            <iframe
              title="map"
              src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_API_KEY}&q=${formatted_address}`}
              // width="400"
              height="300"
              frameBorder="0"
              style={{
                maxWidth: 400,
                width: "100%",
                border: 0,
                margin: "auto",
              }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          </div>
        ) : (
          <Skeleton variant="rect" animation="wave" height={180} width="100%" />
        )
      ) : null}
    </div>
  );
};

export default Commerce;
