import React from "react";
import { I18n } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
// import Slide from "@material-ui/core/Slide";
import { useHistory, useParams } from "react-router-dom";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import { Box } from "@material-ui/core";
import { NavLink as RouterLink } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import Skeleton from "@material-ui/lab/Skeleton";

import * as queries from "../graphql/queries";
import OrderItemStatusComponent from "./Order/OrderItemStatus";
import { toCurrency, getSelectedModifiersString } from "../util";
import useReducer from "../reducers";
import businessLanguage from "../lib/businessLanguage";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    overflow: "hidden",
    lineHeight: 1,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  formControl: {
    minWidth: 120,
  },
  paper: {
    width: "80%",
    maxHeight: 400,
  },
}));

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const OrderItemDialog = () => {
  const classes = useStyles();
  const history = useHistory();
  const { oiid } = useParams();
  const [
    {
      restaurantReducer: { currentRestaurant },
    },
    dispatch,
  ] = useReducer();
  const { businessType } = currentRestaurant || {};
  const [orderItem, setOrderItem] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const { modifiers } = orderItem || {};
  const btype = businessType || "RESTAURANT";

  const selectedModifiersString = React.useMemo(
    () => getSelectedModifiersString(modifiers),
    [modifiers]
  );

  React.useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      const { data } = await API.graphql({
        ...graphqlOperation(queries.getOrderItem, {
          id: oiid,
          filter: {
            itemType: { or: [{ ne: "DISCOUNT" }, { ne: "COUPON" }] },
            status: {
              or: [
                { eq: "DELIVERED" },
                { eq: "READY" },
                { eq: "ORDERED" },
                { eq: "FIRED" },
              ],
            },
          },
        }),
        authMode: "AWS_IAM",
      });
      const { getOrderItem: response } = data || {};
      console.log("response", response);

      if (mounted) {
        setLoading(false);
        setOrderItem(response);
      }
    };

    fetchData();

    return () => {
      mounted = false;
    };
  }, [oiid]);

  const back = (e) => {
    console.log("back");
    e.stopPropagation();
    history.goBack();
  };

  return (
    <Dialog
      container={() => document.getElementById("mainBox")}
      open={true}
      // TransitionComponent={Transition}
      classes={{ paper: classes.paper }}
      style={{
        position: "absolute",
      }}
      BackdropProps={{
        style: {
          position: "absolute",
        },
      }}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={back}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {orderItem.title || oiid}
          </Typography>
        </Toolbar>
      </AppBar>

      {/* <ProductHeader productId={''} product={{}} dialog /> */}

      <List dense>
        <ListItem>
          <ListItemText
            primary={I18n.get(businessLanguage[btype]["Quantity"])}
          />
          <ListItemSecondaryAction>
            {loading ? (
              <Skeleton variant="text" animation="wave" width={80} />
            ) : (
              <Typography variant="caption">{orderItem.qty}</Typography>
            )}
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText primary={I18n.get("Amount")} />
          <ListItemSecondaryAction>
            {loading ? (
              <Skeleton variant="text" animation="wave" width={80} />
            ) : (
              <Typography variant="caption">
                $ {toCurrency((orderItem.amount || 0).toString())}
              </Typography>
            )}
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemText
            primary={I18n.get("Modifiers")}
            secondary={
              loading ? (
                <Skeleton variant="text" animation="wave" />
              ) : (
                selectedModifiersString
              )
            }
          />
        </ListItem>
        <Divider />
        {orderItem.notes && (
          <>
            <ListItem>
              <ListItemText
                primary={I18n.get("Notes")}
                secondary={orderItem.notes}
              />
            </ListItem>
            <Divider />
          </>
        )}
        <ListItem>
          <ListItemText
            primary={I18n.get("Status")}
            secondary={I18n.get("Order item status")}
          />
          <ListItemSecondaryAction>
            {loading ? (
              <Skeleton variant="text" animation="wave" width={80} />
            ) : (
              <OrderItemStatusComponent status={orderItem.status} />
            )}
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
      </List>
      <Box pb={1} px={1} display="flex" justifyContent="flex-end">
        <Button
          size="small"
          color="primary"
          component={RouterLink}
          disabled={loading}
          onClick={() => {
            dispatch({ type: "TABS_SET_TAB", payload: { tab: 0 } });
          }}
          to={{
            pathname: `/t/product/${orderItem.productID}`,
            state: {
              reorder: {
                modifiers: orderItem.modifiers,
                qty: orderItem.qty,
                notes: orderItem.notes,
              },
            },
          }}
        >
          {I18n.get("Re-order")}
        </Button>
      </Box>
    </Dialog>
  );
};

export default OrderItemDialog;
