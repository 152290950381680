import React from "react";

import useReducer from "../reducers";
import businessLanguage from "../lib/businessLanguage";
import MenusWidget from "./Menu/MenusWidget";

const MainMenus = () => {
  const [
    {
      loadingReducer: { loadingApp },
      restaurantReducer: { currentRestaurant },
    },
  ] = useReducer();
  const btype =
    currentRestaurant && currentRestaurant.businessType
      ? currentRestaurant.businessType
      : "RESTAURANT";
  const { menus: { items: menus = [] } = { items: [] } } =
    currentRestaurant || {};

  return (
    <MenusWidget
      title={businessLanguage[btype]["Menus"]}
      loading={loadingApp}
      menus={menus}
    />
  );
};

export default MainMenus;
