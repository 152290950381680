import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import useReducer from '../reducers';
import Loading from './Loading';

function PublicRoute({ children, ...rest }) {
  const [{ orderReducer, loadingReducer }] = useReducer();

  if (loadingReducer.loadingApp) {
    return <Loading force />;
  }

  return (
    <Route
      {...rest}
      render={() =>
        !orderReducer.currentOrder ? (
          children
        ) : (
          <Redirect to={{ pathname: '/' }} />
        )
      }
    />
  );
}

export default PublicRoute;
