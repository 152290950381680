import React from "react";
import { I18n } from "aws-amplify";
import { Typography } from "@material-ui/core";
import SignedInRoute from "../components/SignedInRoute";
import PaymentsComponent from "../components/Payments/PaymentsComponent";

const Payments = () => {
  return (
    <SignedInRoute>
      <>
        <Typography variant="h4">{I18n.get("Payments")}</Typography>
        <PaymentsComponent />
      </>
    </SignedInRoute>
  );
};

export default Payments;
