const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);

export const handleScan = async (data, history, formik) => {
  if (data) {
    console.log("scan result", data);
    const parsedData = data.trim();

    if (
      parsedData.startsWith(`https://www.2dine.io/s/`) ||
      parsedData.startsWith(`https://2dine.io/s/`)
    ) {
      const response = decodeURIComponent(parsedData);
      console.log("commerce QR response", response);
      const commerceSlug = response.split("/").pop();

      history.push(`/s/${commerceSlug}`);
      return;
    }

    if (
      parsedData.startsWith(`https://www.2dine.io/c/`) ||
      parsedData.startsWith(`https://2dine.io/c/`)
    ) {
      const response = decodeURIComponent(parsedData);
      console.log("companyResponse QR response", response);
      const companySlug = response.split("/").pop();

      history.push(`/c/${companySlug}`);
      return;
    }

    if (
      parsedData.startsWith("2dine#") ||
      parsedData.startsWith(`${publicUrl.origin}/qr-scan?tt=2dine#`) ||
      parsedData.startsWith(`https://www.2dine.io/qr-scan?tt=2dine#`) ||
      parsedData.startsWith(`https://2dine.io/qr-scan?tt=2dine#`) ||
      parsedData.startsWith("2dine%23") ||
      parsedData.startsWith(`${publicUrl.origin}/qr-scan?tt=2dine%23`) ||
      parsedData.startsWith(`https://www.2dine.io/qr-scan?tt=2dine%23`) ||
      parsedData.startsWith(`https://2dine.io/qr-scan?tt=2dine%23`)
    ) {
      const response = decodeURIComponent(parsedData);
      console.log("response", response);
      const parsedToken = response.split("#").pop();

      formik.setFieldValue("tableToken", parsedToken);
      await Promise.resolve();
      const isValidToken = /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(
        parsedToken
      );
      console.log("isValidToken", isValidToken);
      if (
        parsedToken &&
        parsedToken !== formik.values.tableToken &&
        isValidToken
      ) {
        formik.handleSubmit(parsedToken);
      }
    }
  }
};

export const handleError = (err, setInvalidBrowser) => {
  console.log("Camera initialization error", err);

  if (err) {
    if (err.name === "NoVideoInputDevicesError") {
      setInvalidBrowser(true);
    }
  }
};
