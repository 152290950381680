import React from "react";
import moment from "moment";
import { I18n } from "aws-amplify";
import SvgIcon from "@material-ui/core/SvgIcon";
import { API, graphqlOperation } from "aws-amplify";
import { Cache } from "aws-amplify";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, Paper } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import * as queries from "../../graphql/queries";
import useQuery from "../../hooks/useQuery";
import useReducer from "../../reducers";
import { ReactComponent as CompletedIcon } from "../../svg/undraw_completed.svg";
import GenerateInvoiceButton from "./GenerateInvoiceButton";
import { toCurrency } from "../../util";

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: "center",
  },
  icon: {
    fontSize: 80,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
}));

const PayementSuccess = () => {
  const classes = useStyles();
  const query = useQuery();
  const history = useHistory();
  const location = useLocation();
  const [
    {
      orderReducer: { currentOrder },
    },
    dispatch,
  ] = useReducer();
  const [session, setSession] = React.useState({});
  const sessionId = query.get("session_id");
  const ot = query.get("ot");
  const sessionAccountId = query.get("accountId");
  const isTravel = ot === "travel";
  const isDelivery = ot === "todeliver" || ot === "togo" || ot === "dinein";
  const isBond = ot === "bond";
  console.log("session", session);
  console.log("orderType", ot);

  React.useEffect(() => {
    let mounted = true;
    dispatch({
      type: "LOADING_START",
      payload: { message: "Checking payment data" },
    });
    const cachedCurrentDeliveryOrderId = Cache.getItem(
      "currentDeliveryOrderId"
    );
    const cachedOrderId = Cache.getItem("currentOrderId");
    const cachedBondOrderId = Cache.getItem("bondOrderId");
    const currOrder = isDelivery
      ? cachedCurrentDeliveryOrderId
      : isBond
      ? cachedBondOrderId
      : cachedOrderId;

    const getSession = async () => {
      let apiName = "api616cdc2a";
      let path = "/stripe/session";

      if (currOrder) {
        const orderQuery = API.graphql({
          ...graphqlOperation(queries.getOrder, {
            id: currOrder,
          }),
          authMode: "AWS_IAM",
        });

        const { data } = (await orderQuery) || {};
        const { getOrder: order } = data || {};
        console.log("order", order);

        if (order?.restaurant) {
          dispatch({
            type: "DELIVERY_RESTAURANT_SET",
            payload: { selectedRestaurant: order?.restaurant },
          });

          if (order?.restaurant?.accountId) {
            let pathAccount = "/stripe/account";

            try {
              const result = await API.get(apiName, pathAccount, {
                queryStringParameters: {
                  account: order?.restaurant?.accountId,
                },
              });
              const chargesEnabled = Boolean(result.charges_enabled);
              console.log("/stripe/account response", result);
              dispatch({
                type: "CHECKOUT_SET_ACCOUNT_CHARGES_ENABLED",
                payload: {
                  accountChargesEnabled: chargesEnabled,
                },
              });
            } catch (error) {
              console.log("error", error);
            }
          }
        }

        if (order?.restaurant?.primaryColor) {
          dispatch({
            type: "THEME_SET_CUSTOM",
            payload: {
              primaryColor: order?.restaurant?.primaryColor,
            },
          });
        }
        if (!order || (order && order._deleted)) {
          dispatch({ type: "LOADING_STOP" });
          if (!isDelivery) {
            if (!isBond) {
              history.replace("/");
            } else {
              history.replace("/bonos");
            }
          } else {
            history.replace("/d");
          }
        }
      }

      const queryStringParameters = {
        session: sessionId,
      };

      if (sessionAccountId) {
        queryStringParameters.stripeAccount = sessionAccountId;
      }

      const session = await API.get(apiName, path, {
        queryStringParameters,
      });
      console.log("session", session);

      if (!session || session.client_reference_id !== currOrder) {
        dispatch({ type: "LOADING_STOP" });

        if (isTravel) {
          history.replace(`/d/order/${session.client_reference_id}`);
          return;
        }

        if (!isDelivery) {
          if (!isBond) {
            history.replace("/");
          } else {
            history.replace("/bonos");
          }
        } else {
          history.replace("/d");
        }
      } else {
        if (mounted) {
          setSession(session);
        }

        dispatch({ type: "LOADING_STOP" });
      }
    };

    getSession();

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBond, isDelivery, isTravel, sessionAccountId, sessionId]);

  const handleContinue = async () => {
    if (
      !currentOrder ||
      (currentOrder &&
        ["CLOSED", "CANCELLED", "DELETED"].includes(currentOrder.status))
    ) {
      await Cache.removeItem("currentOrderId");
      dispatch({ type: "ORDER_RESET" });
      dispatch({ type: "RESTAURANT_RESET" });
      dispatch({ type: "CHECKOUT_RESET" });
      history.push("/main");
    } else {
      history.push({
        pathname: "/checkout",
        state: { background: location },
      });
    }
  };

  const handleDelvieryContinue = async () => {
    Cache.removeItem("deliveryCart");
    Cache.removeItem("cartRestaurant");
    Cache.removeItem("cartCompany");
    history.push({ pathname: `/d/order/${session.client_reference_id}` });
  };

  const handleBondContinue = async () => {
    history.push({
      pathname: `/d/order/${session.client_reference_id}`,
      search: `?ot=bond`,
    });
  };

  return (
    <>
      <Box
        flex={1}
        flexDirection="column"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="h4" className={classes.title}>
          {I18n.get("Payment Succeeds")}
        </Typography>
        <SvgIcon
          component={CompletedIcon}
          viewBox="0 0 809.83511 872.15034"
          style={{ fontSize: 120 }}
        />
        {session && session.payment_intent ? (
          <Paper className={classes.paper}>
            <List dense>
              <ListItem>
                <ListItemText
                  primary={I18n.get("Payment")}
                  secondary={session.payment_intent.id}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={I18n.get("Date")}
                  secondary={moment
                    .unix(session.payment_intent.created)
                    .format("LLL")}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={I18n.get("Order")}
                  secondary={session.client_reference_id}
                />
              </ListItem>
              <ListItem>
                <ListItemText
                  primary={I18n.get("Amount")}
                  secondary={
                    "$" +
                    toCurrency(
                      (
                        (session &&
                          session.payment_intent &&
                          session.payment_intent.amount_received) ||
                        0
                      ).toString()
                    )
                  }
                />
              </ListItem>
            </List>

            {session && session.id && !isBond ? (
              <GenerateInvoiceButton
                orderId={session.client_reference_id}
                stripePaymentId={session.id}
              />
            ) : null}
          </Paper>
        ) : null}
        <div>
          <Button
            size="large"
            variant="contained"
            color="primary"
            onClick={
              isDelivery
                ? handleDelvieryContinue
                : isBond
                ? handleBondContinue
                : handleContinue
            }
          >
            {I18n.get("Continue")}
          </Button>
        </div>
      </Box>
    </>
  );
};

export default PayementSuccess;
