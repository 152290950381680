import React from "react";
import clsx from "clsx";
import { I18n } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { API, graphqlOperation } from "aws-amplify";

import useReducer from "../../reducers";
import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";

const ValidationSchema = Yup.object().shape({
  name: Yup.string(),
});

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1, 0),
  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
}));

const UserNameComponent = () => {
  const classes = useStyles();
  const [
    {
      loginReducer: { currentUser },
    },
    dispatch,
  ] = useReducer();
  const [editMode, setEditMode] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      name: currentUser.name || "",
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      const newName = values.name.trim() || null;

      if (currentUser.name === newName || !newName) {
        return;
      }

      const { data } = await API.graphql({
        ...graphqlOperation(queries.getUser, { id: currentUser.id }),
        authMode: "AWS_IAM",
      });
      const { getUser: user } = data || {};
      const { data: updateData } = await API.graphql({
        ...graphqlOperation(mutations.updateUser, {
          input: {
            id: currentUser.id,
            name: newName,
            _version: user._version,
          },
        }),
        authMode: "AWS_IAM",
      });
      const { updateUser: updateUserResponse } = updateData || {};

      console.log("updatedUser", updateUserResponse);
      dispatch({
        type: "LOGIN_SET_USER",
        payload: { user: updateUserResponse },
      });
    },
  });

  return !editMode ? (
    <>
      <Typography variant="h4" onClick={() => setEditMode(true)}>
        <span
          style={{
            textDecoration: "underline",
            textDecorationStyle: "dotted",
          }}
        >
          {currentUser.name || I18n.get("Add your name")}
        </span>{" "}
        <EditIcon />
      </Typography>
    </>
  ) : (
    <form onSubmit={formik.handleSubmit}>
      <FormControl
        fullWidth
        className={clsx(classes.margin)}
        variant="outlined"
      >
        <InputLabel htmlFor="name">{I18n.get("Update name")}</InputLabel>
        <OutlinedInput
          id="name"
          onChange={formik.handleChange}
          value={formik.values.name}
          autoFocus
          onBlur={() => {
            setEditMode(false);
            formik.handleSubmit();
          }}
          endAdornment={
            <InputAdornment position="end">
              <IconButton type="submit" aria-label="save changes" edge="end">
                <CheckCircleIcon />
              </IconButton>
            </InputAdornment>
          }
          labelWidth={98}
        />
      </FormControl>
    </form>
  );
};

export default UserNameComponent;
