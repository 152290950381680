import React from "react";
import { I18n } from "aws-amplify";
import moment from "moment";
import { useSnackbar } from "notistack";
import SvgIcon from "@material-ui/core/SvgIcon";
import { API, graphqlOperation } from "aws-amplify";
// import CheckCircleIcon from "@material-ui/icons/CheckCircle";
// import WarningIcon from "@material-ui/icons/Warning";
import QRCode from "qrcode.react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Typography,
  Paper,
  makeStyles,
  Button,
  TextField,
  Collapse,
  Grid,
} from "@material-ui/core";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

import { ReactComponent as ConfirmationIcon } from "../../svg/confirmation.svg";
import { ReactComponent as SentIcon } from "../../svg/on_the_way.svg";
import { ReactComponent as ChefIcon } from "../../svg/chef.svg";
import { ReactComponent as ConfirmedIcon } from "../../svg/order_confirmed.svg";
import { ReactComponent as ArrivedIcon } from "../../svg/arrived.svg";
import { useParams, useHistory, Redirect } from "react-router-dom";
import {
  isEmptyObject,
  toCurrency,
  emailRegex,
  getOrderTotal,
  JSONParse,
  getRemainingAmount,
  getAmountPaid,
  isSafariBrowser,
  getCardData,
  getItemTotal,
  getOrderSurchargesAmount,
  filterOrderItems,
  getDiscountItems,
  getDiscountAmount,
} from "../../util";
import * as queries from "../../graphql/queries";
import Skeleton from "@material-ui/lab/Skeleton";
import useReducer from "../../reducers";
import useQuery from "../../hooks/useQuery";
import bondEmailTemplate from "../../emailTemplates/bondEmailTemplate";
import OrderItemStatusComponent from "../Order/OrderItemStatus";
import useCheckout from "../../hooks/useCheckout";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  itemsPaper: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  bottomSpacing: {
    marginBottom: theme.spacing(7),
  },
  boxItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  table: {
    minWidth: 560,
  },
}));

const DeliveryOrder = () => {
  const classes = useStyles();
  const history = useHistory();
  let { orderId } = useParams();
  const checkout = useCheckout();
  const query = useQuery();
  const { enqueueSnackbar } = useSnackbar();
  const [
    {
      loginReducer: { currentUser },
      bondReducer: { bondOrder },
      deliveryReducer: { currentDeliveryOrder },
    },
    dispatch,
  ] = useReducer();
  const [loading, setLoading] = React.useState(true);
  const [notiType, setNotiType] = React.useState(null);
  const [notiValue, setNotiValue] = React.useState("");
  const [isTravel, setIsTravel] = React.useState(false);

  // order type
  const ot = query.get("ot");
  console.log("ot", ot);
  const isBond = ot && ot === "bond";
  const order = !isBond ? currentDeliveryOrder : bondOrder;
  console.log("order", order);

  const {
    payments: { items: payments } = { items: [] },
    items: { items: products } = { items: [] },
    restaurant,
    orderType,
    surcharges,
    deliveryAmount,
  } = order || {};

  const filteredProducts = React.useMemo(
    () => filterOrderItems(products),
    [products]
  );

  const discountItems = React.useMemo(
    () => getDiscountItems(products),
    [products]
  );
  console.log("discountItems", discountItems);

  const orderTotal = React.useMemo(
    () => getOrderTotal(filteredProducts, true),
    [filteredProducts]
  );
  const surchargesAmount = React.useMemo(
    () => getOrderSurchargesAmount(surcharges),
    [surcharges]
  );
  const discountAmount = React.useMemo(
    () => getDiscountAmount(discountItems),
    [discountItems]
  );

  const amountPaid = React.useMemo(() => getAmountPaid(payments), [payments]);

  console.log("orderTotal", orderTotal);
  console.log("deliveryAmount", deliveryAmount);
  console.log("amountPaid", amountPaid);

  const remainingAmount = React.useMemo(
    () =>
      getRemainingAmount(
        orderTotal,
        amountPaid,
        discountAmount,
        deliveryAmount
      ),
    [orderTotal, amountPaid, discountAmount, deliveryAmount]
  );

  console.log("remainingAmount", remainingAmount);

  React.useEffect(() => {
    let mounted = true;

    if (!orderId) {
      if (!isBond) {
        history.replace("/d");
      } else {
        history.replace("/bonos");
      }
    }

    const fetchData = async () => {
      const { data } = await API.graphql({
        ...graphqlOperation(queries.getOrder, {
          id: orderId,
        }),
        authMode: "AWS_IAM",
      });
      const { getOrder: response = {} } = data || {};
      console.log("getOrder response", response);

      if (!response || isEmptyObject(response)) {
        enqueueSnackbar(I18n.get("Order not found!"), {
          variant: "info",
          autoHideDuration: 3000,
        });
        if (!isBond) {
          history.replace("/d");
        } else {
          history.replace("/bonos");
        }
      }

      if (mounted) {
        if (!isBond) {
          const { orderType } = response || {};
          setIsTravel(orderType === "travel");
          dispatch({
            type: "DELIVERY_SET_ORDER",
            payload: { currentDeliveryOrder: response },
          });
        } else {
          dispatch({
            type: "BOND_SET_BOND_ORDER",
            payload: { bondOrder: response },
          });
        }

        if (response?.restaurant) {
          dispatch({
            type: "DELIVERY_RESTAURANT_SET",
            payload: { selectedRestaurant: response?.restaurant },
          });

          if (response?.restaurant?.accountId) {
            let apiName = "api616cdc2a";
            let path = "/stripe/account";

            try {
              const result = await API.get(apiName, path, {
                queryStringParameters: {
                  account: response?.restaurant?.accountId,
                },
              });
              console.log("/stripe/account response", result);
              dispatch({
                type: "CHECKOUT_SET_ACCOUNT_CHARGES_ENABLED",
                payload: {
                  accountChargesEnabled: Boolean(result.charges_enabled),
                },
              });
            } catch (error) {
              console.log("error", error);
            }
          }
        }

        if (response?.restaurant?.primaryColor) {
          dispatch({
            type: "THEME_SET_CUSTOM",
            payload: {
              primaryColor: response?.restaurant?.primaryColor,
            },
          });
        }

        setLoading(false);
      }
    };

    fetchData();
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const handleStatus = (status) => {
    switch (status) {
      case "NEW":
      case "OPEN":
        return "Order created successfully!";
      case "ACCEPTED":
        return "Order accepted by the restaurant!";
      case "PREPARING":
        return "Your order is preparing!";
      case "SENT":
        return "Your order is comming to you!";
      case "DELIVERED":
        return "Your order has been delivered";
      default:
        return "";
    }
  };

  const handleStatusIcon = (status) => {
    switch (status) {
      case "NEW":
      case "OPEN":
        return (
          <SvgIcon
            component={ConfirmationIcon}
            viewBox="0 0 1149.12 755.51"
            style={{ fontSize: 180 }}
          />
        );
      case "ACCEPTED":
        return (
          <SvgIcon
            component={ConfirmedIcon}
            viewBox="0 0 902.567 748.955"
            style={{ fontSize: 180 }}
          />
        );
      case "PREPARING":
        return (
          <SvgIcon
            component={ChefIcon}
            viewBox="0 0 890.06924 692.72819"
            style={{ fontSize: 180 }}
          />
        );
      case "SENT":
        return (
          <SvgIcon
            component={SentIcon}
            viewBox="0 0 1056.29 808.9"
            style={{ fontSize: 180 }}
          />
        );
      case "DELIVERED":
        return (
          <SvgIcon
            component={ArrivedIcon}
            viewBox="0 0 1034 823.8434"
            style={{ fontSize: 180 }}
          />
        );
      default:
        return "";
    }
  };

  const handleNoti = (type) => () => {
    setNotiValue("");
    console.log("notitype", type);
    setNotiType(type);
  };

  const handleSend = async () => {
    let apiName = "api616cdc2a";
    let path =
      notiType === "sms" ? "/notifications/sms" : "/notifications/email";

    dispatch({ type: "LOADING_START" });
    try {
      if (notiType === "sms" && !isValidPhoneNumber(notiValue)) {
        enqueueSnackbar(
          "Ingresa un número de celular válido, verifica tu código de país.",
          {
            variant: "error",
            autoHideDuration: 3000,
          }
        );
        dispatch({ type: "LOADING_STOP" });
        return;
      }

      if (notiType === "email" && !emailRegex.test(notiValue)) {
        enqueueSnackbar("Tye a valid email address", {
          variant: "error",
          autoHideDuration: 3000,
        });
        dispatch({ type: "LOADING_STOP" });
        return;
      }

      const result = await API.post(apiName, path, {
        body: {
          attributes:
            notiType === "sms"
              ? {
                  phone_number: notiValue,
                  message: `2dine Bono Gastronómico. Enviamos tu bono de ${
                    order && order.restuarant && order.restaurant.name
                  } el cual puedes consultar en ${
                    window.location.origin
                  }/d/order/${orderId}`,
                }
              : {
                  email: notiValue,
                  subject: `2dine | Bono Gastronómico de ${
                    order && order.restaurant && order.restaurant.name
                  }`,
                  template: bondEmailTemplate({
                    orderId,
                    link: `${window.location.origin}/d/order/${orderId}`,
                  }),
                },
        },
      });
      console.log("result", result);

      enqueueSnackbar("¡Tu bono se ha enviado correctamente!", {
        variant: "success",
        autoHideDuration: 3000,
      });
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } finally {
      dispatch({ type: "LOADING_STOP" });
    }
  };

  if (!order && !loading) {
    if (!isBond) {
      return <Redirect to="/d" />;
    } else {
      return <Redirect to="/bonos" />;
    }
  }

  const handlePay = async () => {
    dispatch({ type: "LOADING_START" });

    const lineItems = [
      {
        name: restaurant?.name,
        description: "Your purchase in 2dine",
        amount: remainingAmount - (deliveryAmount || 0),
        currency: (restaurant?.currency || "usd").toLowerCase(),
        quantity: 1,
      },
    ];

    if (deliveryAmount && orderType === "todeliver") {
      lineItems.push({
        name: "Delivery",
        description: "Delivery charge",
        amount: deliveryAmount,
        currency: (restaurant?.currency || "usd").toLowerCase(),
        quantity: 1,
      });
    }

    console.log("lineItems", lineItems);

    const metadata = {
      userId: currentUser && currentUser.id ? currentUser.id : null,
      orderType: orderType || "todeliver",
      restaurantName: restaurant ? restaurant.name : null,
      restaurant: restaurant ? restaurant.id : null,
    };

    console.log("metadata", metadata);
    await checkout({ lineItems, orderId, metadata });
  };

  return (
    <>
      {!isBond ? (
        <Box my={1}>
          <Typography variant="h4">{I18n.get("Order Detail")}</Typography>
        </Box>
      ) : null}
      <Paper className={classes.paper}>
        {!isBond ? (
          !isTravel ? (
            <Box
              display="flex"
              alignItems="center"
              flexDirection="column"
              textAlign="center"
            >
              {loading ? (
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height={120}
                  width={180}
                  style={{ marginTop: 24, marginBottom: 16 }}
                />
              ) : (
                handleStatusIcon(order && order.status)
              )}
              {loading ? (
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height={32}
                  width="80%"
                  style={{ marginBottom: 16 }}
                />
              ) : (
                <Typography
                  variant="h5"
                  color="primary"
                  style={{
                    marginTop: -16,
                    marginBottom: 24,
                    fontWeight: "700",
                  }}
                >
                  {I18n.get(handleStatus(order && order.status))}
                </Typography>
              )}
            </Box>
          ) : null
        ) : (
          <Box textAlign="center">
            <Typography variant="h5" color="primary">
              Compra realizada exitosamente
            </Typography>
            <Typography variant="h6">Tu Bono Gastronómico</Typography>
            <Box
              my={2}
              textAlign="center"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              {loading ? (
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height={220}
                  width={200}
                />
              ) : (
                <QRCode
                  value={`${window.location.origin}/d/order/${orderId}`}
                  size={220}
                  bgColor={"#ffffff"}
                  fgColor={"#000000"}
                  level={"Q"}
                  includeMargin
                  renderAs={"svg"}
                  imageSettings={{
                    src: "/assets/comecdmx-ico.png",
                    x: null,
                    y: null,
                    height: 56,
                    width: 56,
                    excavate: true,
                  }}
                />
              )}

              {loading ? (
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height={56}
                  width={"50%"}
                  style={{ marginTop: 16 }}
                />
              ) : (
                <Typography variant="h3" style={{ marginTop: 16 }}>
                  {order && order.restaurant && order.restaurant.name}
                </Typography>
              )}
              {loading ? (
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height={72}
                  width={"70%"}
                />
              ) : (
                <Typography variant="h2" component="div">
                  ${" "}
                  {toCurrency(
                    (
                      (payments && payments.length && payments[0].amount) ||
                      0
                    ).toString()
                  )}
                </Typography>
              )}
            </Box>
          </Box>
        )}

        <Box>
          <Box className={classes.boxItem}>
            <Typography variant="subtitle1">{I18n.get("Serie")}</Typography>
            {loading ? (
              <Skeleton variant="text" animation="wave" width={"50%"} />
            ) : (
              <Typography>{order && order.orderSerie}</Typography>
            )}
          </Box>
          <Box className={classes.boxItem}>
            <Typography variant="subtitle1">{I18n.get("Order")}</Typography>
            {loading ? (
              <Skeleton variant="text" animation="wave" width={"50%"} />
            ) : (
              <Typography style={{ textAlign: "right" }}>
                {orderId.substr(0, 8)}
              </Typography>
            )}
          </Box>
          {!isBond ? (
            <Box className={classes.boxItem}>
              <Typography variant="subtitle1">{I18n.get("Place")}</Typography>
              {loading ? (
                <Skeleton variant="text" animation="wave" width={"50%"} />
              ) : (
                <Typography>
                  {order && order.restaurant && order.restaurant.name}
                </Typography>
              )}
            </Box>
          ) : null}
          <Box className={classes.boxItem}>
            <Typography variant="subtitle1">{I18n.get("Created")}</Typography>
            {loading ? (
              <Skeleton variant="text" animation="wave" width={"50%"} />
            ) : (
              <Typography>
                {moment(order && order.createdAt).format("LLL")}
              </Typography>
            )}
          </Box>
          {!isBond && order && order.closedAt ? (
            <Box className={classes.boxItem}>
              <Typography variant="subtitle1">{I18n.get("Closed")}</Typography>
              {loading ? (
                <Skeleton variant="text" animation="wave" width={"50%"} />
              ) : (
                <Typography>
                  {moment(order && order.closedAt).format("LLL")}
                </Typography>
              )}
            </Box>
          ) : null}

          {!isTravel ? (
            <>
              <Box className={classes.boxItem}>
                <Typography variant="subtitle1">
                  {I18n.get("Amount")}
                </Typography>
                {loading ? (
                  <Skeleton variant="text" animation="wave" width={"50%"} />
                ) : (
                  <Typography>
                    ${" "}
                    {toCurrency(
                      (orderTotal + surchargesAmount || 0).toString()
                    )}
                  </Typography>
                )}
              </Box>
              <Box className={classes.boxItem}>
                <Typography variant="subtitle1">
                  {I18n.get("Delivery Amount")}
                </Typography>
                {loading ? (
                  <Skeleton variant="text" animation="wave" width={"50%"} />
                ) : (
                  <Typography>
                    $ {toCurrency((deliveryAmount || 0).toString())}
                  </Typography>
                )}
              </Box>
              <Box className={classes.boxItem}>
                <Typography variant="subtitle1">
                  {I18n.get("Discount")}
                </Typography>
                {loading ? (
                  <Skeleton variant="text" animation="wave" width={"50%"} />
                ) : (
                  <Typography>
                    $ ({toCurrency((discountAmount || 0).toString())})
                  </Typography>
                )}
              </Box>
            </>
          ) : null}
          <Box className={classes.boxItem}>
            <Typography variant="subtitle1" style={{ fontWeight: "700" }}>
              {I18n.get("Total")}
            </Typography>
            {loading ? (
              <Skeleton variant="text" animation="wave" width={"50%"} />
            ) : (
              <Typography style={{ fontWeight: "700" }}>
                ${" "}
                {toCurrency(
                  (
                    orderTotal +
                      deliveryAmount +
                      surchargesAmount -
                      discountAmount || 0
                  ).toString()
                )}
              </Typography>
            )}
          </Box>

          {!isBond ? (
            <Box className={classes.boxItem}>
              <Typography variant="subtitle1" style={{ fontWeight: "700" }}>
                {I18n.get("Amount paid")}
              </Typography>
              {loading ? (
                <Skeleton variant="text" animation="wave" width={"30%"} />
              ) : (
                <Typography style={{ fontWeight: "700" }}>
                  $ {toCurrency(amountPaid.toString())}
                </Typography>
              )}
            </Box>
          ) : null}
          {!isBond ? (
            <Box className={classes.boxItem}>
              <Typography variant="subtitle1" style={{ fontWeight: "700" }}>
                {I18n.get("Pending amount")}
              </Typography>
              {loading ? (
                <Skeleton variant="text" animation="wave" width={"30%"} />
              ) : (
                <Typography style={{ fontWeight: "700" }}>
                  $ {toCurrency(remainingAmount.toString())}
                </Typography>
              )}
            </Box>
          ) : null}
          {/**
            {!isTravel ? (
              <Box className={classes.boxItem}>
                <Typography variant="subtitle1">{I18n.get("Paid")}</Typography>
                <Box display="flex" alignItems="center">
                  {order?.restaurant?.cardPayments &&
                  !(order && order.isPaid) &&
                  !["DELETED", "CANCELLED"].includes(order && order.status) ? (
                    <Button
                      color="primary"
                      style={{ marginRight: 8 }}
                      onClick={handlePay}
                    >
                      {I18n.get("Pay")}
                    </Button>
                  ) : null}
                  {loading ? (
                    <Skeleton variant="text" animation="wave" width={"10%"} />
                  ) : order && order.isPaid ? (
                    <CheckCircleIcon color="primary" />
                  ) : (
                    <WarningIcon color="error" />
                  )}
                </Box>
              </Box>
            ) : null}
          */}

          {remainingAmount &&
          order?.restaurant?.businessType !== "HOTEL" &&
          !(order && order.isPaid) &&
          !["DELETED", "CANCELLED"].includes(order && order.status) ? (
            <>
              <Typography
                variant="subtitle1"
                color="primary"
                style={{ marginTop: 16, marginBottom: 8 }}
              >
                Método de pago
              </Typography>
              <Grid container spacing={2}>
                {order?.restaurant?.cardPayments ? (
                  <Grid item xs={6}>
                    {isTravel ? (
                      <Button
                        component={RouterLink}
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        to={{
                          pathname: `/payment/card`,
                          search: `?o=${orderId}`,
                        }}
                      >
                        Pago TD/TC
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth
                        onClick={handlePay}
                      >
                        Pago TD/TC
                      </Button>
                    )}
                  </Grid>
                ) : null}
                {order?.restaurant?.oxxoPayments ? (
                  <Grid item xs={6}>
                    <Button
                      component={RouterLink}
                      variant="contained"
                      color="primary"
                      size="large"
                      fullWidth
                      to={{
                        pathname: `/payment/oxxo`,
                        search: `?o=${orderId}`,
                      }}
                    >
                      Pago OXXO
                    </Button>
                  </Grid>
                ) : null}
              </Grid>
            </>
          ) : null}

          {isTravel ? (
            <>
              <Typography
                variant="subtitle1"
                color="primary"
                style={{ marginTop: 16, marginBottom: 8 }}
              >
                Voucher
              </Typography>
              <Button
                component={RouterLink}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                to={{ pathname: `/coupon/${orderId}` }}
              >
                Descargar voucher
              </Button>
            </>
          ) : null}

          {isBond ? (
            <>
              <Box my={2} display="flex" justifyContent="space-around">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNoti("sms")}
                >
                  Enviar por SMS
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNoti("email")}
                >
                  Enviar por email
                </Button>
              </Box>
              <Collapse in={!!notiType}>
                <Box>
                  {notiType === "email" ? (
                    <>
                      <TextField
                        autoFocus
                        autoComplete="off"
                        margin="dense"
                        id="email"
                        label={
                          notiType === "email"
                            ? "Email address"
                            : "Phone number"
                        }
                        type={notiType === "email" ? "email" : "tel"}
                        fullWidth
                        value={notiValue}
                        onChange={(event) => setNotiValue(event.target.value)}
                      />
                      <Box mt={1} textAlign="right">
                        <Button variant="outlined" onClick={handleSend}>
                          Enviar
                        </Button>
                      </Box>
                    </>
                  ) : null}
                  {notiType === "sms" ? (
                    <>
                      <PhoneInput
                        autoFocus
                        placeholder="Ingresa tu número de celular"
                        value={notiValue}
                        onChange={(phone) => setNotiValue(phone)}
                        international
                        defaultCountry="MX"
                        inputMode="tel"
                        type="tel"
                      />
                      <Box mt={1} textAlign="right">
                        <Button variant="outlined" onClick={handleSend}>
                          Enviar
                        </Button>
                      </Box>
                    </>
                  ) : null}
                </Box>
              </Collapse>
              <Typography
                variant="caption"
                component="p"
                color="textSecondary"
                style={{ marginTop: 16, textAlign: "justify" }}
              >
                * Es importante que conserves tu bono, si no estás registrado
                guarda una captura de tu bono, elige recibir tu bono por correo
                o por mensaje de texto. Si estás registrado, no te preocupes tu
                bono se encuentra en la sección de ordenes.
              </Typography>{" "}
            </>
          ) : null}
        </Box>
      </Paper>

      {!isBond ? (
        <Paper className={classes.itemsPaper}>
          <Typography className={classes.heading}>
            {I18n.get("Items")}
          </Typography>

          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">{I18n.get("Qty")}</TableCell>
                  <TableCell align="left">{I18n.get("Product")}</TableCell>
                  <TableCell align="left">{I18n.get("Amount")}</TableCell>
                  <TableCell align="left">{I18n.get("Total")}</TableCell>
                  {!isTravel ? (
                    <>
                      <TableCell align="left">{I18n.get("Status")}</TableCell>
                      <TableCell align="left">{I18n.get("Guest")}</TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell align="left">
                        {I18n.get("Reservation Key")}
                      </TableCell>
                    </>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredProducts.map((row) =>
                  row._deleted ? null : (
                    <TableRow key={row.id} hover>
                      <TableCell component="th" scope="row">
                        {row.qty}
                      </TableCell>
                      <TableCell align="left">{row.title}</TableCell>
                      <TableCell align="left">
                        {toCurrency((row.amount || 0).toString())}
                      </TableCell>
                      <TableCell align="left">
                        {toCurrency((getItemTotal(row) || 0).toString())}
                      </TableCell>

                      {!isTravel ? (
                        <>
                          <TableCell align="left">
                            <OrderItemStatusComponent status={row.status} />
                          </TableCell>
                          <TableCell align="left">
                            {!row.guest ? I18n.get("Table") : row.guest}
                          </TableCell>
                        </>
                      ) : (
                        <TableCell align="left">
                          {row && row.travelData
                            ? (JSONParse(row.travelData) || {}).reservationKey
                            : "No definida"}
                        </TableCell>
                      )}
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : null}

      {!isBond ? (
        <Paper className={classes.itemsPaper}>
          <Typography className={classes.heading}>
            {I18n.get("Payments")}
          </Typography>

          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell align="left">{I18n.get("Amount")}</TableCell>
                  <TableCell align="left">
                    {I18n.get("Payment Method")}
                  </TableCell>
                  <TableCell align="left">{I18n.get("ID")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payments.map((row) =>
                  row._deleted ? null : (
                    <TableRow
                      key={row.id}
                      hover
                      onClick={() => history.push(`/payment-detail/${row.id}`)}
                    >
                      <TableCell component="th" scope="row">
                        {moment(row.createdAt).format("MM/DD/YYYY HH:mm")}
                      </TableCell>
                      <TableCell align="left">
                        {toCurrency((row.amount || 0).toString())}
                      </TableCell>
                      <TableCell align="left">
                        {getCardData(row.paymentData)}
                      </TableCell>
                      <TableCell align="left">{row.id.split("-")[0]}</TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      ) : null}

      {isSafariBrowser ? (
        <div id="space" style={{ minHeight: 64, height: 64 }} />
      ) : (
        <div className={classes.bottomSpacing} />
      )}
    </>
  );
};

export default DeliveryOrder;
