import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";

import * as queries from "../../graphql/queries";
// import RouterBreadcrumbs from "../RouterBreadcrumbs";
import ScrollToTopOnMount from "../ScrollToTopOnMount";
import ProductHeader from "./ProductHeader";
import ProductForm from "./ProductForm";

const ProductComponent = ({ fromSite = false, delivery = false }) => {
  const { restaurantId, productId } = useParams();
  const history = useHistory();
  const [loading, setLoading] = React.useState(true);
  const [product, setProduct] = React.useState({});

  React.useEffect(() => {
    let mounted = true;
    const fetchProduct = async () => {
      if (mounted) {
        setLoading(true);
      }

      try {
        const { data } = await API.graphql({
          ...graphqlOperation(queries.getProduct, {
            id: productId,
          }),
          authMode: "AWS_IAM",
        });
        const { getProduct } = data || {};
        console.log("getProduct", getProduct);
        if (!getProduct || getProduct.status !== "ACTIVE") {
          if (!delivery) {
            history.replace("/");
            return;
          } else {
            history.replace(fromSite ? `/d` : `/d/r/${restaurantId}`);
            return;
          }
        } else {
          if (mounted) {
            setProduct(getProduct);
          }
        }
        if (mounted) {
          setLoading(false);
        }
      } catch (error) {
        console.log("error", error);
        if (mounted) {
          setLoading(false);
        }
      }
    };

    if (productId) {
      fetchProduct();
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  return (
    <>
      <ScrollToTopOnMount />
      {/* <RouterBreadcrumbs title={product.title} delivery={delivery} /> */}

      <ProductHeader loading={loading} product={product} delivery={delivery} />

      <ProductForm
        productId={productId}
        product={product}
        delivery={delivery}
        fromSite={fromSite}
      />
    </>
  );
};

export default ProductComponent;
