import React from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  desktopBox: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: theme.palette.secondary.main,
    zIndex: -1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      marginLeft: 414,
      flexDirection: "column",
      justifyContent: "flex-end",
      alignItems: "center",
      padding: theme.spacing(2),
    },
  },
  typo: {
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    display: "flex",
    alignItems: "center",
  },
  typobottom: {
    color: theme.palette.getContrastText(theme.palette.secondary.main),
  },
}));

const DesktopBox = () => {
  const classes = useStyles();

  return (
    <div className={classes.desktopBox}>
      <Divider variant="fullWidth" />
      <Typography variant="h6" component="div" className={classes.typo}>
        <img
          alt="2dine"
          src="/3.B.png"
          style={{ height: 16, marginRight: 4 }}
        />{" "}
        made with{" "}
        <span
          role="img"
          aria-label="rights"
          style={{ marginLeft: 4, marginRight: 4 }}
        >
          💗
        </span>{" "}
        in México
      </Typography>
      <Typography variant="caption" className={classes.typobottom}>
        All rights reserved. © {moment().format("YYYY")} 2dine, Inc.
      </Typography>
    </div>
  );
};

export default DesktopBox;
