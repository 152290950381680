import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { I18n } from "aws-amplify";
import _compact from "lodash/compact";
// import _omit from "lodash/omit";
import _orderBy from "lodash/orderBy";
import { makeStyles } from "@material-ui/core/styles";
import { Box, GridList } from "@material-ui/core";
import { API, graphqlOperation } from "aws-amplify";

import useReducer from "../../reducers";
import * as queries from "../../graphql/queries";
import {
  getSelectedOrderTypeLabel,
  handleProductPrice,
  clearEmpties,
  handleProductPriceTaxes,
  restoreModifiersPrice,
  isEmptyObject,
} from "../../util";
import OrderTypeButton from "./OrderTypeButton";

const useStyles = makeStyles(() => ({
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
  },
}));

const OrderTypeDialog = () => {
  const classes = useStyles();
  const [
    {
      deliveryReducer: {
        principalRestaurant,
        cartRestaurant,
        orderType,
        deliveryCart,
      },
    },
    dispatch,
  ] = useReducer();
  const {
    id: restaurantID,
    hasPreordering,
    hasDinein,
    hasDelivery,
    businessType,
  } = !isEmptyObject(principalRestaurant)
    ? principalRestaurant
    : cartRestaurant || {};
  const isFirstRun = React.useRef(true);

  const isHotel = businessType && businessType === "HOTEL";
  // console.log("OrderTypeDialog deliveryCart", deliveryCart);

  React.useEffect(() => {
    const fetchRestaurant = async () => {
      dispatch({ type: "LOADING_START" });

      const updatedCart = await Promise.all(
        deliveryCart.map(async (item) => {
          const { data } = await API.graphql({
            ...graphqlOperation(queries.getProduct, {
              id: item.productID,
              filter: {
                status: {
                  eq: "ACTIVE",
                },
              },
            }),
            authMode: "AWS_IAM",
          });
          const { getProduct } = data || {};

          const { restaurant, ...product } =
            getProduct && !getProduct._deleted ? getProduct : {};

          if (!product) {
            return null;
          }

          const productPrice = handleProductPrice(
            product,
            orderType !== "dinein"
          );
          console.log("productPrice", productPrice);

          const filteredModifiers = _compact(
            _orderBy(
              product?.modifiers?.items || [],
              ["position"],
              ["asc"]
            ).map((x) => {
              if (
                x._deleted ||
                x?.modifier?._deleted ||
                x?.modifier?.status !== "ACTIVE"
              ) {
                return null;
              }
              return { ...x.modifier, productModifierPosition: x.position };
            })
          );

          const restoredModifiers = restoreModifiersPrice(
            item.modifiers,
            filteredModifiers
          );

          let input = {
            ...item,
            price: productPrice,
            modifiers:
              restoredModifiers && restoredModifiers.length
                ? JSON.stringify(restoredModifiers)
                : null,
          };

          input = handleProductPriceTaxes(input, restaurant);

          clearEmpties(input);
          return input;
        })
      );

      console.log("dsc", _compact(updatedCart));
      dispatch({
        type: "DELIVERY_SET_CART",
        payload: { deliveryCart: _compact(updatedCart) },
      });

      dispatch({ type: "LOADING_STOP" });
    };

    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    if (restaurantID && orderType) {
      fetchRestaurant();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurantID, orderType]);

  React.useEffect(() => {
    if (isHotel) {
      dispatch({
        type: "DELIVERY_SET_ORDER_TYPE",
        payload: { orderType: "dinein" },
      });
    } else {
      if (hasPreordering && !hasDelivery) {
        dispatch({
          type: "DELIVERY_SET_ORDER_TYPE",
          payload: { orderType: "togo" },
        });
      } else if (!hasPreordering && hasDelivery) {
        dispatch({
          type: "DELIVERY_SET_ORDER_TYPE",
          payload: { orderType: "todeliver" },
        });
      } else {
        dispatch({
          type: "DELIVERY_SET_ORDER_TYPE",
          payload: { orderType: "todeliver" },
        });
      }
    }
  }, [isHotel, hasPreordering, hasDinein, hasDelivery]);

  if (isHotel) {
    return null;
  }

  return (
    <>
      <ListItem>
        <ListItemText
          primary={I18n.get("Order Type")}
          secondary={I18n.get(getSelectedOrderTypeLabel(orderType))}
        />
      </ListItem>
      <Box width={"100%"} p={1}>
        <GridList
          className={classes.gridList}
          cellHeight={48}
          cols={2.3}
          spacing={8}
        >
          {hasDinein ? (
            <OrderTypeButton label={"Dine In"} type="dinein" />
          ) : null}
          {hasDelivery ? (
            <OrderTypeButton label={"Delivery"} type="todeliver" />
          ) : null}
          {hasPreordering ? (
            <OrderTypeButton label={"Pick & Go"} type="togo" />
          ) : null}
        </GridList>
      </Box>
    </>
  );
};

export default OrderTypeDialog;
