const initialState = {
  loadingApp: true,
  loading: false,
  message: 'Loading...'
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOADING_TOGGLE':
      return {
        ...state,
        loading: !state.loading
      };
    case 'LOADING_STOP':
      return {
        ...state,
        loading: false,
        message: 'Loading...'
      };
    case 'LOADING_START':
      return {
        ...state,
        loading: true,
        message: action.payload ? action.payload.message : state.message
      };
    case 'LOADING_SET_MESSAGE':
      return { ...state, message: action.payload.message };
    case 'LOADING_APP_COMPLETED':
      return { ...state, loadingApp: false };
    default:
      return state;
  }
};

export default { initialState, reducer };
