import React from "react";
import _orderBy from "lodash/orderBy";
import Box from "@material-ui/core/Box";
import { API, I18n } from "aws-amplify";

import MenuList from "./MenuList";
import useReducer from "../../reducers";
import { isSafariBrowser } from "../../util";
import * as queries from "../../graphql/queries";
import { Button } from "@material-ui/core";

const MenuComponent = ({
  selectedRestaurant,
  fromSite = false,
  delivery = false,
}) => {
  const [
    {
      restaurantReducer: { currentRestaurant },
    },
  ] = useReducer();
  const { id: restaurantID } = delivery
    ? selectedRestaurant
    : currentRestaurant || {};
  const [loading, setLoading] = React.useState(true);
  const [categories, setCategories] = React.useState([]);
  const [nextToken, setNextToken] = React.useState();
  const hasMore = Boolean(nextToken);

  React.useEffect(() => {
    let mounted = true;
    if (restaurantID) {
      fetchCategories({
        categoryRestaurantId: restaurantID,
        filter: { status: { eq: "ACTIVE" } },
        limit: 20,
      })
        .then(({ data }) => {
          const { categoriesbyRestaurant } = data || {};
          if (mounted) {
            setCategories(categoriesbyRestaurant.items);
            setNextToken(categoriesbyRestaurant.nextToken);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
        });
    }

    return () => {
      mounted = false;
    };
  }, [restaurantID]);

  function fetchCategories(variables) {
    return API.graphql({
      query: queries.categoriesbyRestaurant,
      variables,
      authMode: "AWS_IAM",
    });
  }

  function handleLoadMore() {
    setLoading(true);

    if (!nextToken) {
      setLoading(false);
      return;
    }

    fetchCategories({
      categoryRestaurantId: restaurantID,
      filter: {
        and: [
          { type: { ne: "KITCHEN" } },
          { type: { ne: "AREA" } },
          { type: { ne: "SECTION" } },
          { status: { eq: "ACTIVE" } },
        ],
      },
      limit: 10,
      nextToken,
    }).then(({ data }) => {
      const { categoriesbyRestaurant } = data || {};
      setLoading(false);
      setNextToken(categoriesbyRestaurant.nextToken);
      setCategories([...categories, ...categoriesbyRestaurant.items]);
    });
  }

  const sortedCategories = _orderBy(categories, ["position"], ["asc"]);

  return sortedCategories && sortedCategories.length ? (
    <Box mb={8}>
      {sortedCategories.map((category) =>
        category._deleted || category.status !== "ACTIVE" ? null : (
          <MenuList
            key={category.id}
            category={category}
            restaurantId={selectedRestaurant && selectedRestaurant.id}
            delivery={delivery}
            fromSite={fromSite}
          />
        )
      )}
      {hasMore ? (
        <Button
          style={{ marginTop: 8 }}
          onClick={handleLoadMore}
          disabled={loading || !hasMore}
        >
          {I18n.get("Load more...")}
        </Button>
      ) : null}
      {isSafariBrowser ? (
        <div id="space" style={{ minHeight: 64, height: 64 }} />
      ) : null}
    </Box>
  ) : null;
};

export default MenuComponent;
