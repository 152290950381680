import React from "react";
import { I18n } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import OrderItemComponent from "./OrderItem";
import NoDataItem from "./NoDataItem";
import OrderSummary from "./OrderSummary";
import useReducer from "../../reducers";
import { handleGetGroupedItems } from "../../util";
import businessLanguage from "../../lib/businessLanguage";

const useStyles = makeStyles((theme) => ({
  fullList: {
    width: "auto",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    height: 1,
  },
  title: {
    fontWeight: 700,
  },
}));

const OrderedItems = () => {
  const classes = useStyles();
  const [
    {
      orderReducer: { currentOrder, orderItems, discountItems },
      restaurantReducer: { currentRestaurant },
    },
  ] = useReducer();
  const { businessType } = currentRestaurant || {};
  const btype = businessType || "RESTAURANT";

  const getOrderedItems = (items) => {
    return handleGetGroupedItems(
      items && items.length
        ? items.filter(
            (c) =>
              ["ORDERED", "FIRED", "READY", "DELIVERED"].includes(c.status) &&
              !["DISCOUNT", "COUPON", "REFUND"].includes(c.itemType)
          )
        : []
    );
  };

  const orderedItems = React.useMemo(() => getOrderedItems(orderItems), [
    orderItems,
  ]);

  console.log("discountItems", discountItems);

  return (
    <div role="presentation" className={classes.fullList}>
      <Box px={2} display="flex" justifyContent="space-between">
        <Typography variant="subtitle1" className={classes.title}>
          {I18n.get("Ordered Items")}
        </Typography>
        <Box textAlign="right">
          {currentOrder && currentOrder.orderSerie ? (
            <Typography variant="caption" component="div">
              {I18n.get("Serie")} {currentOrder && currentOrder.orderSerie}
            </Typography>
          ) : null}
          {currentOrder && currentOrder.table && currentOrder.table.title ? (
            <Typography variant="caption" component="div" color="primary">
              {I18n.get(businessLanguage[btype]["Table"])}{" "}
              {currentOrder.table.title}
            </Typography>
          ) : null}
        </Box>
      </Box>

      <List dense style={{ flex: 1, overflow: "auto" }}>
        {orderedItems && orderedItems.length ? (
          orderedItems.map((item) => (
            <OrderItemComponent key={item.id} item={item} ordered />
          ))
        ) : (
          <NoDataItem
            title={I18n.get("No ordered items in your cart")}
            secondary={I18n.get("Add items to your order")}
          />
        )}
      </List>

      <OrderSummary />
    </div>
  );
};

export default OrderedItems;
