import React from "react";
import { Typography, Box } from "@material-ui/core";
import IconContainer, { customIcons } from "./IconContainer";
import { StyledRating } from "./Review.styled";

const ProductStep = ({ formik }) => {
  return (
    <>
      <Box mt={3} mb={2} borderColor="transparent">
        <Typography component="legend">Calidad de los alimentos</Typography>
        <Typography component="legend" color="textSecondary">
          La calidad de tus alimentos y bebidas.
        </Typography>
        <StyledRating
          id="product"
          name="product"
          onChange={(event, newValue) => {
            formik.setFieldValue("product", newValue);
          }}
          value={formik.values.product}
          getLabelText={(value) => customIcons[value].label}
          IconContainerComponent={IconContainer}
          size="large"
        />
      </Box>
      <Box mb={2} borderColor="transparent">
        <Typography component="legend">El lugar</Typography>
        <Typography component="legend" color="textSecondary">
          El mobiliario, distribución, ambientación, iluminación y sonido, ¿Qué
          te parecio?
        </Typography>
        <StyledRating
          id="place"
          name="place"
          onChange={(event, newValue) => {
            formik.setFieldValue("place", newValue);
          }}
          value={formik.values.place}
          getLabelText={(value) => customIcons[value].label}
          IconContainerComponent={IconContainer}
          size="large"
        />
      </Box>
      <Box mb={2} borderColor="transparent">
        <Typography component="legend">Limpieza</Typography>
        <Typography component="legend" color="textSecondary">
          ¿Cómo estuvo la limpieza del lugar?
        </Typography>
        <StyledRating
          id="cleaning"
          name="cleaning"
          onChange={(event, newValue) => {
            formik.setFieldValue("cleaning", newValue);
          }}
          value={formik.values.cleaning}
          getLabelText={(value) => customIcons[value].label}
          IconContainerComponent={IconContainer}
          size="large"
        />
      </Box>
    </>
  );
};

export default ProductStep;
