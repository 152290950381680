import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { ButtonBase, Typography } from "@material-ui/core";
import { I18n } from "aws-amplify";
import { getTotal, toCurrency } from "../../util";
import useReducer from "../../reducers";

const useStyles = makeStyles((theme) => ({
  appBar: {
    top: "auto",
    bottom: 0,
    // color: theme.palette.background.paper,
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.up("sm")]: {
      maxWidth: 414,
      left: 0,
      right: "auto",
    },
  },
  disabled: {
    opacity: 0.9,
    color: theme.palette.text.disabled,
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto",
  },
  toolbar: {
    width: "100%",
  },
}));

const CartButton = ({ onClick = () => null }) => {
  const classes = useStyles();
  const [
    {
      deliveryReducer: {
        deliveryCart,
        deliveryOrderAmount,
        deliveryOrderTaxes,
      },
    },
  ] = useReducer();
  const cartLength = (deliveryCart && deliveryCart.length) || 0;

  const total = React.useMemo(
    () => getTotal(deliveryOrderAmount, deliveryOrderTaxes),
    [deliveryOrderAmount, deliveryOrderTaxes]
  );

  const itemsCount = React.useMemo(
    () =>
      cartLength
        ? deliveryCart.reduce((sum, val) => sum + (val.qty || 0), 0)
        : 0,
    [cartLength, deliveryCart]
  );

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, { [classes.disabled]: !itemsCount })}
    >
      <ButtonBase onClick={onClick} disabled={!itemsCount}>
        <Toolbar className={classes.toolbar}>
          <ShoppingCartIcon style={{ marginRight: 16 }} />
          <Typography variant="button">
            {I18n.get("Cart")} ({itemsCount})
          </Typography>
          <div className={classes.grow} />
          <Typography variant="button">
            ${toCurrency((total || 0).toString())}
          </Typography>
        </Toolbar>
      </ButtonBase>
    </AppBar>
  );
};

export default CartButton;
