import React from "react";
import { I18n } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import businessLanguage from "../../lib/businessLanguage";
import useReducer from "../../reducers";
import QtyComponent from "./QtyComponent";

const useStyles = makeStyles(() => ({
  details: {
    alignItems: "center",
    justifyContent: "center",
  },
}));

const QuantityButtons = ({
  orderItemId,
  status,
  needsEndDate,
  quantityLabel,
  minQuantity,
  maxQuantity,
  formik,
}) => {
  const [
    {
      restaurantReducer: { currentRestaurant },
    },
  ] = useReducer();
  const classes = useStyles();
  const { businessType } = currentRestaurant || {};
  const btype = businessType || "RESTAURANT";

  const handleRemoveQty = () => {
    if (formik.values.qty > (orderItemId ? 0 : 1)) {
      formik.setFieldValue("qty", formik.values.qty - 1);
    }
  };

  const handleAddQty = () => {
    formik.setFieldValue("qty", formik.values.qty + 1);
  };

  return status && status === "ACTIVE" ? (
    needsEndDate ? null : (
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel-quantity"
          id="panel-quantity"
        >
          <Typography>
            {quantityLabel || I18n.get(businessLanguage[btype]["Quantity"])}
          </Typography>
        </AccordionSummary>
        <AccordionDetails classes={{ root: classes.details }}>
          <QtyComponent
            isDelete={!(!orderItemId || formik.values.qty > 1)}
            minQuantity={minQuantity}
            maxQuantity={maxQuantity}
            value={formik.values.qty}
            handleRemoveQty={handleRemoveQty}
            handleAddQty={handleAddQty}
          />
        </AccordionDetails>
      </Accordion>
    )
  ) : null;
};

export default QuantityButtons;
