import React from "react";

const usePaypal = (
  currency = "MXN",
  paypalClientId = process.env.REACT_APP_PAYPAL_CLIENT_ID
) => {
  const [paypal, setPaypal] = React.useState(null);

  React.useEffect(() => {
    let mounted = true;
    if (window.paypal) {
      if (mounted) {
        setPaypal(window.paypal);
      }
    } else {
      var PAYPAL_SCRIPT = `https://www.paypal.com/sdk/js?client-id=${paypalClientId}&currency=${currency}&disable-funding=credit,card`;
      var script = document.createElement("script");
      script.setAttribute("src", PAYPAL_SCRIPT);
      script.setAttribute("id", "paypal-js");
      document.head.appendChild(script);

      document.querySelector("#paypal-js").addEventListener("load", () => {
        // Create Stripe instance once Stripe.js loads
        if (mounted) {
          setPaypal(window.paypal);
        }
      });
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paypalClientId]);

  return paypal;
};

export default usePaypal;
