var domain = document.location.origin;
var currUrl = window.location.href;

export default (customManifest) => {
  var manifest = {
    name: "2Dine Self Ordering System",
    description: "El sistema más avanzado de self-ordering & menú digital",
    start_url: currUrl,
    display: "standalone",
    theme_color: "#ffaa00",
    background_color: "#ffaa00",
    orientation: "portrait",
    icons: [
      {
        src: domain + "/android/mipmap-mdpi/ic_launcher.png",
        type: "image/png",
        sizes: "48x48",
        purpose: "any",
      },
      {
        src: domain + "/android/mipmap-hdpi/ic_launcher.png",
        type: "image/png",
        sizes: "72x72",
        purpose: "any",
      },
      {
        src: domain + "/android/mipmap-xhdpi/ic_launcher.png",
        type: "image/png",
        sizes: "96x96",
        purpose: "any",
      },
      {
        src: domain + "/android/mipmap-xxhdpi/ic_launcher.png",
        type: "image/png",
        sizes: "144x144",
        purpose: "any",
      },
      {
        src: domain + "/android/mipmap-xxxhdpi/ic_launcher.png",
        type: "image/png",
        sizes: "192x192",
        purpose: "any",
      },
      {
        src: domain + "/playstore.png",
        type: "image/png",
        sizes: "512x512",
        purpose: "any",
      },
    ],
    ...customManifest,
  };

  const stringManifest = JSON.stringify(manifest);
  const blob = new Blob([stringManifest], { type: "application/json" });
  const manifestURL = URL.createObjectURL(blob);
  document
    .querySelector("#manifest-placeholder")
    .setAttribute("href", manifestURL);
};
