import React from "react";
import { I18n } from "aws-amplify";
// import { Cache } from 'aws-amplify'
import { usePrevious } from "react-use";
import { useLocation, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import SvgIcon from "@material-ui/core/SvgIcon";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ReceiptIcon from "@material-ui/icons/Receipt";
import PaymentIcon from "@material-ui/icons/Payment";
// import Brightness7 from '@material-ui/icons/Brightness7';
// import Brightness4 from '@material-ui/icons/Brightness4';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

import useReducer from "../../reducers";
import MainContainer from "./MainContainer";
import NavTitle from "./NavTitle";
import MobileSectionList from "./MobileSectionList";
import SecondarySectionList from "./SecondarySectionList";
import BottomButton from "./BottomButton";
import { Divider } from "@material-ui/core";
import businessLanguage from "../../lib/businessLanguage";
import { ReactComponent as QRScan } from "../../svg/qr-code.svg";
import NotificationCheck from "../NotificationsCheck";

const useStyles = makeStyles((theme) => ({
  menuContainer: {
    width: "calc(100% - 32px)",

    [theme.breakpoints.up("sm")]: {
      width: "calc(414px - 32px)",
    },
  },
  divider: {
    marginTop: theme.spacing(1),
  },
}));

const MobileNavigation = ({ fromSite = false }) => {
  const location = useLocation();
  const history = useHistory();
  const prevLocation = usePrevious(location) || {};
  const classes = useStyles();
  const [
    {
      siteReducer: { onlyDelivery },
      restaurantReducer: { currentRestaurant },
      navReducer: { openNav },
      loginReducer: { authState, currentUser },
      deliveryReducer: { selectedRestaurant },
      // themeReducer: { isDark }
    },
    dispatch,
  ] = useReducer();
  // const Icon = isDark ? Brightness7 : Brightness4;
  const currLocation = location.pathname;
  const btype =
    (currentRestaurant && currentRestaurant.businessType) || "RESTAURANT";

  const handleClick = (event) => {
    dispatch({ type: "NAV_OPEN", payload: { openNav: event.currentTarget } });
  };

  const handleClose = () => {
    dispatch({ type: "NAV_CLOSE" });
  };

  React.useEffect(() => {
    if (prevLocation.pathname !== currLocation) {
      handleClose();
    }
  }, [prevLocation.pathname, currLocation]);

  const rootRoutes = [
    "/",
    "/main",
    "/t",
    `/d/r/${selectedRestaurant.id}`,
    "/",
    "/qr-scan",
  ];

  if (fromSite) {
    rootRoutes.push("/d");
  }

  const isRootRoute =
    rootRoutes.includes(location.pathname) ||
    location.pathname.startsWith("/payment");

  return (
    <>
      {isRootRoute ? (
        onlyDelivery ? null : (
          <IconButton edge="start" aria-label="delete" onClick={handleClick}>
            <MenuIcon fontSize="small" />
          </IconButton>
        )
      ) : (
        <IconButton
          edge="start"
          color="inherit"
          onClick={() => history.goBack()}
          aria-label="back"
        >
          <ArrowBackIcon fontSize="small" />
        </IconButton>
      )}

      <Popover
        id="mobile-menu"
        anchorEl={openNav}
        keepMounted
        open={Boolean(openNav)}
        onClose={handleClose}
        classes={{ paper: classes.menuContainer }}
      >
        <MainContainer>
          <NavTitle>{I18n.get("Choose a section")}</NavTitle>
          <MobileSectionList
            links={[
              {
                title: I18n.get(businessLanguage[btype]["Dashboard"]),
                route: "/main",
                icon: <DashboardIcon />,
              },
              {
                title: I18n.get("Scan QR"),
                route: "/qr-scan",
                icon: (
                  <SvgIcon
                    component={QRScan}
                    viewBox="-33 -33 1066.6666 1066.6666"
                    fontSize="small"
                  />
                ),
              },

              {
                title: I18n.get("Orders"),
                route: "/orders",
                icon: <ReceiptIcon />,
              },
              {
                title: I18n.get("Payments"),
                route: "/payments",
                icon: <PaymentIcon />,
              },
            ]}
          />
          <SecondarySectionList
            links={[
              // {
              //   title: 'Profile',
              //   onClick: () => {
              //     dispatch({ type: 'NAV_CLOSE' });
              //     dispatch({ type: 'LOGIN_OPEN' });
              //   }
              // },
              // {
              //   title: 'Search',
              //   route: { pathname: '/search', state: { background: location } }
              // },
              // {
              //   title: I18n.get("How It Works"),
              //   route: { pathname: "/how", state: { background: location } },
              // },
              { title: I18n.get("About"), route: "/about" },

              { title: I18n.get("Settings"), route: "/settings" },
              {
                title: I18n.get("Register my commerce"),
                href: "https://admin.2dine.io",
                component: "a",
                target: "_blank",
              },
              // {
              //   title: isDark ? I18n.get('Light mode') : I18n.get('Dark mode'),
              //   icon: <Icon />,
              //   onClick: () => {
              //     console.log('isDark', !isDark);
              //     Cache.setItem('isDark', !isDark);
              //     dispatch({
              //       type: 'THEME_TOGGLE_MODE',
              //       payload: { isDark: !isDark }
              //     });
              //   }
              // }
            ]}
          />
          <NotificationCheck />
          <Divider light variant="fullWidth" className={classes.divider} />
          <BottomButton
            onClick={() => {
              dispatch({ type: "NAV_CLOSE" });
              dispatch({ type: "LOGIN_OPEN" });
            }}
            title={
              ["loading", "signIn"].includes(authState)
                ? I18n.get("Sign In")
                : currentUser.name || currentUser.username
            }
          />
        </MainContainer>
      </Popover>
    </>
  );
};

export default MobileNavigation;
