// import { Box, Typography } from "@material-ui/core";
import React from "react";

import CategoryWidget from "../components/Category/CategoryWidget";
import { isSafariBrowser } from "../util";

const CategoryPage = () => {
  return (
    <>
      <CategoryWidget />
      {/* <Box>
        <Typography variant="body2">
          Prohibida la venta de bebidas alcohólicas a menores de Edad.
        </Typography>
      </Box> */}
      {isSafariBrowser ? (
        <div id="space" style={{ minHeight: 64, height: 64 }} />
      ) : null}
    </>
  );
};

export default CategoryPage;
