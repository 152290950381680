import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {
  Box,
  Chip,
  GridList,
  GridListTile,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
} from "@material-ui/core";

import {
  JSONParse,
  getPictureUrl,
  handleRestaurantIsEnabled,
} from "../../util";
import useReducer from "../../reducers";
import Skeleton from "@material-ui/lab/Skeleton";
import Photo from "../Photo";
import Img from "react-image";
import { Link } from "react-router-dom";
import { I18n } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  lineClamp: {
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    whiteSpace: "initial",
  },
  bottomPaper: {
    flex: 1,
    width: "100%",
    position: "relative",
  },
  bottomContainer: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  logoContainer: {
    height: 120,
    width: 120,
    borderRadius: "100%",
    background: "#fff",
    position: "absolute",
    top: -110,
    right: 24,
    boxShadow: theme.shadows[3],
  },
  rootCard: {
    maxWidth: "100%",
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  rootGrid: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    // overflow: "hidden",
    marginTop: theme.spacing(2),
  },
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
    paddingBottom: theme.spacing(2),
  },
  gridListMore: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
  },
  tile: {
    height: "100%",
    display: "block",
    overflow: "visible",
    position: "relative",
  },
  img: {
    width: 80,
    height: 80,
    borderRadius: 16,
    objectFit: "cover",
  },
}));

const BottomComponent = () => {
  const classes = useStyles();
  const theme = useTheme();
  // const matches = useMediaQuery("(max-width:374px)");
  const [
    {
      siteReducer: { siteData },
    },
  ] = useReducer();

  const picture =
    siteData && siteData.picture ? JSONParse(siteData.picture) : {};
  const imageUrl =
    siteData && siteData.picture
      ? getPictureUrl({ ...picture, size: "0x144" })
      : null;

  const parsedSchedule =
    siteData && siteData.schedule ? JSONParse(siteData.schedule) : {};

  const resp = handleRestaurantIsEnabled(parsedSchedule);
  const { restaurantIsEnabled } = resp || {};

  const { formatted_address } =
    siteData && siteData.address ? JSONParse(siteData.address) : {};

  return (
    <div className={classes.bottomPaper}>
      <div className={classes.logoContainer}>
        <Img
          src={[imageUrl]}
          alt={siteData && siteData.name}
          loader={
            <Skeleton variant="rect" animation="wave" width={48} height={48} />
          }
          unloader={<Photo />}
          style={{
            height: 80,
            maxWidth: 90,
            objectFit: "contain",
            marginBottom: 8,
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
          }}
        />
      </div>

      <Box className={classes.bottomContainer}>
        <Chip
          size="small"
          label={restaurantIsEnabled ? "Abierto" : `Cerrado, abre mañana`}
          clickable
          color="primary"
          style={{ marginBottom: 8 }}
        />
        <Typography variant="h4" className={classes.lineClamp}>
          {siteData.name}
        </Typography>
        <Typography
          variant="subtitle1"
          color="textSecondary"
          className={classes.lineClamp}
        >
          {siteData.description}
        </Typography>

        <div className={classes.rootGrid}>
          <GridList
            className={classes.gridList}
            cols={1.2}
            cellHeight="auto"
            spacing={16}
          >
            <GridListTile classes={{ tile: classes.tile }}>
              <Card raised className={classes.rootCard}>
                <CardActionArea component={Link} to={`/qr`}>
                  <CardMedia
                    alt="Scan QR"
                    image="/assets/qr-scan.png"
                    title="Scan QR"
                    className={classes.media}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {I18n.get("Scan QR")}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {I18n.get(
                        "Escanea para visualizar el menú e iniciar una orden."
                      )}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </GridListTile>
            <GridListTile classes={{ tile: classes.tile }}>
              <Card raised className={classes.rootCard}>
                <CardActionArea component={Link} to={`/d`}>
                  <CardMedia
                    alt="Menu"
                    image="/assets/menu.jpg"
                    title="Menu"
                    className={classes.media}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {I18n.get("See menu")}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      Consulta nuestro menú completo y elige tus platillos.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </GridListTile>
            <GridListTile classes={{ tile: classes.tile }}>
              <Card raised className={classes.rootCard}>
                <CardActionArea component={Link} to={`/d`}>
                  <CardMedia
                    alt="Delivery"
                    image="/assets/delivery.jpg"
                    title="Delivery"
                    className={classes.media}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                      {I18n.get("Domicilio y Pick Up")}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      component="p"
                    >
                      {I18n.get(
                        "Haz tu orden para servicio a domicilio o pide y recoge en sucursal."
                      )}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </GridListTile>
          </GridList>
        </div>
        <Box mt={1} mx={-2} bgcolor={theme.palette.background.paper} p={2}>
          <Typography variant="h6" style={{ marginBottom: 16 }}>
            Más opciones
          </Typography>

          <GridList
            className={classes.gridListMore}
            cols={1.2}
            cellHeight="auto"
            spacing={16}
          >
            <GridListTile>
              <Grid
                container
                spacing={1}
                style={{ textDecoration: "none", color: "inherit" }}
                component={Link}
                to={`/review`}
              >
                <Grid item xs={4}>
                  <img
                    className={classes.img}
                    alt="review"
                    src="/assets/review.jpg"
                  />
                </Grid>
                <Grid container item xs={8} direction="column" justify="center">
                  <Typography variant="subtitle1" style={{ fontWeight: 700 }}>
                    {I18n.get("Reseña")}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Contesta la encuesta y califica nuestro servicio
                  </Typography>
                </Grid>
              </Grid>
            </GridListTile>
            <GridListTile>
              <Grid
                container
                spacing={2}
                style={{ textDecoration: "none", color: "inherit" }}
                component={Link}
                to={`/invoicing`}
              >
                <Grid item xs={4}>
                  <img
                    className={classes.img}
                    alt="review"
                    src="/assets/invoicing.png"
                  />
                </Grid>
                <Grid item xs={8} container direction="column" justify="center">
                  <Typography variant="subtitle1" style={{ fontWeight: 700 }}>
                    {I18n.get("Invoicing")}
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    Genera y descarga la factura de tu consumo
                  </Typography>
                </Grid>
              </Grid>
            </GridListTile>
          </GridList>
        </Box>

        <Box mt={2}>
          <Typography variant="h6">Información</Typography>

          <List style={{ width: "100%" }}>
            {siteData.contactPhone ? (
              <ListItem disableGutters alignItems="flex-start" button>
                <ListItemAvatar>
                  <Avatar
                    variant="rounded"
                    alt="WhatsApp"
                    src="/assets/whats.jpg"
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={I18n.get("WhatsApp")}
                  secondary={
                    <React.Fragment>
                      {"Contáctanos por WhatsApp"}
                    </React.Fragment>
                  }
                />
              </ListItem>
            ) : null}
            {formatted_address ? (
              <>
                <Divider variant="fullwidth" component="li" />
                <ListItem
                  disableGutters
                  alignItems="flex-start"
                  button
                  component="a"
                  target="_blank"
                  href={`https://www.google.com/maps/place/${encodeURIComponent(
                    formatted_address
                  )}`}
                >
                  <ListItemAvatar>
                    <Avatar
                      variant="rounded"
                      alt="Cindy Baker"
                      src="/assets/location.jpg"
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={I18n.get("Dirección")}
                    secondary={
                      <React.Fragment>
                        {"Visítanos en nuestra ubicación"}
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </>
            ) : null}
          </List>
        </Box>
      </Box>
    </div>
  );
};

export default BottomComponent;
