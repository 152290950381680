import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { NavLink as RouterLink } from "react-router-dom";
import {
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Box,
} from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import EditIcon from "@material-ui/icons/EditTwoTone";

import OrderItemStatus from "./OrderItemStatus";
import useReducer from "../../reducers";
import {
  getItemAmount,
  toCurrency,
  getSelectedModifiersString,
} from "../../util";

const OrderItem = ({
  item = {},
  travel = false,
  delivery = false,
  ordered = false,
  fromSite = false,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [, dispatch] = useReducer();

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink exact ref={ref} {...itemProps} />
      )),
    []
  );

  const handleLinkClick = () => {
    if (!ordered) {
      if (!delivery && !travel) {
        dispatch({ type: "TABS_RESET" });
      } else {
        dispatch({
          type: "DELIVERY_TOGGLE_CHECKOUT",
          payload: { openCheckout: false },
        });
      }
    }
  };

  return (
    <>
      <ListItem
        button
        component={renderLink}
        to={
          ordered
            ? {
                pathname: `/order-item/${item.id}`,
                state: { background: location },
              }
            : {
                pathname:
                  !delivery && !travel
                    ? `/t/product/${item.productID}/update/${item.id}`
                    : fromSite
                    ? `/d/product/${item.productID}/update/${item.id}`
                    : `/d/r/${item.restaurantID}/product/${item.productID}/update/${item.id}`,
              }
        }
        onClick={handleLinkClick}
      >
        {delivery ? (
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
        ) : null}
        <ListItemText primary={item.title} secondary={`x ${item.qty}`} />
        <ListItemSecondaryAction>
          {!ordered ? (
            !delivery && !travel ? (
              <IconButton
                edge="end"
                aria-label="edit"
                onClick={(e) => {
                  e.stopPropagation();
                  handleLinkClick();
                  history.push({
                    pathname:
                      !delivery && !travel
                        ? `/t/product/${item.productID}/update/${item.id}`
                        : fromSite
                        ? `/d/product/${item.productID}/update/${item.id}`
                        : `/d/r/${item.restaurantID}/product/${item.productID}/update/${item.id}`,
                  });
                }}
              >
                <EditIcon />
              </IconButton>
            ) : (
              <Typography variant="caption">
                $ {toCurrency((getItemAmount(item) || 0).toString())}
              </Typography>
            )
          ) : (
            <OrderItemStatus status={item.status} />
          )}
        </ListItemSecondaryAction>
      </ListItem>

      {delivery || travel ? (
        <Box px={2}>
          <Typography color="textSecondary" variant="body2">
            {getSelectedModifiersString(item.modifiers)}
          </Typography>
        </Box>
      ) : null}
    </>
  );
};

export default OrderItem;
