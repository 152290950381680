import { createMuiTheme } from "@material-ui/core/styles";
const family = "'Questrial', sans-serif";

// Create a theme instance.
export const lightTheme = createMuiTheme({
  typography: {
    fontFamily: family,
  },
  palette: {
    type: "light",
    primary: {
      main: "#ffaa00",
    },
    secondary: {
      main: "#ffaa00",
    },
    background: {
      default: "#fafafa",
    },
  },
});

export const darkTheme = createMuiTheme({
  typography: {
    fontFamily: family,
  },
  palette: {
    type: "dark",
    primary: {
      main: "#ffaa00",
    },
    secondary: {
      main: "#e09600",
    },
    background: {
      paper: "#262833",
      default: "#343744",
    },
    text: {
      primary: "#F8F8F8",
    },
    action: {
      active: "#F8F8F8",
    },
  },
});
