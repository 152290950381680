const initialState = {
  openSheet: false,
  selectedTab: 0
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SHEET_TOGGLE':
      return {
        ...state,
        openSheet: action.payload.open || !state.openSheet
      };
    case 'SHEET_CLOSE':
      return {
        ...state,
        openSheet: false
      };
    case 'SHEET_OPEN':
      return {
        ...state,
        openSheet: true
      };
    case 'SHEET_SET_TAB':
      return { ...state, selectedTab: action.payload.tab };
    default:
      return state;
  }
};

export default { initialState, reducer };
