import React from "react";
import { I18n } from "aws-amplify";
import { Button, GridListTile } from "@material-ui/core";
import useReducer from "../../reducers";

const OrderTypeButton = ({ type, label, ...rest }) => {
  const [
    {
      deliveryReducer: { orderType },
    },
    dispatch,
  ] = useReducer();

  const handleClose = (value) => {
    dispatch({
      type: "DELIVERY_TOGGLE_ORDER_TYPE_DIALOG",
      payload: { open: false },
    });

    dispatch({
      type: "DELIVERY_SET_ORDER_TYPE",
      payload: { orderType: value },
    });
  };

  const handleListItemClick = (value) => () => {
    handleClose(value);
  };

  return (
    <GridListTile {...rest}>
      <Button
        variant={orderType === type ? "contained" : "outlined"}
        color={orderType === type ? "primary" : "secondary"}
        fullWidth
        style={{
          borderRadius: 40,
        }}
        onClick={handleListItemClick(type)}
      >
        {I18n.get(label)}
      </Button>
    </GridListTile>
  );
};

export default OrderTypeButton;
