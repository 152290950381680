import React from "react";
import moment from "moment";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Typography } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";

const DatesPicker = ({
  status,
  travelData,
  commerceType,
  needsEndDate,
  orderItemId,
  orderItem,
  formik,
}) => {
  const parsedTravelData = travelData ? JSON.parse(travelData) : {};
  const diff = moment()
    .endOf("day")
    .add(2, "days")
    .diff(moment(parsedTravelData.minDate).endOf("day"), "days");

  const minDate = React.useMemo(
    () =>
      (parsedTravelData.minDate
        ? diff > 0
          ? moment().add(2, "days")
          : moment(parsedTravelData.minDate)
        : moment().add(2, "days")
      ).toString(),
    [diff, parsedTravelData]
  );
  const maxDate = parsedTravelData.maxDate
    ? moment(parsedTravelData.maxDate)
    : null;

  const handleNightsQty = (field, value) => {
    if (needsEndDate && formik.values.endDate) {
      const endv = field === "endDate" ? value : formik.values.endDate;
      const startv = field === "startDate" ? value : formik.values.startDate;
      const nights = moment(endv)
        .endOf("day")
        .diff(moment(startv).endOf("day"), "days");
      console.log("NIGHTS", nights);
      formik.setFieldValue("qty", nights && nights > 0 ? nights : 1);
    }
  };

  React.useEffect(() => {
    let mounted = true;
    if (orderItemId) {
      if (commerceType === "TRAVEL" && orderItem && orderItem.travelData) {
        const { startDate, endDate } = JSON.parse(orderItem.travelData) || {};

        console.log("sd", startDate, endDate);

        if (mounted) {
          const givenStartDate = startDate
            ? moment(startDate)
            : moment().add(2, "days");
          formik.setFieldValue("startDate", givenStartDate.toDate());

          const givenEndDate = endDate
            ? moment(endDate)
            : moment().add(3, "days");
          formik.setFieldValue("endDate", givenEndDate.toDate());
        }
      }
    } else {
      if (mounted && minDate && commerceType === "TRAVEL") {
        console.log("hey", minDate);
        console.log("hey", moment(minDate).toDate());
        formik.setFieldValue("startDate", moment(minDate).toDate());
      }
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commerceType, orderItemId]);

  const pickerOptions = {
    inputVariant: "outlined",
    label: "Fecha",
    format: "dd/MM/yyyy",
    autoOk: true,
    fullWidth: true,
  };

  const startPickerOptions = {
    ...pickerOptions,
    minDate: moment(minDate),
  };

  const endPickerOptions = {
    ...pickerOptions,
    minDate: moment(formik.values.startDate).add(1, "days"),
  };

  if (maxDate) {
    startPickerOptions.maxDate = maxDate;
    endPickerOptions.maxDate = maxDate;
  }

  React.useEffect(() => {
    let mounted = true;
    if (mounted && !orderItemId && formik.values.startDate) {
      const newEndDate = moment(formik.values.startDate).add(1, "days");
      formik.setFieldValue("endDate", newEndDate.toDate());
      handleNightsQty("endDate", newEndDate);
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderItemId, formik.values.startDate]);

  return (
    <>
      {status && status === "ACTIVE" && commerceType === "TRAVEL" ? (
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel-indications"
            id="panel-indications"
          >
            <Typography>Start date</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
              <DatePicker
                name="startDate"
                value={formik.values.startDate}
                onChange={(value) => {
                  console.log("value", value);
                  formik.setFieldValue("startDate", value);
                  handleNightsQty("startDate", value);
                }}
                {...startPickerOptions}
              />
            </MuiPickersUtilsProvider>
          </AccordionDetails>
        </Accordion>
      ) : null}

      {status && status === "ACTIVE" && commerceType === "TRAVEL" ? (
        needsEndDate ? (
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-indications"
              id="panel-indications"
            >
              <Typography>End date</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  name="endDate"
                  value={formik.values.endDate}
                  onChange={(value) => {
                    console.log("value", value);
                    formik.setFieldValue("endDate", value);
                    handleNightsQty("endDate", value);
                  }}
                  {...endPickerOptions}
                />
              </MuiPickersUtilsProvider>
            </AccordionDetails>
          </Accordion>
        ) : null
      ) : null}
    </>
  );
};

export default React.memo(DatesPicker);
