import React from "react";
// import { useParams } from "react-router-dom";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import ProductComponent from "../../components/Product/ProductComponent";
import DeliveryItemUpdate from "../../components/Delivery/DeliveryItemUpdate";

const DeliveryProduct = ({ fromSite = false }) => {
  let { path } = useRouteMatch();
  // let { productId } = useParams();

  return (
    <Switch>
      <Route exact path={path}>
        <ProductComponent delivery fromSite={fromSite} />
      </Route>
      <Route path={`${path}/update/:oiid`}>
        <DeliveryItemUpdate delivery fromSite={fromSite} />
      </Route>
    </Switch>
  );
};

export default DeliveryProduct;
