import React from "react";
import { Typography, Box } from "@material-ui/core";
import { API, graphqlOperation, I18n } from "aws-amplify";

import CategoriesWidget from "../Category/CategoriesWidget";
import * as queries from "../../graphql/queries";

const MenuCategories = ({ fromSite, selectedMenu, restaurantId, delivery }) => {
  const [loading, setLoading] = React.useState(true);
  const [loadingMore, setLoadingMore] = React.useState(false);
  const [nextToken, setNextToken] = React.useState();
  const [menuCategories, setCategories] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const menuResponse = API.graphql({
          ...graphqlOperation(queries.getMenu, {
            id: selectedMenu,
            limit: 20,
          }),
        });
        const { data: menuData } = (await menuResponse) || {};
        const {
          getMenu: { categories },
        } = menuData || {};

        const menuCategories = (categories?.items || [])
          .filter(
            (x) =>
              !x._deleted &&
              !x?.category?._deleted &&
              x?.category?.status === "ACTIVE"
          )
          .map((x) => x.category);
        setCategories(menuCategories);
        setNextToken(categories.nextToken);
        setLoading(false);
      } catch (error) {
        console.log("error", error);
        setLoading(false);
      }
    };

    if (selectedMenu) {
      fetchData();
    }
  }, [selectedMenu]);

  function fetchCategories(variables) {
    return API.graphql({
      query: queries.getMenu,
      variables,
      authMode: "AWS_IAM",
    });
  }

  function handleLoadMore() {
    setLoadingMore(true);

    if (!nextToken) {
      setLoadingMore(false);
      return;
    }

    fetchCategories({
      id: selectedMenu,
      nextToken,
      limit: 10,
    }).then(({ data }) => {
      const {
        getMenu: { categories },
      } = data || {};
      setLoadingMore(false);
      setNextToken(categories.nextToken);
      const menucats = (categories?.items || [])
        .filter(
          (x) =>
            !x._deleted &&
            !x?.category?._deleted &&
            x?.category?.status === "ACTIVE"
        )
        .map((x) => x.category);
      setCategories([...menuCategories, ...menucats]);
    });
  }

  return (
    <Box mb={3}>
      <Typography variant="subtitle2">{I18n.get("Categories")}</Typography>

      <CategoriesWidget
        loading={loading}
        loadingMore={loadingMore}
        handleLoadMore={handleLoadMore}
        hasMore={Boolean(nextToken)}
        categories={menuCategories}
        title={null}
        restaurantId={restaurantId}
        delivery={delivery}
        fromSite={fromSite}
      />
    </Box>
  );
};

export default MenuCategories;
