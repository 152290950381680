import React from "react";
import { Cache } from "aws-amplify";
import { Switch, useRouteMatch, Route, Redirect } from "react-router-dom";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import { API, graphqlOperation } from "aws-amplify";

import Home from "./home";
import CategoryPage from "./category";
import MenuPage from "./menu";
import ProductPage from "./product";
import BottomNav from "../components/BottomNav/BottomNav";
import useReducer from "../reducers";
import * as queries from "../graphql/queries";
import * as subscriptions from "../graphql/subscriptions";
import {
  JSONParse,
  getModifiers,
  getAmount,
  getAmountPaid,
  getTaxes,
  filterOrderItems,
  getDiscountItems,
  getDiscountAmount,
} from "../util";
import PendingItems from "../components/Order/PendingItems";
import OrderedItems from "../components/Order/OrderedItems";
import Buttons from "../components/BottomSheet/Buttons";
import Help from "../components/Help";
import { Toolbar } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: "auto",
    bottom: 0,
    [theme.breakpoints.up("sm")]: {
      maxWidth: 414,
      left: 0,
      right: "auto",
    },
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto",
  },
}));

const Tabs = () => {
  const classes = useStyles();
  let { path } = useRouteMatch();
  const [
    {
      tabsReducer: { activeTab },
      restaurantReducer: { currentRestaurant },
    },
    dispatch,
  ] = useReducer();
  const { taxes } = currentRestaurant || {};

  React.useEffect(() => {
    let mounted = true;
    let paymentSubscription;
    let createOrderItemSubscription;
    let updateOrderItemSubscription;
    let deleteOrderItemSubscription;
    const orderId = Cache.getItem("currentOrderId");
    const fetchData = async (isSubscription = false) => {
      const restTaxes = taxes ? JSONParse(taxes) : [];

      API.graphql({
        ...graphqlOperation(queries.getOrder, { id: orderId }),
        authMode: "AWS_IAM",
      }).then((orderResponse) => {
        const currOrder =
          orderResponse && orderResponse.data && orderResponse.data.getOrder
            ? orderResponse.data.getOrder
            : null;
        // console.log("currOrder", currOrder);
        if (currOrder) {
          const orderItems =
            currOrder && currOrder.items && currOrder.items.items
              ? filterOrderItems(currOrder.items.items)
              : [];
          const discountItems =
            currOrder && currOrder.items && currOrder.items.items
              ? getDiscountItems(currOrder.items.items)
              : [];
          const payments =
            currOrder && currOrder.payments && currOrder.payments.items
              ? currOrder.payments.items.filter((x) => !x._deleted)
              : [];

          // console.log("fetch Result", orderItems);
          // console.log("fetch PaymentResult", payments);

          if (mounted) {
            dispatch({
              type: "ORDER_SET_ORDERITEMS",
              payload: {
                orderItems: !isSubscription
                  ? orderItems
                  : orderItems.map((r) => ({
                      ...r,
                      modifiers: getModifiers(r.modifiers),
                    })),
              },
            });
            dispatch({
              type: "ORDER_SET_DISCOUNT_ITEMS",
              payload: {
                discountItems,
              },
            });
            const amount = getAmount(orderItems);
            const paid = getAmountPaid(payments);

            dispatch({
              type: "ORDER_SET_AMOUNT",
              payload: { orderAmount: amount },
            });
            dispatch({
              type: "ORDER_SET_TAXES",
              payload: { orderTaxes: getTaxes(restTaxes, amount) },
            });
            dispatch({
              type: "ORDER_SET_DISCOUNT_AMOUNT",
              payload: {
                orderDiscountAmount: getDiscountAmount(discountItems),
              },
            });
            dispatch({
              type: "ORDER_SET_AMOUNTPAID",
              payload: { amountPaid: paid },
            });
            dispatch({
              type: "ORDER_SET_ORDERPAYMENTS",
              payload: {
                orderPayments: payments,
              },
            });
          }
        }
      });
    };

    if (orderId) {
      fetchData();

      //create
      createOrderItemSubscription = API.graphql({
        ...graphqlOperation(subscriptions.onCreateOrderItem),
        authMode: "AWS_IAM",
      }).subscribe({
        next: ({
          value: { data: { onCreateOrderItem } = { data: {} } } = {},
        }) => {
          console.log("onCreateOrderItem", onCreateOrderItem);
          if (
            onCreateOrderItem &&
            onCreateOrderItem.orderItemOrderId === orderId
          ) {
            fetchData(true);
          }
        },
      });
      //update
      updateOrderItemSubscription = API.graphql({
        ...graphqlOperation(subscriptions.onUpdateOrderItem),
        authMode: "AWS_IAM",
      }).subscribe({
        next: ({
          value: { data: { onUpdateOrderItem } = { data: {} } } = {},
        }) => {
          console.log("onUpdateOrderItem", onUpdateOrderItem);
          if (
            onUpdateOrderItem &&
            onUpdateOrderItem.orderItemOrderId === orderId
          ) {
            fetchData(true);
          }
        },
      });
      //delete
      deleteOrderItemSubscription = API.graphql({
        ...graphqlOperation(subscriptions.onDeleteOrderItem),
        authMode: "AWS_IAM",
      }).subscribe({
        next: ({
          value: { data: { onDeleteOrderItem } = { data: {} } } = {},
        }) => {
          console.log("onDeleteOrderItem", onDeleteOrderItem);
          if (
            onDeleteOrderItem &&
            onDeleteOrderItem.orderItemOrderId === orderId
          ) {
            fetchData(true);
          }
        },
      });

      paymentSubscription = API.graphql({
        ...graphqlOperation(subscriptions.onCreatePayment),
        authMode: "AWS_IAM",
      }).subscribe({
        next: ({
          value: { data: { onCreatePayment } = { data: {} } } = {},
        }) => {
          console.log("onCreatePayment", onCreatePayment);
          if (
            onCreatePayment &&
            onCreatePayment.order &&
            onCreatePayment.order.id === orderId
          ) {
            fetchData(true);
          }
        },
      });

      const handleConnectionChange = () => {
        const condition = navigator.onLine ? "online" : "offline";
        console.log(condition);
        if (condition === "online") {
          fetchData();
        }
      };

      window.addEventListener("online", handleConnectionChange);
      window.addEventListener("offline", handleConnectionChange);
    }

    return () => {
      mounted = false;
      if (paymentSubscription) {
        paymentSubscription.unsubscribe();
      }
      if (createOrderItemSubscription) {
        createOrderItemSubscription.unsubscribe();
      }
      if (updateOrderItemSubscription) {
        updateOrderItemSubscription.unsubscribe();
      }
      if (deleteOrderItemSubscription) {
        deleteOrderItemSubscription.unsubscribe();
      }
    };
  }, [taxes]);

  return (
    <>
      <Box display="flex" flex={1} flexDirection="column">
        {activeTab === 0 ? (
          <Switch>
            <Route exact path={path}>
              <Home />
            </Route>
            <Route path={`${path}/category/:categoryId`}>
              <CategoryPage />
            </Route>
            <Route path={`${path}/menu`}>
              <MenuPage />
            </Route>
            <Route path={`${path}/product/:productId`}>
              <ProductPage />
            </Route>
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
        ) : null}
        {activeTab === 1 ? <PendingItems /> : null}
        {activeTab === 2 ? <OrderedItems /> : null}
        {activeTab === 3 ? <Help /> : null}
        <Buttons />
      </Box>
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <BottomNav />
      </AppBar>
      <Toolbar />
    </>
  );
};

export default Tabs;
