import * as Yup from "yup";

const ServiceSchema = Yup.object().shape({
  waiter: Yup.number().required().min(1),
  cashier: Yup.number().required().min(1),
  manager: Yup.number().required().min(1),
  host: Yup.number(),
});

const ProductSchema = Yup.object().shape({
  product: Yup.number().required().min(1),
  place: Yup.number().required().min(1),
});

const ContactSchema = Yup.object().shape({
  email: Yup.string().email("Valid email required"),
  comments: Yup.string(),
  preferUs: Yup.string(),
});

const ReviewValidationSchema = [ServiceSchema, ProductSchema, ContactSchema];
export default ReviewValidationSchema;
