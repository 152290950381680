import React from "react";
import { API } from "aws-amplify";

import useReducer from "../reducers";
import CategoriesWidget from "./Category/CategoriesWidget";
import * as queries from "../graphql/queries";

const MainCategories = () => {
  const [
    {
      restaurantReducer: { currentRestaurant },
    },
  ] = useReducer();
  const { id: restaurantID } = currentRestaurant || {};
  const [loading, setLoading] = React.useState(true);
  const [loadingMore, setLoadingMore] = React.useState(false);
  const [categories, setCategories] = React.useState([]);
  const [nextToken, setNextToken] = React.useState();

  React.useEffect(() => {
    let mounted = true;
    if (restaurantID) {
      fetchCategories({
        categoryRestaurantId: restaurantID,
        filter: {
          status: { eq: "ACTIVE" },
          and: [
            { type: { ne: "KITCHEN" } },
            { type: { ne: "AREA" } },
            { type: { ne: "SECTION" } },
          ],
        },
        limit: 100,
      })
        .then(({ data }) => {
          const { categoriesbyRestaurant } = data || {};
          if (mounted) {
            setCategories(categoriesbyRestaurant.items);
            setNextToken(categoriesbyRestaurant.nextToken);
            setLoading(false);
          }
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
        });
    }

    return () => {
      mounted = false;
    };
  }, [restaurantID]);

  function fetchCategories(variables) {
    return API.graphql({
      query: queries.categoriesbyRestaurant,
      variables,
      authMode: "AWS_IAM",
    });
  }

  function handleLoadMore() {
    setLoadingMore(true);

    if (!nextToken) {
      setLoadingMore(false);
      return;
    }

    fetchCategories({
      categoryRestaurantId: restaurantID,
      filter: {
        status: { eq: "ACTIVE" },
        and: [
          { type: { ne: "KITCHEN" } },
          { type: { ne: "AREA" } },
          { type: { ne: "SECTION" } },
        ],
      },
      limit: 20,
      nextToken,
    }).then(({ data }) => {
      const { categoriesbyRestaurant } = data || {};
      setLoadingMore(false);
      setNextToken(categoriesbyRestaurant.nextToken);
      setCategories([...categories, ...categoriesbyRestaurant.items]);
    });
  }

  return (
    <CategoriesWidget
      loading={loading}
      loadingMore={loadingMore}
      handleLoadMore={handleLoadMore}
      hasMore={Boolean(nextToken)}
      categories={categories}
      title="Categories"
      isMain
    />
  );
};

export default MainCategories;
