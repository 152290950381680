import React from "react";
import moment from "moment";
import { I18n } from "aws-amplify";
import _orderBy from "lodash/orderBy";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import GridListTile from "@material-ui/core/GridListTile";
import { Link as RouterLink } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import { Typography, Box, Button } from "@material-ui/core";

import { JSONParse } from "../../util";
import MenuTile from "./MenuTile";

const useStyles = makeStyles((theme) => ({
  gridList: {
    flexWrap: "nowrap",
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: "translateZ(0)",
  },
  chip: {
    width: "100%",
  },
  sectionTitle: {
    marginBottom: theme.spacing(1),
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "center",
  },
}));

const MenusWidget = ({
  title = "Menus",
  menus = [],
  loading = true,
  restaurantId,
  delivery = false,
  fromSite = false,
}) => {
  const classes = useStyles();

  // const parsedSchedule = schedule ? JSONParse(schedule) : {};
  // console.log('schedule', parsedSchedule);
  moment.locale("en");
  var m1 = moment();
  m1.locale(); // en
  const currDay = m1.format("dddd");
  // const currentSchedule = parsedSchedule[currDay];
  // console.log('currentSchedule', currentSchedule);

  const filteredMenus =
    menus && menus.length
      ? menus.filter((x) => {
          const parsedSchedule = x.schedule ? JSONParse(x.schedule) : {};
          const currentSchedule = parsedSchedule[currDay];

          const hasRange = !!(
            currentSchedule &&
            currentSchedule.range &&
            currentSchedule.range.length
          );
          const openHour = hasRange
            ? currentSchedule.range[0]
              ? parseInt(currentSchedule.range[0].replace(":", ""))
              : 0
            : 0;
          const closeHour = hasRange
            ? currentSchedule.range[1]
              ? parseInt(currentSchedule.range[1].replace(":", ""))
              : 0
            : 0;
          const currentHour = parseInt(moment().format("HHmm"));

          const isOpen =
            (openHour === 0 && closeHour === 0) ||
            (currentHour > openHour && currentHour < closeHour);
          return (
            (!hasRange || isOpen) &&
            !(currentSchedule && currentSchedule?.closed) &&
            !x._deleted
          );
        })
      : [];

  if (!loading && !(filteredMenus && filteredMenus.length)) {
    return null;
  }

  return (
    <Box mt={2}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" className={classes.sectionTitle}>
          {I18n.get(title)}
        </Typography>
        <Button
          component={RouterLink}
          to={
            !delivery
              ? "/t/menu"
              : fromSite
              ? `/d/menu`
              : `/d/r/${restaurantId}/menu`
          }
          color="secondary"
        >
          {I18n.get("See more")}
        </Button>
      </Box>

      <GridList
        className={classes.gridList}
        cellHeight={120}
        cols={2.3}
        spacing={8}
      >
        {(loading
          ? Array.from(new Array(3))
          : _orderBy(filteredMenus, ["title"], ["asc"])
        ).map((tile, index) =>
          tile ? (
            <MenuTile
              index={index}
              key={tile.id}
              tile={tile}
              delivery={delivery}
              restaurantId={restaurantId}
              fromSite={fromSite}
            />
          ) : (
            <GridListTile key={index}>
              <Skeleton
                variant="rect"
                animation="wave"
                height={120}
                style={{ borderRadius: 8 }}
              />
            </GridListTile>
          )
        )}
      </GridList>
    </Box>
  );
};

export default MenusWidget;
