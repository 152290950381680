import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';

const MainContainer = ({ children }) => {
  return <Box pt={3}>{children}</Box>;
};

MainContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default MainContainer;
