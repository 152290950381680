import React from "react";
import Img from "react-image";
import { I18n } from "aws-amplify";
import { Typography, Box } from "@material-ui/core";

import Photo from "../Photo";

const InvalidBrowser = () => {
  return (
    <Box
      minHeight={280}
      width={280}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <Typography
        variant="caption"
        style={{ marginBottom: 8, textAlign: "center" }}
      >
        {I18n.get("Open your QR scanner from the browser bar")}
      </Typography>
      <Img
        src={["/qr-iphone-chrome.gif"]}
        alt={"qr iphone chrome"}
        loader={<Photo />}
        unloader={<Photo />}
        style={{
          width: "100%",
          height: 280,
          objectFit: "contain",
          objectPosition: "center",
        }}
      />
    </Box>
  );
};

export default InvalidBrowser;
