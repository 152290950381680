import useReducer from "../reducers";
import { handleTranslation } from "../util";

function useTranslate(value, i18n, field) {
  const [
    {
      deliveryReducer: {
        selectedRestaurant: {
          disableAutoTranslate: delDisableAutoTranslate,
          language: delRestLanguage,
        },
      },
      restaurantReducer: {
        currentRestaurant: { disableAutoTranslate, language: restLanguage },
      },
    },
  ] = useReducer();
  const translateLanguage = (restLanguage || delRestLanguage || "es").split(
    "-"
  )[0];

  if (delDisableAutoTranslate || disableAutoTranslate) {
    return value || "";
  }

  return value ? handleTranslation(value, i18n, field, translateLanguage) : "";
}

export default useTranslate;
