import React from 'react';
// import { Link as RouterLink } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import AccountBoxTwoToneIcon from '@material-ui/icons/AccountBoxTwoTone';
import useReducer from '../reducers';

const NavProfileButton = () => {
  const [, dispatch] = useReducer();

  const handleLogin = () => {
    dispatch({ type: 'LOGIN_OPEN' });
  };

  return (
    // <IconButton
    //   component={RouterLink}
    //   to="/profile"
    //   edge="end"
    //   aria-label="profile"
    // >
    //   <AccountBoxTwoToneIcon fontSize="default" />
    // </IconButton>
    <IconButton edge="end" onClick={handleLogin}>
      <AccountBoxTwoToneIcon />
    </IconButton>
  );
};

export default NavProfileButton;
