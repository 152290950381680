import React from "react";
import { I18n } from "aws-amplify";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
// import Slide from "@material-ui/core/Slide";
import { useHistory } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import { Box } from "@material-ui/core";
import Img from "react-image";
import SvgIcon from "@material-ui/core/SvgIcon";
import Skeleton from "@material-ui/lab/Skeleton";

import Photo from "../components/Photo";
import { ReactComponent as SpecialIcon } from "../svg/undraw_special_event.svg";
import businessLanguage from "../lib/businessLanguage";
import useReducer from "../reducers";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const tutorialSteps = [
  {
    label: "Scan or type your table token",
    gif: () => (
      <Img
        src={["/2dine_scan.gif"]}
        alt={"add products"}
        loader={
          <Skeleton variant="rect" animation="wave" width={240} height={480} />
        }
        unloader={<Photo />}
        style={{
          width: "100%",
          height: 480,
          objectFit: "contain",
          objectPosition: "center",
        }}
      />
    ),
  },
  {
    label: "Select your products",
    gif: () => (
      <Img
        src={["/2dine_add_products.gif"]}
        alt={"add products"}
        loader={
          <Skeleton variant="rect" animation="wave" width={240} height={480} />
        }
        unloader={<Photo />}
        style={{
          width: "100%",
          height: 480,
          objectFit: "contain",
          objectPosition: "center",
        }}
      />
    ),
  },
  {
    label: "Confirm & send your order",
    gif: () => (
      <Img
        src={["/2dine_send_products.gif"]}
        alt={"add products"}
        loader={
          <Skeleton variant="rect" animation="wave" width={240} height={480} />
        }
        unloader={<Photo />}
        style={{
          width: "100%",
          height: 480,
          objectFit: "contain",
          objectPosition: "center",
        }}
      />
    ),
  },
  {
    label: "Add & send more products anytime",
    gif: () => (
      <Img
        src={["/2dine_more_products.gif"]}
        alt={"more products"}
        loader={
          <Skeleton variant="rect" animation="wave" width={240} height={480} />
        }
        unloader={<Photo />}
        style={{
          width: "100%",
          height: 480,
          objectFit: "contain",
          objectPosition: "center",
        }}
      />
    ),
  },
  {
    label: "Enjoy your order!",
    gif: () => (
      <SvgIcon
        component={SpecialIcon}
        viewBox="0 0 998.28223 772.77325"
        style={{ fontSize: 240 }}
      />
    ),
  },
  {
    label: "Checkout & pay",
    gif: () => (
      <Img
        src={["/2dine_payment.gif"]}
        alt={"more products"}
        loader={
          <Skeleton variant="rect" animation="wave" width={240} height={480} />
        }
        unloader={<Photo />}
        style={{
          width: "100%",
          height: 480,
          objectFit: "contain",
          objectPosition: "center",
        }}
      />
    ),
  },
];

const useStyles = makeStyles((theme) => ({
  close: {
    position: "absolute",
    zIndex: 1,
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  dialog: {
    zIndex: "1301 !important",
  },
  container: {
    margin: theme.spacing(1, 0),
  },
}));

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const How = () => {
  const classes = useStyles();
  const history = useHistory();
  const [
    {
      restaurantReducer: { currentRestaurant },
    },
  ] = useReducer();
  const [activeStep, setActiveStep] = React.useState(0);
  const btype =
    currentRestaurant && currentRestaurant.businessType
      ? currentRestaurant.businessType
      : "RESTAURANT";

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const back = (e) => {
    e.stopPropagation();
    history.goBack();
  };

  return (
    <>
      <Dialog
        container={() => document.getElementById("mainBox")}
        fullScreen
        open={true}
        onClose={back}
        // TransitionComponent={Transition}
        className={classes.dialog}
        style={{
          zIndex: 1300,
          right: 0,
          bottom: 0,
          top: 0,
          left: 0,
          position: "absolute",
        }}
        BackdropProps={{
          style: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            zIndex: -1,
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            WebkitTapHighlightColor: "transparent",
          },
        }}
      >
        <IconButton
          edge="start"
          color="inherit"
          onClick={back}
          aria-label="close"
          className={classes.close}
        >
          <CloseIcon />
        </IconButton>

        <AutoPlaySwipeableViews
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
          interval={10000}
        >
          {tutorialSteps.map((step, index) => (
            <Box
              key={index}
              p={2}
              display="flex"
              flexDirection="column"
              style={{ height: "calc(100vh - 46px)" }}
            >
              <Typography
                component="h2"
                variant={"h4"}
                style={{ marginTop: 16 }}
              >
                {I18n.get(businessLanguage[btype][step.label])}
              </Typography>
              <Box
                mt={4}
                display="flex"
                flex={1}
                alignItems="center"
                justifyContent="center"
              >
                {step.gif ? step.gif() : null}
              </Box>
            </Box>
          ))}
        </AutoPlaySwipeableViews>
        <MobileStepper
          variant="progress"
          steps={6}
          position="static"
          activeStep={activeStep}
          className={classes.root}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === 5}
            >
              {I18n.get("Next")}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {I18n.get("Back")}
            </Button>
          }
        />
      </Dialog>
    </>
  );
};

export default How;
