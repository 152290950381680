import React from "react";
import { Auth, Cache } from "aws-amplify";
import Fab from "@material-ui/core/Fab";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import SvgIcon from "@material-ui/core/SvgIcon";

import { ReactComponent as GoogleIcon } from "../../svg/google-glass-logo.svg";

const useStyles = makeStyles((theme) => ({
  paperAnchorBottom: {
    maxHeight: "60%",
    minHeight: "30%",
  },
  margin: {
    marginRight: theme.spacing(5),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    width: "140px !important",
  },
}));

const SignInWithGoogle = () => {
  const classes = useStyles();
  const location = useLocation();

  const handleSignIn = () => {
    const ga = window.gapi.auth2.getAuthInstance();
    Cache.setItem("fromUrl", location);

    ga.signIn().then(
      (googleUser) => {
        getAWSCredentials(googleUser);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getAWSCredentials = async (googleUser) => {
    const { id_token, expires_at } = googleUser.getAuthResponse();
    const profile = googleUser.getBasicProfile();
    console.log("profile", profile);
    let user = {
      pid: profile.getId(),
      email: profile.getEmail(),
      name: profile.getName(),
      picture: profile.getImageUrl(),
      provider: "google",
    };

    const credentials = await Auth.federatedSignIn(
      "google",
      { token: id_token, expires_at },
      user
    );
    console.log("credentials", credentials);
  };

  return (
    <Fab
      variant="extended"
      size="medium"
      color="secondary"
      aria-label="add"
      classes={{ sizeMedium: classes.fab }}
      onClick={handleSignIn}
    >
      <SvgIcon
        component={GoogleIcon}
        viewBox="0 0 510 510"
        className={classes.extendedIcon}
      />
      Google
    </Fab>
  );
};

export default SignInWithGoogle;
