const initialState = {
  siteDomain: "", // customDomain
  siteData: null, // restaurant
  onlyDelivery: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SITE_SET_DATA":
      return {
        ...state,
        siteDomain: action.payload.siteDomain,
        siteData: action.payload.siteData,
        onlyDelivery: action.payload.onlyDelivery,
      };
    case "SITE_RESET":
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default { initialState, reducer };
