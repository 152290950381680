import React from "react";
import { Cache } from "aws-amplify";
import Switch from "@material-ui/core/Switch";
// import IconButton from '@material-ui/core/IconButton';
// import Brightness7 from '@material-ui/icons/Brightness7';
// import Brightness4 from '@material-ui/icons/Brightness4';

import useReducer from "../reducers";

const ThemeSwitch = () => {
  const [
    {
      themeReducer: { isDark },
    },
    dispatch,
  ] = useReducer();
  // const Icon = isDark ? Brightness7 : Brightness4;

  const toggleDarkMode = () => {
    Cache.setItem("isDark", !isDark);
    dispatch({
      type: "THEME_TOGGLE_MODE",
      payload: { isDark: !isDark },
    });
  };

  return (
    // <IconButton aria-label="theme" onClick={toggleDarkMode}>
    //   <Icon fontSize="small" />
    // </IconButton>
    <Switch
      edge="end"
      onChange={toggleDarkMode}
      checked={isDark}
      inputProps={{ "aria-labelledby": "switch-theme" }}
    />
  );
};

export default ThemeSwitch;
