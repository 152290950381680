import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  label: { justifyContent: 'flex-start' },
  textSizeLarge: {
    paddingLeft: theme.spacing(2),
    fontWeight: 400,
    fontSize: 17,
    textTransform: 'capitalize'
  }
}));

const BottomButton = ({ title = 'Default', ...other }) => {
  const classes = useStyles();
  return (
    <Button
      color="secondary"
      size="large"
      fullWidth
      classes={{ label: classes.label, textSizeLarge: classes.textSizeLarge }}
      {...other}
    >
      {title}
    </Button>
  );
};

BottomButton.propTypes = {
  title: PropTypes.string
};

export default BottomButton;
