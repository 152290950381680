/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      type
      title
      body
      data
      payload
      tokens
      channel
      createdAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const createCompany = `mutation CreateCompany(
  $input: CreateCompanyInput!
  $condition: ModelCompanyConditionInput
) {
  createCompany(input: $input, condition: $condition) {
    id
    name
    status
    _version
    _deleted
    _lastChangedAt
    restaurants {
      items {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    users {
      items {
        id
        username
        name
        stripeCustomer
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const updateCompany = `mutation UpdateCompany(
  $input: UpdateCompanyInput!
  $condition: ModelCompanyConditionInput
) {
  updateCompany(input: $input, condition: $condition) {
    id
    name
    status
    _version
    _deleted
    _lastChangedAt
    restaurants {
      items {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    users {
      items {
        id
        username
        name
        stripeCustomer
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const deleteCompany = `mutation DeleteCompany(
  $input: DeleteCompanyInput!
  $condition: ModelCompanyConditionInput
) {
  deleteCompany(input: $input, condition: $condition) {
    id
    name
    status
    _version
    _deleted
    _lastChangedAt
    restaurants {
      items {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    users {
      items {
        id
        username
        name
        stripeCustomer
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const createRestaurant = `mutation CreateRestaurant(
  $input: CreateRestaurantInput!
  $condition: ModelRestaurantConditionInput
) {
  createRestaurant(input: $input, condition: $condition) {
    id
    slug
    name
    address
    timezone
    country
    taxes
    taxesIncluded
    createdAt
    status
    currency
    subscriptionId
    picture
    _version
    _deleted
    _lastChangedAt
    company {
      id
      name
      status
      _version
      _deleted
      _lastChangedAt
      restaurants {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    tables {
      items {
        id
        title
        position
        ordersCount
        guestsCount
        lastOrder
        lastOrderDate
        status
        isOccupied
        occupiedAt
        qr
        area
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    products {
      items {
        id
        title
        description
        picture
        createdAt
        position
        price
        status
        kitchenID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    menus {
      items {
        id
        title
        description
        status
        picture
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    categories {
      items {
        id
        title
        description
        picture
        position
        status
        type
        kitchenID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    modifiers {
      items {
        id
        title
        upcharge
        minSelection
        maxSelection
        createdAt
        position
        status
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    users {
      items {
        id
        restaurantID
        userID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const updateRestaurant = `mutation UpdateRestaurant(
  $input: UpdateRestaurantInput!
  $condition: ModelRestaurantConditionInput
) {
  updateRestaurant(input: $input, condition: $condition) {
    id
    slug
    name
    address
    timezone
    country
    taxes
    taxesIncluded
    createdAt
    status
    currency
    subscriptionId
    picture
    _version
    _deleted
    _lastChangedAt
    company {
      id
      name
      status
      _version
      _deleted
      _lastChangedAt
      restaurants {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    tables {
      items {
        id
        title
        position
        ordersCount
        guestsCount
        lastOrder
        lastOrderDate
        status
        isOccupied
        occupiedAt
        qr
        area
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    products {
      items {
        id
        title
        description
        picture
        createdAt
        position
        price
        status
        kitchenID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    menus {
      items {
        id
        title
        description
        status
        picture
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    categories {
      items {
        id
        title
        description
        picture
        position
        status
        type
        kitchenID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    modifiers {
      items {
        id
        title
        upcharge
        minSelection
        maxSelection
        createdAt
        position
        status
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    users {
      items {
        id
        restaurantID
        userID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const deleteRestaurant = `mutation DeleteRestaurant(
  $input: DeleteRestaurantInput!
  $condition: ModelRestaurantConditionInput
) {
  deleteRestaurant(input: $input, condition: $condition) {
    id
    slug
    name
    address
    timezone
    country
    taxes
    taxesIncluded
    createdAt
    status
    currency
    subscriptionId
    picture
    _version
    _deleted
    _lastChangedAt
    company {
      id
      name
      status
      _version
      _deleted
      _lastChangedAt
      restaurants {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    tables {
      items {
        id
        title
        position
        ordersCount
        guestsCount
        lastOrder
        lastOrderDate
        status
        isOccupied
        occupiedAt
        qr
        area
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    products {
      items {
        id
        title
        description
        picture
        createdAt
        position
        price
        status
        kitchenID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    menus {
      items {
        id
        title
        description
        status
        picture
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    categories {
      items {
        id
        title
        description
        picture
        position
        status
        type
        kitchenID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    modifiers {
      items {
        id
        title
        upcharge
        minSelection
        maxSelection
        createdAt
        position
        status
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    users {
      items {
        id
        restaurantID
        userID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const createTable = `mutation CreateTable(
  $input: CreateTableInput!
  $condition: ModelTableConditionInput
) {
  createTable(input: $input, condition: $condition) {
    id
    title
    position
    ordersCount
    guestsCount
    lastOrder
    lastOrderDate
    status
    isOccupied
    occupiedAt
    qr
    area
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const updateTable = `mutation UpdateTable(
  $input: UpdateTableInput!
  $condition: ModelTableConditionInput
) {
  updateTable(input: $input, condition: $condition) {
    id
    title
    position
    ordersCount
    guestsCount
    lastOrder
    lastOrderDate
    status
    isOccupied
    occupiedAt
    qr
    area
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const deleteTable = `mutation DeleteTable(
  $input: DeleteTableInput!
  $condition: ModelTableConditionInput
) {
  deleteTable(input: $input, condition: $condition) {
    id
    title
    position
    ordersCount
    guestsCount
    lastOrder
    lastOrderDate
    status
    isOccupied
    occupiedAt
    qr
    area
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const createProduct = `mutation CreateProduct(
  $input: CreateProductInput!
  $condition: ModelProductConditionInput
) {
  createProduct(input: $input, condition: $condition) {
    id
    title
    description
    picture
    createdAt
    position
    price
    status
    kitchenID
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    menus {
      items {
        id
        productID
        menuID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    categories {
      items {
        id
        productID
        categoryID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    modifiers {
      items {
        id
        productID
        modifierID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const updateProduct = `mutation UpdateProduct(
  $input: UpdateProductInput!
  $condition: ModelProductConditionInput
) {
  updateProduct(input: $input, condition: $condition) {
    id
    title
    description
    picture
    createdAt
    position
    price
    status
    kitchenID
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    menus {
      items {
        id
        productID
        menuID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    categories {
      items {
        id
        productID
        categoryID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    modifiers {
      items {
        id
        productID
        modifierID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const deleteProduct = `mutation DeleteProduct(
  $input: DeleteProductInput!
  $condition: ModelProductConditionInput
) {
  deleteProduct(input: $input, condition: $condition) {
    id
    title
    description
    picture
    createdAt
    position
    price
    status
    kitchenID
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    menus {
      items {
        id
        productID
        menuID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    categories {
      items {
        id
        productID
        categoryID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    modifiers {
      items {
        id
        productID
        modifierID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const createProductMenu = `mutation CreateProductMenu(
  $input: CreateProductMenuInput!
  $condition: ModelProductMenuConditionInput
) {
  createProductMenu(input: $input, condition: $condition) {
    id
    productID
    menuID
    _version
    _deleted
    _lastChangedAt
    product {
      id
      title
      description
      picture
      createdAt
      position
      price
      status
      kitchenID
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
    }
    menu {
      id
      title
      description
      status
      picture
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      products {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const updateProductMenu = `mutation UpdateProductMenu(
  $input: UpdateProductMenuInput!
  $condition: ModelProductMenuConditionInput
) {
  updateProductMenu(input: $input, condition: $condition) {
    id
    productID
    menuID
    _version
    _deleted
    _lastChangedAt
    product {
      id
      title
      description
      picture
      createdAt
      position
      price
      status
      kitchenID
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
    }
    menu {
      id
      title
      description
      status
      picture
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      products {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const deleteProductMenu = `mutation DeleteProductMenu(
  $input: DeleteProductMenuInput!
  $condition: ModelProductMenuConditionInput
) {
  deleteProductMenu(input: $input, condition: $condition) {
    id
    productID
    menuID
    _version
    _deleted
    _lastChangedAt
    product {
      id
      title
      description
      picture
      createdAt
      position
      price
      status
      kitchenID
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
    }
    menu {
      id
      title
      description
      status
      picture
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      products {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const createMenu = `mutation CreateMenu(
  $input: CreateMenuInput!
  $condition: ModelMenuConditionInput
) {
  createMenu(input: $input, condition: $condition) {
    id
    title
    description
    status
    picture
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    products {
      items {
        id
        productID
        menuID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const updateMenu = `mutation UpdateMenu(
  $input: UpdateMenuInput!
  $condition: ModelMenuConditionInput
) {
  updateMenu(input: $input, condition: $condition) {
    id
    title
    description
    status
    picture
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    products {
      items {
        id
        productID
        menuID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const deleteMenu = `mutation DeleteMenu(
  $input: DeleteMenuInput!
  $condition: ModelMenuConditionInput
) {
  deleteMenu(input: $input, condition: $condition) {
    id
    title
    description
    status
    picture
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    products {
      items {
        id
        productID
        menuID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const createProductCategory = `mutation CreateProductCategory(
  $input: CreateProductCategoryInput!
  $condition: ModelProductCategoryConditionInput
) {
  createProductCategory(input: $input, condition: $condition) {
    id
    productID
    categoryID
    _version
    _deleted
    _lastChangedAt
    product {
      id
      title
      description
      picture
      createdAt
      position
      price
      status
      kitchenID
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
    }
    category {
      id
      title
      description
      picture
      position
      status
      type
      kitchenID
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      products {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const updateProductCategory = `mutation UpdateProductCategory(
  $input: UpdateProductCategoryInput!
  $condition: ModelProductCategoryConditionInput
) {
  updateProductCategory(input: $input, condition: $condition) {
    id
    productID
    categoryID
    _version
    _deleted
    _lastChangedAt
    product {
      id
      title
      description
      picture
      createdAt
      position
      price
      status
      kitchenID
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
    }
    category {
      id
      title
      description
      picture
      position
      status
      type
      kitchenID
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      products {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const deleteProductCategory = `mutation DeleteProductCategory(
  $input: DeleteProductCategoryInput!
  $condition: ModelProductCategoryConditionInput
) {
  deleteProductCategory(input: $input, condition: $condition) {
    id
    productID
    categoryID
    _version
    _deleted
    _lastChangedAt
    product {
      id
      title
      description
      picture
      createdAt
      position
      price
      status
      kitchenID
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
    }
    category {
      id
      title
      description
      picture
      position
      status
      type
      kitchenID
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      products {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const createCategory = `mutation CreateCategory(
  $input: CreateCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  createCategory(input: $input, condition: $condition) {
    id
    title
    description
    picture
    position
    status
    type
    kitchenID
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    products {
      items {
        id
        productID
        categoryID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const updateCategory = `mutation UpdateCategory(
  $input: UpdateCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  updateCategory(input: $input, condition: $condition) {
    id
    title
    description
    picture
    position
    status
    type
    kitchenID
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    products {
      items {
        id
        productID
        categoryID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const deleteCategory = `mutation DeleteCategory(
  $input: DeleteCategoryInput!
  $condition: ModelCategoryConditionInput
) {
  deleteCategory(input: $input, condition: $condition) {
    id
    title
    description
    picture
    position
    status
    type
    kitchenID
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    products {
      items {
        id
        productID
        categoryID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const createProductModifier = `mutation CreateProductModifier(
  $input: CreateProductModifierInput!
  $condition: ModelProductModifierConditionInput
) {
  createProductModifier(input: $input, condition: $condition) {
    id
    productID
    modifierID
    _version
    _deleted
    _lastChangedAt
    product {
      id
      title
      description
      picture
      createdAt
      position
      price
      status
      kitchenID
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
    }
    modifier {
      id
      title
      upcharge
      minSelection
      maxSelection
      createdAt
      position
      status
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      products {
        nextToken
        startedAt
      }
      optionsConnection {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const updateProductModifier = `mutation UpdateProductModifier(
  $input: UpdateProductModifierInput!
  $condition: ModelProductModifierConditionInput
) {
  updateProductModifier(input: $input, condition: $condition) {
    id
    productID
    modifierID
    _version
    _deleted
    _lastChangedAt
    product {
      id
      title
      description
      picture
      createdAt
      position
      price
      status
      kitchenID
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
    }
    modifier {
      id
      title
      upcharge
      minSelection
      maxSelection
      createdAt
      position
      status
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      products {
        nextToken
        startedAt
      }
      optionsConnection {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const deleteProductModifier = `mutation DeleteProductModifier(
  $input: DeleteProductModifierInput!
  $condition: ModelProductModifierConditionInput
) {
  deleteProductModifier(input: $input, condition: $condition) {
    id
    productID
    modifierID
    _version
    _deleted
    _lastChangedAt
    product {
      id
      title
      description
      picture
      createdAt
      position
      price
      status
      kitchenID
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
    }
    modifier {
      id
      title
      upcharge
      minSelection
      maxSelection
      createdAt
      position
      status
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      products {
        nextToken
        startedAt
      }
      optionsConnection {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const createModifier = `mutation CreateModifier(
  $input: CreateModifierInput!
  $condition: ModelModifierConditionInput
) {
  createModifier(input: $input, condition: $condition) {
    id
    title
    upcharge
    minSelection
    maxSelection
    createdAt
    position
    status
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    products {
      items {
        id
        productID
        modifierID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    optionsConnection {
      items {
        id
        modifierID
        title
        upcharge
        status
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const updateModifier = `mutation UpdateModifier(
  $input: UpdateModifierInput!
  $condition: ModelModifierConditionInput
) {
  updateModifier(input: $input, condition: $condition) {
    id
    title
    upcharge
    minSelection
    maxSelection
    createdAt
    position
    status
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    products {
      items {
        id
        productID
        modifierID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    optionsConnection {
      items {
        id
        modifierID
        title
        upcharge
        status
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const deleteModifier = `mutation DeleteModifier(
  $input: DeleteModifierInput!
  $condition: ModelModifierConditionInput
) {
  deleteModifier(input: $input, condition: $condition) {
    id
    title
    upcharge
    minSelection
    maxSelection
    createdAt
    position
    status
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    products {
      items {
        id
        productID
        modifierID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    optionsConnection {
      items {
        id
        modifierID
        title
        upcharge
        status
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const createModifierOption = `mutation CreateModifierOption(
  $input: CreateModifierOptionInput!
  $condition: ModelModifierOptionConditionInput
) {
  createModifierOption(input: $input, condition: $condition) {
    id
    modifierID
    title
    upcharge
    status
    _version
    _deleted
    _lastChangedAt
    modifier {
      id
      title
      upcharge
      minSelection
      maxSelection
      createdAt
      position
      status
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      products {
        nextToken
        startedAt
      }
      optionsConnection {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const updateModifierOption = `mutation UpdateModifierOption(
  $input: UpdateModifierOptionInput!
  $condition: ModelModifierOptionConditionInput
) {
  updateModifierOption(input: $input, condition: $condition) {
    id
    modifierID
    title
    upcharge
    status
    _version
    _deleted
    _lastChangedAt
    modifier {
      id
      title
      upcharge
      minSelection
      maxSelection
      createdAt
      position
      status
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      products {
        nextToken
        startedAt
      }
      optionsConnection {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const deleteModifierOption = `mutation DeleteModifierOption(
  $input: DeleteModifierOptionInput!
  $condition: ModelModifierOptionConditionInput
) {
  deleteModifierOption(input: $input, condition: $condition) {
    id
    modifierID
    title
    upcharge
    status
    _version
    _deleted
    _lastChangedAt
    modifier {
      id
      title
      upcharge
      minSelection
      maxSelection
      createdAt
      position
      status
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
      products {
        nextToken
        startedAt
      }
      optionsConnection {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const createOrder = `mutation CreateOrder(
  $input: CreateOrderInput!
  $condition: ModelOrderConditionInput
) {
  createOrder(input: $input, condition: $condition) {
    id
    createdAt
    restaurantID
    tableID
    isPaid
    closedAt
    status
    orderType
    guests
    guestDevices
    orderDaySerie
    orderSerie
    notes
    deliveryAmount
    deliveryAddress
    _version
    _deleted
    _lastChangedAt
    payments {
      items {
        id
        createdAt
        paymentData
        status
        amount
        tipAmount
        guest
        notes
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    items {
      items {
        id
        productID
        tableID
        restaurantID
        categoryID
        title
        qty
        price
        taxes
        discount
        amount
        modifiers
        notes
        addedAt
        status
        guest
        sentAt
        print
        isPaid
        paidAt
        kitchenID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    customer {
      id
      username
      name
      stripeCustomer
      _version
      _deleted
      _lastChangedAt
    }
    table {
      id
      title
      position
      ordersCount
      guestsCount
      lastOrder
      lastOrderDate
      status
      isOccupied
      occupiedAt
      qr
      area
      _version
      _deleted
      _lastChangedAt
    }
  }
}
`;
export const updateOrder = `mutation UpdateOrder(
  $input: UpdateOrderInput!
  $condition: ModelOrderConditionInput
) {
  updateOrder(input: $input, condition: $condition) {
    id
    createdAt
    restaurantID
    tableID
    isPaid
    closedAt
    status
    orderType
    guests
    guestDevices
    orderDaySerie
    orderSerie
    notes
    _version
    _deleted
    _lastChangedAt
    payments {
      items {
        id
        createdAt
        paymentData
        status
        amount
        tipAmount
        guest
        notes
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    items {
      items {
        id
        productID
        tableID
        restaurantID
        categoryID
        title
        qty
        price
        taxes
        discount
        amount
        modifiers
        notes
        addedAt
        status
        guest
        sentAt
        print
        isPaid
        paidAt
        kitchenID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    customer {
      id
      username
      name
      stripeCustomer
      _version
      _deleted
      _lastChangedAt
    }
    table {
      id
      title
      position
      ordersCount
      guestsCount
      lastOrder
      lastOrderDate
      status
      isOccupied
      occupiedAt
      qr
      area
      _version
      _deleted
      _lastChangedAt
    }
  }
}
`;
export const deleteOrder = `mutation DeleteOrder(
  $input: DeleteOrderInput!
  $condition: ModelOrderConditionInput
) {
  deleteOrder(input: $input, condition: $condition) {
    id
    createdAt
    restaurantID
    tableID
    isPaid
    closedAt
    status
    orderType
    guests
    orderDaySerie
    orderSerie
    notes
    _version
    _deleted
    _lastChangedAt
    payments {
      items {
        id
        createdAt
        paymentData
        status
        amount
        tipAmount
        guest
        notes
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    items {
      items {
        id
        productID
        tableID
        restaurantID
        categoryID
        title
        qty
        price
        taxes
        discount
        amount
        modifiers
        notes
        addedAt
        status
        guest
        sentAt
        print
        isPaid
        paidAt
        kitchenID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
      createdAt
      paymentData
      status
      amount
      tipAmount
      deliveryAmount
      guest
      notes
      invoiceID
      _version
      _deleted
      _lastChangedAt
      order {
        id
        createdAt
        orderCustomerId
        restaurantID
        tableID
        isPaid
        closedAt
        status
        orderType
        guests
        guestDevices
        orderDaySerie
        orderSerie
        notes
        deliveryAddress
        receiverPhone
        receiverName
        receiverEmail
        externalId
        serverID
        _version
        _deleted
        _lastChangedAt
        restaurant {
          id
          slug
          restaurantCompanyId
          name
          address
          timezone
          country
          rfc
          legalName
          fiscalRegime
          accountId
          taxes
          taxesIncluded
          createdAt
          status
          currency
          subscriptionId
          picture
          invoiceSerie
          invoiceFolio
          invoiceAllowedDays
          printer
          contactPhone
          contactEmail
          schedule
          businessType
          tipsEnabled
          cardPayments
          cashPayments
          oxxoPayments
          tpvPayments
          roomChargePayments
          paypalPayments
          paypalAccount
          hasPreordering
          hasDelivery
          hasDinein
          description
          cardPicture
          deliveryPrice
          deliveryType
          minOrderAmount
          freeDeliveryThreshold
          bondEnabled
          bondTotal
          bondBenefit
          bondDescription
          uberLink
          rappiLink
          sdLink
          paymentType
          hasOnSite
          language
          fireEnabled
          notificationTokens
          prepaidMode
          surcharges
          disableAutoTranslate
          _version
          _deleted
          _lastChangedAt
        }
        table {
          id
          title
          tableRestaurantId
          position
          ordersCount
          guestsCount
          lastOrder
          lastOrderDate
          status
          isOccupied
          occupiedAt
          qr
          area
          _version
          _deleted
          _lastChangedAt
        }
        payments {
          nextToken
          startedAt
        }
        items {
          nextToken
          startedAt
        }
        customer {
          id
          username
          name
          role
          phone_number
          stripeCustomer
          stripeCustomerMx
          notificationTokens
          fiscalData
          autoInvoicing
          principalRfc
          addresses
          balance
          picture
          job
          bio
          comeDelivered
          pin
          _version
          _deleted
          _lastChangedAt
        }
        invoices {
          nextToken
          startedAt
        }
      }
      customer {
        id
        username
        name
        role
        phone_number
        stripeCustomer
        stripeCustomerMx
        notificationTokens
        fiscalData
        autoInvoicing
        principalRfc
        addresses
        balance
        picture
        job
        bio
        comeDelivered
        pin
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          status
          logo
          cover
          primaryColor
          secondaryColor
          richDescription
          shortDescription
          gallery
          fbLink
          instaLink
          twLink
          ytLink
          contactEmail
          fbPixel
          fbMessenger
          ga
          subdomain
          businessType
          _version
          _deleted
          _lastChangedAt
        }
        orders {
          nextToken
          startedAt
        }
        payments {
          nextToken
          startedAt
        }
        restaurants {
          nextToken
          startedAt
        }
        invoices {
          nextToken
          startedAt
        }
      }
    }
  }
`;
export const updatePayment = `mutation UpdatePayment(
  $input: UpdatePaymentInput!
  $condition: ModelPaymentConditionInput
) {
  updatePayment(input: $input, condition: $condition) {
    id
    createdAt
    paymentData
    status
    amount
    tipAmount
    guest
    notes
    _version
    _deleted
    _lastChangedAt
    order {
      id
      createdAt
      restaurantID
      tableID
      isPaid
      closedAt
      status
      orderType
      guests
      orderDaySerie
      orderSerie
      notes
      _version
      _deleted
      _lastChangedAt
      payments {
        nextToken
        startedAt
      }
      items {
        nextToken
        startedAt
      }
      customer {
        id
        username
        name
        stripeCustomer
        _version
        _deleted
        _lastChangedAt
      }
    }
    customer {
      id
      username
      name
      stripeCustomer
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      orders {
        nextToken
        startedAt
      }
      payments {
        nextToken
        startedAt
      }
      restaurants {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const deletePayment = `mutation DeletePayment(
  $input: DeletePaymentInput!
  $condition: ModelPaymentConditionInput
) {
  deletePayment(input: $input, condition: $condition) {
    id
    createdAt
    paymentData
    status
    amount
    tipAmount
    guest
    notes
    _version
    _deleted
    _lastChangedAt
    order {
      id
      createdAt
      restaurantID
      tableID
      isPaid
      closedAt
      status
      orderType
      guests
      orderDaySerie
      orderSerie
      notes
      _version
      _deleted
      _lastChangedAt
      payments {
        nextToken
        startedAt
      }
      items {
        nextToken
        startedAt
      }
      customer {
        id
        username
        name
        stripeCustomer
        _version
        _deleted
        _lastChangedAt
      }
    }
    customer {
      id
      username
      name
      stripeCustomer
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      orders {
        nextToken
        startedAt
      }
      payments {
        nextToken
        startedAt
      }
      restaurants {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const createOrderItem = /* GraphQL */ `
  mutation CreateOrderItem(
    $input: CreateOrderItemInput!
    $condition: ModelOrderItemConditionInput
  ) {
    createOrderItem(input: $input, condition: $condition) {
      id
      orderItemOrderId
      productID
      tableID
      restaurantID
      categoryID
      title
      qty
      price
      taxes
      discount
      amount
      modifiers
      notes
      addedAt
      status
      guest
      sentAt
      print
      isPaid
      paidAt
      kitchenID
      itemType
      deliveryDate
      travelData
      _version
      _deleted
      _lastChangedAt
      order {
        id
        createdAt
        restaurantID
        tableID
        isPaid
        closedAt
        status
        orderType
        guests
        orderDaySerie
        orderSerie
        notes
        deliveryAddress
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;
export const updateOrderItem = /* GraphQL */ `
  mutation UpdateOrderItem(
    $input: UpdateOrderItemInput!
    $condition: ModelOrderItemConditionInput
  ) {
    updateOrderItem(input: $input, condition: $condition) {
      id
      orderItemOrderId
      productID
      tableID
      restaurantID
      categoryID
      title
      qty
      price
      taxes
      discount
      amount
      modifiers
      notes
      addedAt
      status
      guest
      sentAt
      print
      isPaid
      paidAt
      kitchenID
      itemType
      deliveryDate
      travelData
      _version
      _deleted
      _lastChangedAt
      order {
        id
        createdAt
        restaurantID
        tableID
        isPaid
        closedAt
        status
        orderType
        guests
        orderDaySerie
        orderSerie
        notes
        deliveryAddress
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;
export const deleteOrderItem = /* GraphQL */ `
  mutation DeleteOrderItem(
    $input: DeleteOrderItemInput!
    $condition: ModelOrderItemConditionInput
  ) {
    deleteOrderItem(input: $input, condition: $condition) {
      id
      orderItemOrderId
      productID
      tableID
      restaurantID
      categoryID
      title
      qty
      price
      taxes
      discount
      amount
      modifiers
      notes
      addedAt
      status
      guest
      sentAt
      print
      isPaid
      paidAt
      kitchenID
      itemType
      deliveryDate
      travelData
      _version
      _deleted
      _lastChangedAt
      order {
        id
        createdAt
        restaurantID
        tableID
        isPaid
        closedAt
        status
        orderType
        guests
        orderDaySerie
        orderSerie
        notes
        deliveryAddress
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
`;
export const createRestaurantUser = `mutation CreateRestaurantUser(
  $input: CreateRestaurantUserInput!
  $condition: ModelRestaurantUserConditionInput
) {
  createRestaurantUser(input: $input, condition: $condition) {
    id
    restaurantID
    userID
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    user {
      id
      username
      name
      stripeCustomer
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      orders {
        nextToken
        startedAt
      }
      payments {
        nextToken
        startedAt
      }
      restaurants {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const updateRestaurantUser = `mutation UpdateRestaurantUser(
  $input: UpdateRestaurantUserInput!
  $condition: ModelRestaurantUserConditionInput
) {
  updateRestaurantUser(input: $input, condition: $condition) {
    id
    restaurantID
    userID
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    user {
      id
      username
      name
      stripeCustomer
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      orders {
        nextToken
        startedAt
      }
      payments {
        nextToken
        startedAt
      }
      restaurants {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const deleteRestaurantUser = `mutation DeleteRestaurantUser(
  $input: DeleteRestaurantUserInput!
  $condition: ModelRestaurantUserConditionInput
) {
  deleteRestaurantUser(input: $input, condition: $condition) {
    id
    restaurantID
    userID
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      tables {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      menus {
        nextToken
        startedAt
      }
      categories {
        nextToken
        startedAt
      }
      modifiers {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    user {
      id
      username
      name
      stripeCustomer
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
      orders {
        nextToken
        startedAt
      }
      payments {
        nextToken
        startedAt
      }
      restaurants {
        nextToken
        startedAt
      }
    }
  }
}
`;
export const createUser = `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    username
    name
    stripeCustomer
    fiscalData
    autoInvoicing
    principalRfc
    _version
    _deleted
    _lastChangedAt
    company {
      id
      name
      status
      _version
      _deleted
      _lastChangedAt
      restaurants {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    orders {
      items {
        id
        createdAt
        restaurantID
        tableID
        isPaid
        closedAt
        status
        orderType
        guests
        orderDaySerie
        orderSerie
        notes
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    payments {
      items {
        id
        createdAt
        paymentData
        status
        amount
        tipAmount
        guest
        notes
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    restaurants {
      items {
        id
        restaurantID
        userID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const updateUser = `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    username
    name
    stripeCustomer
    fiscalData
    autoInvoicing
    principalRfc
    _version
    _deleted
    _lastChangedAt
    company {
      id
      name
      status
      _version
      _deleted
      _lastChangedAt
    }
    orders {
      items {
        id
        createdAt
        restaurantID
        tableID
        isPaid
        closedAt
        status
        orderType
        guests
        orderDaySerie
        orderSerie
        notes
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    payments {
      items {
        id
        createdAt
        paymentData
        status
        amount
        tipAmount
        guest
        notes
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const deleteUser = `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    username
    name
    stripeCustomer
    fiscalData
    autoInvoicing
    principalRfc
    _version
    _deleted
    _lastChangedAt
    company {
      id
      name
      status
      _version
      _deleted
      _lastChangedAt
      restaurants {
        nextToken
        startedAt
      }
      users {
        nextToken
        startedAt
      }
    }
    orders {
      items {
        id
        createdAt
        restaurantID
        tableID
        isPaid
        closedAt
        status
        orderType
        guests
        orderDaySerie
        orderSerie
        notes
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    payments {
      items {
        id
        createdAt
        paymentData
        status
        amount
        tipAmount
        guest
        notes
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    restaurants {
      items {
        id
        restaurantID
        userID
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;

export const createCommand = `mutation CreateCommand(
  $input: CreateCommandInput!
  $condition: ModelCommandConditionInput
) {
  createCommand(input: $input, condition: $condition) {
    id
    createdAt
    restaurantID
    orderID
    print
    status
    kitchenID 
    items
    _version
    _deleted
    _lastChangedAt
    order {
      id
      createdAt
      restaurantID
      tableID
      isPaid
      closedAt 
      status
      orderType
      guests
      orderDaySerie
      orderSerie
      notes
      _version
      _deleted
      _lastChangedAt
      table {
        id
        title
        position
        ordersCount
        guestsCount
        lastOrder
        lastOrderDate
        status
        isOccupied
        occupiedAt
        qr
        area
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
}
`;

export const createReview = /* GraphQL */ `
  mutation CreateReview(
    $input: CreateReviewInput!
    $condition: ModelReviewConditionInput
  ) {
    createReview(input: $input, condition: $condition) {
      id
      restaurantID
      service
      host
      waiter
      cashier
      manager
      product
      place
      comments
      preferUs
      orderID
      userID
      createdAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const createIdentity = /* GraphQL */ `
  mutation CreateIdentity(
    $input: CreateIdentityInput!
    $condition: ModelIdentityConditionInput
  ) {
    createIdentity(input: $input, condition: $condition) {
      id
      notificationTokens
      _version
      _deleted
      _lastChangedAt
      createdAt
    }
  }
`;
export const updateIdentity = /* GraphQL */ `
  mutation UpdateIdentity(
    $input: UpdateIdentityInput!
    $condition: ModelIdentityConditionInput
  ) {
    updateIdentity(input: $input, condition: $condition) {
      id
      notificationTokens
      _version
      _deleted
      _lastChangedAt
      createdAt
    }
  }
`;
