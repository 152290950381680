import React from "react";

const useStripe = (
  apiKey = process.env.REACT_APP_STRIPE_APIKEY,
  options = {}
) => {
  const [stripe, setStripe] = React.useState(null);

  React.useEffect(() => {
    console.log("useStripe");
    let mounted = true;
    if (window.Stripe) {
      if (mounted) {
        setStripe(window.Stripe(apiKey, options));
      }
    } else {
      document.querySelector("#stripe-js").addEventListener("load", () => {
        // Create Stripe instance once Stripe.js loads
        if (mounted) {
          setStripe(window.Stripe(apiKey, options));
        }
      });
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKey, options?.stripeAccount]);

  return stripe;
};

export default useStripe;
