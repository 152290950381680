import React from "react";
import { Box } from "@material-ui/core";
import { I18n } from "aws-amplify";
import { useSnackbar } from "notistack";
import { API, graphqlOperation } from "aws-amplify";
import {
  Switch,
  useRouteMatch,
  Route,
  useParams,
  useHistory,
  Redirect,
} from "react-router-dom";
import { Slider } from "@2dine/promos";

import Loading from "../../components/Loading";
import useReducer from "../../reducers";
import * as queries from "../../graphql/queries";
// import Skeleton from "@material-ui/lab/Skeleton";
import MenusWidget from "../../components/Menu/MenusWidget";
// import RouterBreadcrumbs from "../../components/RouterBreadcrumbs";
import businessLanguage from "../../lib/businessLanguage";
import { isSafariBrowser } from "../../util";
import RestaurantCategories from "../../components/Delivery/RestaurantCategories";

import DeliveryMenu from "./menu";
import DeliveryCategory from "./category";
import DeliveryProduct from "./product";
import { Helmet } from "react-helmet";

const DeliveryRestaurant = ({ fromSite = false }) => {
  const { path, url } = useRouteMatch();
  const { restaurantId } = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(true);

  const [
    {
      siteReducer: { siteData },
      deliveryReducer: { selectedRestaurant },
    },
    dispatch,
  ] = useReducer();

  let restId = fromSite ? siteData?.id : restaurantId;

  const btype =
    selectedRestaurant && selectedRestaurant.businessType
      ? selectedRestaurant.businessType
      : "RESTAURANT";

  React.useEffect(() => {
    let mounted = true;

    const fetchData = async () => {
      try {
        const { data: { getRestaurant: currentRestaurant } = {} } =
          (await API.graphql({
            ...graphqlOperation(queries.getRestaurantData, {
              id: restId,
            }),
            authMode: "AWS_IAM",
          })) || {};
        console.log("restaurant", currentRestaurant);

        if (
          !currentRestaurant ||
          currentRestaurant._deleted ||
          currentRestaurant.status === "INACTIVE"
        ) {
          enqueueSnackbar(I18n.get("No commerce found, ask for help."), {
            variant: "error",
            autoHideDuration: 5000,
          });
          if (mounted) {
            setLoading(false);
          }

          if (fromSite) {
            window.location.href =
              process.env.NODE_ENV === "production"
                ? "https://www.2dine.io"
                : "http://localhost:3000";
          } else {
            history.replace("/d");
          }
        } else {
          if (mounted) {
            dispatch({
              type: "DELIVERY_RESTAURANT_SET",
              payload: { selectedRestaurant: currentRestaurant },
            });

            if (currentRestaurant?.accountId) {
              let apiName = "api616cdc2a";
              let path = "/stripe/account";

              try {
                const result = await API.get(apiName, path, {
                  queryStringParameters: {
                    account: currentRestaurant?.accountId,
                  },
                });
                console.log("/stripe/account response", result);
                dispatch({
                  type: "CHECKOUT_SET_ACCOUNT_CHARGES_ENABLED",
                  payload: {
                    accountChargesEnabled: Boolean(result.charges_enabled),
                  },
                });
              } catch (error) {
                console.log("error", error);
              }
            }

            if (
              currentRestaurant?.primaryColor ||
              currentRestaurant?.company?.primaryColor
            ) {
              dispatch({
                type: "THEME_SET_CUSTOM",
                payload: {
                  primaryColor:
                    currentRestaurant?.primaryColor ||
                    currentRestaurant?.company?.primaryColor,
                },
              });
            }

            setLoading(false);
          }
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };

    if (restId) {
      fetchData();
    } else {
      if (mounted) {
        setLoading(false);
      }
      history.replace("/d");
      return;
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restId]);

  const { menus: { items: menus = [] } = { items: [] } } =
    selectedRestaurant || {};

  if (loading) {
    return <Loading force />;
  }

  return (
    <>
      <Helmet>
        <title>{selectedRestaurant.name}</title>
        <meta name="description" content={selectedRestaurant.description} />
      </Helmet>
      <Switch>
        <Route
          exact
          path={`${path}`}
          render={() => (
            <Box flex={1} mb={7}>
              <div style={{ marginLeft: -16, marginRight: -16 }}>
                <Slider
                  restaurantID={restId}
                  history={history}
                  path={url}
                  delivery
                  fromSite={fromSite}
                />
              </div>

              <MenusWidget
                title={businessLanguage[btype]["Menus"]}
                loading={loading}
                menus={menus}
                restaurantId={restId}
                delivery
                fromSite={fromSite}
              />

              <RestaurantCategories restaurantID={restId} fromSite={fromSite} />
              {isSafariBrowser ? (
                <div id="space" style={{ minHeight: 64, height: 64 }} />
              ) : null}
            </Box>
          )}
        />

        <Route path={`${path}/menu`}>
          <DeliveryMenu fromSite={fromSite} />
        </Route>
        <Route path={`${path}/category/:categoryId`}>
          <DeliveryCategory fromSite={fromSite} />
        </Route>
        <Route path={`${path}/product/:productId`}>
          <DeliveryProduct fromSite={fromSite} />
        </Route>
        <Route path="*">
          <Redirect to={path} />
        </Route>
      </Switch>
    </>
  );
};

export default DeliveryRestaurant;
