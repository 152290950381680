import React from "react";
import { I18n } from "aws-amplify";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import TextField from "@material-ui/core/TextField";

import useReducer from "../../reducers";
import CurrencyInput from "../CurrencyInput";
import { toCurrency, getTotal, getRemainingAmount } from "../../util";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .simple-keyboard.hg-theme-default.myTheme": {
      borderRadius: theme.shape.borderRadius,
    },
    "& .simple-keyboard.hg-layout-default .hg-button": {
      background: theme.palette.background.default,
      color: theme.palette.text.primary,
      boxShadow: theme.shadows[1],
      border: "none",
      ...theme.typography.button,
      fontSize: "1rem",
    },
  },
  title: {
    textAlign: "center",
    margin: theme.spacing(1, 0),
  },
  button: {
    width: 120,
  },
  inputInput: {
    textAlign: "right",
    fontSize: 24,
    fontWeight: 700,
  },
}));

const DivideButtonGroup = () => {
  const classes = useStyles();
  const [
    {
      orderReducer: {
        orderAmount,
        orderTaxes,
        amountPaid,
        orderDiscountAmount,
      },
      checkoutReducer: { divide, dividePayment, amountToPay },
    },
    dispatch,
  ] = useReducer();
  const variant = (value) =>
    dividePayment === value ? "contained" : "outlined";

  const total = React.useMemo(() => getTotal(orderAmount, orderTaxes), [
    orderAmount,
    orderTaxes,
  ]);

  const remainingAmount = React.useMemo(
    () => getRemainingAmount(total, amountPaid, orderDiscountAmount),
    [total, amountPaid, orderDiscountAmount]
  );

  const handleAmountToPay = (value) => {
    if (value < 3) {
      dispatch({
        type: "CHECKOUT_SET_A2P",
        payload: {
          amountToPay: +(remainingAmount / (value + 2)).toFixed(0),
        },
      });
    }
  };

  const handleDivideType = (value) => () => {
    handleAmountToPay(value);
    dispatch({
      type: "CHECKOUT_SET_DIVIDE_TYPE",
      payload: { dividePayment: value },
    });

    if (value === 3) {
      dispatch({
        type: "CHECKOUT_SET_STEP",
        payload: { checkoutStep: 1 },
      });
    } else if (value === 4) {
      dispatch({
        type: "CHECKOUT_SET_STEP",
        payload: { checkoutStep: 2 },
      });
    }
  };

  return (
    <Collapse in={divide}>
      <Box my={2} px={1} textAlign="center">
        <ButtonGroup
          size="large"
          color="primary"
          aria-label="divide type button group"
        >
          <Button variant={variant(0)} onClick={handleDivideType(0)}>
            1/2
          </Button>
          <Button variant={variant(1)} onClick={handleDivideType(1)}>
            1/3
          </Button>
          <Button variant={variant(2)} onClick={handleDivideType(2)}>
            1/4
          </Button>
          <Button variant={variant(3)} onClick={handleDivideType(3)}>
            {I18n.get("by guest")}
          </Button>
        </ButtonGroup>
        <Box my={1}>
          <Button
            variant={variant(4)}
            onClick={handleDivideType(4)}
            color="secondary"
            size="large"
          >
            {I18n.get("other")}
          </Button>
        </Box>
        {dividePayment < 3 ? (
          <CurrencyInput
            Component={TextField}
            variant="filled"
            fullWidth
            placeholder={I18n.get("Amount to pay")}
            InputProps={{
              classes: { input: classes.inputInput },
              readOnly: true,
            }}
            value={amountToPay ? toCurrency(amountToPay.toFixed(0)) : "0.00"}
          />
        ) : null}
      </Box>
    </Collapse>
  );
};

export default DivideButtonGroup;
