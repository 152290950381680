import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

const useStyles = makeStyles(theme => ({
  root: {
    '& .simple-keyboard.hg-theme-default.myTheme': {
      borderRadius: theme.shape.borderRadius
    },
    '& .simple-keyboard.hg-layout-default .hg-button': {
      background: theme.palette.background.default,
      color: theme.palette.text.primary,
      boxShadow: theme.shadows[1],
      border: 'none',
      ...theme.typography.button,
      fontSize: '1rem'
    }
  }
}));

const NumericKeyboard = ({ onChange, onKeyPress, keyboardRef }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Keyboard
        keyboardRef={r => (keyboardRef.current = r)}
        disableCaretPositioning
        onChange={input => onChange(input)}
        onKeyPress={button => onKeyPress(button)}
        theme={'hg-theme-default hg-layout-default myTheme'}
        layout={{
          default: ['1 2 3', '4 5 6', '7 8 9', '0 00 {bksp}']
        }}
        display={{
          '{bksp}': 'delete'
        }}
        buttonTheme={[
          {
            class: 'hg-button',
            buttons: '1 2 3 4 5 6 7 8 9 0 {bksp}'
          }
        ]}
      />
    </div>
  );
};

export default NumericKeyboard;
