import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import useReducer from "../reducers";

const useStyles = makeStyles((theme) => ({
  loaderWrapper: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: theme.zIndex.tooltip + 1,
    overflow: "hidden",
  },
  loader: {
    display: "block",
    position: "relative",
    left: "50%",
    top: "50%",
    width: 150,
    height: 150,
    margin: "-75px 0 0 -75px",
    borderRadius: "50%",
    border: "3px solid transparent",
    borderTopColor: "#1090b1",
    animation: `$spin 1.7s linear infinite`,
    zIndex: 11,

    "&::before": {
      content: "''",
      position: "absolute",
      top: 5,
      left: 5,
      right: 5,
      bottom: 5,
      borderRadius: "50%",
      border: "3px solid transparent",
      borderTopColor: "#e74c3c",
      animation: `$spin-reverse .6s linear infinite`,
    },
    "&::after": {
      content: "''",
      position: "absolute",
      top: 15,
      left: 15,
      right: 15,
      bottom: 15,
      borderRadius: "50%",
      border: "3px solid transparent",
      borderTopColor: theme.palette.primary.main,
      animation: `$spin 1s linear infinite`,
    },
  },

  "@keyframes spin": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
  "@keyframes spin-reverse": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(-360deg)",
    },
  },

  loaderSection: {
    position: "fixed",
    top: 0,
    width: "51%",
    height: "100%",
    background: "#222",
    zIndex: 10,
  },
  loaderSectionLeft: {
    left: 0,
  },
  loaderSectionRight: {
    right: 0,
  },

  loadedSectionLeft: {
    transform: "translateX(-100%)",
    transition: "all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000)",
  },
  loadedSectionRight: {
    transform: "translateX(100%)",
    transition: "all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000)",
  },
  loadedLoader: {
    opacity: 0,
    transition: "all 0.3s ease-out",
  },
  loadedLoaderWrapper: {
    visibility: "hidden",
    transform: "translateY(-100%)",
    transition: "all 0.3s 1s ease-out",
  },
}));

const FullscreenLoader = () => {
  const classes = useStyles();
  const [
    {
      loadingReducer: { loadingApp },
    },
  ] = useReducer();
  return (
    <div
      className={clsx(classes.loaderWrapper, {
        [classes.loadedLoaderWrapper]: !loadingApp,
      })}
    >
      <div
        className={clsx(classes.loader, {
          [classes.loadedLoader]: !loadingApp,
        })}
      ></div>

      <div
        className={clsx(classes.loaderSection, classes.loaderSectionLeft, {
          [classes.loadedSectionLeft]: !loadingApp,
        })}
      ></div>
      <div
        className={clsx(classes.loaderSection, classes.loaderSectionRight, {
          [classes.loadedSectionRight]: !loadingApp,
        })}
      ></div>
    </div>
  );
};

export default FullscreenLoader;
