import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import OrderItemUpdate from '../components/Order/OrderItemUpdate';
import ProductComponent from '../components/Product/ProductComponent';

const ProductPage = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <ProductComponent />
      </Route>
      <Route path={`${path}/update/:oiid`}>
        <OrderItemUpdate />
      </Route>
    </Switch>
  );
};

export default ProductPage;
