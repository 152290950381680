import React from "react";
// import Fuse from "fuse.js";
import Box from "@material-ui/core/Box";
import { API, graphqlOperation } from "aws-amplify";

import * as queries from "../../graphql/queries";
import useReducer from "../../reducers";
import SearchComponent from "./SearchComponent";
import SearchList from "./SearchList";
import useDebounce from "../../hooks/useDebounce";

const Container = ({ delivery, forwardedRef, ...other }) => {
  const [
    {
      searchReducer: { searchQuery },
      restaurantReducer: { currentRestaurant },
      deliveryReducer: { selectedRestaurant },
    },
  ] = useReducer();
  // const { businessType } = selectedRestaurant || { businessType: "RESTAURANT" };
  // const [loading, setLoading] = React.useState(true);
  // const [products, setProducts] = React.useState([]);
  const { id: currRestID } = delivery ? selectedRestaurant : currentRestaurant;
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      // setLoading(true);

      try {
        const { data } = await API.graphql({
          ...graphqlOperation(queries.searchProducts, {
            filter: {
              productRestaurantId: { eq: currRestID },
              or: [
                { title: { wildcard: "*" + debouncedSearchQuery + "*" } },
                { description: { wildcard: "*" + debouncedSearchQuery + "*" } },
              ],
            },
          }),
          authMode: "AWS_IAM",
        });
        const { searchProducts: { items } = { items: [] } } = data || {};

        console.log("searchProducts", items);
        if (mounted) {
          // setLoading(false);
          setItems(items.filter((x) => !x._deleted || x.status !== "INACTIVE"));
        }
      } catch (error) {
        console.log("error", error);
        if (mounted) {
          // setLoading(false);
          setItems([]);
        }
      }
    };

    if (currRestID && debouncedSearchQuery) {
      fetchData();
    }

    return () => {
      mounted = false;
    };
  }, [currRestID, debouncedSearchQuery]);

  // React.useEffect(() => {
  //   let mounted = true;

  //   if (loading) {
  //     return;
  //   }

  //   let fuse = null;
  //   var options = {
  //     shouldSort: true,
  //     threshold: 0.5,
  //     location: 0,
  //     distance: 100,
  //     maxPatternLength: 32,
  //     minMatchCharLength: 1,
  //   };

  //   // if (!delivery || isCommerce) {
  //   options["keys"] = ["title", "description"];
  //   fuse = new Fuse(
  //     products.filter((x) => !x._deleted && x.status === "ACTIVE"),
  //     options
  //   );

  //   if (debouncedSearchQuery) {
  //     var result = fuse ? fuse.search(debouncedSearchQuery) : [];
  //     console.log("search result", result);
  //     if (mounted) {
  //       setItems(result);
  //     }
  //   } else {
  //     if (mounted) {
  //       setItems([]);
  //     }
  //   }

  //   return () => {
  //     mounted = false;
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [debouncedSearchQuery, delivery, products, loading]);

  return (
    <Box {...other} ref={forwardedRef} overflow="auto">
      <SearchComponent inModal />
      <Box mt={8}>
        <SearchList items={items} delivery={delivery} />
      </Box>
    </Box>
  );
};

export default React.forwardRef((props, ref) => (
  <Container {...props} forwardedRef={ref} />
));
