import React from "react";
import { Box } from "@material-ui/core";
import { Redirect, useParams } from "react-router-dom";

import useReducer from "../../reducers";
import { isEmptyObject, isSafariBrowser } from "../../util";
import CategoryWidget from "../../components/Category/CategoryWidget";

const DeliveryCategory = ({ fromSite = false }) => {
  let { restaurantId } = useParams();
  const [
    {
      deliveryReducer: { selectedRestaurant },
    },
  ] = useReducer();

  if (!selectedRestaurant || isEmptyObject(selectedRestaurant)) {
    return <Redirect to={fromSite ? `/d` : `/d/r/${restaurantId}`} />;
  }

  return (
    <Box mb={7}>
      <CategoryWidget delivery fromSite={fromSite} />
      {isSafariBrowser ? (
        <div id="space" style={{ minHeight: 64, height: 64 }} />
      ) : null}
    </Box>
  );
};

export default DeliveryCategory;
