/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const syncCompanies = `query SyncCompanies(
  $filter: ModelCompanyFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCompanies(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      status
      restaurants {
        items {
          id
          slug
          company {
            id
            name
            status
            restaurants {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          name
          address
          timezone
          country
          taxes
          taxesIncluded
          createdAt
          status
          currency
          subscriptionId
          tables {
            items {
              id
              title
              position
          area
              ordersCount
              guestsCount
              lastOrder
              lastOrderDate
              status
              isOccupied
              occupiedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          menus {
            items {
              id
              title
              description
              status
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          categories {
            items {
              id
              title
              description
              picture
              position
              status
              type
              kitchenID
              i18n
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          modifiers {
            items {
              id
              title
              upcharge
              minSelection
              maxSelection
              createdAt
              position
              status
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          products {
            items {
              id
              title
              description
              picture
              createdAt
              position
              price
              status
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          users {
            items {
              id
              restaurantID
              userID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      users {
        items {
          id
          username
          name
          company {
            id
            name
            status
            restaurants {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          restaurants {
            items {
              id
              restaurantID
              userID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          orders {
            items {
              id
              createdAt
              restaurantID
              tableID
              isPaid
              closedAt
              status
              orderType
              guests
              orderDaySerie
              orderSerie
              notes
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          stripeCustomer
          payments {
            items {
              id
              createdAt
              paymentData
              status
              amount
              tipAmount
              guest
              notes
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
    startedAt
  }
}
`;
export const getCompany = `query GetCompany($id: ID!) {
  getCompany(id: $id) {
    id
    name
    status
    restaurants {
      items {
        id
        slug
        company {
          id
          name
          status
          restaurants {
            items {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          users {
            items {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        tables {
          items {
            id
            title
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            position
          area
            ordersCount
            guestsCount
            lastOrder
            lastOrderDate
            status
            isOccupied
            occupiedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        menus {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            status
            products {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        categories {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            position
            status
            products {
              nextToken
              startedAt
            }
            type
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        modifiers {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            upcharge
            minSelection
            maxSelection
            createdAt
            position
            status
            products {
              nextToken
              startedAt
            }
            options {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        products {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            createdAt
            position
            categories {
              nextToken
              startedAt
            }
            price
            modifiers {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            status
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        users {
          items {
            id
            restaurantID
            userID
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            user {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    users {
      items {
        id
        username
        name
        company {
          id
          name
          status
          restaurants {
            items {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          users {
            items {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        restaurants {
          items {
            id
            restaurantID
            userID
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            user {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        orders {
          items {
            id
            createdAt
            customer {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            restaurantID
            tableID
            payments {
              nextToken
              startedAt
            }
            isPaid
            closedAt
            status
            orderType
            guests
            orderDaySerie
            orderSerie
            notes
            items {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        stripeCustomer
        payments {
          items {
            id
            order {
              id
              createdAt
              restaurantID
              tableID
              isPaid
              closedAt
              status
              orderType
              guests
              orderDaySerie
              orderSerie
              notes
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            paymentData
            status
            amount
            tipAmount
            guest
            notes
            customer {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    _version
    _deleted
    _lastChangedAt
  }
}
`;
export const listCompanys = `query ListCompanys(
  $filter: ModelCompanyFilterInput
  $limit: Int
  $nextToken: String
) {
  listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      status
      restaurants {
        items {
          id
          slug
          company {
            id
            name
            status
            restaurants {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          name
          address
          timezone
          country
          taxes
          taxesIncluded
          createdAt
          status
          currency
          subscriptionId
          tables {
            items {
              id
              title
              position
          area
              ordersCount
              guestsCount
              lastOrder
              lastOrderDate
              status
              isOccupied
              occupiedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          menus {
            items {
              id
              title
              description
              status
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          categories {
            items {
              id
              title
              description
              picture
              position
              status
              type
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          modifiers {
            items {
              id
              title
              upcharge
              minSelection
              maxSelection
              createdAt
              position
              status
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          products {
            items {
              id
              title
              description
              picture
              createdAt
              position
              price
              status
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          users {
            items {
              id
              restaurantID
              userID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      users {
        items {
          id
          username
          name
          company {
            id
            name
            status
            restaurants {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          restaurants {
            items {
              id
              restaurantID
              userID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          orders {
            items {
              id
              createdAt
              restaurantID
              tableID
              isPaid
              closedAt
              status
              orderType
              guests
              orderDaySerie
              orderSerie
              notes
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          stripeCustomer
          payments {
            items {
              id
              createdAt
              paymentData
              status
              amount
              tipAmount
              guest
              notes
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
    startedAt
  }
}
`;
export const syncRestaurants = `query SyncRestaurants(
  $filter: ModelRestaurantFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncRestaurants(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      slug
      company {
        id
        name
        status
        restaurants {
          items {
            id
            slug
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            name
            address
            timezone
            country
            taxes
            taxesIncluded
            createdAt
            status
            currency
            subscriptionId
            tables {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            categories {
              nextToken
              startedAt
            }
            modifiers {
              nextToken
              startedAt
            }
            products {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        users {
          items {
            id
            username
            name
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            restaurants {
              nextToken
              startedAt
            }
            orders {
              nextToken
              startedAt
            }
            stripeCustomer
            payments {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      tables {
        items {
          id
          title
          restaurant {
            id
            slug
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            name
            address
            timezone
            country
            taxes
            taxesIncluded
            createdAt
            status
            currency
            subscriptionId
            tables {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            categories {
              nextToken
              startedAt
            }
            modifiers {
              nextToken
              startedAt
            }
            products {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          position
          area
          ordersCount
          guestsCount
          lastOrder
          lastOrderDate
          status
          isOccupied
          occupiedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      menus {
        items {
          id
          restaurant {
            id
            slug
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            name
            address
            timezone
            country
            taxes
            taxesIncluded
            createdAt
            status
            currency
            subscriptionId
            tables {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            categories {
              nextToken
              startedAt
            }
            modifiers {
              nextToken
              startedAt
            }
            products {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          title
          description
          status
          products {
            items {
              id
              productID
              menuID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      categories {
        items {
          id
          restaurant {
            id
            slug
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            name
            address
            timezone
            country
            taxes
            taxesIncluded
            createdAt
            status
            currency
            subscriptionId
            tables {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            categories {
              nextToken
              startedAt
            }
            modifiers {
              nextToken
              startedAt
            }
            products {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          title
          description
          picture
          position
          status
          products {
            items {
              id
              productID
              categoryID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          type
          kitchenID
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      modifiers {
        items {
          id
          restaurant {
            id
            slug
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            name
            address
            timezone
            country
            taxes
            taxesIncluded
            createdAt
            status
            currency
            subscriptionId
            tables {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            categories {
              nextToken
              startedAt
            }
            modifiers {
              nextToken
              startedAt
            }
            products {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          title
          upcharge
          minSelection
          maxSelection
          createdAt
          position
          status
          products {
            items {
              id
              productID
              modifierID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          options {
            items {
              id
              modifierID
              title
              upcharge
              status
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      products {
        items {
          id
          restaurant {
            id
            slug
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            name
            address
            timezone
            country
            taxes
            taxesIncluded
            createdAt
            status
            currency
            subscriptionId
            tables {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            categories {
              nextToken
              startedAt
            }
            modifiers {
              nextToken
              startedAt
            }
            products {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          title
          description
          picture
          createdAt
          position
          categories {
            items {
              id
              productID
              categoryID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          price
          modifiers {
            items {
              id
              productID
              modifierID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          menus {
            items {
              id
              productID
              menuID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          status
          kitchenID
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      users {
        items {
          id
          restaurantID
          userID
          restaurant {
            id
            slug
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            name
            address
            timezone
            country
            taxes
            taxesIncluded
            createdAt
            status
            currency
            subscriptionId
            tables {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            categories {
              nextToken
              startedAt
            }
            modifiers {
              nextToken
              startedAt
            }
            products {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          user {
            id
            username
            name
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            restaurants {
              nextToken
              startedAt
            }
            orders {
              nextToken
              startedAt
            }
            stripeCustomer
            payments {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
    startedAt
  }
}
`;
export const getRestaurant = /* GraphQL */ `
  query GetRestaurant($id: ID!) {
    getRestaurant(id: $id) {
      id
      slug
      company {
        id
        name
        status
        primaryColor
        secondaryColor
        businessType
        principalRestaurantID
        _version
        _deleted
        _lastChangedAt
      }
      name
      address
      location {
        lat
        lon
      }
      timezone
      country
      taxes
      taxesIncluded
      accountId
      createdAt
      status
      currency
      subscriptionId
      picture
      contactPhone
      contactEmail
      schedule
      businessType
      tipsEnabled
      cardPayments
      cashPayments
      oxxoPayments
      tpvPayments
      roomChargePayments
      paypalPayments
      paypalAccount
      hasPreordering
      hasDelivery
      hasDinein
      description
      descriptionMarkdown
      cardPicture
      deliveryPrice
      deliveryType
      minOrderAmount
      freeDeliveryThreshold
      bondEnabled
      bondTotal
      bondBenefit
      bondDescription
      uberLink
      rappiLink
      sdLink
      paymentType
      hasOnSite
      language
      fireEnabled
      notificationTokens
      nativeNotificationTokens
      prepaidMode
      isPrincipal
      surcharges
      primaryColor
      secondaryColor
      disableAutoTranslate
      tips
      tables {
        items {
          id
          title
          ordersCount
          guestsCount
          lastOrder
          lastOrderDate
          status
          isOccupied
          occupiedAt
          area
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      menus(filter: { status: { eq: ACTIVE } }) {
        items {
          id
          title
          description
          status
          picture
          schedule
          i18n
          view
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      categories(
        limit: 500
        filter: {
          and: [
            { type: { ne: KITCHEN } }
            { type: { ne: AREA } }
            { type: { ne: SECTION } }
            { status: { eq: ACTIVE } }
          ]
        }
      ) {
        items {
          id
          title
          description
          picture
          position
          status
          type
          kitchenID
          isSubcategory
          view
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      modifiers(limit: 1000, filter: { status: { eq: ACTIVE } }) {
        items {
          id
          title
          upcharge
          minSelection
          maxSelection
          createdAt
          position
          status
          optionsConnection(limit: 500, filter: { status: { eq: ACTIVE } }) {
            items {
              id
              modifierID
              title
              upcharge
              status
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      products(limit: 1500, filter: { status: { eq: ACTIVE } }) {
        items {
          id
          title
          titleMarkdown
          description
          descriptionMarkdown
          picture
          createdAt
          position
          price
          status
          kitchenID
          kitchens
          compare
          minQuantity
          maxQuantity
          quantityField
          notesField
          schedule
          preorderingEnabled
          deliveryEnabled
          preorderingExtraAmount
          deliveryExtraAmount
          step
          travelData
          i18n
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listRestaurants = `query ListRestaurants(
  $filter: ModelRestaurantFilterInput
  $limit: Int
  $nextToken: String
) {
  listRestaurants(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      slug
      company {
        id
        name
        status
        restaurants {
          items {
            id
            slug
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            name
            address
            timezone
            country
            taxes
            taxesIncluded
            createdAt
            status
            currency
            subscriptionId
            tables {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            categories {
              nextToken
              startedAt
            }
            modifiers {
              nextToken
              startedAt
            }
            products {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        users {
          items {
            id
            username
            name
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            restaurants {
              nextToken
              startedAt
            }
            orders {
              nextToken
              startedAt
            }
            stripeCustomer
            payments {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      tables {
        items {
          id
          title
          restaurant {
            id
            slug
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            name
            address
            timezone
            country
            taxes
            taxesIncluded
            createdAt
            status
            currency
            subscriptionId
            tables {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            categories {
              nextToken
              startedAt
            }
            modifiers {
              nextToken
              startedAt
            }
            products {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          position
          area
          ordersCount
          guestsCount
          lastOrder
          lastOrderDate
          status
          isOccupied
          occupiedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      menus {
        items {
          id
          restaurant {
            id
            slug
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            name
            address
            timezone
            country
            taxes
            taxesIncluded
            createdAt
            status
            currency
            subscriptionId
            tables {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            categories {
              nextToken
              startedAt
            }
            modifiers {
              nextToken
              startedAt
            }
            products {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          title
          description
          status
          products {
            items {
              id
              productID
              menuID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      categories {
        items {
          id
          restaurant {
            id
            slug
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            name
            address
            timezone
            country
            taxes
            taxesIncluded
            createdAt
            status
            currency
            subscriptionId
            tables {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            categories {
              nextToken
              startedAt
            }
            modifiers {
              nextToken
              startedAt
            }
            products {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          title
          description
          picture
          position
          status
          products {
            items {
              id
              productID
              categoryID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          type
          kitchenID
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      modifiers {
        items {
          id
          restaurant {
            id
            slug
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            name
            address
            timezone
            country
            taxes
            taxesIncluded
            createdAt
            status
            currency
            subscriptionId
            tables {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            categories {
              nextToken
              startedAt
            }
            modifiers {
              nextToken
              startedAt
            }
            products {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          title
          upcharge
          minSelection
          maxSelection
          createdAt
          position
          status
          products {
            items {
              id
              productID
              modifierID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          options {
            items {
              id
              modifierID
              title
              upcharge
              status
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      products {
        items {
          id
          restaurant {
            id
            slug
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            name
            address
            timezone
            country
            taxes
            taxesIncluded
            createdAt
            status
            currency
            subscriptionId
            tables {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            categories {
              nextToken
              startedAt
            }
            modifiers {
              nextToken
              startedAt
            }
            products {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          title
          titleMarkdown
          description
          descriptionMarkdown
          picture
          createdAt
          position
          categories {
            items {
              id
              productID
              categoryID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          price
          modifiers {
            items {
              id
              productID
              modifierID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          menus {
            items {
              id
              productID
              menuID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          status
          kitchenID
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      users {
        items {
          id
          restaurantID
          userID
          restaurant {
            id
            slug
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            name
            address
            timezone
            country
            taxes
            taxesIncluded
            createdAt
            status
            currency
            subscriptionId
            tables {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            categories {
              nextToken
              startedAt
            }
            modifiers {
              nextToken
              startedAt
            }
            products {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          user {
            id
            username
            name
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            restaurants {
              nextToken
              startedAt
            }
            orders {
              nextToken
              startedAt
            }
            stripeCustomer
            payments {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
    startedAt
  }
}
`;
export const syncTables = `query SyncTables(
  $filter: ModelTableFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTables(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      title
      position
      ordersCount
      guestsCount
      lastOrder
      lastOrderDate
      status
      isOccupied
      occupiedAt
      qr
      area
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
    }
    nextToken
    startedAt
  }
}
`;
export const getTable = `query GetTable($id: ID!) {
  getTable(id: $id) {
    id
    title
    position
    ordersCount
    guestsCount
    lastOrder
    lastOrderDate
    status
    isOccupied
    occupiedAt
    qr
    area
    _version
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      _version
      _deleted
      _lastChangedAt
    }
  }
}
`;
export const listTables = `query ListTables(
  $filter: ModelTableFilterInput
  $limit: Int
  $nextToken: String
) {
  listTables(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      position
      ordersCount
      guestsCount
      lastOrder
      lastOrderDate
      status
      isOccupied
      occupiedAt
      qr
      area
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        _version
        _deleted
        _lastChangedAt
      }
    }
    nextToken
    startedAt
  }
}
`;

export const syncProducts = `query SyncProducts(
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncProducts(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      restaurant {
        id
        slug
        company {
          id
          name
          status
          restaurants {
            items {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          users {
            items {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        tables {
          items {
            id
            title
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            position
          area
            ordersCount
            guestsCount
            lastOrder
            lastOrderDate
            status
            isOccupied
            occupiedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        menus {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            status
            products {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        categories {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            position
            status
            products {
              nextToken
              startedAt
            }
            type
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        modifiers {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            upcharge
            minSelection
            maxSelection
            createdAt
            position
            status
            products {
              nextToken
              startedAt
            }
            options {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        products {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            createdAt
            position
            categories {
              nextToken
              startedAt
            }
            price
            modifiers {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            status
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        users {
          items {
            id
            restaurantID
            userID
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            user {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      title
      description
      picture
      createdAt
      position
      categories {
        items {
          id
          productID
          categoryID
          product {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            createdAt
            position
            categories {
              nextToken
              startedAt
            }
            price
            modifiers {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            status
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          category {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            position
            status
            products {
              nextToken
              startedAt
            }
            type
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      price
      modifiers {
        items {
          id
          productID
          modifierID
          product {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            createdAt
            position
            categories {
              nextToken
              startedAt
            }
            price
            modifiers {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            status
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          modifier {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            upcharge
            minSelection
            maxSelection
            createdAt
            position
            status
            products {
              nextToken
              startedAt
            }
            options {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      menus {
        items {
          id
          productID
          menuID
          product {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            createdAt
            position
            categories {
              nextToken
              startedAt
            }
            price
            modifiers {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            status
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          menu {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            status
            products {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      status
      kitchenID
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
    startedAt
  }
}
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      title
      titleMarkdown
      description
      descriptionMarkdown
      picture
      createdAt
      position
      price
      status
      kitchenID
      kitchens
      compare
      minQuantity
      maxQuantity
      quantityField
      notesField
      schedule
      preorderingEnabled
      deliveryEnabled
      preorderingExtraAmount
      deliveryExtraAmount
      step
      travelData
      i18n
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        rfc
        legalName
        fiscalRegime
        accountId
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        invoiceSerie
        invoiceFolio
        printer
        contactPhone
        contactEmail
        schedule
        businessType
        tipsEnabled
        cardPayments
        cashPayments
        oxxoPayments
        tpvPayments
        roomChargePayments
        paypalPayments
        paypalAccount
        hasPreordering
        hasDelivery
        hasDinein
        description
        cardPicture
        disableAutoTranslate
        _version
        _deleted
        _lastChangedAt
        # modifiers(limit: 1000, filter: { status: { eq: ACTIVE } }) {
        #   items {
        #     id
        #     title
        #     upcharge
        #     minSelection
        #     maxSelection
        #     createdAt
        #     position
        #     status
        #     optionsConnection(limit: 500, filter: { status: { eq: ACTIVE } }) {
        #       items {
        #         id
        #         modifierID
        #         title
        #         upcharge
        #         status
        #         _version
        #         _deleted
        #         _lastChangedAt
        #       }
        #       nextToken
        #       startedAt
        #     }
        #     products(limit: 1500) {
        #       items {
        #         id
        #         productID
        #         modifierID
        #         position
        #         _version
        #         _deleted
        #         _lastChangedAt
        #       }
        #       nextToken
        #       startedAt
        #     }
        #     _version
        #     _deleted
        #     _lastChangedAt
        #   }
        #   nextToken
        #   startedAt
        # }
      }
      categories {
        items {
          id
          productID
          categoryID
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      modifiers(limit: 1000) {
        items {
          id
          productID
          modifierID
          position
          modifier {
            id
            title
            upcharge
            minSelection
            maxSelection
            createdAt
            position
            status
            i18n
            optionsConnection(limit: 500, filter: { status: { eq: ACTIVE } }) {
              items {
                id
                modifierID
                title
                upcharge
                status
                i18n
                _version
                _deleted
                _lastChangedAt
              }
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listProducts = `query ListProducts(
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      restaurant {
        id
        slug
        company {
          id
          name
          status
          restaurants {
            items {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          users {
            items {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        tables {
          items {
            id
            title
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            position
          area
            ordersCount
            guestsCount
            lastOrder
            lastOrderDate
            status
            isOccupied
            occupiedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        menus {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            status
            products {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        categories {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            position
            status
            products {
              nextToken
              startedAt
            }
            type
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        modifiers {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            upcharge
            minSelection
            maxSelection
            createdAt
            position
            status
            products {
              nextToken
              startedAt
            }
            options {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        products {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            createdAt
            position
            categories {
              nextToken
              startedAt
            }
            price
            modifiers {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            status
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        users {
          items {
            id
            restaurantID
            userID
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            user {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      title
      description
      picture
      createdAt
      position
      categories {
        items {
          id
          productID
          categoryID
          product {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            createdAt
            position
            categories {
              nextToken
              startedAt
            }
            price
            modifiers {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            status
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          category {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            position
            status
            products {
              nextToken
              startedAt
            }
            type
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      price
      modifiers {
        items {
          id
          productID
          modifierID
          product {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            createdAt
            position
            categories {
              nextToken
              startedAt
            }
            price
            modifiers {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            status
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          modifier {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            upcharge
            minSelection
            maxSelection
            createdAt
            position
            status
            products {
              nextToken
              startedAt
            }
            options {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      menus {
        items {
          id
          productID
          menuID
          product {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            createdAt
            position
            categories {
              nextToken
              startedAt
            }
            price
            modifiers {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            status
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          menu {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            status
            products {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      status
      kitchenID
      compare
      minQuantity
      maxQuantity
      quantityField
      notesField
      i18n
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
    startedAt
  }
}
`;
export const syncProductMenus = `query SyncProductMenus(
  $filter: ModelProductMenuFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncProductMenus(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      productID
      menuID
      product {
        id
        restaurant {
          id
          slug
          company {
            id
            name
            status
            restaurants {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          name
          address
          timezone
          country
          taxes
          taxesIncluded
          createdAt
          status
          currency
          subscriptionId
          tables {
            items {
              id
              title
              position
          area
              ordersCount
              guestsCount
              lastOrder
              lastOrderDate
              status
              isOccupied
              occupiedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          menus {
            items {
              id
              title
              description
              status
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          categories {
            items {
              id
              title
              description
              picture
              position
              status
              type
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          modifiers {
            items {
              id
              title
              upcharge
              minSelection
              maxSelection
              createdAt
              position
              status
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          products {
            items {
              id
              title
              description
              picture
              createdAt
              position
              price
              status
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          users {
            items {
              id
              restaurantID
              userID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        title
        description
        picture
        createdAt
        position
        categories {
          items {
            id
            productID
            categoryID
            product {
              id
              title
              description
              picture
              createdAt
              position
              price
              status
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            category {
              id
              title
              description
              picture
              position
              status
              type
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        price
        modifiers {
          items {
            id
            productID
            modifierID
            product {
              id
              title
              description
              picture
              createdAt
              position
              price
              status
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            modifier {
              id
              title
              upcharge
              minSelection
              maxSelection
              createdAt
              position
              status
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        menus {
          items {
            id
            productID
            menuID
            product {
              id
              title
              description
              picture
              createdAt
              position
              price
              status
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            menu {
              id
              title
              description
              status
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        status
        kitchenID
        _version
        _deleted
        _lastChangedAt
      }
      menu {
        id
        restaurant {
          id
          slug
          company {
            id
            name
            status
            restaurants {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          name
          address
          timezone
          country
          taxes
          taxesIncluded
          createdAt
          status
          currency
          subscriptionId
          tables {
            items {
              id
              title
              position
          area
              ordersCount
              guestsCount
              lastOrder
              lastOrderDate
              status
              isOccupied
              occupiedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          menus {
            items {
              id
              title
              description
              status
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          categories {
            items {
              id
              title
              description
              picture
              position
              status
              type
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          modifiers {
            items {
              id
              title
              upcharge
              minSelection
              maxSelection
              createdAt
              position
              status
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          products {
            items {
              id
              title
              description
              picture
              createdAt
              position
              price
              status
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          users {
            items {
              id
              restaurantID
              userID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        title
        description
        status
        products {
          items {
            id
            productID
            menuID
            product {
              id
              title
              description
              picture
              createdAt
              position
              price
              status
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            menu {
              id
              title
              description
              status
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
    startedAt
  }
}
`;
export const syncMenus = `query SyncMenus(
  $filter: ModelMenuFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMenus(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      restaurant {
        id
        slug
        company {
          id
          name
          status
          restaurants {
            items {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          users {
            items {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        tables {
          items {
            id
            title
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            position
          area
            ordersCount
            guestsCount
            lastOrder
            lastOrderDate
            status
            isOccupied
            occupiedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        menus {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            status
            products {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        categories {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            position
            status
            products {
              nextToken
              startedAt
            }
            type
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        modifiers {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            upcharge
            minSelection
            maxSelection
            createdAt
            position
            status
            products {
              nextToken
              startedAt
            }
            options {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        products {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            createdAt
            position
            categories {
              nextToken
              startedAt
            }
            price
            modifiers {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            status
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        users {
          items {
            id
            restaurantID
            userID
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            user {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      title
      description
      status
      products {
        items {
          id
          productID
          menuID
          product {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            createdAt
            position
            categories {
              nextToken
              startedAt
            }
            price
            modifiers {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            status
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          menu {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            status
            products {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
    startedAt
  }
}
`;
export const getMenu = /* GraphQL */ `
  query GetMenu($id: ID!, $limit: Int, $nextToken: String) {
    getMenu(id: $id) {
      id
      title
      description
      status
      picture
      schedule
      i18n
      view
      _version
      _deleted
      _lastChangedAt
      categories(limit: $limit, nextToken: $nextToken) {
        items {
          id
          categoryID
          menuID
          category {
            id
            title
            description
            picture
            position
            status
            type
            kitchenID
            isSubcategory
            i18n
            view
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listMenus = `query ListMenus(
  $filter: ModelMenuFilterInput
  $limit: Int
  $nextToken: String
) {
  listMenus(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      restaurant {
        id
        slug
        company {
          id
          name
          status
          restaurants {
            items {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          users {
            items {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        tables {
          items {
            id
            title
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            position
          area
            ordersCount
            guestsCount
            lastOrder
            lastOrderDate
            status
            isOccupied
            occupiedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        menus {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            status
            products {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        categories {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            position
            status
            products {
              nextToken
              startedAt
            }
            type
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        modifiers {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            upcharge
            minSelection
            maxSelection
            createdAt
            position
            status
            products {
              nextToken
              startedAt
            }
            options {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        products {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            createdAt
            position
            categories {
              nextToken
              startedAt
            }
            price
            modifiers {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            status
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        users {
          items {
            id
            restaurantID
            userID
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            user {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      title
      description
      status
      products {
        items {
          id
          productID
          menuID
          product {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            createdAt
            position
            categories {
              nextToken
              startedAt
            }
            price
            modifiers {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            status
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          menu {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            status
            products {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
    startedAt
  }
}
`;
export const syncProductCategories = `query SyncProductCategories(
  $filter: ModelProductCategoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncProductCategories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      productID
      categoryID
      product {
        id
        restaurant {
          id
          slug
          company {
            id
            name
            status
            restaurants {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          name
          address
          timezone
          country
          taxes
          taxesIncluded
          createdAt
          status
          currency
          subscriptionId
          tables {
            items {
              id
              title
              position
          area
              ordersCount
              guestsCount
              lastOrder
              lastOrderDate
              status
              isOccupied
              occupiedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          menus {
            items {
              id
              title
              description
              status
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          categories {
            items {
              id
              title
              description
              picture
              position
              status
              type
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          modifiers {
            items {
              id
              title
              upcharge
              minSelection
              maxSelection
              createdAt
              position
              status
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          products {
            items {
              id
              title
              description
              picture
              createdAt
              position
              price
              status
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          users {
            items {
              id
              restaurantID
              userID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        title
        description
        picture
        createdAt
        position
        categories {
          items {
            id
            productID
            categoryID
            product {
              id
              title
              description
              picture
              createdAt
              position
              price
              status
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            category {
              id
              title
              description
              picture
              position
              status
              type
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        price
        modifiers {
          items {
            id
            productID
            modifierID
            product {
              id
              title
              description
              picture
              createdAt
              position
              price
              status
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            modifier {
              id
              title
              upcharge
              minSelection
              maxSelection
              createdAt
              position
              status
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        menus {
          items {
            id
            productID
            menuID
            product {
              id
              title
              description
              picture
              createdAt
              position
              price
              status
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            menu {
              id
              title
              description
              status
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        status
        kitchenID
        _version
        _deleted
        _lastChangedAt
      }
      category {
        id
        restaurant {
          id
          slug
          company {
            id
            name
            status
            restaurants {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          name
          address
          timezone
          country
          taxes
          taxesIncluded
          createdAt
          status
          currency
          subscriptionId
          tables {
            items {
              id
              title
              position
          area
              ordersCount
              guestsCount
              lastOrder
              lastOrderDate
              status
              isOccupied
              occupiedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          menus {
            items {
              id
              title
              description
              status
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          categories {
            items {
              id
              title
              description
              picture
              position
              status
              type
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          modifiers {
            items {
              id
              title
              upcharge
              minSelection
              maxSelection
              createdAt
              position
              status
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          products {
            items {
              id
              title
              description
              picture
              createdAt
              position
              price
              status
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          users {
            items {
              id
              restaurantID
              userID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        title
        description
        picture
        position
        status
        products {
          items {
            id
            productID
            categoryID
            product {
              id
              title
              description
              picture
              createdAt
              position
              price
              status
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            category {
              id
              title
              description
              picture
              position
              status
              type
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        type
        kitchenID
        _version
        _deleted
        _lastChangedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
    startedAt
  }
}
`;
export const syncCategories = `query SyncCategories(
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCategories(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      restaurant {
        id
        slug
        company {
          id
          name
          status
          restaurants {
            items {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          users {
            items {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        tables {
          items {
            id
            title
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            position
          area
            ordersCount
            guestsCount
            lastOrder
            lastOrderDate
            status
            isOccupied
            occupiedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        menus {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            status
            products {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        categories {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            position
            status
            products {
              nextToken
              startedAt
            }
            type
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        modifiers {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            upcharge
            minSelection
            maxSelection
            createdAt
            position
            status
            products {
              nextToken
              startedAt
            }
            options {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        products {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            createdAt
            position
            categories {
              nextToken
              startedAt
            }
            price
            modifiers {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            status
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        users {
          items {
            id
            restaurantID
            userID
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            user {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      title
      description
      picture
      position
      status
      products {
        items {
          id
          productID
          categoryID
          product {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            createdAt
            position
            categories {
              nextToken
              startedAt
            }
            price
            modifiers {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            status
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          category {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            position
            status
            products {
              nextToken
              startedAt
            }
            type
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      type
      kitchenID
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
    startedAt
  }
}
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      categoryRestaurantId
      title
      description
      picture
      position
      status
      type
      kitchenID
      printer
      sound
      isSubcategory
      serverID
      i18n
      view
      _version
      _deleted
      _lastChangedAt
      createdAt
      restaurant {
        id
        slug
        restaurantCompanyId
        name
        address
        location {
          lat
          lon
        }
        timezone
        country
        rfc
        legalName
        fiscalRegime
        accountId
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        invoiceSerie
        invoiceFolio
        invoiceAllowedDays
        printer
        contactPhone
        contactEmail
        schedule
        businessType
        tipsEnabled
        cardPayments
        cashPayments
        oxxoPayments
        tpvPayments
        roomChargePayments
        paypalPayments
        paypalAccount
        hasPreordering
        hasDelivery
        hasDinein
        description
        descriptionMarkdown
        cardPicture
        deliveryPrice
        deliveryType
        minOrderAmount
        freeDeliveryThreshold
        bondEnabled
        bondTotal
        bondBenefit
        bondDescription
        uberLink
        rappiLink
        sdLink
        paymentType
        language
        fireEnabled
        hasOnSite
        notificationTokens
        nativeNotificationTokens
        prepaidMode
        isPrincipal
        surcharges
        waiterFee
        disableAutoTranslate
        _version
        _deleted
        _lastChangedAt

        company {
          id
          name
          status
          logo
          cover
          primaryColor
          secondaryColor
          richDescription
          shortDescription
          gallery
          fbLink
          instaLink
          twLink
          ytLink
          contactEmail
          fbPixel
          fbMessenger
          ga
          subdomain
          businessType
          principalRestaurantID
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      products {
        items {
          id
          productID
          categoryID
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        nextToken
        startedAt
      }
      menus {
        items {
          id
          categoryID
          menuID
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        nextToken
        startedAt
      }
      subcategories(limit: 100) {
        items {
          id
          categoryID
          subcategoryID
          subcategory {
            id
            categoryRestaurantId
            title
            description
            picture
            position
            status
            type
            kitchenID
            printer
            sound
            isSubcategory
            serverID
            i18n
            view
          }
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        nextToken
        startedAt
      }
    }
  }
`;
export const listCategorys = /* GraphQL */ `
  query ListCategorys(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        categoryRestaurantId
        title
        description
        picture
        position
        status
        type
        kitchenID
        printer
        sound
        isSubcategory
        serverID
        view
        _version
        _deleted
        _lastChangedAt
        createdAt
        restaurant {
          id
          slug
          restaurantCompanyId
          name
          address
          timezone
          country
          rfc
          legalName
          fiscalRegime
          accountId
          taxes
          taxesIncluded
          createdAt
          status
          currency
          subscriptionId
          picture
          invoiceSerie
          invoiceFolio
          invoiceAllowedDays
          printer
          contactPhone
          contactEmail
          schedule
          businessType
          tipsEnabled
          cardPayments
          cashPayments
          oxxoPayments
          tpvPayments
          roomChargePayments
          paypalPayments
          paypalAccount
          hasPreordering
          hasDelivery
          hasDinein
          description
          descriptionMarkdown
          cardPicture
          deliveryPrice
          deliveryType
          minOrderAmount
          freeDeliveryThreshold
          bondEnabled
          bondTotal
          bondBenefit
          bondDescription
          uberLink
          rappiLink
          sdLink
          paymentType
          language
          fireEnabled
          hasOnSite
          notificationTokens
          nativeNotificationTokens
          prepaidMode
          isPrincipal
          surcharges
          waiterFee
          disableAutoTranslate
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProductModifiers = `query SyncProductModifiers(
  $filter: ModelProductModifierFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncProductModifiers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      productID
      modifierID
      product {
        id
        restaurant {
          id
          slug
          company {
            id
            name
            status
            restaurants {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          name
          address
          timezone
          country
          taxes
          taxesIncluded
          createdAt
          status
          currency
          subscriptionId
          tables {
            items {
              id
              title
              position
          area
              ordersCount
              guestsCount
              lastOrder
              lastOrderDate
              status
              isOccupied
              occupiedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          menus {
            items {
              id
              title
              description
              status
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          categories {
            items {
              id
              title
              description
              picture
              position
              status
              type
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          modifiers {
            items {
              id
              title
              upcharge
              minSelection
              maxSelection
              createdAt
              position
              status
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          products {
            items {
              id
              title
              description
              picture
              createdAt
              position
              price
              status
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          users {
            items {
              id
              restaurantID
              userID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        title
        description
        picture
        createdAt
        position
        categories {
          items {
            id
            productID
            categoryID
            product {
              id
              title
              description
              picture
              createdAt
              position
              price
              status
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            category {
              id
              title
              description
              picture
              position
              status
              type
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        price
        modifiers {
          items {
            id
            productID
            modifierID
            product {
              id
              title
              description
              picture
              createdAt
              position
              price
              status
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            modifier {
              id
              title
              upcharge
              minSelection
              maxSelection
              createdAt
              position
              status
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        menus {
          items {
            id
            productID
            menuID
            product {
              id
              title
              description
              picture
              createdAt
              position
              price
              status
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            menu {
              id
              title
              description
              status
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        status
        kitchenID
        _version
        _deleted
        _lastChangedAt
      }
      modifier {
        id
        restaurant {
          id
          slug
          company {
            id
            name
            status
            restaurants {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          name
          address
          timezone
          country
          taxes
          taxesIncluded
          createdAt
          status
          currency
          subscriptionId
          tables {
            items {
              id
              title
              position
          area
              ordersCount
              guestsCount
              lastOrder
              lastOrderDate
              status
              isOccupied
              occupiedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          menus {
            items {
              id
              title
              description
              status
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          categories {
            items {
              id
              title
              description
              picture
              position
              status
              type
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          modifiers {
            items {
              id
              title
              upcharge
              minSelection
              maxSelection
              createdAt
              position
              status
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          products {
            items {
              id
              title
              description
              picture
              createdAt
              position
              price
              status
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          users {
            items {
              id
              restaurantID
              userID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        title
        upcharge
        minSelection
        maxSelection
        createdAt
        position
        status
        products {
          items {
            id
            productID
            modifierID
            product {
              id
              title
              description
              picture
              createdAt
              position
              price
              status
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            modifier {
              id
              title
              upcharge
              minSelection
              maxSelection
              createdAt
              position
              status
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        options {
          items {
            id
            modifierID
            modifier {
              id
              title
              upcharge
              minSelection
              maxSelection
              createdAt
              position
              status
              _version
              _deleted
              _lastChangedAt
            }
            title
            upcharge
            status
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
    startedAt
  }
}
`;
export const syncModifiers = `query SyncModifiers(
  $filter: ModelModifierFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncModifiers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      restaurant {
        id
        slug
        company {
          id
          name
          status
          restaurants {
            items {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          users {
            items {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        tables {
          items {
            id
            title
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            position
          area
            ordersCount
            guestsCount
            lastOrder
            lastOrderDate
            status
            isOccupied
            occupiedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        menus {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            status
            products {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        categories {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            position
            status
            products {
              nextToken
              startedAt
            }
            type
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        modifiers {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            upcharge
            minSelection
            maxSelection
            createdAt
            position
            status
            products {
              nextToken
              startedAt
            }
            options {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        products {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            createdAt
            position
            categories {
              nextToken
              startedAt
            }
            price
            modifiers {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            status
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        users {
          items {
            id
            restaurantID
            userID
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            user {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      title
      upcharge
      minSelection
      maxSelection
      createdAt
      position
      status
      products {
        items {
          id
          productID
          modifierID
          product {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            createdAt
            position
            categories {
              nextToken
              startedAt
            }
            price
            modifiers {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            status
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          modifier {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            upcharge
            minSelection
            maxSelection
            createdAt
            position
            status
            products {
              nextToken
              startedAt
            }
            options {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      options {
        items {
          id
          modifierID
          modifier {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            upcharge
            minSelection
            maxSelection
            createdAt
            position
            status
            products {
              nextToken
              startedAt
            }
            options {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          title
          upcharge
          status
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
    startedAt
  }
}
`;
export const getModifier = `query GetModifier($id: ID!) {
  getModifier(id: $id) {
    id
    restaurant {
      id
      slug
      company {
        id
        name
        status
        restaurants {
          items {
            id
            slug
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            name
            address
            timezone
            country
            taxes
            taxesIncluded
            createdAt
            status
            currency
            subscriptionId
            tables {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            categories {
              nextToken
              startedAt
            }
            modifiers {
              nextToken
              startedAt
            }
            products {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        users {
          items {
            id
            username
            name
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            restaurants {
              nextToken
              startedAt
            }
            orders {
              nextToken
              startedAt
            }
            stripeCustomer
            payments {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      name
      address
      timezone
      country
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      tables {
        items {
          id
          title
          restaurant {
            id
            slug
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            name
            address
            timezone
            country
            taxes
            taxesIncluded
            createdAt
            status
            currency
            subscriptionId
            tables {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            categories {
              nextToken
              startedAt
            }
            modifiers {
              nextToken
              startedAt
            }
            products {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          position
          area
          ordersCount
          guestsCount
          lastOrder
          lastOrderDate
          status
          isOccupied
          occupiedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      menus {
        items {
          id
          restaurant {
            id
            slug
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            name
            address
            timezone
            country
            taxes
            taxesIncluded
            createdAt
            status
            currency
            subscriptionId
            tables {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            categories {
              nextToken
              startedAt
            }
            modifiers {
              nextToken
              startedAt
            }
            products {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          title
          description
          status
          products {
            items {
              id
              productID
              menuID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      categories {
        items {
          id
          restaurant {
            id
            slug
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            name
            address
            timezone
            country
            taxes
            taxesIncluded
            createdAt
            status
            currency
            subscriptionId
            tables {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            categories {
              nextToken
              startedAt
            }
            modifiers {
              nextToken
              startedAt
            }
            products {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          title
          description
          picture
          position
          status
          products {
            items {
              id
              productID
              categoryID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          type
          kitchenID
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      modifiers {
        items {
          id
          restaurant {
            id
            slug
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            name
            address
            timezone
            country
            taxes
            taxesIncluded
            createdAt
            status
            currency
            subscriptionId
            tables {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            categories {
              nextToken
              startedAt
            }
            modifiers {
              nextToken
              startedAt
            }
            products {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          title
          upcharge
          minSelection
          maxSelection
          createdAt
          position
          status
          products {
            items {
              id
              productID
              modifierID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          options {
            items {
              id
              modifierID
              title
              upcharge
              status
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      products {
        items {
          id
          restaurant {
            id
            slug
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            name
            address
            timezone
            country
            taxes
            taxesIncluded
            createdAt
            status
            currency
            subscriptionId
            tables {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            categories {
              nextToken
              startedAt
            }
            modifiers {
              nextToken
              startedAt
            }
            products {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          title
          description
          picture
          createdAt
          position
          categories {
            items {
              id
              productID
              categoryID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          price
          modifiers {
            items {
              id
              productID
              modifierID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          menus {
            items {
              id
              productID
              menuID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          status
          kitchenID
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      users {
        items {
          id
          restaurantID
          userID
          restaurant {
            id
            slug
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            name
            address
            timezone
            country
            taxes
            taxesIncluded
            createdAt
            status
            currency
            subscriptionId
            tables {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            categories {
              nextToken
              startedAt
            }
            modifiers {
              nextToken
              startedAt
            }
            products {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          user {
            id
            username
            name
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            restaurants {
              nextToken
              startedAt
            }
            orders {
              nextToken
              startedAt
            }
            stripeCustomer
            payments {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
    title
    upcharge
    minSelection
    maxSelection
    createdAt
    position
    status
    products {
      items {
        id
        productID
        modifierID
        product {
          id
          restaurant {
            id
            slug
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            name
            address
            timezone
            country
            taxes
            taxesIncluded
            createdAt
            status
            currency
            subscriptionId
            tables {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            categories {
              nextToken
              startedAt
            }
            modifiers {
              nextToken
              startedAt
            }
            products {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          title
          description
          picture
          createdAt
          position
          categories {
            items {
              id
              productID
              categoryID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          price
          modifiers {
            items {
              id
              productID
              modifierID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          menus {
            items {
              id
              productID
              menuID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          status
          kitchenID
          _version
          _deleted
          _lastChangedAt
        }
        modifier {
          id
          restaurant {
            id
            slug
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            name
            address
            timezone
            country
            taxes
            taxesIncluded
            createdAt
            status
            currency
            subscriptionId
            tables {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            categories {
              nextToken
              startedAt
            }
            modifiers {
              nextToken
              startedAt
            }
            products {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          title
          upcharge
          minSelection
          maxSelection
          createdAt
          position
          status
          products {
            items {
              id
              productID
              modifierID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          options {
            items {
              id
              modifierID
              title
              upcharge
              status
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    options {
      items {
        id
        modifierID
        modifier {
          id
          restaurant {
            id
            slug
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            name
            address
            timezone
            country
            taxes
            taxesIncluded
            createdAt
            status
            currency
            subscriptionId
            tables {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            categories {
              nextToken
              startedAt
            }
            modifiers {
              nextToken
              startedAt
            }
            products {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          title
          upcharge
          minSelection
          maxSelection
          createdAt
          position
          status
          products {
            items {
              id
              productID
              modifierID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          options {
            items {
              id
              modifierID
              title
              upcharge
              status
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        title
        upcharge
        status
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    _version
    _deleted
    _lastChangedAt
  }
}
`;
export const listModifiers = `query ListModifiers(
  $filter: ModelModifierFilterInput
  $limit: Int
  $nextToken: String
) {
  listModifiers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      restaurant {
        id
        slug
        company {
          id
          name
          status
          restaurants {
            items {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          users {
            items {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        tables {
          items {
            id
            title
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            position
          area
            ordersCount
            guestsCount
            lastOrder
            lastOrderDate
            status
            isOccupied
            occupiedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        menus {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            status
            products {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        categories {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            position
            status
            products {
              nextToken
              startedAt
            }
            type
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        modifiers {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            upcharge
            minSelection
            maxSelection
            createdAt
            position
            status
            products {
              nextToken
              startedAt
            }
            options {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        products {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            createdAt
            position
            categories {
              nextToken
              startedAt
            }
            price
            modifiers {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            status
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        users {
          items {
            id
            restaurantID
            userID
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            user {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      title
      upcharge
      minSelection
      maxSelection
      createdAt
      position
      status
      products {
        items {
          id
          productID
          modifierID
          product {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            createdAt
            position
            categories {
              nextToken
              startedAt
            }
            price
            modifiers {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            status
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          modifier {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            upcharge
            minSelection
            maxSelection
            createdAt
            position
            status
            products {
              nextToken
              startedAt
            }
            options {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      options {
        items {
          id
          modifierID
          modifier {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            upcharge
            minSelection
            maxSelection
            createdAt
            position
            status
            products {
              nextToken
              startedAt
            }
            options {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          title
          upcharge
          status
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
    startedAt
  }
}
`;
export const syncModifierOptions = `query SyncModifierOptions(
  $filter: ModelModifierOptionFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncModifierOptions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      modifierID
      modifier {
        id
        restaurant {
          id
          slug
          company {
            id
            name
            status
            restaurants {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          name
          address
          timezone
          country
          taxes
          taxesIncluded
          createdAt
          status
          currency
          subscriptionId
          tables {
            items {
              id
              title
              position
          area
              ordersCount
              guestsCount
              lastOrder
              lastOrderDate
              status
              isOccupied
              occupiedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          menus {
            items {
              id
              title
              description
              status
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          categories {
            items {
              id
              title
              description
              picture
              position
              status
              type
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          modifiers {
            items {
              id
              title
              upcharge
              minSelection
              maxSelection
              createdAt
              position
              status
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          products {
            items {
              id
              title
              description
              picture
              createdAt
              position
              price
              status
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          users {
            items {
              id
              restaurantID
              userID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        title
        upcharge
        minSelection
        maxSelection
        createdAt
        position
        status
        products {
          items {
            id
            productID
            modifierID
            product {
              id
              title
              description
              picture
              createdAt
              position
              price
              status
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            modifier {
              id
              title
              upcharge
              minSelection
              maxSelection
              createdAt
              position
              status
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        options {
          items {
            id
            modifierID
            modifier {
              id
              title
              upcharge
              minSelection
              maxSelection
              createdAt
              position
              status
              _version
              _deleted
              _lastChangedAt
            }
            title
            upcharge
            status
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      title
      upcharge
      status
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
    startedAt
  }
}
`;
export const getModifierOption = `query GetModifierOption($id: ID!) {
  getModifierOption(id: $id) {
    id
    modifierID
    modifier {
      id
      restaurant {
        id
        slug
        company {
          id
          name
          status
          restaurants {
            items {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          users {
            items {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        tables {
          items {
            id
            title
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            position
          area
            ordersCount
            guestsCount
            lastOrder
            lastOrderDate
            status
            isOccupied
            occupiedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        menus {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            status
            products {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        categories {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            position
            status
            products {
              nextToken
              startedAt
            }
            type
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        modifiers {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            upcharge
            minSelection
            maxSelection
            createdAt
            position
            status
            products {
              nextToken
              startedAt
            }
            options {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        products {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            createdAt
            position
            categories {
              nextToken
              startedAt
            }
            price
            modifiers {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            status
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        users {
          items {
            id
            restaurantID
            userID
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            user {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      title
      upcharge
      minSelection
      maxSelection
      createdAt
      position
      status
      products {
        items {
          id
          productID
          modifierID
          product {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            createdAt
            position
            categories {
              nextToken
              startedAt
            }
            price
            modifiers {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            status
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          modifier {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            upcharge
            minSelection
            maxSelection
            createdAt
            position
            status
            products {
              nextToken
              startedAt
            }
            options {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      options {
        items {
          id
          modifierID
          modifier {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            upcharge
            minSelection
            maxSelection
            createdAt
            position
            status
            products {
              nextToken
              startedAt
            }
            options {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          title
          upcharge
          status
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
    title
    upcharge
    status
    _version
    _deleted
    _lastChangedAt
  }
}
`;
export const listModifierOptions = `query ListModifierOptions(
  $filter: ModelModifierOptionFilterInput
  $limit: Int
  $nextToken: String
) {
  listModifierOptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      modifierID
      modifier {
        id
        restaurant {
          id
          slug
          company {
            id
            name
            status
            restaurants {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          name
          address
          timezone
          country
          taxes
          taxesIncluded
          createdAt
          status
          currency
          subscriptionId
          tables {
            items {
              id
              title
              position
          area
              ordersCount
              guestsCount
              lastOrder
              lastOrderDate
              status
              isOccupied
              occupiedAt
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          menus {
            items {
              id
              title
              description
              status
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          categories {
            items {
              id
              title
              description
              picture
              position
              status
              type
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          modifiers {
            items {
              id
              title
              upcharge
              minSelection
              maxSelection
              createdAt
              position
              status
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          products {
            items {
              id
              title
              description
              picture
              createdAt
              position
              price
              status
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          users {
            items {
              id
              restaurantID
              userID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        title
        upcharge
        minSelection
        maxSelection
        createdAt
        position
        status
        products {
          items {
            id
            productID
            modifierID
            product {
              id
              title
              description
              picture
              createdAt
              position
              price
              status
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            modifier {
              id
              title
              upcharge
              minSelection
              maxSelection
              createdAt
              position
              status
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        options {
          items {
            id
            modifierID
            modifier {
              id
              title
              upcharge
              minSelection
              maxSelection
              createdAt
              position
              status
              _version
              _deleted
              _lastChangedAt
            }
            title
            upcharge
            status
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      title
      upcharge
      status
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
    startedAt
  }
}
`;
export const syncOrders = `query SyncOrders(
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncOrders(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      createdAt
      customer {
        id
        username
        name
        company {
          id
          name
          status
          restaurants {
            items {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          users {
            items {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        restaurants {
          items {
            id
            restaurantID
            userID
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            user {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        orders {
          items {
            id
            createdAt
            customer {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            restaurantID
            tableID
            payments {
              nextToken
              startedAt
            }
            isPaid
            closedAt
            status
            orderType
            guests
            orderDaySerie
            orderSerie
            notes
            items {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        stripeCustomer
        payments {
          items {
            id
            order {
              id
              createdAt
              restaurantID
              tableID
              isPaid
              closedAt
              status
              orderType
              guests
              orderDaySerie
              orderSerie
              notes
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            paymentData
            status
            amount
            tipAmount
            guest
            notes
            customer {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      restaurantID
      tableID
      payments {
        items {
          id
          order {
            id
            createdAt
            customer {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            restaurantID
            tableID
            payments {
              nextToken
              startedAt
            }
            isPaid
            closedAt
            status
            orderType
            guests
            orderDaySerie
            orderSerie
            notes
            items {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          paymentData
          status
          amount
          tipAmount
          guest
          notes
          customer {
            id
            username
            name
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            restaurants {
              nextToken
              startedAt
            }
            orders {
              nextToken
              startedAt
            }
            stripeCustomer
            payments {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      isPaid
      closedAt
      status
      orderType
      guests
      orderDaySerie
      orderSerie
      notes
      items {
        items {
          id
          order {
            id
            createdAt
            customer {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            restaurantID
            tableID
            payments {
              nextToken
              startedAt
            }
            isPaid
            closedAt
            status
            orderType
            guests
            orderDaySerie
            orderSerie
            notes
            items {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          productID
          tableID
          restaurantID
          categoryID
          title
          qty
          price
          taxes
          discount
          amount
          modifiers
          notes
          addedAt
          status
          guest
          sentAt
          print
          isPaid
          paidAt
          kitchenID
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
    startedAt
  }
}
`;
export const getOrder = `query GetOrder($id: ID!) {
  getOrder(id: $id) {
    id
    createdAt
    restaurantID
    tableID
    isPaid
    closedAt
    status
    orderType
    guests
    guestDevices
    orderDaySerie
    orderSerie
    notes
    orderCustomerId
    deliveryAmount
    deliveryAddress
    receiverPhone
    receiverName
    receiverEmail
    reviewed
    surcharges
    selectedPaymentMethod
    _version 
    _deleted
    _lastChangedAt
    restaurant {
      id
      slug
      name
      address
      location {
        lat
        lon
      }
      timezone
      country
      rfc
      legalName
      fiscalRegime
      accountId
      taxes
      taxesIncluded
      createdAt
      status
      currency
      subscriptionId
      picture
      invoiceSerie
      invoiceFolio
      printer
      contactPhone
      contactEmail
      schedule
      businessType
      tipsEnabled
      cardPayments
      cashPayments
      oxxoPayments
      tpvPayments
      roomChargePayments
      paypalPayments
      paypalAccount
      hasPreordering
      hasDelivery
      hasDinein
      description
      cardPicture
      deliveryPrice
      deliveryType
      minOrderAmount
      freeDeliveryThreshold
      bondEnabled
      bondTotal
      language
      prepaidMode
      isPrincipal
      surcharges
      primaryColor
      secondaryColor
      _version
      _deleted
      _lastChangedAt
    }
    table {
      id
      title
      position
      ordersCount
      guestsCount
      lastOrder
      lastOrderDate
      status
      isOccupied
      occupiedAt
      qr
      area
      _version
      _deleted
      _lastChangedAt
    }
    payments(limit: 200) {
      items {
        id
        createdAt
        paymentData
        status
        amount
        tipAmount
        deliveryAmount
        guest
        notes
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    items(limit: 1500, filter: {status: {ne: CANCELLED}}) {
      items {
        id
        productID
        tableID
        restaurantID
        categoryID
        title
        qty
        price
        taxes
        discount
        amount
        modifiers
        notes
        addedAt
        status
        guest
        sentAt
        print
        isPaid
        paidAt
        kitchenID
        itemType
        deliveryDate
        travelData
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    customer {
      id
      username
      name
      stripeCustomer
      _version
      _deleted
      _lastChangedAt
    }
  }
}
`;
export const listOrders = `query ListOrders(
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      orderCustomerId
      customer {
        id
        username
        name
        company {
          id
          name
          status
          restaurants {
            items {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          users {
            items {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        restaurants {
          items {
            id
            restaurantID
            userID
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            user {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        orders {
          items {
            id
            createdAt
            customer {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            restaurantID
            tableID
            payments {
              nextToken
              startedAt
            }
            isPaid
            closedAt
            status
            orderType
            guests
            orderDaySerie
            orderSerie
            notes
            items {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        stripeCustomer
        payments {
          items {
            id
            order {
              id
              createdAt
              restaurantID
              tableID
              isPaid
              closedAt
              status
              orderType
              guests
              orderDaySerie
              orderSerie
              notes
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            paymentData
            status
            amount
            tipAmount
            deliveryAmount
            guest
            notes
            customer {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      restaurantID
      tableID
      payments {
        items {
          id
          order {
            id
            createdAt
            customer {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            restaurantID
            tableID
            payments {
              nextToken
              startedAt
            }
            isPaid
            closedAt
            status
            orderType
            guests
            orderDaySerie
            orderSerie
            notes
            items {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          paymentData
          status
          amount
          tipAmount
          guest
          notes
          customer {
            id
            username
            name
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            restaurants {
              nextToken
              startedAt
            }
            orders {
              nextToken
              startedAt
            }
            stripeCustomer
            payments {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      isPaid
      closedAt
      status
      orderType
      guests
      guestDevices
      orderDaySerie
      orderSerie
      notes
      items {
        items {
          id
          order {
            id
            createdAt
            customer {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            restaurantID
            tableID
            payments {
              nextToken
              startedAt
            }
            isPaid
            closedAt
            status
            orderType
            guests
            orderDaySerie
            orderSerie
            notes
            items {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          productID
          tableID
          restaurantID
          categoryID
          title
          qty
          price
          taxes
          discount
          amount
          modifiers
          notes
          addedAt
          status
          guest
          sentAt
          print
          isPaid
          paidAt
          kitchenID
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
    startedAt
  }
}
`;
export const syncPayments = `query SyncPayments(
  $filter: ModelPaymentFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPayments(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      order {
        id
        createdAt
        customer {
          id
          username
          name
          company {
            id
            name
            status
            restaurants {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          restaurants {
            items {
              id
              restaurantID
              userID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          orders {
            items {
              id
              createdAt
              restaurantID
              tableID
              isPaid
              closedAt
              status
              orderType
              guests
              orderDaySerie
              orderSerie
              notes
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          stripeCustomer
          payments {
            items {
              id
              createdAt
              paymentData
              status
              amount
              tipAmount
              guest
              notes
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        restaurantID
        tableID
        payments {
          items {
            id
            order {
              id
              createdAt
              restaurantID
              tableID
              isPaid
              closedAt
              status
              orderType
              guests
              orderDaySerie
              orderSerie
              notes
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            paymentData
            status
            amount
            tipAmount
            guest
            notes
            customer {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        isPaid
        closedAt
        status
        orderType
        guests
        orderDaySerie
        orderSerie
        notes
        items {
          items {
            id
            order {
              id
              createdAt
              restaurantID
              tableID
              isPaid
              closedAt
              status
              orderType
              guests
              orderDaySerie
              orderSerie
              notes
              _version
              _deleted
              _lastChangedAt
            }
            productID
            tableID
            restaurantID
            categoryID
            title
            qty
            price
            taxes
            discount
            amount
            modifiers
            notes
            addedAt
            status
            guest
            sentAt
            print
            isPaid
            paidAt
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      paymentData
      status
      amount
      tipAmount
      guest
      notes
      customer {
        id
        username
        name
        company {
          id
          name
          status
          restaurants {
            items {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          users {
            items {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        restaurants {
          items {
            id
            restaurantID
            userID
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            user {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        orders {
          items {
            id
            createdAt
            customer {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            restaurantID
            tableID
            payments {
              nextToken
              startedAt
            }
            isPaid
            closedAt
            status
            orderType
            guests
            orderDaySerie
            orderSerie
            notes
            items {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        stripeCustomer
        payments {
          items {
            id
            order {
              id
              createdAt
              restaurantID
              tableID
              isPaid
              closedAt
              status
              orderType
              guests
              orderDaySerie
              orderSerie
              notes
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            paymentData
            status
            amount
            tipAmount
            guest
            notes
            customer {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
    startedAt
  }
}
`;
export const getPayment = `query GetPayment($id: ID!) {
  getPayment(id: $id) {
    id
    createdAt
    paymentData
    status
    amount
    tipAmount
    deliveryAmount
    guest
    notes
    invoiceID
    _version
    _deleted
    _lastChangedAt
    order {
      id
      createdAt
      restaurantID
      tableID
      isPaid
      closedAt
      status
      orderType
      guests
      orderDaySerie
      orderSerie
      notes
      _version
      _deleted
      _lastChangedAt
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        rfc
        legalName
        fiscalRegime
        accountId
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        invoiceSerie
        invoiceFolio
        printer
        _version
        _deleted
        _lastChangedAt
      }
      customer {
        id
        username
        name
        stripeCustomer
        _version
        _deleted
        _lastChangedAt
      }
    }
    customer {
      id
      username
      name
      role
      stripeCustomer
      fiscalData
      autoInvoicing
      principalRfc
      _version
      _deleted
      _lastChangedAt
    }
  }
}
`;
export const listPayments = `query ListPayments(
  $filter: ModelPaymentFilterInput
  $limit: Int
  $nextToken: String
) {
  listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      order {
        id
        createdAt
        customer {
          id
          username
          name
          company {
            id
            name
            status
            restaurants {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          restaurants {
            items {
              id
              restaurantID
              userID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          orders {
            items {
              id
              createdAt
              restaurantID
              tableID
              isPaid
              closedAt
              status
              orderType
              guests
              orderDaySerie
              orderSerie
              notes
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          stripeCustomer
          payments {
            items {
              id
              createdAt
              paymentData
              status
              amount
              tipAmount
              guest
              notes
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        restaurantID
        tableID
        payments {
          items {
            id
            order {
              id
              createdAt
              restaurantID
              tableID
              isPaid
              closedAt
              status
              orderType
              guests
              orderDaySerie
              orderSerie
              notes
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            paymentData
            status
            amount
            tipAmount
            guest
            notes
            customer {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        isPaid
        closedAt
        status
        orderType
        guests
        orderDaySerie
        orderSerie
        notes
        items {
          items {
            id
            order {
              id
              createdAt
              restaurantID
              tableID
              isPaid
              closedAt
              status
              orderType
              guests
              orderDaySerie
              orderSerie
              notes
              _version
              _deleted
              _lastChangedAt
            }
            productID
            tableID
            restaurantID
            categoryID
            title
            qty
            price
            taxes
            discount
            amount
            modifiers
            notes
            addedAt
            status
            guest
            sentAt
            print
            isPaid
            paidAt
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      paymentData
      status
      amount
      tipAmount
      guest
      notes
      customer {
        id
        username
        name
        company {
          id
          name
          status
          restaurants {
            items {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          users {
            items {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        restaurants {
          items {
            id
            restaurantID
            userID
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            user {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        orders {
          items {
            id
            createdAt
            customer {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            restaurantID
            tableID
            payments {
              nextToken
              startedAt
            }
            isPaid
            closedAt
            status
            orderType
            guests
            orderDaySerie
            orderSerie
            notes
            items {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        stripeCustomer
        payments {
          items {
            id
            order {
              id
              createdAt
              restaurantID
              tableID
              isPaid
              closedAt
              status
              orderType
              guests
              orderDaySerie
              orderSerie
              notes
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            paymentData
            status
            amount
            tipAmount
            guest
            notes
            customer {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
    startedAt
  }
}
`;
export const syncOrderItems = `query SyncOrderItems(
  $filter: ModelOrderItemFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncOrderItems(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      order {
        id
        createdAt
        customer {
          id
          username
          name
          company {
            id
            name
            status
            restaurants {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          restaurants {
            items {
              id
              restaurantID
              userID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          orders {
            items {
              id
              createdAt
              restaurantID
              tableID
              isPaid
              closedAt
              status
              orderType
              guests
              orderDaySerie
              orderSerie
              notes
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          stripeCustomer
          payments {
            items {
              id
              createdAt
              paymentData
              status
              amount
              tipAmount
              guest
              notes
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        restaurantID
        tableID
        payments {
          items {
            id
            order {
              id
              createdAt
              restaurantID
              tableID
              isPaid
              closedAt
              status
              orderType
              guests
              orderDaySerie
              orderSerie
              notes
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            paymentData
            status
            amount
            tipAmount
            guest
            notes
            customer {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        isPaid
        closedAt
        status
        orderType
        guests
        orderDaySerie
        orderSerie
        notes
        items {
          items {
            id
            order {
              id
              createdAt
              restaurantID
              tableID
              isPaid
              closedAt
              status
              orderType
              guests
              orderDaySerie
              orderSerie
              notes
              _version
              _deleted
              _lastChangedAt
            }
            productID
            tableID
            restaurantID
            categoryID
            title
            qty
            price
            taxes
            discount
            amount
            modifiers
            notes
            addedAt
            status
            guest
            sentAt
            print
            isPaid
            paidAt
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      productID
      tableID
      restaurantID
      categoryID
      title
      qty
      price
      taxes
      discount
      amount
      modifiers
      notes
      addedAt
      status
      guest
      sentAt
      print
      isPaid
      paidAt
      kitchenID
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
    startedAt
  }
}
`;
export const getOrderItem = `query GetOrderItem($id: ID!) {
  getOrderItem(id: $id) {
    id
    orderItemOrderId
    productID
    tableID
    restaurantID
    categoryID
    title
    qty
    price
    taxes
    discount
    amount
    modifiers
    notes
    addedAt
    status
    guest
    sentAt
    print
    isPaid
    paidAt
    kitchenID
    _version
    _deleted
    _lastChangedAt 
  }
}
`;
export const listOrderItems = `query ListOrderItems(
  $filter: ModelOrderItemFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrderItems(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      order {
        id
        createdAt
        customer {
          id
          username
          name
          company {
            id
            name
            status
            restaurants {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          restaurants {
            items {
              id
              restaurantID
              userID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          orders {
            items {
              id
              createdAt
              restaurantID
              tableID
              isPaid
              closedAt
              status
              orderType
              guests
              orderDaySerie
              orderSerie
              notes
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          stripeCustomer
          payments {
            items {
              id
              createdAt
              paymentData
              status
              amount
              tipAmount
              guest
              notes
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        restaurantID
        tableID
        payments {
          items {
            id
            order {
              id
              createdAt
              restaurantID
              tableID
              isPaid
              closedAt
              status
              orderType
              guests
              orderDaySerie
              orderSerie
              notes
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            paymentData
            status
            amount
            tipAmount
            guest
            notes
            customer {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        isPaid
        closedAt
        status
        orderType
        guests
        orderDaySerie
        orderSerie
        notes
        items {
          items {
            id
            order {
              id
              createdAt
              restaurantID
              tableID
              isPaid
              closedAt
              status
              orderType
              guests
              orderDaySerie
              orderSerie
              notes
              _version
              _deleted
              _lastChangedAt
            }
            productID
            tableID
            restaurantID
            categoryID
            title
            qty
            price
            taxes
            discount
            amount
            modifiers
            notes
            addedAt
            status
            guest
            sentAt
            print
            isPaid
            paidAt
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      orderItemOrderId
      productID
      tableID
      restaurantID
      categoryID
      title
      qty
      price
      taxes
      discount
      amount
      modifiers
      notes
      addedAt
      status
      guest
      sentAt
      print
      isPaid
      paidAt
      kitchenID
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
    startedAt
  }
}
`;
export const syncRestaurantUsers = `query SyncRestaurantUsers(
  $filter: ModelRestaurantUserFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncRestaurantUsers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      restaurantID
      userID
      restaurant {
        id
        slug
        company {
          id
          name
          status
          restaurants {
            items {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          users {
            items {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        name
        address
        timezone
        country
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        tables {
          items {
            id
            title
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            position
          area
            ordersCount
            guestsCount
            lastOrder
            lastOrderDate
            status
            isOccupied
            occupiedAt
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        menus {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            status
            products {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        categories {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            position
            status
            products {
              nextToken
              startedAt
            }
            type
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        modifiers {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            upcharge
            minSelection
            maxSelection
            createdAt
            position
            status
            products {
              nextToken
              startedAt
            }
            options {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        products {
          items {
            id
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            title
            description
            picture
            createdAt
            position
            categories {
              nextToken
              startedAt
            }
            price
            modifiers {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            status
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        users {
          items {
            id
            restaurantID
            userID
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            user {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      user {
        id
        username
        name
        company {
          id
          name
          status
          restaurants {
            items {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          users {
            items {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        restaurants {
          items {
            id
            restaurantID
            userID
            restaurant {
              id
              slug
              name
              address
              timezone
              country
              taxes
              taxesIncluded
              createdAt
              status
              currency
              subscriptionId
              _version
              _deleted
              _lastChangedAt
            }
            user {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        orders {
          items {
            id
            createdAt
            customer {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            restaurantID
            tableID
            payments {
              nextToken
              startedAt
            }
            isPaid
            closedAt
            status
            orderType
            guests
            orderDaySerie
            orderSerie
            notes
            items {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        stripeCustomer
        payments {
          items {
            id
            order {
              id
              createdAt
              restaurantID
              tableID
              isPaid
              closedAt
              status
              orderType
              guests
              orderDaySerie
              orderSerie
              notes
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            paymentData
            status
            amount
            tipAmount
            guest
            notes
            customer {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
    startedAt
  }
}
`;
export const syncUsers = `query SyncUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUsers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      username
      name
      company {
        id
        name
        status
        restaurants {
          items {
            id
            slug
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            name
            address
            timezone
            country
            taxes
            taxesIncluded
            createdAt
            status
            currency
            subscriptionId
            tables {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            categories {
              nextToken
              startedAt
            }
            modifiers {
              nextToken
              startedAt
            }
            products {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        users {
          items {
            id
            username
            name
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            restaurants {
              nextToken
              startedAt
            }
            orders {
              nextToken
              startedAt
            }
            stripeCustomer
            payments {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      restaurants {
        items {
          id
          restaurantID
          userID
          restaurant {
            id
            slug
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            name
            address
            timezone
            country
            taxes
            taxesIncluded
            createdAt
            status
            currency
            subscriptionId
            tables {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            categories {
              nextToken
              startedAt
            }
            modifiers {
              nextToken
              startedAt
            }
            products {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          user {
            id
            username
            name
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            restaurants {
              nextToken
              startedAt
            }
            orders {
              nextToken
              startedAt
            }
            stripeCustomer
            payments {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      orders {
        items {
          id
          createdAt
          customer {
            id
            username
            name
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            restaurants {
              nextToken
              startedAt
            }
            orders {
              nextToken
              startedAt
            }
            stripeCustomer
            payments {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          restaurantID
          tableID
          payments {
            items {
              id
              createdAt
              paymentData
              status
              amount
              tipAmount
              guest
              notes
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          isPaid
          closedAt
          status
          orderType
          guests
          orderDaySerie
          orderSerie
          notes
          items {
            items {
              id
              productID
              tableID
              restaurantID
              categoryID
              title
              qty
              price
              taxes
              discount
              amount
              modifiers
              notes
              addedAt
              status
              guest
              sentAt
              print
              isPaid
              paidAt
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      stripeCustomer
      payments {
        items {
          id
          order {
            id
            createdAt
            customer {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            restaurantID
            tableID
            payments {
              nextToken
              startedAt
            }
            isPaid
            closedAt
            status
            orderType
            guests
            orderDaySerie
            orderSerie
            notes
            items {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          paymentData
          status
          amount
          tipAmount
          guest
          notes
          customer {
            id
            username
            name
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            restaurants {
              nextToken
              startedAt
            }
            orders {
              nextToken
              startedAt
            }
            stripeCustomer
            payments {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
    startedAt
  }
}
`;
export const getUser = `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    username
    name
    phone_number
    stripeCustomer
    fiscalData
    autoInvoicing
    principalRfc
    _version
    _deleted
    _lastChangedAt
    company {
      id
      name
      status
      _version
      _deleted
      _lastChangedAt
    }
    orders(limit: 500) {
      items {
        id
        createdAt
        restaurantID
        restaurant {
          id
          slug
          name
          address
          timezone
          country
          rfc
          legalName
          fiscalRegime
          accountId
          taxes
          taxesIncluded
          createdAt
          status
          currency
          subscriptionId
          picture
          invoiceSerie
          invoiceFolio
          printer
          _version
          _deleted
          _lastChangedAt
        }
        tableID
        isPaid
        closedAt
        status
        orderType
        guests
        guestDevices
        orderDaySerie
        orderSerie
        notes
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
    payments(limit: 500) {
      items {
        id
        createdAt
        paymentData
        status
        amount
        tipAmount
        deliveryAmount
        guest
        notes
        order {
          id
          createdAt
          restaurantID
          restaurant {
            id
            name
          }
          tableID
          isPaid
          closedAt
          status
          orderType
          guests
          orderDaySerie
          orderSerie
          notes
          _version
          _deleted
          _lastChangedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
}
`;
export const listUsers = `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      username
      name
      company {
        id
        name
        status
        restaurants {
          items {
            id
            slug
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            name
            address
            timezone
            country
            taxes
            taxesIncluded
            createdAt
            status
            currency
            subscriptionId
            tables {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            categories {
              nextToken
              startedAt
            }
            modifiers {
              nextToken
              startedAt
            }
            products {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        users {
          items {
            id
            username
            name
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            restaurants {
              nextToken
              startedAt
            }
            orders {
              nextToken
              startedAt
            }
            stripeCustomer
            payments {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      restaurants {
        items {
          id
          restaurantID
          userID
          restaurant {
            id
            slug
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            name
            address
            timezone
            country
            taxes
            taxesIncluded
            createdAt
            status
            currency
            subscriptionId
            tables {
              nextToken
              startedAt
            }
            menus {
              nextToken
              startedAt
            }
            categories {
              nextToken
              startedAt
            }
            modifiers {
              nextToken
              startedAt
            }
            products {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          user {
            id
            username
            name
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            restaurants {
              nextToken
              startedAt
            }
            orders {
              nextToken
              startedAt
            }
            stripeCustomer
            payments {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      orders {
        items {
          id
          createdAt
          customer {
            id
            username
            name
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            restaurants {
              nextToken
              startedAt
            }
            orders {
              nextToken
              startedAt
            }
            stripeCustomer
            payments {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          restaurantID
          tableID
          payments {
            items {
              id
              createdAt
              paymentData
              status
              amount
              tipAmount
              guest
              notes
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          isPaid
          closedAt
          status
          orderType
          guests
          orderDaySerie
          orderSerie
          notes
          items {
            items {
              id
              productID
              tableID
              restaurantID
              categoryID
              title
              qty
              price
              taxes
              discount
              amount
              modifiers
              notes
              addedAt
              status
              guest
              sentAt
              print
              isPaid
              paidAt
              kitchenID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      stripeCustomer
      payments {
        items {
          id
          order {
            id
            createdAt
            customer {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            restaurantID
            tableID
            payments {
              nextToken
              startedAt
            }
            isPaid
            closedAt
            status
            orderType
            guests
            orderDaySerie
            orderSerie
            notes
            items {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          createdAt
          paymentData
          status
          amount
          tipAmount
          guest
          notes
          customer {
            id
            username
            name
            company {
              id
              name
              status
              _version
              _deleted
              _lastChangedAt
            }
            restaurants {
              nextToken
              startedAt
            }
            orders {
              nextToken
              startedAt
            }
            stripeCustomer
            payments {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
    startedAt
  }
}
`;
export const ordersByRestaurantByDate = `query OrdersByRestaurantByDate(
  $restaurantID: ID
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  ordersByRestaurantByDate(
    restaurantID: $restaurantID
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      restaurantID
      tableID
      isPaid
      closedAt
      status
      orderType
      guests
      guestDevices
      orderDaySerie
      orderSerie
      notes
      _version
      _deleted
      _lastChangedAt 
    }
    nextToken
    startedAt
  }
}
`;
export const ordersByTableByStatus = `query OrdersByTableByStatus(
  $tableID: ID
  $status: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  ordersByTableByStatus(
    tableID: $tableID
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      createdAt
      restaurantID
      tableID
      payments(limit: 200) {
        items {
          id
          createdAt
          paymentData
          status
          amount
          tipAmount
          guest
          notes
          customer {
            id
            username
            name
            stripeCustomer
            _version
            _deleted
            _lastChangedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      isPaid
      closedAt
      status
      orderType
      guests
      guestDevices
      orderDaySerie
      orderSerie
      notes
      items(limit: 1500, filter: {status: {ne: CANCELLED}}) {
        items {
          id
          productID
          tableID
          restaurantID
          categoryID
          title
          qty
          price
          taxes
          discount
          amount
          modifiers
          notes
          addedAt
          status
          guest
          sentAt
          print
          isPaid
          paidAt
          kitchenID
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      table {
        id
        title
        position
        ordersCount
        guestsCount
        lastOrder
        lastOrderDate
        status
        isOccupied
        occupiedAt
        qr
        area
        _version
        _deleted
        _lastChangedAt
      }
      customer {
        id
        username
        name
        stripeCustomer
        _version
        _deleted
        _lastChangedAt
      }
      restaurant {
        id
        slug
        name
        address
        timezone
        country
        rfc
        legalName
        fiscalRegime
        accountId
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        invoiceSerie
        invoiceFolio
        printer
        _version
        _deleted
        _lastChangedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
    startedAt
  }
}
`;
export const orderItemsByRestaurantByStatusBySentAt = `query OrderItemsByRestaurantByStatusBySentAt(
  $restaurantID: ID
  $statusSentAt: ModelOrderItemByRestaurantbyStatusbySentAtCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelOrderItemFilterInput
  $limit: Int
  $nextToken: String
) {
  orderItemsByRestaurantByStatusBySentAt(
    restaurantID: $restaurantID
    statusSentAt: $statusSentAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      order {
        id
        createdAt
        customer {
          id
          username
          name
          company {
            id
            name
            status
            restaurants {
              nextToken
              startedAt
            }
            users {
              nextToken
              startedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          restaurants {
            items {
              id
              restaurantID
              userID
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          orders {
            items {
              id
              createdAt
              restaurantID
              tableID
              isPaid
              closedAt
              status
              orderType
              guests
              orderDaySerie
              orderSerie
              notes
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          stripeCustomer
          payments {
            items {
              id
              createdAt
              paymentData
              status
              amount
              tipAmount
              guest
              notes
              _version
              _deleted
              _lastChangedAt
            }
            nextToken
            startedAt
          }
          _version
          _deleted
          _lastChangedAt
        }
        restaurantID
        tableID
        payments {
          items {
            id
            order {
              id
              createdAt
              restaurantID
              tableID
              isPaid
              closedAt
              status
              orderType
              guests
              orderDaySerie
              orderSerie
              notes
              _version
              _deleted
              _lastChangedAt
            }
            createdAt
            paymentData
            status
            amount
            tipAmount
            guest
            notes
            customer {
              id
              username
              name
              stripeCustomer
              _version
              _deleted
              _lastChangedAt
            }
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        isPaid
        closedAt
        status
        orderType
        guests
        orderDaySerie
        orderSerie
        notes
        items {
          items {
            id
            order {
              id
              createdAt
              restaurantID
              tableID
              isPaid
              closedAt
              status
              orderType
              guests
              orderDaySerie
              orderSerie
              notes
              _version
              _deleted
              _lastChangedAt
            }
            productID
            tableID
            restaurantID
            categoryID
            title
            qty
            price
            taxes
            discount
            amount
            modifiers
            notes
            addedAt
            status
            guest
            sentAt
            print
            isPaid
            paidAt
            kitchenID
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
      productID
      tableID
      restaurantID
      categoryID
      title
      qty
      price
      taxes
      discount
      amount
      modifiers
      notes
      addedAt
      status
      guest
      sentAt
      print
      isPaid
      paidAt
      kitchenID
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
    startedAt
  }
}
`;
export const productsbyCategory = `query ProductsbyCategory(
  $categoryID: ID
  $productID: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  productsbyCategory(
    categoryID: $categoryID
    productID: $productID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      productID
      categoryID
      _version
      _deleted
      _lastChangedAt
      product {
        id
        title
        description
        picture
        createdAt
        position
        price
        status
        kitchenID
        kitchens
        compare
        minQuantity
        maxQuantity
        quantityField
        notesField
        schedule
        preorderingEnabled
        deliveryEnabled
        preorderingExtraAmount
        deliveryExtraAmount
        step
        travelData
        i18n
        _version
        _deleted
        _lastChangedAt
      }
    }
    nextToken
    startedAt
  }
}
`;
export const optionsByModifier = `query OptionsByModifier(
  $modifierID: ID
  $sortDirection: ModelSortDirection
  $filter: ModelModifierOptionFilterInput
  $limit: Int
  $nextToken: String
) {
  optionsByModifier(
    modifierID: $modifierID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      modifierID
      title
      upcharge
      status
      i18n
      _version
      _deleted
      _lastChangedAt
    }
    nextToken
    startedAt
  }
}
`;

export const productsbyMenu = `query ProductsbyMenu(
  $menuID: ID
  $productID: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductMenuFilterInput
  $limit: Int
  $nextToken: String
) {
  productsbyMenu(
    menuID: $menuID
    productID: $productID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      productID
      menuID
      _version
      _deleted
      _lastChangedAt
      product {
        id
        title
        description
        picture
        createdAt
        position
        price
        status
        kitchenID
        kitchens
        compare
        minQuantity
        maxQuantity
        quantityField
        notesField
        schedule
        preorderingEnabled
        deliveryEnabled
        preorderingExtraAmount
        deliveryExtraAmount
        step
        travelData
        i18n
        _version
        _deleted
        _lastChangedAt
      }
      menu {
        id
        title
        description
        status
        picture
        i18n
        _version
        _deleted
        _lastChangedAt
      }
    }
    nextToken
    startedAt
  }
}
`;
export const modifiersbyProduct = `query ModifiersbyProduct(
  $productID: ID
  $modifierID: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelProductModifierFilterInput
  $limit: Int
  $nextToken: String
) {
  modifiersbyProduct(
    productID: $productID
    modifierID: $modifierID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      productID
      modifierID
      _version
      _deleted
      _lastChangedAt
      modifier {
        id
        title
        upcharge
        minSelection
        maxSelection
        createdAt
        position
        status
        optionsConnection(limit: 500, filter: {status: {eq: ACTIVE}}) {
          items {
            id
            modifierID
            title
            upcharge
            status
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
        _version
        _deleted
        _lastChangedAt
      }
    }
    nextToken
    startedAt
  }
}
`;

export const getInvoice = `query GetInvoice($id: ID!) {
  getInvoice(id: $id) {
    id
    createdAt
    paymentID
    orderID
    restaurantID
    userID
    rfc
    pdfFile
    xmlFile
    _version
    _deleted
    _lastChangedAt
    order {
      id
      createdAt
      restaurantID
      tableID
      isPaid
      closedAt
      status
      orderType
      guests
      orderDaySerie
      orderSerie
      notes
      _version
      _deleted
      _lastChangedAt
      table {
        id
        title
        position
        ordersCount
        guestsCount
        lastOrder
        lastOrderDate
        status
        isOccupied
        occupiedAt
        qr
        area
        _version
        _deleted
        _lastChangedAt
      }
      customer {
        id
        username
        name
        role
        stripeCustomer
        fiscalData
        autoInvoicing
        principalRfc
        _version
        _deleted
        _lastChangedAt
      }
    }
    payment {
      id
      createdAt
      paymentData
      status
      amount
      tipAmount
      guest
      notes
      invoiceID
      _version
      _deleted
      _lastChangedAt
      order {
        id
        createdAt
        restaurantID
        tableID
        isPaid
        closedAt
        status
        orderType
        guests
        orderDaySerie
        orderSerie
        notes
        _version
        _deleted
        _lastChangedAt
      }
      customer {
        id
        username
        name
        role
        stripeCustomer
        fiscalData
        autoInvoicing
        principalRfc
        _version
        _deleted
        _lastChangedAt
      }
    }
    user {
      id
      username
      name
      role
      stripeCustomer
      fiscalData
      autoInvoicing
      principalRfc
      _version
      _deleted
      _lastChangedAt
      company {
        id
        name
        status
        _version
        _deleted
        _lastChangedAt
      }
    }
  }
} 
`;
export const restaurantsbyBusinessTypebyHasDelivery = /* GraphQL */ `
  query RestaurantsbyBusinessTypebyHasDelivery(
    $businessType: BusinessType
    $hasDelivery: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRestaurantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    restaurantsbyBusinessTypebyHasDelivery(
      businessType: $businessType
      hasDelivery: $hasDelivery
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        name
        address
        location {
          lat
          lon
        }
        timezone
        country
        rfc
        legalName
        fiscalRegime
        accountId
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        invoiceSerie
        invoiceFolio
        printer
        contactPhone
        contactEmail
        schedule
        businessType
        tipsEnabled
        cardPayments
        cashPayments
        oxxoPayments
        tpvPayments
        roomChargePayments
        paypalPayments
        paypalAccount
        hasPreordering
        hasDelivery
        hasDinein
        description
        descriptionMarkdown
        cardPicture
        deliveryPrice
        deliveryType
        minOrderAmount
        freeDeliveryThreshold
        bondEnabled
        bondTotal
        language
        prepaidMode
        isPrincipal
        surcharges
        disableAutoTranslate
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          status
          _version
          _deleted
          _lastChangedAt
        }
        products(limit: 1500, filter: { status: { eq: ACTIVE } }) {
          items {
            id
            title
            description
            picture
            createdAt
            position
            price
            status
            kitchenID
            kitchens
            compare
            minQuantity
            maxQuantity
            quantityField
            notesField
            schedule
            preorderingEnabled
            deliveryEnabled
            preorderingExtraAmount
            deliveryExtraAmount
            step
            travelData
            i18n
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const restaurantsbyBusinessTypebyPreordering = /* GraphQL */ `
  query RestaurantsbyBusinessTypebyPreordering(
    $businessType: BusinessType
    $hasPreordering: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRestaurantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    restaurantsbyBusinessTypebyPreordering(
      businessType: $businessType
      hasPreordering: $hasPreordering
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        name
        address
        location {
          lat
          lon
        }
        timezone
        country
        rfc
        legalName
        fiscalRegime
        accountId
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        invoiceSerie
        invoiceFolio
        printer
        contactPhone
        contactEmail
        schedule
        businessType
        tipsEnabled
        cardPayments
        cashPayments
        oxxoPayments
        tpvPayments
        roomChargePayments
        paypalPayments
        paypalAccount
        hasPreordering
        hasDelivery
        hasDinein
        description
        descriptionMarkdown
        cardPicture
        minOrderAmount
        freeDeliveryThreshold
        bondEnabled
        bondTotal
        language
        prepaidMode
        isPrincipal
        surcharges
        disableAutoTranslate
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          status
          _version
          _deleted
          _lastChangedAt
        }
        products(limit: 1500, filter: { status: { eq: ACTIVE } }) {
          items {
            id
            title
            titleMarkdown
            description
            descriptionMarkdown
            picture
            createdAt
            position
            price
            status
            kitchenID
            kitchens
            compare
            minQuantity
            maxQuantity
            quantityField
            notesField
            schedule
            preorderingEnabled
            deliveryEnabled
            preorderingExtraAmount
            deliveryExtraAmount
            step
            travelData
            i18n
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const resturantsbyBondEnabled = /* GraphQL */ `
  query ResturantsbyBondEnabled(
    $bondEnabled: Int
    $sortDirection: ModelSortDirection
    $filter: ModelRestaurantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resturantsbyBondEnabled(
      bondEnabled: $bondEnabled
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        name
        address
        location {
          lat
          lon
        }
        timezone
        country
        rfc
        legalName
        fiscalRegime
        accountId
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        invoiceSerie
        invoiceFolio
        printer
        contactPhone
        contactEmail
        schedule
        businessType
        tipsEnabled
        cardPayments
        cashPayments
        oxxoPayments
        tpvPayments
        roomChargePayments
        paypalPayments
        paypalAccount
        hasPreordering
        hasDelivery
        description
        descriptionMarkdown
        cardPicture
        deliveryPrice
        deliveryType
        minOrderAmount
        freeDeliveryThreshold
        bondEnabled
        bondTotal
        language
        prepaidMode
        isPrincipal
        surcharges
        disableAutoTranslate
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          status
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const searchRestaurants = /* GraphQL */ `
  query SearchRestaurants(
    $filter: SearchableRestaurantFilterInput
    $sort: SearchableRestaurantSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchRestaurants(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        name
        address
        location {
          lat
          lon
        }
        timezone
        country
        rfc
        legalName
        fiscalRegime
        accountId
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        invoiceSerie
        invoiceFolio
        printer
        contactPhone
        contactEmail
        schedule
        businessType
        tipsEnabled
        cardPayments
        cashPayments
        oxxoPayments
        tpvPayments
        roomChargePayments
        paypalPayments
        paypalAccount
        hasPreordering
        hasDelivery
        hasDinein
        description
        descriptionMarkdown
        cardPicture
        deliveryPrice
        deliveryType
        minOrderAmount
        freeDeliveryThreshold
        bondEnabled
        bondTotal
        language
        prepaidMode
        isPrincipal
        surcharges
        disableAutoTranslate
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          status
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      total
    }
  }
`;

export const nearbyRestaurants = /* GraphQL */ `
  query NearbyRestaurants(
    $location: LocationInput!
    $m: Int
    $limit: Int
    $nextToken: String
  ) {
    nearbyRestaurants(
      location: $location
      m: $m
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        restaurantCompanyId
        name
        address
        location {
          lat
          lon
        }
        timezone
        country
        rfc
        legalName
        fiscalRegime
        accountId
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        invoiceSerie
        invoiceFolio
        invoiceAllowedDays
        printer
        contactPhone
        contactEmail
        schedule
        businessType
        tipsEnabled
        cardPayments
        cashPayments
        oxxoPayments
        tpvPayments
        roomChargePayments
        paypalPayments
        paypalAccount
        hasPreordering
        hasDelivery
        hasDinein
        description
        descriptionMarkdown
        cardPicture
        deliveryPrice
        deliveryType
        minOrderAmount
        freeDeliveryThreshold
        bondEnabled
        bondTotal
        bondBenefit
        bondDescription
        uberLink
        rappiLink
        sdLink
        paymentType
        language
        fireEnabled
        hasOnSite
        notificationTokens
        nativeNotificationTokens
        prepaidMode
        isPrincipal
        surcharges
        waiterFee
        disableAutoTranslate
        _deleted
        company {
          id
          name
          status
        }
      }
      total
      nextToken
    }
  }
`;

export const bySlug = /* GraphQL */ `
  query BySlug(
    $slug: String
    $sortDirection: ModelSortDirection
    $filter: ModelRestaurantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bySlug(
      slug: $slug
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        name
        address
        location {
          lat
          lon
        }
        timezone
        country
        rfc
        legalName
        fiscalRegime
        accountId
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        invoiceSerie
        invoiceFolio
        printer
        contactPhone
        contactEmail
        schedule
        businessType
        tipsEnabled
        cardPayments
        cashPayments
        oxxoPayments
        tpvPayments
        roomChargePayments
        paypalPayments
        paypalAccount
        hasPreordering
        hasDelivery
        hasDinein
        description
        descriptionMarkdown
        cardPicture
        deliveryPrice
        deliveryType
        minOrderAmount
        freeDeliveryThreshold
        bondEnabled
        bondTotal
        language
        prepaidMode
        isPrincipal
        surcharges
        primaryColor
        secondaryColor
        disableAutoTranslate
        _version
        _deleted
        _lastChangedAt
        company {
          id
          name
          status
          primaryColor
          secondaryColor
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;
export const companybySubdomain = /* GraphQL */ `
  query CompanybySubdomain(
    $subdomain: String
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companybySubdomain(
      subdomain: $subdomain
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        status
        logo
        cover
        primaryColor
        secondaryColor
        richDescription
        shortDescription
        gallery
        fbLink
        instaLink
        twLink
        ytLink
        contactEmail
        fbPixel
        fbMessenger
        ga
        subdomain
        businessType
        _version
        _deleted
        _lastChangedAt
        restaurants(limit: 1500, filter: { status: { ne: INACTIVE } }) {
          items {
            id
            slug
            name
            address
            location {
              lat
              lon
            }
            timezone
            country
            rfc
            legalName
            fiscalRegime
            accountId
            taxes
            taxesIncluded
            createdAt
            status
            currency
            subscriptionId
            picture
            invoiceSerie
            invoiceFolio
            printer
            contactPhone
            contactEmail
            schedule
            businessType
            tipsEnabled
            cardPayments
            cashPayments
            oxxoPayments
            tpvPayments
            roomChargePayments
            paypalPayments
            paypalAccount
            hasPreordering
            hasDelivery
            hasDinein
            description
            cardPicture
            deliveryPrice
            deliveryType
            minOrderAmount
            freeDeliveryThreshold
            bondEnabled
            bondTotal
            language
            prepaidMode
            isPrincipal
            surcharges
            disableAutoTranslate
            _version
            _deleted
            _lastChangedAt
          }
          nextToken
          startedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const subcategoriesbyCategory = /* GraphQL */ `
  query SubcategoriesbyCategory(
    $categoryID: ID
    $subcategoryID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCategorySubcategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    subcategoriesbyCategory(
      categoryID: $categoryID
      subcategoryID: $subcategoryID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        categoryID
        subcategoryID
        _version
        _deleted
        _lastChangedAt
        subcategory {
          id
          categoryRestaurantId
          title
          description
          picture
          position
          status
          type
          kitchenID
          printer
          sound
          serverID
          i18n
          view
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const getIdentity = /* GraphQL */ `
  query GetIdentity($id: ID!) {
    getIdentity(id: $id) {
      id
      notificationTokens
      _version
      _deleted
      _lastChangedAt
      createdAt
    }
  }
`;

export const getRestaurantData = /* GraphQL */ `
  query GetRestaurantData($id: ID!) {
    getRestaurant(id: $id) {
      id
      slug
      name
      address
      location {
        lat
        lon
      }
      timezone
      country
      taxes
      taxesIncluded
      accountId
      createdAt
      status
      currency
      subscriptionId
      picture
      contactPhone
      contactEmail
      schedule
      businessType
      tipsEnabled
      cardPayments
      cashPayments
      oxxoPayments
      tpvPayments
      roomChargePayments
      paypalPayments
      paypalAccount
      hasPreordering
      hasDelivery
      hasDinein
      description
      descriptionMarkdown
      cardPicture
      deliveryPrice
      deliveryType
      minOrderAmount
      freeDeliveryThreshold
      bondEnabled
      bondTotal
      bondBenefit
      bondDescription
      uberLink
      rappiLink
      sdLink
      paymentType
      hasOnSite
      language
      fireEnabled
      notificationTokens
      nativeNotificationTokens
      prepaidMode
      isPrincipal
      surcharges
      primaryColor
      secondaryColor
      tips
      disableAutoTranslate
      company {
        id
        name
        status
        primaryColor
        secondaryColor
        businessType
        principalRestaurantID
        _version
        _deleted
        _lastChangedAt
      }
      menus(filter: { status: { eq: ACTIVE } }) {
        items {
          id
          title
          description
          status
          picture
          schedule
          i18n
          view
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

export const categoriesbyRestaurant = /* GraphQL */ `
  query CategoriesbyRestaurant(
    $categoryRestaurantId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    categoriesbyRestaurant(
      categoryRestaurantId: $categoryRestaurantId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        categoryRestaurantId
        title
        description
        picture
        position
        status
        type
        kitchenID
        printer
        sound
        isSubcategory
        serverID
        i18n
        view
        _version
        _deleted
        _lastChangedAt
        createdAt
        restaurant {
          id
          slug
          restaurantCompanyId
          name
          address
          timezone
          country
          rfc
          legalName
          fiscalRegime
          accountId
          taxes
          taxesIncluded
          createdAt
          status
          currency
          subscriptionId
          picture
          invoiceSerie
          invoiceFolio
          invoiceAllowedDays
          printer
          contactPhone
          contactEmail
          schedule
          businessType
          tipsEnabled
          cardPayments
          cashPayments
          oxxoPayments
          tpvPayments
          roomChargePayments
          paypalPayments
          paypalAccount
          hasPreordering
          hasDelivery
          hasDinein
          description
          descriptionMarkdown
          cardPicture
          deliveryPrice
          deliveryType
          minOrderAmount
          freeDeliveryThreshold
          bondEnabled
          bondTotal
          bondBenefit
          bondDescription
          uberLink
          rappiLink
          sdLink
          paymentType
          language
          fireEnabled
          hasOnSite
          notificationTokens
          nativeNotificationTokens
          prepaidMode
          isPrincipal
          surcharges
          waiterFee
          disableAutoTranslate
          _version
          _deleted
          _lastChangedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const categoriesbyMenu = /* GraphQL */ `
  query CategoriesbyMenu(
    $menuID: ID
    $categoryID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCategoryMenuFilterInput
    $limit: Int
    $nextToken: String
  ) {
    categoriesbyMenu(
      menuID: $menuID
      categoryID: $categoryID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        categoryID
        menuID
        _version
        _deleted
        _lastChangedAt
        createdAt
        menu {
          id
          title
          description
          status
          picture
          schedule
          i18n
          view
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
        category {
          id
          categoryRestaurantId
          title
          description
          picture
          position
          status
          type
          kitchenID
          printer
          sound
          isSubcategory
          serverID
          view
          _version
          _deleted
          _lastChangedAt
          createdAt
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const restaurantbyCustomDomain = /* GraphQL */ `
  query RestaurantbyCustomDomain(
    $customDomain: String
    $sortDirection: ModelSortDirection
    $filter: ModelRestaurantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    restaurantbyCustomDomain(
      customDomain: $customDomain
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        restaurantCompanyId
        name
        address
        location {
          lat
          lon
        }
        timezone
        country
        rfc
        legalName
        fiscalRegime
        accountId
        taxes
        taxesIncluded
        createdAt
        status
        currency
        subscriptionId
        picture
        invoiceSerie
        invoiceFolio
        invoiceAllowedDays
        printer
        contactPhone
        contactEmail
        schedule
        businessType
        tipsEnabled
        cardPayments
        cashPayments
        oxxoPayments
        tpvPayments
        roomChargePayments
        paypalPayments
        paypalAccount
        hasPreordering
        hasDelivery
        hasDinein
        description
        descriptionMarkdown
        cardPicture
        deliveryPrice
        deliveryType
        minOrderAmount
        freeDeliveryThreshold
        bondEnabled
        bondTotal
        bondBenefit
        bondDescription
        uberLink
        rappiLink
        sdLink
        paymentType
        language
        fireEnabled
        hasOnSite
        notificationTokens
        nativeNotificationTokens
        prepaidMode
        isPrincipal
        surcharges
        waiterFee
        tips
        primaryColor
        secondaryColor
        customDomain
        disableAutoTranslate
        _version
        _deleted
        _lastChangedAt
        updatedAt
        company {
          id
          name
          status
          logo
          cover
          primaryColor
          secondaryColor
          richDescription
          shortDescription
          gallery
          fbLink
          instaLink
          twLink
          ytLink
          contactEmail
          fbPixel
          fbMessenger
          ga
          subdomain
          businessType
          principalRestaurantID
          _version
          _deleted
          _lastChangedAt
          createdAt
          updatedAt
        }
      }
      nextToken
      startedAt
    }
  }
`;

export const searchProducts = /* GraphQL */ `
  query SearchProducts(
    $filter: SearchableProductFilterInput
    $sort: SearchableProductSortInput
    $limit: Int
    $nextToken: String
  ) {
    searchProducts(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productRestaurantId
        title
        titleMarkdown
        description
        descriptionMarkdown
        picture
        createdAt
        position
        price
        status
        kitchenID
        kitchens
        compare
        minQuantity
        maxQuantity
        quantityField
        notesField
        schedule
        preorderingEnabled
        deliveryEnabled
        preorderingExtraAmount
        deliveryExtraAmount
        step
        brand
        provider
        productType
        time
        externalId
        travelData
        i18n
        linkedProducts
        _version
        _deleted
        restaurant {
          id
          slug
          restaurantCompanyId
          name
          address
          timezone
          country
          rfc
          legalName
          fiscalRegime
          accountId
          taxes
          taxesIncluded
          createdAt
          status
          currency
          subscriptionId
          picture
          invoiceSerie
          invoiceFolio
          invoiceAllowedDays
          printer
          contactPhone
          contactEmail
          schedule
          businessType
          tipsEnabled
          cardPayments
          cashPayments
          oxxoPayments
          tpvPayments
          paypalPayments
          paypalAccount
          hasPreordering
          hasDelivery
          hasDinein
          description
          descriptionMarkdown
          cardPicture
          deliveryPrice
          deliveryType
          minOrderAmount
          freeDeliveryThreshold
          bondEnabled
          bondTotal
          bondBenefit
          bondDescription
          uberLink
          rappiLink
          sdLink
          paymentType
          language
          fireEnabled
          hasOnSite
          notificationTokens
          nativeNotificationTokens
          prepaidMode
          isPrincipal
          surcharges
          waiterFee
          tips
          primaryColor
          secondaryColor
          customDomain
          disableAutoTranslate
          _version
          _deleted
        }
      }
      nextToken
      total
    }
  }
`;
