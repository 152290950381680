import { I18n } from "aws-amplify";
import moment from "moment";
import "moment/locale/es"; // without this line it didn't work

const language = window.navigator.userLanguage || window.navigator.language;
const dict = {
  es: {
    "Open 2dine on your smartphone": "Abre 2dine en tu celular",
    Login: "Ingresa",
    "Sign In/Register with your email, phone number, Facebook or Google":
      "Regístrate o inicia sesión con tu email, número de celular, Facebook o Google",
    "Search…": "Buscar…",
    "Search...": "Buscar...",
    "Search for something...": "Busca lo que quieras...",
    "What do you want today?": "¿Qué quieres pedir hoy?",
    Menus: "Menús",
    Categories: "Categorías",
    Cart: "Carrito",
    Order: "Orden",
    Home: "Inicio",
    "Choose a section": "Elige una sección",
    Orders: "Órdenes",
    Payments: "Pagos",
    Menu: "Menú",
    "How It Works": "Cómo funciona",
    About: "Nosotros",
    "Scan Table": "Escanea tu mesa",
    "Light mode": "Modo Luminoso",
    "Dark mode": "Modo Oscuro",
    "Sign In": "Iniciar Sesión",
    "Pending Items": "Productos pendientes",
    "No pending items in your cart":
      "No hay productos pendientes en tu carrito",
    "Add items to your order": "Agrega productos a tu orden",
    "Confirm & send your order": "Confirma y envía tu orden",
    "Confirm & send to order": "Confirma y envía tu selección",
    "Ordered Items": "Productos en tu orden",
    Serie: "Serie",
    Subtotal: "Subtotal",
    Taxes: "Impuestos",
    Total: "Total",
    "Checkout & pay": "Pagar",
    "No items in": "No hay productos en",
    "Select a menu": "Selecciona un menú",
    "Choose the menu you want to see": "Elige el menú que quieres ver",
    "Complete menu": "Menú completo",
    Date: "Fecha",
    Status: "Estatus",
    NEW: "Nuevo",
    DELETED: "Eliminado",
    CLOSED: "Cerrado",
    OPEN: "Abierto",
    Amount: "Importe",
    Card: "Tarjeta",
    Back: "Atrás",
    Next: "Siguiente",
    "Scan or type your table token": "Escanea el código de tu mesa",
    "Select your products": "Selecciona tus productos",
    "Confirm & send to your order": "Confirma y envía tu orden",
    "Add & send more products anytime":
      "Envía más productos en cualquier momento",
    "Enjoy your order!": "¡Disfruta tu orden!",
    Terms: "Términos",
    "Privacy policy": "Privacidad",
    Version: "Versión",
    "Developed by": "Desarrollado por",
    "Scan QR Code": "Escanear código QR",
    "Open or join to an order easy & fast":
      "Inicia o únete a una orden fácil y rápido",
    "Table token": "Código de tu mesa",
    "Add the table uid": "Agrega el uid de tu mesa",
    "Create or join to order": "Crear o unirse a una orden",
    "Products sent to kitchen successfully!":
      "¡Productos enviados a cocina correctamente!",
    "Divide check": "Dividir cuenta",
    Checkout: "Checkout",
    "Order Total": "Total de la orden",
    "Remaining Amount": "Pendiente de pago",
    "No divide": "No dividir",
    Divide: "Dividir",
    "by guest": "por usuario",
    other: "otro",
    "Custom Amount": "Ingresar cantidad",
    "Table guests": "Comensales",
    "Divide by guest": "Dividir por usuario",
    "Select guests": "Selecionar cantidad",
    Table: "Mesa",
    "Select payment method": "Selecciona método de pago",
    "Pay with": "Pagar con",
    Cash: "Efectivo",
    "Amount to pay": "Cantidad a pagar",
    Tip: "Propina",
    Custom: "Otro",
    "No tip": "Sin propina",
    Pay: "Pagar",
    "No modifiers selected": "No seleccionaste modificadores",
    Settings: "Preferencias",
    "Order Detail": "Detalle de la orden",
    Created: "Creación",
    Closed: "Cierre",
    Items: "Productos",
    Qty: "Cantidad",
    Product: "Producto",
    Guest: "Comensal",
    "All steps completed": "Proceso completado",
    Confirmation: "Confirmación",
    Guests: "Visitantes",
    "Custom amount": "Otra cantidad",
    "Payment Method": "Método de pago",
    Category: "Categoría",
    "No restaurant found, ask for help.":
      "Restaurante no encontrado, pide ayuda!",
    "Open your QR scanner from the browser bar":
      "Abre tu cámara desde la barra de búsqueda para escanear el código QR",
    "Re-order": "Reordenar",
    "Order item status": "Estatus del producto",
    Quantity: "Cantidad",
    "Report feedback": "Reportar error",
    "Are you sure you want to delete the item?":
      "¿Estás seguro de eliminar el producto?",
    Confirm: "Confirmar",
    Cancel: "Cancelar",
    "Update name": "Modificar nombre",
    "Add your name": "Agrega tu nombre",
    Actions: "Acciones",
    "Missing some required modifiers":
      "Falta seleccionar modificadores requeridos",
    "Item added successfully!": "¡Producto agregado correctamente!",
    "Item updated successfully!": "¡Producto actualizado correctamente!",
    "Item deleted successfully!": "¡Producto eliminado correctamente!",
    "Additional Indications": "Indicaciones adicionales",
    "Add special indications": "Agrega indicaciones especiales",
    "Add to cart": "Agregar al carrito",
    Update: "Actualizar",
    Optional: "Opcional",
    "Two selections are required": "Dos selecciones son requeridas",
    "Three selections are required": "Tres selecciones son requeridas",
    "Select an option": "Selecciona una opción",
    "Payment Succeeds": "Pago exitoso",
    Continue: "Continuar",
    "Checking payment data": "Verificando pago",
    "No ordered items in your cart": "No hay productos ordenados",
    "Sign Out": "Cerrar Sesión",
    "Pending Payment": "Pago pendiente",
    "Go to the cash or wait for your server":
      "Paga en caja o espera a tu mesero",
    "Make another payment": "Realizar otro pago",
    "Payment Cancelled": "Pago cancelado",
    "Error processing your payment, try again!":
      "¡Error al procesar tu pago, intenta nuevamente!",
    "Order Item": "Producto",
    Help: "Ayuda",
    "Are you sure you want to delete?":
      "¿Estás seguro de eliminar el registro?",
    "Set as principal RFC": "Seleccionar como RFC principal",
    Theme: "Tema",
    "Ok, your waiter is comming! Wait a minute please.":
      "¡Bien, tu mesero ya viene! Espera un minuto por favor.",
    Invoicing: "Facturación",
    "Find or generate your invoice": "Genera o recupera tu factura",
    Finish: "Terminar",
    Reset: "Reinicar",
    "Download invoice": "Descargar factura",
    "Login to retrieve or generate your invoices":
      "Inicia sesión para recuperar o generar tus facturas",
    "Add your payment ID": "Agrega el ID del pago",
    "Select or add your RFC": "Selecciona o agrega tu RFC",
    "Invoice data": "Datos de la factura",
    "New RFC": "Nuevo RFC",
    "Call your server": "Llamar al mesero",
    "Ask for your server": "Pide a tu mesero",
    "Cash payment": "Pago en efectivo",
    "Ask for cash payment of your order":
      "Solicita realizar tu pago en efectivo",
    FAQs: "Preguntas frecuentes",
    "Find an answer to your questions": "Encuentra respuesta a tus dudas",
    Download: "Descargar",
    "Your invoice was sent by email":
      "Tu factura fue enviada por correo electrónico",
    "Invoice generated, download here": "Factura generada, descarga aquí",
    "Add RFC": "Agregar RFC",
    save: "guardar",
    "Generate & Download Invoice": "Generar y descargar factura",
    cash: "efectivo",
    Place: "Origen",
    promo: "en promo",
    "No connection! Trying to reconnect.":
      "¡Sin conexión! Tratando de reconectarse.",
    Modifiers: "Modificadores",
    Catalogs: "Catálogos",
    Terminal: "Terminal",
    Catalog: "Catálogo",
    "Scan or type your QR Code": "",
    "QR Code": "Código QR",
    "Add the code UID": "Agrega el ID del código",
    Location: "Ubicación",
    "Select a catalog": "Selecciona un catálogo",
    "Choose the catalog you want to see": "Elige el catálogo que quieres ver",
    "All catalogs": "Todos los catálogos",
    "No hotel code found, ask for help.":
      "Código de hotel no encontrado, pide ayuda o intenta más tarde",
    "No code found, ask for help.":
      "Código no encontrado, pide ayuda o intenta más tarde",
    Nights: "Noches",
    Concierge: "Conserje",
    "Call to concierge": "Llamar a recepción",
    "Calling waiter": "Esta llamando al mesero",
    "Calling concierge": "Esta llamando a recepción",
    "No location found, ask for help.":
      "Ubicación no encontrada, pide ayuda o intenta más tarde.",
    "Wants to pay with cash": "Quiere pagar en efectivo",
    "Consultation after hours": "Consulta fuera de horario",
    Restaurants: "Restaurantes",
    Dashboard: "Tablero",
    Payment: "Pago",
    "Confirm order": "Confirmar pedido",
    "Delivery options": "Opciones de entrega",
    "Your order": "Tu orden",
    Notes: "Notas",
    "Credit or debit card": "Tarjeta crédito o débito",
    "Order Type": "Tipo de pedido",
    Delivery: "A domicilio",
    "Delivery Address": "Dirección de entrega",
    "Collection Address": "Dirección de recolección",
    "To Go": "Para llevar",
    Localization: "Ubicación",
    "Delivery address": "Dirección de entrega",
    Addresses: "Direcciones",
    "Search your address": "Busca tu dirección",
    "Apartment/Office/Floor": "Depto/Oficina/Piso",
    Save: "Guardar",
    "Are you sure to delete the address?":
      "¿Estás seguro de eliminar la dirección?",
    "Address deleted successfully!": "¡Dirección eliminada correctamente!",
    "Address created successfully!": "¡Dirección creada correctamente!",
    "Order created successfully!": "¡Orden creada correctamente!",
    "Add your address": "Agrega tu dirección",
    "To retrieve the nearest restaurants":
      "Para recuperar los restaurantes más cercanos",
    "Add address": "Agregar dirección",
    "Order accepted by the restaurant!": "¡Pedido aceptado!",
    "Your order is preparing!": "¡Se esta preparando tu pedido!",
    "Your order is comming to you!": "¡Tu pedido va en camino!",
    "Your order has been delivered": "¡Tu pedido ha sido entregado!",
    "You have an active order": "Tienes un pedido activo",
    ACCEPTED: "Aceptado",
    PREPARING: "En preparción",
    SENT: "Enviado",
    DELIVERED: "Entregado",
    CANCELLED: "Cancelado",
    "Dine In": "Comer Aquí",
    Bond: "Bono Gastronómico",
    "Delivery Amount": "Entrega",
    Free: "Gratis",
    "Amount paid": "Importe pagado",
    Monday: "Lunes",
    Tuesday: "Martes",
    Wednesday: "Miércoles",
    Thursday: "Jueves",
    Friday: "Viernes",
    Saturday: "Sábado",
    Sunday: "Domingo",
    "Pay with OXXO": "Pagar con OXXO",
    "Add your email": "Ingresa tu correo electrónico",
    "Your name": "Tu nombre",
    "Your last name": "Tu apellido",
    "Add your last name": "Ingresa tu apellido",
    "Scan QR": "Scan QR",
    "Register my commerce": "Registrar mi comercio",
    "My profile": "Mi perfil",
    "No commerce found, ask for help.": "¡No se encontro el comercio!",
    "Select or add your delivery address":
      "Selecciona o agrega tu dirección de entrega",
    "Add Tip": "Agregar propina",
    "No Charge": "Sin cargo",
    "See more": "Ver todo",
    "See menu": "Ver menú",
    Hours: "Horario de operación",
    Contact: "Contacto",
    Address: "Dirección",
    Enter: "Entrar",
    Others: "Otros",
    Paid: "Pagado",
    "Pending amount": "Pendiente de pago",
    "Share your experience": "Compártenos tu experiencia",
    "Rate this place": "Califica este lugar",
    Service: "Servicio",
    "Product & Place": "Producto y Lugar",
    "Thanks for your response": "Gracias por tu respuesta",
    Remove: "Eliminar",
    "Order reviewed, thanks!": "Ya recibimos tus comentarios, gracias!",
    "Confirm your selection to send to kitchen":
      "Confirmar envío a cocina de los productos seleccionados",
    "Clear cart": "Limpiar carrito",
    "Physical TPV": "TPV Pago con tarjeta",
    "Answer satisfaction survey.": "Responder encuesta de satisfacción",
    Answer: "Responder",
    "Load more...": "Cargar más...",
    "View more": "Ver más",
    Discount: "Descuento",
    "Let us know the evaluation of your experience with us.":
      "Déjanos conocer la evaluación de tu experiencia con nosotros.",
    Close: "Cerrar",
    "A new version is available!": "¡Tenemos una nueva versión!",
    "Our new version improves your experience.":
      "Nuestra nueva versión mejora tu experiencia",
    "We will ask for the ones on your card after you confirm your order.":
      "Los datos de tu tarjeta los pediremos después de que confirmes tu pedido.",
    "Room Charge": "Cargo a habitación",
    or: "o",
    "You need to select": "Te falta seleccionar",
    "Add your name, email, phone & accept terms & conditions to enable paypal payment.":
      "Agrega tu nombre, teléfono, correo y acepta los términos para habilitar el pago con Paypal",
    "Add your name & phone to enable paypal payment.":
      "Agrega tu nombre y teléfono para habilitar el pago con PayPal",
    "Complete your payment with PayPal": "Completa tu pago con PayPal",
  },
  fr: {
    "Open 2dine on your smartphone": "Ouvrez 2dine sur votre smartphone",
    Login: "S'identifier",
    "Sign In": "Se connecter",
    "Search…": "Chercher…",
    "Search...": "Chercher…",
    "Search for something...": "Rechercher quelque chose ...",
    "What do you want today?": "Que veux-tu aujourd'hui?",
    Menus: "Les menus",
    Categories: "Les catégories",
    Cart: "Chariot",
    Order: "Ordre",
    Home: "Accueil",
    "Choose a section": "Choisissez une section",
    Orders: "Ordres",
    Payments: "Paiements",
    Menu: "Menu",
    "How It Works": "Comment ça fonctionne",
    About: "À propos de",
    "Scan Table": "Table de numérisation",
    "Light mode": "Mode lumière",
    "Dark mode": "Mode sombre",
    "Pending Items": "Éléments en attente",
    "No pending items in your cart":
      "Aucun article en attente dans votre panier",
    "Add items to your order": "Ajoutez des articles à votre commande",
    "Confirm & send your order": "Confirmer et envoyer votre commande",
    "Confirm & send to order": "Confirmer et envoyer à la commande",
    "Ordered Items": "Articles commandés",
    Serie: "Serie",
    Subtotal: "Total",
    Taxes: "Impôt",
    Total: "Total",
    "Checkout & pay": "Commander et payer",
    "No items in": "Aucun élément dans",
    "Select a menu": "Sélectionnez un menu",
    "Choose the menu you want to see":
      "Choisissez le menu que vous souhaitez voir",
    "Complete menu": "Menu complet",
    Date: "Date",
    Status: "Statut",
    NEW: "NOUVEAU",
    DELETED: "SUPPRIMÉ",
    CLOSED: "FERMÉ",
    OPEN: "OUVERT",
    Amount: "Montant",
    Card: "Carte",
    Back: "Retour",
    Next: "Prochain",
    "Scan or type your table token": "Scannez ou tapez votre jeton de table",
    "Select your products": "Sélectionnez vos produits",
    "Confirm & send to your order": "Confirmez et envoyez à votre commande",
    "Add & send more products anytime":
      "Ajoutez et envoyez plus de produits à tout moment",
    "Enjoy your order!": "Bon appétit!",
    Terms: "termes",
    "Privacy policy": "Politique de confidentialité",
    Version: "Version",
    "Developed by": "Développé par",
    "Scan QR Code": "Scannez le code QR",
    "Open or join to an order easy & fast":
      "Ouvrir ou joindre à une commande facile et rapide",
    "Table token": "Tableau jeton",
    "Add the table uid": "Ajouter le uid de table",
    "Create or join to order": "Créer ou rejoindre à l'ordre",
    "Products sent to kitchen successfully!":
      "Produits envoyés à la cuisine avec succès!",
    "Divide check": "diviser vérification",
    Checkout: "Check-out",
    "Order Total": "Total de la commande",
    "Remaining Amount": "Montant restant",
    "No divide": "pas de fracture",
    Divide: "Diviser",
    "by guest": "par des clients",
    other: "autre",
    "Custom Amount": "Montant personnalisé",
    "Table guests": "table invités",
    "Divide by guest": "Diviser par des clients",
    "Select guests": "Sélectionnez les clients",
    Table: "Table",
    "Select payment method": "Sélectionnez le mode de paiement",
    "Pay with": "Payer avec",
    Cash: "En espèces",
    "Amount to pay": "Montant à payer",
    Tip: "Pointe",
    Custom: "Douane",
    "No tip": "Aucun conseil",
    Pay: "Payer",
    "No modifiers selected": "Aucun modificateur sélectionné",
    Settings: "Réglages",
    "Order Detail": "Détails de la commande",
    Created: "Créé",
    Closed: "Fermé",
    Items: "Articles",
    Qty: "Quantité",
    Product: "Produit",
    Guest: "Client",
    "All steps completed": "Toutes les étapes franchies",
    Confirmation: "Confirmation",
    Guests: "Invités",
    "Custom amount": "Montant de la commande",
    "Payment Method": "Mode de paiement",
    Category: "Catégorie",
    "No restaurant found, ask for help.":
      "Pas de restaurant trouvé, demander de l'aide.",
    "Open your QR scanner from the browser bar":
      "Ouvrez votre scanner QR dans la barre de navigateur",
    "Re-order": "Réorganiser",
    "Order item status": "état de l'élément de commande",
    Quantity: "Quantité",
    "Report feedback": "commentaires rapport",
    "Are you sure you want to delete the item?":
      "Etes-vous sûr de vouloir supprimer l'article?",
    Confirm: "Confirmer",
    Cancel: "Annuler",
    "Update name": "Nom de mise à jour",
    "Add your name": "Ajoutez votre nom",
    Actions: "Actions",
    "Missing some required modifiers": "Manque certains modificateurs requis",
    "Item added successfully!": "Article ajouté avec succès!",
    "Item updated successfully!": "Article mis à jour avec succès!",
    "Item deleted successfully!": "Article supprimé avec succès!",
    "Additional Indications": "Indications supplémentaires",
    "Add special indications": "Ajouter des indications spéciales",
    "Add to cart": "Ajouter au chariot",
    Update: "Mise à jour",
    Optional: "Optionnel",
    "Two selections are required": "Deux sélections sont nécessaires",
    "Three selections are required": "Trois sélections sont nécessaires",
    "Select an option": "Sélectionnez une option",
    "Payment Succeeds": "paiement réussit",
    Continue: "Continuer",
    "Checking payment data": "Vérification des données de paiement",
    "No ordered items in your cart":
      "Aucun articles commandés dans votre panier",
    "Sign Out": "Déconnexion",
    "Pending Payment": "En attente de paiement",
    "Go to the cash or wait for your server":
      "Aller à la caisse ou attendez que votre serveur",
    "Make another payment": "Faire un autre paiement",
    "Payment Cancelled": "paiement annulé",
    "Error processing your payment, try again!":
      "Erreur lors du traitement de votre paiement, essayez à nouveau!",
    "Order Item": "Point de commande",
    Help: "Aidez-moi",
    "Are you sure you want to delete?":
      "Etes-vous sûr que vous voulez supprimer?",
    "Set as principal RFC": "Définir comme RFC principale",
    Theme: "Thème",
    "Ok, your waiter is comming! Wait a minute please.":
      "Ok, votre serveur est comming! Attends une minute s'il te plait.",
    Invoicing: "facturation",
    "Find or generate your invoice": "Trouvez ou générer votre facture",
    Finish: "terminer",
    Reset: "Réinitialiser",
    "Download invoice": "facture Télécharger",
    "Login to retrieve or generate your invoices":
      "Connectez-vous pour récupérer ou générer vos factures",
    "Add your payment ID": "Ajoutez votre ID de paiement",
    "Select or add your RFC": "Sélectionnez ou ajoutez votre RFC",
    "Invoice data": "Données de facturation",
    "New RFC": "nouveau RFC",
    "Call your server": "Appelez votre serveur",
    "Ask for your server": "Demandez votre serveur",
    "Cash payment": "Paiement en espèces",
    "Ask for cash payment of your order":
      "Demandez un paiement en espèces de votre commande",
    FAQs: "FAQ",
    "Find an answer to your questions": "Trouver une réponse à vos questions",
    Download: "Télécharger",
    "Your invoice was sent by email": "Votre facture a été envoyée par e-mail",
    "Invoice generated, download here": "Facture générée, à télécharger ici",
    "Add RFC": "Ajouter RFC",
    save: "enregistrer",
    "Generate & Download Invoice": "Générer et Télécharger la facture",
    cash: "en espèces",
    Place: "Endroit",
    promo: "promo",
    "No connection! Trying to reconnect.":
      "Pas de connection! Essayer de se reconnecter.",
    Modifiers: "modificateurs",
    Catalogs: "Catalogues",
    Terminal: "Terminal",
    Catalog: "Catalogue",
    "Scan or type your QR Code": "Numérisez ou tapez votre code QR",
    "QR Code": "QR Code",
    "Add the code UID": "Ajoutez le code UID",
    Location: "Emplacement",
    "Select a catalog": "Sélectionnez un catalogue",
    "Choose the catalog you want to see":
      "Choisissez le catalogue que vous voulez voir",
    "All catalogs": "tous les catalogues",
    "No hotel code found, ask for help.":
      "Aucun code d'hôtel trouvé, demander de l'aide.",
    "No code found, ask for help.": "Aucun code trouvé, demander de l'aide.",
    Nights: "Nuits",
    Concierge: "La conciergerie",
    "Call to concierge": "Appel à concierge",
    "Calling waiter": "Appel serveur",
    "Calling concierge": "concierge Appel",
    "No location found, ask for help.":
      "Aucun emplacement trouvé, demander de l'aide.",
    "Wants to pay with cash": "Veut payer en espèces",
    "Consultation after hours": "Consultation après les heures",
    Restaurants: "Restaurants",
    Dashboard: "Tableau de bord",
    Payment: "Paiement",
    "Confirm order": "Confirmer la commande",
    "Delivery options": "Options de livraison",
    "Your order": "Votre commande",
    Notes: "Remarques",
    "Credit or debit card": "carte de crédit ou de débit",
    "Order Type": "type de commande",
    Delivery: "Livraison",
    "Delivery Address": "Adresse de livraison",
    "Collection Address": "Adresse collection",
    "To Go": "Aller",
    Localization: "Localisation",
    "Delivery address": "Adresse de livraison",
    Addresses: "adresses",
    "Search your address": "Rechercher votre adresse",
    "Apartment/Office/Floor": "Appartement / Bureau / étage",
    Save: "sauver",
    "Are you sure to delete the address?":
      "Etes-vous sûr de supprimer l'adresse?",
    "Address deleted successfully!": "Adresse supprimé avec succès!",
    "Address created successfully!": "Adresse créé avec succès!",
    "Order created successfully!": "Ordre créé avec succès!",
    "Add your address": "Ajoutez votre adresse",
    "To retrieve the nearest restaurants":
      "Pour récupérer les restaurants les plus proches",
    "Add address": "Ajoutez l'adresse",
    "Order accepted by the restaurant!": "Commande acceptée par le restaurant!",
    "Your order is preparing!": "Votre commande prépare!",
    "Your order is comming to you!": "Votre commande est comming pour vous!",
    "Your order has been delivered": "Votre commande a été livrée",
    "You have an active order": "Vous avez un ordre actif",
    ACCEPTED: "ACCEPTÉ",
    PREPARING: "EN TRAIN DE PRÉPARER",
    SENT: "ENVOYÉ",
    DELIVERED: "LIVRÉ",
    CANCELLED: "ANNULÉ",
    "Dine In": "Dîner dans",
    Bond: "Liaison",
    "Delivery Amount": "Montant de livraison",
    Free: "Gratuit",
    "Amount paid": "Le montant payé",
    Monday: "Lundi",
    Tuesday: "Mardi",
    Wednesday: "Mercredi",
    Thursday: "Jeudi",
    Friday: "Vendredi",
    Saturday: "samedi",
    Sunday: "dimanche",
    "Pay with OXXO": "Payez avec OXXO",
    "Add your email": "Ajouter votre e-mail",
    "Your name": "Votre nom",
    "Your last name": "Votre nom de famille",
    "Add your last name": "Ajouter votre nom de famille",
    "Scan QR": "QR balayage",
    "Register my commerce": "Enregistrer mon commerce",
    "My profile": "Mon profil",
    "No commerce found, ask for help.":
      "Pas de commerce trouvé, demander de l'aide.",
    "Select or add your delivery address":
      "Sélectionnez ou ajoutez votre adresse de livraison",
    "Add Tip": "Ajouter Astuce",
    "No Charge": "Gratuit",
  },
  zh: {
    "Open 2dine on your smartphone": "在智能手机上打开2dine",
    Login: "登录",
    "Sign In": "登入",
    "Search…": "搜索…",
    "Search...": "搜索...",
    "Search for something...": "搜索的东西...",
    "What do you want today?": "今天你想干什么？",
    Menus: "菜单",
    Categories: "分类",
    Cart: "大车",
    Order: "订购",
    Home: "家",
    "Choose a section": "选择部分",
    Orders: "命令",
    Payments: "支付",
    Menu: "菜单",
    "How It Works": "怎么运行的",
    About: "关于",
    "Scan Table": "扫描表",
    "Light mode": "光模式",
    "Dark mode": "黑暗模式",
    "Pending Items": "待批项目",
    "No pending items in your cart": "购物车中无未达账项",
    "Add items to your order": "将项目添加到您的订单",
    "Confirm & send your order": "确认并发送订单",
    "Confirm & send to order": "确认并发送至顺序",
    "Ordered Items": "订购项目",
    Serie: "意甲",
    Subtotal: "小计",
    Taxes: "税",
    Total: "总",
    "Checkout & pay": "结帐付款功能",
    "No items in": "在没有项目",
    "Select a menu": "选择菜单",
    "Choose the menu you want to see": "选择您想看到的菜单",
    "Complete menu": "完整菜单",
    Date: "日期",
    Status: "状态",
    NEW: "新",
    DELETED: "DELETED",
    CLOSED: "关闭",
    OPEN: "打开",
    Amount: "量",
    Card: "卡",
    Back: "背部",
    Next: "下一个",
    "Scan or type your table token": "扫描或输入你的表令牌",
    "Select your products": "选择你的产品",
    "Confirm & send to your order": "确认并发送到您的订单",
    "Add & send more products anytime": "添加和发送更多的产品随时",
    "Enjoy your order!": "享受您的订单！",
    Terms: "条款",
    "Privacy policy": "隐私政策",
    Version: "版",
    "Developed by": "由开发",
    "Scan QR Code": "扫描二维码",
    "Open or join to an order easy & fast": "打开或加入到订单中很容易和快速",
    "Table token": "表令牌",
    "Add the table uid": "添加表UID",
    "Create or join to order": "创建或加入到订单",
    "Products sent to kitchen successfully!": "产品送到厨房成功！",
    "Divide check": "除法检查",
    Checkout: "查看",
    "Order Total": "合计订单",
    "Remaining Amount": "剩余数量",
    "No divide": "没有鸿沟",
    Divide: "划分",
    "by guest": "嘉宾",
    other: "其他",
    "Custom Amount": "自定义金额",
    "Table guests": "一桌客人",
    "Divide by guest": "除以客人",
    "Select guests": "选择客人",
    Table: "表",
    "Select payment method": "选择支付方式",
    "Pay with": "使用。。。支付",
    Cash: "现金",
    "Amount to pay": "支付数量",
    Tip: "小费",
    Custom: "习惯",
    "No tip": "没有提示",
    Pay: "工资",
    "No modifiers selected": "未选择修饰符",
    Settings: "设置",
    "Order Detail": "订单详情",
    Created: "创建",
    Closed: "关闭",
    Items: "项目",
    Qty: "数量",
    Product: "产品",
    Guest: "来宾",
    "All steps completed": "完成所有步骤",
    Confirmation: "确认",
    Guests: "宾客",
    "Custom amount": "定制量",
    "Payment Method": "付款方法",
    Category: "类别",
    "No restaurant found, ask for help.": "没有餐厅发现，寻求帮助。",
    "Open your QR scanner from the browser bar": "从浏览器栏打开你的QR扫描",
    "Re-order": "重新排序",
    "Order item status": "订单项状态",
    Quantity: "数量",
    "Report feedback": "报告反馈",
    "Are you sure you want to delete the item?": "你确定要删除项目吗？",
    Confirm: "确认",
    Cancel: "取消",
    "Update name": "更新名称",
    "Add your name": "加入你的名字",
    Actions: "操作",
    "Missing some required modifiers": "缺少一些需要的改性剂",
    "Item added successfully!": "项目成功添加！",
    "Item updated successfully!": "项目成功更新！",
    "Item deleted successfully!": "项目成功删除！",
    "Additional Indications": "其他适应症",
    "Add special indications": "添加特殊适应症",
    "Add to cart": "添加到购物车",
    Update: "更新",
    Optional: "可选的",
    "Two selections are required": "两个选择都需要",
    "Three selections are required": "三个选项是必需的",
    "Select an option": "选择一个选项",
    "Payment Succeeds": "付款则成功",
    Continue: "继续",
    "Checking payment data": "检查付款数据",
    "No ordered items in your cart": "您的购物车没有订购的商品",
    "Sign Out": "登出",
    "Pending Payment": "待付款",
    "Go to the cash or wait for your server": "转至现金或等待您的服务器",
    "Make another payment": "再次付款",
    "Payment Cancelled": "付款已取消",
    "Error processing your payment, try again!": "错误处理您的付款，请重试！",
    "Order Item": "订单项目",
    Help: "帮帮我",
    "Are you sure you want to delete?": "你确定你要删除？",
    "Set as principal RFC": "设为主要RFC",
    Theme: "主题",
    "Ok, your waiter is comming! Wait a minute please.":
      "好吧，你是服务员正在添加！请等一下。",
    Invoicing: "进销存",
    "Find or generate your invoice": "查找或生成发票",
    Finish: "完",
    Reset: "重启",
    "Download invoice": "下载发票",
    "Login to retrieve or generate your invoices": "登录检索或生成发票",
    "Add your payment ID": "添加您的付款ID",
    "Select or add your RFC": "选择或添加您的RFC",
    "Invoice data": "发票数据",
    "New RFC": "新的RFC",
    "Call your server": "打电话给你的服务器",
    "Ask for your server": "请为您的服务器",
    "Cash payment": "现金支付",
    "Ask for cash payment of your order": "要求现金支付您的订单",
    FAQs: "常见问题解答",
    "Find an answer to your questions": "找到答案你的问题",
    Download: "下载",
    "Your invoice was sent by email": "您的发票通过电子邮件发送",
    "Invoice generated, download here": "这里发票生成，下载",
    "Add RFC": "添加RFC",
    save: "救",
    "Generate & Download Invoice": "生成并下载发票",
    cash: "现金",
    Place: "地点",
    promo: "促销",
    "No connection! Trying to reconnect.": "无连接！尝试重新连接。",
    Modifiers: "修饰符",
    Catalogs: "产品目录",
    Terminal: "终奌站",
    Catalog: "目录",
    "Scan or type your QR Code": "扫描或输入您的QR码",
    "QR Code": "二维码",
    "Add the code UID": "添加代码UID",
    Location: "位置",
    "Select a catalog": "选择目录",
    "Choose the catalog you want to see": "选择您想看到的目录",
    "All catalogs": "所有目录",
    "No hotel code found, ask for help.": "没有酒店代码中发现，寻求帮助。",
    "No code found, ask for help.": "没有代码中发现，寻求帮助。",
    Nights: "夜",
    Concierge: "门房",
    "Call to concierge": "调用礼宾",
    "Calling waiter": "呼叫服务员",
    "Calling concierge": "调用礼宾",
    "No location found, ask for help.": "没有位置找到，请求帮助。",
    "Wants to pay with cash": "想用现金支付",
    "Consultation after hours": "咨询小时后",
    Restaurants: "餐馆",
    Dashboard: "仪表板",
    Payment: "付款",
    "Confirm order": "确认订单",
    "Delivery options": "交付选项",
    "Your order": "你的订单",
    Notes: "笔记",
    "Credit or debit card": "信用卡或借记卡",
    "Order Type": "订单类型",
    Delivery: "交货",
    "Delivery Address": "邮寄地址",
    "Collection Address": "收藏地址",
    "To Go": "去",
    Localization: "本土化",
    "Delivery address": "邮寄地址",
    Addresses: "地址",
    "Search your address": "搜索您的地址",
    "Apartment/Office/Floor": "公寓/办公室/楼",
    Save: "救",
    "Are you sure to delete the address?": "您确定要删除的地址？",
    "Address deleted successfully!": "地址已被删除！",
    "Address created successfully!": "地址创建成功！",
    "Order created successfully!": "为了创建成功！",
    "Add your address": "添加您的地址",
    "To retrieve the nearest restaurants": "要检索最近的餐馆",
    "Add address": "添加地址",
    "Order accepted by the restaurant!": "为了接受了餐厅！",
    "Your order is preparing!": "您的订单准备！",
    "Your order is comming to you!": "您的订单正在添加到您！",
    "Your order has been delivered": "您的订单已交付",
    "You have an active order": "你有一个活跃的顺序",
    ACCEPTED: "公认",
    PREPARING: "制备",
    SENT: "SENT",
    DELIVERED: "DELIVERED",
    CANCELLED: "取消",
    "Dine In": "在用餐",
    Bond: "键",
    "Delivery Amount": "交付金额",
    Free: "自由",
    "Amount paid": "支付的金额",
    Monday: "星期一",
    Tuesday: "星期二",
    Wednesday: "星期三",
    Thursday: "星期四",
    Friday: "星期五",
    Saturday: "星期六",
    Sunday: "星期日",
    "Pay with OXXO": "与OXXO收费",
    "Add your email": "添加您的电子邮件",
    "Your name": "你的名字",
    "Your last name": "你的姓氏",
    "Add your last name": "添加您的姓氏",
    "Scan QR": "扫描QR",
    "Register my commerce": "注册我的电子商务",
    "My profile": "我的简历",
    "No commerce found, ask for help.": "没有商业发现，寻求帮助。",
    "Select or add your delivery address": "选择或添加您的收货地址",
    "Add Tip": "添加提示",
    "No Charge": "免费",
  },
  de: {
    "Open 2dine on your smartphone": "Öffnen 2dine auf Ihrem Smartphone",
    Login: "Anmeldung",
    "Sign In": "Anmelden",
    "Search…": "Suche…",
    "Search...": "Suche...",
    "Search for something...": "Suche nach etwas ...",
    "What do you want today?": "Was willst du heute?",
    Menus: "Menüs",
    Categories: "Kategorien",
    Cart: "Wagen",
    Order: "Auftrag",
    Home: "Zuhause",
    "Choose a section": "Wählen Sie einen Abschnitt",
    Orders: "Aufträge",
    Payments: "Zahlungen",
    Menu: "Speisekarte",
    "How It Works": "Wie es funktioniert",
    About: "Über",
    "Scan Table": "Scan Tabelle",
    "Light mode": "Light-Modus",
    "Dark mode": "dunkel-Modus",
    "Pending Items": "Ausstehende Sachen",
    "No pending items in your cart": "Keine ausstehenden Artikel im Warenkorb",
    "Add items to your order": "Fügen Sie Artikel zu Ihrer Bestellung",
    "Confirm & send your order": "Bestätigen & senden Sie Ihre Bestellung",
    "Confirm & send to order": "Bestätigen & senden, um zu bestellen",
    "Ordered Items": "bestellte Artikel",
    Serie: "Serie",
    Subtotal: "Zwischensumme",
    Taxes: "Steuern",
    Total: "Gesamt",
    "Checkout & pay": "Kassen- & Bezahlung",
    "No items in": "Keine Artikel in",
    "Select a menu": "Wählen Sie ein Menü",
    "Choose the menu you want to see": "Wählen Sie das Menü Sie sehen wollen",
    "Complete menu": "komplettes Menü",
    Date: "Datum",
    Status: "Status",
    NEW: "NEU",
    DELETED: "DELETED",
    CLOSED: "GESCHLOSSEN",
    OPEN: "ÖFFNEN",
    Amount: "Menge",
    Card: "Karte",
    Back: "Zurück",
    Next: "Nächster",
    "Scan or type your table token": "Scannen oder geben Sie Ihren Tisch Token",
    "Select your products": "Wählen Sie Ihre Produkte",
    "Confirm & send to your order": "Bestätigen & send zu Ihrer Bestellung",
    "Add & send more products anytime":
      "In und weiteren Produkten zu jeder Zeit senden",
    "Enjoy your order!": "Genießen Sie Ihre Bestellung!",
    Terms: "Bedingungen",
    "Privacy policy": "Datenschutz-Bestimmungen",
    Version: "Ausführung",
    "Developed by": "Entwickelt von",
    "Scan QR Code": "QR-Code scannen",
    "Open or join to an order easy & fast":
      "Öffnen oder sich zu einer Bestellung einfach und schnell",
    "Table token": "Tabelle Token",
    "Add the table uid": "Fügen Sie die Tabelle uid",
    "Create or join to order": "Erstellen oder beitreten, um zu bestellen",
    "Products sent to kitchen successfully!":
      "Produkte zur Küche erfolgreich gesendet!",
    "Divide check": "divide-Check",
    Checkout: "Auschecken",
    "Order Total": "Auftragssumme",
    "Remaining Amount": "Restbetrag",
    "No divide": "keine divide",
    Divide: "Teilen",
    "by guest": "von Gast",
    other: "andere",
    "Custom Amount": "benutzerdefinierte Betrag",
    "Table guests": "Tabelle Gäste",
    "Divide by guest": "Divide by Gast",
    "Select guests": "Wählen Gäste",
    Table: "Tabelle",
    "Select payment method": "Wählen Sie die Zahlungsmethode",
    "Pay with": "Bezahlen mit",
    Cash: "Kasse",
    "Amount to pay": "Zu zahlender Betrag",
    Tip: "Trinkgeld",
    Custom: "Benutzerdefiniert",
    "No tip": "Kein Trinkgeld",
    Pay: "Zahlen",
    "No modifiers selected": "Keine Modifikatoren ausgewählt",
    Settings: "die Einstellungen",
    "Order Detail": "Bestelldetails",
    Created: "Erstellt",
    Closed: "Geschlossen",
    Items: "Artikel",
    Qty: "Anz",
    Product: "Produkt",
    Guest: "Gast",
    "All steps completed": "Alle Schritte abgeschlossen",
    Confirmation: "Bestätigung",
    Guests: "Gäste",
    "Custom amount": "benutzerdefinierte Menge",
    "Payment Method": "Bezahlverfahren",
    Category: "Kategorie",
    "No restaurant found, ask for help.":
      "Kein Restaurant gefunden, um Hilfe zu bitten.",
    "Open your QR scanner from the browser bar":
      "Öffnen Sie Ihren QR Scanner aus der Browserleiste",
    "Re-order": "Nachbestellung",
    "Order item status": "Auftragsposition Status",
    Quantity: "Menge",
    "Report feedback": "Bericht Feedback",
    "Are you sure you want to delete the item?":
      "Sind Sie sicher, dass Sie das Element löschen?",
    Confirm: "Bestätigen",
    Cancel: "Stornieren",
    "Update name": "Update Name",
    "Add your name": "Fügen Sie Ihren Namen",
    Actions: "Aktionen",
    "Missing some required modifiers":
      "Fehlen einige erforderlich Modifikatoren",
    "Item added successfully!": "Artikel erfolgreich hinzugefügt!",
    "Item updated successfully!": "Artikel erfolgreich aktualisiert!",
    "Item deleted successfully!": "Artikel erfolgreich gelöscht!",
    "Additional Indications": "Weitere Indikationen",
    "Add special indications": "In speziellen Indikationen",
    "Add to cart": "In den Warenkorb legen",
    Update: "Aktualisieren",
    Optional: "Optional",
    "Two selections are required": "Zwei Auswahlen sind erforderlich",
    "Three selections are required": "Drei Auswahlen sind erforderlich",
    "Select an option": "Wähle eine Option",
    "Payment Succeeds": "Die Zahlung ist erfolgreich",
    Continue: "Fortsetzen",
    "Checking payment data": "Überprüfen Zahlungsdaten",
    "No ordered items in your cart": "Keine bestellten Artikel im Warenkorb",
    "Sign Out": "Ausloggen",
    "Pending Payment": "Ausstehende Zahlung",
    "Go to the cash or wait for your server":
      "Gehen Sie auf das Bargeld oder warten Sie auf Ihren Server",
    "Make another payment": "Machen Sie eine weitere Zahlung",
    "Payment Cancelled": "Zahlung storniert",
    "Error processing your payment, try again!":
      "Fehler der Verarbeitung Ihrer Zahlung, versuchen Sie es erneut!",
    "Order Item": "Bestellungsartikel",
    Help: "Hilfe",
    "Are you sure you want to delete?":
      "Sind Sie sicher, dass Sie löschen möchten?",
    "Set as principal RFC": "Stellen Sie als Haupt RFC",
    Theme: "Thema",
    "Ok, your waiter is comming! Wait a minute please.":
      "Ok, Ihr Kellner steht vor der Tür! Warte bitte eine Minute.",
    Invoicing: "Fakturierung",
    "Find or generate your invoice": "Finden oder generieren Sie Ihre Rechnung",
    Finish: "Fertig",
    Reset: "Reset",
    "Download invoice": "Download Rechnung",
    "Login to retrieve or generate your invoices":
      "Loggen Sie sich in Ihre Rechnungen abrufen oder erzeugen",
    "Add your payment ID": "Fügen Sie Ihre Zahlung ID",
    "Select or add your RFC": "Wählen Sie ein oder fügen Sie Ihre RFC",
    "Invoice data": "Rechnungsdaten",
    "New RFC": "New RFC",
    "Call your server": "Rufen Sie Ihren Server",
    "Ask for your server": "Fordern Sie Ihren Server",
    "Cash payment": "Barzahlung",
    "Ask for cash payment of your order":
      "Fragen Sie nach Barzahlung Ihrer Bestellung",
    FAQs: "Häufig gestellte Fragen",
    "Find an answer to your questions":
      "Finden Sie eine Antwort auf Ihre Fragen",
    Download: "Herunterladen",
    "Your invoice was sent by email": "Ihre Rechnung wurde per E-Mail",
    "Invoice generated, download here":
      "Rechnung generiert, hier herunterladen",
    "Add RFC": "In RFC",
    save: "speichern",
    "Generate & Download Invoice": "Generieren und downloaden Rechnung",
    cash: "Kasse",
    Place: "Platz",
    promo: "promo",
    "No connection! Trying to reconnect.":
      "Keine Verbindung! Der Versuch, die Verbindung wiederherzustellen.",
    Modifiers: "Modifikatoren",
    Catalogs: "Kataloge",
    Terminal: "Terminal",
    Catalog: "Katalog",
    "Scan or type your QR Code": "Scannen oder geben Sie Ihren QR-Code",
    "QR Code": "QR-Code",
    "Add the code UID": "Fügen Sie den Code UID",
    Location: "Standort",
    "Select a catalog": "Wählen Sie einen Katalog",
    "Choose the catalog you want to see":
      "Wählen Sie den Katalog, den Sie wollen sehen,",
    "All catalogs": "Alle Kataloge",
    "No hotel code found, ask for help.":
      "Kein Hotel Code gefunden, um Hilfe zu bitten.",
    "No code found, ask for help.": "Kein Code gefunden, um Hilfe zu bitten.",
    Nights: "Nächte",
    Concierge: "Portier",
    "Call to concierge": "Aufruf zum Concierge",
    "Calling waiter": "Der Aufruf Kellner",
    "Calling concierge": "Der Aufruf Concierge",
    "No location found, ask for help.":
      "Kein Standort gefunden, um Hilfe zu bitten.",
    "Wants to pay with cash": "Möchte heute mit Bargeld bezahlen",
    "Consultation after hours": "Beratung nach Stunden",
    Restaurants: "Restaurants",
    Dashboard: "Instrumententafel",
    Payment: "Zahlung",
    "Confirm order": "Bestellung bestätigen",
    "Delivery options": "Lieferoptionen",
    "Your order": "Deine Bestellung",
    Notes: "Anmerkungen",
    "Credit or debit card": "Kredit- oder Debitkarte",
    "Order Type": "Auftragsart",
    Delivery: "Lieferung",
    "Delivery Address": "Lieferadresse",
    "Collection Address": "Sammeladresse",
    "To Go": "Gehen",
    Localization: "Lokalisierung",
    "Delivery address": "Lieferadresse",
    Addresses: "Adressen",
    "Search your address": "Suchen Sie Ihre Adresse",
    "Apartment/Office/Floor": "Wohnung / Büro / Stockwerk",
    Save: "speichern",
    "Are you sure to delete the address?":
      "Sind Sie sicher, um die Adresse zu löschen?",
    "Address deleted successfully!": "Adresse erfolgreich gelöscht!",
    "Address created successfully!": "Adresse erfolgreich erstellt!",
    "Order created successfully!": "Geschaffen, um erfolgreich!",
    "Add your address": "Fügen Sie Ihre Adresse",
    "To retrieve the nearest restaurants":
      "Zum Abrufen der nächsten Restaurants",
    "Add address": "Adresse hinzufügen",
    "Order accepted by the restaurant!":
      "Auftrag angenommen durch das Restaurant!",
    "Your order is preparing!": "Ihre Bestellung wird in Vorbereitung!",
    "Your order is comming to you!": "Ihre Bestellung wird comming zu Ihnen!",
    "Your order has been delivered": "Ihre Bestellung wurde ausgeliefert",
    "You have an active order": "Sie haben einen aktiven Auftrag",
    ACCEPTED: "AKZEPTIERT",
    PREPARING: "VORBEREITUNG",
    SENT: "GESCHICKT",
    DELIVERED: "ABGEGEBEN",
    CANCELLED: "ABGEBROCHEN",
    "Dine In": "Speisen in",
    Bond: "Bindung",
    "Delivery Amount": "die Lieferung",
    Free: "Kostenlos",
    "Amount paid": "Betrag bezahlt",
    Monday: "Montag",
    Tuesday: "Dienstag",
    Wednesday: "Mittwoch",
    Thursday: "Donnerstag",
    Friday: "Freitag",
    Saturday: "Samstag",
    Sunday: "Sonntag",
    "Pay with OXXO": "Bezahlen Sie mit OXXO",
    "Add your email": "Fügen Sie Ihre E-Mail",
    "Your name": "Dein Name",
    "Your last name": "Ihr Nachname",
    "Add your last name": "Fügen Sie Ihren Nachnamen",
    "Scan QR": "Scan QR",
    "Register my commerce": "Registrieren Sie meinen Handel",
    "My profile": "Mein Profil",
    "No commerce found, ask for help.":
      "Kein Handel gefunden, um Hilfe zu bitten.",
    "Select or add your delivery address":
      "Wählen Sie ein oder fügen Sie Ihre Lieferadresse",
    "Add Tip": "In Tip",
    "No Charge": "Kostenlos",
  },
  it: {
    "Open 2dine on your smartphone": "Aperto 2dine sullo smartphone",
    Login: "Accesso",
    "Sign In": "Registrati",
    "Search…": "Ricerca…",
    "Search...": "Ricerca...",
    "Search for something...": "Cerca qualcosa ...",
    "What do you want today?": "Di che cosa hai voglia oggi?",
    Menus: "menu",
    Categories: "categorie",
    Cart: "Carrello",
    Order: "Ordine",
    Home: "Casa",
    "Choose a section": "Scegli una sezione",
    Orders: "Ordini",
    Payments: "pagamenti",
    Menu: "Menù",
    "How It Works": "Come funziona",
    About: "Di",
    "Scan Table": "Tabella di scansione",
    "Light mode": "modalità Luce",
    "Dark mode": "Modalità scura",
    "Pending Items": "Elementi in sospeso",
    "No pending items in your cart":
      "Non ci sono elementi in sospeso nel carrello",
    "Add items to your order": "Aggiungere titoli ad un Ordine",
    "Confirm & send your order": "Confermare e inviare l'ordine",
    "Confirm & send to order": "Conferma e invio di ordine",
    "Ordered Items": "articoli ordinati",
    Serie: "Serie",
    Subtotal: "totale parziale",
    Taxes: "Le tasse",
    Total: "Totale",
    "Checkout & pay": "Checkout e paga",
    "No items in": "Nessun articolo nel",
    "Select a menu": "Selezionare un menu",
    "Choose the menu you want to see":
      "Scegliere il menu che si desidera vedere",
    "Complete menu": "Menu completo",
    Date: "Data",
    Status: "Stato",
    NEW: "NUOVO",
    DELETED: "CANCELLATO",
    CLOSED: "CHIUSO",
    OPEN: "APERTO",
    Amount: "Quantità",
    Card: "Carta",
    Back: "Indietro",
    Next: "Il prossimo",
    "Scan or type your table token":
      "Eseguire la scansione o digitare il token tavolo",
    "Select your products": "Seleziona i prodotti",
    "Confirm & send to your order": "Conferma e invio al vostro ordine",
    "Add & send more products anytime":
      "Aggiungere e inviare più prodotti in qualsiasi momento",
    "Enjoy your order!": "Godetevi il vostro ordine!",
    Terms: "condizioni",
    "Privacy policy": "Politica sulla riservatezza",
    Version: "Versione",
    "Developed by": "Sviluppato da",
    "Scan QR Code": "Scansiona il codice QR",
    "Open or join to an order easy & fast":
      "Aprire o unirsi a un ordine semplice e veloce",
    "Table token": "Token Tabella",
    "Add the table uid": "Aggiungere l'uid tavolo",
    "Create or join to order": "Creare o partecipare a ordine",
    "Products sent to kitchen successfully!":
      "Prodotti inviati alla cucina con successo!",
    "Divide check": "controllo divide",
    Checkout: "Check-out",
    "Order Total": "Ordine totale",
    "Remaining Amount": "Importo residuo",
    "No divide": "No divide",
    Divide: "Dividere",
    "by guest": "da ospite",
    other: "altro",
    "Custom Amount": "Importo personalizzato",
    "Table guests": "Tabella ospiti",
    "Divide by guest": "Divide by ospite",
    "Select guests": "Select ospiti",
    Table: "tavolo",
    "Select payment method": "Scegli il metodo di pagamento",
    "Pay with": "Paga con",
    Cash: "Contanti",
    "Amount to pay": "Somma da pagare",
    Tip: "Mancia",
    Custom: "costume",
    "No tip": "Nessun suggerimento",
    Pay: "pagare",
    "No modifiers selected": "Nessun modificatori selezionati",
    Settings: "impostazioni",
    "Order Detail": "Dettagli ordine",
    Created: "Creato",
    Closed: "Chiuso",
    Items: "Elementi",
    Qty: "Quantità",
    Product: "Prodotto",
    Guest: "ospite",
    "All steps completed": "Tutti i passaggi completati",
    Confirmation: "Conferma",
    Guests: "Ospiti",
    "Custom amount": "importo personalizzato",
    "Payment Method": "Metodo di pagamento",
    Category: "Categoria",
    "No restaurant found, ask for help.":
      "Nessun ristorante trovato, chiedere aiuto.",
    "Open your QR scanner from the browser bar":
      "Aprire lo scanner QR dalla barra del browser",
    "Re-order": "Re-ordine",
    "Order item status": "Stato articolo dell'ordine",
    Quantity: "Quantità",
    "Report feedback": "rapporto di feedback",
    "Are you sure you want to delete the item?":
      "Sei sicuro di voler cancellare questo annuncio?",
    Confirm: "Confermare",
    Cancel: "Annulla",
    "Update name": "nome aggiornamento",
    "Add your name": "Aggiungi il tuo nome",
    Actions: "Azioni",
    "Missing some required modifiers": "Mancano alcuni modificatori richieste",
    "Item added successfully!": "Articolo aggiunto con successo!",
    "Item updated successfully!": "Articolo aggiornato con successo!",
    "Item deleted successfully!": "Articolo cancellato con successo!",
    "Additional Indications": "Indicazioni supplementari",
    "Add special indications": "Aggiungere indicazioni speciali",
    "Add to cart": "Aggiungi al carrello",
    Update: "Aggiornare",
    Optional: "Opzionale",
    "Two selections are required": "Due selezioni sono necessari",
    "Three selections are required": "Tre selezioni sono necessari",
    "Select an option": "Seleziona un'opzione",
    "Payment Succeeds": "pagamento Succeeds",
    Continue: "Continua",
    "Checking payment data": "Controllo dei dati di pagamento",
    "No ordered items in your cart":
      "Non ci sono articoli ordinati nel carrello",
    "Sign Out": "Disconnessione",
    "Pending Payment": "In attesa di Pagamento",
    "Go to the cash or wait for your server":
      "Vai alla cassa o attendere che il server",
    "Make another payment": "Effettuare un nuovo pagamento",
    "Payment Cancelled": "pagamento annullato",
    "Error processing your payment, try again!":
      "Errore di elaborazione del vostro pagamento, provare di nuovo!",
    "Order Item": "Order Item",
    Help: "Aiuto",
    "Are you sure you want to delete?": "Sei sicuro di voler eliminare?",
    "Set as principal RFC": "Imposta come RFC principale",
    Theme: "Tema",
    "Ok, your waiter is comming! Wait a minute please.":
      "Ok, il tuo cameriere sta venendo! Aspetta un minuto per favore.",
    Invoicing: "fatturazione",
    "Find or generate your invoice": "Trova o generare la fattura",
    Finish: "finire",
    Reset: "Ripristina",
    "Download invoice": "Scarica fattura",
    "Login to retrieve or generate your invoices":
      "Effettua il login per recuperare o generare le fatture",
    "Add your payment ID": "Aggiungi il tuo ID pagamento",
    "Select or add your RFC": "Selezionare o aggiungere il RFC",
    "Invoice data": "Dati di fatturazione",
    "New RFC": "Nuovo RFC",
    "Call your server": "Chiamate il vostro server",
    "Ask for your server": "Chiedi per il server",
    "Cash payment": "Pagamento in contanti",
    "Ask for cash payment of your order":
      "Chiedi a pagamento in contanti del tuo ordine",
    FAQs: "FAQs",
    "Find an answer to your questions": "Trovare una risposta alle tue domande",
    Download: "Scarica",
    "Your invoice was sent by email": "La fattura è stato inviato per e-mail",
    "Invoice generated, download here": "Fattura generato, il download qui",
    "Add RFC": "Aggiungere RFC",
    save: "Salva",
    "Generate & Download Invoice": "Generare e scaricare Invoice",
    cash: "Contanti",
    Place: "Posto",
    promo: "promo",
    "No connection! Trying to reconnect.":
      "Nessuna connessione! Cercando di riconnessione.",
    Modifiers: "modificatori",
    Catalogs: "Cataloghi",
    Terminal: "terminale",
    Catalog: "Catalogare",
    "Scan or type your QR Code":
      "Eseguire la scansione o digitare il codice QR",
    "QR Code": "QR Code",
    "Add the code UID": "Aggiungere il codice UID",
    Location: "Posizione",
    "Select a catalog": "Selezionare un catalogo",
    "Choose the catalog you want to see": "Scegli il catalogo che vuoi vedere",
    "All catalogs": "tutti i cataloghi",
    "No hotel code found, ask for help.":
      "Nessun codice hotel trovato, chiedere aiuto.",
    "No code found, ask for help.": "Nessun codice trovato, chiedere aiuto.",
    Nights: "notti",
    Concierge: "Concierge",
    "Call to concierge": "Chiama per portineria",
    "Calling waiter": "Chiamare cameriere",
    "Calling concierge": "Chiamata portineria",
    "No location found, ask for help.": "Nessun luogo trovato, chiedere aiuto.",
    "Wants to pay with cash": "Vuole pagare con denaro contante",
    "Consultation after hours": "Consultazione dopo ore",
    Restaurants: "Ristoranti",
    Dashboard: "Pannello di controllo",
    Payment: "Pagamento",
    "Confirm order": "Confermare l'ordine",
    "Delivery options": "Opzioni di consegna",
    "Your order": "Il tuo ordine",
    Notes: "Appunti",
    "Credit or debit card": "Di credito o di debito",
    "Order Type": "Tipo ordine",
    Delivery: "Consegna",
    "Delivery Address": "Indirizzo di consegna",
    "Collection Address": "collezione Indirizzo",
    "To Go": "Andare",
    Localization: "Localizzazione",
    "Delivery address": "Indirizzo di consegna",
    Addresses: "indirizzi",
    "Search your address": "Cerca il tuo indirizzo",
    "Apartment/Office/Floor": "Appartamento / ufficio / Piano",
    Save: "Salva",
    "Are you sure to delete the address?":
      "Sei sicuro di voler cancellare l'indirizzo?",
    "Address deleted successfully!": "Indirizzo cancellato con successo!",
    "Address created successfully!": "Indirizzo creato con successo!",
    "Order created successfully!": "Order creato con successo!",
    "Add your address": "Aggiungi il tuo indirizzo",
    "To retrieve the nearest restaurants":
      "Per recuperare i ristoranti più vicini",
    "Add address": "Aggiungi indirizzo",
    "Order accepted by the restaurant!": "Ordine accettato dal ristorante!",
    "Your order is preparing!": "Il vostro ordine si prepara!",
    "Your order is comming to you!": "Il vostro ordine sta venendo a voi!",
    "Your order has been delivered": "Il tuo ordine è stato consegnato",
    "You have an active order": "Si dispone di un ordine attivo",
    ACCEPTED: "ACCETTATO",
    PREPARING: "PREPARAZIONE",
    SENT: "INVIATO",
    DELIVERED: "CONSEGNATO",
    CANCELLED: "ANNULLATO",
    "Dine In": "Cenare in",
    Bond: "Legame",
    "Delivery Amount": "della consegna",
    Free: "Gratuito",
    "Amount paid": "Importo pagato",
    Monday: "Lunedi",
    Tuesday: "martedì",
    Wednesday: "mercoledì",
    Thursday: "giovedi",
    Friday: "Venerdì",
    Saturday: "Sabato",
    Sunday: "Domenica",
    "Pay with OXXO": "Paga con OXXO",
    "Add your email": "Aggiungi la tua email",
    "Your name": "Il tuo nome",
    "Your last name": "Il tuo cognome",
    "Add your last name": "Aggiungi il tuo cognome",
    "Scan QR": "QR Scan",
    "Register my commerce": "Registrare il mio commerce",
    "My profile": "Il mio profilo",
    "No commerce found, ask for help.": "No commerce trovato, chiedere aiuto.",
    "Select or add your delivery address":
      "Selezionare o aggiungere l'indirizzo di consegna",
    "Add Tip": "Aggiungere Tip",
    "No Charge": "No Charge",
  },
  pl: {},
  pt: {
    "Open 2dine on your smartphone": "Abrir 2dine em seu smartphone",
    Login: "Conecte-se",
    "Sign In": "Assinar em",
    "Search…": "Procurar…",
    "Search...": "Procurar...",
    "Search for something...": "Procurar alguma coisa ...",
    "What do you want today?": "O que você quer hoje?",
    Menus: "menus",
    Categories: "categorias",
    Cart: "Carrinho",
    Order: "Ordem",
    Home: "Casa",
    "Choose a section": "Escolha uma seção",
    Orders: "ordens",
    Payments: "pagamentos",
    Menu: "Cardápio",
    "How It Works": "Como funciona",
    About: "Sobre",
    "Scan Table": "Tabela de digitalização",
    "Light mode": "modo de luz",
    "Dark mode": "modo escuro",
    "Pending Items": "Itens pendentes",
    "No pending items in your cart": "Nenhum item pendentes no seu carrinho",
    "Add items to your order": "Adicionar itens ao seu fim",
    "Confirm & send your order": "Confirmar e enviar a sua encomenda",
    "Confirm & send to order": "Confirmar e enviar a ordem",
    "Ordered Items": "Itens pedidos",
    Serie: "Serie",
    Subtotal: "Subtotal",
    Taxes: "Impostos",
    Total: "Total",
    "Checkout & pay": "Caixa & pay",
    "No items in": "Não há itens no",
    "Select a menu": "Escolha um menu",
    "Choose the menu you want to see": "Escolha o menu que você quer ver",
    "Complete menu": "Menu completo",
    Date: "Encontro",
    Status: "estado",
    NEW: "NOVO",
    DELETED: "DELETED",
    CLOSED: "FECHADAS",
    OPEN: "ABERTO",
    Amount: "Montante",
    Card: "Cartão",
    Back: "De volta",
    Next: "Próximo",
    "Scan or type your table token": "Digitalizar ou digitar seu token mesa",
    "Select your products": "Selecione seus produtos",
    "Confirm & send to your order": "Confirmar e enviar a sua encomenda",
    "Add & send more products anytime":
      "Adicionar & enviar mais produtos a qualquer hora",
    "Enjoy your order!": "Aproveite o seu fim!",
    Terms: "condições",
    "Privacy policy": "Política de Privacidade",
    Version: "Versão",
    "Developed by": "Desenvolvido por",
    "Scan QR Code": "Digitalização QR Code",
    "Open or join to an order easy & fast":
      "Abrir ou juntar-se a uma ordem rápida e fácil",
    "Table token": "token de mesa",
    "Add the table uid": "Adicione o uid mesa",
    "Create or join to order": "Criar ou juntar-se ao fim",
    "Products sent to kitchen successfully!":
      "Os produtos enviados para a cozinha com sucesso!",
    "Divide check": "verificação Divide",
    Checkout: "Verificação de saída",
    "Order Total": "total de pedidos",
    "Remaining Amount": "Montante restante",
    "No divide": "Sem divisão",
    Divide: "Dividir",
    "by guest": "pelo convidado",
    other: "de outros",
    "Custom Amount": "personalizado Quantidade",
    "Table guests": "comensais",
    "Divide by guest": "Divide pelo convidado",
    "Select guests": "convidados selecionados",
    Table: "Mesa",
    "Select payment method": "Selecione o método de pagamento",
    "Pay with": "Pagar com",
    Cash: "Dinheiro",
    "Amount to pay": "Montante a pagar",
    Tip: "Gorjeta",
    Custom: "personalizadas",
    "No tip": "Sem ponta",
    Pay: "Pagar",
    "No modifiers selected": "Sem modificadores seleccionados",
    Settings: "Definições",
    "Order Detail": "Detalhe ordem",
    Created: "Criada",
    Closed: "Fechadas",
    Items: "Itens",
    Qty: "Qtde",
    Product: "produtos",
    Guest: "Hóspede",
    "All steps completed": "Todas as etapas concluídas",
    Confirmation: "Confirmação",
    Guests: "Os hóspedes",
    "Custom amount": "quantidade personalizada",
    "Payment Method": "Forma de pagamento",
    Category: "Categoria",
    "No restaurant found, ask for help.":
      "Não há restaurante encontrado, pedir ajuda.",
    "Open your QR scanner from the browser bar":
      "Abra o scanner QR na barra do navegador",
    "Re-order": "Reordenar",
    "Order item status": "status de item de ordem",
    Quantity: "Quantidade",
    "Report feedback": "relatório de Feedback",
    "Are you sure you want to delete the item?":
      "Tem certeza de que deseja excluir o item?",
    Confirm: "confirme",
    Cancel: "Cancelar",
    "Update name": "nome da atualização",
    "Add your name": "Adicione seu nome",
    Actions: "Ações",
    "Missing some required modifiers":
      "Faltando alguns modificadores necessários",
    "Item added successfully!": "Item adicionado com sucesso!",
    "Item updated successfully!": "Item atualizado com sucesso!",
    "Item deleted successfully!": "Item excluído com sucesso!",
    "Additional Indications": "Indicações adicionais",
    "Add special indications": "Adicionar indicações especiais",
    "Add to cart": "Adicionar ao carrinho",
    Update: "Atualizar",
    Optional: "Opcional",
    "Two selections are required": "Duas seleções são obrigatórios",
    "Three selections are required": "Três seleções são obrigatórios",
    "Select an option": "Selecione uma opção",
    "Payment Succeeds": "pagamento Sucede",
    Continue: "Continuar",
    "Checking payment data": "Verificando os dados de pagamento",
    "No ordered items in your cart":
      "Não há itens encomendados no seu carrinho",
    "Sign Out": "Sair",
    "Pending Payment": "Pagamento Pendente",
    "Go to the cash or wait for your server":
      "Vá para o dinheiro ou esperar para o seu servidor",
    "Make another payment": "Fazer outro pagamento",
    "Payment Cancelled": "pagamento cancelado",
    "Error processing your payment, try again!":
      "Erro ao processar seu pagamento, tente novamente!",
    "Order Item": "fim do item",
    Help: "Socorro",
    "Are you sure you want to delete?": "Tem certeza de que deseja excluir?",
    "Set as principal RFC": "Definir como RFC diretor",
    Theme: "Tema",
    "Ok, your waiter is comming! Wait a minute please.":
      "Ok, seu garçom está vindo! Espere um minuto por favor.",
    Invoicing: "facturação",
    "Find or generate your invoice": "Encontrar ou gerar sua fatura",
    Finish: "Terminar",
    Reset: "Redefinir",
    "Download invoice": "Baixe o Invoice",
    "Login to retrieve or generate your invoices":
      "Entre para recuperar ou gerar suas faturas",
    "Add your payment ID": "Adicione seu ID de pagamento",
    "Select or add your RFC": "Selecione ou adicionar seu RFC",
    "Invoice data": "dados de facturação",
    "New RFC": "New RFC",
    "Call your server": "Ligue para o seu servidor",
    "Ask for your server": "Pergunte para o seu servidor",
    "Cash payment": "Pagamento em dinheiro",
    "Ask for cash payment of your order":
      "Pergunte para pagamento em dinheiro do seu pedido",
    FAQs: "FAQs",
    "Find an answer to your questions":
      "Encontrar uma resposta para suas perguntas",
    Download: "Baixar",
    "Your invoice was sent by email": "A sua factura foi enviada por e-mail",
    "Invoice generated, download here": "Facturar gerado, baixe aqui",
    "Add RFC": "Adicionar RFC",
    save: "Salve ",
    "Generate & Download Invoice": "Gerar e transferir Invoice",
    cash: "dinheiro",
    Place: "Lugar, colocar",
    promo: "promo",
    "No connection! Trying to reconnect.": "Sem ligação! Tentando reconectar.",
    Modifiers: "modificadores",
    Catalogs: "catálogos",
    Terminal: "terminal",
    Catalog: "Catálogo",
    "Scan or type your QR Code": "Digitalizar ou digitar seu código de QR",
    "QR Code": "Código QR",
    "Add the code UID": "Adicione o código UID",
    Location: "Localização",
    "Select a catalog": "Selecione um catálogo",
    "Choose the catalog you want to see":
      "Escolha o catálogo que você quer ver",
    "All catalogs": "Todos os catálogos",
    "No hotel code found, ask for help.":
      "Nenhum código Hotel encontrado, pedir ajuda.",
    "No code found, ask for help.": "Nenhum código encontrado, pedir ajuda.",
    Nights: "noites",
    Concierge: "Porteiro",
    "Call to concierge": "Chamada para concierge",
    "Calling waiter": "chamando garçom",
    "Calling concierge": "chamando concierge",
    "No location found, ask for help.": "Nenhum local encontrado, pedir ajuda.",
    "Wants to pay with cash": "Quer pagar com dinheiro",
    "Consultation after hours": "Consulta depois de horas",
    Restaurants: "restaurantes",
    Dashboard: "painel de controle",
    Payment: "Forma de pagamento",
    "Confirm order": "Confirmar orden",
    "Delivery options": "Opções de entrega",
    "Your order": "Seu pedido",
    Notes: "Notas",
    "Credit or debit card": "Cartão de crédito ou débito",
    "Order Type": "Tipo de ordem",
    Delivery: "Entrega",
    "Delivery Address": "Endereço de entrega",
    "Collection Address": "coleção Endereço",
    "To Go": "Ir",
    Localization: "Localização",
    "Delivery address": "Endereço de entrega",
    Addresses: "endereços",
    "Search your address": "Pesquisar seu endereço",
    "Apartment/Office/Floor": "Apartamento / Escritório / Piso",
    Save: "Salve ",
    "Are you sure to delete the address?":
      "Tem certeza que deseja excluir o endereço?",
    "Address deleted successfully!": "Endereço excluído com sucesso!",
    "Address created successfully!": "Endereço criada com sucesso!",
    "Order created successfully!": "Ordem criada com sucesso!",
    "Add your address": "Adicione o seu endereço",
    "To retrieve the nearest restaurants":
      "Para recuperar os restaurantes mais próximos",
    "Add address": "Adicione o endereço",
    "Order accepted by the restaurant!": "Pedido aceito pelo restaurante!",
    "Your order is preparing!": "Seu pedido está preparando!",
    "Your order is comming to you!": "Seu pedido está vindo para você!",
    "Your order has been delivered": "Seu pedido foi entregue",
    "You have an active order": "Você tem uma ordem ativa",
    ACCEPTED: "ACEITARAM",
    PREPARING: "PREPARANDO",
    SENT: "ENVIEI",
    DELIVERED: "ENTREGUE",
    CANCELLED: "CANCELADO",
    "Dine In": "Dine In",
    Bond: "Vinculo",
    "Delivery Amount": "entrega Quantidade",
    Free: "Livre",
    "Amount paid": "Quantia paga",
    Monday: "Segunda-feira",
    Tuesday: "terça",
    Wednesday: "quarta-feira",
    Thursday: "quinta-feira",
    Friday: "Sexta-feira",
    Saturday: "sábado",
    Sunday: "domingo",
    "Pay with OXXO": "Pague com OXXO",
    "Add your email": "Adicione o seu e-mail",
    "Your name": "Seu nome",
    "Your last name": "Seu último Nome",
    "Add your last name": "Adicione seu apelido",
    "Scan QR": "QR digitalização",
    "Register my commerce": "Registrar meu commerce",
    "My profile": "Meu perfil",
    "No commerce found, ask for help.": "Sem commerce encontrado, pedir ajuda.",
    "Select or add your delivery address":
      "Selecionar ou adicionar o seu endereço de entrega",
    "Add Tip": "Adicionar Tip",
    "No Charge": "não há cobrança",
  },
};

moment.locale(language || "en");
I18n.putVocabularies(dict);
