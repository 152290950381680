import React from "react";
import { I18n } from "aws-amplify";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Brightness4 from "@material-ui/icons/Brightness4";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import DescriptionIcon from "@material-ui/icons/Description";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";

import useReducer from "../reducers";
// import SignedInRoute from '../components/SignedInRoute';
import ThemeSwitch from "../components/ThemeSwitch";
import FiscalDataForm from "../components/Invoicing/FiscalDataForm";
import { JSONParse } from "../util";
import AutoInvoicingSwitch from "../components/Invoicing/AutoInvoicingSwitch";
import { Box, Typography, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  primaryText: {
    color: theme.palette.primary.main,
  },
}));

const Settings = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openForm, setOpenForm] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState(null);

  const [
    {
      loginReducer: {
        authState,
        currentUser,
        browserData: { country },
      },
    },
    dispatch,
  ] = useReducer();

  const handleClick = () => {
    setOpen(!open);
  };

  const handleOpenForm = (value) => () => {
    setOpenForm(value || !openForm);
  };

  React.useEffect(() => {
    let mounted = true;
    if (!openForm) {
      if (mounted) {
        setSelectedItem(null);
      }
    }

    return () => {
      mounted = false;
    };
  }, [openForm]);

  const handleEdit = (item) => () => {
    setSelectedItem(item);
    handleOpenForm(true)();
  };

  const { fiscalData, principalRfc } = currentUser || {};
  const rfcList = (fiscalData && Object.values(JSONParse(fiscalData))) || [];

  return (
    <List
      subheader={<ListSubheader>{I18n.get("Settings")}</ListSubheader>}
      className={classes.root}
    >
      <ListItem>
        <ListItemIcon>
          <Brightness4 />
        </ListItemIcon>
        <ListItemText
          id="switch-list-label-wifi"
          primary={I18n.get("Theme")}
          secondary={I18n.get("Dark mode")}
        />
        <ListItemSecondaryAction>
          <ThemeSwitch />
        </ListItemSecondaryAction>
      </ListItem>
      {country && country.toUpperCase() === "MX" ? (
        <>
          <ListItem button onClick={handleClick}>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary="Facturación" secondary="Datos fiscales" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {authState === "signedIn" ? (
              <List component="div" disablePadding>
                <AutoInvoicingSwitch />
                <FiscalDataForm
                  handleOpenForm={handleOpenForm}
                  openForm={openForm}
                  selectedItem={selectedItem}
                />
                {rfcList.length
                  ? rfcList.map((item, index) => (
                      <ListItem
                        key={(item && item.rfc) || index}
                        className={classes.nested}
                      >
                        <ListItemText
                          primary={item && item.rfc}
                          secondary={item && item.name}
                          classes={{
                            primary:
                              principalRfc === (item && item.rfc)
                                ? classes.primaryText
                                : null,
                          }}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="edit"
                            onClick={handleEdit(item)}
                          >
                            <EditIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))
                  : null}
              </List>
            ) : (
              <Box p={4} textAlign="center">
                <Typography>
                  Inicia sesión para guardar tus datos fiscales
                </Typography>
                <Button
                  variant="text"
                  onClick={() => dispatch({ type: "LOGIN_OPEN" })}
                  style={{ marginTop: 16 }}
                >
                  {I18n.get("Sign In")}
                </Button>
              </Box>
            )}
          </Collapse>
        </>
      ) : null}
    </List>
  );
};

export default Settings;
