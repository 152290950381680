import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
// import Slide from "@material-ui/core/Slide";
import { I18n } from "aws-amplify";
import {
  Collapse,
  Button,
  Box,
  ButtonGroup,
  TextField,
} from "@material-ui/core";

import {
  getTipCurrency,
  getDigitsFromValue,
  getTotal,
  toCurrency,
  JSONParse,
  isEmptyObject,
} from "../../util";
import useReducer from "../../reducers";
import NumericKeyboard from "../NumericKeyboard";
import CurrencyInput from "../CurrencyInput";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const DeliveryTipsDialog = () => {
  const classes = useStyles();
  const keyboardRef = React.useRef();
  const [open, setOpen] = React.useState(false);
  const [
    {
      deliveryReducer: {
        selectedRestaurant,
        paymentMethod,
        tipType,
        tipAmount,
        deliveryOrderAmount,
        deliveryOrderTaxes,
      },
    },
    dispatch,
  ] = useReducer();
  const { tips } = selectedRestaurant || {};
  let parsedTips = tips ? JSONParse(tips) : null;
  parsedTips = parsedTips && !isEmptyObject(parsedTips) ? parsedTips : null;
  console.log("tips", parsedTips);

  const variant = (value) => (tipType === value ? "contained" : "outlined");

  const getTipValue = () => {
    if (parsedTips && tipType < 3) {
      return Object.keys(parsedTips)?.[tipType] || "other";
    }

    switch (tipType) {
      case 0:
        return "10%";
      case 1:
        return "15%";
      case 2:
        return "20%";
      default:
        return "other";
    }
  };

  const deliveryTotal = React.useMemo(
    () => getTotal(deliveryOrderAmount, deliveryOrderTaxes),
    [deliveryOrderAmount, deliveryOrderTaxes]
  );

  const handleTipType = React.useCallback(
    (value) => () => {
      dispatch({ type: "DELIVERY_SET_TIP_TYPE", payload: { tipType: value } });
      const amount = getTipCurrency(value, deliveryTotal, parsedTips);
      const numericValue = +getDigitsFromValue(amount);
      dispatch({
        type: "DELIVERY_SET_TIP_AMOUNT",
        payload: { tipAmount: numericValue },
      });
      if (keyboardRef && keyboardRef.current) {
        keyboardRef.current.setInput(amount);
        if (value !== 3) {
          setOpen(false);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deliveryTotal]
  );

  React.useEffect(() => {
    if (deliveryTotal && paymentMethod !== "cash") {
      handleTipType(tipType)();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryTotal, tipAmount, tipType, paymentMethod]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onChange = (input) => {
    const currencyValue = toCurrency(input);
    const numericValue = +getDigitsFromValue(currencyValue);
    dispatch({
      type: "DELIVERY_SET_TIP_AMOUNT",
      payload: { tipAmount: numericValue },
    });
    keyboardRef.current.setInput(currencyValue);
  };

  const onKeyPress = (button) => {};

  return (
    <>
      <ListItem
        button
        disabled={paymentMethod !== "card"}
        onClick={handleClickOpen}
      >
        <ListItemText
          primary={I18n.get("Add Tip")}
          secondary={
            tipAmount
              ? `$ ${toCurrency((tipAmount || 0).toString())} (${I18n.get(
                  getTipValue()
                )})`
              : "-"
          }
        />
      </ListItem>
      <Dialog
        container={() => document.getElementById("mainBox")}
        fullScreen
        open={open}
        onClose={handleClose}
        // TransitionComponent={Transition}
        style={{
          position: "absolute",
        }}
        BackdropProps={{
          style: {
            position: "absolute",
          },
        }}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {I18n.get("Tip")}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box flex={1} mt={2} display="flex" flexDirection="column">
          <Box className={classes.root}>
            {parsedTips ? (
              <ButtonGroup
                size="large"
                color="primary"
                aria-label="large outlined primary button group"
              >
                {Object.keys(parsedTips).map((t, index) => {
                  return (
                    <Button
                      key={index}
                      variant={variant(index)}
                      onClick={handleTipType(index)}
                    >
                      {t}
                    </Button>
                  );
                })}
                <Button variant={variant(3)} onClick={handleTipType(3)}>
                  {I18n.get("Custom")}
                </Button>
              </ButtonGroup>
            ) : (
              <ButtonGroup
                size="large"
                color="primary"
                aria-label="large outlined primary button group"
              >
                <Button variant={variant(0)} onClick={handleTipType(0)}>
                  10%
                </Button>
                <Button variant={variant(1)} onClick={handleTipType(1)}>
                  15%
                </Button>
                <Button variant={variant(2)} onClick={handleTipType(2)}>
                  20%
                </Button>

                <Button variant={variant(3)} onClick={handleTipType(3)}>
                  {I18n.get("Custom")}
                </Button>
              </ButtonGroup>
            )}
            <Box mt={1}>
              <Button color="secondary" size="small" onClick={handleTipType(4)}>
                {I18n.get("No tip")}
              </Button>
            </Box>
          </Box>
          <Box m={1}>
            <CurrencyInput
              Component={TextField}
              variant="filled"
              fullWidth
              placeholder={I18n.get("Tip amount")}
              InputProps={{
                classes: { input: classes.inputInput },
                readOnly: true,
              }}
              value={tipAmount ? toCurrency((tipAmount || 0).toString()) : ""}
            />
            <Collapse in={tipType === 3}>
              <NumericKeyboard
                keyboardRef={keyboardRef}
                onChange={onChange}
                onKeyPress={onKeyPress}
              />
            </Collapse>
            <Button
              variant="contained"
              style={{ marginTop: 8 }}
              size="large"
              fullWidth
              color="primary"
              onClick={() => setOpen(false)}
            >
              {I18n.get("Continue")}
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default DeliveryTipsDialog;
