import React from "react";
import { I18n } from "aws-amplify";
import { Typography } from "@material-ui/core";
import SignedInRoute from "../components/SignedInRoute";
import OrdersComponent from "../components/Orders/OrdersComponent";

const Orders = () => {
  return (
    <SignedInRoute>
      <>
        <Typography variant="h4">{I18n.get("Orders")}</Typography>
        <OrdersComponent />
      </>
    </SignedInRoute>
  );
};

export default Orders;
