import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import { Box, TextField } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { parsePhoneNumber } from "react-phone-number-input";

import useReducer from "../../reducers";
import { dialCodes, emailRegex } from "../../util";

const useStyles = makeStyles((theme) => ({
  formControl: {
    marginRight: theme.spacing(1),
    minWidth: 100,
  },
}));

const DeliveryCustomerData = ({ withEmail = false }) => {
  const classes = useStyles();
  const [
    {
      loginReducer: { currentUser },
      deliveryReducer: {
        receiverPhoneCode,
        receiverPhone,
        receiverName,
        receiverEmail,
      },
    },
    dispatch,
  ] = useReducer();
  const { name, phone_number, username } = currentUser || {};

  React.useEffect(() => {
    if (name) {
      dispatch({
        type: "DELIVERY_SET_NAME",
        payload: { receiverName: name },
      });
    }

    if (username) {
      if (emailRegex.test(username)) {
        dispatch({
          type: "DELIVERY_SET_EMAIL",
          payload: { receiverEmail: username },
        });
      }
    }

    if (phone_number) {
      const { countryCallingCode, nationalNumber } =
        parsePhoneNumber(phone_number) || {};

      //   console.log(countryCallingCode, nationalNumber);
      if (nationalNumber) {
        dispatch({
          type: "DELIVERY_SET_PHONE",
          payload: { receiverPhone: nationalNumber },
        });
      }

      if (countryCallingCode) {
        dispatch({
          type: "DELIVERY_SET_PHONE_CODE",
          payload: { receiverPhoneCode: `+${countryCallingCode}` },
        });
      }
    }
  }, [name, phone_number, username]);

  return (
    <Box p={1}>
      <TextField
        id="standard-basic"
        label="Customer full name"
        variant="outlined"
        fullWidth
        value={receiverName}
        onChange={(event) =>
          dispatch({
            type: "DELIVERY_SET_NAME",
            payload: { receiverName: event.target.value },
          })
        }
      />
      {withEmail ? (
        <TextField
          id="email-field"
          label="Email address"
          variant="outlined"
          type="email"
          fullWidth
          style={{ marginTop: 16 }}
          value={receiverEmail}
          onChange={(event) =>
            dispatch({
              type: "DELIVERY_SET_EMAIL",
              payload: { receiverEmail: event.target.value },
            })
          }
        />
      ) : null}
      <Box mt={2} display="flex" flexDirection="row" alignItems="center">
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor="dial-code">Código</InputLabel>
          <Select
            native
            value={receiverPhoneCode}
            onChange={(event) =>
              dispatch({
                type: "DELIVERY_SET_PHONE_CODE",
                payload: { receiverPhoneCode: event.target.value },
              })
            }
            label="Code"
            inputProps={{
              name: "dialCode",
              id: "dial-code",
            }}
          >
            {dialCodes.map((x) => (
              <option key={x} value={x}>
                {x}
              </option>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Phone number"
          variant="outlined"
          fullWidth
          value={receiverPhone}
          inputMode="tel"
          type="tel"
          onChange={(event) =>
            dispatch({
              type: "DELIVERY_SET_PHONE",
              payload: { receiverPhone: event.target.value },
            })
          }
        />
      </Box>
    </Box>
  );
};

export default DeliveryCustomerData;
