import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import ReorderIcon from "@material-ui/icons/Reorder";
import IconButton from "@material-ui/core/IconButton";
import { Draggable } from "react-beautiful-dnd";
import Tooltip from "@material-ui/core/Tooltip";

import { toCurrency, JSONParse } from "../../util";
import { I18n } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(9),
  },
  paid: {
    textDecoration: "line-through",
  },
}));

const GuestOrderItem = ({ item, index }) => {
  const classes = useStyles();
  const itemTotal =
    item.amount +
    (item.taxes
      ? JSONParse(item.taxes).reduce((sum, val) => sum + val.amount, 0)
      : 0);

  return (
    <Draggable
      draggableId={item.id.toString()}
      index={index}
      isDragDisabled={item.isPaid}
    >
      {(provided) => (
        <ListItem
          ContainerProps={{
            ref: provided.innerRef,
            ...provided.draggableProps,
            ...provided.dragHandleProps,
          }}
          dense
          className={classes.nested}
        >
          <ListItemIcon>
            <Tooltip title={I18n.get("Drag to reorder")}>
              <IconButton edge="start" aria-label="reorder">
                <ReorderIcon />
              </IconButton>
            </Tooltip>
          </ListItemIcon>
          <ListItemText
            primary={`${item.qty} x ${item.title}`}
            classes={{ primary: item.isPaid ? classes.paid : null }}
          />
          <ListItemSecondaryAction>
            <Typography
              variant="subtitle2"
              className={item.isPaid ? classes.paid : null}
            >
              $ {toCurrency((itemTotal || 0).toString())}
            </Typography>
          </ListItemSecondaryAction>
        </ListItem>
      )}
    </Draggable>
  );
};

export default GuestOrderItem;
