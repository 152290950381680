import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// import Slide from "@material-ui/core/Slide";
import { I18n } from "aws-amplify";

import DeliveryGeolocation from "../Addresses/DeliveryGeolocation";
import DeliveryAddressList from "./DeliveryAddressList";
import useReducer from "../../reducers";
import AddressComponent from "../Addresses/AddressComponent";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  paper: {
    backgroundColor: theme.palette.background.default,
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
}));

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const DeliveryAddressDialog = () => {
  const classes = useStyles();
  const [
    {
      deliveryReducer: { openDeliveryAddressDialog: open },
    },
    dispatch,
  ] = useReducer();

  const handleClickOpen = () => {
    dispatch({
      type: "DELIVERY_TOGGLE_ADDRESS_DIALOG",
      payload: { open: true },
    });
  };

  const handleClose = () => {
    dispatch({
      type: "DELIVERY_TOGGLE_ADDRESS_DIALOG",
      payload: { open: false },
    });
  };

  return (
    <>
      <DeliveryGeolocation onClick={handleClickOpen} />

      <Dialog
        container={() => document.getElementById("mainBox")}
        fullScreen
        open={open}
        onClose={handleClose}
        // TransitionComponent={Transition}
        style={{
          position: "absolute",
        }}
        BackdropProps={{
          style: {
            position: "absolute",
          },
        }}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {I18n.get("Addresses")}
            </Typography>
          </Toolbar>
        </AppBar>
        <div>
          <AddressComponent />

          <DeliveryAddressList />
        </div>
      </Dialog>
    </>
  );
};

export default DeliveryAddressDialog;
