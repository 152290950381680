import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/AddCircle";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.primary.light,
  },
}));

const MenuSection = ({
  fromSite = false,
  delivery = false,
  restaurantId,
  category,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const handleClickTile = () => {
    if (!delivery) {
      history.push(`/t/category/${category.id}`);
    } else {
      history.push(
        fromSite
          ? `/d/category/${category.id}`
          : `/d/r/${restaurantId}/category/${category.id}`
      );
    }
  };

  return (
    <Box
      px={2}
      py={1.5}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Typography variant="subtitle1">{category.title}</Typography>
      <IconButton
        edge="end"
        size="small"
        aria-label={`${category.title} view more`}
        onClick={handleClickTile}
      >
        <AddIcon className={classes.icon} />
      </IconButton>
    </Box>
  );
};

export default MenuSection;
