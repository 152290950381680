import React from "react";
import { I18n } from "aws-amplify";
import { Auth } from "aws-amplify";
import { Box, Divider } from "@material-ui/core";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import ReceiptIcon from "@material-ui/icons/Receipt";
import PaymentIcon from "@material-ui/icons/Payment";
import SettingsIcon from "@material-ui/icons/Settings";

import useReducer from "../../reducers";
import ListItemLink from "../MobileNavigation/ListItemLink";
import UserNameComponent from "./UserNameComponent";

const ProfileSummary = () => {
  const [
    {
      loginReducer: { currentUser },
    },
    dispatch,
  ] = useReducer();

  const signOut = () => {
    Auth.signOut()
      .then((data) => {
        console.log("signout data", data);
        localStorage.clear();
        dispatch({
          type: "LOGIN_SET_AUTHSTATE",
          payload: { authState: "signIn" },
        });
        dispatch({
          type: "LOGIN_SET_USER",
          payload: { user: null },
        });
        dispatch({ type: "LOGIN_SET_VIEW", payload: { view: "signin" } });
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Box mx={2} mt={2}>
        <Box mb={2}>
          <UserNameComponent />
          <Typography variant="body2">
            {currentUser?.username?.split(":")?.[1]}
          </Typography>
        </Box>
      </Box>

      <List
        component="nav"
        aria-labelledby="profile-list"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            {I18n.get("Actions")}
          </ListSubheader>
        }
      >
        <ListItem>
          <ListItemIcon>
            <PermIdentityIcon />
          </ListItemIcon>
          <ListItemText primary={currentUser.id.split("_").pop()} />
        </ListItem>
        <Divider variant="inset" component="li" />
        <ListItemLink
          button
          to="/orders"
          primary={I18n.get("Orders")}
          icon={<ReceiptIcon />}
        />
        <Divider variant="inset" component="li" />
        <ListItemLink
          button
          to="/payments"
          primary={I18n.get("Payments")}
          icon={<PaymentIcon />}
        />
        <Divider variant="inset" component="li" />
        <ListItemLink
          button
          to="/settings"
          primary={I18n.get("Settings")}
          icon={<SettingsIcon />}
        />
        <Divider variant="inset" component="li" />
        <ListItem button onClick={signOut}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary={I18n.get("Sign Out")} />
        </ListItem>
      </List>
    </>
  );
};

export default ProfileSummary;
