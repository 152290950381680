import React from "react";

import BottomComponent from "./components/SiteMain.js/BottomComponent";
import useReducer from "./reducers";
import { JSONParse, getPictureUrl } from "./util";
import Wave from "./components/SiteMain.js/Wave";

const SiteMain = () => {
  const [
    {
      siteReducer: { siteData },
    },
  ] = useReducer();
  const cardPicture =
    siteData && siteData.cardPicture ? JSONParse(siteData.cardPicture) : {};
  const cardPictureUrl =
    siteData && siteData.cardPicture
      ? getPictureUrl({ ...cardPicture, size: "575x0" })
      : null;

  return (
    <>
      <div
        style={{
          height: 240,
          backgroundImage: `url(${cardPictureUrl})`,
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          position: "relative",
        }}
      >
        <Wave style={{ position: "absolute", bottom: -2, width: "100%" }} />
      </div>
      <BottomComponent />
    </>
  );
};

export default SiteMain;
