const initialState = {
  currentRestaurant: {},
  menu: null,
  categories: []
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'RESTAURANT_RESET':
      return {
        ...initialState
      };
    case 'RESTAURANT_SET':
      return {
        ...state,
        currentRestaurant: action.payload.currentRestaurant
      };
    case 'RESTAURANT_SET_MENU':
      return {
        ...state,
        menu: action.payload.menu
      };
    case 'RESTAURANT_SET_CATEGORIES':
      return {
        ...state,
        categories: action.payload.categories
      };
    default:
      return state;
  }
};

export default { initialState, reducer };
