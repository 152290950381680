import React from "react";
import { Cache } from "aws-amplify";
import { Box, Fab } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Switch, useRouteMatch, Route, Redirect } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

import * as queries from "../graphql/queries";
import DeliveryRestaurants from "./Delivery/restaurants";
import DeliveryRestaurant from "./Delivery/restaurant";
import CheckoutComponent from "../components/Delivery/CheckoutComponent";
import useReducer from "../reducers";
import {
  getAmount,
  getTaxesAmount,
  isEmptyObject,
  getDiscountItems,
  getDiscountAmount,
} from "../util";
import DeliveryAddressDialog from "../components/Delivery/DeliveryAddressDialog";
import DeliveryOrder from "../components/Delivery/DeliveryOrder";
import UnavailableCartDialog from "../components/Delivery/UnavailableCartDialog";
import OutOfTimeDialog from "../components/Delivery/OutOfTimeDialog";
import { green } from "@material-ui/core/colors";

const ColorButton = withStyles((theme) => ({
  root: {
    zIndex: 1,
    position: "fixed",
    bottom: theme.spacing(8),
    right: theme.spacing(1),
    color: theme.palette.getContrastText(green[600]),
    backgroundColor: green[600],
    "&:hover": {
      backgroundColor: green[700],
    },

    [theme.breakpoints.up("sm")]: {
      bottom: theme.spacing(2.5),
      right: theme.spacing(2),
    },
  },
}))(Fab);

const Delivery = ({ fromSite = false }) => {
  const routeMatch = useRouteMatch();
  const { path } = routeMatch;
  const [
    {
      deliveryReducer: {
        cartCompany,
        selectedRestaurant,
        deliveryCart,
        deliveryAddress,
      },
    },
    dispatch,
  ] = useReducer();
  const isTravel =
    selectedRestaurant && selectedRestaurant.businessType === "TRAVEL";

  React.useEffect(() => {
    let mounted = true;
    const retrievedDeliveryCart = Cache.getItem(`deliveryCart`);
    const retrievedCartRestaurant = Cache.getItem(`cartRestaurant`);
    const retrievedCartCompany = Cache.getItem(`cartCompany`);
    const retrievedAddressList = Cache.getItem(`addressList`);
    const retrievedDeliveryAddress = Cache.getItem(`deliveryAddress`);
    const cachedCurrentDeliveryOrderId = Cache.getItem(
      `currentDeliveryOrderId`
    );

    if (mounted) {
      if (cachedCurrentDeliveryOrderId) {
        dispatch({
          type: "DELIVERY_SET_ORDER_ID",
          payload: {
            currentDeliveryOrderId: cachedCurrentDeliveryOrderId,
          },
        });
      }

      if (retrievedDeliveryCart) {
        dispatch({
          type: "DELIVERY_SET_CART",
          payload: { deliveryCart: retrievedDeliveryCart },
        });
      }

      if (retrievedCartCompany) {
        dispatch({
          type: "DELIVERY_SET_CART_COMPANY",
          payload: { cartCompany: retrievedCartCompany },
        });
      }

      if (retrievedCartRestaurant) {
        dispatch({
          type: "DELIVERY_SET_CART_RESTAURANT",
          payload: { cartRestaurant: retrievedCartRestaurant },
        });
      }

      if (retrievedDeliveryAddress) {
        dispatch({
          type: "DELIVERY_SET_ADDRESS",
          payload: { deliveryAddress: retrievedDeliveryAddress },
        });
      }

      if (retrievedAddressList && retrievedAddressList.length) {
        dispatch({
          type: "DELIVERY_SET_ADDRESS_LIST",
          payload: { addressList: retrievedAddressList },
        });

        if (!retrievedDeliveryAddress) {
          dispatch({
            type: "DELIVERY_SET_ADDRESS",
            payload: { deliveryAddress: retrievedAddressList[0] },
          });
        }
      }
    }

    return () => {
      mounted = false;
    };
  }, []);

  React.useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      const { data } = await API.graphql({
        ...graphqlOperation(queries.getRestaurantData, {
          id: cartCompany.principalRestaurantID,
        }),
      });

      const { getRestaurant } = data || {};

      console.log("getRestaurantData", getRestaurant);
      if (getRestaurant) {
        if (mounted) {
          dispatch({
            type: "DELIVERY_SET_PRINCIPAL_RESTAURANT",
            payload: { principalRestaurant: getRestaurant },
          });
        }
      }
    };

    console.log("isTravel", isTravel);
    if (cartCompany && !isTravel && cartCompany.principalRestaurantID) {
      fetchData();
    }

    return () => {
      mounted = false;
    };
  }, [cartCompany, isTravel]);

  React.useEffect(() => {
    let mounted = true;
    if (deliveryCart && deliveryCart.length) {
      const amount = getAmount(deliveryCart, true);
      const taxes = getTaxesAmount(deliveryCart);

      const discountItems = getDiscountItems(deliveryCart);

      if (mounted) {
        dispatch({
          type: "DELIVERY_SET_AMOUNT",
          payload: { deliveryOrderAmount: amount },
        });
        dispatch({
          type: "DELIVERY_SET_TAXES",
          payload: { deliveryOrderTaxes: taxes },
        });
        dispatch({
          type: "DELIVERY_SET_DISCOUNT_AMOUNT",
          payload: { deliveryDiscountAmount: getDiscountAmount(discountItems) },
        });
      }
    } else {
      if (mounted) {
        dispatch({
          type: "DELIVERY_SET_AMOUNT",
          payload: { deliveryOrderAmount: 0 },
        });
        dispatch({
          type: "DELIVERY_SET_TAXES",
          payload: { deliveryOrderTaxes: [] },
        });
        dispatch({
          type: "DELIVERY_SET_DISCOUNT_AMOUNT",
          payload: { deliveryDiscountAmount: 0 },
        });
      }
    }

    return () => {
      mounted = false;
    };
  }, [deliveryCart, deliveryCart.length]);

  React.useEffect(() => {
    let mounted = true;

    const fetchData = async () => {
      try {
        if (deliveryAddress && !isEmptyObject(deliveryAddress)) {
          const latitude =
            deliveryAddress.geometry &&
            deliveryAddress.geometry.location &&
            deliveryAddress.geometry.location.lat;
          const longitude =
            deliveryAddress.geometry &&
            deliveryAddress.geometry.location &&
            deliveryAddress.geometry.location.lng;

          const queryNearbyRestaurants = API.graphql({
            ...graphqlOperation(queries.nearbyRestaurants, {
              m: 4000,
              limit: 500,
              location: {
                lat: latitude,
                lon: longitude,
              },
            }),
            authMode: "AWS_IAM",
          });

          const { data } = (await queryNearbyRestaurants) || {};

          const {
            nearbyRestaurants: { items: nearbyRestaurants } = { items: [] },
          } = data || {};
          // console.log("nearbyRestaurants", nearbyRestaurants);

          if (mounted) {
            dispatch({
              type: "DELIVERY_SET_NEARBY_LOADING",
              payload: { loadingNearbyResturants: false },
            });
            dispatch({
              type: "DELIVERY_SET_NEARBY_RESTAURANTS",
              payload: { nearbyRestaurants: nearbyRestaurants },
            });
          }
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();

    return () => {
      mounted = false;
    };
  }, [deliveryAddress]);

  return (
    <>
      <DeliveryAddressDialog />

      <UnavailableCartDialog />
      <OutOfTimeDialog />

      <Box display="flex" flex={1} flexDirection="column">
        <Switch>
          <Redirect
            exact
            from={`${path}/restaurant/:restaurantId`}
            to={`${path}/r/:restaurantId`}
          />
          <Redirect
            from={`${path}/restaurant/:restaurantId/menu`}
            to={`${path}/r/:restaurantId/menu`}
          />
          <Redirect
            from={`${path}/restaurant/:restaurantId/category/:categoryId`}
            to={`${path}/r/:restaurantId/category/:categoryId`}
          />
          <Redirect
            from={`${path}/restaurant/:restaurantId/product/:productId`}
            to={`${path}/r/:restaurantId/product/:productId`}
          />
          {!fromSite ? (
            <Route path={`${path}/r/:restaurantId`}>
              <DeliveryRestaurant />
            </Route>
          ) : null}
          <Route path={`${path}/order/:orderId`}>
            <DeliveryOrder />
          </Route>
          <Route path={path}>
            {fromSite ? (
              <DeliveryRestaurant fromSite={fromSite} />
            ) : (
              <DeliveryRestaurants />
            )}
          </Route>
          <Route path="*">
            <Redirect to={path} />
          </Route>
        </Switch>
      </Box>
      {selectedRestaurant && selectedRestaurant.status === "ACTIVE" ? (
        <CheckoutComponent fromSite={fromSite} />
      ) : null}

      {isTravel && selectedRestaurant && selectedRestaurant.contactPhone ? (
        <ColorButton
          component="a"
          href={`https://wa.me/${selectedRestaurant.contactPhone.replace(
            "+",
            ""
          )}?text=Me%20interesa%20mayor%20informaci%C3%B3n`}
          target="_blank"
          color="primary"
          aria-label="whats"
        >
          <WhatsAppIcon fontSize="large" />
        </ColorButton>
      ) : null}
    </>
  );
};

export default Delivery;
