import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import ScrollToTopOnMount from "../../components/ScrollToTopOnMount";
import useQuery from "../../hooks/useQuery";
import MenuComponent from "../../components/Menu/MenuComponent";
import MenuGrid from "../../components/Menu/MenuGrid";
import useReducer from "../../reducers";
import { isEmptyObject, isSafariBrowser } from "../../util";
import { Redirect } from "react-router-dom";

const DeliveryMenu = ({ fromSite = false }) => {
  const query = useQuery();
  const [
    {
      deliveryReducer: { selectedRestaurant },
    },
  ] = useReducer();
  // get selected menu
  const selectedMenu = query.get("smenu");
  const selectedTitle = query.get("mtitle");

  if (!selectedRestaurant || isEmptyObject(selectedRestaurant)) {
    return <Redirect to={`/d`} />;
  }

  return (
    <>
      <ScrollToTopOnMount />
      <Box mb={1}>
        {/* <Typography variant="h4">{I18n.get("Menu")}</Typography> */}
        {selectedTitle ? (
          <Typography
            variant="h6"
            color="secondary"
            style={{ marginBottom: 8 }}
          >
            {selectedTitle}
          </Typography>
        ) : null}
      </Box>

      {!selectedMenu ? (
        <MenuComponent
          selectedRestaurant={selectedRestaurant}
          delivery
          fromSite={fromSite}
        />
      ) : (
        <Box mb={7}>
          <MenuGrid
            selectedMenu={selectedMenu}
            restaurantId={
              selectedRestaurant && selectedRestaurant.id
                ? selectedRestaurant.id
                : null
            }
            delivery
            fromSite={fromSite}
          />
          {isSafariBrowser ? (
            <div id="space" style={{ minHeight: 64, height: 64 }} />
          ) : null}
        </Box>
      )}
    </>
  );
};

export default DeliveryMenu;
