import React from "react";
import { I18n } from "aws-amplify";
import Button from "@material-ui/core/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { Typography } from "@material-ui/core";

function ConfirmDialog(props) {
  const {
    onClose,
    onConfirm,
    open,
    message = "Are you sure you want to delete the item?",
    ...other
  } = props;

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onConfirm();
  };

  return (
    <Dialog
      container={() => document.getElementById("mainBox")}
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
      style={{
        position: "absolute",
      }}
      BackdropProps={{
        style: {
          position: "absolute",
        },
      }}
    >
      <DialogTitle id="confirmation-dialog-title">
        {I18n.get("Confirm")}
      </DialogTitle>
      <DialogContent dividers>
        <Typography>{I18n.get(message)}</Typography>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          {I18n.get("Cancel")}
        </Button>
        <Button onClick={handleOk} color="primary">
          {I18n.get("Confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
