import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { I18n } from "aws-amplify";
import { API, graphqlOperation } from "aws-amplify";
import Skeleton from "@material-ui/lab/Skeleton";

import { NavLink } from "react-router-dom";
import { JSONParse } from "../../util";
import * as queries from "../../graphql/queries";

const GenerateInvoiceButton = ({ orderId, stripePaymentId }) => {
  const [loading, setLoading] = React.useState(true);
  const [payment, setPayment] = React.useState({});
  console.log("generate invoice payment", payment);

  React.useEffect(() => {
    let mounted = true;

    const fetchData = async () => {
      try {
        const { data } = await API.graphql({
          ...graphqlOperation(queries.getOrder, { id: orderId }),
          authMode: "AWS_IAM",
        });

        const { getOrder: currentOrder } = data || {};
        console.log("currentOrder", currentOrder);
        const { payments: { items: orderPayments } = { items: [] } } =
          currentOrder || {};
        console.log("generate orderPayments", orderPayments);

        if (orderId && orderPayments && orderPayments.length) {
          const currPayment = orderPayments.find((x) => {
            const parsedPaymentData = x.paymentData
              ? JSONParse(x.paymentData)
              : {};
            return parsedPaymentData.id === stripePaymentId;
          });
          console.log("findedPayment", currPayment);

          if (mounted) {
            setPayment(currPayment);
          }
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    };

    if (orderId && stripePaymentId) {
      fetchData();
    }
  }, [orderId, stripePaymentId]);

  return !loading ? (
    payment && payment.id ? (
      <Box>
        <Button
          style={{ marginTop: 16 }}
          variant="contained"
          color="secondary"
          fullWidth
          component={NavLink}
          to={`/invoicing?pid=${payment.id}`}
        >
          {I18n.get("Generate & Download Invoice")}
        </Button>
      </Box>
    ) : null
  ) : (
    <Box>
      <Skeleton
        variant="rect"
        animation="wave"
        height={36}
        width={"100%"}
        style={{ borderRadius: 4, marginTop: 16 }}
      />
    </Box>
  );
};

export default GenerateInvoiceButton;
