import React from "react";
import moment from "moment";
import { I18n } from "aws-amplify";
import { NavLink as RouterLink } from "react-router-dom";
import { Typography, Box, Button } from "@material-ui/core";

import useReducer from "../reducers";

const About = () => {
  const [
    {
      themeReducer: { isDark },
    },
  ] = useReducer();

  const forceSWupdate = () => {
    console.log("navigator", navigator);
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .getRegistrations()
        .then(function (registrations) {
          console.log("registrations", registrations);
          for (let registration of registrations) {
            registration.update();
          }
          window.location.reload(true);
        })
        .catch((error) => console.log("error", error));
    }
  };

  return (
    <>
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h6">eMenu</Typography>
        <Typography variant="h6">Self Ordering System</Typography>
        {isDark ? (
          <img
            src="/3.W.png"
            alt="Plukke"
            style={{
              height: 64,
              width: 140,
              marginTop: 16,
              marginBottom: 16,
              objectFit: "contain",
            }}
          />
        ) : (
          <img
            src="/3.B.png"
            alt="Plukke"
            style={{
              height: 64,
              width: 140,
              marginTop: 16,
              marginBottom: 16,
              objectFit: "contain",
            }}
          />
        )}
        <div>
          <Typography
            variant="caption"
            style={{ textAlign: "center", minWidth: 112, whiteSpace: "nowrap" }}
          >
            © {moment().format("YYYY")} 2dine, Inc.
          </Typography>
        </div>
        <Box>
          <Button
            color="primary"
            component={RouterLink}
            to="/terms"
            style={{ marginRight: 16 }}
          >
            {I18n.get("Terms")}
          </Button>
          <Button color="primary" component={RouterLink} to="/privacy">
            {I18n.get("Privacy policy")}
          </Button>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography
          variant="caption"
          style={{ textAlign: "center", minWidth: 96, whiteSpace: "nowrap" }}
          onClick={forceSWupdate}
        >
          {I18n.get("Version")} {process.env.REACT_APP_VERSION}
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Typography
          variant="caption"
          style={{ minWidth: 96, whiteSpace: "nowrap" }}
        >
          {I18n.get("Developed by")}
        </Typography>
        {isDark ? (
          <img
            src="/plukke_footer.png"
            alt="Plukke"
            style={{
              height: 24,
              width: 72,
              marginLeft: 8,
              objectFit: "contain",
            }}
          />
        ) : (
          <img
            src="/plukke_footer_b.png"
            alt="Plukke"
            style={{
              height: 20,
              width: 72,
              marginLeft: 8,
              objectFit: "contain",
            }}
          />
        )}
      </Box>
    </>
  );
};

export default About;
