import * as Yup from "yup";
import { parsePhoneNumber, ParseError } from "libphonenumber-js";
import { emailRegex } from "../../util";

export const SignInSchema = Yup.object().shape({
  userAlias: Yup.string()
    .test("userAlias", "Ingresa un email o número de celular válido", function (
      value
    ) {
      if (!value || value.length < 3) return false;
      let isValidPhone = false;
      try {
        const phoneNumber = parsePhoneNumber(value, "MX");
        isValidPhone = phoneNumber.isValid();
      } catch (error) {
        if (error instanceof ParseError) {
          // Not a phone number, non-existent country, etc.
          console.log(error.message);
        } else {
          throw error;
        }
      }

      const isValidEmail = emailRegex.test(value);
      return isValidPhone || isValidEmail;
    })
    .required("Ingresa un email o número de teléfono válido"),
});
