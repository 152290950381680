import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddCircle";
import { useHistory, useParams } from "react-router-dom";
import Img from "react-image";
import VisibilitySensor from "react-visibility-sensor";
import Skeleton from "@material-ui/lab/Skeleton";

import {
  toCurrency,
  JSONParse,
  getPictureUrl,
  handleAvailability,
  handleProductPrice,
} from "../util";
import useReducer from "../reducers";
import Photo from "./Photo";
import PromoLabel from "./PromoLabel";
import useTranslate from "../hooks/useTranslate";
// import TranslateText from "./TranslateText";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    height: 168,
    position: "relative",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 168,
  },
  controls: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
  lineClamp: {
    lineHeight: 1.35,
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
  icon: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "center",
  },
}));

const Translation = ({ item, field }) => {
  const text = useTranslate(item[field], item.i18n, field);
  return text || null;
};

const ProductCard = ({
  index,
  product = {},
  delivery = false,
  fromSite = false,
  ...other
}) => {
  let { restaurantId } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const picture = product && product.picture ? JSONParse(product.picture) : {};
  const isPicArray = picture ? Array.isArray(picture) : false;
  const imageUrl =
    product && product.picture
      ? isPicArray
        ? getPictureUrl({ ...picture[0], size: "0x271" })
        : getPictureUrl({ ...picture, size: "0x271" })
      : null;
  const [
    {
      deliveryReducer: {
        selectedRestaurant: { currency: restCurrency },
      },
      restaurantReducer: {
        currentRestaurant: { currency },
      },
    },
  ] = useReducer();

  const selectedCurrency = currency || restCurrency;

  // Check if product is available (hours)
  const isProductAvailable = React.useMemo(() => handleAvailability(product), [
    product,
  ]);

  const productPrice = React.useMemo(
    () => handleProductPrice(product, delivery),
    [product, delivery]
  );

  const handleClickCard = () => {
    if (product.status === "ACTIVE" && isProductAvailable) {
      if (!delivery) {
        history.push(`/t/product/${product.id}`);
      } else {
        history.push(
          fromSite
            ? `/d/product/${product.id}`
            : `/d/r/${restaurantId}/product/${product.id}`
        );
      }
    }
  };

  if (!product || product._deleted || product.status !== "ACTIVE") {
    return null;
  }

  return (
    // <Grow
    //   in
    //   style={{ transformOrigin: "0 0 0" }}
    //   {...{ timeout: (index >= 20 ? 0 : index + 1) * 250 }}
    // >
    <Grid item xs={12} {...other}>
      <div style={{ position: "relative" }}>
        {product && product.compare && product.compare > productPrice ? (
          <PromoLabel
            label={`- ${Math.abs(
              (productPrice / product.compare - 1) * 100
            ).toFixed(0)}%`}
          />
        ) : null}
        <Card
          className={classes.card}
          onClick={handleClickCard}
          style={
            product.status !== "ACTIVE" || !isProductAvailable
              ? { opacity: 0.34 }
              : {}
          }
        >
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <Typography
                component="h6"
                variant="subtitle1"
                className={classes.lineClamp}
              >
                <Translation item={product} field="title" />
              </Typography>

              <Typography
                variant="body2"
                color="textSecondary"
                className={classes.lineClamp}
              >
                <Translation item={product} field="description" />
              </Typography>
            </CardContent>
            <div className={classes.controls}>
              {product && product.compare && product.compare > productPrice ? (
                <Typography
                  variant="subtitle2"
                  color="secondary"
                  style={{ textDecoration: "line-through", marginBottom: -8 }}
                >
                  {toCurrency((product.compare || 0).toString())}
                </Typography>
              ) : null}
              <Typography variant="h6">
                {productPrice
                  ? `${selectedCurrency} ${toCurrency(
                      (productPrice || 0).toString()
                    )}`
                  : null}
              </Typography>
            </div>
          </div>

          <CardMedia
            onContextMenu={() => false}
            className={classes.cover}
            title={product.title}
          >
            <VisibilitySensor>
              <Img
                src={[imageUrl]}
                alt={product.title}
                loader={
                  <Skeleton
                    variant="rect"
                    animation="wave"
                    width={168}
                    height={168}
                  />
                }
                unloader={<Photo />}
                className={classes.image}
              />
            </VisibilitySensor>
          </CardMedia>
          <IconButton
            aria-label={`info about product`}
            className={classes.icon}
            color="secondary"
            onClick={(event) => {
              event.stopPropagation();
              handleClickCard();
            }}
          >
            <AddIcon />
          </IconButton>
        </Card>
      </div>
    </Grid>
    // </Grow>
  );
};

export default ProductCard;
