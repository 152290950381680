import React from "react";
import moment from "moment";
import { Cache, I18n } from "aws-amplify";
import _omit from "lodash/omit";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";

import useReducer from "../../reducers";
import { Typography, Button, Box, DialogContent } from "@material-ui/core";

const useStyles = makeStyles({
  dialog: {
    width: 300,
  },
});

const ClearCartDialog = () => {
  const classes = useStyles();
  const [
    {
      deliveryReducer: { openClearCartDialog, newProduct, newCartRestaurant },
    },
    dispatch,
  ] = useReducer();

  const handleClearCart = () => {
    dispatch({ type: "DELIVERY_CLEAR_CART" });
    Cache.setItem(`deliveryCart`, [newProduct], {
      priority: 1,
      expires: moment().add(1, "month").endOf("day").valueOf(),
    });

    Cache.setItem("cartCompany", newCartRestaurant.company, {
      priority: 1,
      expires: moment().add(1, "month").endOf("day").valueOf(),
    });
    Cache.setItem(
      `cartRestaurant`,
      _omit(newCartRestaurant, [
        "tables",
        "menus",
        "categories",
        "modifiers",
        "products",
      ]),
      {
        priority: 1,
        expires: moment().add(1, "month").endOf("day").valueOf(),
      }
    );
    dispatch({
      type: "DELIVERY_SET_CART",
      payload: { deliveryCart: [newProduct] },
    });
    dispatch({
      type: "DELIVERY_SET_CART_COMPANY",
      payload: { cartCompany: newCartRestaurant.company },
    });
    dispatch({
      type: "DELIVERY_SET_CART_RESTAURANT",
      payload: { cartRestaurant: newCartRestaurant },
    });
    dispatch({
      type: "DELIVERY_SET_PRINCIPAL_RESTAURANT",
      payload: { principalRestaurant: newCartRestaurant },
    });
    dispatch({
      type: "DELIVERY_CLEAR_CART_NEW_ITEM",
      payload: {
        newProduct: null,
        newCartRestaurant: null,
      },
    });
    dispatch({
      type: "DELIVERY_TOGGLE_CLEAR_CART_DIALOG",
      payload: { open: false },
    });
  };

  return (
    <Dialog
      container={() => document.getElementById("mainBox")}
      onClose={() =>
        dispatch({
          type: "DELIVERY_TOGGLE_CLEAR_CART_DIALOG",
          payload: { open: false },
        })
      }
      aria-labelledby="simple-dialog-title"
      open={openClearCartDialog}
      classes={{ paper: classes.dialog }}
      style={{
        position: "absolute",
      }}
      BackdropProps={{
        style: {
          position: "absolute",
        },
      }}
    >
      <DialogTitle id="simple-dialog-title">
        {I18n.get("Solo es posible agregar productos de un menú a la vez")}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" style={{ marginTop: -8, marginBottom: 16 }}>
          Elimina el contenido del carrito si aún quieres pedir este producto
        </Typography>

        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <Button onClick={handleClearCart}>Vaciar carrito y agregar</Button>
          <Button
            onClick={() =>
              dispatch({
                type: "DELIVERY_TOGGLE_CLEAR_CART_DIALOG",
                payload: { open: false },
              })
            }
          >
            Cancelar
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ClearCartDialog;
