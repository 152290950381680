import React from "react";
import InputMask from "react-input-mask";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
// import { Auth } from "aws-amplify";
import { useFormik } from "formik";
import { makeStyles } from "@material-ui/core";

import * as AuthService from "../../lib/auth.service";
import { SecretCodeSchema } from "./SecretCodeSchema";
import useReducer from "../../reducers";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1, 0),
  },
  label: {
    textAlign: "left !important",
  },
  formButton: {
    margin: `${theme.spacing(1, 0, 0, 1)} !important`,
  },
}));

function TextMaskCustom(props) {
  const { ...other } = props;

  return (
    <InputMask
      {...other}
      mask="99-99-99"
      maskChar=""
      style={{ textAlign: "center" }}
    />
  );
}

const SecretCodeForm = () => {
  const classes = useStyles();
  const inputRef = React.useRef(null);
  const [, dispatch] = useReducer();

  React.useEffect(() => {
    inputRef.current.focus();
  }, []);

  const onSubmit = async (values, actions) => {
    const parsedCode = values.secretCode.split("-").join("");
    const isAuthenticated = await AuthService.answerCustomChallenge(
      parsedCode,
      () => dispatch({ type: "LOGIN_SET_VIEW", payload: { view: "signin" } })
    );

    if (isAuthenticated) {
      //   const userInfo = await Auth.currentUserInfo()
      //   try {
      //     await client.mutate({
      //       mutation: CreateUser,
      //       variables: {
      //         attributes: {
      //           name: userInfo.attributes.name,
      //           email: userInfo.attributes.email,
      //           phone_number: userInfo.attributes.phone_number,
      //         },
      //       },
      //     })
      //   } catch (err) {
      //     // eslint-disable-next-line array-callback-return
      //     err.graphQLErrors.map(({ message, locations, path }) => {
      //       console.log(`Message: ${message} Path: ${path}`)
      //     })
      //   }

      dispatch({ type: "LOGIN_CLOSE" });
    } else {
      actions.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      secretCode: "",
    },
    validationSchema: SecretCodeSchema,
    onSubmit,
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        inputRef={inputRef}
        fullWidth
        margin="normal"
        className={classes.margin}
        label="Ingresa el código que te enviamos"
        variant="outlined"
        name="secretCode"
        id="secretCode"
        autoComplete="off"
        autoCapitalize="off"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.secretCode}
        error={!!(formik.touched.secretCode && formik.errors.secretCode)}
        helperText={
          (formik.touched.secretCode && formik.errors.secretCode) ||
          (formik.status && formik.status.msg) ||
          "Ingresa el código que enviamos a tu email o celular"
        }
        InputProps={{
          inputComponent: TextMaskCustom,
        }}
        InputLabelProps={{
          className: classes.label,
        }}
      />

      <Button
        type="submit"
        variant="contained"
        color="primary"
        size="large"
        disabled={formik.isSubmitting}
        fullWidth
      >
        Ingresar
      </Button>
    </form>
  );
};

export default SecretCodeForm;
