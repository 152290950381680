import React from "react";
import moment from "moment";
import { Cache } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import { I18n } from "aws-amplify";
import { Typography, Button, Box, DialogContent } from "@material-ui/core";

import useReducer from "../../reducers";

const useStyles = makeStyles({
  dialog: {
    width: 300,
  },
});

const OutOfTimeDialog = () => {
  const classes = useStyles();
  const [
    {
      deliveryReducer: { openOutOfTimeDialog },
    },
    dispatch,
  ] = useReducer();

  const handleRemoveItems = () => {
    Cache.setItem(`deliveryCart`, [], {
      priority: 1,
      expires: moment().add(1, "month").endOf("day").valueOf(),
    });
    dispatch({ type: "DELIVERY_SET_CART", payload: { deliveryCart: [] } });

    dispatch({
      type: "DELIVERY_TOGGLE_CHECKOUT",
      payload: { openCheckout: false },
    });

    dispatch({
      type: "DELIVERY_TOGGLE_OUT_OF_TIME_DIALOG",
      payload: { open: false },
    });
  };

  return (
    <Dialog
      container={() => document.getElementById("mainBox")}
      onClose={() =>
        dispatch({
          type: "DELIVERY_TOGGLE_OUT_OF_TIME_DIALOG",
          payload: { open: false },
        })
      }
      aria-labelledby="simple-dialog-title"
      open={openOutOfTimeDialog}
      classes={{ paper: classes.dialog }}
      style={{
        position: "absolute",
      }}
      BackdropProps={{
        style: {
          position: "absolute",
        },
      }}
    >
      <DialogTitle id="simple-dialog-title">
        {I18n.get("El comercio de los productos en tu carrito ya esta cerrado")}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" style={{ marginTop: -8, marginBottom: 16 }}>
          Puedes eliminar los productos no disponibles para continuar con tu
          pedido con otro comercio
        </Typography>

        <Box display="flex" flexDirection="column" alignItems="flex-end">
          <Button style={{ textAlign: "right" }} onClick={handleRemoveItems}>
            Quitar productos no disponibles
          </Button>
          <Button
            style={{ textAlign: "right" }}
            onClick={() =>
              dispatch({
                type: "DELIVERY_TOGGLE_OUT_OF_TIME_DIALOG",
                payload: { open: false },
              })
            }
          >
            Cancelar
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default OutOfTimeDialog;
