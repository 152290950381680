import React from "react";
import { I18n } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import SvgIcon from "@material-ui/core/SvgIcon";

import * as serviceWorker from "../serviceWorker";
import { ReactComponent as UpgradeIcon } from "../svg/undraw_upgrade.svg";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  close: {
    padding: theme.spacing(0.5),
  },
  root: {
    boxShadow: "0 16px 40px -12.125px rgba(0,0,0,0.3)",
  },
  title: {
    flex: "0 0 auto",
    margin: 0,
    padding: "16px 24px",
    textAlign: "center",
  },
  content: {
    flex: "1 1 auto",
    padding: "8px 24px",
    overflowY: "auto",
    textAlign: "center",
  },
}));

const ServiceWorkerWrapper = () => {
  const classes = useStyles();
  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] = React.useState(null);

  const onSWUpdate = (registration) => {
    console.log("sw update");
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  React.useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, []);

  const reloadPage = () => {
    // eslint-disable-next-line no-unused-expressions
    waitingWorker?.postMessage({ type: "SKIP_WAITING" });
    setShowReload(false);
    window.location.reload(true);
  };

  return (
    <Dialog
      container={() => document.getElementById("mainBox")}
      open={showReload}
      disableEscapeKeyDown
      disableBackdropClick
      onClose={() => {}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ root: classes.root }}
      style={{
        position: "absolute",
      }}
      BackdropProps={{
        style: {
          position: "absolute",
        },
      }}
    >
      <DialogTitle id="alert-dialog-title" classes={{ root: classes.title }}>
        {I18n.get("A new version is available!")}
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        <DialogContentText id="alert-dialog-description">
          {I18n.get("Our new version improves your experience.")}
        </DialogContentText>
        <SvgIcon
          component={UpgradeIcon}
          viewBox="0 0 1066 773.96"
          style={{ fontSize: 160 }}
        />

        <Box mt={1}>
          <Button fullWidth color="inherit" onClick={reloadPage}>
            {I18n.get("Update")}
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ServiceWorkerWrapper;
