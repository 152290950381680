import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Prompt, useLocation } from "react-router-dom";
import { Container } from "@material-ui/core";
import { SnackbarProvider } from "notistack";

import Loading from "./components/Loading";
import Navigator from "./components/Navigator";
import useReducer from "./reducers";
import LoginSheet from "./components/LoginSheet/LoginSheet";
// import ReviewSnackbar from "./components/Reviews/ReviewSnackbar";
import ActiveOrderBanner from "./components/Delivery/ActiveOrderBanner";
import FullscreenLoader from "./components/FullscreenLoader";
import DesktopBox from "./components/SiteMain.js/DesktopBox";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    background: theme.palette.background.default,
    position: "relative",
    width: "100%",
    maxWidth: 414,
  },
  noBox: {
    maxWidth: "100%",
  },
}));

const Main = ({ render }) => {
  const classes = useStyles();
  const prevLocation = useLocation();
  const [
    {
      siteReducer: { siteDomain },
      sheetReducer: { openSheet },
      tabsReducer: { activeTab },
      loginReducer: { openLogin },
      deliveryReducer: { openCheckout },
    },
    dispatch,
  ] = useReducer();

  const noLaylout =
    prevLocation.pathname.startsWith("/s/") ||
    prevLocation.pathname.startsWith("/c/") ||
    prevLocation.pathname.startsWith("/survey/") ||
    Boolean(prevLocation.pathname === "/main" && siteDomain);

  const noDesktopBox = prevLocation.pathname.startsWith("/survey/");

  return (
    <SnackbarProvider
      preventDuplicate
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <div
        id="mainBox"
        className={clsx(classes.mainBox, { [classes.noBox]: noDesktopBox })}
      >
        {noLaylout ? (
          render
        ) : (
          <>
            <Navigator fromSite={!!siteDomain} />
            {/* <ReviewSnackbar /> */}

            {/* <PromoTop /> */}

            <ActiveOrderBanner />

            <Container
              component="main"
              style={{
                flex: 1,
                paddingTop: 8,
                paddingBottom: 16,
                maxWidth: 414,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {render}
            </Container>
          </>
        )}

        <LoginSheet />
      </div>

      {noDesktopBox ? null : <DesktopBox />}

      <FullscreenLoader />
      <Loading />
      <Prompt
        when={true}
        message={(location, action) => {
          // console.log("loc", location);
          // console.log("action", action);
          // console.log("prev", prevLocation);
          if (action === "POP" && (openSheet || openLogin || openCheckout)) {
            if (openLogin) {
              dispatch({ type: "LOGIN_CLOSE" });
            }
            if (openSheet) {
              dispatch({ type: "SHEET_CLOSE" });
            }

            if (openCheckout) {
              dispatch({
                type: "DELIVERY_TOGGLE_CHECKOUT",
                payload: { openCheckout: false },
              });
            }

            return false;
          }

          const needsReset =
            location.pathname.startsWith("/t/menu") ||
            location.pathname === "/t";
          if (
            activeTab &&
            needsReset &&
            prevLocation.pathname !== "/checkout" &&
            !prevLocation.pathname.startsWith("/order-item")
          ) {
            dispatch({ type: "TABS_RESET" });
          }

          if (action === "PUSH" && openLogin) {
            dispatch({ type: "LOGIN_CLOSE" });
          }

          return true;
        }}
      />
    </SnackbarProvider>
  );
};

export default Main;
