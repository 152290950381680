import * as Yup from "yup";

const PaymentSchema = Yup.object().shape({
  paymentId: Yup.string().required("Valid payment ID required"),
});

const RfcSchema = Yup.object().shape({
  selectedRfc: Yup.string().required("RFC selection is required"),
});

const arr = [PaymentSchema, RfcSchema, null];

export default arr;
