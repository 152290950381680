import React from "react";
import { I18n } from "aws-amplify";
import { useHistory } from "react-router-dom";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import ShoppingBasket from "@material-ui/icons/ShoppingBasket";
import ReceiptIcon from "@material-ui/icons/Receipt";
import HomeIcon from "@material-ui/icons/Home";
import HelpIcon from "@material-ui/icons/Help";
import Badge from "@material-ui/core/Badge";

import useReducer from "../../reducers";
import { filterOrderItems } from "../../util";

export default function BottomNav() {
  const [
    {
      tabsReducer: { activeTab },
      orderReducer: { orderItems },
    },
    dispatch,
  ] = useReducer();
  const history = useHistory();

  const handleChange = (event, newValue) => {
    if (activeTab === newValue && activeTab === 0) {
      history.push("/t");
      return;
    }
    dispatch({ type: "TABS_SET_TAB", payload: { tab: newValue } });
  };

  const getPendingItems = (items) => {
    return items && items.length
      ? items.filter((c) => c.status === "PENDING")
      : [];
  };

  const getOrderedItems = (items) => {
    return items && items.length
      ? items.filter((c) =>
          ["ORDERED", "FIRED", "READY", "DELIVERED"].includes(c.status)
        )
      : [];
  };

  const filteredOrderItems = React.useMemo(() => filterOrderItems(orderItems), [
    orderItems,
  ]);
  const pendingItems = React.useMemo(
    () => getPendingItems(filteredOrderItems),
    [filteredOrderItems]
  );
  const orderedItems = React.useMemo(
    () => getOrderedItems(filteredOrderItems),
    [filteredOrderItems]
  );

  return (
    <BottomNavigation value={activeTab} onChange={handleChange} showLabels>
      <BottomNavigationAction label={I18n.get("Home")} icon={<HomeIcon />} />
      <BottomNavigationAction
        label={I18n.get("Cart")}
        icon={
          <Badge
            color="secondary"
            overlap="circle"
            badgeContent={pendingItems.length}
          >
            <ShoppingBasket fontSize="small" />
          </Badge>
        }
      />
      <BottomNavigationAction
        label={I18n.get("Order")}
        icon={
          <Badge
            color="secondary"
            overlap="circle"
            variant="dot"
            invisible={!orderedItems.length}
          >
            <ReceiptIcon fontSize="small" />
          </Badge>
        }
      />
      <BottomNavigationAction label={I18n.get("Help")} icon={<HelpIcon />} />
    </BottomNavigation>
  );
}
