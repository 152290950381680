import React from "react";
import GridList from "@material-ui/core/GridList";
// import { Box, Typography } from "@material-ui/core";
// import { I18n } from "aws-amplify";
// import useReducer from "../reducers";
import useStyles from "../components/Main/GridCard.styled";
import LoginLink from "../components/Main/LoginLink";
import GridCard from "../components/Main/GridCard";

const Main = () => {
  const classes = useStyles();
  // const [
  //   {
  //     loginReducer: {
  //       browserData: { ipInfo: { _region } = {} },
  //     },
  //   },
  // ] = useReducer();

  const tileData = [
    {
      img: "/assets/delivery.jpg",
      title: "Entrega a domicilio",
      subtitle: "Pide y recibe en tu domicilio",
      route: "/d",
      featured: false,
    },
    {
      img: "/assets/qr-scan.png",
      title: "Escanear QR",
      subtitle: "Escanea para iniciar o unirse a una orden",
      route: "/qr-scan",
      featured: false,
    },
    // {
    //   img: `${process.env.REACT_APP_CLOUDFRONT}/public/assets/0x271/bono_gastronomico.png`,
    //   title: "Bono Gastronómico",
    //   route: "/bonos",
    //   subtitle: "Apoya a tus restaurantes favoritos",
    //   featured: false,
    // },
    {
      img: "/assets/pickngo.png",
      title: "Pick & Go",
      subtitle: "Ordena y recoge en el restaurante",
      route: "/d?dt=togo",
      featured: false,
    },
    {
      img: "/assets/invoicing.png",
      title: "Facturación",
      route: "/invoicing",
      subtitle: "Generar o recuperar factura de tus consumos",
    },
  ];

  // const otherCommerces = [];

  // if (
  //   _region === "Ciudad de México" ||
  //   _region === "Estado de México" ||
  //   _region === "México" ||
  //   _region === "Mexico City"
  // ) {
  //   otherCommerces.splice(4, 0, {
  //     img: `${process.env.REACT_APP_CLOUDFRONT}/public/assets/0x271/grontify`,
  //     title: "Frutas y verduras",
  //     route: "/d/r/5d82953c-07e9-4ec1-959f-c52775b949a5",
  //     subtitle: "De la central a tu hogar",
  //   });

  //   otherCommerces.splice(5, 0, {
  //     img: `${process.env.REACT_APP_CLOUDFRONT}/public/assets/0x271/valenciana`,
  //     title: "La Valenciana",
  //     route: "/d/r/224df3d3-606a-4aaa-867a-2aaeb422f9d8",
  //     subtitle: "Distribuidora de abarrotes",
  //   });
  // }

  // if (_region === "Quintana Roo") {
  //   otherCommerces.splice(4, 0, {
  //     img: `${process.env.REACT_APP_CLOUDFRONT}/public/assets/0x271/grontify`,
  //     title: "Abasto Directo",
  //     route: "/d/r/413d72a5-acfd-4f5e-b13a-64687d277676",
  //     subtitle: "Producto de la central a tu hogar",
  //   });
  // }

  return (
    <div
      style={{
        width: "100%",
        flex: 1,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className={classes.root}>
        <GridList
          cellHeight={180}
          spacing={12}
          className={classes.gridList}
          classes={{ root: classes.listRoot }}
        >
          {tileData.map((tile, index) => {
            return (
              // <Grow
              //   in
              //   key={tile.title}
              //   style={{ transformOrigin: "0 0 0" }}
              //   {...{ timeout: (index + 1) * 300 }}
              // >
              <GridCard key={tile.title} tile={tile} />
              // </Grow>
            );
          })}
        </GridList>

        {/* {otherCommerces && otherCommerces.length ? (
          <Box mt={3} mb={1} px={0.5} textAlign="left" width="100%">
            <Typography variant="subtitle2">{I18n.get("Others")}</Typography>
          </Box>
        ) : null}

        <GridList
          spacing={8}
          cols={3}
          className={classes.gridList}
          classes={{ root: classes.listRoot }}
        >
          {otherCommerces.map((tile, index) => {
            return (
              // <Grow
              //   in
              //   key={tile.title}
              //   style={{ transformOrigin: "0 0 0" }}
              //   {...{ timeout: (index + 1) * 300 }}
              // >
              <div key={tile.title}>
                <GridCard tile={tile} clean />
                <Typography style={{ marginTop: 8 }} variant="subtitle2" noWrap>
                  {tile.title}
                </Typography>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  className={classes.lineClamp}
                >
                  {tile.subtitle}
                </Typography>
              </div>
              // </Grow>
            );
          })}
        </GridList> */}
      </div>
      <LoginLink />
    </div>
  );
};

export default Main;
