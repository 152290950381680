import React from "react";
import { Box, Button } from "@material-ui/core";
import { I18n } from "aws-amplify";
import moment from "moment";
import Barcode from "react-barcode";
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";
import { parsePhoneNumberFromString } from "libphonenumber-js";

import { toCurrency } from "../../util";

const useStyles = makeStyles((theme) => ({
  resultOxxo: {
    textAlign: "center",
    maxWidth: 414,
    height: "auto",
    margin: "0 auto",
    alignSelf: "center",
    padding: theme.spacing(2, 2),
  },
  instructions: {
    textAlign: "left",
    padding: theme.spacing(2),
  },
  barcode: {
    width: "100%",
    "& > svg": {
      width: "100%",
    },
  },
}));

const Voucher = ({ orderId, order, oxxoVoucher }) => {
  const classes = useStyles();

  return (
    <Box className={classes.resultOxxo}>
      <h1>¡Gracias! Solo falta un paso más:</h1>
      <div className={classes.instructions}>
        <div>
          Instrucciones de pago en OXXO:
          <p>
            1. Entregue el código al cajero en cualquier OXXO para que lo
            escanee.
          </p>
          <p>2. Proporcione el pago en efectivo al cajero.</p>
          <p>
            3. Una vez completado el pago, guarde el recibo de su pago para sus
            archivos.
          </p>
          <p>
            4. Para cualquier duda o aclaración, escríbanos a{" "}
            {order?.restaurant?.contactEmail || "contacto@2dine.io"} o al{" "}
            {parsePhoneNumberFromString(
              order?.restaurant?.contactPhone || "+525570105625"
            ).formatInternational()}
          </p>
        </div>
      </div>

      <div>
        <b>Monto: </b>
        <span className="order-amount">
          {((oxxoVoucher && oxxoVoucher.currency) || "").toUpperCase()} $
          {toCurrency(((oxxoVoucher && oxxoVoucher.amount) || 0).toString())}
        </span>
      </div>
      <div>
        <b>Expira: </b>
        <span className="oxxo-expiry-date">
          {moment
            .unix(oxxoVoucher && oxxoVoucher.expires_after)
            .format("DD-MMM-YYYY")}
        </span>
      </div>

      <img alt="oxxo-logo" width="150px" src="/assets/oxxo.png" />
      <div className={classes.barcode}>
        {oxxoVoucher && oxxoVoucher.number ? (
          <Barcode
            value={oxxoVoucher.number}
            width={2}
            height={50}
            fontSize={15}
          />
        ) : null}
      </div>

      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          window.print();
        }}
      >
        Guardar Voucher
      </Button>

      <Box mt={4}>
        <Button
          component={RouterLink}
          to={`/d/order/${orderId}`}
          color="secondary"
        >
          {I18n.get("Continue")}
        </Button>
      </Box>
    </Box>
  );
};

export default Voucher;
