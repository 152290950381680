import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/AddCircle";
import { useHistory } from "react-router-dom";
import Img from "react-image";
import VisibilitySensor from "react-visibility-sensor";
import Skeleton from "@material-ui/lab/Skeleton";

import {
  JSONParse,
  getPictureUrl,
  handleRestaurantIsEnabled,
} from "../../util";
import Photo from "../Photo";
import { useLocation } from "react-use";
// import { Grow } from "@material-ui/core";
import useTranslate from "../../hooks/useTranslate";
// import TranslateText from "../TranslateText";

const useStyles = makeStyles((theme) => ({
  icon: {
    color: "rgba(255, 255, 255, 0.54)",
  },
  tile: {
    borderRadius: 8,
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "center",
  },
  tileTitle: {
    overflow: "hidden",
    fontSize: "1rem",
    lineHeight: "24px",
    whiteSpace: "normal",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
  },
}));

const CategoryTileBar = ({ isRest, category, handleClickTile }) => {
  const classes = useStyles();
  const translatedTitle = useTranslate(category.title, category.i18n, "title");
  const translatedDescription = useTranslate(
    category.description,
    category.i18n,
    "description"
  );

  return (
    <GridListTileBar
      classes={{
        title: classes.tileTitle,
      }}
      title={!isRest ? translatedTitle : category.name}
      subtitle={
        category && category.description ? (
          <span>{translatedDescription}</span>
        ) : null
      }
      actionIcon={
        <IconButton
          aria-label={`info about ${!isRest ? category.title : category.name} `}
          className={classes.icon}
          onClick={(event) => {
            event.stopPropagation();
            handleClickTile();
          }}
        >
          <AddIcon />
        </IconButton>
      }
    />
  );
};

const CategoryCard = ({
  index,
  category,
  isRest,
  delivery,
  restaurantId,
  fromBond,
  fromSite,
  ...other
}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const picture = !isRest
    ? category && category.picture
      ? JSONParse(category.picture)
      : {}
    : category && category.cardPicture
    ? JSONParse(category.cardPicture)
    : {};
  const imageUrl =
    (category && category.picture) || (category && category.cardPicture)
      ? getPictureUrl({ ...picture, size: "0x271" })
      : null;

  const [isRestaurantEnabled, setIsRestaurantEnabled] = React.useState(true);
  const [tileHeight, setTileHeight] = React.useState(0);
  const tileRef = React.useRef(null);

  React.useEffect(() => {
    if (tileRef && tileRef.current) {
      setTileHeight(tileRef.current.offsetWidth);
    }
  }, []);

  React.useEffect(() => {
    if (isRest && category) {
      const { restaurantIsEnabled } = handleRestaurantIsEnabled(
        category && category.schedule
      );

      setIsRestaurantEnabled(restaurantIsEnabled);
    }
  }, [category, isRest, setIsRestaurantEnabled]);

  const handleClickTile = () => {
    if (!isRest) {
      if (!delivery) {
        history.push(`/t/category/${category.id}`);
      } else {
        history.push(
          fromSite
            ? `/d/category/${category.id}`
            : `/d/r/${restaurantId}/category/${category.id}`
        );
      }
    } else {
      if (!fromBond) {
        if (!isRestaurantEnabled) {
          return;
        }

        history.push(`/d/r/${category.id}`);
      } else {
        history.push({
          pathname: `/bono/${category.id}`,
          state: { background: location },
        });
      }
    }
  };

  const parsedStyle = React.useMemo(() => {
    const s = { ...other.style };

    if (isRest && !fromBond && !isRestaurantEnabled) {
      s["opacity"] = 0.34;
    }

    if (tileHeight) {
      s["height"] = tileHeight;
    }

    return s;
  }, [fromBond, isRest, isRestaurantEnabled, other.style, tileHeight]);

  if (!category) {
    return null;
  }

  return (
    // <Grow
    //   in
    //   style={{ transformOrigin: "0 0 0" }}
    //   {...{ timeout: (index + 1) * 300 }}
    // >
    <GridListTile
      classes={{ tile: classes.tile }}
      onClick={handleClickTile}
      {...other}
      ref={tileRef}
      style={parsedStyle}
    >
      <VisibilitySensor>
        <Img
          src={[imageUrl]}
          alt={!isRest ? category.title : category.name}
          loader={
            <Skeleton
              variant="rect"
              animation="wave"
              width={"100%"}
              height={"100%"}
            />
          }
          unloader={<Photo />}
          className={classes.image}
        />
      </VisibilitySensor>
      <CategoryTileBar
        handleClickTile={handleClickTile}
        category={category}
        isRest={isRest}
      />
    </GridListTile>
    // </Grow>
  );
};

export default CategoryCard;
