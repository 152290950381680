import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import useReducer from "../../reducers";
import { I18n } from "aws-amplify";
import { Box, Button } from "@material-ui/core";

import OrderTypeDialog from "./OrderTypeDialog";
import DeliveryCheckoutItems from "./DeliveryCheckoutItems";
import { green } from "@material-ui/core/colors";
import DeliveryCheckoutNext from "./DeliveryCheckoutNext";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 0,
    paddingTop: 15,
    paddingBottom: 15,
    height: 56,
    minHeight: 56,
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}));

const DeliveryCheckout = ({ fromSite = false }) => {
  const classes = useStyles();
  const [
    {
      deliveryReducer: { cartRestaurant, selectedRestaurant },
      loadingReducer: { loading },
    },
    dispatch,
  ] = useReducer();
  const [activeStep, setActiveStep] = React.useState(0);
  const { cardPayments } = selectedRestaurant || cartRestaurant || {};

  React.useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (!cardPayments) {
        dispatch({
          type: "DELIVERY_SET_PAYMENT_METHOD",
          payload: { paymentMethod: "cash" },
        });
      } else {
        dispatch({
          type: "DELIVERY_SET_PAYMENT_METHOD",
          payload: { paymentMethod: "card" },
        });
      }
    }

    return () => {
      mounted = false;
    };
  }, [cardPayments]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getStepContent = (step) => {
    if (step === 1) {
      return <DeliveryCheckoutNext handleBack={handleBack} />;
    }

    return (
      <>
        <Box flex={1} dispatch="flex" flexDirection="column">
          <List>
            <OrderTypeDialog />
            <Divider />
          </List>

          <DeliveryCheckoutItems fromSite={fromSite} />
        </Box>
        <Button
          size="large"
          variant="contained"
          fullWidth
          disabled={loading}
          className={classes.button}
          onClick={handleNext}
        >
          {I18n.get("Continue")}
        </Button>
      </>
    );
  };

  return (
    <>
      <Box mt={1} flex={1} display="flex" flexDirection="column">
        {getStepContent(activeStep)}
      </Box>
    </>
  );
};

export default DeliveryCheckout;
