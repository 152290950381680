import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { Typography, Link, List } from "@material-ui/core";
import { NavLink } from "react-router-dom";

const AcceptTerms = ({ accepted, handleAccept = () => {} }) => {
  return (
    <List>
      <ListItem role={undefined} dense button onClick={handleAccept}>
        <ListItemIcon>
          <Checkbox edge="start" checked={accepted} disableRipple />
        </ListItemIcon>
        <ListItemText
          primary="I accept terms and conditions of service."
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                style={{
                  display: "inline",
                }}
                color="textPrimary"
              >
                You can consult the complete document by
              </Typography>{" "}
              <Link
                color={"textSecondary"}
                component={NavLink}
                to={"/contrato-ayv"}
                target="_blank"
              >
                clicking here
              </Link>
            </React.Fragment>
          }
        />
      </ListItem>
    </List>
  );
};

export default AcceptTerms;
