import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  fullList: {
    width: 'auto'
  },
  title: {
    fontWeight: 700
  },
  ordered: {
    backgroundColor: '#e3f2fd',
    color: '#2196f3'
  },
  delivered: {
    backgroundColor: '#e8f5e9',
    color: '#4caf50'
  }
}));

const OrderItemStatusComponent = ({ status = 'PENDING' }) => {
  const classes = useStyles();
  return (
    <Chip
      size="small"
      color="primary"
      label={status}
      classes={{
        colorPrimary: status === 'ORDERED' ? classes.ordered : classes.delivered
      }}
    />
  );
};

export default OrderItemStatusComponent;
