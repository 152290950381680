import React from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, Button, Grid, Paper } from "@material-ui/core";
import { I18n } from "aws-amplify";
import { useParams, useHistory } from "react-router-dom";
import Img from "react-image";
import Skeleton from "@material-ui/lab/Skeleton";
import { API, graphqlOperation } from "aws-amplify";
import { useSnackbar } from "notistack";

import Photo from "../../components/Photo";
import * as queries from "../../graphql/queries";
import {
  isEmptyObject,
  getSelectedModifiersString,
  filterOrderItems,
} from "../../util";
import Loading from "../../components/Loading";

const useStyles = makeStyles((theme) => ({
  image: {
    height: 64,
    width: 64,
    objectFit: "contain",
    objectPosition: "center",
    marginRight: 16,
  },
}));

const Coupon = () => {
  const [loading, setLoading] = React.useState(true);
  const [order, setOrder] = React.useState();
  const classes = useStyles();
  let { orderId } = useParams();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  React.useEffect(() => {
    let mounted = true;

    if (!orderId) {
      history.replace("/d");
    }

    const fetchData = async () => {
      const { data } = await API.graphql({
        ...graphqlOperation(queries.getOrder, {
          id: orderId,
        }),
        authMode: "AWS_IAM",
      });
      const { getOrder: response = {} } = data || {};
      console.log("getOrder response", response);

      if (!response || isEmptyObject(response)) {
        enqueueSnackbar(I18n.get("Order not found!"), {
          variant: "info",
          autoHideDuration: 3000,
        });

        history.replace("/d");
      }

      if (mounted) {
        setOrder(response);
        setLoading(false);
      }
    };

    fetchData();
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const { isPaid } = order || {};

  if (loading) {
    return <Loading force />;
  }

  return (
    <>
      <Box mt={1} mb={1} display="flex" alignItems="center">
        <Img
          src={["/abona_viaja.png"]}
          alt={"Abona y viaja"}
          loader={
            <Skeleton
              variant="rect"
              animation="wave"
              width={"64px"}
              height={"64px"}
            />
          }
          unloader={<Photo />}
          className={classes.image}
        />
        <Typography variant="h5" component={"h1"}>
          {!isPaid
            ? "Confirmación de Reserva NO PAGADA"
            : I18n.get("Voucher de Reserva PAGADA")}
        </Typography>
      </Box>

      <Box mb={2}>
        {!isPaid ? (
          <Typography variant="subtitle1" color="textSecondary">
            Valida únicamente como referencia para el pago
          </Typography>
        ) : null}
      </Box>

      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ fontWeight: "700" }}>
            Fecha
          </Typography>
          <Typography>
            {moment(order.createdAt).format("DD/MM/YYYY HH:mm")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ fontWeight: "700" }}>
            Titular
          </Typography>
          <Typography>{order.receiverName}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ fontWeight: "700" }}>
            ID
          </Typography>
          <Typography>{order.id}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" style={{ fontWeight: "700" }}>
            Serie
          </Typography>
          <Typography>{order.orderSerie}</Typography>
        </Grid>
      </Grid>

      <Typography variant="h6" color="primary">
        Detalle del servicio
      </Typography>
      {filterOrderItems(order.items.items).map((item) => {
        const parsedTravelData = item.travelData
          ? JSON.parse(item.travelData)
          : {};
        return (
          <Paper style={{ padding: 8 }} key={item.id}>
            <Grid container spacing={1}>
              {!isPaid ? (
                <Grid item xs={12}>
                  <Typography variant="h5" style={{ fontWeight: "700" }}>
                    Fecha límite de pago
                  </Typography>
                  <Typography variant="h5">
                    {moment(parsedTravelData.paydayLimit).format("DD/MM/YYYY")}
                  </Typography>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <Typography variant="h5" style={{ fontWeight: "700" }}>
                  Clave de la reservación
                </Typography>
                <Typography variant="h5">
                  {parsedTravelData.reservationKey || "No disponible"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ fontWeight: "700" }}>
                  Producto
                </Typography>
                <Typography>{item.title}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ fontWeight: "700" }}>
                  Noches o cantidad
                </Typography>
                <Typography>{item.qty}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ fontWeight: "700" }}>
                  Fecha de inicio
                </Typography>
                <Typography>
                  {parsedTravelData && parsedTravelData.startDate
                    ? moment(parsedTravelData.startDate).format("DD/MM/YYYY")
                    : "Sin datos"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ fontWeight: "700" }}>
                  Fecha de salida
                </Typography>
                <Typography>
                  {parsedTravelData && parsedTravelData.startDate
                    ? moment(parsedTravelData.startDate)
                        .add(item.qty, "days")
                        .format("DD/MM/YYYY")
                    : "Sin datos"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ fontWeight: "700" }}>
                  Selección
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  {getSelectedModifiersString(item.modifiers)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" style={{ fontWeight: "700" }}>
                  Notas
                </Typography>
                <Typography color="textSecondary" variant="body2">
                  {item.notes}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        );
      })}

      <Box mt={2}>
        <Typography variant="h6" color="primary">
          Información del titular
        </Typography>

        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="subtitle1" style={{ fontWeight: "700" }}>
              Nombre
            </Typography>
            <Typography>{order.receiverName}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" style={{ fontWeight: "700" }}>
              Teléfono
            </Typography>
            <Typography>{order.receiverPhone}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" style={{ fontWeight: "700" }}>
              Email
            </Typography>
            <Typography>{order.receiverEmail}</Typography>
          </Grid>
        </Grid>
      </Box>

      <Box mt={3}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => {
            window.print();
          }}
        >
          Guardar Voucher
        </Button>
      </Box>
    </>
  );
};

export default Coupon;
