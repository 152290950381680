import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import RoomIcon from "@material-ui/icons/Room";

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    width: 18,
    height: 18,
    borderRadius: "100%",
    userSelect: "none",
    transform: "translate(-50%, -50%)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));

const Marker = ({ ...rest }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper} alt={rest.text}>
      <RoomIcon fontSize="default" color="primary" />
    </div>
  );
};

export default Marker;
