import React from "react";
import { I18n } from "aws-amplify";
import { Box, Typography } from "@material-ui/core";
import SvgIcon from "@material-ui/core/SvgIcon";

import { ReactComponent as VoidSvg } from "../svg/undraw_void.svg";

const NoData = () => {
  return (
    <Box pt={5} textAlign="center">
      <SvgIcon
        component={VoidSvg}
        viewBox="0 0 797.5 834.5"
        style={{ fontSize: 240 }}
      />
      <Typography style={{ marginTop: 16 }} color="primary" variant="h5">
        {I18n.get("No data to show")}
      </Typography>
    </Box>
  );
};

export default NoData;
