import React from "react";
import { Auth, Cache } from "aws-amplify";
import Fab from "@material-ui/core/Fab";
import FacebookIcon from "@material-ui/icons/Facebook";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  margin: {
    marginRight: theme.spacing(5),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    width: "140px !important",
  },
}));

const SignInWithFacebook = () => {
  const classes = useStyles();
  const location = useLocation();

  const handleSignIn = () => {
    const fb = window.FB;
    Cache.setItem("fromUrl", location);

    fb.getLoginStatus((response) => {
      if (response.status === "connected") {
        getAWSCredentials(response.authResponse);
      } else {
        fb.login(
          (response) => {
            if (!response || !response.authResponse) {
              return;
            }
            getAWSCredentials(response.authResponse);
          },
          {
            // the authorized scopes
            scope: "public_profile,email",
          }
        );
      }
    });
  };

  const getAWSCredentials = (response) => {
    const { accessToken, expiresIn } = response;
    const date = new Date();
    const expires_at = expiresIn * 1000 + date.getTime();
    if (!accessToken) {
      return;
    }

    const fb = window.FB;
    fb.api("/me", { fields: "id,name,email,picture" }, (response) => {
      console.log("/me response", response);
      const user = {
        pid: response.id,
        name: response.name,
        email: response.email,
        picture: response.picture,
        provider: "facebook",
      };

      Auth.federatedSignIn("facebook", { token: accessToken, expires_at }, user)
        .then((credentials) => {
          console.log("credentials", credentials);
          return Auth.currentAuthenticatedUser();
        })
        .then((user) => {
          // If success, the user object you passed in Auth.federatedSignIn
          console.log(user);
        })
        .catch((e) => {
          console.log(e);
        });
    });
  };

  return (
    <Fab
      variant="extended"
      size="medium"
      color="secondary"
      aria-label="add"
      classes={{ sizeMedium: classes.fab }}
      className={classes.margin}
      onClick={handleSignIn}
    >
      <FacebookIcon className={classes.extendedIcon} />
      Facebook
    </Fab>
  );
};

export default SignInWithFacebook;
