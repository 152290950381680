import React from "react";
import ReactDOM from "react-dom";
import usePaypal from "../../hooks/usePaypal";
import useReducer from "../../reducers";
import { isEmptyObject } from "../../util";

const PaypalButton = ({ amount, currency = "MXN", afterApprove }) => {
  const [
    {
      deliveryReducer: { selectedRestaurant, cartRestaurant },
      restaurantReducer: { currentRestaurant },
    },
    dispatch,
  ] = useReducer();

  const defaultRest = {
    paypalAccount: process.env.REACT_APP_PAYPAL_CLIENT_ID,
  };
  const { paypalAccount } =
    cartRestaurant && !isEmptyObject(cartRestaurant)
      ? cartRestaurant || defaultRest
      : selectedRestaurant && !isEmptyObject(selectedRestaurant)
      ? selectedRestaurant || defaultRest
      : currentRestaurant || defaultRest;
  const paypalId = paypalAccount || process.env.REACT_APP_PAYPAL_CLIENT_ID;
  const paypal = usePaypal(currency, paypalId);

  const createOrder = (data, actions) => {
    return actions.order.create({
      intent: "CAPTURE",
      purchase_units: [
        {
          amount: {
            currency_code: currency,
            value: (amount / 100).toFixed(2),
          },
        },
      ],
    });
  };

  const onApprove = async (data, actions) => {
    dispatch({ type: "LOADING_START" });
    dispatch({
      type: "LOADING_SET_MESSAGE",
      payload: { message: "Comprobando pago con Paypal" },
    });
    console.log("data", data, actions);
    const details = await actions.order.capture();
    console.log("details", details);
    await afterApprove(true, details);
    dispatch({ type: "LOADING_STOP" });
  };

  if (!paypal) {
    return null;
  }

  const PPButton = paypal.Buttons.driver("react", { React, ReactDOM });

  return (
    <PPButton
      createOrder={(data, actions) => createOrder(data, actions)}
      onApprove={(data, actions) => onApprove(data, actions)}
    />
  );
};

export default PaypalButton;
