import React from "react";
import { parsePhoneNumber } from "libphonenumber-js";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import ArrowForward from "@material-ui/icons/ArrowForward";
import { useFormik } from "formik";
import { makeStyles } from "@material-ui/core";

import * as AuthService from "../../lib/auth.service";
import { SignInSchema } from "./SignInSchema";
import useReducer from "../../reducers";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1, 0),
  },
  label: {
    textAlign: "left !important",
  },
}));

const SignInForm = () => {
  const classes = useStyles();
  const [, dispatch] = useReducer();

  const onSubmit = async (values, actions) => {
    const obj = AuthService.getUserAttribute(values.userAlias);
    const username = obj.phone_number
      ? parsePhoneNumber(obj.phone_number, "MX").number
      : obj.email;

    try {
      await AuthService.signIn(username);
      dispatch({ type: "LOGIN_SET_VIEW", payload: { view: "secret_code" } });
    } catch (err) {
      handleError(err);
      actions.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      userAlias: "",
    },
    validationSchema: SignInSchema,
    onSubmit,
  });

  const handleError = (err) => {
    console.log(err);

    switch (err.code) {
      case "UserNotFoundException":
        dispatch({ type: "LOGIN_SET_VIEW", payload: { view: "signup" } });
        return;
      case "MessageRejected":
        dispatch({ type: "LOGIN_SET_VIEW", payload: { view: "confirm" } });
        return;

      default:
        break;
    }
  };

  return (
    <form id="signinForm" onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        margin="normal"
        className={classes.margin}
        label="Ingresa tu email o número de celular"
        variant="outlined"
        id="userAlias"
        type="text"
        name="userAlias"
        autoComplete="off"
        autoCapitalize="off"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.userAlias}
        error={!!(formik.touched.userAlias && formik.errors.userAlias)}
        helperText={
          (formik.touched.userAlias && formik.errors.userAlias) ||
          (formik.status && formik.status.msg) ||
          "Ingresa tu email o celular válido"
        }
        InputLabelProps={{
          className: classes.label,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Submit"
                type="submit"
                disabled={formik.isSubmitting}
                color="primary"
              >
                <ArrowForward />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </form>
  );
};

export default SignInForm;
