import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
// import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from "@material-ui/core/ListSubheader";
import { Typography, Divider } from "@material-ui/core";
import SimplifiedSection from "./SimplifiedSection";
import { isEmptyObject } from "../../util";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    // maxHeight: 300,
    marginBottom: theme.spacing(8),
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
  subheader: {
    background: theme.palette.background.default,
  },
}));

export default function SimplifiedView({
  menu,
  category,
  delivery,
  fromSite,
  restaurantId,
}) {
  const classes = useStyles();
  const fromCategory = !!(category && !isEmptyObject(category));
  const items =
    (fromCategory ? category?.subcategories?.items : menu?.categories?.items) ||
    [];
  console.log("items", fromCategory, items);

  const menuCategories = React.useMemo(() => {
    return items
      .filter(
        (x) =>
          !x._deleted &&
          !x?.[fromCategory ? "subcategory" : "category"]?._deleted &&
          x?.[fromCategory ? "subcategory" : "category"]?.status === "ACTIVE"
      )
      .map((x) => x[fromCategory ? "subcategory" : "category"]);
  }, [fromCategory, items]);

  console.log("menuCategories", menuCategories);

  return (
    <List
      className={classes.root}
      subheader={<li />}
      style={{ background: "transparent", overflow: "visible" }}
    >
      {(menuCategories || []).map((category) => (
        <li key={`section-${category.id}`} className={classes.listSection}>
          <ul className={classes.ul}>
            <ListSubheader id={category.id} className={classes.subheader}>
              <Typography
                variant="h5"
                style={{ padding: "8px 0", fontWeight: 700 }}
              >{`${category.title}`}</Typography>
              <Divider />
            </ListSubheader>
            <SimplifiedSection
              restaurantId={restaurantId}
              categoryId={category.id}
              delivery={delivery}
              fromSite={fromSite}
            />
          </ul>
        </li>
      ))}
    </List>
  );
}
