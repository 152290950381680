const businessLanguage = {
  RESTAURANT: {
    Menus: "Menus",
    Table: "Table",
    Menu: "Menu",
    "Scan Table": "Scan Table",
    "Scan or type your table token": "Scan or type your table token",
    "Table token": "Table token",
    "Add the table uid": "Add the table uid",
    "Table guests": "Table guests",
    "Select a menu": "Select a menu",
    "Choose the menu you want to see": "Choose the menu you want to see",
    "Complete menu": "Complete menu",
    "No restaurant found, ask for help.": "No restaurant found, ask for help.",
    Quantity: "Quantity",
    "No table found, ask for help.": "No table found, ask for help.",
    "Ok, your waiter is comming! Wait a minute please.":
      "Ok, your waiter is comming! Wait a minute please.",
    "Select your products": "Select your products",
    "Confirm & send your order": "Confirm & send your order",
    "Add & send more products anytime": "Add & send more products anytime",
    "Enjoy your order!": "Enjoy your order!",
    "Checkout & pay": "Checkout & pay",
    Dashboard: "Dashboard",
  },
  GOURMET_MARKET: {
    Menus: "Menus",
    Table: "Table",
    Menu: "Menu",
    "Scan Table": "Scan Table",
    "Scan or type your table token": "Scan or type your table token",
    "Table token": "Table token",
    "Add the table uid": "Add the table uid",
    "Table guests": "Table guests",
    "Select a menu": "Select a menu",
    "Choose the menu you want to see": "Choose the menu you want to see",
    "Complete menu": "Complete menu",
    "No restaurant found, ask for help.": "No restaurant found, ask for help.",
    Quantity: "Quantity",
    "No table found, ask for help.": "No table found, ask for help.",
    "Ok, your waiter is comming! Wait a minute please.":
      "Ok, your waiter is comming! Wait a minute please.",
    "Select your products": "Select your products",
    "Confirm & send your order": "Confirm & send your order",
    "Add & send more products anytime": "Add & send more products anytime",
    "Enjoy your order!": "Enjoy your order!",
    "Checkout & pay": "Checkout & pay",
    Dashboard: "Dashboard",
  },
  HOTEL: {
    Menus: "Catalogs",
    Menu: "Catalog",
    "Scan Table": "Scan QR Code",
    "Scan or type your table token": "Scan or type your QR Code",
    "Table token": "QR Code",
    "Add the table uid": "Add the code UID",
    "Table guests": "Guests",
    Table: "Location",
    "Select a menu": "Select a catalog",
    "Choose the menu you want to see": "Choose the catalog you want to see",
    "Complete menu": "All catalogs",
    "No restaurant found, ask for help.": "No hotel code found, ask for help.",
    Quantity: "Quantity",
    "No table found, ask for help.": "No location found, ask for help.",
    "Ok, your waiter is comming! Wait a minute please.":
      "Ok, your concierge is comming! Wait a moment please.",
    "Select your products": "Select your products",
    "Confirm & send your order": "Confirm & send your order",
    "Add & send more products anytime": "Add & send more products anytime",
    "Enjoy your order!": "Enjoy your order!",
    "Checkout & pay": "Checkout & pay",
    Dashboard: "Dashboard",
  },
  TRAVEL: {
    Menus: "Catalogs",
    Menu: "Catalog",
    "Scan Table": "Scan QR Code",
    "Scan or type your table token": "Scan or type your QR Code",
    "Table token": "QR Code",
    "Add the table uid": "Add the code UID",
    "Table guests": "Guests",
    Table: "Location",
    "Select a menu": "Select a catalog",
    "Choose the menu you want to see": "Choose the catalog you want to see",
    "Complete menu": "All catalogs",
    "No restaurant found, ask for help.": "No code found, ask for help.",
    Quantity: "Nights",
    "No table found, ask for help.": "No location found, ask for help.",
    "Ok, your waiter is comming! Wait a minute please.":
      "Ok, your waiter is comming! Wait a minute please.",
    "Select your products": "Select your products",
    "Confirm & send your order": "Confirm & send your order",
    "Add & send more products anytime": "Add & send more products anytime",
    "Enjoy your order!": "Enjoy your order!",
    "Checkout & pay": "Checkout & pay",
    Dashboard: "Dashboard",
  },
  MARKET: {
    Menus: "Catalogs",
    Menu: "Catalog",
    "Scan Table": "Scan QR Code",
    "Scan or type your table token": "Scan or type your QR Code",
    "Table token": "QR Code",
    "Add the table uid": "Add the code UID",
    "Table guests": "Guests",
    Table: "Location",
    "Select a menu": "Select a catalog",
    "Choose the menu you want to see": "Choose the catalog you want to see",
    "Complete menu": "All catalogs",
    "No restaurant found, ask for help.": "No code found, ask for help.",
    Quantity: "Quantity",
    "No table found, ask for help.": "No location found, ask for help.",
    "Ok, your waiter is comming! Wait a minute please.":
      "Ok, your waiter is comming! Wait a minute please.",
    "Select your products": "Select your products",
    "Confirm & send your order": "Confirm & send your order",
    "Add & send more products anytime": "Add & send more products anytime",
    "Enjoy your order!": "Enjoy your order!",
    "Checkout & pay": "Checkout & pay",
    Dashboard: "Dashboard",
  },
};

export default businessLanguage;
