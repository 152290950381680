import React from "react";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";

import SigninForm from "./SignInForm";
import useReducer from "../../reducers";

const useStyles = makeStyles((theme) => ({
  link: {
    margin: theme.spacing(1),
  },
  sectionTitle: {
    margin: theme.spacing(2, 2, 0, 2),
  },
  section: {
    margin: theme.spacing(0, 2),
  },
}));

const SignIn = () => {
  const classes = useStyles();
  const [, dispatch] = useReducer();

  return (
    <>
      <div className={classes.sectionTitle}>
        <Typography variant="h6">Inicia sesión</Typography>
      </div>
      <div className={classes.section}>
        <SigninForm />
      </div>
      <Typography
        component="p"
        style={{
          marginTop: 8,
          marginLeft: 16,
          marginRight: 16,
          marginBottom: 16,
        }}
      >
        ¿Eres nuevo?{" "}
        <Link
          onClick={() => {
            dispatch({ type: "LOGIN_SET_VIEW", payload: { view: "signup" } });
          }}
          className={classes.link}
        >
          Regístrate gratis
        </Link>
      </Typography>
    </>
  );
};

export default SignIn;
