import React from "react";
import { API, graphqlOperation } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";

import useReducer from "../../reducers";
import { JSONParse } from "../../util";
import * as mutations from "../../graphql/mutations";

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const AutoInvoicingSwitch = () => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [
    {
      loginReducer: { currentUser },
    },
    dispatch,
  ] = useReducer();
  const { fiscalData, autoInvoicing } = currentUser || {};
  const rfcList = (fiscalData && Object.values(JSONParse(fiscalData))) || [];

  const handleOnChange = async () => {
    setLoading(true);
    const newValue = !Boolean(autoInvoicing);
    console.log("autoInvoicing", newValue);

    const input = {
      id: currentUser.id,
      _version: currentUser._version,
      autoInvoicing: newValue,
    };
    console.log("input", input);

    try {
      const { data } = await API.graphql({
        ...graphqlOperation(mutations.updateUser, { input }),
        authMode: "AWS_IAM",
      });
      const { updateUser: updatedUser } = data || {};

      console.log("updatedUser", updatedUser);
      dispatch({
        type: "LOGIN_SET_USER",
        payload: { user: updatedUser },
      });
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <ListItem className={classes.nested}>
      <ListItemText
        primary="Auto facturación"
        secondary="Auto facturar consumos al RFC principal"
      />
      <ListItemSecondaryAction>
        <Switch
          edge="end"
          onChange={handleOnChange}
          checked={Boolean(autoInvoicing)}
          inputProps={{ "aria-labelledby": "switch-auto-invoicing" }}
          disabled={!(rfcList && rfcList.length) || loading}
        />
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default AutoInvoicingSwitch;
