import React from "react";
import { Cache } from "aws-amplify";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import useReducer from "../reducers";

const useDarkMode = () => {
  const [{ themeReducer: state }, dispatch] = useReducer();
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  React.useEffect(() => {
    let mounted = true;
    const cachedIsDark = Cache.getItem("isDark");
    // console.log("cachedisdark", cachedIsDark);
    if (cachedIsDark === null) {
      // console.log("prefersdarkmode", prefersDarkMode);
      if (mounted) {
        dispatch({
          type: "THEME_TOGGLE_MODE",
          payload: { isDark: prefersDarkMode },
        });
      }
    } else {
      if (mounted) {
        dispatch({
          type: "THEME_TOGGLE_MODE",
          payload: { isDark: cachedIsDark },
        });
      }
    }

    return () => {
      mounted = false;
    };
  }, [prefersDarkMode]);

  return [state];
};

export default useDarkMode;
