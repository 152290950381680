import React from "react";
import { Typography, Box, Button } from "@material-ui/core";
import { I18n } from "aws-amplify";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import SvgIcon from "@material-ui/core/SvgIcon";
import { useParams, useHistory } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";

import { ReactComponent as SurveyIcon } from "../svg/undraw_customer_survey.svg";

import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import useReducer from "../reducers";
import { isEmptyObject } from "../util";
import ReviewValidationSchema from "../components/Reviews/Schema";
import useStyles from "../components/Reviews/Review.styled";
import ServiceStep from "../components/Reviews/ServiceStep";
import ProductStep from "../components/Reviews/ProductStep";
import ContactStep from "../components/Reviews/ContactStep";

function getSteps() {
  return ["Service", "Product & Place", "Contact"];
}

const ReviewPage = ({ rest = false, fromSite = false }) => {
  const classes = useStyles();
  const params = useParams();
  const { orderId, slug } = params;
  console.log("params", params);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [
    {
      loginReducer: { currentUser },
      siteReducer: { siteData },
    },
    dispatch,
  ] = useReducer();

  const restSlug = fromSite ? siteData?.slug : slug;

  const [activeStep, setActiveStep] = React.useState(0);
  const [reviewSubmitted, setSubmitted] = React.useState(false);
  const [reviewOrder, setOrder] = React.useState();
  const [reviewRestaurant, setRestaurant] = React.useState({});
  console.log("reviewRestaurant", reviewRestaurant);
  const steps = getSteps();

  const formik = useFormik({
    initialValues: {
      waiter: 0,
      cashier: 0,
      manager: 0,
      host: 0,
      product: 0,
      place: 0,
      cleaning: 0,
      comments: "",
      preferUs: "",
      email: "",
    },
    validationSchema: ReviewValidationSchema[activeStep],
    onSubmit: async (values, actions) => {
      console.log("value", values);

      if (activeStep === steps.length - 1) {
        dispatch({ type: "LOADING_START" });
        setSubmitted(true);

        const input = {
          ...values,
        };

        if (!input.email) {
          delete input.email;
        }

        if (rest && reviewOrder) {
          input.orderID = reviewOrder.id;
          input.restaurantID = reviewOrder.restaurantID;
        } else {
          input.restaurantID = reviewRestaurant.id;
        }

        if (!input.comments) {
          input.comments = null;
        }

        if (!input.preferUs) {
          input.preferUs = null;
        }

        if (currentUser && currentUser.id) {
          input.userID = currentUser.id;
        }

        if (!values.email && currentUser && currentUser.username) {
          input.email = currentUser.username;
        }

        console.log("input", input);

        try {
          const { data } = await API.graphql({
            ...graphqlOperation(mutations.createReview, {
              input,
            }),
            authMode: "AWS_IAM",
          });
          const { createReview } = data || {};

          if (orderId && !rest) {
            await API.graphql({
              ...graphqlOperation(mutations.updateOrder, {
                input: {
                  id: orderId,
                  reviewed: true,
                  _version: reviewOrder._version,
                },
              }),
            });
          }

          console.log("createReviewResponse", createReview);
        } catch (error) {
          console.log("error", error);
        } finally {
          dispatch({ type: "LOADING_STOP" });
        }
      } else {
        handleNext();
        actions.setTouched({});
        actions.setSubmitting(false);
      }
    },
  });

  React.useEffect(() => {
    let mounted = true;

    const fetchData = async () => {
      dispatch({ type: "LOADING_START" });
      const { data } = await API.graphql({
        ...graphqlOperation(queries.getOrder, {
          id: orderId,
        }),
      });
      const { getOrder: response = {} } = data || {};
      console.log("getOrder response", response);

      if (!response || isEmptyObject(response)) {
        enqueueSnackbar(I18n.get("Order not found!"), {
          variant: "info",
          autoHideDuration: 3000,
        });
        dispatch({ type: "LOADING_STOP" });
        history.goBack();
      }

      if (mounted) {
        setOrder(response);
        dispatch({ type: "LOADING_STOP" });
      }
    };

    const fetchRestaurant = async () => {
      dispatch({ type: "LOADING_START" });
      const { data } = await API.graphql({
        ...graphqlOperation(queries.bySlug, { slug: restSlug }),
        authMode: "AWS_IAM",
      });

      const { items: [response] = { items: [] } } = (data && data.bySlug) || {};
      if (response && !response._deleted && response.status !== "INACTIVE") {
        if (mounted) {
          setRestaurant(response);
          dispatch({
            type: "RESTAURANT_SET",
            payload: { currentRestaurant: response },
          });
          if (response?.primaryColor || response?.company?.primaryColor) {
            dispatch({
              type: "THEME_SET_CUSTOM",
              payload: {
                primaryColor:
                  response?.primaryColor || response?.company?.primaryColor,
              },
            });
          }
          dispatch({ type: "LOADING_STOP" });
        }
      } else {
        dispatch({ type: "LOADING_STOP" });
        history.goBack();
      }
    };

    if (orderId && !rest) {
      fetchData();
    } else {
      fetchRestaurant();
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <ServiceStep formik={formik} />;
      case 1:
        return <ProductStep formik={formik} />;
      case 2:
        return <ContactStep formik={formik} />;
      default:
        return "Unknown step";
    }
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        {!reviewSubmitted ? (
          <>
            <Typography variant="h4" style={{ marginBottom: 16 }}>
              {I18n.get("Share your experience")}
            </Typography>
            {/* <Typography variant="subtitle1" color="textSecondary">
              {I18n.get("Rate this place")}
            </Typography> */}
            <Stepper activeStep={activeStep} style={{ padding: 8 }}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{I18n.get(label)}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {getStepContent(activeStep)}
            <div style={{ marginTop: 24, textAlign: "right" }}>
              <Button
                disabled={activeStep === 0 || activeStep === 3}
                onClick={handleBack}
                style={{ marginRight: 16 }}
              >
                {I18n.get("Back")}
              </Button>
              <Button
                type="submit"
                disabled={formik.isSubmitting || activeStep === 3}
                variant="contained"
                color="primary"
              >
                {I18n.get(
                  activeStep === steps.length - 1 ? "Enviar evaluación" : "Next"
                )}
              </Button>
            </div>
          </>
        ) : (
          <Box
            flex={1}
            flexDirection="column"
            display="flex"
            justifyContent="center"
            alignItems="center"
            p={2}
          >
            <Typography variant="h4" className={classes.title}>
              {I18n.get("Thanks for your response")}
            </Typography>
            <SvgIcon
              component={SurveyIcon}
              viewBox="0 0 638.9752 778.64494"
              style={{ fontSize: 120 }}
            />
            <Button
              style={{ marginTop: 16 }}
              variant="contained"
              component="button"
              onClick={() => {
                formik.resetForm();
                setActiveStep(0);
                setSubmitted(false);
              }}
            >
              Ir a inicio
            </Button>
          </Box>
        )}
      </form>
    </>
  );
};

export default ReviewPage;
