import React from "react";
import { Box } from "@material-ui/core";
import { API } from "aws-amplify";

import CategoriesWidget from "./CategoriesWidget";
import * as queries from "../../graphql/queries";
import useReducer from "../../reducers";

const Subcategories = ({ categoryId, fromSite = false, delivery = false }) => {
  const [loading, setLoading] = React.useState(true);
  const [loadingMore, setLoadingMore] = React.useState(false);
  const [nextToken, setNextToken] = React.useState();
  const [menuCategories, setCategories] = React.useState([]);
  const [
    {
      deliveryReducer: { selectedRestaurant },
    },
  ] = useReducer();

  React.useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      setLoading(true);
      try {
        const subcategoriesResponse = API.graphql({
          query: queries.subcategoriesbyCategory,
          variables: {
            categoryID: categoryId,
            limit: 100,
          },
        });

        // subcategories query response
        const { data: categoriesData } = (await subcategoriesResponse) || {};
        const {
          subcategoriesbyCategory: {
            items: subcategories,
            nextToken: token,
          } = {
            items: [],
          },
        } = categoriesData || {};
        const categorySubcategories = (subcategories || [])
          .filter((x) => !x._deleted)
          .map((x) => x.subcategory)
          .filter((x) => x.status === "ACTIVE");
        if (mounted) {
          setCategories(categorySubcategories);
          setNextToken(token);
          setLoading(false);
        }
      } catch (error) {
        console.log("error", error);
        if (mounted) {
          setLoading(false);
        }
      }
    };

    if (categoryId) {
      fetchData();
    }

    return () => {
      mounted = false;
    };
  }, [categoryId]);

  function fetchCategories(variables) {
    return API.graphql({
      query: queries.subcategoriesbyCategory,
      variables,
      authMode: "AWS_IAM",
    });
  }

  function handleLoadMore() {
    setLoadingMore(true);

    if (!nextToken) {
      setLoadingMore(false);
      return;
    }

    fetchCategories({
      categoryID: categoryId,
      limit: 20,
      nextToken,
    }).then(({ data }) => {
      const {
        subcategoriesbyCategory: { items: subcategories, nextToken: token } = {
          items: [],
        },
      } = data || {};
      const categorySubcategories = (subcategories || [])
        ?.filter((x) => !x._deleted)
        .map((x) => x.subcategory)
        .filter((x) => x.status === "ACTIVE");
      setLoadingMore(false);
      setNextToken(token);

      setCategories([...menuCategories, ...categorySubcategories]);
    });
  }

  return !loading && !(menuCategories && menuCategories.length) ? null : (
    <Box mb={3}>
      <CategoriesWidget
        loading={loading}
        loadingMore={loadingMore}
        handleLoadMore={handleLoadMore}
        hasMore={Boolean(nextToken)}
        categories={menuCategories}
        title={null}
        restaurantId={selectedRestaurant.id}
        delivery={delivery}
        fromSite={fromSite}
      />
    </Box>
  );
};

export default Subcategories;
