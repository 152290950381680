import React from "react";
import { Survey } from "@2dine/surveys";
import { useParams, useHistory } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { Helmet } from "react-helmet";

import useReducer from "../reducers";
import * as queries from "../graphql/queries";
import { getPictureUrl, JSONParse } from "../util";

const SurveyPage = () => {
  const params = useParams();
  const history = useHistory();
  const { restaurantID, slug } = params;
  const [, dispatch] = useReducer();
  const [surveyRestaurant, setRestaurant] = React.useState({});

  const picture =
    surveyRestaurant && surveyRestaurant.picture
      ? JSONParse(surveyRestaurant.picture)
      : {};
  const pictureUrl =
    surveyRestaurant && surveyRestaurant.picture
      ? getPictureUrl({ ...picture, size: "0x256" })
      : null;

  React.useEffect(() => {
    let mounted = true;
    const fetchRestaurant = async () => {
      dispatch({ type: "LOADING_START" });
      const { data } = await API.graphql({
        ...graphqlOperation(queries.getRestaurant, { id: restaurantID }),
        authMode: "AWS_IAM",
      });

      const retrievedRestaurant = (data && data.getRestaurant) || {};
      if (
        retrievedRestaurant &&
        !retrievedRestaurant._deleted &&
        retrievedRestaurant.status !== "INACTIVE"
      ) {
        if (mounted) {
          setRestaurant(retrievedRestaurant);
          dispatch({
            type: "RESTAURANT_SET",
            payload: { currentRestaurant: retrievedRestaurant },
          });
          if (
            retrievedRestaurant?.primaryColor ||
            retrievedRestaurant?.company?.primaryColor
          ) {
            dispatch({
              type: "THEME_SET_CUSTOM",
              payload: {
                primaryColor:
                  retrievedRestaurant?.primaryColor ||
                  retrievedRestaurant?.company?.primaryColor,
              },
            });
          }
          dispatch({ type: "LOADING_STOP" });
        }
      } else {
        dispatch({ type: "LOADING_STOP" });
        history.replace("/");
      }
    };

    if (restaurantID) {
      fetchRestaurant();
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurantID]);

  return (
    <>
      <Helmet>
        <title>{surveyRestaurant?.name || ""} | Encuesta</title>
        <meta
          name="description"
          content={surveyRestaurant?.description || ""}
        />
        <link id="favicon" rel="icon" href={pictureUrl} />
        <link rel="apple-touch-icon" href={pictureUrl} />
      </Helmet>

      <Survey
        restaurantID={restaurantID}
        slug={slug}
        restName={surveyRestaurant?.name}
        restLogo={pictureUrl}
      />
    </>
  );
};

export default SurveyPage;
