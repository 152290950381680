import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import useReducer from "../../reducers";
import AddressItem from "./AddressItem";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  itemText: {
    WebkitLineClamp: 2,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
}));

const DeliveryAddressList = () => {
  const classes = useStyles();
  const [
    {
      deliveryReducer: { addressList },
    },
  ] = useReducer();

  return (
    <List
      subheader={
        <ListSubheader disableSticky>Tus direcciones guardadas</ListSubheader>
      }
      className={classes.root}
    >
      {addressList && addressList.length ? (
        addressList.map((address, index) => {
          return <AddressItem key={index} address={address} index={index} />;
        })
      ) : (
        <ListItem>
          <ListItemText primary={"No addresses saved"} />
        </ListItem>
      )}
    </List>
  );
};

export default DeliveryAddressList;
