import React from 'react';
import { Redirect } from 'react-router-dom';

import useReducer from '../reducers';
import Loading from './Loading';

const SignedInRoute = ({ children }) => {
  const [
    {
      loginReducer: { authState }
    },
    dispatch
  ] = useReducer();

  React.useEffect(() => {
    if (authState === 'signIn') {
      dispatch({ type: 'LOGIN_OPEN' });
    }
  }, [authState]);

  if (authState === 'loading') {
    return <Loading force />;
  }

  return authState === 'signIn' ? (
    <Redirect to={{ pathname: '/' }} />
  ) : (
    children
  );
};

export default SignedInRoute;
