import React from "react";
// import { uuid } from "uuidv4";
import { v4 as uuid } from "uuid";
import moment from "moment";
import _compact from "lodash/compact";
import _omit from "lodash/omit";
import { I18n } from "aws-amplify";
import { Cache } from "aws-amplify";
import _orderBy from "lodash/orderBy";
import { useHistory, useLocation, useParams, Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import { Box, TextField, Button } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import { API, graphqlOperation } from "aws-amplify";

import useReducer from "../../reducers";
import {
  getModifiersAmount,
  toCurrency,
  cleanObject,
  JSONParse,
  isEmptyObject,
  restoreModifiersPrice,
  updater,
  handleProductPrice,
  getAmount as getDeliveryAmount,
  getTaxesAmount,
  handleProductPriceTaxes,
  getDiscountAmount,
  getDiscountItems,
} from "../../util";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import ModifierSelect from "./ModifierSelect";
import ConfirmDialog from "../ConfirmDialog";
import ClearCartDialog from "../Delivery/ClearCartDialog";
import DatesPicker from "./DatesPicker";
import QuantityButtons from "./QuantityButtons";

const useStyles = makeStyles((theme) => ({
  addButton: {
    position: "fixed",
    bottom: theme.spacing(8),
    right: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  summary: {
    justifyContent: "space-between",
  },
  paper: {
    width: "80%",
    maxHeight: 435,
  },
}));

const ProductForm = ({
  productId,
  orderItemId,
  product = {},
  orderItem = {},
  delivery = false,
  fromSite = false,
}) => {
  const classes = useStyles();
  const [
    {
      siteReducer: { onlyDelivery },
      loginReducer: { identityId },
      deliveryReducer: { selectedRestaurant, deliveryCart, cartCompany },
      orderReducer: { currentOrder, orderItems },
      restaurantReducer: { currentRestaurant },
    },
    dispatch,
  ] = useReducer();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const [modifiersErrors, setModifiersErrors] = React.useState({});
  const { restaurantId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const reorderState = (location.state && location.state.reorder) || {};

  const visibleRest = !delivery ? currentRestaurant : selectedRestaurant;
  const { status, businessType: commerceType } = visibleRest;

  console.log("modifiersERrors", modifiersErrors);
  // console.log("product", product);
  // console.log("visibleRest", visibleRest);
  // console.log("status", status);

  const filteredModifiers = _compact(
    _orderBy(product?.modifiers?.items || [], ["position"], ["asc"]).map(
      (x) => {
        if (
          x._deleted ||
          x?.modifier?._deleted ||
          x?.modifier?.status !== "ACTIVE"
        ) {
          return null;
        }
        return { ...x.modifier, productModifierPosition: x.position };
      }
    )
  );

  const productPrice = React.useMemo(
    () => handleProductPrice(product, delivery),
    [product, delivery]
  );

  const {
    label: notesLabel,
    placeholder: notesPlaceholder,
  } = product.notesField ? JSONParse(product.notesField) : {};
  const {
    label: quantityLabel,
    // placeholder: quantityPlaceholder
  } = product.quantityField ? JSONParse(product.quantityField) : {};
  const needsEndDate = (quantityLabel || "")
    .toLowerCase()
    .trim()
    .includes("noches");

  const handleMissingModifiersMessage = (difference) => {
    const lastDiff = difference[0];

    return `${I18n.get("You need to select")} ${lastDiff.title}`;
  };

  const formik = useFormik({
    initialValues: {
      qty: 1,
      modifiers: {},
      notes: "",
      startDate: moment().add(2, "days"),
    },
    onSubmit: async (values) => {
      const modifiersArr = Object.values(values.modifiers);
      // console.log("modifiersArr", modifiersArr);

      const modifiersComplete = modifiersArr
        ? modifiersArr
            .filter((x) => {
              const optsQty = (x?.options || []).reduce(
                (sum, val) => val.qty + sum,
                0
              );
              console.log("optsQty", optsQty);
              return (
                (x.minSelection > 0 &&
                  optsQty >= x.minSelection &&
                  optsQty <= x.maxSelection) ||
                !x.minSelection
              );
            })
            .map((x) => x.id)
        : [];
      const requiredModifiers = filteredModifiers.filter(
        (i) => !i._deleted && i.minSelection
      );
      const difference = requiredModifiers.filter(
        (x) => !modifiersComplete.includes(x.id)
      );

      // console.log("difference", difference);
      if (difference && difference.length) {
        difference.forEach((x) => {
          setModifiersErrors((me) => ({
            ...me,
            [x.id]: `${I18n.get("You need to select")} ${x.title}`,
          }));
        });

        enqueueSnackbar(handleMissingModifiersMessage(difference), {
          variant: "error",
          autoHideDuration: 5000,
        });
        return;
      }

      if (!delivery) {
        const { data } = await API.graphql({
          ...graphqlOperation(queries.getOrder, {
            id: currentOrder.id,
          }),
          authMode: "AWS_IAM",
        });
        const { getOrder: response = {} } = data || {};
        // console.log("getOrder response", response);

        if (
          !response ||
          response._deleted ||
          ["DELETED", "CLOSED", "CANCELLED"].includes(response.status)
        ) {
          enqueueSnackbar(I18n.get("Order is not available!"), {
            variant: "error",
            autoHideDuration: 3000,
          });
          window.location.reload(true);
          return;
        }

        if (!orderItemId) {
          const now = new Date();
          const modifiersValues = Object.values(values.modifiers);
          // console.log("modifiersValues", modifiersValues);

          const orderGuest =
            (response.guestDevices || []).indexOf(identityId) + 1;
          // console.log("orderGuest", orderGuest);

          const params = {
            qty: values.qty,
            productID: productId,
            restaurantID: currentRestaurant.id,
            tableID: response.tableID,
            modifiers:
              modifiersValues && modifiersValues.length
                ? JSON.stringify(modifiersValues)
                : null,
            title: product.title,
            price: productPrice,
            status: "PENDING",
            guest: orderGuest || 0,
            addedAt: now.toISOString(),
            sentAt: now.toISOString(),
            orderItemOrderId: currentOrder.id,
            kitchenID: product.kitchenID,
          };

          if (values.notes) {
            params.notes = values.notes;
          }

          cleanObject(params);

          // console.log("params", params);

          // newOrderItem
          await API.graphql({
            ...graphqlOperation(mutations.createOrderItem, {
              input: params,
            }),
            authMode: "AWS_IAM",
          });

          // console.log("newOrderItem", newOrderItem);
          enqueueSnackbar(I18n.get("Item added successfully!"), {
            variant: "success",
            autoHideDuration: 3000,
          });

          formik.resetForm();
          // dispatch({ type: 'SHEET_OPEN' });
          // dispatch({ type: 'SHEET_SET_TAB', payload: { tab: 0 } });
          dispatch({ type: "TABS_SET_TAB", payload: { tab: 1 } });
        } else {
          const original = orderItems.find((x) => x.id === orderItemId);
          // console.log("original", original);
          if (values.qty) {
            // console.log("qty", values.qty);
            const modifiersValues = Object.values(values.modifiers);
            // console.log("modifiersValues", modifiersValues);
            const input = {
              id: orderItemId,
              _version: original._version,
              qty: values.qty,
              modifiers:
                modifiersValues && modifiersValues.length
                  ? JSON.stringify(modifiersValues)
                  : null,
            };

            if (values.notes) {
              input.notes = values.notes;
            }

            await API.graphql({
              ...graphqlOperation(mutations.updateOrderItem, {
                input,
              }),
              authMode: "AWS_IAM",
            });

            enqueueSnackbar(I18n.get("Item updated successfully!"), {
              variant: "success",
              autoHideDuration: 5000,
            });
            dispatch({ type: "TABS_SET_TAB", payload: { tab: 1 } });
          } else {
            // console.log("delete orderitem");

            await API.graphql({
              ...graphqlOperation(mutations.deleteOrderItem, {
                input: {
                  id: orderItemId,
                  _version: original._version,
                },
                condition: {
                  status: { eq: "PENDING" },
                },
              }),
              authMode: "AWS_IAM",
            });

            enqueueSnackbar(I18n.get("Item deleted successfully!"), {
              variant: "success",
              autoHideDuration: 5000,
            });

            dispatch({ type: "TABS_SET_TAB", payload: { tab: 1 } });
            history.push("/t");
          }
        }
      } else {
        if (!orderItemId) {
          // console.log("add item to delivery cart");

          const { businessType: companyBusinessType, id: cartCompanyId = {} } =
            cartCompany || {};
          // console.log("cartCompany", cartCompany);
          const { company: { id: selectedRestaurantCompany } = {} } =
            selectedRestaurant || {};

          const modifiersValues = Object.values(values.modifiers);
          let params = {
            qty: values.qty,
            productID: productId,
            companyID: selectedRestaurantCompany || null,
            restaurantID: selectedRestaurant.id,
            restaurantName: selectedRestaurant.name,
            modifiers:
              modifiersValues && modifiersValues.length
                ? JSON.stringify(modifiersValues)
                : null,
            title: product.title,
            price: productPrice,
            status: "PENDING",
            guest: 0,
            kitchenID: product.kitchenID,
            print: 0,
            isPaid: false,
            id: uuid(),
          };

          cleanObject(params);

          if (values.notes) {
            params.notes = values.notes;
          }

          if (commerceType === "TRAVEL" && values.startDate) {
            const parsedStartDate = values.startDate.toISOString();
            const td = { startDate: parsedStartDate };

            if (needsEndDate && values.endDate) {
              const parsedEndDate = values.endDate.toISOString();
              td.endDate = parsedEndDate;

              const nights = moment(values.endDate)
                .endOf("day")
                .diff(moment(values.startDate).endOf("day"), "days");
              console.log("nights", nights);
              params.qty = nights && nights > 0 ? nights : 1;
            }

            params.travelData = JSON.stringify(td);
          }

          params = handleProductPriceTaxes(params, selectedRestaurant);

          // console.log("params", params);

          // Verificar que solo haya productos del restaurante en curso
          const otherProducts = deliveryCart.find(
            (x) => x.restaurantID !== selectedRestaurant.id
          );

          /**
           * Checar que el siguiente producto a agregar sea de la misma Company
           * cartRestaurant.company.id === selectedRestaurant.company.id
           */

          if (
            (companyBusinessType &&
              cartCompanyId &&
              selectedRestaurantCompany &&
              cartCompanyId !== selectedRestaurantCompany) ||
            (commerceType !== "GOURMET_MARKET" && otherProducts)
          ) {
            console.log("DIFFERENT COMPANY");
            dispatch({
              type: "DELIVERY_TOGGLE_CLEAR_CART_DIALOG",
              payload: { open: true },
            });
            dispatch({
              type: "DELIVERY_CLEAR_CART_NEW_ITEM",
              payload: {
                newProduct: params,
                newCartRestaurant: selectedRestaurant,
              },
            });
            return;
          }

          const updatedDeliveryCart = [...deliveryCart, params];
          Cache.setItem(`deliveryCart`, updatedDeliveryCart, {
            priority: 1,
            expires: moment().add(1, "month").endOf("day").valueOf(),
          });
          Cache.setItem("cartCompany", selectedRestaurant.company, {
            priority: 1,
            expires: moment().add(1, "month").endOf("day").valueOf(),
          });
          Cache.setItem(
            `cartRestaurant`,
            _omit(selectedRestaurant, [
              "tables",
              "menus",
              "categories",
              "modifiers",
              "products",
            ]),
            {
              priority: 1,
              expires: moment().add(1, "month").endOf("day").valueOf(),
            }
          );
          dispatch({
            type: "DELIVERY_SET_CART_COMPANY",
            payload: { cartCompany: selectedRestaurant.company },
          });
          dispatch({
            type: "DELIVERY_SET_CART_RESTAURANT",
            payload: { cartRestaurant: selectedRestaurant },
          });
          dispatch({
            type: "DELIVERY_SET_PRINCIPAL_RESTAURANT",
            payload: { principalRestaurant: selectedRestaurant },
          });
          dispatch({
            type: "DELIVERY_SET_CART",
            payload: { deliveryCart: updatedDeliveryCart },
          });

          if (updatedDeliveryCart && updatedDeliveryCart.length) {
            const amt = getDeliveryAmount(updatedDeliveryCart, true);
            const txs = getTaxesAmount(updatedDeliveryCart);
            const discountItems = getDiscountItems(deliveryCart);

            dispatch({
              type: "DELIVERY_SET_AMOUNT",
              payload: { deliveryOrderAmount: amt },
            });
            dispatch({
              type: "DELIVERY_SET_TAXES",
              payload: { deliveryOrderTaxes: txs },
            });
            dispatch({
              type: "DELIVERY_SET_DISCOUNT_AMOUNT",
              payload: {
                deliveryDiscountAmount: getDiscountAmount(discountItems),
              },
            });
          } else {
            dispatch({
              type: "DELIVERY_SET_AMOUNT",
              payload: { deliveryOrderAmount: 0 },
            });
            dispatch({
              type: "DELIVERY_SET_TAXES",
              payload: { deliveryOrderTaxes: [] },
            });
            dispatch({
              type: "DELIVERY_SET_DISCOUNT_AMOUNT",
              payload: { deliveryDiscountAmount: 0 },
            });
          }

          formik.resetForm();

          enqueueSnackbar(I18n.get("Item added successfully!"), {
            variant: "success",
            autoHideDuration: 3000,
          });

          if (commerceType === "TRAVEL") {
            dispatch({
              type: "DELIVERY_TOGGLE_CHECKOUT",
              payload: { openCheckout: true },
            });
          }
        } else {
          // Update Delivery Cart Item

          const original = deliveryCart.find((x) => x.id === orderItemId);
          // console.log("original", original);

          if (values.qty) {
            // console.log("qty", values.qty);
            const modifiersValues = Object.values(values.modifiers);
            // console.log("modifiersValues", modifiersValues);
            let input = {
              ...original,
              id: orderItemId,
              qty: values.qty,
              price: productPrice,
              modifiers:
                modifiersValues && modifiersValues.length
                  ? JSON.stringify(modifiersValues)
                  : null,
            };

            if (values.notes) {
              input.notes = values.notes;
            }

            if (commerceType === "TRAVEL" && values.startDate) {
              const parsedStartDate = values.startDate.toISOString();
              const td = { startDate: parsedStartDate };

              if (needsEndDate && values.endDate) {
                const parsedEndDate = values.endDate.toISOString();
                td.endDate = parsedEndDate;

                const nights = moment(values.endDate)
                  .endOf("day")
                  .diff(moment(values.startDate).endOf("day"), "days");
                console.log("nights", nights);
                input.qty = nights && nights > 0 ? nights : 1;
              }

              input.travelData = JSON.stringify(td);
            }

            input = handleProductPriceTaxes(input, selectedRestaurant);

            const updatedDeliveryCart = updater(deliveryCart, input);
            // console.log("updatedDeliveryCart", updatedDeliveryCart);

            Cache.setItem(`deliveryCart`, updatedDeliveryCart, {
              priority: 1,
              expires: moment().add(1, "month").endOf("day").valueOf(),
            });
            dispatch({
              type: "DELIVERY_SET_CART",
              payload: { deliveryCart: updatedDeliveryCart },
            });

            if (updatedDeliveryCart && updatedDeliveryCart.length) {
              const amt = getDeliveryAmount(updatedDeliveryCart, true);
              const txs = getTaxesAmount(updatedDeliveryCart);
              const discountItems = getDiscountItems(deliveryCart);

              dispatch({
                type: "DELIVERY_SET_AMOUNT",
                payload: { deliveryOrderAmount: amt },
              });
              dispatch({
                type: "DELIVERY_SET_TAXES",
                payload: { deliveryOrderTaxes: txs },
              });
              dispatch({
                type: "DELIVERY_SET_DISCOUNT_AMOUNT",
                payload: {
                  deliveryDiscountAmount: getDiscountAmount(discountItems),
                },
              });
            } else {
              dispatch({
                type: "DELIVERY_SET_AMOUNT",
                payload: { deliveryOrderAmount: 0 },
              });
              dispatch({
                type: "DELIVERY_SET_TAXES",
                payload: { deliveryOrderTaxes: [] },
              });
              dispatch({
                type: "DELIVERY_SET_DISCOUNT_AMOUNT",
                payload: { deliveryDiscountAmount: 0 },
              });
            }

            enqueueSnackbar(I18n.get("Item updated successfully!"), {
              variant: "success",
              autoHideDuration: 5000,
            });
            dispatch({
              type: "DELIVERY_TOGGLE_CHECKOUT",
              payload: { openCheckout: true },
            });
          } else {
            // console.log("delete orderitem");

            const updatedDeliveryCart = updater(
              deliveryCart,
              { id: orderItemId },
              "id",
              true
            );

            Cache.setItem(`deliveryCart`, updatedDeliveryCart, {
              priority: 1,
              expires: moment().add(1, "month").endOf("day").valueOf(),
            });
            dispatch({
              type: "DELIVERY_SET_CART",
              payload: { deliveryCart: updatedDeliveryCart },
            });

            enqueueSnackbar(I18n.get("Item deleted successfully!"), {
              variant: "success",
              autoHideDuration: 5000,
            });

            dispatch({
              type: "DELIVERY_TOGGLE_CHECKOUT",
              payload: { openCheckout: true },
            });
            history.push(fromSite ? `/d` : `/d/r/${original.restaurantID}`);
          }
        }
      }
    },
  });

  React.useEffect(() => {
    if (orderItemId) {
      let parsedModifiers = {};

      if (product && product._deleted) {
        handleDeleteItem();
        return;
      }

      if (
        orderItem.modifiers &&
        filteredModifiers &&
        filteredModifiers.length
      ) {
        const restoredModifiers = restoreModifiersPrice(
          orderItem.modifiers,
          filteredModifiers
        );
        // console.log("restoredModifiers", restoredModifiers);

        JSONParse(restoredModifiers).map(
          (modifier) =>
            (parsedModifiers = { ...parsedModifiers, [modifier.id]: modifier })
        );

        formik.setFieldValue("modifiers", parsedModifiers);
      } else {
        formik.setFieldValue("modifiers", {});
      }

      formik.setFieldValue("qty", orderItem.qty || 1);
      if (orderItem.notes) {
        formik.setFieldValue("notes", orderItem.notes);
      }
    } else {
      if (product.minQuantity) {
        formik.setFieldValue("qty", product.minQuantity);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // filteredModifiers,
    product.minQuantity,
    orderItem.qty,
    orderItem.notes,
    orderItem.modifiers,
    orderItemId,
  ]);

  React.useEffect(() => {
    if (
      !isEmptyObject(reorderState) &&
      filteredModifiers &&
      filteredModifiers.length
    ) {
      if (reorderState.modifiers) {
        const restoredModifiers = restoreModifiersPrice(
          reorderState.modifiers,
          filteredModifiers
        );
        // console.log("restoredModifiers", restoredModifiers);
        let parsedModifiers = {};
        JSONParse(restoredModifiers).map(
          (modifier) =>
            (parsedModifiers = { ...parsedModifiers, [modifier.id]: modifier })
        );

        formik.setFieldValue("modifiers", parsedModifiers);
      }

      formik.setFieldValue("qty", reorderState.qty || 1);
      if (reorderState.notes) {
        formik.setFieldValue("notes", reorderState.notes);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filteredModifiers,
    reorderState.qty,
    reorderState.notes,
    reorderState.modifiers,
  ]);

  const handleChange = (modifier, options) => {
    const selectedModifiers = {
      ...formik.values.modifiers,
      [modifier.id]: { ...modifier, options },
    };

    if (!options.length) {
      delete selectedModifiers[modifier.id];
    }

    formik.setFieldValue("modifiers", selectedModifiers);

    // CHECAR MODIFICADORES COMPLETADOS - INICIO //
    const modifiersArr = Object.values(selectedModifiers);
    // console.log("modifiersArr", modifiersArr);

    const modifiersComplete = modifiersArr
      ? modifiersArr
          .filter((x) => {
            const optsQty = (x?.options || []).reduce(
              (sum, val) => val.qty + sum,
              0
            );
            console.log("optsQty", optsQty);
            return (
              (x.minSelection > 0 &&
                optsQty >= x.minSelection &&
                optsQty <= x.maxSelection) ||
              !x.minSelection
            );
          })
          .map((x) => x.id)
      : [];
    console.log("modifiersComplete", modifiersComplete);

    const requiredModifiers = filteredModifiers.filter(
      (i) => !i._deleted && i.minSelection
    );

    console.log("requiredModifiers", requiredModifiers);
    const difference = requiredModifiers.filter(
      (x) => !modifiersComplete.includes(x.id)
    );

    console.log("difference", difference);
    const noErrorModifiers = Object.keys(modifiersErrors || {}).filter(
      (x) => !(difference || []).map((x) => x.id).includes(x)
    );
    console.log("noErrorModifiers", noErrorModifiers);
    noErrorModifiers.forEach((x) => {
      setModifiersErrors((me) => {
        if (me[x]) {
          delete me[x];
        }

        return me;
      });
    });
    // CHECAR MODIFICADORES COMPLETADOS - FINAL //
  };

  const handleDeleteItem = async () => {
    if (!delivery) {
      const original = orderItems.find((x) => x.id === orderItemId);
      // console.log("original", original);

      await API.graphql({
        ...graphqlOperation(mutations.deleteOrderItem, {
          input: {
            id: orderItemId,
            _version: original._version,
          },
          condition: {
            status: { eq: "PENDING" },
          },
        }),
        authMode: "AWS_IAM",
      });

      handleClose();
      enqueueSnackbar(I18n.get("Item deleted successfully!"), {
        variant: "success",
        autoHideDuration: 5000,
      });
      history.push("/");
    } else {
      const updatedDeliveryCart = updater(
        deliveryCart,
        { id: orderItemId },
        "id",
        true
      );
      // console.log("updatedDeliveryCart", updatedDeliveryCart);
      Cache.setItem(`deliveryCart`, updatedDeliveryCart, {
        priority: 1,
        expires: moment().add(1, "month").endOf("day").valueOf(),
      });

      enqueueSnackbar(I18n.get("Item deleted successfully!"), {
        variant: "success",
        autoHideDuration: 5000,
      });

      if (updatedDeliveryCart && updatedDeliveryCart.length) {
        dispatch({
          type: "DELIVERY_SET_CART",
          payload: { deliveryCart: updatedDeliveryCart },
        });
        dispatch({
          type: "DELIVERY_TOGGLE_CHECKOUT",
          payload: { openCheckout: true },
        });
      } else {
        dispatch({
          type: "DELIVERY_SET_CART",
          payload: { deliveryCart: [] },
        });
        dispatch({
          type: "DELIVERY_SET_CART_RESTAURANT",
          payload: { cartRestaurant: {} },
        });
        dispatch({
          type: "DELIVERY_SET_PRINCIPAL_RESTAURANT",
          payload: { principalRestaurant: {} },
        });
        dispatch({
          type: "DELIVERY_SET_CART_COMPANY",
          payload: { cartCompany: {} },
        });
        Cache.removeItem(`deliveryCart`);
        Cache.removeItem(`cartRestaurant`);
        Cache.removeItem(`cartCompany`);
      }

      history.push(fromSite ? `/d` : `/d/r/${restaurantId}`);
    }
  };

  const getAmount = (qty, price, modifiers) => {
    // console.log("ga modifiers", modifiers);
    const modifiersAmount = getModifiersAmount(Object.values(modifiers));
    return toCurrency(
      (qty * ((price || 0) + (modifiersAmount || 0))).toString()
    );
  };

  const itemAmount = React.useMemo(
    () => getAmount(formik.values.qty, productPrice, formik.values.modifiers),
    [formik.values.qty, formik.values.modifiers, productPrice]
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sortedModifiers = _orderBy(
    filteredModifiers,
    ["productModifierPosition", "title"],
    ["asc", "asc"]
  );

  if (!visibleRest || isEmptyObject(visibleRest)) {
    return (
      <Redirect
        to={
          !delivery
            ? `/`
            : fromSite
            ? onlyDelivery
              ? `/`
              : `/d`
            : `/d/${restaurantId}`
        }
      />
    );
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box mt={2} mb={13}>
          {sortedModifiers.map((modifier) =>
            modifier._deleted ? null : (
              <Accordion defaultExpanded key={modifier.id}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel ${modifier.title}`}
                  id={`panel-${modifier.title}`}
                  classes={{ content: classes.summary }}
                >
                  <Typography className={classes.heading}>
                    {modifier.title}
                  </Typography>
                  {modifier && modifier.upcharge ? (
                    <Typography>+ ${modifier.upcharge / 100}</Typography>
                  ) : null}
                </AccordionSummary>
                <AccordionDetails>
                  <ModifierSelect
                    modifier={modifier}
                    handleChange={handleChange}
                    selectedOptions={
                      (formik.values.modifiers[modifier.id] || {}).options || []
                    }
                    hasError={!!modifiersErrors?.[modifier.id]}
                  />
                </AccordionDetails>
              </Accordion>
            )
          )}

          <DatesPicker
            status={status}
            travelData={product && product.travelData}
            needsEndDate={needsEndDate}
            commerceType={commerceType}
            formik={formik}
            orderItemId={orderItemId}
            orderItem={orderItem}
          />

          {status && status === "ACTIVE" ? (
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel-indications"
                id="panel-indications"
              >
                <Typography>
                  {notesLabel || I18n.get("Additional Indications")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TextField
                  id="notes"
                  name="notes"
                  label={
                    notesPlaceholder || I18n.get("Add special indications")
                  }
                  placeholder={notesPlaceholder}
                  multiline
                  rowsMax="4"
                  rows="2"
                  fullWidth
                  onChange={formik.handleChange}
                  value={formik.values.notes}
                />
              </AccordionDetails>
            </Accordion>
          ) : null}

          <QuantityButtons
            orderItemId={orderItemId}
            status={status}
            needsEndDate={needsEndDate}
            quantityLabel={quantityLabel}
            minQuantity={product.minQuantity}
            maxQuantity={product.maxQuantity}
            formik={formik}
          />

          {status && status === "ACTIVE" ? (
            <>
              {!orderItemId ? (
                <Button
                  fullWidth
                  variant="contained"
                  size="large"
                  color="primary"
                  aria-label="add"
                  // className={classes.addButton}
                  onClick={formik.submitForm}
                >
                  <AddIcon className={classes.extendedIcon} />
                  {I18n.get("Add to cart")} ${itemAmount}
                </Button>
              ) : (
                <div>
                  <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    onClick={handleOpen}
                    style={{ marginBottom: 12 }}
                  >
                    <DeleteIcon />
                    {I18n.get("Remove")}
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    color="primary"
                    aria-label="update"
                    onClick={formik.submitForm}
                  >
                    <SaveIcon className={classes.extendedIcon} />
                    {I18n.get("Update")} ${itemAmount}
                  </Button>
                </div>
              )}
            </>
          ) : null}
        </Box>
      </form>
      <ConfirmDialog
        classes={{
          paper: classes.paper,
        }}
        open={open}
        onClose={handleClose}
        onConfirm={handleDeleteItem}
      />
      <ClearCartDialog />
    </>
  );
};

export default ProductForm;
