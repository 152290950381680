import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import axios from "axios";
import { Cache, I18n } from "aws-amplify";
import GoogleMapReact from "google-map-react";
import { useSnackbar } from "notistack";
import Typography from "@material-ui/core/Typography";
import { Collapse, TextField, Button } from "@material-ui/core";

import useReducer from "../../reducers";
import Marker from "./Marker";
import SearchAddress from "./SearchAddress";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(2),
  },
}));

const AddressComponent = ({ initialAddress }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { geometry: { location = { lat: 59.95, lng: 30.33 } } = {} } =
    initialAddress || {};

  const [
    {
      deliveryReducer: { addressList },
    },
    dispatch,
  ] = useReducer();

  const [mapsApiLoaded, setApiLoad] = React.useState(false);
  const [center, setCenter] = React.useState(location);
  const [disabledMarker, setDisableMarker] = React.useState(false);
  const [address, setAddress] = React.useState(null);
  const [show, setShow] = React.useState(false);
  const [searchField, setSearchField] = React.useState("");
  const [apartment, setApartment] = React.useState("");

  const [map, setMap] = React.useState(null);
  const [maps, setMaps] = React.useState(null);

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (!searchField) {
        setAddress(null);
      }
    }

    return () => {
      mounted = false;
    };
  }, [searchField]);

  const getMapBounds = (maps, places) => {
    const bounds = new maps.LatLngBounds();
    places.forEach((place) => {
      if (place.geometry.viewport) {
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }
    });
    return bounds;
  };

  const apiLoaded = (map, maps) => {
    setMaps(maps);
    setMap(map);
    setApiLoad(true);
  };

  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, "idle", () => {
      maps.event.addDomListener(window, "resize", () => {
        map.fitBounds(bounds);
      });
    });
  };

  const handleCenter = (map) => {
    const lat = map.center.lat();
    const lng = map.center.lng();
    setCenter({ lat, lng });
    setDisableMarker(false);
  };

  const onPlacesChanged = (places, maps) => {
    console.log("places", places);
    const bounds = getMapBounds(maps, places);
    // Fit map to bounds
    map.fitBounds(bounds);
    // Bind the resize listener
    bindResizeListener(map, maps, bounds);

    handleCenter(map);

    if (places && places.length) {
      setShow(true);
      setAddress(places[0]);
    }
  };

  const handleOnDragEnd = (map) => {
    handleCenter(map);
  };

  const handleOnDrag = () => {
    setDisableMarker(true);
  };

  const handleOnChange = async (map) => {
    const lat = map.center.lat;
    const lng = map.center.lng;
    const { data: { results = [] } = {} } = await axios({
      method: "post",
      baseURL: "https://maps.googleapis.com/maps/api/",
      url: "/geocode/json",
      params: {
        latlng: `${lat},${lng}`,
        key: process.env.REACT_APP_GOOGLE_API_KEY,
      },
    });
    console.log("results", results[0]);

    const geometry = results && results.length ? results[0].geometry || {} : {};

    setAddress((prevAddress) => ({
      ...prevAddress,
      geometry,
    }));
  };

  const handleTextChange = (e) => {
    setSearchField(e.target.value);
  };

  const handleSaveAddress = () => {
    const params = {
      ...address,
      apartment,
    };

    Cache.setItem(`addressList`, [...addressList, params], {
      priority: 1,
      expires: moment().add(1, "year").endOf("day").valueOf(),
    });
    Cache.setItem(`deliveryAddress`, params, {
      priority: 1,
      expires: moment().add(1, "month").endOf("day").valueOf(),
    });
    dispatch({
      type: "DELIVERY_SET_ADDRESS_LIST",
      payload: { addressList: [...addressList, params] },
    });

    dispatch({
      type: "DELIVERY_SET_ADDRESS",
      payload: { deliveryAddress: params },
    });

    enqueueSnackbar(I18n.get("Address created successfully!"), {
      variant: "success",
      autoHideDuration: 5000,
    });

    setAddress(null);
    setApartment("");
    setShow(false);
  };

  return (
    <>
      {mapsApiLoaded && (
        <SearchAddress
          onPlacesChanged={onPlacesChanged}
          onChangeText={handleTextChange}
          maps={maps}
        />
      )}

      <Collapse in={show} style={{ paddingLeft: 16, paddingRight: 16 }}>
        <>
          <Typography>{I18n.get("Confirma tu ubicación")}</Typography>
          <div style={{ height: 280, width: "100%", position: "relative" }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: process.env.REACT_APP_GOOGLE_API_KEY,
                libraries: ["places", "drawing"],
              }}
              center={center}
              zoom={17}
              yesIWantToUseGoogleMapApiInternals
              onGoogleApiLoaded={({ map, maps }) => {
                apiLoaded(map, maps);
              }}
              onDragEnd={handleOnDragEnd}
              onChange={handleOnChange}
              onDrag={handleOnDrag}
            >
              {!disabledMarker && (
                <Marker lat={center.lat} lng={center.lng} address={address} />
              )}
            </GoogleMapReact>
            {disabledMarker && <Marker />}
          </div>
          <TextField
            id="apartment"
            name="apartment"
            label={I18n.get("Apartment/Office/Floor")}
            className={classes.textField}
            fullWidth
            size="medium"
            variant="outlined"
            inputProps={{
              spellCheck: "false",
              autoCorrect: "off",
              autoCapitalize: "off",
              autoComplete: "off",
            }}
            onChange={(e) => setApartment(e.target.value)}
            value={apartment}
          />
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            disabled={!searchField || !address}
            className={classes.textField}
            onClick={handleSaveAddress}
          >
            {I18n.get("Save")}
          </Button>
        </>
      </Collapse>
    </>
  );
};

export default AddressComponent;
