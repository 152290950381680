const initialState = {
  logoUrl: "",
  openNav: null,
  categoryView: "panel",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "NAV_CLOSE":
      return {
        ...state,
        openNav: null,
      };
    case "NAV_OPEN":
      return {
        ...state,
        openNav: action.payload.openNav,
      };
    case "NAV_LOGO":
      return {
        ...state,
        logoUrl: action.payload.logoUrl,
      };
    case "NAV_TOGGLE_CATEGORY_VIEW":
      return {
        ...state,
        categoryView: action?.payload?.view
          ? action?.payload?.view === "LIST"
            ? "list"
            : "panel"
          : state.categoryView === "list"
          ? "panel"
          : "list",
      };
    default:
      return state;
  }
};

export default { initialState, reducer };
