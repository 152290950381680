import React from "react";
import { Button, Box } from "@material-ui/core";
import { I18n } from "aws-amplify";

import useReducer from "../../reducers";

const LoginLink = () => {
  const [
    {
      loginReducer: { authState },
    },
    dispatch,
  ] = useReducer();
  return (
    <Box textAlign="center" mt={2}>
      <Button
        color="primary"
        onClick={() => {
          dispatch({ type: "NAV_CLOSE" });
          dispatch({ type: "LOGIN_OPEN" });
        }}
      >
        {["loading", "signIn"].includes(authState)
          ? I18n.get("Sign In")
          : I18n.get("My profile")}
      </Button>
    </Box>
  );
};

export default LoginLink;
