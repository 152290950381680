import React from "react";
import moment from "moment";
import { I18n } from "aws-amplify";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Zoom from "@material-ui/core/Zoom";
import FilterListIcon from "@material-ui/icons/FilterList";
import Fab from "@material-ui/core/Fab";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import DialogContent from "@material-ui/core/DialogContent";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";

import useReducer from "../../reducers";
import { useHistory } from "react-router-dom";
import useQuery from "../../hooks/useQuery";
import businessLanguage from "../../lib/businessLanguage";
import { JSONParse } from "../../util";

const useStyles = makeStyles((theme) => ({
  fab: {
    position: "fixed",
    bottom: theme.spacing(8),
    right: theme.spacing(2),
    zIndex: 9999,
  },
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    overflow: "hidden",
    lineHeight: 1,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  paper: {
    width: "80%",
    maxHeight: 400,
  },
  formControl: {
    margin: theme.spacing(3),
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MenuDialog = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const query = useQuery();
  const [open, setOpen] = React.useState(false);
  const [selectedMenu, setValue] = React.useState("");
  // get selected menu
  const smenu = query.get("smenu");
  const [
    {
      restaurantReducer: { currentRestaurant },
    },
  ] = useReducer();
  const btype =
    (currentRestaurant && currentRestaurant.businessType) || "RESTAURANT";
  const { menus: { items: menus = [] } = {} } = currentRestaurant || {};

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  React.useEffect(() => {
    let mounted = true;

    if (mounted) {
      setValue(smenu || "");
    }

    return () => {
      mounted = false;
    };
  }, [smenu]);

  const handleChange = (event) => {
    // setValue(event.target.value);

    if (!event.target.value) {
      history.replace(`/t/menu`);
    } else {
      history.replace(
        `/t/menu?mtitle=${event.target.name}&smenu=${event.target.value}`
      );
    }

    handleClose();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  moment.locale("en");
  var m1 = moment();
  m1.locale(); // en
  const currDay = m1.format("dddd");

  const filteredMenus =
    menus && menus.length
      ? menus.filter((x) => {
          const parsedSchedule = x.schedule ? JSONParse(x.schedule) : {};
          const currentSchedule = parsedSchedule[currDay];

          const hasRange = !!(
            currentSchedule &&
            currentSchedule.range &&
            currentSchedule.range.length
          );
          const openHour = hasRange
            ? currentSchedule.range[0]
              ? parseInt(currentSchedule.range[0].replace(":", ""))
              : 0
            : 0;
          const closeHour = hasRange
            ? currentSchedule.range[1]
              ? parseInt(currentSchedule.range[1].replace(":", ""))
              : 0
            : 0;
          const currentHour = parseInt(moment().format("HHmm"));
          const isOpen =
            (openHour === 0 && closeHour === 0) ||
            (currentHour > openHour && currentHour < closeHour);

          return (
            (!hasRange || isOpen) &&
            !(currentSchedule && currentSchedule?.closed)
          );
        })
      : [];

  return (
    <>
      <Zoom in={true} timeout={transitionDuration} unmountOnExit>
        <Fab
          size="medium"
          className={classes.fab}
          color={"primary"}
          onClick={handleOpen}
        >
          <FilterListIcon />
        </Fab>
      </Zoom>

      <Dialog
        container={() => document.getElementById("mainBox")}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        classes={{ paper: classes.paper }}
        style={{
          position: "absolute",
        }}
        BackdropProps={{
          style: {
            position: "absolute",
          },
        }}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {I18n.get(businessLanguage[btype]["Select a menu"])}
            </Typography>
          </Toolbar>
        </AppBar>

        <DialogContent>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormLabel component="legend">
              {I18n.get(
                businessLanguage[btype]["Choose the menu you want to see"]
              )}
            </FormLabel>
            <RadioGroup
              aria-label="gender"
              name="gender1"
              value={selectedMenu}
              onChange={handleChange}
            >
              <FormControlLabel
                value={""}
                name={"all"}
                control={<Radio />}
                label={I18n.get(businessLanguage[btype]["Complete menu"])}
              />
              {filteredMenus && filteredMenus.length
                ? filteredMenus.map((menu) => {
                    if (menu._deleted) {
                      return null;
                    }

                    return (
                      <FormControlLabel
                        key={menu.id}
                        value={menu.id}
                        name={menu.title}
                        disabled={menu.status !== "ACTIVE"}
                        control={<Radio />}
                        label={menu.title}
                      />
                    );
                  })
                : null}
            </RadioGroup>
          </FormControl>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MenuDialog;
