import React from "react";
import { I18n } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import { Typography, Box } from "@material-ui/core";

import OrderItemComponent from "./OrderItem";
import NoDataItem from "./NoDataItem";
import useReducer from "../../reducers";
// import { handleGetGroupedItems } from '../../util';

const useStyles = makeStyles((theme) => ({
  fullList: {
    width: "auto",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    height: 1,
  },
  title: {
    fontWeight: 700,
  },
}));

const PendingItems = () => {
  const classes = useStyles();
  const [
    {
      orderReducer: { orderItems },
    },
  ] = useReducer();

  const getPendingItems = (items) => {
    return items && items.length
      ? items.filter(
          (c) =>
            c.status === "PENDING" &&
            !["DISCOUNT", "COUPON", "REFUND"].includes(c.itemType)
        )
      : [];
  };

  const pendingItems = React.useMemo(() => getPendingItems(orderItems), [
    orderItems,
  ]);

  return (
    <div role="presentation" className={classes.fullList}>
      <Box px={2}>
        <Typography variant="subtitle1" className={classes.title}>
          {I18n.get("Pending Items")}
        </Typography>
      </Box>

      <List dense style={{ flex: 1, overflow: "auto" }}>
        {pendingItems && pendingItems.length ? (
          pendingItems.map((item) => (
            <OrderItemComponent key={item.id} item={item} />
          ))
        ) : (
          <NoDataItem
            title={I18n.get("No pending items in your cart")}
            secondary={I18n.get("Add items to your order")}
          />
        )}
      </List>
    </div>
  );
};

export default PendingItems;
