import React from "react";
import { Button, Typography, Grid } from "@material-ui/core";

import { toCurrency } from "../../util";

const PaymentButtons = ({ isCard, setPaymentAmount, maxAmount, order }) => {
  const installmentsAccepted = !!(order && order.orderType === "travel");
  return installmentsAccepted ? (
    <>
      <Typography
        variant="subtitle1"
        color="primary"
        style={{ marginTop: 16, marginBottom: 8 }}
      >
        Choose payment amount
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            disabled={10000 > maxAmount}
            onClick={setPaymentAmount(10000)}
          >
            $100
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            disabled={20000 > maxAmount}
            onClick={setPaymentAmount(20000)}
          >
            $200
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            disabled={30000 > maxAmount}
            onClick={setPaymentAmount(30000)}
          >
            $300
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            disabled={50000 > maxAmount}
            onClick={setPaymentAmount(50000)}
          >
            $500
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            disabled={100000 > maxAmount}
            onClick={setPaymentAmount(100000)}
          >
            $1,000
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            disabled={200000 > maxAmount}
            onClick={setPaymentAmount(200000)}
          >
            $2,000
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            disabled={!isCard && maxAmount > 1000000}
            onClick={setPaymentAmount(maxAmount)}
          >
            Total ${toCurrency((maxAmount || 0).toString())}
          </Button>
        </Grid>
      </Grid>
    </>
  ) : (
    <>
      <Typography
        variant="subtitle1"
        color="primary"
        style={{ marginTop: 16, marginBottom: 8 }}
      >
        {isCard
          ? "Realizar pago con tarjeta"
          : "Generar línea de captura para pago en OXXO"}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            disabled={!isCard && maxAmount > 1000000}
            onClick={setPaymentAmount(maxAmount)}
          >
            Total ${toCurrency((maxAmount || 0).toString())}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default PaymentButtons;
