import React from "react";
import Box from "@material-ui/core/Box";
import { I18n } from "aws-amplify";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import { API, graphqlOperation } from "aws-amplify";

import useReducer from "../../reducers";
import * as mutations from "../../graphql/mutations";
import businessLanguage from "../../lib/businessLanguage";
import { filterOrderItems } from "../../util";

const OrderGuestsSelect = () => {
  const [
    {
      orderReducer: { currentOrder, orderItems },
      restaurantReducer: { currentRestaurant },
    },
  ] = useReducer();
  const btype =
    (currentRestaurant && currentRestaurant.businessType) || "RESTAURANT";
  const [guestsQty, setGuestsQty] = React.useState(currentOrder.guests || "");

  const handleChange = async (event) => {
    setGuestsQty(event.target.value);

    if (currentOrder.guests !== +event.target.value) {
      const orderItemsGreater = filterOrderItems(orderItems).filter(
        (x) => x.guest > event.target.value
      );

      await API.graphql({
        ...graphqlOperation(mutations.updateOrder, {
          input: {
            id: currentOrder.id,
            guests: +event.target.value,
            _version: currentOrder._version,
          },
        }),
        authMode: "AWS_IAM",
      });

      if (orderItemsGreater && orderItemsGreater.length) {
        await Promise.all(
          orderItemsGreater.map(async (item) => {
            const original = orderItems.find((x) => x.id === item.id);

            API.graphql({
              ...graphqlOperation(mutations.updateOrderItem, {
                input: {
                  id: item.id,
                  guest: 0,
                  _version: original._version,
                },
              }),
              authMode: "AWS_IAM",
            });
          })
        );
      }
    }
  };

  return (
    <Box p={1} mb={1} display="flex" alignItems="center">
      <Typography style={{ flex: 1 }}>
        {I18n.get(businessLanguage[btype]["Table guests"])}
      </Typography>
      <FormControl
        size="medium"
        variant="outlined"
        hiddenLabel
        fullWidth
        style={{ flex: 1 }}
      >
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={guestsQty}
          onChange={handleChange}
          displayEmpty
        >
          <MenuItem value="" disabled>
            {I18n.get("Select guests")}
          </MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={6}>6</MenuItem>
          <MenuItem value={7}>7</MenuItem>
          <MenuItem value={8}>8</MenuItem>
          <MenuItem value={9}>9</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default OrderGuestsSelect;
