import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Skeleton from "@material-ui/lab/Skeleton";
import { Typography } from "@material-ui/core";

const ProductItemSkeleton = () => {
  return (
    <ListItem disableGutters>
      <ListItemText
        primary={<Skeleton variant="text" animation="wave" width={"80%"} />}
        secondary={<Skeleton variant="text" animation="wave" width={"60%"} />}
      />
      <ListItemSecondaryAction>
        <Typography>
          <Skeleton variant="text" animation="wave" width={40} />
        </Typography>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default ProductItemSkeleton;
