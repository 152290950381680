import React from "react";
import { I18n } from "aws-amplify";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepContent from "@material-ui/core/StepContent";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import { useFormik } from "formik";
import { API, graphqlOperation } from "aws-amplify";
import { useSnackbar } from "notistack";

import * as mutations from "../graphql/mutations";
import useQuery from "../hooks/useQuery";
import useReducer from "../reducers";
import PaymentIdStep from "../components/Invoicing/PaymentIdStep";
import RfcSelectStep from "../components/Invoicing/RfcSelectStep";
import InvoiceDataStep from "../components/Invoicing/InvoiceDataStep";
import { isEmptyObject, JSONParse } from "../util";
import DownloadInvoiceStep from "../components/Invoicing/DownloadInvoiceStep";
import ValidationSchema from "../components/Invoicing/ValidationSchema";

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
    textAlign: "right",
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  subtitle: {
    marginBottom: theme.spacing(2),
  },
}));

function getSteps() {
  return ["Add your payment ID", "Select or add your RFC", "Invoice data"];
}

const Invoicing = () => {
  const classes = useStyles();
  const history = useHistory();
  const query = useQuery();
  const [
    {
      invoiceReducer: { invoicePayment, loadingPayment },
      loginReducer: {
        authState,
        currentUser,
        browserData: { country },
      },
    },
    dispatch,
  ] = useReducer();
  const { principalRfc } = currentUser || {};
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();
  const { enqueueSnackbar } = useSnackbar();
  const pid = query.get("pid");

  const isLastPage = activeStep === steps.length;

  const formik = useFormik({
    initialValues: {
      paymentId: "",
      selectedRfc: "",
    },
    validationSchema: ValidationSchema[activeStep],
    onSubmit: async (values, actions) => {
      if (activeStep === steps.length - 1) {
        dispatch({
          type: "LOADING_START",
          payload: { message: "Generating invoice..." },
        });
        const { fiscalData } = currentUser || {};
        const rfcs = (fiscalData && JSONParse(fiscalData)) || {};
        const fd = rfcs[values.selectedRfc];
        console.log(fd, values.paymentId);
        handleCreateInvoice(values.paymentId, fd);
      } else {
        handleNext();
        actions.setTouched({});
        actions.setSubmitting(false);
      }
    },
  });

  React.useEffect(() => {
    if (country && country !== "MX") {
      history.replace("/main");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  React.useEffect(() => {
    let mounted = true;

    if (mounted && principalRfc) {
      formik.setFieldValue("selectedRfc", principalRfc);
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [principalRfc]);

  React.useEffect(() => {
    let mounted = true;

    if (mounted && pid) {
      formik.setFieldValue("paymentId", pid);
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pid]);

  const handleCreateInvoice = async (paymentId, fiscalData) => {
    let apiName = "api616cdc2a";
    let path = "/invoice/createInvoice";

    const result = await API.post(apiName, path, {
      body: {
        paymentId,
        fiscalData,
      },
    });
    console.log("result", result);

    if (result.error) {
      const invoiceResponse =
        result && result.body ? JSONParse(result.body) : {};

      if (!(invoiceResponse && invoiceResponse.pdfFile)) {
        enqueueSnackbar(I18n.get(result.error), {
          variant: "error",
          autoHideDuration: 5000,
        });
      } else {
        dispatch({
          type: "INVOICE_SET",
          payload: { invoice: invoiceResponse },
        });

        formik.setSubmitting(false);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        dispatch({ type: "LOADING_STOP" });
      }
    } else {
      dispatch({ type: "INVOICE_SET", payload: { invoice: result } });

      // Verificar si la orden tiene asignado al customer
      // si no, asignarle el customer que facturo
      if (invoicePayment) {
        const orderCustomer =
          invoicePayment &&
          invoicePayment.order &&
          invoicePayment.order.customer &&
          invoicePayment.order.customer.id;

        if (!orderCustomer) {
          // console.log("add customer to order");

          const { data } = await API.graphql({
            ...graphqlOperation(mutations.updateOrder, {
              input: {
                id: invoicePayment.order.id,
                orderCustomerId: currentUser.username,
                _version: invoicePayment.order._version,
              },
            }),
            authMode: "AWS_IAM",
          });
          const { updateOrder: updateOrderResponse } = data || {};

          console.log("updateOrderResponse", updateOrderResponse);
        }
      }

      formik.setSubmitting(false);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      dispatch({ type: "LOADING_STOP" });
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    formik.setSubmitting(false);
    formik.handleReset();
    dispatch({ type: "INVOICE_RESET" });
    setActiveStep(0);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return <PaymentIdStep formik={formik} />;
      case 1:
        return <RfcSelectStep formik={formik} />;
      case 2:
        return <InvoiceDataStep formik={formik} handleReset={handleReset} />;
      default:
        return "Unknown step";
    }
  }

  return (
    <>
      <Typography variant="h4">{I18n.get("Invoicing")}</Typography>
      <Typography variant="subtitle1" className={classes.subtitle}>
        {I18n.get("Find or generate your invoice")}
      </Typography>

      {authState === "signedIn" ? (
        <form onSubmit={formik.handleSubmit} noValidate>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{I18n.get(label)}</StepLabel>
                <StepContent>
                  {getStepContent(index)}
                  <div className={classes.actionsContainer}>
                    <div>
                      <Button
                        disabled={
                          activeStep === 0 ||
                          (activeStep === 2 &&
                            (loadingPayment ||
                              !invoicePayment ||
                              isEmptyObject(invoicePayment)))
                        }
                        onClick={handleBack}
                        className={classes.button}
                      >
                        {I18n.get("Back")}
                      </Button>
                      <Button
                        type="submit"
                        disabled={
                          formik.isSubmitting ||
                          (activeStep === 2 &&
                            (loadingPayment ||
                              !invoicePayment ||
                              isEmptyObject(invoicePayment)))
                        }
                        variant="contained"
                        color="primary"
                        className={classes.button}
                      >
                        {I18n.get(
                          activeStep === steps.length - 1 ? "Confirm" : "Next"
                        )}
                      </Button>
                    </div>
                  </div>
                </StepContent>
              </Step>
            ))}
          </Stepper>
          {isLastPage && (
            <Paper square elevation={0} className={classes.resetContainer}>
              <DownloadInvoiceStep />
              <div style={{ textAlign: "right" }}>
                <Button onClick={handleReset} className={classes.button}>
                  {I18n.get("Reset")}
                </Button>
              </div>
            </Paper>
          )}
        </form>
      ) : (
        <Box
          flex={1}
          p={4}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <Typography>
            {I18n.get("Login to retrieve or generate your invoices")}
          </Typography>
          <Button
            variant="text"
            onClick={() => dispatch({ type: "LOGIN_OPEN" })}
            style={{ marginTop: 16 }}
          >
            {I18n.get("Sign In")}
          </Button>
        </Box>
      )}
    </>
  );
};

export default Invoicing;
