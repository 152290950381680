import React from "react";
import { I18n } from "aws-amplify";
import { saveAs } from "file-saver";
import { Box, Button } from "@material-ui/core";
import { API, graphqlOperation } from "aws-amplify";
import Skeleton from "@material-ui/lab/Skeleton";

import { JSONParse } from "../../util";
import useFileUrl from "../../hooks/useFileUrl";
import * as queries from "../../graphql/queries";

const FileButton = ({ text, file }) => {
  const { path, Key } = file ? JSONParse(file) : {};
  const parsedPath = path.split("/");
  const level = parsedPath.shift();
  const key = `${parsedPath.join("/")}/${Key}`;
  const url = useFileUrl({ key, level });

  const handleDownload = (file) => () => {
    var blob = new Blob([file.Body], {
      type: file.ContentType,
    });
    saveAs(blob, Key);
  };

  return (
    <Button
      fullWidth
      variant="outlined"
      color="secondary"
      onClick={handleDownload(url)}
    >
      {I18n.get("Download")} {text}
    </Button>
  );
};

const DownloadInvoiceButtons = ({ invoiceID }) => {
  const [loading, setLoading] = React.useState(true);
  const [invoice, setInvoice] = React.useState({});
  const { pdfFile, xmlFile } = invoice || {};

  React.useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      try {
        const { data } = await API.graphql({
          ...graphqlOperation(queries.getInvoice, {
            id: invoiceID,
          }),
          authMode: "AWS_IAM",
        });
        const { getInvoice: response = {} } = data || {};
        console.log("getInvoice response", response);

        if (mounted) {
          setInvoice(response);
        }
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    };

    if (invoiceID) {
      fetchData();
    }

    return () => {
      mounted = false;
    };
  }, [invoiceID]);

  return !loading ? (
    <Box
      mt={2}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <div>
        <FileButton text={"pdf"} file={pdfFile} />
      </div>
      <div>
        <FileButton text={"xml"} file={xmlFile} />
      </div>
    </Box>
  ) : (
    <Box
      mt={2}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <div>
        <Skeleton
          variant="rect"
          animation="wave"
          height={36}
          width={"100%"}
          style={{ borderRadius: 4 }}
        />
      </div>
      <div style={{ marginLeft: 8 }}>
        <Skeleton
          variant="rect"
          animation="wave"
          height={36}
          width={"100%"}
          style={{ borderRadius: 4 }}
        />
      </div>
    </Box>
  );
};

export default DownloadInvoiceButtons;
