import React from "react";
import { I18n } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import {
  TextField,
  Box,
  Button,
  FormHelperText,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as Yup from "yup";
import { API, graphqlOperation } from "aws-amplify";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { useSnackbar } from "notistack";

import { cfdiUses, rfcValido, JSONParse } from "../../util";
import * as mutations from "../../graphql/mutations";
import useReducer from "../../reducers";
import ConfirmDialog from "../ConfirmDialog";

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
  textField: {
    margin: theme.spacing(1, 0),
  },
  paper: {
    width: "80%",
    maxHeight: 435,
  },
}));

const ValidationSchema = Yup.object().shape({
  rfc: Yup.string()
    .required("Valid RFC required")
    .transform(function (currentValue) {
      return this.isType(currentValue) && currentValue !== null
        ? currentValue.toUpperCase().trim()
        : currentValue;
    })
    .test("rfc", "Ingresa un RFC válido", function (value) {
      if (!value) return true;
      if (value.length < 12) return false;
      return rfcValido(value);
    }),
  name: Yup.string().required("Your name is required"),
  cfdiUse: Yup.string().required("CFDI Use is required"),
  email: Yup.string().email("Valid email required"),
});

export const FiscalDataFormComponent = ({ handleOpenForm, selectedItem }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const inputLabel = React.useRef(null);
  const [confirmOpen, setConfirmOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [labelWidth, setLabelWidth] = React.useState(0);
  const [isPrincipal, setIsPrincipal] = React.useState(false);
  const [
    {
      loginReducer: { currentUser },
    },
    dispatch,
  ] = useReducer();
  const { fiscalData, principalRfc } = currentUser || {};
  const rfcs = (fiscalData && JSONParse(fiscalData)) || {};

  React.useEffect(() => {
    if (inputLabel && inputLabel.current) {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      rfc: "",
      name: "",
      cfdiUse: "P01",
      email: "",
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      console.log("values", values);
      const rfc = values.rfc.trim().toUpperCase();
      const input = {
        id: currentUser.id,
        _version: currentUser._version,
        fiscalData: rfcs,
      };
      input.fiscalData[rfc] = {
        ...rfcs[rfc],
        rfc,
        name: values.name,
        cfdiUse: values.cfdiUse,
      };

      if (values.email) {
        input.fiscalData[rfc].email = values.email;
      }
      input.fiscalData = JSON.stringify(input.fiscalData);

      if (selectedItem && isPrincipal) {
        input.principalRfc = selectedItem.rfc;
      }

      console.log("input", input);

      try {
        const { data } = await API.graphql({
          ...graphqlOperation(mutations.updateUser, { input }),
          authMode: "AWS_IAM",
        });
        const { updateUser: updatedUser } = data || {};

        console.log("updatedUser", updatedUser);
        enqueueSnackbar("RFC creado exitosamente.", {
          variant: "success",
          autoHideDuration: 5000,
        });
        dispatch({
          type: "LOGIN_SET_USER",
          payload: { user: updatedUser },
        });
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
        handleOpenForm(false)();
      }
    },
  });

  React.useEffect(() => {
    if (selectedItem) {
      formik.setValues({
        rfc: selectedItem.rfc,
        name: selectedItem.name,
        cfdiUse: selectedItem.cfdiUse,
        email: selectedItem.email,
      });

      if (principalRfc === selectedItem.rfc) {
        setIsPrincipal(true);
      } else {
        setIsPrincipal(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  const handleDelete = async () => {
    if (selectedItem) {
      setLoading(true);
      const currentRfcs = { ...rfcs };
      delete currentRfcs[selectedItem.rfc];

      try {
        const input = {
          id: currentUser.id,
          _version: currentUser._version,
          fiscalData: JSON.stringify(currentRfcs),
        };

        console.log("input", input);

        const { data } = await API.graphql({
          ...graphqlOperation(mutations.updateUser, { input }),
          authMode: "AWS_IAM",
        });
        const { updateUser: updatedUser } = data || {};

        console.log("updatedUser", updatedUser);
        dispatch({
          type: "LOGIN_SET_USER",
          payload: { user: updatedUser },
        });
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
        handleOpenForm(false)();
      }
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <TextField
        id="rfc"
        name="rfc"
        label={"RFC"}
        className={classes.textField}
        fullWidth
        size="medium"
        variant="outlined"
        inputProps={{
          spellCheck: "false",
        }}
        disabled={Boolean(selectedItem)}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.rfc}
        error={formik.errors.rfc && formik.touched.rfc}
        helperText={
          formik.errors.rfc && formik.touched.rfc
            ? formik.errors.rfc
            : I18n.get("Add your RFC")
        }
      />
      <TextField
        id="name"
        name="name"
        label={"Name or company"}
        className={classes.textField}
        fullWidth
        size="medium"
        variant="outlined"
        inputProps={{
          spellCheck: "false",
        }}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.name}
        error={formik.errors.name && formik.touched.name}
        helperText={
          formik.errors.name && formik.touched.name
            ? formik.errors.name
            : I18n.get("Add your name or company name")
        }
      />
      <FormControl
        variant="outlined"
        fullWidth
        error={formik.errors.cfdiUse && formik.touched.cfdiUse}
        className={classes.textField}
      >
        <InputLabel ref={inputLabel}>CFDI Use</InputLabel>
        <Select
          labelId="cfdiUse"
          id="cfdiUse"
          name="cfdiUse"
          value={formik.values.cfdiUse}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          labelWidth={labelWidth}
        >
          {cfdiUses.map((use) => (
            <MenuItem key={use.key} value={use.key}>
              {use.key} - {use.value}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
          {formik.errors.cfdiUse && formik.touched.cfdiUse
            ? formik.errors.cfdiUse
            : I18n.get("Select the CFDI Use")}
        </FormHelperText>
      </FormControl>
      <TextField
        id="email"
        name="email"
        type="email"
        label={"Email"}
        className={classes.textField}
        fullWidth
        size="medium"
        variant="outlined"
        inputProps={{
          type: "email",
          spellCheck: "false",
        }}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email}
        error={formik.errors.email && formik.touched.email}
        helperText={
          formik.errors.email && formik.touched.email
            ? formik.errors.email
            : I18n.get("Add email for receive invoices")
        }
      />
      {selectedItem ? (
        <FormControlLabel
          control={
            <Switch
              checked={isPrincipal}
              onChange={() => setIsPrincipal((prev) => !prev)}
            />
          }
          label={I18n.get("Set as principal RFC")}
        />
      ) : null}
      <Box display="flex" justifyContent="flex-end" mt={2}>
        {selectedItem ? (
          <Button
            type="button"
            variant="outlined"
            style={{ marginRight: 16 }}
            disabled={loading}
            onClick={() => setConfirmOpen(true)}
          >
            {I18n.get("Delete")}
          </Button>
        ) : null}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {I18n.get("Save")}
        </Button>
      </Box>

      <ConfirmDialog
        keepMounted
        classes={{
          paper: classes.paper,
        }}
        open={confirmOpen}
        message={"Are you sure you want to delete?"}
        onClose={() => setConfirmOpen(false)}
        onConfirm={handleDelete}
      />
    </form>
  );
};

const FiscalDataForm = ({ handleOpenForm, openForm, selectedItem }) => {
  const classes = useStyles();

  return (
    <>
      <ListItem button onClick={handleOpenForm()} className={classes.nested}>
        <ListItemText primary="Nuevo" secondary="Agregar datos fiscales" />
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="add" onClick={handleOpenForm()}>
            <AddIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Collapse in={openForm} timeout="auto" unmountOnExit>
        <Box py={1} px={2}>
          <FiscalDataFormComponent
            handleOpenForm={handleOpenForm}
            selectedItem={selectedItem}
          />
        </Box>
      </Collapse>
    </>
  );
};

export default FiscalDataForm;
