const initialState = {
  activeTab: 0
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'TABS_SET_TAB':
      return { ...state, activeTab: action.payload.tab };
    case 'TABS_RESET':
      return initialState;
    default:
      return state;
  }
};

export default { initialState, reducer };
