import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
// import Slide from "@material-ui/core/Slide";
import { API, graphqlOperation } from "aws-amplify";
import { I18n } from "aws-amplify";

import {
  getTotal,
  handleAvailability,
  getDeliveryPrice,
  handleRestaurantIsEnabled,
  isEmptyObject,
  handleOrderSurcharges,
} from "../../util";
import DeliveryCheckout from "./DeliveryCheckout";
import TravelCheckout from "../Travel/TravelCheckout";
import CartButton from "./CartButton";
import useReducer from "../../reducers";
import * as queries from "../../graphql/queries";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  button: {
    color: theme.palette.background.paper,
    backgroundColor: theme.palette.success.main,
  },
}));

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const CheckoutComponent = ({ fromSite = false }) => {
  const classes = useStyles();
  const [
    {
      deliveryReducer: {
        openCheckout,
        orderType,
        deliveryCart,
        cartRestaurant,
        principalRestaurant,
        cartCompany,
        paymentMethod,
        deliveryAddress,
        deliveryOrderAmount,
        deliveryOrderTaxes,
        selectedRestaurant,
      },
    },
    dispatch,
  ] = useReducer();
  const {
    deliveryPrice,
    deliveryType,
    freeDeliveryThreshold,
    location,
    schedule,
  } = !isEmptyObject(principalRestaurant)
    ? principalRestaurant
    : selectedRestaurant || {};

  const { surcharges } = !isEmptyObject(principalRestaurant)
    ? principalRestaurant
    : cartRestaurant || {};

  const { businessType } = selectedRestaurant || cartCompany || {};

  const deliveryTotal = React.useMemo(
    () => getTotal(deliveryOrderAmount, deliveryOrderTaxes),
    [deliveryOrderAmount, deliveryOrderTaxes]
  );

  const { restaurantIsEnabled } = React.useMemo(
    () => handleRestaurantIsEnabled(schedule),
    [schedule]
  );

  React.useEffect(() => {
    if (
      deliveryTotal &&
      orderType === "todeliver" &&
      (deliveryTotal < freeDeliveryThreshold || !freeDeliveryThreshold)
    ) {
      const deliveryAmount = getDeliveryPrice({
        deliveryPrice,
        deliveryType,
        location,
        deliveryAddress,
      });
      dispatch({
        type: "DELIVERY_SET_DELIVERY_PRICE_AMOUNT",
        payload: { deliveryCostAmount: deliveryAmount },
      });
    } else {
      dispatch({
        type: "DELIVERY_SET_DELIVERY_PRICE_AMOUNT",
        payload: { deliveryCostAmount: 0 },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryPrice, deliveryTotal, deliveryType, orderType]);

  React.useEffect(() => {
    if (openCheckout) {
      if (
        !restaurantIsEnabled &&
        !["MARKET", "TRAVEL", "HOTEL"].includes(businessType)
      ) {
        dispatch({
          type: "DELIVERY_TOGGLE_OUT_OF_TIME_DIALOG",
          payload: { open: true },
        });
        return;
      }

      API.graphql({
        ...graphqlOperation(queries.getRestaurant, {
          id: selectedRestaurant.id,
        }),
        authMode: "AWS_IAM",
      })
        .then(({ data: restaurant }) => {
          const {
            getRestaurant: { products: { selectedRestItems } } = {
              products: { items: [] },
            },
          } = restaurant || {};

          const unavailableItems =
            selectedRestItems && selectedRestItems.length
              ? selectedRestaurant.businessType === "RESTAURANT"
                ? selectedRestItems.filter(
                    (x) =>
                      !handleAvailability(x) &&
                      (x.deliveryEnabled || x.preorderingEnabled) &&
                      !x._deleted
                  )
                : []
              : [];

          const difference = deliveryCart.filter((x) =>
            unavailableItems.map((u) => u.id).includes(x.productID)
          );
          console.log("unavailable difference", difference);

          if (difference && difference.length) {
            dispatch({
              type: "DELIVERY_SET_UNAVAILABLE_CART_ITEMS",
              payload: { unavailableCartItems: difference },
            });
            dispatch({
              type: "DELIVERY_TOGGLE_UNAVAILABLE_CART_DIALOG",
              payload: { open: true },
            });
            return;
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openCheckout]);

  React.useEffect(() => {
    if (paymentMethod !== "card" || businessType === "MARKET") {
      dispatch({
        type: "DELIVERY_SET_TIP_AMOUNT",
        payload: { tipAmount: "" },
      });
      dispatch({
        type: "DELIVERY_SET_TIP_TYPE",
        payload: { tipType: 4 },
      });
    }
  }, [businessType, paymentMethod]);

  React.useEffect(() => {
    if (
      ["togo", "todeliver", "dinein"].includes(orderType) &&
      deliveryOrderAmount
    ) {
      const surchargesArr = handleOrderSurcharges(
        orderType,
        surcharges,
        deliveryOrderAmount
      );
      console.log("surchargesArr", surchargesArr);
      dispatch({ type: "DELIVERY_SET_SURCHARGES", payload: { surchargesArr } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderType, deliveryOrderAmount]);

  const handleClickOpen = () => {
    dispatch({
      type: "DELIVERY_TOGGLE_CHECKOUT",
      payload: { openCheckout: true },
    });
  };

  const handleClose = () => {
    dispatch({
      type: "DELIVERY_TOGGLE_CHECKOUT",
      payload: { openCheckout: false },
    });
  };

  return (
    <>
      <CartButton onClick={handleClickOpen} />
      <Dialog
        container={() => document.getElementById("mainBox")}
        fullScreen
        open={openCheckout}
        onClose={handleClose}
        // TransitionComponent={Transition}
        style={{
          zIndex: 1300,
          right: 0,
          bottom: 0,
          top: 0,
          left: 0,
          position: "absolute",
        }}
        BackdropProps={{
          style: {
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            zIndex: -1,
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            WebkitTapHighlightColor: "transparent",
          },
        }}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {I18n.get("Payment")}
            </Typography>
          </Toolbar>
        </AppBar>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            overflow: "auto",
          }}
        >
          {businessType === "TRAVEL" ? (
            <TravelCheckout fromSite={fromSite} />
          ) : (
            <DeliveryCheckout fromSite={fromSite} />
          )}
        </div>
      </Dialog>
    </>
  );
};

export default CheckoutComponent;
