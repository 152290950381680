import React from "react";
import _orderBy from "lodash/orderBy";
import Grid from "@material-ui/core/Grid";
import { Typography, Box, List } from "@material-ui/core";
import { API, graphqlOperation, I18n } from "aws-amplify";
import useInfiniteScroll from "react-infinite-scroll-hook";
import IconButton from "@material-ui/core/IconButton";
import ViewAgendaIcon from "@material-ui/icons/ViewAgenda";
import ViewListIcon from "@material-ui/icons/ViewList";

import * as queries from "../../graphql/queries";
import useReducer from "../../reducers";
import ProductCard from "../ProductCard";
import ProductCardSkeleton from "../ProductCardSkeleton";
import ProductItem from "../Product/ProductItem";
import ProductItemSkeleton from "../Product/ProductItemSkeleton";

const MenuProducts = ({ selectedMenu, fromSite = false, delivery = false }) => {
  const [loading, setLoading] = React.useState(true);
  const [items, setItems] = React.useState([]);
  const [loadingMore, setLoadingMore] = React.useState(false);
  const [token, setToken] = React.useState();
  const [
    {
      navReducer: { categoryView },
      deliveryReducer: { selectedRestaurant },
    },
    dispatch,
  ] = useReducer();
  const { businessType } = selectedRestaurant || {};

  React.useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      const productsbyMenuResponse = API.graphql({
        ...graphqlOperation(queries.productsbyMenu, {
          menuID: selectedMenu,
          limit: 20,
        }),
        authMode: "AWS_IAM",
      });

      const { data } = (await productsbyMenuResponse) || {};

      const { productsbyMenu: { items: products, nextToken } = {} } =
        data || {};

      if (mounted) {
        setLoading(false);

        if (delivery && businessType === "RESTAURANT") {
          setItems(
            products.filter(
              (x) =>
                x &&
                !x._deleted &&
                x.product &&
                !x.product._deleted &&
                (x.product.preorderingEnabled || x.product.deliveryEnabled)
            )
          );
        } else {
          setItems(products.filter((x) => x && !x._deleted));
        }
        setToken(nextToken);
      }
    };

    fetchData();

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [delivery, selectedMenu]);

  const handleLoadMore = async () => {
    setLoadingMore(true);

    if (!token) {
      setLoadingMore(false);
      return;
    }

    try {
      const productsbyMenuResponse = API.graphql({
        query: queries.productsbyMenu,
        variables: {
          menuID: selectedMenu,
          limit: 10,
          nextToken: token,
        },
        authMode: "AWS_IAM",
      });

      const { data } = (await productsbyMenuResponse) || {};

      const { productsbyMenu: { items: products, nextToken } = {} } =
        data || {};
      setLoadingMore(false);
      setItems([...items, ...products.filter((x) => x && !x._deleted)]);
      setToken(nextToken);
    } catch (err) {
      console.log("err", err);
      setLoadingMore(false);
    }
  };

  const infiniteRef = useInfiniteScroll({
    loading: loadingMore,
    hasNextPage: Boolean(token),
    onLoadMore: handleLoadMore,
    scrollContainer: "window",
  });

  const sortedProducts = _orderBy(
    items,
    ["product.status", "product.position"],
    ["asc", "asc"]
  ).filter((x) => !x?.product?._deleted && x?.product?.status === "ACTIVE");

  const toggleView = () => {
    dispatch({ type: "NAV_TOGGLE_CATEGORY_VIEW" });
  };

  return (
    <>
      {sortedProducts && sortedProducts.length ? (
        <Box width={"100%"} display="flex" alignItems="center">
          <Typography variant="subtitle2" style={{ flex: 1 }}>
            {I18n.get("Products")}
          </Typography>
          <IconButton edge="end" aria-label="view" onClick={toggleView}>
            {categoryView === "panel" ? (
              <ViewAgendaIcon fontSize="small" />
            ) : (
              <ViewListIcon fontSize="small" />
            )}
          </IconButton>
        </Box>
      ) : null}

      {categoryView === "panel" ? (
        <Grid container spacing={2} ref={infiniteRef}>
          {(loading
            ? Array.from(new Array(6))
            : sortedProducts
          ).map((item, index) =>
            item ? (
              <ProductCard
                key={item.id}
                index={index}
                product={item.product}
                delivery={delivery}
                fromSite={fromSite}
              />
            ) : (
              <ProductCardSkeleton key={index} />
            )
          )}
          {loadingMore && <ProductCardSkeleton />}
        </Grid>
      ) : (
        <List>
          {(loading
            ? Array.from(new Array(6))
            : sortedProducts
          ).map((item, index) =>
            item ? (
              <ProductItem
                key={item.id}
                index={index}
                product={item.product}
                delivery={delivery}
                fromSite={fromSite}
              />
            ) : (
              <ProductItemSkeleton key={index} />
            )
          )}
          {loadingMore && <ProductItemSkeleton />}
        </List>
      )}
    </>
  );
};

export default MenuProducts;
