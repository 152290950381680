const initialState = {
  amountToPay: 0,
  guestsChecked: [],
  tipAmount: "",
  tipType: 1,
  dividePayment: null,
  divide: false,
  checkoutStep: 0,
  paymentMethod: "",
  accountChargesEnabled: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "CHECKOUT_RESET":
      return {
        ...initialState,
      };
    case "CHECKOUT_SET_STEP":
      return { ...state, checkoutStep: action.payload.checkoutStep };
    case "CHECKOUT_SET_STEP_NEXT":
      return { ...state, checkoutStep: state.checkoutStep + 1 };
    case "CHECKOUT_SET_STEP_BACK":
      return { ...state, checkoutStep: state.checkoutStep - 1 };
    case "CHECKOUT_SET_DIVIDE":
      return {
        ...state,
        divide: action.payload.divide,
      };
    case "CHECKOUT_SET_DIVIDE_TYPE":
      return {
        ...state,
        dividePayment: action.payload.dividePayment,
      };
    case "CHECKOUT_SET_TIP_TYPE":
      return {
        ...state,
        tipType: action.payload.tipType,
      };
    case "CHECKOUT_SET_TIP_AMOUNT":
      return {
        ...state,
        tipAmount: action.payload.tipAmount,
      };
    case "CHECKOUT_SET_A2P":
      return {
        ...state,
        amountToPay: action.payload.amountToPay,
      };
    case "CHECKOUT_SET_GUESTSCHECKED":
      return {
        ...state,
        guestsChecked: action.payload.guestsChecked,
      };
    case "CHECKOUT_SET_PAYMENT_METHOD":
      return {
        ...state,
        paymentMethod: action.payload.paymentMethod,
      };
    case "CHECKOUT_SET_ACCOUNT_CHARGES_ENABLED":
      return {
        ...state,
        accountChargesEnabled: action.payload.accountChargesEnabled,
      };
    default:
      return state;
  }
};

export default { initialState, reducer };
