import React from "react";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import { I18n } from "aws-amplify";
import _omit from "lodash/omit";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import {
  ListItemIcon,
  TextField,
  Box,
  Button,
  Typography,
} from "@material-ui/core";
import ListSubheader from "@material-ui/core/ListSubheader";
import { makeStyles } from "@material-ui/core/styles";
// import { uuid } from "uuidv4";
import { v4 as uuid } from "uuid";
import moment from "moment";
import { Cache } from "aws-amplify";
import * as Sentry from "@sentry/browser";
import { useSnackbar } from "notistack";
import { API, graphqlOperation } from "aws-amplify";
import { isValidPhoneNumber } from "react-phone-number-input";
import _groupBy from "lodash/groupBy";

import * as queries from "../../graphql/queries";
import * as mutations from "../../graphql/mutations";
import {
  getTotal,
  clearEmpties,
  toCurrency,
  handleRestaurantIsEnabled,
  isEmptyObject,
  clean,
  getOrderSurchargesAmount,
} from "../../util";
import { useHistory } from "react-router-dom";
import useCheckout from "../../hooks/useCheckout";
import PaymentMethodDialog from "./PaymentMethodDialog";
import { JSONParse } from "../../util";
import useReducer from "../../reducers";
import DeliveryCustomerData from "./DeliveryCustomerData";
import OrderSummary from "../Order/OrderSummary";
import DeliveryTipsDialog from "./DeliveryTipsDialog";
import { green } from "@material-ui/core/colors";
import PaypalButton from "../Checkout/PaypalButton";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 0,
    paddingTop: 15,
    paddingBottom: 15,
    height: 56,
    minHeight: 56,
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
  },
}));

const DeliveryCheckoutNext = ({ handleBack }) => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [
    {
      loginReducer: { currentUser },
      deliveryReducer: {
        orderType,
        deliveryCart,
        principalRestaurant,
        cartRestaurant,
        deliveryNotes,
        paymentMethod,
        deliveryAddress,
        deliveryOrderAmount,
        deliveryOrderTaxes,
        deliveryDiscountAmount,
        tipAmount,
        unavailableCartItems,
        deliveryCostAmount,
        receiverPhone,
        receiverPhoneCode,
        receiverName,
        surchargesArr,
        tableID,
      },
      loadingReducer: { loading },
    },
    dispatch,
  ] = useReducer();
  const {
    id: restId,
    name: restName,
    address,
    schedule,
    businessType,
    currency,
  } = !isEmptyObject(principalRestaurant)
    ? principalRestaurant
    : cartRestaurant || {};
  const { formatted_address } = address ? JSONParse(address) : {};
  const checkout = useCheckout();

  const deliveryTotal = React.useMemo(
    () => getTotal(deliveryOrderAmount, deliveryOrderTaxes),
    [deliveryOrderAmount, deliveryOrderTaxes]
  );

  const { restaurantIsEnabled } = React.useMemo(
    () => handleRestaurantIsEnabled(schedule),
    [schedule]
  );

  const dataCompleted = React.useMemo(
    () => Boolean(receiverPhone && receiverName),
    [receiverPhone, receiverName]
  );

  const surchargesAmount = React.useMemo(() => {
    if (surchargesArr && surchargesArr.length) {
      return getOrderSurchargesAmount(surchargesArr);
    }
    return 0;
  }, [surchargesArr]);

  const total = React.useMemo(
    () =>
      deliveryTotal +
      +deliveryCostAmount +
      +tipAmount +
      surchargesAmount -
      deliveryDiscountAmount,
    [
      deliveryCostAmount,
      deliveryDiscountAmount,
      deliveryTotal,
      surchargesAmount,
      tipAmount,
    ]
  );

  console.log("total", total);

  const handleCreateOrder = async (isPaypal, paypalData) => {
    // crear items
    dispatch({ type: "LOADING_START" });

    if (
      !restaurantIsEnabled &&
      !["MARKET", "TRAVEL", "HOTEL"].includes(businessType)
    ) {
      dispatch({
        type: "DELIVERY_TOGGLE_OUT_OF_TIME_DIALOG",
        payload: { open: true },
      });
      dispatch({ type: "LOADING_STOP" });
      return;
    }

    if (unavailableCartItems && unavailableCartItems.length) {
      dispatch({
        type: "DELIVERY_TOGGLE_UNAVAILABLE_CART_DIALOG",
        payload: { open: true },
      });

      dispatch({ type: "LOADING_STOP" });
      return;
    }

    if (!(deliveryCart && deliveryCart.length) || !deliveryOrderAmount) {
      enqueueSnackbar(I18n.get("¡Es necesario agregar productos al carrito!"), {
        variant: "error",
        autoHideDuration: 3000,
      });

      dispatch({ type: "LOADING_STOP" });
      return;
    }

    if (
      principalRestaurant.minOrderAmount &&
      deliveryTotal < principalRestaurant.minOrderAmount
    ) {
      enqueueSnackbar(
        I18n.get(
          `El monto mínimo para servicio a domicilio de $${toCurrency(
            (principalRestaurant.minOrderAmount || 0).toString()
          )}`
        ),
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
      dispatch({ type: "LOADING_STOP" });
      return;
    }

    if (!paymentMethod) {
      enqueueSnackbar(I18n.get("¡Selecciona un método de pago!"), {
        variant: "error",
        autoHideDuration: 3000,
      });
      dispatch({ type: "LOADING_STOP" });
      return;
    }

    if (
      orderType === "todeliver" &&
      (!deliveryAddress || isEmptyObject(deliveryAddress))
    ) {
      enqueueSnackbar(
        I18n.get("¡Agrega o selecciona tu dirección de entrega!"),
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
      dispatch({ type: "LOADING_STOP" });
      return;
    }

    if (!receiverName) {
      enqueueSnackbar(
        "Ingresa tu nombre o de la persona que recibe tu pedido",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
      dispatch({ type: "LOADING_STOP" });
      return;
    }

    if (
      !receiverPhone ||
      !isValidPhoneNumber(receiverPhoneCode + receiverPhone)
    ) {
      enqueueSnackbar(
        "Ingresa un número de celular válido o verifica tu código de país",
        {
          variant: "error",
          autoHideDuration: 3000,
        }
      );
      dispatch({ type: "LOADING_STOP" });
      return;
    }

    if (currentUser && !(currentUser && currentUser.phone_number)) {
      await API.graphql({
        ...graphqlOperation(mutations.updateUser, {
          input: {
            id: currentUser.id,
            phone_number: receiverPhoneCode + receiverPhone,
            _version: currentUser._version,
          },
        }),
        authMode: "AWS_IAM",
      });
    }

    const groupedDeliveryCart = _groupBy(deliveryCart, "restaurantID");
    // console.log("deliveryCheckout groupedDeliveryCart", groupedDeliveryCart);

    try {
      const orderIds = await Promise.all(
        Object.keys(groupedDeliveryCart).map(async (restId) => {
          //arreglo de items en el carrito por restaurante
          const oitems = groupedDeliveryCart[restId];

          const now = new Date();
          const orderId = uuid();

          const deliveryDate = moment()
            .add(
              restaurantIsEnabled ? (businessType === "MARKET" ? 1 : 0) : 2,
              "days"
            )
            .startOf("day")
            .toISOString();

          try {
            await Promise.all(
              oitems.map(async (item) => {
                const original = oitems.find((x) => x.id === item.id);
                const input = {
                  ..._omit(original, ["companyID", "restaurantName"]),
                  tableID:
                    orderType === "dinein" && tableID ? tableID : orderType,
                  addedAt: now.toISOString(),
                  sentAt: now.toISOString(),
                  print: 0,
                  isPaid: false,
                  orderItemOrderId: orderId,
                  deliveryDate,
                  id: uuid(),
                };
                console.log("input", input);
                clearEmpties(input);

                await API.graphql({
                  ...graphqlOperation(mutations.createOrderItem, {
                    input,
                  }),
                  authMode: "AWS_IAM",
                });
              })
            );
          } catch (error) {
            console.log("error", error);
            Sentry.captureMessage(error, "fatal");
            Cache.removeItem("deliveryCart");
            Cache.removeItem("cartRestaurant");

            enqueueSnackbar(I18n.get("Error creating your products!"), {
              variant: "error",
              autoHideDuration: 3000,
            });

            dispatch({
              type: "DELIVERY_TOGGLE_CHECKOUT",
              payload: { openCheckout: false },
            });
            dispatch({ type: "LOADING_STOP" });
            return;
          }

          // crear orden
          const prevOrderRes = await API.graphql({
            ...graphqlOperation(queries.ordersByRestaurantByDate, {
              restaurantID: oitems[0].restaurantID,
              sortDirection: "DESC",
            }),
            authMode: "AWS_IAM",
          });
          const {
            data: {
              ordersByRestaurantByDate: { items: prevOrders } = { items: [] },
            } = {},
          } = prevOrderRes || {};
          const prevOrder =
            prevOrders && prevOrders.length ? prevOrders[0] : {};
          console.log("prevOrder result", prevOrder);
          const isSameDay = moment().isSame(prevOrder.createdAt, "day");
          console.log("isSameDay", isSameDay);

          const vars = {
            id: orderId,
            restaurantID: oitems[0].restaurantID,
            tableID: orderType === "dinein" && tableID ? tableID : orderType,
            status: "NEW",
            orderType: orderType,
            orderDaySerie: isSameDay ? (prevOrder.orderDaySerie || 0) + 1 : 1,
            orderSerie: (prevOrder.orderSerie || 0) + 1,
            receiverPhone: `${receiverPhoneCode}${receiverPhone}`,
            receiverName,
            selectedPaymentMethod: paymentMethod,
          };

          if (deliveryAddress && orderType !== "dinein") {
            console.log("deliveryAddress", deliveryAddress);
            clearEmpties(deliveryAddress);
            clean(deliveryAddress);
            console.log("deliveryAddress", deliveryAddress);
            let parsedAddress = {
              formatted_address: deliveryAddress.formatted_address,
            };
            if (
              deliveryAddress.geometry &&
              !isEmptyObject(deliveryAddress.geometry)
            ) {
              parsedAddress.geometry = deliveryAddress.geometry;
            }

            if (deliveryAddress.apartment) {
              parsedAddress.apartment = deliveryAddress.apartment;
            }

            if (deliveryAddress.place_id) {
              parsedAddress.place_id = deliveryAddress.place_id;
            }

            vars.deliveryAddress = JSON.stringify(parsedAddress);
            vars.deliveryAmount = deliveryCostAmount;
          }

          if (deliveryNotes) {
            vars.notes = deliveryNotes;
          }

          if (currentUser) {
            vars.orderCustomerId = currentUser.id;
          }

          if (surchargesArr && surchargesArr.length) {
            vars.surcharges = JSON.stringify(surchargesArr);
          }

          clean(vars);
          console.log("new order", vars);
          Cache.setItem(`currentDeliveryOrderId`, orderId, {
            priority: 1,
            expires: moment().add(1, "month").endOf("day").valueOf(),
          });

          try {
            const { data } = await API.graphql({
              ...graphqlOperation(mutations.createOrder, { input: vars }),
              authMode: "AWS_IAM",
            });
            const { createOrder: newOrder } = data || {};

            console.log("newOrder", newOrder);
          } catch (error) {
            console.log("error", error);
            Sentry.captureMessage(error, "fatal");
          }

          return orderId;
        })
      );
      console.log("orderIds", orderIds);
      const isMultiple =
        orderIds && orderIds.length && orderIds.length > 1 ? 1 : 0;
      console.log("isMultiple", isMultiple);

      if (isPaypal && paypalData) {
        await Promise.all(
          orderIds.map(async (oid) => {
            const paymentInput = {
              paymentData: JSON.stringify({
                payment_method: "paypal",
                ...paypalData,
              }),
              status: "succeeded",
              amount: total,
              tipAmount: tipAmount,
              deliveryAmount: deliveryCostAmount,
              paymentOrderId: oid,
              internal: true,
            };

            const { data } = await API.graphql({
              ...graphqlOperation(mutations.createPayment, {
                input: paymentInput,
              }),
              authMode: "AWS_IAM",
            });
            const { createPayment } = data || {};
            console.log("createPayment", createPayment);
          })
        );

        dispatch({ type: "DELIVERY_CLEAR_CART" });
        dispatch({
          type: "DELIVERY_TOGGLE_CHECKOUT",
          payload: { openCheckout: false },
        });
        dispatch({ type: "LOADING_STOP" });

        history.push({
          pathname: `/payment/paypal`,
          search: `?o=${orderIds[0]}&ot=${orderType}`,
        });
        return;
      }

      //  si es tarjeta, crear sesión
      if (paymentMethod && paymentMethod === "card") {
        await handlePay(orderIds, isMultiple);
      } else if (paymentMethod && paymentMethod === "oxxo") {
        handleOxxoPay(orderIds[0]);
      } else {
        Cache.removeItem("deliveryCart");
        Cache.removeItem("cartRestaurant");
        Cache.removeItem("cartCompany");

        enqueueSnackbar(I18n.get("Order created successfully!"), {
          variant: "success",
          autoHideDuration: 3000,
        });

        dispatch({ type: "DELIVERY_CLEAR_CART" });
        dispatch({
          type: "DELIVERY_TOGGLE_CHECKOUT",
          payload: { openCheckout: false },
        });
        dispatch({ type: "LOADING_STOP" });

        history.push(`/d/order/${orderIds[0]}`);
      }
    } catch (error) {
      console.log("error", error);
      return;
    }
  };

  const handleOxxoPay = (orderId) => {
    const surchargesAmount = +(
      getOrderSurchargesAmount(surchargesArr) || 0
    ).toFixed(0);
    const total =
      deliveryTotal + surchargesAmount + (tipAmount || 0) + deliveryCostAmount;

    Cache.removeItem("deliveryCart");
    Cache.removeItem("cartRestaurant");
    Cache.removeItem("cartCompany");

    enqueueSnackbar(I18n.get("Order created successfully!"), {
      variant: "success",
      autoHideDuration: 3000,
    });

    dispatch({ type: "DELIVERY_CLEAR_CART" });
    dispatch({
      type: "DELIVERY_TOGGLE_CHECKOUT",
      payload: { openCheckout: false },
    });
    dispatch({ type: "LOADING_STOP" });

    history.push({
      pathname: `/payment/oxxo`,
      search: `?o=${orderId}&rn=${receiverName}`,
      state: { amount: total, receiverName },
    });
  };

  const handlePay = async (orderIds, isMultiple = 0) => {
    const surchargesAmount = +(
      getOrderSurchargesAmount(surchargesArr) || 0
    ).toFixed(0);

    const lineItems = [
      {
        name: restName,
        description: "Your consumption",
        amount: deliveryTotal + surchargesAmount,
        currency: (currency || "usd").toLowerCase(),
        quantity: 1,
      },
    ];

    if (tipAmount) {
      lineItems.push({
        name: "Tip",
        description: "Tip of your consumption",
        amount: tipAmount,
        currency: (currency || "usd").toLowerCase(),
        quantity: 1,
      });
    }

    if (deliveryCostAmount && orderType === "todeliver") {
      lineItems.push({
        name: "Delivery",
        description: "Delivery charge",
        amount: deliveryCostAmount,
        currency: (currency || "usd").toLowerCase(),
        quantity: 1,
      });
    }

    console.log("lineItems", lineItems);

    const metadata = {
      isMultiple,
      orderIds: JSON.stringify(orderIds),
      userId: currentUser && currentUser.id ? currentUser.id : null,
      orderType: orderType || "todeliver",
      restaurantName: restName || null,
      restaurant: restId || null,
    };
    console.log("delivery checkout metadata", metadata);

    await checkout({
      lineItems,
      orderId: orderIds[0],
      metadata,
    });
  };

  const handleDeliveryAddressDialog = () => {
    if (orderType !== "todeliver") {
      return;
    }
    if (deliveryAddress && deliveryAddress.formatted_address) {
      return;
    }

    dispatch({
      type: "DELIVERY_TOGGLE_ADDRESS_DIALOG",
      payload: { open: true },
    });
  };

  return (
    <>
      <Box>
        <ListSubheader disableSticky>Customer info</ListSubheader>
        <DeliveryCustomerData />

        <List>
          {orderType !== "dinein" ? (
            <ListItem
              button={
                orderType === "todeliver" &&
                !(deliveryAddress && deliveryAddress.formatted_address)
              }
              onClick={handleDeliveryAddressDialog}
            >
              <ListItemIcon>
                <LocationOnIcon />
              </ListItemIcon>
              <ListItemText
                primary={I18n.get(
                  orderType === "togo"
                    ? "Collection Address"
                    : "Delivery Address"
                )}
                secondary={
                  orderType === "togo"
                    ? formatted_address
                      ? formatted_address
                      : ""
                    : (deliveryAddress && deliveryAddress.formatted_address) ||
                      I18n.get("Select or add your delivery address")
                }
              />
            </ListItem>
          ) : null}
          <Divider />
          <DeliveryTipsDialog />
          <Divider />
        </List>

        <Box p={1}>
          <TextField
            id="outlined-multiline-static"
            label={I18n.get("Add special indications")}
            value={deliveryNotes}
            multiline
            rows="4"
            variant="outlined"
            fullWidth
            onChange={(event) =>
              dispatch({
                type: "DELIVERY_SET_NOTES",
                payload: { deliveryNotes: event.target.value },
              })
            }
          />
        </Box>

        <OrderSummary delivery />
      </Box>

      <List>
        <PaymentMethodDialog />
      </List>

      {paymentMethod === "paypal" ? (
        dataCompleted ? (
          <>
            <Box
              mb={2}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography>
                {I18n.get("Complete your payment with PayPal")}
              </Typography>
            </Box>
            <Box px={2}>
              <PaypalButton
                amount={total}
                currency={currency}
                afterApprove={handleCreateOrder}
              />
            </Box>
          </>
        ) : (
          <Box mb={2} px={2} bgcolor="#fff" borderRadius={4}>
            <Typography variant="caption">
              {I18n.get("Add your name & phone to enable paypal payment.")}
            </Typography>
          </Box>
        )
      ) : (
        <Box display="flex">
          <Button
            style={{ flex: 1 }}
            size="large"
            fullWidth
            onClick={handleBack}
          >
            {I18n.get("Back")}
          </Button>

          <Button
            style={{ flex: 2 }}
            size="large"
            variant="contained"
            fullWidth
            disabled={loading}
            className={classes.button}
            onClick={handleCreateOrder}
          >
            {I18n.get("Confirm order")}
          </Button>
        </Box>
      )}
    </>
  );
};

export default DeliveryCheckoutNext;
