import React from "react";
import { Typography, GridList, GridListTile } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import makeStyles from "@material-ui/styles/makeStyles";

import * as queries from "../graphql/queries";
import Loading from "../components/Loading";
import Skeleton from "@material-ui/lab/Skeleton";
import CategoryCard from "../components/Category/CategoryCard";
import { JSONParse, getPictureUrl } from "../util";
import { Helmet } from "react-helmet";
import { useGeolocation } from "react-use";

const useStyles = makeStyles((theme) => ({
  curved: {
    position: "relative",
    background: theme.palette.background.paper,
    height: "30vh",
    borderBottomLeftRadius: "50% 15%",
    borderBottomRightRadius: "50% 15%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  title: {
    textAlign: "center",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    color: theme.palette.common.white,
    lineHeight: 1.35,
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    whiteSpace: "pre-wrap",
  },
  shadow: {
    paddingBottom: theme.spacing(2),
    background:
      "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  description: {
    textAlign: "center",
    margin: theme.spacing(0, 2),
    color: theme.palette.grey[300],
  },
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  gridList: {
    width: "100%",
  },
}));

const CompanyPage = () => {
  const styles = useStyles();
  const history = useHistory();
  // const state = usePermission({ name: "geolocation" });
  const geostate = useGeolocation();
  const { companySubdomain } = useParams();
  const [loading, setLoading] = React.useState(true);
  const [company, setCompany] = React.useState({});
  const [nearbyRestaurants, setNearbyRestaurants] = React.useState();

  console.log("geostate", geostate);
  const { loading: loadingGeo, latitude, longitude } = geostate || {};

  React.useEffect(() => {
    let mounted = true;
    API.graphql({
      ...graphqlOperation(queries.companybySubdomain, {
        subdomain: companySubdomain,
      }),
      authMode: "AWS_IAM",
    }).then((companybySubdomainResponse) => {
      const { items: [companyResp] = { items: [] } } =
        (companybySubdomainResponse &&
          companybySubdomainResponse.data &&
          companybySubdomainResponse.data.companybySubdomain) ||
        null;
      // console.log("companyResp", companyResp);
      if (companyResp) {
        if (mounted) {
          setCompany(companyResp);
        }
      } else {
        history.replace("/main");
        return;
      }
      setLoading(false);
    });

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companySubdomain]);

  React.useEffect(() => {
    const fetchData = async () => {
      const queryNearbyRestaurants = API.graphql({
        ...graphqlOperation(queries.nearbyRestaurants, {
          m: 25000,
          limit: 1000,
          location: {
            lat: latitude,
            lon: longitude,
          },
        }),
        authMode: "AWS_IAM",
      });

      const { data } = (await queryNearbyRestaurants) || {};
      const { nearbyRestaurants } = data || {};
      console.log("nearbyRestaurants", nearbyRestaurants);
      if (
        nearbyRestaurants &&
        nearbyRestaurants.items &&
        nearbyRestaurants.items.length
      ) {
        setNearbyRestaurants(
          nearbyRestaurants.items.filter(
            (x) =>
              x.restaurantCompanyId === company.id &&
              (x.hasDelivery || x.hasPreordering)
          )
        );
      }
    };

    if (company?.id && !loadingGeo && latitude && longitude) {
      fetchData();
    }
  }, [loadingGeo, latitude, longitude, company.id]);

  const coverPicture = company && company.cover ? JSONParse(company.cover) : {};
  const companyCover =
    company && company.cover
      ? getPictureUrl({ ...coverPicture, size: "521x0" })
      : {};
  console.log("companyCover", companyCover);

  if (loading) {
    return <Loading force />;
  }

  return (
    <>
      <Helmet>
        <title>{company.name}</title>
        <meta name="description" content={company.shortDescription} />
      </Helmet>
      <section
        className={styles.curved}
        style={{
          backgroundImage: `url(${companyCover})`,
        }}
      >
        <div className={styles.shadow}>
          <Typography variant="h4" noWrap gutterBottom className={styles.title}>
            {company.name}
          </Typography>
          <Typography variant="body2" className={styles.description}>
            {company.shortDescription}
          </Typography>
        </div>
      </section>

      <div className={styles.root}>
        <GridList cols={2} cellHeight={180} className={styles.gridList}>
          {(loading
            ? Array.from(new Array(6))
            : nearbyRestaurants && nearbyRestaurants.length
            ? nearbyRestaurants
            : company && company.restaurants && company.restaurants.items
            ? company.restaurants.items.filter(
                (x) => x.hasDelivery || x.hasPreordering
              )
            : []
          ).map((item, index) =>
            item ? (
              item._deleted ? null : (
                <CategoryCard key={item.id} category={item} isRest />
              )
            ) : (
              <GridListTile key={index}>
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height={180}
                  width={185}
                  style={{ borderRadius: 8 }}
                />
              </GridListTile>
            )
          )}
        </GridList>
      </div>
    </>
  );
};

export default CompanyPage;
