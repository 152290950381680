import React from 'react';
import { useHistory } from 'react-router-dom';

function ScrollToTopOnMount() {
  const { action } = useHistory();
  React.useEffect(() => {
    // console.log('action', action);
    if (action !== 'POP') {
      window.scrollTo(0, 0);
    }
  }, [action]);

  return null;
}

export default ScrollToTopOnMount;
