import React from "react";
import { API, graphqlOperation } from "aws-amplify";
import { useParams, Redirect } from "react-router-dom";

import ScrollToTopOnMount from "../ScrollToTopOnMount";
// import RouterBreadcrumbs from "../RouterBreadcrumbs";
import ProductHeader from "../Product/ProductHeader";
import useReducer from "../../reducers";
import * as queries from "../../graphql/queries";
import ProductForm from "../Product/ProductForm";

const DeliveryItemUpdate = ({ fromSite = false }) => {
  const { productId, oiid } = useParams();
  const [
    {
      deliveryReducer: { deliveryCart },
    },
  ] = useReducer();
  const [loading, setLoading] = React.useState(true);
  const [product, setProduct] = React.useState({});
  const orderItem =
    deliveryCart && deliveryCart.length
      ? deliveryCart.find((x) => x.id === oiid)
      : {};

  console.log("deliveryCart", deliveryCart);
  console.log("orderItem", orderItem);

  React.useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      if (mounted) {
        setLoading(true);
      }

      const { data } = await API.graphql({
        ...graphqlOperation(queries.getProduct, {
          id: productId,
          filter: {
            status: {
              eq: "ACTIVE",
            },
          },
        }),
        authMode: "AWS_IAM",
      });
      const { getProduct: item } = data || {};
      console.log("getOrderItem response", item);

      if (mounted) {
        setProduct(item);
        setLoading(false);
      }
    };

    fetchData();
    return () => {
      mounted = false;
    };
  }, [productId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!orderItem || !product || !oiid) {
    return <Redirect to="/d" />;
  }

  return (
    <>
      <ScrollToTopOnMount />
      {/* <RouterBreadcrumbs title={product.title} delivery={delivery} /> */}

      <ProductHeader loading={loading} product={product} delivery />

      <ProductForm
        productId={productId}
        orderItemId={oiid}
        product={product}
        orderItem={orderItem}
        delivery
        fromSite={fromSite}
      />
    </>
  );
};

export default DeliveryItemUpdate;
