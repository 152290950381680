import React from 'react';
import { getFileUrl } from '../util';

const useFileUrl = ({ key, level }) => {
  const [url, setUrl] = React.useState('');
  React.useEffect(() => {
    let mounted = true;

    const fetchUrl = async () => {
      if (key) {
        const response = await getFileUrl({ key, level });
        if (mounted) {
          setUrl(response);
        }
      }
    };

    fetchUrl();

    return () => {
      mounted = false;
    };
  }, [key, level]);

  return url;
};

export default useFileUrl;
