import React from "react";
import GridListTile from "@material-ui/core/GridListTile";
import Skeleton from "@material-ui/lab/Skeleton";

const MenuItemSkeleton = () => {
  return (
    <GridListTile>
      <Skeleton variant="rect" animation="wave" height={180} width={268} />
    </GridListTile>
  );
};

export default MenuItemSkeleton;
