import React from "react";
import PropTypes from "prop-types";
import { PromoProvider } from "@2dine/promos";
import Amplify, { Analytics, Cache } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { API, graphqlOperation } from "aws-amplify";
import { AmazonAIPredictionsProvider } from "@aws-amplify/predictions";

import awsmobile from "../aws-exports";
import awsConfig from "../aws-exports2";
import "../lib/vocabularies";
import useDarkMode from "../hooks/useDarkMode";
import useReducer from "../reducers";
import useAuth from "../hooks/useAuth";
import useBootstrapApp from "../hooks/useBootstrapApp";
import * as queries from "../graphql/queries";
import * as subscriptions from "../graphql/subscriptions";
import Main from "../Main";
import { SurveyProvider } from "@2dine/surveys";

awsmobile.oauth.redirectSignIn = `${window.location.origin}/redirect-auth/`;
awsmobile.oauth.redirectSignOut = `${window.location.origin}/`;

Amplify.configure(awsmobile);
Amplify.addPluggable(new AmazonAIPredictionsProvider());
// window.LOG_LEVEL = 'DEBUG';
Analytics.autoTrack("session", { enable: true });
Analytics.autoTrack("pageView", {
  enable: true,
  type: "SPA",
  // eventName: 'pageView',
  eventName: "pageViewWebClient",
  provider: "AWSPinpoint",
  // OPTIONAL, to get the current page url
  getUrl: () => {
    // the default function
    return window.location.origin + window.location.pathname;
  },
});

const Layout = ({ children }) => {
  useAuth();
  useBootstrapApp();
  const [{ theme }] = useDarkMode();
  const history = useHistory();

  const [
    {
      loginReducer: { authState },
      orderReducer: { currentOrder },
    },
    dispatch,
  ] = useReducer();
  const currOrderId = currentOrder && currentOrder.id;
  const cachedCurrentDeliveryOrderId = Cache.getItem(`currentDeliveryOrderId`);
  const cachedBondOrderId = Cache.getItem(`bondOrderId`);

  React.useEffect(() => {
    const handleSubscription = (data) => {
      if (currOrderId === (data && data.id)) {
        if (["DELETED", "CLOSED", "CANCELLED"].includes(data.status)) {
          Cache.removeItem("currentOrderId");
          Cache.removeItem("currentRestaurantId");
          Cache.setItem("prevOrder", data.id);
          dispatch({ type: "ORDER_RESET" });
          dispatch({ type: "RESTAURANT_RESET" });
          dispatch({ type: "CHECKOUT_RESET" });
          history.push("/main");
        } else {
          API.graphql({
            ...graphqlOperation(queries.getOrder, { id: currOrderId }),
            authMode: "AWS_IAM",
          }).then((orderResponse) => {
            const currOrder =
              orderResponse && orderResponse.data && orderResponse.data.getOrder
                ? orderResponse.data.getOrder
                : null;
            if (currOrder) {
              dispatch({
                type: "ORDER_SET",
                payload: { currentOrder: currOrder },
              });
            }
          });
        }
      }

      if (cachedCurrentDeliveryOrderId === (data && data.id)) {
        if (
          ["DELETED", "CLOSED", "CANCELLED", "DELIVERED"].includes(data.status)
        ) {
          Cache.removeItem("deliveryCart");
          Cache.removeItem("cartRestaurant");
          Cache.removeItem("cartCompany");
          Cache.removeItem("currentDeliveryOrderId");
          dispatch({ type: "DELIVERY_RESTAURANT_RESET" });
          history.push("/d");
        } else {
          dispatch({
            type: "DELIVERY_SET_ORDER",
            payload: { currentDeliveryOrder: data },
          });
        }
      }

      if (cachedBondOrderId === (data && data.id)) {
        if (
          ["DELETED", "CLOSED", "CANCELLED", "DELIVERED"].includes(data.status)
        ) {
          Cache.removeItem("bondOrderId");
          dispatch({ type: "BOND_RESET" });
          history.push("/bonos");
        } else {
          dispatch({
            type: "BOND_SET_BOND_ORDER",
            payload: { bondOrder: data },
          });
        }
      }
    };

    let updateSubscription;
    let deleteSubscription;
    if (currOrderId || cachedCurrentDeliveryOrderId) {
      // console.log("subs");
      updateSubscription = API.graphql({
        ...graphqlOperation(subscriptions.onUpdateOrder),
        // authMode:
        //   authState === "signedIn" ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
      }).subscribe({
        next: ({ value: { data } = {} }) => {
          console.log("onUpdateOrder", data);
          const { onUpdateOrder: updatedOrder } = data || {};
          handleSubscription(updatedOrder);
        },
        error: (error) => {
          console.warn("onUpdateOrder Error", error);
        },
      });

      deleteSubscription = API.graphql({
        ...graphqlOperation(subscriptions.onDeleteOrder),
        // authMode:
        //   authState === "signedIn" ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
      }).subscribe({
        next: ({ value: { data } = {} }) => {
          console.log("onDeleteOrder", data);
          const { onDeleteOrder: deletedOrder } = data || {};
          handleSubscription(deletedOrder);
        },
        error: (error) => {
          console.warn("onDeleteOrder Error", error);
        },
      });
    }

    return () => {
      if (updateSubscription) {
        updateSubscription.unsubscribe();
      }
      if (deleteSubscription) {
        deleteSubscription.unsubscribe();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cachedBondOrderId, cachedCurrentDeliveryOrderId, currOrderId, authState]);

  React.useEffect(() => {
    var metaThemeColor = document.querySelector("meta[name=theme-color]");
    metaThemeColor.setAttribute("content", theme.palette.background.paper);
  }, [theme.palette.background.paper]);

  React.useEffect(() => {
    const fetchBrowserData = async () => {
      let apiName = "api616cdc2a";
      let path = "/browserData";

      try {
        const result = await API.get(apiName, path);
        dispatch({
          type: "BROWSER_SET_DATA",
          payload: { browserData: result },
        });
      } catch (error) {
        console.log("error", error);
        dispatch({
          type: "BROWSER_SET_DATA",
          payload: {
            browserData: { country: "MX", ipInfo: { _region: "Mexico City" } },
          },
        });
      }
    };

    fetchBrowserData();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <PromoProvider
        awsConfig={awsConfig}
        options={{
          cdn: process.env.REACT_APP_CLOUDFRONT,
        }}
      >
        <SurveyProvider awsConfig={awsConfig}>
          <Main render={children} />
        </SurveyProvider>
      </PromoProvider>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Layout;
