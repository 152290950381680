import React from "react";
import { I18n } from "aws-amplify";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import MenuDialog from "../components/Menu/MenuDialog";
import useQuery from "../hooks/useQuery";
import MenuComponent from "../components/Menu/MenuComponent";
import MenuGrid from "../components/Menu/MenuGrid";
import ScrollToTopOnMount from "../components/ScrollToTopOnMount";
import useReducer from "../reducers";
import businessLanguage from "../lib/businessLanguage";

const MenuPage = () => {
  const query = useQuery();
  const [
    {
      restaurantReducer: { currentRestaurant },
    },
  ] = useReducer();
  const btype =
    currentRestaurant && currentRestaurant.businessType
      ? currentRestaurant.businessType
      : "RESTAURANT";

  // get selected menu
  const selectedMenu = query.get("smenu");
  const selectedTitle = query.get("mtitle");

  return (
    <>
      <ScrollToTopOnMount />
      <Box mb={1}>
        <Typography variant="h4">
          {I18n.get(businessLanguage[btype]["Menu"])}
        </Typography>
        {selectedTitle ? (
          <Typography
            variant="h6"
            color="secondary"
            style={{ marginBottom: 8 }}
          >
            {selectedTitle}
          </Typography>
        ) : null}
      </Box>

      {!selectedMenu ? (
        <MenuComponent />
      ) : (
        <MenuGrid selectedMenu={selectedMenu} />
      )}

      <MenuDialog />

      {/* <Box>
        <Typography variant="body2">
          Prohibida la venta de bebidas alcohólicas a menores de Edad.
        </Typography>
      </Box> */}
    </>
  );
};

export default MenuPage;
