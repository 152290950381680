import React from "react";
import moment from "moment";
import { Cache } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { ListItemSecondaryAction, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useSnackbar } from "notistack";
import { I18n } from "aws-amplify";

import useReducer from "../../reducers";
import ConfirmDialog from "../ConfirmDialog";

const useStyles = makeStyles((theme) => ({
  itemText: {
    WebkitLineClamp: 2,
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
  },
}));

const AddressItem = ({ address, index }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const [
    {
      deliveryReducer: { addressList },
    },
    dispatch,
  ] = useReducer();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteItem = () => {
    console.log(index);

    const updatedAddressList = [...addressList];

    updatedAddressList.splice(index, 1);

    Cache.setItem(`addressList`, [...updatedAddressList], {
      priority: 1,
      expires: moment().add(1, "year").endOf("day").valueOf(),
    });

    if (
      !updatedAddressList ||
      !(updatedAddressList && updatedAddressList.length)
    ) {
      dispatch({
        type: "DELIVERY_SET_ADDRESS",
        payload: { deliveryAddress: null },
      });
    }

    dispatch({
      type: "DELIVERY_SET_ADDRESS_LIST",
      payload: { addressList: [...updatedAddressList] },
    });
    enqueueSnackbar(I18n.get("Address deleted successfully!"), {
      variant: "success",
      autoHideDuration: 5000,
    });
    handleClose();
  };

  return (
    <>
      <ListItem
        button
        onClick={() => {
          Cache.setItem(`deliveryAddress`, addressList[index], {
            priority: 1,
            expires: moment().add(1, "month").endOf("day").valueOf(),
          });
          dispatch({
            type: "DELIVERY_SET_ADDRESS",
            payload: { deliveryAddress: addressList[index] },
          });
          dispatch({
            type: "DELIVERY_TOGGLE_ADDRESS_DIALOG",
            payload: { open: false },
          });
        }}
      >
        <ListItemText
          classes={{ primary: classes.itemText }}
          primary={address.formatted_address}
          secondary={address.apartment}
        />
        <ListItemSecondaryAction>
          <IconButton edge="end" aria-label="delete" onClick={handleOpen}>
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <ConfirmDialog
        keepMounted
        classes={{
          paper: classes.paper,
        }}
        open={open}
        onClose={handleClose}
        onConfirm={handleDeleteItem}
        message={I18n.get("Are you sure to delete the address?")}
      />
    </>
  );
};

export default AddressItem;
