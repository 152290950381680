import React from "react";
import moment from "moment";
import { I18n } from "aws-amplify";
import _orderBy from "lodash/orderBy";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { API, graphqlOperation } from "aws-amplify";

import * as queries from "../../graphql/queries";

import useReducer from "../../reducers";
import { toCurrency, getCardData } from "../../util";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(1),
  },
  table: {
    minWidth: 560,
  },
}));

const PaymentsComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const [
    {
      loginReducer: { currentUser },
    },
  ] = useReducer();
  const [payments, setPayments] = React.useState([]);

  React.useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      const { data } = await API.graphql({
        ...graphqlOperation(queries.getUser, {
          id: currentUser.id,
        }),
        authMode: "AWS_IAM",
      });
      const { getUser = {} } = data || {};
      const response =
        getUser && getUser.payments && getUser.payments.items
          ? getUser.payments.items
          : [];

      console.log("response", response);
      if (mounted) {
        setPayments(response);
      }
    };

    fetchData();

    return () => {
      mounted = false;
    };
  }, [currentUser.id]);

  return (
    <TableContainer className={classes.container} component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{I18n.get("Date")}</TableCell>
            <TableCell align="left">{I18n.get("Amount")}</TableCell>
            <TableCell align="left">{I18n.get("Card")}</TableCell>
            <TableCell align="left">{I18n.get("Place")}</TableCell>
            <TableCell align="left">{I18n.get("Order")}</TableCell>
            <TableCell align="left">{I18n.get("ID")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_orderBy(payments, ["createdAt"], ["desc"]).map((row) => (
            <TableRow
              key={row.id}
              hover
              onClick={() => {
                history.push(`/payment-detail/${row.id}`);
              }}
            >
              <TableCell component="th" scope="row">
                {moment(row.createdAt).format("LLL")}
              </TableCell>
              <TableCell align="left">
                {toCurrency((row.amount || 0).toString())}
              </TableCell>
              <TableCell>
                {row &&
                  row.order &&
                  row.order.restaurant &&
                  row.order.restaurant.name}
              </TableCell>
              <TableCell align="left">
                {I18n.get(getCardData(row.paymentData))}
              </TableCell>
              <TableCell align="left">
                {(row &&
                  row.order &&
                  row.order.id &&
                  row.order.id.split("-")[0]) ||
                  ""}
              </TableCell>
              <TableCell align="left">{row.id.split("-")[0]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PaymentsComponent;
