import React from "react";
import { Box, Collapse, Typography, TextField } from "@material-ui/core";
import { API, graphqlOperation } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";

import * as queries from "../../graphql/queries";
import useStripe from "../../hooks/useStripe";
import {
  isEmptyObject,
  getDigitsFromValue,
  getAmountPaid,
  getRemainingAmount,
  getOrderTotal,
  toCurrency,
  filterOrderItems,
  getDiscountItems,
  getDiscountAmount,
} from "../../util";
import { I18n } from "aws-amplify";
import useQuery from "../../hooks/useQuery";
import { useLocation, useHistory } from "react-router-dom";
// import CurrencyInput from "../CurrencyInput";
// import NumericKeyboard from "../NumericKeyboard";
import useReducer from "../../reducers";
import Loading from "../Loading";
import Voucher from "./Voucher";
import PaymentButtons from "./PaymentButtons";
import ConfirmDialog from "../ConfirmDialog";

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: theme.spacing(1, 0),
  },
  inputInput: {
    textAlign: "right",
    fontSize: 24,
    fontWeight: 700,
  },
}));

const ValidationSchema = Yup.object().shape({
  first_name: Yup.string().min(3).required("Tu nombre es requerido"),
  last_name: Yup.string().min(3).required("Tu apellido es requerido"),
  email: Yup.string().required().email("Ingresa tu email"),
  amount: Yup.number().required("Amount is required"),
});

const PaymentWrapper = ({
  children,
  orderId,
  setMaxAmount,
  setCurrOrder,
  setAmount,
}) => {
  const history = useHistory();
  const [, dispatch] = useReducer();
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    let mounted = true;

    const fetchData = async () => {
      const orderResponse = await API.graphql({
        ...graphqlOperation(queries.getOrder, { id: orderId }),
        authMode: "AWS_IAM",
      });
      const { data = {} } = orderResponse || {};
      const currOrder = data.getOrder || null;
      console.log("currOrder", currOrder);
      if (currOrder) {
        if (currOrder.isPaid) {
          history.replace(`/d/order/${orderId}`);
        }

        const orderItems =
          currOrder &&
          currOrder.items &&
          currOrder.items.items &&
          currOrder.items.items.length
            ? filterOrderItems(currOrder.items.items)
            : [];
        const payments =
          currOrder &&
          currOrder.payments &&
          currOrder.payments.items &&
          currOrder.payments.items.length
            ? currOrder &&
              currOrder.payments &&
              currOrder.payments.items &&
              currOrder.payments.items.filter((x) => !x._deleted)
            : [];
        const discountItems =
          currOrder &&
          currOrder.items &&
          currOrder.items.items &&
          currOrder.items.items.length
            ? getDiscountItems(currOrder.items.items)
            : [];
        const discountAmount = getDiscountAmount(discountItems);

        console.log("fetch Result", orderItems);
        console.log("fetch PaymentResult", payments);

        if (mounted) {
          setCurrOrder(currOrder);
          const total = getOrderTotal(orderItems, true);
          const paid = getAmountPaid(payments);

          const remainingAmount = getRemainingAmount(
            total,
            paid,
            discountAmount,
            currOrder?.deliveryAmount
          );
          console.log("remainingAmount", remainingAmount);

          if (remainingAmount) {
            setAmount(remainingAmount.toString());
            setMaxAmount(remainingAmount);
          }

          if (currOrder?.restaurant) {
            dispatch({
              type: "DELIVERY_RESTAURANT_SET",
              payload: { selectedRestaurant: currOrder?.restaurant },
            });

            if (currOrder?.restaurant?.accountId) {
              let apiName = "api616cdc2a";
              let path = "/stripe/account";

              try {
                const result = await API.get(apiName, path, {
                  queryStringParameters: {
                    account: currOrder?.restaurant?.accountId,
                  },
                });
                console.log("/stripe/account response", result);

                const oxxoEnabled =
                  result &&
                  result.capabilities &&
                  result.capabilities.oxxo_payments
                    ? result.capabilities.oxxo_payments === "active"
                    : false;
                const accountChargesEnabled =
                  oxxoEnabled && Boolean(result.charges_enabled);

                dispatch({
                  type: "CHECKOUT_SET_ACCOUNT_CHARGES_ENABLED",
                  payload: {
                    accountChargesEnabled,
                  },
                });
              } catch (error) {
                console.log("error", error);
              }
            }
          }

          if (currOrder?.restaurant?.primaryColor) {
            dispatch({
              type: "THEME_SET_CUSTOM",
              payload: {
                primaryColor: currOrder?.restaurant?.primaryColor,
              },
            });
          }

          setLoading(false);
        }
      } else {
        history.goBack();
      }
    };

    if (orderId) {
      fetchData();
    } else {
      history.replace(`/main`);
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  if (loading) {
    return <Loading force />;
  }

  return children;
};

const PaymentOxxo = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [
    {
      loginReducer: { currentUser },
      // deliveryReducer: { selectedRestaurant },
      // checkoutReducer: { accountChargesEnabled },
    },
    dispatch,
  ] = useReducer();

  // const stripeAccount =
  //   accountChargesEnabled && selectedRestaurant && selectedRestaurant.accountId
  //     ? selectedRestaurant.accountId
  //     : process.env.REACT_APP_CONNECTED_STRIPE_ACCOUNT;
  // console.log("stripeAccount", stripeAccount);
  const stripe = useStripe(process.env.REACT_APP_STRIPE_APIKEY, {
    betas: ["oxxo_pm_beta_1"],
    apiVersion: "2020-03-02; oxxo_beta=v1",
    // stripeAccount,
    apiKey: process.env.REACT_APP_STRIPE_MX_APIKEY,
  });
  const keyboardRef = React.useRef();
  const query = useQuery();
  const orderId = query.get("o");
  const receiverName = query.get("rn");
  const location = useLocation();
  const { state = {} } = location || {};
  const [open, setOpen] = React.useState(false);

  const { name, id: email } = currentUser || {};
  const [oxxoVoucher, setOxxoVoucher] = React.useState({});
  const [maxAmount, setMaxAmount] = React.useState(0);
  const [currOrder, setCurrOrder] = React.useState({});
  const visible = Boolean(oxxoVoucher && !isEmptyObject(oxxoVoucher));

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      amount: 0,
    },
    validationSchema: ValidationSchema,
    onSubmit: async (values) => {
      dispatch({ type: "LOADING_START" });
      console.log("values", values);
      setOpen(false);
      handleOxxoPayment(values);
    },
  });

  const setAmount = (value) => {
    const numericValue = +getDigitsFromValue(value);

    let topay = value;
    let topaynumber = numericValue;

    if (keyboardRef && keyboardRef && keyboardRef.current) {
      keyboardRef.current.setInput(topay);
    }
    formik.setFieldValue("amount", topaynumber);
  };

  React.useEffect(() => {
    if (state.amount) {
      if (keyboardRef && keyboardRef.current) {
        const currencyValue = state.amount.toString();
        setAmount(currencyValue);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.amount]);

  React.useEffect(() => {
    if (!isEmptyObject(formik.errors) || !isEmptyObject(formik.touched)) {
      console.log(formik.errors);
      setOpen(false);
    }
  }, [formik.errors, formik.touched]);

  React.useEffect(() => {
    if (name || receiverName) {
      const splittedName = (receiverName || name).trim().split(" ");
      if (splittedName.length === 1) {
        formik.setFieldValue("first_name", splittedName[0]);
      } else {
        formik.setFieldValue("first_name", splittedName[0]);
        formik.setFieldValue(
          "last_name",
          splittedName[splittedName.length - 1]
        );
      }
    }

    if (email) {
      formik.setFieldValue("email", email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, email]);

  const setPaymentAmount = (value) => () => {
    formik.setFieldValue("amount", value);

    setOpen(true);
  };

  const handleOxxoPayment = async (values) => {
    let apiName = "api616cdc2a";
    let path = "/stripe/oxxo";
    let intent;

    if (values.amount > maxAmount || values.amount > 1000000) {
      enqueueSnackbar(
        I18n.get(
          "La cantidad a pagar es superior al saldo restante o supera el limite por transacción, ingresa una cantidad menor."
        ),
        {
          variant: "error",
          autoHideDuration: 6000,
        }
      );
      dispatch({ type: "LOADING_STOP" });
      return;
    }

    try {
      intent = await API.post(apiName, path, {
        body: {
          amount: values.amount,
          description: `Tu compra en ${currOrder?.restaurant?.name}`,
          metadata: {
            orderId,
            orderType: currOrder?.orderType,
            commerceId: currOrder?.restaurant?.id || null,
            commerce: currOrder?.restaurant?.name || null,
          },
          // stripeCustomer: currentUser.stripeCustomer,
        },
      });
    } catch (error) {
      console.log("error", error);
      dispatch({ type: "LOADING_STOP" });
      return;
    }

    const { client_secret } = intent || {};
    console.log("intent", intent);

    if (!client_secret) {
      dispatch({ type: "LOADING_STOP" });
      return;
    }

    try {
      const result = await stripe.confirmOxxoPayment(
        client_secret,
        {
          payment_method: {
            billing_details: {
              name: values.first_name.trim() + " " + values.last_name.trim(),
              email: values.email,
            },
          },
        },
        { handleActions: false }
      );

      if (result.error) {
        console.log("result.error", result.error);
      } else {
        // An OXXO voucher was successfully created
        const amount = result.paymentIntent.amount;
        const currency = result.paymentIntent.currency;
        const details = result.paymentIntent.next_action.display_oxxo_details;
        const number = details.number;
        const expires_after = details.expires_after;

        setOxxoVoucher({ amount, currency, number, expires_after });
      }
    } catch (error) {
      console.log("confirmOxxoPayment Error", error);
    } finally {
      dispatch({ type: "LOADING_STOP" });
    }
  };

  return (
    <PaymentWrapper
      orderId={orderId}
      setMaxAmount={setMaxAmount}
      setCurrOrder={setCurrOrder}
      setAmount={setAmount}
    >
      <>
        <Collapse in={!visible}>
          <Box>
            <Typography variant="h4" component="h2">
              Pay in Oxxo
            </Typography>
            {currOrder.orderType === "travel" ? (
              <Typography variant="body2" color="textSecondary">
                You can reserve the tentative date for your trip and pay. Once
                the payment is complete, you can enjoy your vacation.
              </Typography>
            ) : (
              <Typography variant="body2" color="textSecondary">
                Confirm your payment at OXXO and we will generate a capture line
                which you can pay at any OXXO. Your payment will be reflected
                automatically immediately.
              </Typography>
            )}
            <Box mt={2}>
              <form onSubmit={formik.handleSubmit}>
                <TextField
                  id="first_name"
                  name="first_name"
                  label={"Tu nombre"}
                  className={classes.textField}
                  fullWidth
                  size="medium"
                  variant="outlined"
                  inputProps={{
                    spellCheck: "false",
                    autoComplete: "off",
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.first_name}
                  error={formik.errors.first_name && formik.touched.first_name}
                  helperText={
                    formik.errors.first_name && formik.touched.first_name
                      ? formik.errors.first_name
                      : I18n.get("Add your name")
                  }
                />
                <TextField
                  id="last_name"
                  name="last_name"
                  label={"Tu apellido"}
                  className={classes.textField}
                  fullWidth
                  size="medium"
                  variant="outlined"
                  inputProps={{
                    spellCheck: "false",
                    autoComplete: "off",
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.last_name}
                  error={formik.errors.last_name && formik.touched.last_name}
                  helperText={
                    formik.errors.last_name && formik.touched.last_name
                      ? formik.errors.last_name
                      : I18n.get("Add your last name")
                  }
                />

                <TextField
                  id="email"
                  name="email"
                  type="email"
                  label={"Your email address"}
                  className={classes.textField}
                  fullWidth
                  size="medium"
                  variant="outlined"
                  inputProps={{
                    type: "email",
                    spellCheck: "false",
                  }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={formik.errors.email && formik.touched.email}
                  helperText={
                    formik.errors.email && formik.touched.email
                      ? formik.errors.email
                      : I18n.get("Add your email")
                  }
                />

                <PaymentButtons
                  maxAmount={maxAmount}
                  setPaymentAmount={setPaymentAmount}
                  order={currOrder}
                />
              </form>
            </Box>
          </Box>
        </Collapse>

        <Collapse in={visible}>
          <Voucher
            orderId={orderId}
            order={currOrder}
            oxxoVoucher={oxxoVoucher}
          />
        </Collapse>

        <ConfirmDialog
          keepMounted
          classes={{
            paper: classes.paper,
          }}
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          onConfirm={formik.handleSubmit}
          message={I18n.get(
            `¿Quieres generar tu ficha de pago por $${toCurrency(
              (formik.values.amount || 0).toString()
            )} MXN?`
          )}
        />
      </>
    </PaymentWrapper>
  );
};

export default PaymentOxxo;
