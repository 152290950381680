import React from "react";
import { I18n } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Typography from "@material-ui/core/Typography";

import useReducer from "../../reducers";
import { JSONParse } from "../../util";
import AddRfcDialog from "./AddRfcDialog";

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: theme.spacing(1, 0),
  },
  button: {
    marginBottom: theme.spacing(1),
  },
}));

const RfcSelectStep = ({ formik }) => {
  const classes = useStyles();
  const inputLabel = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  const [
    {
      loginReducer: { currentUser },
    },
  ] = useReducer();
  const { fiscalData } = currentUser || {};
  const rfcList = (fiscalData && Object.values(JSONParse(fiscalData))) || [];

  React.useEffect(() => {
    if (inputLabel && inputLabel.current) {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, []);

  return (
    <>
      <FormControl
        variant="outlined"
        fullWidth
        error={formik.errors.selectedRfc && formik.touched.selectedRfc}
        className={classes.textField}
      >
        <InputLabel ref={inputLabel}>RFC</InputLabel>
        <Select
          labelId="selectedRfc"
          id="selectedRfc"
          name="selectedRfc"
          value={formik.values.selectedRfc}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          labelWidth={labelWidth}
        >
          {rfcList.map((use) => (
            <MenuItem key={use.rfc} value={use.rfc}>
              <Typography>
                {use.rfc} | {use.name}
              </Typography>
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>
          {formik.errors.selectedRfc && formik.touched.selectedRfc
            ? formik.errors.selectedRfc
            : I18n.get("Select the RFC")}
        </FormHelperText>
      </FormControl>

      <AddRfcDialog />
    </>
  );
};

export default RfcSelectStep;
