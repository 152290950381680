import React from "react";
import { Typography, Box } from "@material-ui/core";
import IconContainer, { customIcons } from "./IconContainer";
import { StyledRating } from "./Review.styled";

const ServiceStep = ({ formik }) => {
  return (
    <>
      <Box mt={3} mb={2} borderColor="transparent">
        <Typography component="legend">Recepción</Typography>
        <Typography component="legend" color="textSecondary">
          Que te parecio el servicio ofrecido por la hostess a tu llegada al
          establecimiento.
        </Typography>

        <StyledRating
          id="host"
          name="host"
          onChange={(event, newValue) => {
            formik.setFieldValue("host", newValue);
          }}
          value={formik.values.host}
          getLabelText={(value) => customIcons[value].label}
          IconContainerComponent={IconContainer}
          size="large"
        />
      </Box>
      <Box mb={2} borderColor="transparent">
        <Typography component="legend">Anfitrión</Typography>
        <Typography component="legend" color="textSecondary">
          Que te parecio el servicio ofrecido por tu anfitrión.
        </Typography>

        <StyledRating
          id="waiter"
          name="waiter"
          onChange={(event, newValue) => {
            formik.setFieldValue("waiter", newValue);
          }}
          value={formik.values.waiter}
          getLabelText={(value) => customIcons[value].label}
          IconContainerComponent={IconContainer}
          size="large"
        />
      </Box>
      <Box mb={2} borderColor="transparent">
        <Typography component="legend">Cajera</Typography>
        <Typography component="legend" color="textSecondary">
          Que te parecio el servicio ofrecido en la caja.
        </Typography>

        <StyledRating
          id="cashier"
          name="cashier"
          onChange={(event, newValue) => {
            formik.setFieldValue("cashier", newValue);
          }}
          value={formik.values.cashier}
          getLabelText={(value) => customIcons[value].label}
          IconContainerComponent={IconContainer}
          size="large"
        />
      </Box>
      <Box mb={2} borderColor="transparent">
        <Typography component="legend">Gerencia</Typography>
        <Typography component="legend" color="textSecondary">
          El gerente o el supervisor se dirigieron a tu mesa.
        </Typography>

        <StyledRating
          id="manager"
          name="manager"
          onChange={(event, newValue) => {
            formik.setFieldValue("manager", newValue);
          }}
          value={formik.values.manager}
          getLabelText={(value) => customIcons[value].label}
          IconContainerComponent={IconContainer}
          size="large"
        />
      </Box>
    </>
  );
};

export default ServiceStep;
