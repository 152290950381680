import React from "react";
import { Switch, useRouteMatch, Route, Redirect } from "react-router-dom";
import PaymentSuccess from "../components/Payment/PaymentSuccess";
import PaymentPending from "../components/Payment/PaymentPending";
import PaymentCancel from "../components/Payment/PaymentError";
import PaymentOxxo from "../components/Payment/PaymentOxxo";
import PaymentCard from "../components/Payment/PaymentCard";
import PaymentPaypal from "../components/Payment/PaymentPaypal";

const Payment = () => {
  let { path } = useRouteMatch();
  return (
    <>
      <Switch>
        <Route exact path={path}>
          <Redirect to="/" />
        </Route>
        <Route path={`${path}/paypal`}>
          <PaymentPaypal />
        </Route>
        <Route path={`${path}/oxxo`}>
          <PaymentOxxo />
        </Route>
        <Route path={`${path}/card`}>
          <PaymentCard />
        </Route>
        <Route path={`${path}/success`}>
          <PaymentSuccess />
        </Route>
        <Route path={`${path}/pending`}>
          <PaymentPending />
        </Route>
        <Route path={`${path}/cancel`}>
          <PaymentCancel />
        </Route>
        <Route path={`${path}/error`}>
          <PaymentCancel />
        </Route>
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </>
  );
};

export default Payment;
