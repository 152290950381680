import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { I18n } from "aws-amplify";
import { API, graphqlOperation } from "aws-amplify";

import * as queries from "../graphql/queries";
import CategoryCard from "../components/Category/CategoryCard";
import { GridListTile, GridList } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { isSafariBrowser } from "../util";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    marginTop: theme.spacing(2),
  },
  media: {
    height: 160,
  },
  gridList: {
    width: "100%",
  },
}));

const Bonds = () => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    let mounted = true;

    const fetchData = async () => {
      try {
        const queryRestaurantsHasDelivery = API.graphql({
          ...graphqlOperation(queries.resturantsbyBondEnabled, {
            bondEnabled: 1,
          }),
          authMode: "AWS_IAM",
        });

        const { data } = await queryRestaurantsHasDelivery;
        const {
          resturantsbyBondEnabled: { items: products } = {
            items: [],
          },
        } = data || {};

        // console.log("resturantsbyBondEnabled", data);

        if (mounted) {
          setLoading(false);
          setItems(products);
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchData();

    return () => {
      mounted = false;
    };
  }, []);

  return (
    <Box mt={1} mb={7}>
      <Card>
        <CardMedia
          className={classes.media}
          image={`${process.env.REACT_APP_CLOUDFRONT}/public/assets/571x0/comecdmx`}
          title="#ComeCDMX"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {I18n.get("Bono gastronómico")}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            #ComeCDMX: Elige el restaurante que te gustaría apoyar y compra un
            bono para tu consumo futuro.
            {/* <br />
            <br />
            <b>
              Jose Cuervo, en apoyo a la industria restaurantera durante esta
              pandemia, duplicará tu bono. Compra un bono por MX $1,000 y recibe
              $2,000 para tu siguiente visita en tu restaurante favorito.
            </b> */}
          </Typography>
        </CardContent>
      </Card>

      <div className={classes.root}>
        <GridList cols={2} cellHeight={180} className={classes.gridList}>
          {(loading ? Array.from(new Array(6)) : items).map((item, index) =>
            item ? (
              item._deleted ? null : (
                <CategoryCard key={item.id} category={item} isRest fromBond />
              )
            ) : (
              <GridListTile key={index}>
                <Skeleton
                  variant="rect"
                  animation="wave"
                  height={180}
                  width={185}
                  style={{ borderRadius: 8 }}
                />
              </GridListTile>
            )
          )}
        </GridList>
      </div>
      {isSafariBrowser ? (
        <div id="space" style={{ minHeight: 64, height: 64 }} />
      ) : null}
    </Box>
  );
};

export default Bonds;
