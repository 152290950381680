import React from "react";
import moment from "moment";
import { I18n } from "aws-amplify";
import _orderBy from "lodash/orderBy";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";

import * as queries from "../../graphql/queries";
import useReducer from "../../reducers";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(1),
  },
  table: {
    minWidth: 640,
  },
}));

const OrdersComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const [
    {
      loginReducer: { currentUser },
    },
  ] = useReducer();
  const [orders, setOrders] = React.useState([]);

  React.useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      const { data } = await API.graphql({
        ...graphqlOperation(queries.getUser, {
          id: currentUser.id,
        }),
        authMode: "AWS_IAM",
      });
      const { getUser = {} } = data || {};
      const response =
        getUser && getUser.orders && getUser.orders.items
          ? getUser.orders.items
          : [];
      if (mounted) {
        setOrders(response);
      }
    };

    fetchData();

    return () => {
      mounted = false;
    };
  }, [currentUser.id]);

  const handleOrderType = (ot) => {
    switch (ot) {
      case "tostay":
        return "Dine In";
      case "togo":
        return "To Go";
      case "todeliver":
        return "Delivery";
      case "bond":
        return "Bond";
      default:
        return "";
    }
  };

  return (
    <TableContainer className={classes.container} component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{I18n.get("Date")}</TableCell>
            {/* <TableCell align="left">Ticket</TableCell> */}
            <TableCell align="left">{I18n.get("Serie")}</TableCell>
            <TableCell align="left">{I18n.get("Status")}</TableCell>
            <TableCell align="left">{I18n.get("Place")}</TableCell>
            <TableCell align="left">{I18n.get("Order Type")}</TableCell>
            <TableCell align="left">{I18n.get("ID")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_orderBy(orders, ["createdAt"], ["desc"]).map((row) =>
            row._deleted || (row.orderType === "bond" && !row.isPaid) ? null : (
              <TableRow
                key={row.id}
                hover
                onClick={() => {
                  if (row && row.orderType === "tostay") {
                    history.push(`/order/${row.id}`);
                  } else {
                    history.push({
                      pathname: `/d/order/${row.id}`,
                      search: row && row.orderType === "bond" ? `?ot=bond` : ``,
                    });
                  }
                }}
              >
                <TableCell component="th" scope="row">
                  {moment(row.createdAt).format("LLL")}
                </TableCell>
                {/* <TableCell align="left">{row.orderDaySerie}</TableCell> */}
                <TableCell align="left">{row.orderSerie}</TableCell>
                <TableCell align="left">{I18n.get(row.status)}</TableCell>
                <TableCell align="left">
                  {row && row.restaurant && row.restaurant.name}
                </TableCell>
                <TableCell align="left">
                  {I18n.get(handleOrderType(row && row.orderType))}
                </TableCell>
                <TableCell align="left">{row.id.split("-")[0]}</TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrdersComponent;
