import React from 'react';
import _chunk from 'lodash/chunk';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';

import ListItemLink from './ListItemLink';

const useStyles = makeStyles(() => ({
  section: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  list: {
    flex: 1
  }
}));

const MobileSectionList = ({ links = [] }) => {
  const chunks = _chunk(links, 2);
  const classes = useStyles();
  return (
    <Box className={classes.section}>
      {chunks.map((list, index) => {
        return (
          <List
            key={index}
            component="nav"
            aria-label="mobile nav left section"
            className={classes.list}
            disablePadding
          >
            {list.map((item, idx) => {
              return (
                <ListItemLink
                  key={idx}
                  to={item.route}
                  primary={item.title}
                  icon={item.icon}
                />
              );
            })}
          </List>
        );
      })}
    </Box>
  );
};

MobileSectionList.propTypes = {
  links: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.arrayOf(PropTypes.object)
  ])
};

export default MobileSectionList;
