import React from "react";
import { API } from "aws-amplify";

import {
  isPushNotificationSupported,
  getUserSubscription,
  askUserPermission,
  createNotificationSubscription,
  removeNotificationSubscription,
} from "../lib/pushNotifications";

const pushNotificationSupported = isPushNotificationSupported();

const useNotification = () => {
  const [userConsent, setSuserConsent] = React.useState(
    pushNotificationSupported ? Notification.permission : null
  );
  const [userSubscription, setUserSubscription] = React.useState(null);

  React.useEffect(() => {
    let mounted = true;
    const getExistingSubscription = async () => {
      try {
        const existingSubscription = await getUserSubscription();
        console.log("existingSubscription", existingSubscription);
        if (mounted) {
          setUserSubscription(existingSubscription);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (pushNotificationSupported) {
      getExistingSubscription();
    }

    return () => {
      mounted = false;
    };
  }, []);

  /**
   * define a click handler that asks the user permission,
   * it uses the setSuserConsent state, to set the consent of the user
   * If the user denies the consent, an error is created with the setError hook
   */
  const onClickAskUserPermission = () => {
    askUserPermission().then((consent) => {
      setSuserConsent(consent);
      if (consent !== "granted") {
        console.log("consent", consent);
        return;
      }

      if (consent === "granted") {
        onClickSusbribeToPushNotification();
        return;
      }
    });
  };

  /**
   * define a click handler that creates a push notification subscription.
   * Once the subscription is created, it uses the setUserSubscription hook
   */
  const onClickSusbribeToPushNotification = () => {
    return createNotificationSubscription()
      .then(function (subscription) {
        setUserSubscription(subscription);
        return subscription;
      })
      .catch((err) => {
        console.error(
          "Couldn't create the notification subscription",
          err,
          "name:",
          err.name,
          "message:",
          err.message,
          "code:",
          err.code
        );
        return null;
      });
  };

  /**
   * define a click handler that removes a push notification subscription.
   */
  const onClickUnsusbribeToPushNotification = () => {
    removeNotificationSubscription()
      .then(function (subscription) {
        setUserSubscription(subscription);
      })
      .catch((err) => {
        console.error(
          "Couldn't remove the notification subscription",
          err,
          "name:",
          err.name,
          "message:",
          err.message,
          "code:",
          err.code
        );
      });
  };

  /**
   * define a click handler that sends the push susbcribtion to the push server.
   * Once the subscription ics created on the server, it saves the id using the hook setPushServerSubscriptionId
   */
  const onClickSaveSubscription = () => {
    console.log("save subscription");
  };

  /**
   * define a click handler that request the push server to send a notification, passing the id of the saved subscription
   */
  const onClickSendNotification = async () => {
    console.log("send notification");

    let apiName = "api616cdc2a";
    let path = "/notifications/push";

    const result = await API.post(apiName, path, {
      body: {},
    });
    console.log("result", result);
  };

  return {
    userConsent,
    userSubscription,
    onClickAskUserPermission,
    onClickSusbribeToPushNotification,
    onClickUnsusbribeToPushNotification,
    onClickSaveSubscription,
    onClickSendNotification,
  };
};

export default useNotification;
