const initialState = {
  nearbyRestaurants: [],
  loadingNearbyResturants: true,
  selectedRestaurant: {},
  orderType: "todeliver",
  deliveryAddress: null,
  deliveryCart: [], // delivery order items
  cartRestaurant: {},
  cartCompany: {},
  principalRestaurant: {},
  openCheckout: false,
  deliveryOrderAmount: 0,
  deliveryOrderTaxes: [],
  deliveryDiscountAmount: 0,
  paymentMethod: "card",
  tipAmount: "",
  tipType: 1,
  openOTDialog: false,
  openPMDialog: false,
  deliveryNotes: "",
  openClearCartDialog: false,
  newProduct: null,
  newCartRestaurant: null,
  addressList: [],
  openDeliveryAddressDialog: false,
  currentDeliveryOrderId: null,
  currentDeliveryOrder: null,
  openUnavailableCartDialog: false,
  unavailableCartItems: [],
  deliveryCostAmount: 0, // precio de envio
  openOutOfTimeDialog: false,
  receiverPhone: "",
  receiverName: "",
  receiverPhoneCode: "+52",
  receiverEmail: "",
  tableID: "",
  surchargesArr: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "DELIVERY_RESTAURANT_RESET":
      return {
        ...initialState,
      };
    case "DELIVERY_RESTAURANT_SET":
      return {
        ...state,
        selectedRestaurant: action.payload.selectedRestaurant,
      };
    case "DELIVERY_SET_NEARBY_LOADING":
      return {
        ...state,
        loadingNearbyResturants: action.payload.loadingNearbyResturants,
      };
    case "DELIVERY_SET_NEARBY_RESTAURANTS":
      return {
        ...state,
        nearbyRestaurants: action.payload.nearbyRestaurants,
      };
    case "DELIVERY_SET_CART":
      return {
        ...state,
        deliveryCart: action.payload.deliveryCart,
      };
    case "DELIVERY_CLEAR_CART":
      return {
        ...state,
        deliveryCart: [],
      };
    case "DELIVERY_SET_CART_RESTAURANT":
      return {
        ...state,
        cartRestaurant: action.payload.cartRestaurant,
      };
    case "DELIVERY_SET_CART_COMPANY":
      return {
        ...state,
        cartCompany: action.payload.cartCompany,
      };
    case "DELIVERY_SET_PRINCIPAL_RESTAURANT":
      return {
        ...state,
        principalRestaurant: action.payload.principalRestaurant,
      };
    case "DELIVERY_SET_ORDER_TYPE":
      return {
        ...state,
        orderType: action.payload.orderType,
      };
    case "DELIVERY_TOGGLE_CHECKOUT":
      return {
        ...state,
        openCheckout: action.payload.openCheckout,
      };
    case "DELIVERY_TOGGLE_ORDER_TYPE_DIALOG":
      return {
        ...state,
        openOTDialog: action.payload.open,
      };
    case "DELIVERY_TOGGLE_PAYMENY_METHOD_DIALOG":
      return {
        ...state,
        openPMDialog: action.payload.open,
      };
    case "DELIVERY_SET_AMOUNT":
      return {
        ...state,
        deliveryOrderAmount: action.payload.deliveryOrderAmount,
      };
    case "DELIVERY_SET_DISCOUNT_AMOUNT":
      return {
        ...state,
        deliveryDiscountAmount: action.payload.deliveryDiscountAmount,
      };
    case "DELIVERY_SET_TAXES":
      return {
        ...state,
        deliveryOrderTaxes: action.payload.deliveryOrderTaxes,
      };
    case "DELIVERY_SET_TIP_TYPE":
      return {
        ...state,
        tipType: action.payload.tipType,
      };
    case "DELIVERY_SET_TIP_AMOUNT":
      return {
        ...state,
        tipAmount: action.payload.tipAmount,
      };
    case "DELIVERY_SET_PAYMENT_METHOD":
      return {
        ...state,
        paymentMethod: action.payload.paymentMethod,
      };
    case "DELIVERY_SET_NOTES":
      return {
        ...state,
        deliveryNotes: action.payload.deliveryNotes,
      };
    case "DELIVERY_SET_PHONE_CODE":
      return {
        ...state,
        receiverPhoneCode: action.payload.receiverPhoneCode,
      };
    case "DELIVERY_SET_PHONE":
      return {
        ...state,
        receiverPhone: action.payload.receiverPhone,
      };
    case "DELIVERY_SET_NAME":
      return {
        ...state,
        receiverName: action.payload.receiverName,
      };
    case "DELIVERY_SET_EMAIL":
      return {
        ...state,
        receiverEmail: action.payload.receiverEmail,
      };
    case "DELIVERY_TOGGLE_UNAVAILABLE_CART_DIALOG":
      return {
        ...state,
        openUnavailableCartDialog: action.payload.open,
      };
    case "DELIVERY_SET_UNAVAILABLE_CART_ITEMS":
      return {
        ...state,
        unavailableCartItems: action.payload.unavailableCartItems,
      };
    case "DELIVERY_TOGGLE_CLEAR_CART_DIALOG":
      return {
        ...state,
        openClearCartDialog: action.payload.open,
      };
    case "DELIVERY_CLEAR_CART_NEW_ITEM":
      return {
        ...state,
        newProduct: action.payload.newProduct,
        newCartRestaurant: action.payload.newCartRestaurant,
      };
    case "DELIVERY_TOGGLE_ADDRESS_DIALOG":
      return {
        ...state,
        openDeliveryAddressDialog: action.payload.open,
      };
    case "DELIVERY_SET_ADDRESS":
      return {
        ...state,
        deliveryAddress: action.payload.deliveryAddress,
      };
    case "DELIVERY_SET_ADDRESS_LIST":
      return {
        ...state,
        addressList: action.payload.addressList,
      };
    case "DELIVERY_SET_ORDER_ID":
      return {
        ...state,
        currentDeliveryOrderId: action.payload.currentDeliveryOrderId,
      };
    case "DELIVERY_SET_ORDER":
      return {
        ...state,
        currentDeliveryOrder: action.payload.currentDeliveryOrder,
      };
    case "DELIVERY_SET_DELIVERY_PRICE_AMOUNT":
      return {
        ...state,
        deliveryCostAmount: action.payload.deliveryCostAmount,
      };
    case "DELIVERY_TOGGLE_OUT_OF_TIME_DIALOG":
      return {
        ...state,
        openOutOfTimeDialog: action.payload.open,
      };
    case "DELIVERY_SET_ORDER_TABLE":
      return {
        ...state,
        tableID: action.payload.tableID,
      };
    case "DELIVERY_SET_SURCHARGES":
      return {
        ...state,
        surchargesArr: action.payload.surchargesArr,
      };
    default:
      return state;
  }
};

export default { initialState, reducer };
