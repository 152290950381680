import React from "react";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    height: 168,
    position: "relative",
  },
  details: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 168,
  },
  controls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
}));

const ProductCardSkeleton = () => {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <Card className={classes.card}>
        <div className={classes.details}>
          <CardContent className={classes.content}>
            <Skeleton variant="rect" animation="wave" height={32} />
            <Skeleton variant="text" animation="wave" width={"80%"} />
            <Skeleton variant="text" animation="wave" width={"80%"} />
          </CardContent>
          <div className={classes.controls}>
            <Skeleton
              variant="rect"
              animation="wave"
              height={32}
              width={"40%"}
            />
          </div>
        </div>
        <CardMedia className={classes.cover}>
          <Skeleton variant="rect" animation="wave" height={168} width={168} />
        </CardMedia>
      </Card>
    </Grid>
  );
};

export default ProductCardSkeleton;
