import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, InputBase, Box } from "@material-ui/core";
import { I18n } from "aws-amplify";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  input: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  paper: {
    backgroundColor: theme.palette.background.default,
    padding: "8px 4px",
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
}));

const SearchAddress = ({ onPlacesChanged = () => {}, onChangeText, maps }) => {
  const classes = useStyles();
  const inputRef = React.useRef();
  const searchBox = React.useRef();

  const handleOnPlacesChanged = (maps) => {
    onPlacesChanged(searchBox.current.getPlaces(), maps);
  };

  React.useEffect(
    () => {
      if (inputRef.current) {
        inputRef.current.focus();
        searchBox.current = maps && new maps.places.SearchBox(inputRef.current);
        searchBox.current &&
          searchBox.current.addListener("places_changed", () =>
            handleOnPlacesChanged(maps)
          );
      }

      return () => {
        window.google.maps.event.clearInstanceListeners(searchBox.current);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inputRef]
  );

  return (
    <Box mt={3} mx={2} mb={1}>
      <Paper className={classes.paper}>
        <InputBase
          inputRef={inputRef}
          className={classes.input}
          placeholder={I18n.get("Search your address")}
          inputProps={{ type: "search", "aria-label": "search google maps" }}
          onChange={onChangeText}
        />
      </Paper>
    </Box>
  );
};

export default SearchAddress;
