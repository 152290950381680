import React from "react";
import { Typography } from "@material-ui/core";
import { API, graphqlOperation } from "aws-amplify";
import _compact from "lodash/compact";
import _orderBy from "lodash/orderBy";

import * as queries from "../../graphql/queries";
import { toCurrency } from "../../util";

const ItemModifier = ({ productId }) => {
  const [loading, setLoading] = React.useState(true);
  const [product, setProduct] = React.useState({});
  const [topModifier, setTopModifier] = React.useState();

  React.useEffect(() => {
    let mounted = true;
    const fetchProduct = async () => {
      if (mounted) {
        setLoading(true);
      }

      try {
        const { data } = await API.graphql({
          ...graphqlOperation(queries.getProduct, {
            id: productId,
          }),
          authMode: "AWS_IAM",
        });
        const { getProduct } = data || {};
        if (!getProduct || getProduct.status !== "ACTIVE") {
          setProduct({});
        } else {
          if (mounted) {
            setProduct(getProduct);
            const filteredModifiers = _compact(
              _orderBy(
                getProduct?.modifiers?.items || [],
                ["position"],
                ["asc"]
              ).map((x) => {
                if (
                  x._deleted ||
                  x?.modifier?._deleted ||
                  x?.modifier?.status !== "ACTIVE"
                ) {
                  return null;
                }
                return { ...x.modifier, productModifierPosition: x.position };
              })
            );

            const mdfr = _orderBy(
              filteredModifiers,
              ["productModifierPosition", "title"],
              ["asc", "asc"]
            )?.[0];
            setTopModifier(mdfr);
            console.log("topModifier", mdfr);
          }
        }
        if (mounted) {
          setLoading(false);
        }
      } catch (error) {
        console.log("error", error);
        if (mounted) {
          setLoading(false);
        }
      }
    };

    if (productId) {
      fetchProduct();
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  if (loading || !product?.id) {
    return null;
  }

  return (
    <>
      {topModifier ? (
        (topModifier?.optionsConnection?.items || []).map((x) => {
          return (
            <div
              key={x.id}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography variant="caption" color="textSecondary">
                {x.title}
              </Typography>
              <Typography variant="caption">
                {toCurrency(
                  (
                    product?.price +
                    topModifier.upcharge +
                    x.upcharge
                  ).toString()
                )}
              </Typography>
            </div>
          );
        })
      ) : (
        <Typography>{toCurrency((product?.price).toString())}</Typography>
      )}
    </>
  );
};

export default ItemModifier;
