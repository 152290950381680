import React from "react";
import { I18n } from "aws-amplify";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Box, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useLocation } from "react-router-dom";

import { ReactComponent as TimeIcon } from "../../svg/undraw_time_management.svg";

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

const PaymentPending = () => {
  const classes = useStyles();
  const location = useLocation();

  return (
    <Box
      flex={1}
      flexDirection="column"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <SvgIcon
        component={TimeIcon}
        viewBox="0 0 1037.94 772.29"
        style={{ fontSize: 120 }}
      />
      <Typography variant="h4" className={classes.title}>
        {I18n.get("Pending Payment")}
      </Typography>
      <Typography>
        {I18n.get("Go to the cash or wait for your server")}
      </Typography>

      <Button
        variant="contained"
        color="secondary"
        component={Link}
        to={{ pathname: "/checkout", state: { background: location } }}
        className={classes.button}
      >
        {I18n.get("Make another payment")}
      </Button>
    </Box>
  );
};

export default PaymentPending;
