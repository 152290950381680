import React from "react";
import { Box, TextField } from "@material-ui/core";

const ContactStep = ({ formik }) => {
  return (
    <>
      <Box mt={3} mb={2}>
        <TextField
          id="email"
          name="email"
          type="email"
          label={"Email (opcional)"}
          fullWidth
          size="medium"
          variant="outlined"
          inputProps={{
            type: "email",
            spellCheck: "false",
          }}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          error={formik.errors.email && formik.touched.email}
          helperText={
            formik.errors.email && formik.touched.email
              ? formik.errors.email
              : "Para enviarte promociones y descuentos especiales"
          }
        />
      </Box>
      <Box mb={2}>
        <TextField
          id="comments"
          name="comments"
          label="Agrega comentarios adicionales (opcional)"
          multiline
          rows={5}
          variant="filled"
          fullWidth
          value={formik.values.comments}
          onChange={formik.handleChange}
        />
      </Box>
      <Box mb={2}>
        <TextField
          id="preferUs"
          name="preferUs"
          label="¿Por qué nos prefieres? (opcional)"
          multiline
          rows={5}
          variant="filled"
          fullWidth
          value={formik.values.preferUs}
          onChange={formik.handleChange}
        />
      </Box>
    </>
  );
};

export default ContactStep;
