import React from "react";
import { Typography } from "@material-ui/core";
import { useParams } from "react-router-dom";
import makeStyles from "@material-ui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: theme.spacing(-1, -2),
  },
}));

const HotelPage = () => {
  const styles = useStyles();
  const { hotelSubdomain } = useParams();
  return (
    <div className={styles.container}>
      <Typography>Hotel {hotelSubdomain}</Typography>
    </div>
  );
};

export default HotelPage;
