const initialState = {
  currentOrder: null,
  orderAmount: 0,
  orderTaxes: [],
  amountPaid: 0,
  orderItems: [],
  orderPayments: [],
  orderDiscountAmount: 0,
  discountItems: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "ORDER_RESET":
      return {
        ...initialState,
      };
    case "ORDER_SET":
      return {
        ...state,
        currentOrder: action.payload.currentOrder,
      };
    case "ORDER_SET_AMOUNT":
      return {
        ...state,
        orderAmount: action.payload.orderAmount,
      };
    case "ORDER_SET_TAXES":
      return {
        ...state,
        orderTaxes: action.payload.orderTaxes,
      };
    case "ORDER_SET_DISCOUNT_AMOUNT":
      return {
        ...state,
        orderDiscountAmount: action.payload.orderDiscountAmount,
      };
    case "ORDER_SET_DISCOUNT_ITEMS":
      return {
        ...state,
        discountItems: action.payload.discountItems,
      };
    case "ORDER_SET_AMOUNTPAID":
      return {
        ...state,
        amountPaid: action.payload.amountPaid,
      };
    case "ORDER_SET_ORDERITEMS":
      return {
        ...state,
        orderItems: action.payload.orderItems,
      };
    case "ORDER_SET_ORDERPAYMENTS":
      return {
        ...state,
        orderPayments: action.payload.orderPayments,
      };
    default:
      return state;
  }
};

export default { initialState, reducer };
