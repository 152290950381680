import { createMuiTheme } from "@material-ui/core/styles";
import { lighten } from "polished";
import { lightTheme } from "../theme";

const family = "'Questrial', sans-serif";
const initialState = {
  isDark: false,
  theme: lightTheme,
  primaryColor: "#ffaa00",
  secondaryColor: "#e09600",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "THEME_TOGGLE_MODE":
      return {
        ...state,
        isDark: action.payload.isDark,
        theme: createMuiTheme(
          action.payload.isDark
            ? {
                typography: {
                  fontFamily: family,
                },
                palette: {
                  type: "dark",
                  primary: {
                    main: state.primaryColor || "#ffaa00",
                  },
                  secondary: {
                    main: lighten(0.2, state.secondaryColor),
                  },
                  background: {
                    paper: "#262833",
                    default: "#343744",
                  },
                  text: {
                    primary: "#F8F8F8",
                  },
                  action: {
                    active: "#F8F8F8",
                  },
                },
              }
            : {
                typography: {
                  fontFamily: family,
                },
                palette: {
                  type: "light",
                  primary: {
                    main: state.primaryColor || "#ffaa00",
                  },
                  secondary: {
                    main: lighten(0.2, state.secondaryColor),
                  },
                  background: {
                    default: "#fafafa",
                  },
                },
              }
        ),
      };
    case "THEME_SET_CUSTOM":
      return {
        ...state,
        primaryColor: action.payload.primaryColor,
        secondaryColor: action.payload.primaryColor,
        theme: createMuiTheme(
          state.isDark
            ? {
                typography: {
                  fontFamily: family,
                },
                palette: {
                  type: "dark",
                  primary: {
                    main: action.payload.primaryColor || "#ffaa00",
                  },
                  secondary: {
                    main: lighten(0.2, action.payload.primaryColor),
                  },
                  background: {
                    paper: "#262833",
                    default: "#343744",
                  },
                  text: {
                    primary: "#F8F8F8",
                  },
                  action: {
                    active: "#F8F8F8",
                  },
                },
              }
            : {
                typography: {
                  fontFamily: family,
                },
                palette: {
                  type: "light",
                  primary: {
                    main: action.payload.primaryColor || "#ffaa00",
                  },
                  secondary: {
                    main: lighten(0.2, action.payload.primaryColor),
                  },
                  background: {
                    default: "#fafafa",
                  },
                },
              }
        ),
      };
    default:
      return state;
  }
};

export default { reducer, initialState };
