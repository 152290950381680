const initialState = {
  openLogin: false,
  authState: "loading", // loading, signIn, signedIn
  currentUser: null,
  browserData: {},
  identityId: null,
  loginView: "signin",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN_RESET":
      return initialState;
    case "LOGIN_TOGGLE":
      return {
        ...state,
        openLogin: action.payload.open || !state.openLogin,
      };
    case "LOGIN_CLOSE":
      return {
        ...state,
        openLogin: false,
      };
    case "LOGIN_OPEN":
      return {
        ...state,
        openLogin: true,
      };
    case "LOGIN_SET_AUTHSTATE":
      return {
        ...state,
        authState: action.payload.authState,
      };
    case "LOGIN_SET_USER":
      return {
        ...state,
        currentUser: action.payload.user,
      };
    case "BROWSER_SET_DATA":
      return {
        ...state,
        browserData: action.payload.browserData,
      };
    case "SET_IDENTITY_ID":
      return {
        ...state,
        identityId: action.payload.identityId,
      };
    case "LOGIN_SET_VIEW":
      return {
        ...state,
        loginView: action.payload.view,
      };
    default:
      return state;
  }
};

export default { initialState, reducer };
