import React from "react";
import { I18n } from "aws-amplify";
import _orderBy from "lodash/orderBy";
import { makeStyles } from "@material-ui/core/styles";
import GridList from "@material-ui/core/GridList";
import { Typography, Box, GridListTile } from "@material-ui/core";
import { API } from "aws-amplify";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import * as queries from "../../graphql/queries";

import MenuSection from "./MenuSection";
import MenuItem from "./MenuItem";
import useReducer from "../../reducers";
import MenuItemSkeleton from "./MenuItemSkeleton";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
  },
}));

const MenuList = ({
  category,
  restaurantId,
  fromSite = false,
  delivery = false,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [items, setItems] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  // const [loadingMore, setLoadingMore] = React.useState(false);
  const [token, setToken] = React.useState();
  const hasMore = Boolean(token);
  const [
    {
      deliveryReducer: { selectedRestaurant },
      restaurantReducer: {
        currentRestaurant: { currency },
      },
    },
  ] = useReducer();
  const { businessType, currency: restCurrency } = selectedRestaurant || {};
  const selectedCurrency = currency || restCurrency;

  React.useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      const { data } = await API.graphql({
        query: queries.productsbyCategory,
        variables: {
          categoryID: category.id,
          limit: 15,
        },
        authMode: "AWS_IAM",
      });
      const {
        productsbyCategory: { items: products, nextToken } = { items: [] },
      } = data || {};

      const sortedProducts = _orderBy(
        products,
        ["product.status", "product.position"],
        ["asc", "asc"]
      );

      if (mounted) {
        setLoading(false);

        if (delivery && businessType === "RESTAURANT") {
          setItems(
            sortedProducts.filter(
              (x) =>
                x &&
                !x._deleted &&
                x.product &&
                !x.product._deleted &&
                (x.product.preorderingEnabled || x.product.deliveryEnabled)
            )
          );
        } else {
          setItems(
            sortedProducts.filter(
              (x) => x && !x._deleted && x.product && !x.product._deleted
            )
          );
        }
        setToken(nextToken);
      }
    };

    fetchData();
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category.id, delivery]);

  const handleClickTile = () => {
    if (!delivery) {
      history.push(`/t/category/${category.id}`);
    } else {
      history.push(
        fromSite
          ? `/d/category/${category.id}`
          : `/d/r/${restaurantId}/category/${category.id}`
      );
    }
  };

  return (
    <div className={classes.root}>
      <MenuSection
        delivery={delivery}
        restaurantId={restaurantId}
        category={category}
        fromSite={fromSite}
      />

      <GridList className={classes.gridList} cols={1.4}>
        {(loading ? Array.from(new Array(3)) : items).map((item, index) =>
          item ? (
            <MenuItem
              key={item.id}
              product={item}
              restaurantId={restaurantId}
              delivery={delivery}
              currency={selectedCurrency}
              fromSite={fromSite}
            />
          ) : (
            <MenuItemSkeleton key={index} />
          )
        )}
        {hasMore && (
          <GridListTile onClick={handleClickTile}>
            <Box
              width="100%"
              height="100%"
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <AddCircleIcon size="large" />
              <Typography>{I18n.get("View more")}</Typography>
            </Box>
          </GridListTile>
        )}
      </GridList>

      {!loading && !items.length ? (
        <Box py={1} px={2} bgcolor="background.default">
          <Typography>
            {I18n.get("No items in")} {category.title}
          </Typography>
        </Box>
      ) : null}
    </div>
  );
};

export default MenuList;
