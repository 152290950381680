import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import IconButton from '@material-ui/core/IconButton';
import { Link as RouterLink } from 'react-router-dom';
import { ReactComponent as QRScan } from '../svg/qr-code.svg';

const NavCodeScanButton = () => {
  return (
    <IconButton
      component={RouterLink}
      to="/qr-scan"
      edge="end"
      aria-label="qr scan"
    >
      <SvgIcon
        component={QRScan}
        viewBox="-33 -33 1066.6666 1066.6666"
        fontSize="small"
      />
    </IconButton>
  );
};

export default NavCodeScanButton;
