import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { I18n } from "aws-amplify";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import useReducer from "../../reducers";
import { Box, Typography } from "@material-ui/core";
import { isEmptyObject } from "../../util";

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const PaymentMethodDialog = () => {
  const classes = useStyles();
  const [
    {
      deliveryReducer: { principalRestaurant, cartRestaurant, paymentMethod },
    },
    dispatch,
  ] = useReducer();
  const {
    cashPayments,
    cardPayments,
    tpvPayments,
    oxxoPayments,
    roomChargePayments,
    paypalPayments,
    businessType,
  } = !isEmptyObject(principalRestaurant)
    ? principalRestaurant
    : cartRestaurant || {};

  const handleClose = (value) => {
    dispatch({
      type: "DELIVERY_TOGGLE_PAYMENY_METHOD_DIALOG",
      payload: { open: false },
    });

    dispatch({
      type: "DELIVERY_SET_PAYMENT_METHOD",
      payload: { paymentMethod: value },
    });
  };

  // const handleListItemClick = (value) => {
  //   handleClose(value);
  // };

  const handleChange = (event) => {
    handleClose(event.target.value);
  };

  return (
    <>
      <ListItem>
        <ListItemText
          primary={I18n.get("Select payment method")}
          // secondary={I18n.get(
          //   paymentMethod === "cash" ? "Cash" : "Credit or debit card"
          // )}
        />
      </ListItem>

      <Box
        m={2}
        mt={0}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <FormControl fullWidth variant="filled" className={classes.formControl}>
          <InputLabel id="pm-select">{I18n.get("Payment Method")}</InputLabel>
          <Select
            labelId="pm-select"
            id="pm-select"
            value={paymentMethod}
            onChange={handleChange}
          >
            {cardPayments ? (
              <MenuItem value={"card"}>
                {I18n.get("Credit or debit card")}
              </MenuItem>
            ) : null}
            {cashPayments ? (
              <MenuItem value={"cash"}>{I18n.get("Cash")}</MenuItem>
            ) : null}
            {oxxoPayments && businessType === "MARKET" ? (
              <MenuItem value={"oxxo"}>{I18n.get("OXXO Pay")}</MenuItem>
            ) : null}
            {paypalPayments ? (
              <MenuItem value={"paypal"}>{I18n.get("PayPal")}</MenuItem>
            ) : null}
            {tpvPayments ? (
              <MenuItem value={"tpv"}>{I18n.get("Physical TPV")}</MenuItem>
            ) : null}
            {roomChargePayments ? (
              <MenuItem value={"roomCharge"}>
                {I18n.get("Room Charge")}
              </MenuItem>
            ) : null}
          </Select>
        </FormControl>
        {/**
         {cardPayments ? (
          <Button
            variant={paymentMethod === "card" ? "contained" : "outlined"}
            color={paymentMethod === "card" ? "primary" : "secondary"}
            size="small"
            fullWidth
            style={{
              marginTop: "auto",
              borderRadius: 40,
              paddingTop: 9,
              paddingBottom: 9,
            }}
            onClick={() => handleListItemClick("card")}
          >
            {I18n.get("Credit or debit card")}
          </Button>
        ) : null}
        {cashPayments ? (
          <Button
            variant={paymentMethod === "cash" ? "contained" : "outlined"}
            color={paymentMethod === "cash" ? "primary" : "secondary"}
            size="small"
            fullWidth
            style={{
              marginLeft: 16,
              borderRadius: 40,
              paddingTop: 8,
              paddingBottom: 8,
            }}
            onClick={() => handleListItemClick("cash")}
          >
            {I18n.get("Cash")}
          </Button>
        ) : null}
        {oxxoPayments && businessType === "MARKET" ? (
          <Button
            variant={paymentMethod === "oxxo" ? "contained" : "outlined"}
            color={paymentMethod === "oxxo" ? "primary" : "secondary"}
            size="small"
            fullWidth
            style={{
              marginLeft: 16,
              borderRadius: 40,
              paddingTop: 8,
              paddingBottom: 8,
            }}
            onClick={() => handleListItemClick("oxxo")}
          >
            {I18n.get("OXXO Pay")}
          </Button>
        ) : null}
        {tpvPayments ? (
          <Button
            variant={paymentMethod === "tpv" ? "contained" : "outlined"}
            color={paymentMethod === "tpv" ? "primary" : "secondary"}
            size="small"
            fullWidth
            style={{
              marginLeft: 16,
              borderRadius: 40,
              paddingTop: 8,
              paddingBottom: 8,
            }}
            onClick={() => handleListItemClick("tpv")}
          >
            {I18n.get("Physical TPV")}
          </Button>
        ) : null}
        */}
      </Box>
      {paymentMethod === "card" ? (
        <Box m={2} mt={0}>
          <Typography variant="caption" color="textSecondary">
            {I18n.get(
              "We will ask for the ones on your card after you confirm your order."
            )}
          </Typography>
        </Box>
      ) : null}
    </>
  );
};

export default PaymentMethodDialog;
