import React from "react";
import { saveAs } from "file-saver";
import { I18n } from "aws-amplify";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import FileCopyIcon from "@material-ui/icons/FileCopy";

import useReducer from "../../reducers";
import useFileUrl from "../../hooks/useFileUrl";
import { JSONParse } from "../../util";

const FileButton = ({ text, file }) => {
  const { path, Key } = file ? JSONParse(file) : {};
  const parsedPath = path.split("/");
  const level = parsedPath.shift();
  const key = `${parsedPath.join("/")}/${Key}`;
  const url = useFileUrl({ key, level });

  const handleDownload = (file) => () => {
    var blob = new Blob([file.Body], {
      type: file.ContentType,
    });
    saveAs(blob, Key);
  };

  return (
    <ListItem button onClick={handleDownload(url)} alignItems="flex-start">
      <ListItemIcon>
        {text === "pdf" ? <PictureAsPdfIcon /> : <FileCopyIcon />}
      </ListItemIcon>
      <ListItemText primary={I18n.get("Download")} secondary={text} />
    </ListItem>
  );
};

const DownloadInvoiceStep = () => {
  const [
    {
      invoiceReducer: { invoice },
    },
  ] = useReducer();
  console.log("invoice", invoice);
  const { invoiceFolio, uuid, id, rfc, pdfFile, xmlFile } = invoice || {};

  return (
    <>
      <Typography>{I18n.get("Invoice generated, download here")}</Typography>
      <Typography>{I18n.get("Your invoice was sent by email")}</Typography>

      <List>
        {invoiceFolio ? (
          <>
            <ListItem alignItems="flex-start">
              <ListItemText primary="Folio" secondary={invoiceFolio} />
            </ListItem>
            <Divider component="li" />
          </>
        ) : null}
        {rfc ? (
          <>
            <ListItem alignItems="flex-start">
              <ListItemText primary="RFC" secondary={rfc} />
            </ListItem>
            <Divider component="li" />
          </>
        ) : null}
        {uuid || id ? (
          <>
            <ListItem alignItems="flex-start">
              <ListItemText primary="UUID" secondary={uuid || id} />
            </ListItem>
            <Divider component="li" />
          </>
        ) : null}
        <Divider component="li" />
        <FileButton text="pdf" file={pdfFile}></FileButton>
        <Divider component="li" />
        <FileButton text="xml" file={xmlFile}></FileButton>
      </List>
    </>
  );
};

export default DownloadInvoiceStep;
