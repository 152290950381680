import React from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

import About from "./pages/about";
import QRScanner from "./components/QRScanner";
import PublicRoute from "./components/PublicRoute";
import ProtectedRoute from "./components/ProtectedRoute";
import Terms from "./components/About/Terms";

import Search from "./pages/search";
import Payment from "./pages/payment";
import Tabs from "./pages/tabs";
import Settings from "./pages/settings";
import Orders from "./pages/orders";
import Payments from "./pages/payments";
import Main from "./pages/main";
import Invoicing from "./pages/invoicing";
import OrderPage from "./pages/order";
import PaymentDetailPage from "./pages/paymentDetail";
import Delivery from "./pages/delivery";
import Bonds from "./pages/bonds";
import Commerce from "./pages/commerce";
import BondPage from "./pages/bondPage";
import RedirectAuth from "./pages/redirectAuth";
import CompanyPage from "./pages/company";
import HotelPage from "./pages/hotel";
import Coupon from "./pages/Travel/coupon";
import ReviewPage from "./pages/Review";
import TermsAyV from "./components/About/TermsAyV";
import Wait from "./pages/wait";
import SiteMain from "./SiteMain";
import SurveyPage from "./pages/surveyPage";
import Privacy from "./components/About/Privacy";

const MasterApp = ({ fromSite = false }) => {
  // cooment
  const location = useLocation();
  let background = location.state && location.state.background;

  return (
    <Switch location={background || location}>
      {/** Redirects START */}
      <Redirect from="/category/:categoryId" to="/t/category/:categoryId" />
      <Redirect from="/menu" to="/t/menu" />
      <Redirect from="/qr" to="/qr-scan" />
      <Redirect from="/product/:productId" to="/t/product/:productId" />
      {/** Redirects END */}

      {/** Public Routes START */}
      <PublicRoute path="/qr-scan">
        <QRScanner fromSite={fromSite} />
      </PublicRoute>
      {/** Public Routes END */}

      <Route path="/about">
        <About />
      </Route>
      <Route path={"/settings"}>
        <Settings />
      </Route>
      <Route path={"/orders"}>
        <Orders />
      </Route>
      <Route path={"/coupon/:orderId"}>
        <Coupon />
      </Route>
      <Route path={"/payment"}>
        <Payment />
      </Route>
      <Route path={"/payments"}>
        <Payments />
      </Route>
      <Route path={"/invoicing"}>
        <Invoicing />
      </Route>
      <Route path={`/order/:orderId`}>
        <OrderPage />
      </Route>
      <Route path={`/payment-detail/:paymentId`}>
        <PaymentDetailPage />
      </Route>
      <Route path={"/terms"}>
        <Terms />
      </Route>
      <Route path={"/privacy"}>
        <Privacy />
      </Route>
      <Route path={"/contrato-ayv"}>
        <TermsAyV />
      </Route>
      <Route path={"/main"}>{fromSite ? <SiteMain /> : <Main />}</Route>
      <Route path={`/bono/:restaurantId`}>
        <BondPage />
      </Route>
      <Route path={`/bonos`}>
        <Bonds />
      </Route>
      <Route path={`/h/:hotelSubdomain`}>
        <HotelPage />
      </Route>
      <Route path={`/c/:companySubdomain`}>
        <CompanyPage />
      </Route>
      <Route path={`/s/:slug`}>
        <Commerce />
      </Route>
      <Route path={"/d"}>
        <Delivery fromSite={fromSite} />
      </Route>
      <Route path={"/review/order/:orderId"}>
        <ReviewPage />
      </Route>
      {!fromSite ? (
        <Route path={"/review/:slug"}>
          <ReviewPage rest />
        </Route>
      ) : (
        <Route path={"/review"}>
          <ReviewPage rest fromSite={fromSite} />
        </Route>
      )}
      {!fromSite ? (
        <Route path={"/survey/:restaurantID/:slug"}>
          <SurveyPage />
        </Route>
      ) : (
        <Route path={"/survey/:slug"}>
          <SurveyPage fromSite={fromSite} />
        </Route>
      )}
      <Route path={"/wait/:slug"}>
        <Wait />
      </Route>
      <Route path={"/redirect-auth"}>
        <RedirectAuth />
      </Route>

      {/** Rutas de self-ordering */}
      {/** Protected Routes START */}
      <ProtectedRoute path={"/search"}>
        <Search />
      </ProtectedRoute>
      <ProtectedRoute path={"/t"}>
        <Tabs />
      </ProtectedRoute>
      <ProtectedRoute exact path={"/"}>
        <Redirect to="/t" />
      </ProtectedRoute>
      <ProtectedRoute path="*">
        <Redirect to="/t" />
      </ProtectedRoute>
      {/** Protected Routes END */}
    </Switch>
  );
};

export default MasterApp;
