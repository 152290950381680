import React from "react";
import { Cache } from "aws-amplify";
import { Auth } from "aws-amplify";
import { API, graphqlOperation } from "aws-amplify";

import useReducer from "../reducers";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { getHostname, JSONParse, getPictureUrl } from "../util";
import getDynamicManifest from "../lib/getDynamicManifest";
import { lighten } from "polished";

const useBootstrapApp = () => {
  const [, dispatch] = useReducer();

  React.useEffect(() => {
    let mounted = true;
    const bootstrapAsync = async () => {
      const currentOrder = Cache.getItem("currentOrderId");
      const currentRestaurantId = Cache.getItem("currentRestaurantId");
      const cachedCurrentDeliveryOrderId = Cache.getItem(
        `currentDeliveryOrderId`
      );
      const cachedBondOrderId = Cache.getItem(`bondOrderId`);

      if (currentOrder && currentRestaurantId) {
        const orderQuery = API.graphql({
          ...graphqlOperation(queries.getOrder, { id: currentOrder }),
          authMode: "AWS_IAM",
        });
        const restaurantQuery = API.graphql({
          ...graphqlOperation(queries.getRestaurantData, {
            id: currentRestaurantId,
          }),
          authMode: "AWS_IAM",
        });

        const { data } = await orderQuery;
        const { data: restData } = await restaurantQuery;
        const { getOrder: order } = data || {};
        const { getRestaurant: currentRestaurant } = restData || {};
        // console.log("protected order", order, currentRestaurant);

        if (mounted) {
          if (
            order &&
            !order._deleted &&
            ["NEW", "OPEN"].includes(order.status)
          ) {
            let currOrder = order;
            if (!(order.orderCustomerId || order.customer)) {
              let currentUser;
              try {
                currentUser = await Auth.currentAuthenticatedUser();
              } catch (error) {
                console.log("error", error);
              }

              if (currentUser) {
                // console.log("add customer to order");

                const { data } = await API.graphql({
                  ...graphqlOperation(mutations.updateOrder, {
                    input: {
                      id: currentOrder,
                      orderCustomerId: currentUser.username,
                      _version: order._version,
                    },
                  }),
                  authMode: "AWS_IAM",
                });
                const { updateOrder: updateOrderResponse } = data || {};

                const updatedOrder = updateOrderResponse || order;
                currOrder = updatedOrder;
              }
            }

            dispatch({
              type: "ORDER_SET",
              payload: { currentOrder: currOrder },
            });
            dispatch({
              type: "RESTAURANT_SET",
              payload: { currentRestaurant },
            });

            if (currentRestaurant?.accountId) {
              let apiName = "api616cdc2a";
              let path = "/stripe/account";

              try {
                const result = await API.get(apiName, path, {
                  queryStringParameters: {
                    account: currentRestaurant?.accountId,
                  },
                });
                console.log("/stripe/account response", result);
                dispatch({
                  type: "CHECKOUT_SET_ACCOUNT_CHARGES_ENABLED",
                  payload: {
                    accountChargesEnabled: Boolean(result.charges_enabled),
                  },
                });
              } catch (error) {
                console.log("error", error);
              }
            }

            if (
              currentRestaurant?.primaryColor ||
              currentRestaurant?.company?.primaryColor
            ) {
              dispatch({
                type: "THEME_SET_CUSTOM",
                payload: {
                  primaryColor:
                    currentRestaurant?.primaryColor ||
                    currentRestaurant?.company?.primaryColor,
                },
              });
            }
          } else {
            Cache.removeItem("currentOrderId");
            Cache.removeItem("currentRestaurantId");
            dispatch({ type: "ORDER_RESET" });
            dispatch({ type: "RESTAURANT_RESET" });
            dispatch({ type: "CHECKOUT_RESET" });
          }
        }
      } else {
        dispatch({ type: "ORDER_RESET" });
        dispatch({ type: "RESTAURANT_RESET" });
        dispatch({ type: "CHECKOUT_RESET" });
      }

      if (cachedCurrentDeliveryOrderId) {
        try {
          const orderQuery = API.graphql({
            ...graphqlOperation(queries.getOrder, {
              id: cachedCurrentDeliveryOrderId,
            }),
            authMode: "AWS_IAM",
          });
          const { data } = await orderQuery;
          const { getOrder: order } = data || {};

          if (
            order &&
            !order._deleted &&
            ["NEW", "OPEN", "ACCEPTED", "PREPARING", "SENT"].includes(
              order.status
            )
          ) {
            dispatch({
              type: "DELIVERY_SET_ORDER",
              payload: { currentDeliveryOrder: order },
            });
            dispatch({
              type: "DELIVERY_SET_ORDER_ID",
              payload: {
                currentDeliveryOrderId: cachedCurrentDeliveryOrderId,
              },
            });
          } else {
            Cache.removeItem("currentDeliveryOrderId");
            dispatch({ type: "DELIVERY_RESTAURANT_RESET" });
          }
        } catch (error) {
          console.log("error", error);
        }
      }

      if (cachedBondOrderId) {
        try {
          const orderQuery = API.graphql({
            ...graphqlOperation(queries.getOrder, {
              id: cachedBondOrderId,
            }),
            authMode: "AWS_IAM",
          });
          const { data } = await orderQuery;
          const { getOrder: order } = data || {};

          if (
            order &&
            !order._deleted &&
            ["NEW", "OPEN", "ACCEPTED", "PREPARING", "SENT"].includes(
              order.status
            )
          ) {
            dispatch({
              type: "BOND_SET_BOND_ORDER",
              payload: { bondOrder: order },
            });
            dispatch({
              type: "BOND_SET_BOND_ORDER_ID",
              payload: {
                bondOrderId: cachedBondOrderId,
              },
            });
          } else {
            Cache.removeItem("bondOrderId");
            dispatch({ type: "BOND_RESET" });
          }
        } catch (error) {
          console.log("error", error);
        }
      }

      const domain = getHostname();

      const customManifest = {
        name: "2Dine Self Ordering System",
        description: "El sistema más avanzado de self-ordering & menú digital",
        theme_color: "#ffaa00",
        background_color: "#ffaa00",
      };

      if (domain) {
        try {
          const { data } = await API.graphql({
            ...graphqlOperation(queries.restaurantbyCustomDomain, {
              customDomain: domain,
            }),
            authMode: "AWS_IAM",
          });
          const { restaurantbyCustomDomain: rests } = data || {};
          const {
            items: [rest],
          } = rests || {};
          console.log("rest", rest);

          if (rest) {
            const onlyDelivery =
              ["TRAVEL", "MARKET"].includes(rest.businessType) ||
              rest.status !== "ACTIVE";
            dispatch({
              type: "SITE_SET_DATA",
              payload: { siteDomain: domain, siteData: rest, onlyDelivery },
            });

            customManifest.name = rest.name;
            customManifest.description = rest.description;
            customManifest.theme_color =
              rest.primaryColor || rest.company.primaryColor;
            customManifest.background_color =
              rest.secondaryColor ||
              lighten(0.2, rest.primaryColor || rest.company.primaryColor);

            const picture = rest && rest.picture ? JSONParse(rest.picture) : {};
            const imageUrl =
              rest && rest.picture
                ? getPictureUrl({ ...picture, size: "512x512" })
                : null;
            if (imageUrl) {
              customManifest.icons = [
                {
                  src: imageUrl,
                  type: "image/png",
                  sizes: "512x512",
                  purpose: "any maskable",
                },
              ];
            }

            if (rest?.primaryColor || rest?.company?.primaryColor) {
              dispatch({
                type: "THEME_SET_CUSTOM",
                payload: {
                  primaryColor:
                    rest?.primaryColor || rest?.company?.primaryColor,
                },
              });
            }
            dispatch({
              type: "DELIVERY_RESTAURANT_SET",
              payload: { selectedRestaurant: rest },
            });

            if (rest?.accountId) {
              let apiName = "api616cdc2a";
              let path = "/stripe/account";

              try {
                const result = await API.get(apiName, path, {
                  queryStringParameters: {
                    account: rest?.accountId,
                  },
                });
                console.log("/stripe/account response", result);
                dispatch({
                  type: "CHECKOUT_SET_ACCOUNT_CHARGES_ENABLED",
                  payload: {
                    accountChargesEnabled: Boolean(result.charges_enabled),
                  },
                });
              } catch (error) {
                console.log("error", error);
              }
            }
          } else {
            window.location.href =
              process.env.NODE_ENV === "production"
                ? "https://www.2dine.io"
                : "localhost:3000";
          }
        } catch (error) {
          console.log("error", error);
        }
      } else {
        customManifest.short_name = "2dine";
      }

      getDynamicManifest(customManifest);
      dispatch({ type: "LOADING_APP_COMPLETED" });
    };

    bootstrapAsync();

    return () => {
      mounted = false;
    };
  }, []);
};

export default useBootstrapApp;
