import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import { Typography, makeStyles } from "@material-ui/core";
import { handleProductPrice, toCurrency, handleAvailability } from "../../util";
import useReducer from "../../reducers";
import { useParams, useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  secondaryAction: {
    top: 0,
    right: 0,
    position: "absolute",
    transform: "none",
  },
}));

const ProductItem = ({
  index,
  product = {},
  delivery = false,
  fromSite = false,
}) => {
  let { restaurantId } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [
    {
      deliveryReducer: {
        selectedRestaurant: { currency: restCurrency },
      },
      restaurantReducer: {
        currentRestaurant: { currency },
      },
    },
  ] = useReducer();

  const selectedCurrency = currency || restCurrency;
  const productPrice = React.useMemo(
    () => handleProductPrice(product, delivery),
    [product, delivery]
  );

  // Check if product is available (hours)
  const isProductAvailable = React.useMemo(() => handleAvailability(product), [
    product,
  ]);

  const handleClickCard = () => {
    if (product.status === "ACTIVE" && isProductAvailable) {
      if (!delivery) {
        history.push(`/t/product/${product.id}`);
      } else {
        history.push(
          fromSite
            ? `/d/product/${product.id}`
            : `/d/r/${restaurantId}/product/${product.id}`
        );
      }
    }
  };

  if (!product || product._deleted || product.status !== "ACTIVE") {
    return null;
  }

  return (
    <ListItem divider disableGutters button onClick={handleClickCard}>
      <ListItemText
        primary={product.title ? product.title : null}
        secondary={product.description ? product.description : null}
      />
      <ListItemSecondaryAction classes={{ root: classes.secondaryAction }}>
        <Typography style={{ fontWeight: 700 }}>
          {productPrice
            ? `${selectedCurrency} ${toCurrency(
                (productPrice || 0).toString()
              )}`
            : null}
        </Typography>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default ProductItem;
