import React from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

import Delivery from "./pages/delivery";
import useReducer from "./reducers";
import MasterApp from "./MasterApp";
import Search from "./pages/search";
import RedirectAuth from "./pages/redirectAuth";
import About from "./pages/about";
import Settings from "./pages/settings";
import Payment from "./pages/payment";
import Payments from "./pages/payments";
import Invoicing from "./pages/invoicing";
import Orders from "./pages/orders";
import PaymentDetailPage from "./pages/paymentDetail";
import Terms from "./components/About/Terms";
import Privacy from "./components/About/Privacy";

const SiteApp = () => {
  const location = useLocation();
  const [
    {
      siteReducer: { onlyDelivery },
    },
  ] = useReducer();
  let background = location.state && location.state.background;

  return onlyDelivery ? (
    <Switch location={background || location}>
      <Route path="/about">
        <About />
      </Route>
      <Route path={"/settings"}>
        <Settings />
      </Route>
      <Route path={"/orders"}>
        <Orders />
      </Route>
      <Route path={"/payment"}>
        <Payment />
      </Route>
      <Route path={"/payments"}>
        <Payments />
      </Route>
      <Route path={"/invoicing"}>
        <Invoicing />
      </Route>

      <Route path={`/payment-detail/:paymentId`}>
        <PaymentDetailPage />
      </Route>
      <Route path={"/terms"}>
        <Terms />
      </Route>
      <Route path={"/privacy"}>
        <Privacy />
      </Route>

      <Route path="/d">
        <Delivery fromSite />
      </Route>

      <Route path={"/redirect-auth"}>
        <RedirectAuth />
      </Route>

      <Route path={"/search"}>
        <Search />
      </Route>
      <Route path="*">
        <Redirect to="/d" />
      </Route>
    </Switch>
  ) : (
    <MasterApp fromSite />
  );
};

export default SiteApp;
