import React from "react";
import { Cache, I18n } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
// import Slide from "@material-ui/core/Slide";
import { useHistory } from "react-router-dom";
import { Box } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { API, graphqlOperation } from "aws-amplify";

import Divide from "../components/Checkout/Divide";
import CustomAmount from "../components/Checkout/CustomAmount";
import ByGuest from "../components/Checkout/ByGuest";
import PaymentMethod from "../components/Checkout/PaymentMethod";
import Tip from "../components/Checkout/Tip";
import useReducer from "../reducers";
import useCheckout from "../hooks/useCheckout";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import {
  JSONParse,
  filterOrderItems,
  getModifiers,
  getAmount,
  getAmountPaid,
  getTaxes,
  getDiscountAmount,
  getDiscountItems,
} from "../util";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
}));

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const getSteps = (tipsEnabled) => {
  const steps = ["Divide check", "Guests", "Custom amount", "Payment Method"];

  if (tipsEnabled) {
    steps.push("Tip");
  }

  return steps;
};

const Checkout = () => {
  const classes = useStyles();
  const history = useHistory();
  const checkout = useCheckout();
  const { enqueueSnackbar } = useSnackbar();
  const [
    {
      restaurantReducer: { currentRestaurant },
      checkoutReducer: { checkoutStep },
      orderReducer: { currentOrder },
      checkoutReducer: { amountToPay, tipAmount, guestsChecked },
      loginReducer: { currentUser },
    },
    dispatch,
  ] = useReducer();
  const { taxes, currency } = currentRestaurant || {};

  // console.log("currentRestaurant", currentRestaurant);
  const { tipsEnabled, cashPayments, cardPayments, paypalPayments } =
    currentRestaurant || {};

  const steps = getSteps(tipsEnabled);

  React.useEffect(() => {
    let mounted = true;
    const orderId = Cache.getItem("currentOrderId");
    const fetchData = async (isSubscription = false) => {
      const restTaxes = taxes ? JSONParse(taxes) : [];

      API.graphql({
        ...graphqlOperation(queries.getOrder, { id: orderId }),
        authMode: "AWS_IAM",
      }).then((orderResponse) => {
        const currOrder =
          orderResponse && orderResponse.data && orderResponse.data.getOrder
            ? orderResponse.data.getOrder
            : null;
        // console.log("currOrder", currOrder);
        if (currOrder) {
          const orderItems =
            currOrder && currOrder.items && currOrder.items.items
              ? filterOrderItems(currOrder.items.items)
              : [];
          const discountItems =
            currOrder && currOrder.items && currOrder.items.items
              ? getDiscountItems(currOrder.items.items)
              : [];
          const payments =
            currOrder && currOrder.payments && currOrder.payments.items
              ? currOrder.payments.items.filter((x) => !x._deleted)
              : [];

          // console.log("fetch Result", orderItems);
          // console.log("fetch PaymentResult", payments);

          if (mounted) {
            dispatch({
              type: "ORDER_SET_ORDERITEMS",
              payload: {
                orderItems: !isSubscription
                  ? orderItems
                  : orderItems.map((r) => ({
                      ...r,
                      modifiers: getModifiers(r.modifiers),
                    })),
              },
            });
            dispatch({
              type: "ORDER_SET_DISCOUNT_ITEMS",
              payload: {
                discountItems,
              },
            });
            const amount = getAmount(orderItems);
            const paid = getAmountPaid(payments);

            dispatch({
              type: "ORDER_SET_AMOUNT",
              payload: { orderAmount: amount },
            });
            dispatch({
              type: "ORDER_SET_TAXES",
              payload: { orderTaxes: getTaxes(restTaxes, amount) },
            });
            dispatch({
              type: "ORDER_SET_DISCOUNT_AMOUNT",
              payload: {
                orderDiscountAmount: getDiscountAmount(discountItems),
              },
            });
            dispatch({
              type: "ORDER_SET_AMOUNTPAID",
              payload: { amountPaid: paid },
            });
            dispatch({
              type: "ORDER_SET_ORDERPAYMENTS",
              payload: {
                orderPayments: payments,
              },
            });
          }
        }
      });
    };

    if (orderId) {
      fetchData();
    }

    return () => {
      mounted = false;
    };
  }, [taxes]);

  const handlePay = async () => {
    if (!amountToPay) {
      enqueueSnackbar(I18n.get("You need an amount to pay!"), {
        variant: "info",
      });
      return;
    }

    const lineItems = [
      {
        name: currentRestaurant.name,
        description: "Your consumption in Restaurant",
        amount: amountToPay,
        currency: (currentRestaurant.currency || "usd").toLowerCase(),
        quantity: 1,
      },
    ];

    if (tipsEnabled && tipAmount) {
      lineItems.push({
        name: "Tip",
        description: "Tip of your Restaurant consumption",
        amount: tipAmount,
        currency: (currentRestaurant.currency || "usd").toLowerCase(),
        quantity: 1,
      });
    }
    // console.log("lineItems", lineItems);

    const metadata = {
      userId: currentUser && currentUser.id ? currentUser.id : null,
      orderType: "tostay",
      guests:
        guestsChecked && guestsChecked.length
          ? JSON.stringify(guestsChecked)
          : null,
      restaurantName: currentRestaurant ? currentRestaurant.name : null,
      restaurant: currentRestaurant ? currentRestaurant.id : null,
    };

    dispatch({ type: "LOADING_START" });
    await checkout({ lineItems, orderId: currentOrder.id, metadata });
  };

  const handlePaypalPay = async (isPaypal, paypalData) => {
    dispatch({ type: "LOADING_START" });
    if (!amountToPay) {
      enqueueSnackbar(I18n.get("You need an amount to pay!"), {
        variant: "info",
      });
      dispatch({ type: "LOADING_STOP" });
      return;
    }

    const amount = amountToPay + (tipAmount || 0);

    const paymentInput = {
      paymentData: JSON.stringify({
        payment_method: "paypal",
        ...paypalData,
      }),
      status: "succeeded",
      amount: amount,
      tipAmount: tipAmount,
      deliveryAmount: 0,
      paymentOrderId: currentOrder.id,
      internal: true,
    };

    const { data } = await API.graphql({
      ...graphqlOperation(mutations.createPayment, { input: paymentInput }),
      authMode: "AWS_IAM",
    });
    const { createPayment } = data || {};
    console.log("createPayment", createPayment);
    history.push({
      pathname: `/payment/paypal`,
      search: `?o=${currentOrder.id}`,
    });
    return;
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <Divide />;
      case 1:
        return <ByGuest />;
      case 2:
        return <CustomAmount />;
      case 3:
        return (
          <PaymentMethod
            tipsEnabled={tipsEnabled}
            cashPayments={cashPayments}
            cardPayments={cardPayments}
            paypalPayments={paypalPayments}
            currency={currency}
            handlePay={handlePay}
            handlePaypalPay={handlePaypalPay}
          />
        );
      case 4:
        return <Tip handlePay={handlePay} handlePaypalPay={handlePaypalPay} />;
      default:
        return "Unknown stepIndex";
    }
  };

  const handleReset = () => {
    dispatch({ type: "CHECKOUT_SET_STEP", payload: { checkoutStep: 0 } });
  };

  const back = () => {
    history.goBack();
  };

  return (
    <Dialog
      container={() => document.getElementById("mainBox")}
      fullScreen
      open={true}
      onClose={back}
      // TransitionComponent={Transition}
      style={{
        zIndex: 1300,
        right: 0,
        bottom: 0,
        top: 0,
        left: 0,
        position: "absolute",
      }}
      BackdropProps={{
        style: {
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          zIndex: -1,
          position: "absolute",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          WebkitTapHighlightColor: "transparent",
        },
      }}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={back}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {checkoutStep === steps.length
              ? I18n.get("Confirmation")
              : I18n.get(steps[checkoutStep])}{" "}
            | {I18n.get("Checkout")}
          </Typography>
        </Toolbar>
      </AppBar>

      {checkoutStep === steps.length ? (
        <div>
          <Typography className={classes.instructions}>
            {I18n.get("All steps completed")}
          </Typography>
          <Button onClick={handleReset}>Reset</Button>
        </div>
      ) : (
        <Box mt={1} flex={1} display="flex" flexDirection="column">
          {getStepContent(checkoutStep)}
        </Box>
      )}
    </Dialog>
  );
};

export default Checkout;
