import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import * as Sentry from "@sentry/browser";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";

import "./tailwind.output.css";
import App from "./App";
import { Reducer } from "./reducers";
import Layout from "./components/Layout";
import ErrorBoundary from "./components/ErrorBoundary";
// import * as serviceWorker from "./serviceWorker";
import ServiceWorkerWrapper from "./components/ServiceWorkerWrapper";

Sentry.init({
  dsn: "https://a36e2bb654dc4322a78dd37eafd78df6@sentry.io/1895541",
  debug: false,
  ignoreErrors: [
    "AbortError",
    "Non-Error exception captured",
    "UnhandledRejection",
    "RangeError",
  ],
  release: "2dine-client@" + process.env.REACT_APP_VERSION,
});

ReactGA.initialize("UA-166850118-2");
ReactGA.pageview(window.location.pathname + window.location.search);
ReactPixel.init("698696804215895");
ReactPixel.pageView(); // For tracking page view

ReactDOM.render(
  <Router>
    <ErrorBoundary>
      <Reducer>
        <Layout>
          <App />
        </Layout>
        <ServiceWorkerWrapper />
      </Reducer>
    </ErrorBoundary>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
