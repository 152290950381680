import React from "react";
import Modal from "@material-ui/core/Modal";
import { useHistory, Redirect } from "react-router-dom";

import useReducer from "../../reducers";
import SearchContainer from "./SearchContainer";
import useQuery from "../../hooks/useQuery";
import { useLocation } from "react-use";
import { isEmptyObject } from "../../util";

const SearchModal = () => {
  const [
    {
      orderReducer: { currentOrder },
    },
    dispatch,
  ] = useReducer();
  const location = useLocation();
  const history = useHistory();
  const query = useQuery();
  const origin = query.get("origin"); // origin [delivery]

  const back = (e) => {
    e.stopPropagation();
    dispatch({ type: "SEARCH_SET_QUERY", payload: { searchQuery: "" } });
    history.goBack();
  };

  if (!origin && (!currentOrder || isEmptyObject(currentOrder))) {
    return <Redirect to={{ pathname: "/main", state: { from: location } }} />;
  }

  return (
    <Modal
      container={() => document.getElementById("mainBox")}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={true}
      onClose={back}
      style={{
        position: "absolute",
        display: "flex",
        flexDirection: "column",
      }}
      BackdropProps={{
        style: {
          position: "absolute",
        },
      }}
    >
      <SearchContainer delivery={origin === "delivery"} />
    </Modal>
  );
};

export default SearchModal;
