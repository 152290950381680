import React from "react";
import Link from "@material-ui/core/Link";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";

import SignUpForm from "./SignUpForm";
import useReducer from "../../reducers";

const useStyles = makeStyles((theme) => ({
  link: {
    margin: theme.spacing(1),
  },
  sectionTitle: {
    margin: theme.spacing(2, 2, 0, 2),
  },
  section: {
    margin: theme.spacing(0, 2, 2),
  },
}));

const Signup = () => {
  const classes = useStyles();
  const [, dispatch] = useReducer();

  return (
    <>
      <div className={classes.sectionTitle}>
        <Typography variant="h6">Crea tu cuenta gratis</Typography>
      </div>
      <Divider variant="middle" light />
      <div className={classes.section}>
        <SignUpForm />
      </div>
      <Typography
        component="p"
        style={{
          marginTop: 8,
          marginLeft: 16,
          marginRight: 16,
          marginBottom: 16,
        }}
      >
        ¿Ya tienes cuenta?{" "}
        <Link
          onClick={() => {
            dispatch({ type: "LOGIN_SET_VIEW", payload: { view: "signin" } });
          }}
          className={classes.link}
        >
          Inicia sesión
        </Link>
      </Typography>
    </>
  );
};

export default Signup;
