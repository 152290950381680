import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { API, graphqlOperation, I18n, Cache } from "aws-amplify";
import SvgIcon from "@material-ui/core/SvgIcon";
import { makeStyles } from "@material-ui/core/styles";

import * as queries from "../../graphql/queries";
import useQuery from "../../hooks/useQuery";
import useReducer from "../../reducers";
import { ReactComponent as CompletedIcon } from "../../svg/undraw_completed.svg";

const useStyles = makeStyles((theme) => ({
  title: {
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  icon: {
    fontSize: 80,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
}));

const PaymentPaypal = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const orderId = query.get("o");
  const ot = query.get("ot");
  const isTravel = ot === "travel";
  const isDelivery = ot === "todeliver" || ot === "togo" || ot === "dinein";
  const [
    {
      orderReducer: { currentOrder },
    },
    dispatch,
  ] = useReducer();

  React.useEffect(() => {
    const fetchOrder = async () => {
      dispatch({
        type: "LOADING_START",
        payload: { message: "Checking payment data" },
      });
      const orderQuery = API.graphql({
        ...graphqlOperation(queries.getOrder, {
          id: orderId,
        }),
        authMode: "AWS_IAM",
      });

      const { data } = (await orderQuery) || {};
      const { getOrder: order } = data || {};
      console.log("order", order);

      if (!order || (order && order._deleted)) {
        dispatch({ type: "LOADING_STOP" });
        if (!isDelivery) {
          history.replace("/");
        } else {
          history.replace("/d");
        }
      }

      dispatch({ type: "LOADING_STOP" });
    };

    if (orderId) {
      fetchOrder();
    } else {
      history.replace("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDelivery, orderId]);

  const handleContinue = async () => {
    if (
      !currentOrder ||
      (currentOrder &&
        ["CLOSED", "CANCELLED", "DELETED"].includes(currentOrder.status))
    ) {
      await Cache.removeItem("currentOrderId");
      dispatch({ type: "ORDER_RESET" });
      dispatch({ type: "RESTAURANT_RESET" });
      dispatch({ type: "CHECKOUT_RESET" });
      history.push("/main");
    } else {
      history.push({
        pathname: "/checkout",
        state: { background: location },
      });
    }
  };

  const handleDelvieryContinue = async () => {
    Cache.removeItem("deliveryCart");
    Cache.removeItem("cartRestaurant");
    Cache.removeItem("cartCompany");
    history.push({ pathname: `/d/order/${orderId}` });
  };

  return (
    <Box
      flex={1}
      flexDirection="column"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="h4" className={classes.title}>
        {I18n.get("Payment Succeeds")}
      </Typography>
      <SvgIcon
        component={CompletedIcon}
        viewBox="0 0 809.83511 872.15034"
        style={{ fontSize: 120 }}
      />
      <div style={{ marginTop: 16, width: "100%" }}>
        <Button
          size="large"
          fullWidth
          variant="contained"
          color="primary"
          onClick={
            isDelivery || isTravel ? handleDelvieryContinue : handleContinue
          }
        >
          {I18n.get("Continue")}
        </Button>
      </div>
    </Box>
  );
};

export default PaymentPaypal;
