import React from "react";
import { Cache } from "aws-amplify";
import { useHistory } from "react-router-dom";

import Loading from "../components/Loading";
import useQuery from "../hooks/useQuery";

const RedirectAuth = () => {
  const fromUrl = Cache.getItem("fromUrl");
  const query = useQuery();
  const code = query.get("code");
  const history = useHistory();
  // console.log("fromUrl", fromUrl);

  React.useEffect(() => {
    if (!code || !fromUrl) {
      history.replace("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromUrl, code]);

  return <Loading force />;
};

export default RedirectAuth;
