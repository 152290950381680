import React from "react";
import { I18n } from "aws-amplify";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { API, graphqlOperation } from "aws-amplify";

import useNotification from "../hooks/useNotification";
import ConfirmDialog from "./ConfirmDialog";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import useReducer from "../reducers";
import { JSONParse, isEmptyObject } from "../util";
import { isPushNotificationSupported } from "../lib/pushNotifications";

const NotificationCheck = () => {
  const [loading, setLoading] = React.useState(true);
  const [checked, setChecked] = React.useState(false);
  const [identityResponse, setIdentity] = React.useState({});
  const [openUnsubscribe, setOpenUnsubscribe] = React.useState(false);
  const {
    userConsent,
    userSubscription,
    onClickAskUserPermission,
    onClickSusbribeToPushNotification,
    onClickUnsusbribeToPushNotification,
  } = useNotification();
  const [
    {
      loginReducer: { identityId },
    },
  ] = useReducer();

  const pushNotificationSupported = isPushNotificationSupported();

  React.useEffect(() => {
    let mounted = true;
    const fetchData = async () => {
      try {
        const { data } = await API.graphql({
          ...graphqlOperation(queries.getIdentity, {
            id: identityId,
          }),
        });
        const { getIdentity: response } = data || {};
        if (mounted) {
          setIdentity(response || {});
        }
        console.log("response", response);
      } catch (error) {
        console.log("error", error);
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    };

    if (identityId) {
      fetchData();
    }

    return () => {
      mounted = false;
    };
  }, [identityId]);

  React.useEffect(() => {
    let mounted = true;
    const hasSubscription = Boolean(userSubscription);
    if (mounted) {
      setChecked(hasSubscription);
    }

    if (
      hasSubscription &&
      identityId &&
      !loading &&
      !(identityResponse && !isEmptyObject(identityResponse))
    ) {
      handleCreateIdentity([userSubscription]);
    }

    return () => {
      mounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, userSubscription, identityResponse]);

  const handleToggle = async () => {
    if (!checked) {
      if (userConsent === "granted") {
        const newSubscription = await onClickSusbribeToPushNotification();
        if (newSubscription) {
          if (identityResponse && !isEmptyObject(identityResponse)) {
            const newTokens = getExistentTokens();
            await handleUpdateIdentity([...newTokens, newSubscription]);
          } else {
            await handleCreateIdentity([newSubscription]);
          }
        }
      } else {
        onClickAskUserPermission();
      }
    } else {
      setOpenUnsubscribe(true);
    }
  };

  const handleUnsubscribe = async () => {
    try {
      const newTokens = getExistentTokens();
      const { endpoint: actualEndpoint } = userSubscription;
      const index = newTokens.findIndex(
        ({ endpoint }) => actualEndpoint === endpoint
      );
      newTokens.splice(index, 1);
      await handleUpdateIdentity(newTokens);
      await onClickUnsusbribeToPushNotification();
      setOpenUnsubscribe(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getExistentTokens = () => {
    const { notificationTokens = [] } = identityResponse || {
      notificationTokens: [],
    };
    const parsedNotificationTokens = notificationTokens
      ? JSONParse(notificationTokens)
      : [];
    const newTokens = [...parsedNotificationTokens];

    return newTokens;
  };

  const handleUpdateIdentity = async (tokens) => {
    try {
      const response = await API.graphql({
        ...graphqlOperation(mutations.updateIdentity, {
          input: {
            id: identityId,
            notificationTokens:
              tokens && tokens.length ? JSON.stringify(tokens) : null,
            _version: identityResponse._version || 1,
          },
        }),
      });

      console.log("updateIdentityResponse", response);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleCreateIdentity = async (tokens) => {
    try {
      const response = await API.graphql({
        ...graphqlOperation(mutations.createIdentity, {
          input: {
            id: identityId,
            notificationTokens:
              tokens && tokens.length ? JSON.stringify(tokens) : null,
          },
        }),
      });

      console.log("createIdentityResponse", response);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      {pushNotificationSupported ? (
        <ListItem role={undefined} dense button onClick={handleToggle}>
          <ListItemIcon>
            <Checkbox checked={checked} tabIndex={-1} disableRipple />
          </ListItemIcon>
          <ListItemText
            primary={`Activar notificaciones`}
            secondary={"Recibe estatus de tus órdenes, avisos y promociones."}
          />
        </ListItem>
      ) : null}
      <ConfirmDialog
        open={openUnsubscribe}
        onClose={() => {
          setOpenUnsubscribe(false);
        }}
        onConfirm={handleUnsubscribe}
        message={I18n.get(
          "Are you sure you want to unsubscribe notifications?"
        )}
      />
    </>
  );
};

export default NotificationCheck;
