import React from "react";
import moment from "moment";
import { I18n } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import { useParams, useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { API, graphqlOperation } from "aws-amplify";

import * as queries from "../graphql/queries";
import OrderItemStatusComponent from "../components/Order/OrderItemStatus";
import {
  toCurrency,
  getCardData,
  isEmptyObject,
  filterOrderItems,
  getDiscountItems,
} from "../util";
import SignedInRoute from "../components/SignedInRoute";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  boxItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const OrderPage = () => {
  const classes = useStyles();
  let { orderId } = useParams();
  const history = useHistory();
  const [order, setOrder] = React.useState({});
  const [expanded, setExpanded] = React.useState(false);

  React.useEffect(() => {
    let mounted = true;

    if (!orderId) {
      history.replace("/orders");
    }

    const fetchData = async () => {
      const { data } = await API.graphql({
        ...graphqlOperation(queries.getOrder, {
          id: orderId,
        }),
        authMode: "AWS_IAM",
      });
      const { getOrder: response = {} } = data || {};
      // console.log("getOrder response", response);

      if (!response || isEmptyObject(response)) {
        history.replace("/orders");
      }

      if (mounted) {
        setOrder(response);
      }
    };

    fetchData();
    return () => {
      mounted = false;
    };
  }, [history, orderId]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const orderItems =
    order && order.items && order.items.items
      ? filterOrderItems(order.items.items)
      : [];
  console.log("orderItems", orderItems);
  const payments =
    order && order.payments && order.payments.items ? order.payments.items : [];

  const discountItems =
    order && order.items && order.items.items
      ? getDiscountItems(order.items.items)
      : [];
  console.log("discountItems", discountItems);

  return (
    <SignedInRoute>
      <>
        <Box my={1}>
          <Typography variant="h4">{I18n.get("Order Detail")}</Typography>
          <Typography variant="body1">{orderId}</Typography>
        </Box>
        <Paper className={classes.paper}>
          <Box>
            <Box className={classes.boxItem}>
              <Typography variant="subtitle1">{I18n.get("Serie")}</Typography>
              <Typography>{order.orderSerie}</Typography>
            </Box>
            <Box className={classes.boxItem}>
              <Typography variant="subtitle1">{I18n.get("Place")}</Typography>
              <Typography>
                {order && order.resturant && order.restaurant.name}
              </Typography>
            </Box>
            <Box className={classes.boxItem}>
              <Typography variant="subtitle1">{I18n.get("Created")}</Typography>
              <Typography>{moment(order.createdAt).format("LLL")}</Typography>
            </Box>
            {order && order.closedAt ? (
              <Box className={classes.boxItem}>
                <Typography variant="subtitle1">
                  {I18n.get("Closed")}
                </Typography>
                <Typography>{moment(order.closedAt).format("LLL")}</Typography>
              </Box>
            ) : null}
            <Box className={classes.boxItem}>
              <Typography variant="subtitle1">{I18n.get("Status")}</Typography>
              <OrderItemStatusComponent status={order.status} />
            </Box>
          </Box>
        </Paper>

        <Box mt={2}>
          <Accordion
            expanded={expanded === "orderItems"}
            onChange={handleChange("orderItems")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography className={classes.heading}>
                {I18n.get("Items")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">{I18n.get("Qty")}</TableCell>
                      <TableCell align="left">{I18n.get("Product")}</TableCell>
                      <TableCell align="left">{I18n.get("Amount")}</TableCell>
                      <TableCell align="left">{I18n.get("Status")}</TableCell>
                      <TableCell align="left">{I18n.get("Guest")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {orderItems.map((row) =>
                      row._deleted ? null : (
                        <TableRow key={row.id} hover>
                          <TableCell component="th" scope="row">
                            {row.qty}
                          </TableCell>
                          <TableCell align="left">{row.title}</TableCell>
                          <TableCell align="left">
                            {toCurrency((row.amount || 0).toString())}
                          </TableCell>
                          <TableCell align="left">
                            <OrderItemStatusComponent status={row.status} />
                          </TableCell>
                          <TableCell align="left">
                            {!row.guest ? I18n.get("Table") : row.guest}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "payments"}
            onChange={handleChange("payments")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Typography className={classes.heading}>
                {I18n.get("Payments")}
              </Typography>
              {order.isPaid ? (
                <Typography className={classes.secondaryHeading}>
                  Order paid
                </Typography>
              ) : null}
            </AccordionSummary>
            <AccordionDetails>
              <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell align="left">{I18n.get("Amount")}</TableCell>
                      <TableCell align="left">
                        {I18n.get("Payment Method")}
                      </TableCell>
                      <TableCell align="left">{I18n.get("ID")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {payments.map((row) =>
                      row._deleted ? null : (
                        <TableRow
                          key={row.id}
                          hover
                          onClick={() =>
                            history.push(`/payment-detail/${row.id}`)
                          }
                        >
                          <TableCell component="th" scope="row">
                            {moment(row.createdAt).format("MM/DD/YYYY HH:mm")}
                          </TableCell>
                          <TableCell align="left">
                            {toCurrency((row.amount || 0).toString())}
                          </TableCell>
                          <TableCell align="left">
                            {getCardData(row.paymentData)}
                          </TableCell>
                          <TableCell align="left">
                            {row.id.split("-")[0]}
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        </Box>
      </>
    </SignedInRoute>
  );
};

export default OrderPage;
