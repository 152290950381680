import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const NoDataItem = ({ title = 'No Data', secondary = '' }) => {
  return (
    <ListItem button>
      <ListItemText primary={title} secondary={secondary} />
    </ListItem>
  );
};

export default NoDataItem;
