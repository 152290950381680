import React from "react";
import PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { NavLink as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  active: {
    "&& .MuiTypography-root": {
      fontWeight: "700",
      color: theme.palette.secondary.light,
    },
    "&& .MuiSvgIcon-root": {
      fill: theme.palette.secondary.light,
    },
  },
}));

function ListItemLink(props) {
  const classes = useStyles();
  const { icon, primary, to, onClick, component, ...other } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink
          activeClassName={classes.active}
          exact
          to={to}
          ref={ref}
          {...itemProps}
        />
      )),
    [classes.active, to]
  );

  return (
    <li>
      {onClick ? (
        <ListItem button onClick={onClick} {...other}>
          {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
          <ListItemText primary={primary} />
        </ListItem>
      ) : (
        <ListItem button component={component || renderLink} {...other}>
          {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
          <ListItemText primary={primary} />
        </ListItem>
      )}
    </li>
  );
}

ListItemLink.propTypes = {
  icon: PropTypes.element,
  primary: PropTypes.string.isRequired,
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onClick: PropTypes.func,
};

export default ListItemLink;
