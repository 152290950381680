import React from "react";
import { I18n } from "aws-amplify";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import { makeStyles } from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { getTotal, toCurrency, getOrderSurchargesAmount } from "../../util";
import useReducer from "../../reducers";
import { Box } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 700,
  },
}));

const OrderSummary = ({ travel = false, delivery = false }) => {
  const classes = useStyles();
  const [
    {
      deliveryReducer: {
        deliveryOrderAmount,
        deliveryOrderTaxes,
        deliveryCostAmount,
        deliveryDiscountAmount,
        orderType,
        tipAmount,
        surchargesArr,
      },
      orderReducer: { orderAmount, orderTaxes, orderDiscountAmount },
    },
  ] = useReducer();

  const deliveryTotal = React.useMemo(
    () => getTotal(deliveryOrderAmount, deliveryOrderTaxes),
    [deliveryOrderAmount, deliveryOrderTaxes]
  );

  const total = React.useMemo(() => getTotal(orderAmount, orderTaxes), [
    orderAmount,
    orderTaxes,
  ]);

  const surchargesAmount = React.useMemo(() => {
    if (surchargesArr && surchargesArr.length) {
      return getOrderSurchargesAmount(surchargesArr);
    }
    return 0;
  }, [surchargesArr]);

  return (
    <Box>
      <TableContainer>
        <Table size="small" aria-label="spanning table">
          <TableBody>
            <TableRow>
              {/* <TableCell rowSpan={6} /> */}
              <TableCell colSpan={2}>{I18n.get("Subtotal")}</TableCell>
              <TableCell align="right">
                $
                {toCurrency(
                  (
                    (delivery || travel ? deliveryOrderAmount : orderAmount) ||
                    0
                  ).toString()
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={3}>{I18n.get("Taxes")}</TableCell>
            </TableRow>
            {delivery || travel
              ? deliveryOrderTaxes && deliveryOrderTaxes.length
                ? deliveryOrderTaxes.map((t, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell></TableCell>
                        <TableCell align="right">{`${t.title} ${
                          t.rate / 100
                        }%`}</TableCell>
                        <TableCell align="right">
                          ${toCurrency((t.amount || 0).toString())}
                        </TableCell>
                      </TableRow>
                    );
                  })
                : null
              : orderTaxes && orderTaxes.length
              ? orderTaxes.map((t, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell></TableCell>
                      <TableCell align="right">{`${t.title} ${
                        t.rate / 100
                      }%`}</TableCell>
                      <TableCell align="right">
                        ${toCurrency((t.amount || 0).toString())}
                      </TableCell>
                    </TableRow>
                  );
                })
              : null}
            {delivery && orderType === "todeliver" ? (
              <TableRow>
                <TableCell colSpan={2}>{I18n.get("Delivery Amount")}</TableCell>

                {deliveryCostAmount ? (
                  <TableCell align="right">
                    ${toCurrency((deliveryCostAmount || 0).toString())}
                  </TableCell>
                ) : (
                  <TableCell align="right">{I18n.get("Free")}</TableCell>
                )}
              </TableRow>
            ) : null}
            {delivery ? (
              <TableRow>
                <TableCell colSpan={2}>{I18n.get("Tip")}</TableCell>
                <TableCell align="right">
                  ${toCurrency((tipAmount || 0).toString())}
                </TableCell>
              </TableRow>
            ) : null}

            {surchargesArr
              ? surchargesArr && surchargesArr.length
                ? surchargesArr.map((s, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell colSpan={2}>{s.title}</TableCell>
                        <TableCell align="right">
                          ${toCurrency((s.amount || 0).toString())}
                        </TableCell>
                      </TableRow>
                    );
                  })
                : null
              : null}
            <TableRow>
              <TableCell colSpan={2}>{I18n.get("Discount")}</TableCell>
              <TableCell align="right">
                {delivery || travel
                  ? `(${toCurrency((deliveryDiscountAmount || 0).toString())})`
                  : `(${toCurrency((orderDiscountAmount || 0).toString())})`}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} className={classes.title}>
                {I18n.get("Total")}
              </TableCell>
              <TableCell align="right" className={classes.title}>
                $
                {toCurrency(
                  (
                    (delivery || travel
                      ? deliveryTotal +
                        +deliveryCostAmount +
                        +tipAmount +
                        surchargesAmount -
                        deliveryDiscountAmount
                      : total - orderDiscountAmount) || 0
                  ).toString()
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default OrderSummary;
