import React from "react";
import { useParams, useHistory } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { API, graphqlOperation } from "aws-amplify";

import * as queries from "../graphql/queries";

const Wait = () => {
  const { slug } = useParams();
  const history = useHistory();
  const [restaurant, setRestaurant] = React.useState();
  console.log("restaurant", restaurant);

  React.useEffect(() => {
    let mounted = true;

    const fetchRestaurant = async () => {
      const { data } = await API.graphql({
        ...graphqlOperation(queries.bySlug, { slug }),
        authMode: "AWS_IAM",
      });

      const { items: [response] = { items: [] } } = (data && data.bySlug) || {};
      if (
        mounted &&
        response &&
        !response._deleted &&
        response.status !== "INACTIVE"
      ) {
        setRestaurant(response);
      } else {
        history.goBack();
      }
    };

    if (slug) {
      fetchRestaurant();
    }

    return () => {
      mounted = false;
    };
  });

  return (
    <>
      <Typography>Wait Line</Typography>
      <Typography>{slug}</Typography>
    </>
  );
};

export default Wait;
