import React from "react";
import { I18n } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
// import Slide from "@material-ui/core/Slide";
import { FiscalDataFormComponent } from "./FiscalDataForm";
import { Container } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  textField: {
    margin: theme.spacing(1, 0),
  },
  button: {
    marginBottom: theme.spacing(1),
  },
}));

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const AddRfcDialog = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenForm = (value) => () => {
    setOpen(value);
  };

  return (
    <>
      <Button
        className={classes.button}
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
        startIcon={<AddCircleIcon />}
      >
        {I18n.get("New RFC")}
      </Button>
      <Dialog
        container={() => document.getElementById("mainBox")}
        fullScreen
        open={open}
        onClose={handleClose}
        // TransitionComponent={Transition}
        style={{
          position: "absolute",
        }}
        BackdropProps={{
          style: {
            position: "absolute",
          },
        }}
      >
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {I18n.get("Add RFC")}
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              {I18n.get("save")}
            </Button>
          </Toolbar>
        </AppBar>
        <Container style={{ marginTop: 16 }}>
          <FiscalDataFormComponent handleOpenForm={handleOpenForm} />
        </Container>
      </Dialog>
    </>
  );
};

export default AddRfcDialog;
