import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";

import ListItemLink from "./ListItemLink";

const useStyles = makeStyles((theme) => ({
  section: {
    paddingTop: theme.spacing(1),
    display: "flex",
    flexWrap: "wrap",
  },
  list: {
    flex: 1,
  },
  divider: {
    marginTop: theme.spacing(1),
  },
}));

const SecondarySectionList = ({ links = [] }) => {
  const classes = useStyles();
  return (
    <>
      <Divider light className={classes.divider} />
      <Box className={classes.section}>
        <List
          component="nav"
          aria-label="mobile nav secondary section"
          className={classes.list}
          disablePadding
        >
          {links.map((link, index) => {
            return (
              <ListItemLink
                key={index}
                to={link.route}
                onClick={link.onClick}
                primary={link.title}
                icon={link.icon}
                component={link.component}
                href={link.href}
                target={link.target}
              />
            );
          })}
        </List>
      </Box>
    </>
  );
};

SecondarySectionList.propTypes = {
  links: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.arrayOf(PropTypes.object),
  ]),
};

export default SecondarySectionList;
