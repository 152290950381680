import React from "react";
import _uniqBy from "lodash/uniqBy";
import { Typography, Box } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Radio from "@material-ui/core/Radio";

import QtyComponent from "./QtyComponent";
import { isEmptyObject } from "../../util";

function updater(arr, newItem, modifier, sum) {
  //   console.log("updater", arr, newItem, modifier, sum);

  var foundIndex = arr.findIndex((x) => x.id === newItem.id);

  if (
    modifier.minSelection === 0 &&
    newItem.qty === 1 &&
    sum === 1 &&
    foundIndex !== -1
  ) {
    arr.splice(0, 1);
    return [...arr];
  }

  const singleSelect =
    modifier.minSelection >= 0 && modifier.maxSelection === 1;
  if (singleSelect && sum === modifier.maxSelection) {
    arr.splice(0, 1);
    return _uniqBy([...arr, newItem], "id");
  }

  // console.log("foundIndex", foundIndex);
  // buscar el elemento
  if (foundIndex !== -1) {
    console.log("newItemQty", newItem.qty);
    if (newItem.qty) {
      // remplazar
      arr.splice(foundIndex, 1, newItem);
    } else {
      // quitar
      arr.splice(foundIndex, 1);
    }
    console.log("arr", arr);
    return _uniqBy([...arr], "id");
  }
  // agregar
  return _uniqBy([...arr, newItem], "id");
}

const OptionControl = ({
  option,
  handleChange,
  selectedOptions,
  modifier,
  sum,
}) => {
  const singleSelect = modifier.minSelection > 0 && modifier.maxSelection === 1;
  const Component = singleSelect ? Radio : Checkbox;

  const selectedOpt = selectedOptions.find((x) => x.id === option.id) || {};
  const selected = !isEmptyObject(selectedOpt);
  //   console.log("option", option);
  //   console.log("selectedOptions", selectedOptions);
  //   console.log("selected", selected);
  //   console.log("selectedOpt", selectedOpt);

  const handleRemoveQty = () => {
    handleChange(
      modifier,
      updater(
        selectedOptions,
        { ...selectedOpt, qty: (selectedOpt.qty || 0) - 1 },
        modifier,
        sum
      )
    );
  };

  const handleAddQty = () => {
    console.log("handleAdd", modifier.maxSelection, sum);
    if (modifier.maxSelection === sum) {
      return;
    }

    handleChange(
      modifier,
      updater(
        selectedOptions,
        !selectedOpt.qty
          ? { ...option, qty: 1 }
          : { ...selectedOpt, qty: (selectedOpt.qty || 0) + 1 },
        modifier,
        sum
      )
    );
  };

  return (
    <Box display="flex" alignItems="center">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        flex={1}
      >
        <Typography variant="body2" color="textSecondary">
          {option.title}
        </Typography>
        {option.upcharge ? (
          <Typography variant="body2" style={{ marginLeft: 4, marginRight: 4 }}>
            + ${option.upcharge / 100}
          </Typography>
        ) : null}
      </Box>
      {singleSelect ||
      (modifier.minSelection === 0 && modifier.maxSelection === 1) ? (
        <Component
          checked={selected}
          onChange={(event) => {
            event.stopPropagation();
            handleChange(
              modifier,
              updater(selectedOptions, { ...option, qty: 1 }, modifier, sum)
            );
          }}
          value={option.id}
        />
      ) : (
        <QtyComponent
          isDelete={!selectedOpt.qty || selectedOpt.qty <= 1}
          small
          value={selectedOpt.qty || 0}
          minQuantity={modifier.minSelection}
          maxQuantity={modifier.maxSelection}
          handleAddQty={handleAddQty}
          handleRemoveQty={handleRemoveQty}
          sum={sum}
          isModifierOption
        />
      )}
    </Box>
  );
};

export default OptionControl;
