import React from "react";
import Img from "react-image";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import { useHistory } from "react-router-dom";
import VisibilitySensor from "react-visibility-sensor";
import Skeleton from "@material-ui/lab/Skeleton";

import Photo from "../Photo";
import useStyles from "./GridCard.styled";

const GridCard = ({ tile, style, clean = false, ...other }) => {
  const classes = useStyles();
  const history = useHistory();

  const [tileHeight, setTileHeight] = React.useState(0);
  const tileRef = React.useRef(null);

  React.useEffect(() => {
    if (tileRef && tileRef.current) {
      setTileHeight(tileRef.current.offsetWidth);
    }
  }, []);

  const handleClickTile = (to) => () => {
    if (to) {
      history.push(to);
    }
  };

  const parsedStyle = React.useMemo(() => {
    const s = { ...style };
    if (tileHeight) {
      s["height"] = tileHeight;
    }

    if (tile.featured) {
      s["width"] = "100%";
      s["height"] = tileHeight / 2;
    }
    return s;
  }, [style, tile.featured, tileHeight]);

  return (
    <GridListTile
      cols={tile.featured ? 2 : 1}
      // rows={tile.featured ? 2 : 1}
      onClick={handleClickTile(tile.route)}
      classes={{ tile: classes.gridListTile }}
      {...other}
      ref={tileRef}
      style={parsedStyle}
    >
      <VisibilitySensor>
        <Img
          src={[tile.img]}
          alt={tile.title}
          loader={
            <Skeleton
              variant="rect"
              animation="wave"
              width={"100%"}
              height={"100%"}
            />
          }
          unloader={<Photo />}
          className={classes.image}
        />
      </VisibilitySensor>

      {!clean ? (
        <GridListTileBar
          title={tile.title}
          subtitle={tile.subtitle}
          titlePosition="top"
          className={classes.titleBar}
          classes={{ subtitle: classes.lineClamp }}
        />
      ) : null}
    </GridListTile>
  );
};

export default GridCard;
