import moment from "moment";
import _groupBy from "lodash/groupBy";
import _compact from "lodash/compact";
import { I18n, Storage } from "aws-amplify";
import { isValidNumber } from "libphonenumber-js";
import { isSafari, isMobileSafari, isIOS, isChrome } from "react-device-detect";
import getDistance from "geolib/es/getDistance";

export const updater = (arr = [], newItem, idField = "id", remove = false) => {
  if (!newItem) {
    return [...arr];
  }

  var foundIndex = arr.findIndex((x) => x[idField] === newItem[idField]);

  if (foundIndex !== -1) {
    if (remove) {
      arr.splice(foundIndex, 1);
      return arr;
    } else {
      return arr.map((item) =>
        item[idField] === newItem[idField] ? newItem : item
      );
    }
  } else {
    return [
      ...arr.filter((item) => item[idField] !== newItem[idField]),
      newItem,
    ];
  }
};

export const getDigitsFromValue = (value = "") =>
  value.replace(/(-(?!\d))|[^0-9|-]/g, "") || "";

const padDigits = (digits) => {
  const desiredLength = 3;
  const actualLength = digits.length;

  if (actualLength >= desiredLength) {
    return digits;
  }

  const amountToAdd = desiredLength - actualLength;
  const padding = "0".repeat(amountToAdd);

  return padding + digits;
};

const removeLeadingZeros = (number) => number.replace(/^0+([0-9]+)/, "$1");

const numberWithThousandSeparator = (number, thousandSeparator) => {
  const digits = number.split("");
  // add in any thousand separators
  for (let x = digits.length - 3; x > 0; x = x - 3) {
    digits.splice(x, 0, thousandSeparator);
  }
  return digits.join("");
};

const addDecimalToNumber = (number, separator, thousandSeparator) => {
  const centsStartingPosition = number.length - 2;
  let dollars = removeLeadingZeros(number.substring(0, centsStartingPosition));
  dollars = numberWithThousandSeparator(dollars, thousandSeparator);
  const cents = number.substring(centsStartingPosition);
  return dollars + separator + cents;
};

export const toCurrency = (value, separator = ".", thousandSeparator = ",") => {
  const digits = getDigitsFromValue(value);
  const digitsWithPadding = padDigits(digits);

  return addDecimalToNumber(digitsWithPadding, separator, thousandSeparator);
};

export const getTipCurrency = (value, orderAmount, parsedTips) => {
  // console.log("parsedTips", parsedTips);
  if (parsedTips && value < 3) {
    // console.log("calc", (Object.values(parsedTips)?.[value] || 0) / 10000);
    return toCurrency(
      (
        orderAmount *
        ((Object.values(parsedTips)?.[value] || 0) / 10000)
      ).toFixed(0)
    );
  }

  switch (value) {
    case 0:
      return toCurrency((orderAmount * 0.1).toFixed(0));
    case 1:
      return toCurrency((orderAmount * 0.15).toFixed(0));
    case 2:
      return toCurrency((orderAmount * 0.2).toFixed(0));
    default:
      return "";
  }
};

export const getTotal = (amount, taxes) => {
  return taxes && taxes.length
    ? taxes.reduce((sum, val) => {
        return sum + val.amount;
      }, amount)
    : amount;
};

export const getRemainingAmount = (
  total,
  paid,
  discount,
  deliveryAmount = 0
) => {
  const realTotal = total + (deliveryAmount || 0) - discount;
  return realTotal - paid > 0 ? realTotal - paid : 0;
};

const jsonparse = (stringifiedObject) => {
  if (typeof stringifiedObject === "string") {
    const newObj = JSON.parse(stringifiedObject);
    return jsonparse(newObj);
  } else {
    return stringifiedObject;
  }
};

export const JSONParse = jsonparse;

export const getModifiers = (modifiers) => {
  return modifiers ? jsonparse(modifiers) : [];
};

export const getAmount = (i, isDelivery) => {
  console.log("getAmount isDelivery", isDelivery);
  const statuses = ["CANCELLED"];

  if (!isDelivery) {
    statuses.push("PENDING");
  }

  console.log("statuses", statuses);

  return i && i.length
    ? i.reduce((sum, x) => {
        return (
          sum +
          (statuses.includes(x.status)
            ? 0
            : (x.qty || 1) * (x.price + getModifiersAmount(x.modifiers)))
        );
      }, 0)
    : 0;
};

export const getDiscountAmount = (i) => {
  return i && i.length
    ? i.reduce((sum, x) => {
        return sum + (x.status === "CANCELLED" ? 0 : (x.qty || 1) * x.price);
      }, 0)
    : 0;
};

export const getAmountPaid = (i) => {
  return i && i.length
    ? i.reduce((sum, x) => {
        return (
          sum + (x.status === "succeeded" ? x.amount - (x.tipAmount || 0) : 0)
        );
      }, 0)
    : 0;
};

export const getTipAmount = (i) => {
  return i && i.length ? i.reduce((sum, x) => sum + (x.tipAmount || 0)) : 0;
};

export const getTaxes = (taxes, amount) => {
  return taxes && taxes.length
    ? taxes.map((t) => ({
        title: t.title,
        rate: t.rate,
        base: amount,
        amount: +(amount * (t.rate / 10000)).toFixed(0),
      }))
    : 0;
};

export const getTaxesAmount = (items = []) => {
  let taxesbyrest = [];
  items.forEach((x) => {
    const parsedTaxes = x.taxes ? JSON.parse(x.taxes) : [];
    taxesbyrest = [...(taxesbyrest || []), ...parsedTaxes];
  });

  taxesbyrest = Object.values(_groupBy(taxesbyrest, (x) => x.title));
  let taxes = [];
  taxesbyrest.forEach((p) => {
    const red = p.reduce(
      (sum, val) => ({
        ...val,
        base: (sum.base || 0) + val.base,
        amount: (sum.amount || 0) + val.amount,
      }),
      {}
    );
    taxes = [...taxes, red];
  });
  taxesbyrest = taxes;

  return taxesbyrest;
};

export const subtractTaxes = (price = 0, taxes) => {
  return +(taxes && taxes.length
    ? taxes.reduce((sum, t) => {
        return sum + price / (1 + t.rate / 10000);
      }, 0)
    : price
  ).toFixed(0);
};

export const removePriceTaxes = (item, taxes) => {
  let priceNoTaxes = 0;
  if (taxes && taxes.length) {
    priceNoTaxes = subtractTaxes(item.price, taxes);
  }

  return +priceNoTaxes.toFixed(0);
};

export const removeModifiersTaxes = (item, taxes) => {
  const parsedModifiers = getModifiers(item.modifiers);
  // console.log("parsedModifiers", parsedModifiers);

  const result = parsedModifiers.map((modifier) => {
    return {
      ...modifier,
      upcharge: subtractTaxes(modifier.upcharge, taxes),
      options:
        modifier &&
        modifier.options &&
        modifier.options.map((option) => ({
          ...option,
          upcharge: subtractTaxes(option.upcharge, taxes),
        })),
    };
  });

  return result;
};

export const getModifiersAmount = (modifiers) => {
  const parsedModifiers = getModifiers(modifiers);

  return parsedModifiers.length
    ? parsedModifiers.reduce(
        (s, k) =>
          s +
          (k.upcharge +
            (k.options && k.options.length
              ? k.options.reduce((t, v) => t + v.upcharge * (v.qty || 1), 0)
              : 0)),
        0
      )
    : 0;
};

export const restoreModifiersPrice = (
  modifiersToRestore,
  originalModifiers
) => {
  console.log("restoreModifiersPrice");
  const parsedModifiers = getModifiers(modifiersToRestore);
  console.log("parsedModifiers", JSON.stringify(parsedModifiers, null, 2));
  const parsedOriginalModifiers = getModifiers(originalModifiers);
  console.log(
    "parsedOriginalModifiers",
    JSON.stringify(parsedOriginalModifiers, null, 2)
  );

  const result = parsedModifiers.map((modifier) => {
    const originalModifier = parsedOriginalModifiers.find(
      (x) => x.id === modifier.id
    );
    console.log("originalModifier", originalModifier);
    if (!originalModifier) {
      return { ...modifier };
    }

    return {
      ...modifier,
      upcharge: originalModifier.upcharge,
      options: modifier.options.map((option) => {
        const originalOption = originalModifier.optionsConnection.items.find(
          (x) => x.id === option.id
        );
        return {
          ...option,
          upcharge: originalOption.upcharge,
        };
      }),
    };
  });

  return result;
};

export const getItemAmount = (p) => {
  return p ? (p.qty || 1) * (p.price + getModifiersAmount(p.modifiers)) : 0;
};

export const getFileUrl = ({ key, level = "public" }) => {
  return new Promise((resolve, reject) => {
    Storage.get(key, {
      level: "public",
      download: true,
      customPrefix: {
        public: "protected/",
      },
    })
      .then((url) => {
        resolve(url);
      })
      .catch((err) => reject(err));
  });
};

export const getPictureUrl = ({ key, level = "public", size = "300x300" }) => {
  if (key) {
    const parsedKeyArr = key.split("/");
    parsedKeyArr.splice(parsedKeyArr.length - 1, 0, size);
    const parsedKey = parsedKeyArr.join("/");
    return `${process.env.REACT_APP_CLOUDFRONT}/${level}/${parsedKey}`;
  }
  return null;
};

export const cleanObject = (obj) => {
  for (var propName in obj) {
    if (
      obj[propName] === "" ||
      obj[propName] === null ||
      obj[propName] === undefined
    ) {
      delete obj[propName];
    }
  }
};

export const clearEmpties = (o) => {
  for (var k in o) {
    if (!o[k] || typeof o[k] !== "object") {
      continue; // If null or not an object, skip to the next iteration
    }

    // The property is an object
    clearEmpties(o[k]); // <-- Make a recursive call on the nested object
    if (Object.keys(o[k]).length === 0) {
      delete o[k]; // The object had no properties, so delete that property
    }
  }
};

export const clean = (obj) => {
  for (var propName in obj) {
    if (
      obj[propName] === "" ||
      obj[propName] === null ||
      obj[propName] === undefined
    ) {
      delete obj[propName];
    } else if (obj[propName] && typeof obj[propName] === "object") {
      clean(obj[propName]);
    }
  }
};

export const isEmptyObject = (obj) => {
  return obj && Object.keys(obj).length === 0;
};

export const getSelectedModifiersString = (modifiers, delivery = false) => {
  if (modifiers) {
    let str = "";
    for (const iterator of JSONParse(modifiers)) {
      str += ` ${iterator.title}:`;
      const optionsStr = iterator.options.map((x) => x.title).join(", ");
      str += ` ${optionsStr}.`;
    }

    return str;
  }

  return !delivery ? I18n.get("No modifiers selected") : null;
};

export const getGuestPaid = (payments, guestId) => {
  let isPaid = false;
  if (payments && payments.length) {
    isPaid = payments.reduce((acum, val) => acum || val.id === guestId, isPaid);

    for (const payment of payments) {
      const parsedGuests = payment.guest ? JSONParse(payment.guest) : [];
      isPaid = parsedGuests.reduce(
        (acum, val) => acum || val.id === guestId,
        isPaid
      );
    }
  }

  return isPaid;
};

export const getCardData = (paymentData) => {
  const parsedPaymentData = JSONParse(paymentData) || {};
  const { payment_method, payment_intent = {} } = parsedPaymentData;
  const card =
    (payment_intent &&
      payment_intent.charges &&
      payment_intent.charges.data &&
      payment_intent.charges.data.length &&
      payment_intent.charges.data[0].payment_method_details &&
      payment_intent.charges.data[0].payment_method_details.card) ||
    {};

  if (
    payment_intent &&
    payment_intent.payment_method_types &&
    payment_intent.payment_method_types.includes("oxxo")
  ) {
    return "OXXO Pay";
  }

  if (card && !isEmptyObject(card)) {
    return `${card.brand} ${card.last4}`;
  }

  if (payment_method === "cash") {
    return "cash";
  } else if (payment_method === "paypal") {
    return "PayPal";
  }
  return "other";
};

export const _sort = (
  arr,
  { prop, direction } = { prop: "title", direction: "ASC" }
) => {
  arr.sort((a, b) => {
    if (a[prop] < b[prop]) {
      return direction === "ASC" ? -1 : 1;
    }
    if (b[prop] < a[prop]) {
      return direction === "ASC" ? 1 : -1;
    }
    return 0;
  });
};

const equals = (x, y) => {
  if (x === y) return true;
  // if both x and y are null or undefined and exactly the same

  if (!(x instanceof Object) || !(y instanceof Object)) return false;
  // if they are not strictly equal, they both need to be Objects

  if (x.constructor !== y.constructor) return false;
  // they must have the exact same prototype chain, the closest we can do is
  // test there constructor.

  for (var p in x) {
    if (!x.hasOwnProperty(p)) continue;
    // other properties were tested using x.constructor === y.constructor

    if (!y.hasOwnProperty(p)) return false;
    // allows to compare x[ p ] and y[ p ] when set to undefined

    if (x[p] === y[p]) continue;
    // if they have the same strict value or identity then they are equal

    if (typeof x[p] !== "object") return false;
    // Numbers, Strings, Functions, Booleans must be strictly equal

    if (!equals(x[p], y[p])) return false;
    // Objects and Arrays must be tested recursively
  }

  for (p in y) {
    if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
    // allows x[ p ] to be set to undefined
  }
  return true;
};

export const handleGetGroupedItems = (array) => {
  const result = array.reduce(function (res, value) {
    const itemId = value.productID;

    if (!res[itemId]) {
      res[itemId] = { ...value };
    } else {
      if (
        equals(JSONParse(res[itemId].modifiers), JSONParse(value.modifiers))
      ) {
        res[itemId].qty += value.qty || 1;
      } else {
        const ocurrencies = Object.keys(res).filter(
          (x) => x.split("_")[0] === itemId
        );

        for (let idx = 0; idx < ocurrencies.length; idx++) {
          const element = ocurrencies[idx];
          if (
            equals(
              JSONParse(res[element].modifiers),
              JSONParse(value.modifiers)
            )
          ) {
            res[itemId].qty += value.qty || 1;
          } else {
            if (idx === ocurrencies.length - 1) {
              res[`${element}_${ocurrencies.length}`] = { ...value };
            }
          }
        }
      }
    }

    return res;
  }, {});

  return Object.values(result);
};

export const cfdiUses = [
  { key: "G01", value: "Adquisición de mercancias" },
  { key: "G03", value: "Gastos en general" },
  { key: "P01", value: "Por definir" },
];

export const rfcValido = (rfc, aceptarGenerico = true) => {
  const re = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
  var validado = rfc.match(re);

  if (!validado)
    //Coincide con el formato general del regex?
    return false;

  //Separar el dígito verificador del resto del RFC
  const digitoVerificador = validado.pop(),
    rfcSinDigito = validado.slice(1).join(""),
    len = rfcSinDigito.length,
    //Obtener el digito esperado
    diccionario = "0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ",
    indice = len + 1;
  var suma, digitoEsperado;

  if (+len === 12) suma = 0;
  else suma = 481; //Ajuste para persona moral

  for (var i = 0; i < len; i++)
    suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i);
  digitoEsperado = 11 - (suma % 11);
  if (+digitoEsperado === 11) digitoEsperado = 0;
  else if (+digitoEsperado === 10) digitoEsperado = "A";

  //El dígito verificador coincide con el esperado?
  // o es un RFC Genérico (ventas a público general)?
  if (
    +digitoVerificador !== +digitoEsperado &&
    (!aceptarGenerico || rfcSinDigito + digitoVerificador !== "XAXX010101000")
  )
    return false;
  else if (
    !aceptarGenerico &&
    rfcSinDigito + digitoVerificador === "XEXX010101000"
  )
    return false;
  return rfcSinDigito + digitoVerificador;
};

export const isSafariBrowser =
  isSafari || isMobileSafari || (isIOS && !isChrome);

export const handleAvailability = (prd) => {
  moment.locale("en");
  var m1 = moment();
  m1.locale(); // en
  const currDay = m1.format("dddd");

  if (!(prd && prd.schedule)) {
    return true;
  }

  const parsedSchedule = prd && prd.schedule ? JSONParse(prd.schedule) : {};
  const currentSchedule = parsedSchedule[currDay];
  const hasRange = !!(
    currentSchedule &&
    currentSchedule.range &&
    currentSchedule.range.length
  );
  const openHour = hasRange
    ? currentSchedule.range[0]
      ? parseInt(currentSchedule.range[0].replace(":", ""))
      : 0
    : 0;
  const closeHour = hasRange
    ? currentSchedule.range[1]
      ? parseInt(currentSchedule.range[1].replace(":", ""))
      : 0
    : 0;
  const currentHour = parseInt(moment().format("HHmm"));

  const isNextDayClose = closeHour < openHour;
  // console.log("isNextDayClose", isNextDayClose);
  const isOpen =
    (openHour === 0 && closeHour === 0) ||
    (!isNextDayClose
      ? currentHour > openHour && currentHour < closeHour
      : (currentHour > openHour && currentHour < 2359) ||
        (currentHour > 0 && currentHour < closeHour));
  return (!hasRange || isOpen) && !(currentSchedule && currentSchedule?.closed);
};

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const phoneValidation = (phone_number, country = "MX") => {
  return isValidNumber(phone_number, country);
};

export const getDeliveryPrice = ({
  deliveryPrice, // restaurant delivery price
  deliveryType, // restaurant delivery type
  location, // restaurant location
  deliveryAddress,
}) => {
  // console.log("deliveryType", deliveryType);
  // console.log("location", location);
  // console.log("deliveryAddress", deliveryAddress);
  const parsedPrice = deliveryPrice ? JSONParse(deliveryPrice) : {};

  if (deliveryType) {
    switch (deliveryType) {
      case "FIXED":
        return +parsedPrice.amount;
      case "DISTANCE":
        let distance;

        if (
          deliveryAddress &&
          !isEmptyObject(deliveryAddress) &&
          location &&
          !isEmptyObject(location)
        ) {
          const latitude =
            deliveryAddress &&
            deliveryAddress.geometry &&
            deliveryAddress.geometry.location &&
            deliveryAddress.geometry.location.lat;
          const longitude =
            deliveryAddress &&
            deliveryAddress.geometry &&
            deliveryAddress.geometry.location &&
            deliveryAddress.geometry.location.lng;

          distance = getDistance(
            {
              latitude: location.lat,
              longitude: location.lon,
            },
            {
              latitude,
              longitude,
            }
          );
        }

        const deliveryDistances = Object.keys(parsedPrice) || [];
        const matchedDistance =
          deliveryDistances.find((x) => distance <= +x) ||
          deliveryDistances[deliveryDistances.length - 1];

        return +parsedPrice[matchedDistance];
      default:
        return 0;
    }
  } else {
    return 0;
  }
};

// check if restaurant schedule is open
export const handleRestaurantIsEnabled = (schedule) => {
  if (!schedule) {
    return { restaurantIsEnabled: true };
  }

  const parsedSchedule = schedule ? JSONParse(schedule) : {};
  // console.log("schedule", parsedSchedule);
  moment.locale("en");
  var m1 = moment();
  m1.locale(); // en
  const currDay = m1.format("dddd");
  const currentSchedule = parsedSchedule[currDay];
  // console.log("currentSchedule", currentSchedule);
  const hasRange = !!(
    currentSchedule &&
    currentSchedule.range &&
    currentSchedule.range.length
  );
  const openHour = hasRange
    ? currentSchedule.range[0]
      ? parseInt(currentSchedule.range[0].replace(":", ""))
      : 0
    : 0;
  const closeHour = hasRange
    ? currentSchedule.range[1]
      ? parseInt(currentSchedule.range[1].replace(":", ""))
      : 0
    : 0;
  const currentHour = parseInt(moment().format("HHmm"));

  const isNextDayClose = closeHour < openHour;
  // console.log("isNextDayClose", isNextDayClose);
  const isOpen =
    (openHour === 0 && closeHour === 0) ||
    (!isNextDayClose
      ? currentHour > openHour && currentHour < closeHour
      : (currentHour > openHour && currentHour < 2359) ||
        (currentHour > 0 && currentHour < closeHour));
  const restaurantIsEnabled =
    (!hasRange || isOpen) && !(currentSchedule && currentSchedule?.closed);
  // console.log("restaurantIsEnabled", restaurantIsEnabled);
  return { restaurantIsEnabled, currentSchedule, currDay, isNextDayClose };
};

export const handleProductPrice = (prod, delivery) => {
  if (delivery) {
    if (
      (prod.deliveryEnabled && prod.deliveryExtraAmount) ||
      (prod.preorderingEnabled && prod.preorderingExtraAmount)
    ) {
      return prod.price + prod.deliveryExtraAmount;
    } else {
      return (prod && prod.price) || 0;
    }
  }

  return (prod && prod.price) || 0;
};

export const handleProductPriceTaxes = (input, selectedRestaurant) => {
  const restTaxes =
    selectedRestaurant && selectedRestaurant.taxes
      ? JSONParse(selectedRestaurant.taxes)
      : [];

  if (selectedRestaurant.taxesIncluded) {
    // remove taxes from price (newprice)
    const priceNoTaxes = removePriceTaxes(input, restTaxes);
    // remove taxes from modifiers
    const modifiersNoTaxes = removeModifiersTaxes(input, restTaxes);
    // calculate amount
    const itemAmount = getItemAmount({
      ...input,
      price: priceNoTaxes,
      modifiers: modifiersNoTaxes,
    });
    // getTaxes
    const taxesArr = getTaxes(restTaxes, itemAmount);

    input.price = priceNoTaxes;

    if (modifiersNoTaxes && modifiersNoTaxes.length) {
      input.modifiers = JSON.stringify(modifiersNoTaxes);
    }

    input.taxes = taxesArr && taxesArr.length ? JSON.stringify(taxesArr) : null;
    input.amount = itemAmount;
  } else {
    // regular process
    const itemAmount = getItemAmount(input);
    const taxesArr = getTaxes(restTaxes, itemAmount);

    input.taxes = taxesArr && taxesArr.length ? JSON.stringify(taxesArr) : null;
    input.amount = itemAmount;
  }

  return input;
};

export const getItemTotal = (orderItem) => {
  const statuses = ["CANCELLED", "DELETED"];
  const parsedTaxes = orderItem.taxes && JSONParse(orderItem.taxes);
  const taxesAmount = +(parsedTaxes && parsedTaxes.length
    ? parsedTaxes.reduce((sum, val) => {
        return sum + val.amount;
      }, 0)
    : 0
  ).toFixed(0);

  return statuses.includes(orderItem.status) || orderItem._deleted
    ? 0
    : orderItem.amount + taxesAmount;
};

export const getOrderTotal = (orderItems, isDelivery) => {
  console.log("getOrderTotal isDelivery", isDelivery);
  const statuses = ["CANCELLED", "DELETED"];

  // if (!isDelivery) {
  //   statuses.push("PENDING");
  // }

  console.log("statuses", statuses);

  if (orderItems && orderItems.length) {
    return orderItems.reduce((sum, val) => {
      const parsedTaxes = val.taxes && JSONParse(val.taxes);
      const taxesAmount = +(parsedTaxes && parsedTaxes.length
        ? parsedTaxes.reduce((sum, val) => {
            return sum + val.amount;
          }, 0)
        : 0
      ).toFixed(0);

      return (
        sum +
        (statuses.includes(val.status) || val._deleted
          ? 0
          : val.amount + taxesAmount)
      );
    }, 0);
  }
  return 0;
};

export const dialCodes = [
  "+1",
  "+7",
  "+20",
  "+27",
  "+30",
  "+31",
  "+32",
  "+33",
  "+34",
  "+36",
  "+39",
  "+40",
  "+41",
  "+43",
  "+44",
  "+45",
  "+46",
  "+47",
  "+48",
  "+49",
  "+51",
  "+52",
  "+53",
  "+54",
  "+55",
  "+56",
  "+57",
  "+58",
  "+60",
  "+61",
  "+62",
  "+63",
  "+64",
  "+65",
  "+66",
  "+81",
  "+82",
  "+84",
  "+86",
  "+90",
  "+91",
  "+92",
  "+93",
  "+94",
  "+95",
  "+98",
  "+212",
  "+213",
  "+216",
  "+218",
  "+220",
  "+221",
  "+222",
  "+223",
  "+224",
  "+225",
  "+226",
  "+227",
  "+228",
  "+229",
  "+230",
  "+231",
  "+232",
  "+233",
  "+234",
  "+235",
  "+236",
  "+237",
  "+238",
  "+239",
  "+240",
  "+241",
  "+242",
  "+243",
  "+244",
  "+245",
  "+246",
  "+248",
  "+249",
  "+250",
  "+251",
  "+252",
  "+253",
  "+254",
  "+255",
  "+256",
  "+257",
  "+258",
  "+260",
  "+261",
  "+262",
  "+263",
  "+264",
  "+265",
  "+266",
  "+267",
  "+268",
  "+269",
  "+290",
  "+291",
  "+297",
  "+298",
  "+299",
  "+345",
  "+350",
  "+351",
  "+352",
  "+353",
  "+354",
  "+355",
  "+356",
  "+357",
  "+358",
  "+359",
  "+370",
  "+371",
  "+372",
  "+373",
  "+374",
  "+375",
  "+376",
  "+377",
  "+378",
  "+379",
  "+380",
  "+381",
  "+382",
  "+385",
  "+386",
  "+387",
  "+389",
  "+420",
  "+421",
  "+423",
  "+500",
  "+501",
  "+502",
  "+503",
  "+504",
  "+505",
  "+506",
  "+507",
  "+508",
  "+509",
  "+537",
  "+590",
  "+591",
  "+593",
  "+594",
  "+595",
  "+596",
  "+597",
  "+598",
  "+599",
  "+670",
  "+672",
  "+673",
  "+674",
  "+675",
  "+676",
  "+677",
  "+678",
  "+679",
  "+680",
  "+681",
  "+682",
  "+683",
  "+685",
  "+686",
  "+687",
  "+688",
  "+689",
  "+690",
  "+691",
  "+692",
  "+850",
  "+852",
  "+853",
  "+855",
  "+856",
  "+872",
  "+880",
  "+886",
  "+960",
  "+961",
  "+962",
  "+963",
  "+964",
  "+965",
  "+966",
  "+967",
  "+968",
  "+970",
  "+971",
  "+972",
  "+973",
  "+974",
  "+975",
  "+976",
  "+977",
  "+992",
  "+993",
  "+994",
  "+995",
  "+996",
  "+998",
];

export const language = (
  window.navigator.userLanguage ||
  window.navigator.language ||
  "en"
).split("-")?.[0];

export function getCorrectTextColor(hex) {
  let threshold = 130; /* about half of 256. Lower threshold equals more dark text on dark background  */

  let hRed = hexToR(hex);
  let hGreen = hexToG(hex);
  let hBlue = hexToB(hex);

  function hexToR(h) {
    return parseInt(cutHex(h).substring(0, 2), 16);
  }
  function hexToG(h) {
    return parseInt(cutHex(h).substring(2, 4), 16);
  }
  function hexToB(h) {
    return parseInt(cutHex(h).substring(4, 6), 16);
  }
  function cutHex(h) {
    // eslint-disable-next-line eqeqeq
    return h.charAt(0) == "#" ? h.substring(1, 7) : h;
  }

  let cBrightness = (hRed * 299 + hGreen * 587 + hBlue * 114) / 1000;
  if (cBrightness > threshold) {
    return "#000000";
  } else {
    return "#ffffff";
  }
}

export const getSelectedOrderTypeLabel = (orderType) => {
  switch (orderType) {
    case "togo":
      return "Pick & Go (Recoger en el establecimiento)";
    case "dinein":
      return "Dine In";
    default:
      return "Delivery";
  }
};

export const handleOrderSurcharges = (orderType, surcharges, amount) => {
  const parsedSurcharges = surcharges ? JSONParse(surcharges) : [];
  return parsedSurcharges && parsedSurcharges.length
    ? _compact(
        parsedSurcharges.map((s) => {
          if (!s.orderType.includes(orderType)) {
            return null;
          }

          const result =
            s.type === "currency"
              ? +s.amount
              : +(amount * (s.amount / 10000)).toFixed(0);
          return {
            title: s.title,
            amount: result,
          };
        })
      )
    : [];
};

export const getOrderSurchargesAmount = (surcharges) => {
  const parsedSurcharges = surcharges ? JSONParse(surcharges) : [];
  return parsedSurcharges && parsedSurcharges.length
    ? parsedSurcharges.reduce((sum, x) => {
        return sum + (x.amount || 0);
      }, 0)
    : 0;
};

export const filterOrderItems = (items) => {
  return items && items.length
    ? items.filter(
        (x) =>
          !x._deleted && !["DISCOUNT", "COUPON", "REFUND"].includes(x.itemType)
      )
    : [];
};

export const getDiscountItems = (items) => {
  return items && items.length
    ? items.filter(
        (c) =>
          !c._deleted &&
          c.status !== "CANCELLED" &&
          ["DISCOUNT", "COUPON", "REFUND"].includes(c.itemType)
      )
    : [];
};

export const handleTranslation = (
  value,
  i18n,
  field = "title",
  valueLang = "es"
) => {
  const parsedi18n = i18n ? JSONParse(i18n) : {};
  const targetLanguage = language;

  if (valueLang === targetLanguage) {
    return value;
  }

  const targetTranslation = parsedi18n?.[targetLanguage];
  if (targetTranslation) {
    return targetTranslation[field] || value || "";
  }

  return value;
};

export const getHostname = () => {
  let hostname = window.location.hostname.toLowerCase();
  hostname = hostname.replace("-staging", "");
  const reserved = ["2dine", "localhost", "amplifyapp"];
  const hostnameSections = hostname.split(".");
  // const first = hostnameSections[0];
  // console.log("location", window.location);
  // console.log("hostname", hostname);

  if (
    reserved.includes(
      hostnameSections.length === 2
        ? hostnameSections[0]
        : hostnameSections.length === 3
        ? hostnameSections[1]
        : hostnameSections[hostnameSections.length - 2]
    )
  ) {
    return null;
  }

  return hostname;
};
