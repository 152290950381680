import React from "react";
// import { uuid } from "uuidv4";
import { v4 as uuid } from "uuid";
import moment from "moment";
import { Cache } from "aws-amplify";
import { I18n } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import { API, graphqlOperation } from "aws-amplify";
import {
  Typography,
  Button,
  Box,
  TextField,
  Collapse,
} from "@material-ui/core";
import { useSnackbar } from "notistack";

import NumericKeyboard from "../NumericKeyboard";
import CurrencyInput from "../CurrencyInput";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import useReducer from "../../reducers";
import {
  toCurrency,
  JSONParse,
  removePriceTaxes,
  removeModifiersTaxes,
  getItemAmount,
  getTaxes,
  getDigitsFromValue,
  cleanObject,
} from "../../util";
import useCheckout from "../../hooks/useCheckout";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  paper: {
    width: "100%",
  },
  media: {
    height: 160,
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "center",
  },
  button: {
    backgroundImage: "linear-gradient(147deg, #fe8a39 0%, #fd3838 74%)",
    boxShadow: "0px 4px 32px rgba(252, 56, 56, 0.4)",
    borderRadius: 100,
    paddingLeft: 24,
    paddingRight: 24,
    color: "#fff",
  },
  ribbon: {
    position: "absolute",
    top: "15px",
    right: 0,
    padding: "8px 10px",
    background: "#ee4035", //#ee4035
    color: "#fff",
    boxShadow: "-1px 2px 3px rgba(0,0,0,.3)",
  },
}));

const BondSelection = ({ item, restaurantId }) => {
  const classes = useStyles();
  const [isCustomAmount, setIsCustomAmount] = React.useState(false);
  const keyboardRef = React.useRef();
  const [
    {
      loginReducer: { currentUser },
      bondReducer: { bondAmount },
    },
    dispatch,
  ] = useReducer();
  const checkout = useCheckout();
  const { enqueueSnackbar } = useSnackbar();

  const handleCustomBondAmount = () => {
    setIsCustomAmount(true);
    dispatch({
      type: "BOND_SET_AMOUNT",
      payload: { bondAmount: "" },
    });
  };

  const setBondAmount = (value) => {
    const numericValue = +getDigitsFromValue(value);
    dispatch({
      type: "BOND_SET_AMOUNT",
      payload: { bondAmount: numericValue },
    });
    keyboardRef.current.setInput(value);
  };

  const onChange = (input) => {
    const currencyValue = toCurrency(input);
    console.log("currencyValue", currencyValue);
    setBondAmount(currencyValue);
  };

  const onKeyPress = (button) => {};

  const handleBondButton = (val) => async () => {
    console.log("val", val);
    setBondAmount(val);
    await Promise.resolve();
    handleCreateOrder(+val);
  };

  const handleCreateOrder = async (amount) => {
    dispatch({ type: "LOADING_START" });

    if (!amount) {
      enqueueSnackbar("Selecciona un bono o ingresa una cantidad", {
        variant: "error",
        autoHideDuration: 3000,
      });
      dispatch({ type: "LOADING_STOP" });
      return;
    }

    if (amount < 35000) {
      enqueueSnackbar("¡El monto mínimo es MX $350, gracias!", {
        variant: "error",
        autoHideDuration: 3000,
      });
      dispatch({ type: "LOADING_STOP" });
      return;
    }

    const now = new Date();
    const orderId = uuid();

    const params = {
      qty: 1,
      productID: "custom_bond",
      restaurantID: restaurantId,
      title: `${item && item.name} - Bono gastronómico`,
      price: amount,
      status: "ORDERED",
      guest: 0,
      addedAt: now.toISOString(),
      sentAt: now.toISOString(),
      tableID: "bond",
      print: 0,
      isPaid: false,
      orderItemOrderId: orderId,
      id: uuid(),
    };

    cleanObject(params);

    console.log("params", params);

    const restTaxes = item && item.taxes ? JSONParse(item.taxes) : [];
    if (item.taxesIncluded) {
      // remove taxes from price (newprice)
      const priceNoTaxes = removePriceTaxes(params, restTaxes);
      // remove taxes from modifiers
      const modifiersNoTaxes = removeModifiersTaxes(params, restTaxes);
      // calculate amount
      const itemAmount = getItemAmount({
        ...params,
        price: priceNoTaxes,
        modifiers: modifiersNoTaxes,
      });
      // getTaxes
      const taxesArr = getTaxes(restTaxes, itemAmount);

      params.price = priceNoTaxes;

      if (modifiersNoTaxes && modifiersNoTaxes.length) {
        params.modifiers = JSON.stringify(modifiersNoTaxes);
      }

      params.taxes =
        taxesArr && taxesArr.length ? JSON.stringify(taxesArr) : null;
      params.amount = itemAmount;
    } else {
      // regular process
      const itemAmount = getItemAmount(params);
      const taxesArr = getTaxes(restTaxes, itemAmount);

      params.taxes =
        taxesArr && taxesArr.length ? JSON.stringify(taxesArr) : null;
      params.amount = itemAmount;
    }

    await API.graphql({
      ...graphqlOperation(mutations.createOrderItem, {
        input: params,
      }),
      authMode: "AWS_IAM",
    });

    // crear orden
    const prevOrderRes = await API.graphql({
      ...graphqlOperation(queries.ordersByRestaurantByDate, {
        restaurantID: restaurantId,
        sortDirection: "DESC",
      }),
      authMode: "AWS_IAM",
    });
    const {
      data: {
        ordersByRestaurantByDate: { items: prevOrders } = { items: [] },
      } = {},
    } = prevOrderRes || {};
    const prevOrder = prevOrders && prevOrders.length ? prevOrders[0] : {};
    console.log("prevOrder result", prevOrder);
    const isSameDay = moment().isSame(prevOrder.createdAt, "day");
    console.log("isSameDay", isSameDay);

    const vars = {
      id: orderId,
      restaurantID: restaurantId,
      tableID: "bond",
      status: "NEW",
      orderType: "bond",
      orderDaySerie: isSameDay ? (prevOrder.orderDaySerie || 0) + 1 : 1,
      orderSerie: (prevOrder.orderSerie || 0) + 1,
    };

    if (currentUser) {
      vars.orderCustomerId = currentUser.id;
    }

    console.log("new order", vars);
    Cache.setItem(`bondOrderId`, orderId, {
      priority: 1,
      expires: moment().add(1, "year").endOf("day").valueOf(),
    });

    const { data } = await API.graphql({
      ...graphqlOperation(mutations.createOrder, { input: vars }),
      authMode: "AWS_IAM",
    });
    const { createOrder: newOrder } = data || {};

    console.log("newOrder", newOrder);

    await handlePay(orderId, amount);
  };

  const handlePay = async (orderId, amount) => {
    const lineItems = [
      {
        name: item && item.name,
        description: "Tu bono gastronómico, gracias por tu compra",
        amount: amount,
        currency: ((item && item.currency) || "usd").toLowerCase(),
        quantity: 1,
      },
    ];

    const metadata = {
      userId: currentUser && currentUser.id ? currentUser.id : null,
      orderType: "bond",
      restaurant: restaurantId || null,
    };

    console.log("lineItems", lineItems);
    await checkout({ lineItems, orderId, metadata });
  };

  return (
    <>
      <Box
        mt={4}
        mb={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
      >
        <Box alignSelf="flex-start" textAlign="left" mb={2}>
          <Typography variant="subtitle1">Elige tu bono</Typography>
        </Box>
        <Collapse in={!isCustomAmount}>
          <>
            <Button
              fullWidth
              style={{ marginBottom: 24 }}
              classes={{ root: classes.button }}
              onClick={handleBondButton("50000")}
            >
              Bono por MX $500
            </Button>
            <Button
              fullWidth
              style={{ marginBottom: 24 }}
              classes={{ root: classes.button }}
              onClick={handleBondButton("100000")}
            >
              Bono por MX $1,000
            </Button>
            <Button variant="outlined" onClick={handleCustomBondAmount}>
              Bono por otra cantidad
            </Button>
          </>
        </Collapse>
      </Box>
      <Collapse in={isCustomAmount}>
        <Box width="100%" marginBottom={2}>
          <CurrencyInput
            Component={TextField}
            variant="filled"
            fullWidth
            placeholder={I18n.get("Custom amount")}
            InputProps={{
              classes: { input: classes.inputInput },
              readOnly: true,
            }}
            value={bondAmount ? toCurrency((bondAmount || 0).toString()) : ""}
          />
          <NumericKeyboard
            keyboardRef={keyboardRef}
            onChange={onChange}
            onKeyPress={onKeyPress}
          />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            style={{ marginTop: 16 }}
            onClick={() => handleCreateOrder(bondAmount)}
          >
            Pagar
          </Button>
        </Box>
      </Collapse>
    </>
  );
};

export default BondSelection;
