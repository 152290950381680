import React from "react";
import { I18n } from "aws-amplify";
import { Cache } from "aws-amplify";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, Paper } from "@material-ui/core";
import QrReader from "react-qr-reader2";
import { useSnackbar } from "notistack";
import { useHistory, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
// import { uuid } from "uuidv4";
import { v4 as uuid } from "uuid";
import { API, graphqlOperation } from "aws-amplify";
import moment from "moment";
import FlashOffIcon from "@material-ui/icons/FlashOff";
import FlashOnIcon from "@material-ui/icons/FlashOn";

import useReducer from "../reducers";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import useQuery from "../hooks/useQuery";
import businessLanguage from "../lib/businessLanguage";
import { handleRestaurantIsEnabled } from "../util";
import { handleScan, handleError } from "./Scanner/util";
import InvalidBrowser from "./Scanner/InvalidBrowser";

function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const QReaderSchema = Yup.object().shape({
  tableToken: Yup.string().required("Valid table token required"),
});

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: theme.spacing(3, 0),
  },
}));

const QRScanner = ({ fromSite = false }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  let history = useHistory();
  let location = useLocation();
  const query = useQuery();
  const tt = query.get("tt");
  const [
    {
      loginReducer: { currentUser, identityId },
      restaurantReducer: { currentRestaurant },
    },
    dispatch,
  ] = useReducer();
  const { businessType } = currentRestaurant || {};
  const btype = businessType || "RESTAURANT";
  const [torch, setTorch] = React.useState(false);
  const [track, setTrack] = React.useState(null);
  let { from } = location.state || { from: { pathname: "/" } };
  const [invalidBrowser, setInvalidBrowser] = React.useState(false);
  const FlashIcon = torch ? FlashOffIcon : FlashOnIcon;

  const handleStartNewOrder = async (newOrderId, table, values, rest) => {
    const prevOrderRes = await API.graphql({
      ...graphqlOperation(queries.ordersByRestaurantByDate, {
        restaurantID: rest.id,
        sortDirection: "DESC",
      }),
      authMode: "AWS_IAM",
    });
    const {
      data: {
        ordersByRestaurantByDate: { items: prevOrders } = { items: [] },
      } = {},
    } = prevOrderRes || {};
    const prevOrder = prevOrders && prevOrders.length ? prevOrders[0] : {};
    console.log("prevOrder result", prevOrder);
    const isSameDay = moment().isSame(prevOrder.createdAt, "day");
    console.log("isSameDay", isSameDay);

    const vars = {
      id: newOrderId,
      restaurantID: table.restaurant.id,
      tableID: values.tableToken,
      status: "NEW",
      orderType: "tostay",
      orderDaySerie: isSameDay ? (prevOrder.orderDaySerie || 0) + 1 : 1,
      orderSerie: (prevOrder.orderSerie || 0) + 1,
      guests: 1,
    };

    if (currentUser) {
      vars.orderCustomerId = currentUser.id;
    }

    if (identityId) {
      vars.guestDevices = [identityId];
    }

    const { data } = await API.graphql({
      ...graphqlOperation(mutations.createOrder, { input: vars }),
      authMode: "AWS_IAM",
    });
    const { createOrder: newOrder } = data || {};

    console.log("newOrder", newOrder);

    Cache.setItem("currentOrderId", newOrder.id);
    Cache.setItem("currentRestaurantId", table.restaurant.id);
    dispatch({ type: "ORDER_SET", payload: { currentOrder: newOrder } });
    dispatch({
      type: "RESTAURANT_SET",
      payload: { currentRestaurant: rest },
    });

    if (rest?.accountId) {
      let apiName = "api616cdc2a";
      let path = "/stripe/account";

      try {
        const result = await API.get(apiName, path, {
          queryStringParameters: {
            account: rest?.accountId,
          },
        });
        console.log("/stripe/account response", result);
        dispatch({
          type: "CHECKOUT_SET_ACCOUNT_CHARGES_ENABLED",
          payload: {
            accountChargesEnabled: Boolean(result.charges_enabled),
          },
        });
      } catch (error) {
        console.log("error", error);
      }
    }

    dispatch({ type: "LOADING_STOP" });
    history.replace(from);
  };

  const handleExistentOrder = async (existentOrder, table, rest) => {
    const vars = {
      id: existentOrder.id,
      guests: existentOrder.guests + 1,
      _version: existentOrder._version,
    };

    if (identityId) {
      vars.guestDevices = [...(existentOrder.guestDevices || []), identityId];
    }

    await API.graphql({
      ...graphqlOperation(mutations.updateOrder, {
        input: vars,
      }),
      authMode: "AWS_IAM",
    });

    Cache.setItem("currentOrderId", existentOrder.id);
    Cache.setItem("currentRestaurantId", table.restaurant.id);
    dispatch({
      type: "ORDER_SET",
      payload: { currentOrder: existentOrder },
    });
    dispatch({
      type: "RESTAURANT_SET",
      payload: { currentRestaurant: rest },
    });

    if (rest?.accountId) {
      let apiName = "api616cdc2a";
      let path = "/stripe/account";

      try {
        const result = await API.get(apiName, path, {
          queryStringParameters: {
            account: rest?.accountId,
          },
        });
        console.log("/stripe/account response", result);
        dispatch({
          type: "CHECKOUT_SET_ACCOUNT_CHARGES_ENABLED",
          payload: {
            accountChargesEnabled: Boolean(result.charges_enabled),
          },
        });
      } catch (error) {
        console.log("error", error);
      }
    }

    dispatch({ type: "LOADING_STOP" });
    history.replace(from);
  };

  const formik = useFormik({
    initialValues: {
      tableToken: "",
    },
    validationSchema: QReaderSchema,
    onSubmit: async (values) => {
      dispatch({ type: "LOADING_START" });
      console.log("values", values);
      const tableID = values.tableToken;

      let newOrderId = uuid();

      const { data: tableData } = await API.graphql({
        ...graphqlOperation(queries.getTable, { id: tableID }),
        authMode: "AWS_IAM",
      });

      const { getTable: table = {} } = tableData || {};

      console.log("table", table);

      const { isOccupied, lastOrder } = table || {};
      let existentOrder;

      if (!table || table._deleted || table.status === "INACTIVE") {
        enqueueSnackbar(
          I18n.get(businessLanguage[btype]["No table found, ask for help."]),
          {
            variant: "error",
            autoHideDuration: 5000,
          }
        );
        dispatch({ type: "LOADING_STOP" });
        return;
      }

      const { data: { getRestaurant } = {} } =
        (await API.graphql({
          ...graphqlOperation(queries.getRestaurantData, {
            id: table.restaurant.id,
          }),
          authMode: "AWS_IAM",
        })) || {};
      console.log("restaurant", getRestaurant);

      if (!getRestaurant) {
        enqueueSnackbar(
          I18n.get(
            businessLanguage[btype]["No restaurant found, ask for help."]
          ),
          {
            variant: "error",
            autoHideDuration: 5000,
          }
        );
        dispatch({ type: "LOADING_STOP" });
        return;
      }

      if (getRestaurant && getRestaurant.businessType === "TRAVEL") {
        console.log("VIAJES");
        dispatch({ type: "LOADING_STOP" });
        history.push(fromSite ? `/d` : `/d/r/${getRestaurant.id}`);
        return;
      }

      const { restaurantIsEnabled } = handleRestaurantIsEnabled(
        getRestaurant && getRestaurant.schedule
      );
      console.log("restaurantIsEnabled", restaurantIsEnabled);

      if (!restaurantIsEnabled) {
        enqueueSnackbar(I18n.get("Consultation after hours"), {
          variant: "error",
          autoHideDuration: 5000,
        });
        dispatch({ type: "LOADING_STOP" });
        return;
      }

      if (getRestaurant?.primaryColor || getRestaurant?.company?.primaryColor) {
        dispatch({
          type: "THEME_SET_CUSTOM",
          payload: {
            primaryColor:
              getRestaurant?.primaryColor ||
              getRestaurant?.company?.primaryColor,
          },
        });
      }

      if (getRestaurant && getRestaurant.prepaidMode) {
        dispatch({
          type: "DELIVERY_SET_ORDER_TYPE",
          payload: { orderType: "dinein" },
        });
        dispatch({
          type: "DELIVERY_SET_ORDER_TABLE",
          payload: { tableID },
        });
        dispatch({ type: "LOADING_STOP" });
        history.push(fromSite ? `/d` : `/d/r/${getRestaurant.id}`);
        return;
      }

      // eslint-disable-next-line eqeqeq
      if (isOccupied == "true") {
        newOrderId = lastOrder;
        const { data } = await API.graphql({
          ...graphqlOperation(queries.getOrder, { id: lastOrder }),
          authMode: "AWS_IAM",
        });
        const { getOrder } = data || {};
        existentOrder = getOrder;
      } else {
        const now = new Date();
        const updateTableResponse = await API.graphql({
          ...graphqlOperation(mutations.updateTable, {
            input: {
              id: tableID,
              lastOrder: newOrderId,
              lastOrderDate: now.toISOString(),
              isOccupied: "true",
              _version: table._version,
            },
            condition: {
              isOccupied: { ne: "true" },
              status: { eq: "ACTIVE" },
            },
          }),
        });

        console.log("updateTableResponse", updateTableResponse);
      }

      console.log("existentOrder", existentOrder);
      if (!existentOrder) {
        // Start new Order
        handleStartNewOrder(newOrderId, table, values, getRestaurant);
      } else {
        // Existent Order
        handleExistentOrder(existentOrder, table, getRestaurant);
      }
    },
  });

  React.useEffect(() => {
    const retarding = async (tkn) => {
      await Promise.resolve();
      const isValidToken = /^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i.test(
        tkn
      );
      if (tkn && tkn !== formik.values.tableToken && isValidToken) {
        formik.handleSubmit(tkn);
      }
    };

    if (tt && tt.startsWith("2dine#")) {
      const parsedToken = tt.split("#").pop();
      formik.setFieldValue("tableToken", parsedToken);
      retarding(parsedToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tt]);

  React.useEffect(() => {
    if (track) {
      const capabilities =
        track && typeof track.getCapabilities !== undefined
          ? track.getCapabilities()
          : null;

      if (capabilities && capabilities.torch) {
        track
          .applyConstraints({
            advanced: [{ torch }],
          })
          .catch((e) => console.log("track eror", e));
      }
    }
  }, [torch, track]);

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Paper
        style={{
          padding: 16,
          paddingTop: 24,
          paddingBottom: 24,
          width: "100%",
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography variant="h5">{I18n.get("Scan QR Code")}</Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          style={{ marginBottom: 24, textAlign: "center" }}
        >
          {I18n.get("Open or join to an order easy & fast")}
        </Typography>
        {!invalidBrowser ? (
          <>
            <div style={{ position: "relative" }}>
              <QrReader
                onError={(err) => handleError(err, setInvalidBrowser)}
                onScan={(data) => handleScan(data, history, formik)}
                resolution={1000}
                style={{ width: 280, height: 280 }}
                showViewFinder={false}
                onLoad={async ({ streamTrack }) => {
                  try {
                    await timeout(1000);
                    setTrack(streamTrack);
                  } catch (error) {
                    console.log("onLoad error", error);
                  }
                }}
              />
              <Button
                variant="contained"
                style={{ position: "absolute", top: 10, right: 10 }}
                onClick={() => {
                  setTorch((prev) => !prev);
                }}
                startIcon={<FlashIcon />}
              >
                {I18n.get("Switch torch")}
              </Button>
            </div>
          </>
        ) : (
          <InvalidBrowser />
        )}
      </Paper>

      <form onSubmit={formik.handleSubmit}>
        <TextField
          id="tableToken"
          name="tableToken"
          label={I18n.get(businessLanguage[btype]["Table token"])}
          className={classes.textField}
          fullWidth
          size="medium"
          variant="outlined"
          inputProps={{
            spellCheck: "false",
            autoCorrect: "off",
            autoCapitalize: "off",
            autoComplete: "off",
          }}
          onChange={formik.handleChange}
          value={formik.values.tableToken}
          error={formik.errors.tableToken && formik.touched.tableToken}
          helperText={
            formik.errors.tableToken && formik.touched.tableToken
              ? formik.errors.tableToken
              : I18n.get(businessLanguage[btype]["Add the table uid"])
          }
        />
        <Button
          type="submit"
          fullWidth
          size="large"
          variant="contained"
          color="primary"
        >
          {I18n.get("Create or join to order")}
        </Button>
      </form>
    </div>
  );
};

export default QRScanner;
