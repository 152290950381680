import React from "react";
import { makeStyles } from "@material-ui/core";
import { parsePhoneNumber } from "libphonenumber-js";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useFormik } from "formik";

import { SignUpSchema } from "./SignUpSchema";
import * as AuthService from "../../lib/auth.service";
import useReducer from "../../reducers";

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1, 0),
  },
  label: {
    textAlign: "left !important",
  },
  formButton: {
    margin: `${theme.spacing(1, 0, 0, 1)} !important`,
  },
}));

const SignupForm = () => {
  const classes = useStyles();
  const [, dispatch] = useReducer();

  const onSubmit = async (values, actions) => {
    console.log(values, actions);
    const { name, userAlias } = values;

    try {
      await AuthService.signUp(userAlias, name);
    } catch (err) {
      console.log(err);
      actions.setSubmitting(false);
      return;
    }

    try {
      const obj = AuthService.getUserAttribute(values.userAlias);
      const username = obj.phone_number
        ? parsePhoneNumber(obj.phone_number, "MX").number
        : obj.email;
      await AuthService.signIn(username);
      dispatch({ type: "LOGIN_SET_VIEW", payload: { view: "secret_code" } });

      actions.setSubmitting(false);
    } catch (err) {
      console.log(err);
      actions.setSubmitting(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      userAlias: "",
    },
    validationSchema: SignUpSchema,
    onSubmit,
  });

  return (
    <form id="signupForm" onSubmit={formik.handleSubmit}>
      <TextField
        fullWidth
        margin="normal"
        className={classes.margin}
        label="¿Cómo te llamas?"
        variant="outlined"
        id="name"
        type="text"
        name="name"
        autoComplete="off"
        autoCapitalize="off"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.name}
        error={!!(formik.touched.name && formik.errors.name)}
        helperText={
          (formik.touched.name && formik.errors.name) || "Ingresa tu nombre"
        }
        InputLabelProps={{
          className: classes.label,
        }}
      />
      <TextField
        fullWidth
        margin="normal"
        className={classes.margin}
        label="¿Cuál es tu email o número celular?"
        variant="outlined"
        id="userAlias"
        type="text"
        name="userAlias"
        autoComplete="off"
        autoCapitalize="off"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.userAlias}
        error={!!(formik.touched.userAlias && formik.errors.userAlias)}
        helperText={formik.touched.userAlias && formik.errors.userAlias}
        InputLabelProps={{
          className: classes.label,
        }}
      />

      <Button
        type="submit"
        variant="contained"
        color="primary"
        size="large"
        disabled={formik.isSubmitting}
        fullWidth
        // className={classes.formButton}
      >
        Registrarse
      </Button>
    </form>
  );
};

export default SignupForm;
