import React from "react";
import _uniqBy from "lodash/uniqBy";
import { I18n } from "aws-amplify";
import {
  Typography,
  GridList,
  Box,
  Chip,
  Paper,
  Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GridListTile from "@material-ui/core/GridListTile";
import Skeleton from "@material-ui/lab/Skeleton";
import { Link as RouterLink } from "react-router-dom";

import useQuery from "../../hooks/useQuery";
import CategoryCard from "../../components/Category/CategoryCard";
import useReducer from "../../reducers";
import { isSafariBrowser } from "../../util";
import NoData from "../../components/NoData";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    marginTop: theme.spacing(2),
  },
  gridList: {
    width: "100%",
  },
  button: {
    background:
      "linear-gradient(to right, #ffb347 0%, #ffcc33 51%, #ffb347 100%)",
    boxShadow: "0 3px 5px 2px #e09600",
    borderRadius: 100,
    paddingLeft: 24,
    paddingRight: 24,
    color: "#262833",
    "&:hover": {
      backgroundPosition: "right center",
    },
  },
}));

const DeliveryRestaurants = () => {
  const classes = useStyles();
  const query = useQuery();
  const dt = query.get("dt"); // delivery type [togo,todeliver]
  const [
    {
      deliveryReducer: {
        nearbyRestaurants,
        deliveryAddress,
        loadingNearbyResturants: loading,
      },
    },
    dispatch,
  ] = useReducer();
  const [deliveryItems, setDeliveryItems] = React.useState([]);
  const [preorderingItems, setPreorderingItems] = React.useState([]);

  React.useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch({
        type: "DELIVERY_SET_ORDER_TYPE",
        payload: { orderType: dt || "todeliver" },
      });
    }

    return () => {
      mounted = false;
    };
  }, [dt]);

  React.useEffect(() => {
    let mounted = true;

    if (mounted) {
      setDeliveryItems(
        _uniqBy(
          nearbyRestaurants.filter((x) => x.hasDelivery),
          "id"
        )
      );
      setPreorderingItems(
        _uniqBy(
          nearbyRestaurants.filter((x) => x.hasPreordering),
          "id"
        )
      );
    }

    return () => {
      mounted = false;
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nearbyRestaurants]);

  const handleClickOpen = () => {
    console.log("toggle");
    dispatch({
      type: "DELIVERY_TOGGLE_ADDRESS_DIALOG",
      payload: { open: true },
    });
  };

  return (
    <Box mt={2} mb={7}>
      <Box display="flex" justifyContent="space-between">
        <Typography variant="h5">{I18n.get("Restaurants")}</Typography>
        <Chip
          variant={dt === "togo" ? "default" : "outlined"}
          clickable
          color={dt === "togo" ? "primary" : "default"}
          label={I18n.get("To Go")}
          component={RouterLink}
          to={
            dt !== "togo"
              ? {
                  pathname: `/d`,
                  search: `?dt=togo`,
                }
              : "/d"
          }
        />
      </Box>

      {!deliveryAddress ? (
        <Paper
          elevation={2}
          variant="elevation"
          style={{ padding: 16, marginTop: 16 }}
        >
          <Typography variant="h5">{I18n.get("Add your address")}</Typography>
          <Typography variant="subtitle2" style={{ marginBottom: 24 }}>
            {I18n.get("To retrieve the nearest restaurants")}
          </Typography>

          <Button onClick={handleClickOpen} classes={{ root: classes.button }}>
            {I18n.get("Add address")}
          </Button>
        </Paper>
      ) : (
        <div className={classes.root}>
          <GridList cols={2} cellHeight={180} className={classes.gridList}>
            {(loading
              ? Array.from(new Array(6))
              : dt && dt === "togo"
              ? preorderingItems
              : deliveryItems
            ).map((item, index) =>
              item ? (
                item._deleted ? null : (
                  <CategoryCard key={item.id} category={item} isRest />
                )
              ) : (
                <GridListTile key={index}>
                  <Skeleton
                    variant="rect"
                    animation="wave"
                    height={180}
                    width={185}
                    style={{ borderRadius: 8 }}
                  />
                </GridListTile>
              )
            )}
          </GridList>
          {!loading &&
          !((dt && dt === "togo" ? preorderingItems : deliveryItems) || [])
            .length ? (
            <NoData />
          ) : null}
        </div>
      )}
      {isSafariBrowser ? (
        <div id="space" style={{ minHeight: 64, height: 64 }} />
      ) : null}
    </Box>
  );
};

export default DeliveryRestaurants;
