import React from "react";
import { Cache, I18n } from "aws-amplify";
import { Button } from "@material-ui/core";
import ConfirmDialog from "../ConfirmDialog";
import useReducer from "../../reducers";

const ClearCartButton = () => {
  const [, dispatch] = useReducer();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClearCart = () => {
    dispatch({
      type: "DELIVERY_SET_CART",
      payload: { deliveryCart: [] },
    });
    dispatch({
      type: "DELIVERY_SET_CART_COMPANY",
      payload: { cartCompany: {} },
    });
    dispatch({
      type: "DELIVERY_SET_CART_RESTAURANT",
      payload: { cartRestaurant: {} },
    });
    dispatch({
      type: "DELIVERY_SET_PRINCIPAL_RESTAURANT",
      payload: { principalRestaurant: {} },
    });
    Cache.removeItem(`deliveryCart`);
    Cache.removeItem(`cartRestaurant`);
    Cache.removeItem(`cartCompany`);
    dispatch({
      type: "DELIVERY_TOGGLE_CHECKOUT",
      payload: { openCheckout: false },
    });
  };

  return (
    <>
      <Button size="small" onClick={handleOpen}>
        {I18n.get("Clear cart")}
      </Button>
      <ConfirmDialog
        open={open}
        onClose={handleClose}
        onConfirm={handleClearCart}
      />
    </>
  );
};

export default ClearCartButton;
