import React from "react";
import RemoveIcon from "@material-ui/icons/Remove";
import { Fab, Box } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

const QtyComponent = ({
  isDelete = false,
  handleRemoveQty = () => {},
  handleAddQty = () => {},
  minQuantity = 0,
  maxQuantity = 0,
  value = 1,
  small = false,
  sum = 0,
  isModifierOption = false,
}) => {
  const styles = small ? { width: 24, height: 24, minHeight: 24 } : {};
  const stylesValue = small
    ? {
        width: 28,
        height: 28,
        fontSize: 16,
        marginLeft: 6,
        marginRight: 6,
      }
    : {
        width: 48,
        height: 48,
        fontSize: 20,
        marginLeft: 12,
        marginRight: 12,
      };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: 9,
        paddingBottom: 9,
      }}
    >
      {!isDelete ? (
        <Fab
          size="small"
          color="secondary"
          aria-label="less"
          onClick={handleRemoveQty}
          disabled={value === 1 || (minQuantity === value && !isModifierOption)}
          style={styles}
        >
          <RemoveIcon fontSize="small" />
        </Fab>
      ) : (
        <Fab
          size="small"
          color="secondary"
          aria-label="less"
          onClick={handleRemoveQty}
          disabled={!value}
          style={styles}
        >
          <DeleteIcon fontSize="small" />
        </Fab>
      )}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{
          ...stylesValue,
          borderRadius: "100%",
          backgroundColor: "rgba(255, 255, 255, 0.09)",
        }}
      >
        {value}
      </Box>
      <Fab
        size="small"
        color="secondary"
        aria-label="add"
        onClick={handleAddQty}
        disabled={maxQuantity === (isModifierOption ? sum : value)}
        style={styles}
      >
        <AddIcon />
      </Fab>
    </div>
  );
};

export default QtyComponent;
