import React from "react";
import { useTheme } from "@material-ui/core";

const Wave = ({ style }) => {
  const theme = useTheme();
  return (
    <svg
      style={style}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1440 320"
    >
      <path
        fill={theme.palette.background.default}
        fillOpacity="1"
        d="M0,256L120,261.3C240,267,480,277,720,240C960,203,1200,117,1320,74.7L1440,32L1440,320L1320,320C1200,320,960,320,720,320C480,320,240,320,120,320L0,320Z"
      ></path>
    </svg>
  );
};

export default Wave;
