import React from "react";
import { I18n } from "aws-amplify";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import useReducer from "../../reducers";
import ProfileSummary from "../Profile/ProfileSummary";
import SignInWithFacebook from "../Federation/SignInWithFacebook";
import SignInWithGoogle from "../Federation/SignInWithGoogle";
import LoginWrapper from "../Login/LoginWrapper";
import { Divider } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paperAnchorBottom: {
    maxHeight: "70%",
    minHeight: "30%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  margin: {
    marginRight: theme.spacing(5),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  fab: {
    width: "140px !important",
  },
}));

const LoginSheet = () => {
  const classes = useStyles();
  const [
    {
      loginReducer: { openLogin, currentUser, authState },
    },
    dispatch,
  ] = useReducer();

  const toggleSheet = (open = !openLogin) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    dispatch({ type: "LOGIN_TOGGLE", payload: { open } });
  };

  // const handleSignInButton = (provider = "Facebook") => () => {
  //   Cache.setItem("fromUrl", location);
  //   Auth.federatedSignIn({ provider });
  // };

  return (
    <Drawer
      anchor="bottom"
      open={openLogin}
      onClose={toggleSheet(false)}
      classes={{ paper: classes.paperAnchorBottom }}
    >
      {authState === "signIn" && (
        <>
          <Box mx={2} mt={1}>
            <Typography variant="h4">{I18n.get("Login")}</Typography>
            <Typography variant="body2">
              {I18n.get(
                "Sign In/Register with your email, phone number, Facebook or Google"
              )}
            </Typography>
          </Box>
          <LoginWrapper />
          <Box
            mb={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Divider style={{ flex: 1 }} variant="middle" />
            <Typography variant="caption">{I18n.get("or")}</Typography>
            <Divider style={{ flex: 1 }} variant="middle" />
          </Box>

          <Box
            flex={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <SignInWithFacebook />
            <SignInWithGoogle />
          </Box>
        </>
      )}
      {authState === "signedIn" && currentUser ? <ProfileSummary /> : null}
    </Drawer>
  );
};

export default LoginSheet;
