import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  ribbonWrapper: {
    position: "absolute",
    display: "block",
    top: -4,
    right: -4,
    width: 96,
    height: 96,
    overflow: "hidden",
    zIndex: 1
  },
  ribbon: {
    position: "relative",
    display: "block",
    left: -6,
    top: 20,
    width: 134,
    padding: "5px 0",
    // fontSize: 14,
    textAlign: "center",
    color: theme.palette.background.paper,
    backgroundColor: "#68e85e",
    WebkitBoxShadow: "0px 0px 4px rgba(0,0,0,0.3)",
    MozBoxShadow: "0px 0px 4px rgba(0,0,0,0.3)",
    MsBoxShadow: "0px 0px 4px rgba(0,0,0,0.3)",
    boxShadow: "0px 0px 4px rgba(0,0,0,0.3)",
    WebkitTransform: "rotate(45deg) translate3d(0,0,0)",
    MozTransform: "rotate(45deg) translate3d(0,0,0)",
    MsTransform: "rotate(45deg) translate3d(0,0,0)",
    transform: "rotate(45deg) translate3d(0,0,0)",
    "&::before": {
      content: '""',
      position: "absolute",
      bottom: -4,
      borderTop: "4px solid #22a518",
      borderLeft: "4px solid transparent",
      borderRight: "4px solid transparent",
      left: 0
    },
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: -4,
      borderTop: "4px solid #22a518",
      borderLeft: "4px solid transparent",
      borderRight: "4px solid transparent",
      right: 0
    }
  }
}));

const PromoLabel = ({ label }) => {
  const classes = useStyles();

  return (
    <div className={classes.ribbonWrapper}>
      <Typography
        variant="subtitle2"
        component="div"
        className={classes.ribbon}
      >
        {label}
      </Typography>
    </div>
  );
};

export default PromoLabel;
