import React from "react";
import Box from "@material-ui/core/Box";
import { useTheme } from "@material-ui/core/styles";
import useReducer from "../reducers";
import { JSONParse, getPictureUrl, isEmptyObject } from "../util";

// import { ReactComponent as PhotoSVG } from "../svg/photo.svg";

const Photo = ({
  color = "#000",
  bgcolor = "#ddd",
  width = "100%",
  height = "100%",
}) => {
  const theme = useTheme();

  const [
    {
      restaurantReducer: { currentRestaurant },
      deliveryReducer: { selectedRestaurant },
    },
  ] = useReducer();
  const rest =
    selectedRestaurant && !isEmptyObject(selectedRestaurant)
      ? selectedRestaurant
      : currentRestaurant || {};
  const picture = rest && rest.picture ? JSONParse(rest.picture) : {};
  const isPicArray = picture ? Array.isArray(picture) : false;
  const imageUrl =
    rest && rest.picture
      ? isPicArray
        ? getPictureUrl({ ...picture[0], size: "0x570" })
        : getPictureUrl({ ...picture, size: "0x570" })
      : null;

  return (
    <Box
      bgcolor={theme.palette.background.paper || bgcolor}
      // padding={1}
      flex={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
      width={width}
      height={height}
    >
      {/* <PhotoSVG
        width={60}
        height={60}
        style={{ fill: theme.palette.text.primary || color }}
      /> */}
      <img
        alt="not found"
        style={{
          width: "100%",
          height: "100%",
          background: "white",
          objectFit: "contain",
        }}
        src={
          imageUrl ||
          `${process.env.REACT_APP_CLOUDFRONT}/public/assets/no_image.jpeg`
        }
      />
    </Box>
  );
};

export default Photo;
