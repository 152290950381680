import React from "react";
import loadingReducer from "./loadingReducer";
import orderReducer from "./orderReducer";
import themeReducer from "./themeReducer";
import sheetReducer from "./sheetReducer";
import searchReducer from "./searchReducer";
import checkoutReducer from "./checkoutReducer";
import restaurantReducer from "./restaurantReducer";
import navReducer from "./navReducer";
import loginReducer from "./loginReducer";
import tabsReducer from "./tabsReducer";
import invoiceReducer from "./invoiceReducer";
import { combineReducers } from "../lib/combineReducers";
import deliveryReducer from "./deliveryReducer";
import bondReducer from "./bondReducer";
import siteReducer from "./siteReducer";

const Context = React.createContext();

const [rootReducer, rootState] = combineReducers({
  themeReducer: [themeReducer.reducer, themeReducer.initialState],
  orderReducer: [orderReducer.reducer, orderReducer.initialState],
  loadingReducer: [loadingReducer.reducer, loadingReducer.initialState],
  navReducer: [navReducer.reducer, navReducer.initialState],
  sheetReducer: [sheetReducer.reducer, sheetReducer.initialState],
  tabsReducer: [tabsReducer.reducer, tabsReducer.initialState],
  searchReducer: [searchReducer.reducer, searchReducer.initialState],
  checkoutReducer: [checkoutReducer.reducer, checkoutReducer.initialState],
  invoiceReducer: [invoiceReducer.reducer, invoiceReducer.initialState],
  bondReducer: [bondReducer.reducer, bondReducer.initialState],
  restaurantReducer: [
    restaurantReducer.reducer,
    restaurantReducer.initialState,
  ],
  deliveryReducer: [deliveryReducer.reducer, deliveryReducer.initialState],
  loginReducer: [loginReducer.reducer, loginReducer.initialState],
  siteReducer: [siteReducer.reducer, siteReducer.initialState],
});

export const Reducer = ({ children }) => {
  const mainReducer = React.useReducer(rootReducer, rootState);

  return <Context.Provider value={mainReducer}>{children}</Context.Provider>;
};

const useReducer = () => React.useContext(Context);

export default useReducer;
