import { API } from "aws-amplify";

import useReducer from "../reducers";
import useStripe from "./useStripe";
import { isEmptyObject } from "../util";
import usePaypal from "./usePaypal";

const useCheckout = () => {
  const [
    {
      deliveryReducer: { selectedRestaurant, cartRestaurant },
      restaurantReducer: { currentRestaurant },
      checkoutReducer: { accountChargesEnabled },
    },
  ] = useReducer();

  const defaultRest = {
    country: "MX",
    currency: "MXN",
    accountId: process.env.REACT_APP_CONNECTED_STRIPE_ACCOUNT,
    paypalAccount: process.env.REACT_APP_PAYPAL_CLIENT_ID,
  };
  const { country, currency, accountId, paypalAccount } =
    cartRestaurant && !isEmptyObject(cartRestaurant)
      ? cartRestaurant || defaultRest
      : selectedRestaurant && !isEmptyObject(selectedRestaurant)
      ? selectedRestaurant || defaultRest
      : currentRestaurant || defaultRest;
  const options = accountChargesEnabled
    ? { stripeAccount: accountId }
    : process.env.REACT_APP_IS_PROD === "true" && country === "MX"
    ? {
        stripeAccount: process.env.REACT_APP_CONNECTED_STRIPE_ACCOUNT,
      }
    : null;
  console.log("stripe params", process.env.REACT_APP_STRIPE_APIKEY, options);
  const paypalId = paypalAccount || process.env.REACT_APP_PAYPAL_CLIENT_ID;
  const stripe = useStripe(process.env.REACT_APP_STRIPE_APIKEY, options);
  usePaypal(currency, paypalId);

  const handleCheckout = async ({ lineItems, orderId, metadata }) => {
    let stripeCustomer = null;

    // if (currentUser && country !== "MX") {
    //   if (currentUser.stripeCustomer) {
    //     stripeCustomer = currentUser.stripeCustomer;
    //   } else {
    //     stripeCustomer = await createCustomer();

    //     const { data } = await API.graphql({
    //       ...graphqlOperation(queries.getUser, { id: currentUser.id }),
    //       authMode: "AWS_IAM",
    //     });
    //     const { getUser: user } = data || {};
    //     const { data: updateData } = await API.graphql({
    //       ...graphqlOperation(mutations.updateUser, {
    //         input: {
    //           id: currentUser.id,
    //           stripeCustomer,
    //           _version: user._version,
    //         },
    //       }),
    //       authMode: "AWS_IAM",
    //     });
    //     const { updateUser: updateUserResponse } = updateData || {};

    //     dispatch({
    //       type: "LOGIN_SET_USER",
    //       payload: { user: updateUserResponse },
    //     });
    //   }
    // }

    const session = await createSession(
      lineItems,
      stripeCustomer,
      orderId,
      metadata
    );
    // const session = {
    //   id: 'cs_test_LvYl7L5LPKL2eYjYEZKfTYD7mRYtuZbMpgEM11wZbiPar0UhA6faRtki',
    //   payment_intent: 'pi_1FyIXYAFlE9IzScpZm60ZXdx'
    // };
    if (session && session.id) {
      stripe
        .redirectToCheckout({
          // Make the id field from the Checkout Session creation API response
          // available to this file, so you can provide it as parameter here
          // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
          sessionId: session.id,
        })
        .then(function (result) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
          console.log("result", result && JSON.stringify(result, null, 2));
        });
    }
  };

  // const createCustomer = async () => {
  //   let apiName = "api616cdc2a";
  //   let path = "/stripe/customer";

  //   const result = await API.post(apiName, path, {
  //     body: {
  //       email: currentUser.id,
  //       name: currentUser.name,
  //       metadata: { userId: currentUser.id || null },
  //     },
  //   });
  //   console.log("result", result);

  //   return result.id;
  // };

  const createSession = async (
    lineItems,
    stripeCustomer,
    orderId,
    metadata
  ) => {
    let apiName = "api616cdc2a";
    let path = "/stripe/session";

    const result = await API.post(apiName, path, {
      body: {
        orderId: orderId,
        lineItems,
        stripeCustomer,
        metadata,
        country,
      },
    });
    console.log("result", result);

    return result;
  };

  return handleCheckout;
};

export default useCheckout;
