import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridListTile from "@material-ui/core/GridListTile";
import GridListTileBar from "@material-ui/core/GridListTileBar";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/AddCircle";
import { useHistory } from "react-router-dom";
import Img from "react-image";
import Skeleton from "@material-ui/lab/Skeleton";

import {
  JSONParse,
  getPictureUrl,
  handleProductPrice,
  toCurrency,
} from "../../util";
import Photo from "../Photo";

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "center",
  },
}));

const MenuItem = ({
  product,
  style,
  restaurantId,
  delivery,
  currency,
  fromSite,
  ...other
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { product: item = { product: {} } } = product || {};
  const image = item && item.picture ? JSONParse(item.picture) : {};
  const isPicArray = item && item.picture ? Array.isArray(image) : false;
  const imageUrl =
    item && item.picture
      ? isPicArray
        ? getPictureUrl({ ...image[0], size: "0x271" })
        : getPictureUrl({ ...image, size: "0x271" })
      : null;

  const productPrice = React.useMemo(
    () => handleProductPrice(item || {}, delivery),
    [item, delivery]
  );

  const handleClickCard = () => {
    if (item && item.status === "ACTIVE") {
      if (!delivery) {
        history.push(`/t/product/${item && item.id}`);
      } else {
        history.push(
          fromSite
            ? `/d/product/${item && item.id}`
            : `/d/r/${restaurantId}/product/${item && item.id}`
        );
      }
    }
  };

  if (!item || item.status !== "ACTIVE") {
    return null;
  }

  return (
    <GridListTile
      {...other}
      style={item.status !== "ACTIVE" ? { ...style, opacity: 0.45 } : style}
      onClick={handleClickCard}
    >
      <Img
        src={[imageUrl]}
        alt={item && item.title}
        loader={
          <Skeleton
            variant="rect"
            animation="wave"
            width={"100%"}
            height={180}
            className={classes.skeleton}
          />
        }
        unloader={<Photo />}
        className={classes.image}
      />
      <GridListTileBar
        title={item && item.title}
        subtitle={
          productPrice
            ? `${currency} ${toCurrency((productPrice || 0).toString())}`
            : null
        }
        classes={{
          root: classes.titleBar,
          title: classes.title,
        }}
        actionIcon={
          <IconButton
            aria-label={`star ${item && item.MenuItemtitle}`}
            onClick={(event) => {
              event.stopPropagation();
              handleClickCard();
            }}
          >
            <AddIcon className={classes.title} />
          </IconButton>
        }
      />
    </GridListTile>
  );
};

export default MenuItem;
