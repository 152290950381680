import React from "react";
import { I18n } from "aws-amplify";
import _groupBy from "lodash/groupBy";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import Button from "@material-ui/core/Button";
import Zoom from "@material-ui/core/Zoom";
import { useSnackbar } from "notistack";
import { API, graphqlOperation } from "aws-amplify";

import useReducer from "../../reducers";
import {
  JSONParse,
  getItemAmount,
  getTaxes,
  removePriceTaxes,
  removeModifiersTaxes,
  clean,
  filterOrderItems,
} from "../../util";
import * as mutations from "../../graphql/mutations";
import * as queries from "../../graphql/queries";
import ConfirmDialog from "../ConfirmDialog";

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    position: "relative",
    minHeight: 42,
    marginTop: theme.spacing(1),
  },
  button: {
    position: "absolute",
    width: "100%",
  },
}));

const Buttons = () => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [
    {
      tabsReducer: { activeTab },
      restaurantReducer: { currentRestaurant },
      orderReducer: { currentOrder, orderItems },
    },
    dispatch,
  ] = useReducer();
  const { taxes } = currentRestaurant || {};

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const getPendingItems = (items) => {
    return items && items.length
      ? items.filter((c) => c.status === "PENDING")
      : [];
  };

  const getOrderedItems = (items) => {
    return items && items.length
      ? items.filter((c) =>
          ["ORDERED", "FIRED", "READY", "DELIVERED"].includes(c.status)
        )
      : [];
  };

  const filteredOrderItems = React.useMemo(() => filterOrderItems(orderItems), [
    orderItems,
  ]);
  const pendingItems = React.useMemo(
    () => getPendingItems(filteredOrderItems),
    [filteredOrderItems]
  );
  const orderedItems = React.useMemo(
    () => getOrderedItems(filteredOrderItems),
    [filteredOrderItems]
  );

  const getItemsbyKitchen = async (items) => {
    const { table } = currentOrder || {};
    const { area } = table || {};

    let areaKitchens = [];
    if (area) {
      const {
        data: { getCategory },
      } = await API.graphql({
        ...graphqlOperation(queries.getCategory, { id: area }),
      });

      console.log("getCategory", getCategory);
      const { subcategories: { items: akitems } = { items: [] } } =
        getCategory || {};
      areaKitchens = akitems;
    }

    const parseKitchens = areaKitchens
      .filter(({ _deleted }) => !_deleted)
      .map(({ subcategoryID }) => subcategoryID);
    const parseItems = await items.reduce(async (result, item) => {
      let actualResult = await result;
      let actualKitchens;
      const {
        data: {
          getProduct: { kitchenID, kitchens },
        },
      } = await API.graphql({
        ...graphqlOperation(queries.getProduct, {
          id: item.productID,
        }),
      });
      actualKitchens = kitchens ? kitchens : [kitchenID];
      const productAreaKitchens = parseKitchens.filter(
        (id) => kitchens && kitchens.includes(id)
      );
      if (productAreaKitchens.length) actualKitchens = productAreaKitchens;
      actualKitchens.forEach((kitchenID) => {
        actualResult = [...actualResult, { ...item, kitchenID }];
      });
      return actualResult;
    }, Promise.resolve([]));
    const itemsByKitchen = _groupBy(parseItems, ({ kitchenID = null }) => {
      if (!kitchenID) return "not_assigned";
      return kitchenID;
    });
    return itemsByKitchen;
  };

  const handleCreateCommandsbykithchen = async (items) => {
    const itemsbyKitchen = await getItemsbyKitchen(items);

    console.log("currentOrder", currentOrder);

    await Promise.all(
      Object.keys(itemsbyKitchen).map(async (kitchen) => {
        const kitchenItems = itemsbyKitchen[kitchen];
        const {
          data: { createCommand },
        } = await API.graphql({
          ...graphqlOperation(mutations.createCommand, {
            input: {
              restaurantID: currentRestaurant.id,
              orderID: currentOrder.id,
              print: 0,
              kitchenID: kitchen,
              status: "PENDING",
              items: JSON.stringify(
                kitchenItems.filter(
                  (x) => x.status === "PENDING" && !x._deleted
                )
              ),
            },
          }),
          authMode: "AWS_IAM",
        });

        console.log("createCommand response", createCommand);
      })
    );
  };

  const send2kitchen = async () => {
    const restTaxes = taxes ? JSONParse(taxes) : [];
    console.log("pendingItems", pendingItems);
    setOpenConfirm(false);

    if (pendingItems && pendingItems.length) {
      const now = new Date();

      await Promise.all(
        pendingItems.map(async (item) => {
          const original = pendingItems.find((x) => x.id === item.id);
          const input = {
            id: item.id,
            status: "ORDERED",
            _version: item._version,
            sentAt: now.toISOString(),
            print: 0,
            isPaid: false,
          };

          if (!currentRestaurant.fireEnabled) {
            input.status = "FIRED";
            input.print = 1;
          }

          if (currentRestaurant.taxesIncluded) {
            // remove taxes from price (newprice)
            const priceNoTaxes = removePriceTaxes(original, restTaxes);
            // remove taxes from modifiers
            const modifiersNoTaxes = removeModifiersTaxes(original, restTaxes);
            // calculate amount
            const itemAmount = getItemAmount({
              ...original,
              price: priceNoTaxes,
              modifiers: modifiersNoTaxes,
            });
            // getTaxes
            const taxesArr = getTaxes(restTaxes, itemAmount);

            input.price = priceNoTaxes;

            if (modifiersNoTaxes && modifiersNoTaxes.length) {
              input.modifiers = JSON.stringify(modifiersNoTaxes);
            }

            input.taxes =
              taxesArr && taxesArr.length ? JSON.stringify(taxesArr) : null;
            input.amount = itemAmount;
          } else {
            // regular process
            const itemAmount = getItemAmount(original);
            const taxesArr = getTaxes(restTaxes, itemAmount);

            input.taxes =
              taxesArr && taxesArr.length ? JSON.stringify(taxesArr) : null;
            input.amount = itemAmount;
          }

          await API.graphql({
            ...graphqlOperation(mutations.updateOrderItem, {
              input,
              condition: { status: { eq: "PENDING" } },
            }),
            authMode: "AWS_IAM",
          });
        })
      );

      console.log("pending Items", pendingItems);
      const parsedPendingItems = pendingItems.map((i) => {
        const parsedModifiers =
          i.modifiers && i.modifiers.length
            ? JSON.stringify(i.modifiers)
            : null;

        clean(i);
        return { ...i, modifiers: parsedModifiers };
      });
      console.log("parsed pending Items", pendingItems);

      if (!currentRestaurant.fireEnabled) {
        await handleCreateCommandsbykithchen(parsedPendingItems);
      }

      if (currentOrder.status === "NEW") {
        API.graphql({
          ...graphqlOperation(mutations.updateOrder, {
            input: {
              id: currentOrder.id,
              status: "OPEN",
              _version: currentOrder._version,
            },
          }),
          authMode: "AWS_IAM",
        })
          .then(({ data: updateData }) => {
            const { updateOrder: updatedOrderResponse } = updateData || {};
            console.log("updatedOrderResponse", updatedOrderResponse);
          })
          .catch((error) => {
            console.error(error.message);
          });
      }

      dispatch({ type: "TABS_SET_TAB", payload: { tab: 2 } });
      enqueueSnackbar(I18n.get("Products sent to kitchen successfully!"), {
        variant: "success",
      });
    }
  };

  const buttons = [
    {
      id: 1,
      color: "primary",
      label: I18n.get("Confirm & send to order"),
      onClick: () => setOpenConfirm(true),
      disabled: !pendingItems.length,
    },
    {
      id: 2,
      color: "primary",
      label: I18n.get("Checkout & pay"),
      onClick: () => {
        history.push({
          pathname: "/checkout",
          state: { background: location },
        });
      },
      disabled: !orderedItems.length,
    },
  ];

  return [1, 2].includes(activeTab) ? (
    <div className={classes.buttonContainer}>
      {buttons.map((button, index) => (
        <Zoom
          key={index}
          in={activeTab === button.id}
          timeout={transitionDuration}
          style={{
            transitionDelay: `${
              activeTab === button.id ? transitionDuration.exit : 0
            }ms`,
          }}
          unmountOnExit
        >
          <Button
            fullWidth
            size="large"
            variant="contained"
            className={classes.button}
            {...button}
          >
            {button.label}
          </Button>
        </Zoom>
      ))}

      <ConfirmDialog
        open={openConfirm}
        onClose={() => {
          setOpenConfirm(false);
        }}
        onConfirm={send2kitchen}
        message={I18n.get("Confirm your selection to send to kitchen")}
      />
    </div>
  ) : null;
};

export default Buttons;
