const initialState = {
  loadingPayment: true,
  invoicePayment: {},
  paymentRestaurant: null,
  invoice: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INVOICE_SET_LOADING':
      return { ...state, loadingPayment: action.payload.loading };
    case 'INVOICE_SET_PAYMENT':
      return { ...state, invoicePayment: action.payload.payment };
    case 'INVOICE_SET_RESTAURANT':
      return { ...state, paymentRestaurant: action.payload.restaurant };
    case 'INVOICE_SET':
      return { ...state, invoice: action.payload.invoice };
    case 'INVOICE_RESET':
      return initialState;
    default:
      return state;
  }
};

export default { initialState, reducer };
